<template>
    <v-dialog v-model="dialog" max-width="1200px" persistent @click:outside="closeDialog">
        <v-card class="background">
            <v-card-title>
                <div class="mr-2">
                    <v-img :src="require('@/assets/container.png')" width="30"></v-img>
                </div>
                <div>
                    {{ $t("headers.freight_overview") }}
                </div>
                <v-spacer></v-spacer>
                <v-btn icon @click="moreDetails">
                    <v-icon >
                        open_in_new
                    </v-icon>
                </v-btn>
                <v-btn icon @click="closeDialog">
                    <v-icon>
                        close
                    </v-icon>
                </v-btn>
            </v-card-title>
            <v-card-text class="overflow-y-auto background" style="max-height: 80vh;">
                <LoadingBar v-if="tripProp.id == null && $apollo.queries.tripFreight.loading"></LoadingBar>
                <div v-else>
                    <v-row align="stretch" dense>
                        <!-- Freight Details -->
                        <v-col cols="12" xs="12" md="12" lg="5" xl="5">
                            <TripFreightsNew
                                :trip="mergeTripData"
                                :tripIsLoading.sync="freightIsLoading"
                                :isSharedTrip="isSharedTrip"
                                :organisationPermissions.sync="organisationPermissions"
                                @refresh-trip="$emit('refresh-trip')"
                                :dialogMode="true"
                            ></TripFreightsNew>
                        </v-col>
                        <!-- Map -->
                        <v-col cols="12" xs="12" md="12" :lg="isFullScreen ? 12 : 7" :xl="isFullScreen ? 12 : 7">
                            <TripMap
                                :trip="mergeTripData"
                                :tripIsLoading.sync="freightIsLoading"
                                :isSharedTrip="isSharedTrip"
                                :organisationPermissions.sync="organisationPermissions"
                                @fullScreen="changeFullScreen"
                                v-on:refresh-trip="$emit('refresh-trip')"
                                :dialogMode="true"
                            ></TripMap>
                        </v-col>
                        <v-col cols="12" xs="12" md="12" lg="5" xl="5">
                            <v-row dense>
                                <v-col cols="12" class="colContent" >
                                    <TripAlertsNew
                                        :trip="mergeTripData"
                                        :tripIsLoading.sync="freightIsLoading"
                                        :isSharedTrip="isSharedTrip"
                                        :organisationPermissions.sync="organisationPermissions"
                                        v-on:refresh-trip="$emit('refresh-trip')"
                                        :dialogMode="true"
                                    ></TripAlertsNew>
                                </v-col>
                                <v-col cols="12" >
                                    <TripDocuments
                                        :trip="mergeTripData"
                                        :tripIsLoading.sync="freightIsLoading"
                                        :isSharedTrip="isSharedTrip"
                                        :organisationPermissions.sync="organisationPermissions"
                                        v-on:refresh-trip="$emit('refresh-trip')"
                                        :dialogMode="true"
                                    ></TripDocuments>
                                </v-col>
                            </v-row>
                        </v-col>
                        <v-col cols="12" xs="12" md="12" lg="7" xl="7">
                            <TripTimelineCardVersion
                                :trip="mergeTripData"
                                :isSharedTrip="isSharedTrip"
                                :organisationPermissions.sync="organisationPermissions"
                                :dialogMode="true"
                            >
                            </TripTimelineCardVersion>
                        </v-col>
                    </v-row>
                    <v-row>
                        
                    </v-row>
                </div>
            </v-card-text>
           <!--  <v-card-actions>
                <v-spacer></v-spacer>
                <v-btn color="primary" @click="closeDialog">{{ $t("general.close") }}</v-btn>
                <v-spacer></v-spacer>
            </v-card-actions> -->
        </v-card>
    </v-dialog>
</template>

<script>
import helper from "@/utils/helper";
import TripFreightsNew from "@/components/trips/arrangement/TripFreightsNew.vue";
import TripMap from "@/components/trips/arrangement/TripMap.vue";
import TripAlertsNew from "@/components/trips/arrangement/TripAlertsNew.vue";
import TripDocuments from "@/components/trips/arrangement/TripDocuments.vue";
import TripTimelineCardVersion from "@/components/trips/arrangement/TripTimelineCardVersion.vue";
import LoadingBar from "@/components/base/LoadingBar.vue";
import gql from "graphql-tag";

export default {
    props: {
        tripProp: {
            type: Object,
            required: false,
            default: () => {
                return {};
            }
        },
        currentFreight: {
            type: Object,
            required: false,
            default: () => {
                return {};
            }
        },
        isSharedTrip: {
            type: Boolean,
            required: false,
            default: false
        },
        value: {
            type: Boolean,
            required: false
        }
    },
    components: {
        LoadingBar,
        TripFreightsNew,
        TripMap,
        TripAlertsNew,
        TripDocuments,
        TripTimelineCardVersion
    },
    apollo: {
        tripFreight: {
            query: gql`
                query tripFreight($freightId: ID!) {
                    tripFreight(id: $freightId) {
                        id
                        carrierEvents
                        referenceNumber
                        orderNumber
                        containerNumber
                        airWaybillNumber
                        billOfLadingNumber
                        startDate
                        endDate
                        goods
                        loadDate
                        loadPoint
                        term
                        contact
                        notes
                        trackerLastConnectionTime
                        trackerLastLocation
                        additionalInfo
                        tripFreightTrackerSet {
                            edges {
                                node {
                                    id
                                    name
                                    lowTemperature
                                    highTemperature
                                    lowHumidity
                                    highHumidity
                                    tracker {
                                        id
                                        serialNumber
                                        activatedDate
                                        model
                                        brand
                                        lastConnectionTime
                                        lastBatteryDeviceTime
                                        lastBatteryValue
                                        lastTemperatureDeviceTime
                                        lastTemperatureValue
                                        lastExternalTemperatureDeviceTime
                                        lastExternalTemperatureValue
                                        lastProbeTemperatureDeviceTime
                                        lastProbeTemperatureValue
                                        lastHumidityDeviceTime
                                        lastHumidityValue
                                        lastLightDeviceTime
                                        lastLightValue
                                        lastShockDeviceTime
                                        lastShockValue
                                        lastLocationDeviceTime
                                        lastLatitude
                                        lastLongitude
                                        lastLocationDescription
                                    }
                                }
                            }
                        }
                        tripFreightAlertRuleSet {
                            edges {
                                node {
                                    id
                                    tenantAlertRule {
                                        id
                                        name
                                        alertSeverity
                                        isEnabled
                                        isStandardAlert
                                        tenantAlertRuleTriggerSet {
                                            edges {
                                                node {
                                                    id
                                                    triggerJson
                                                }
                                            }
                                        }
                                    }
                                }
                            }
                        }
                        trip {
                            id
                            tenantCarrier {
                                id
                                name
                                carrier {
                                    id
                                    name
                                    website
                                    code
                                }
                            }
                            originLocation {
                                id
                                name
                                city
                                country
                                latitude
                                longitude
                                radius
                            }
                            destinationLocation {
                                id
                                name
                                city
                                country
                                latitude
                                longitude
                                radius
                            }
                            tripDocumentSet(isReportDoc: false) {
                                edges {
                                    node {
                                        id
                                        name
                                        size
                                        format
                                        url
                                        file
                                        description
                                    }
                                }
                            }
                        }
                    }
                }
            `,
            variables() {
                return { freightId: this.currentFreight?.node?.id };
            },
            // Additional options here
            fetchPolicy: "cache-and-network",
            nextFetchPolicy: "cache-first",
            update: data => data.tripFreight,
            watchLoading(isLoading) {
                this.$emit("loading", isLoading);
            },
            result({ loading, data }) {
                if (!loading) {
                    this.freightIsLoading = false;
                }
            }
        },
        tenantPartners: {
            query: gql`
                query TenantPartners($tenantId: ID, $tenantPartnerUserSetUserId: ID) {
                    tenantPartners(tenantId: $tenantId, tenantPartnerUserSet_UserId: $tenantPartnerUserSetUserId) {
                        edges {
                            node {
                                id
                                organisationPermissions
                                partnerType
                            }
                        }
                    }
                }
            `,
            variables() {
                return {
                    tenantId: this.trip?.node?.tenant.id,
                    tenantPartnerUserSetUserId: this.me?.id
                };
            },
            // Additional options here
            fetchPolicy: "cache-and-network",
            nextFetchPolicy: "cache-first", // this setting can avoid query again after fetchMore
            update: data => data.tenantPartners,
            result({ loading, data }) {
                if (!loading) {
                    if (data?.tenantPartners?.edges[0]?.node) {
                        //finish loading

                        this.organisationPermissions = JSON.parse(
                            data?.tenantPartners?.edges[0]?.node.organisationPermissions || []
                        );
                    }
                }
            },
            skip() {
                return false;
            }
        }
    },
    data() {
        return {
            organisationPermissions: [],
            freightIsLoading: true,
            isFullScreen: false
        };
    },
    watch: {
        value(val) {
            if (val) {
                this.freightIsLoading = true;
            } else {
                this.freightIsLoading = false;
            }
        }
    },
    computed: {
        mergeTripData() {
            return {
                id: this.tripProp.node.id,
                createdAt: this.tripProp.node.createdAt,
                referenceNumber: this.tripProp.node.referenceNumber,
                transportMode: this.tripProp.node.transportMode,
                status: this.tripProp.node.status,
                plannedDepartureDate: this.tripProp.node?.plannedDepartureDate,
                plannedArrivalDate: this.tripProp.node?.plannedArrivalDate,
                trackerLastConnectionTime: this.tripProp.node?.trackerLastConnectionTime,
                trackerLastLocation: this.tripProp.node?.trackerLastLocation,
                trackerLastTemperatureValue: this.tripProp.node?.trackerLastTemperatureValue,
                loadPoint: this.tripFreight?.trip?.loadPoint || this.trip?.loadPoint,
                loadDate: this.tripFreight?.trip?.loadDate || this.trip?.loadDate,
                tenant: {
                    ...this.tripProp.node?.tenant
                },
                tenantCarrier: {
                    ...this.tripFreight?.trip?.tenantCarrier
                },
                originLocation: {
                    ...this.tripFreight?.trip?.originLocation
                },
                destinationLocation: {
                    ...this.tripFreight?.trip?.destinationLocation
                },
                tripAlertSet: {
                    ...this.tripProp.node?.tripAlertSet
                },
                tripDocumentSet: {
                    ...this.tripFreight?.trip?.tripDocumentSet
                },
                tripPartnerSet: {
                    ...this.tripProp.node?.tripPartnerSet
                },
                tripFreightSet: {
                    edges: [
                        {
                            node: {
                                ...this.tripFreight
                            }
                        }
                    ]
                },
                tripSeaTransportSet: {
                    ...this.tripProp.node?.tripSeaTransportSet
                }
            };
        },
        me() {
            return helper.me();
        },
        skipTenantPartners() {
            if (this.isSharedTrip) return false;
            return true;
        },
        dialog: {
            get() {
                return this.value;
            },
            set(value) {
                this.$emit("input", value);
            }
        },
        hasPermission() {
            return this.organisationPermissions.length > 0;
        }
    },
    methods: {
        closeDialog() {
            this.dialog = false;
        },
        changeFullScreen(val) {
            this.isFullScreen = val;
        },
        moreDetails() {
            this.dialog = false;
            this.$emit("more-details", this.tripProp);
        }
    }
};
</script>

<style scoped>
/* Add any component-specific styles here */
.colContent {
    height: 547px;
}
</style>
