<template>
    <v-container class="px-5"> 
        <v-row>
            <v-col cols="12">
                <LoadingBar
                    v-if="$apollo.queries.partnerUsers.loading && !hasData"
                ></LoadingBar>  
                <PartnerStaffForm
                    v-else
                    :users="partnerUsers"
                    :searchInput.sync="searchInput"
                    v-on:update-user="editUser"
                    v-on:delete-user="deleteUser"
                >
                    <template v-slot:addPartnerUser>
                        <v-btn
                            elevation="0"
                            color="primary"
                            @click="openAddPartnerUserDialog = true"
                        >
                            {{ $t("partners.add_user1") }}
                        </v-btn>
                    </template>
                </PartnerStaffForm>
            </v-col>
        </v-row>
        <!---- ADD PARTNER USER DIALOG ---->
            <AddPartnerUserDialog
                :openAddPartnerUserDialog="openAddPartnerUserDialog"
                :partner="partner"
                v-on:refreshPartnerUsersTable="
                    $apollo.queries.partnerUsers.refetch()
                "
                v-on:closeAddPartnerUserDialog="openAddPartnerUserDialog = false"
            ></AddPartnerUserDialog>
    
            <!---- EDIT PARTNER USER DIALOG ---->
            <EditPartnerUserDialog
                ref="editPartnerUserDialog"
                :openEditPartnerUserDialog="openEditUserDialog"
                :partner="partner"
                v-on:refreshPartnerUsersTable="
                    $apollo.queries.partnerUsers.refetch()"
                v-on:closeAddPartnerUserDialog="openEditUserDialog = false"
            ></EditPartnerUserDialog>
    
            <!---- DELETE PARTNER USER DIALOG ---->
            <DeletePartnerUserDialog
                :openDeleteDialog="openDeletePartnerDialog"
                :usersID="selectedUsers"
                v-on:refreshDataTable="$apollo.queries.partnerUsers.refetch()"
                v-on:closeDeletePartnerUserDialog="closeAndCleanDeletedUsers"
            >
                <template #title>{{ $t("partners.delete_partner_user") }}</template>
                <template #content>{{ $t("partners.sure_partner_user") }}</template>
            </DeletePartnerUserDialog>
    </v-container>
</template>
<script>
import gql from "graphql-tag";
import AddPartnerUserDialog from "@/components/partners/create/AddPartnerUserDialog.vue";
import DeletePartnerUserDialog from "@/components/partners/delete/DeletePartnerUserDialog.vue";
import EditPartnerUserDialog from "@/components/partners/update/EditPartnerUserDialog.vue";
import PartnerStaffForm from "@/components/partners/forms/PartnerStaffForm.vue";
import Config from "@/utils/config.json";
import LoadingBar from "@/components/base/LoadingBar.vue";
import helper from "@/utils/helper.js";

export default {
    name: "PartnerUsersTab",
    components: {
        AddPartnerUserDialog,
        DeletePartnerUserDialog,
        EditPartnerUserDialog,
        PartnerStaffForm,
        LoadingBar
    },
    props: {
        partner: {
            type: Object,
            required: true
        },
        isSelectorEnabled: Boolean
    },
    apollo: {
        partnerUsers: {
            query: gql`
                query getTenantPartnerUsers(
                    $tenantPartner: ID
                    $search: String
                ) {
                    tenantPartnerUsers(
                        tenantPartner: $tenantPartner
                        search: $search
                    ) {
                        edges {
                            node {
                                id
                                notificationEnabled
                                user {
                                    id
                                    email
                                    firstName
                                    lastName
                                    require2fa
                                    lastLogin
                                }
                            }
                        }
                    }
                }
            `,
            variables() {
                return {
                    tenantPartner: this.partner ? this.partner.id : null,
                    search: this.searchInput ? this.searchInput : null
                };
            },
            fetchPolicy: "network-only",
            update: response => {
                return response.tenantPartnerUsers.edges;
            },
            skip: false
        }
    },
    data() {
        return {
            openAddPartnerUserDialog: false,
            openDeletePartnerDialog: false,
            openEditUserDialog: false,
            selectedUsers: [],
            searchInput: null,
            partnerUsers: []
        };
    },
    watch: {},
    computed: {
        enableOrDisableRemove() {
            return this.selectedUsers?.length > 0 ? false : true;
        }, 
        hasData() {
           return this.partnerUsers?.length > 0 ? true : false;
        }
    },
    created() {
        this.formatDateTime = helper.formatDateTime;
    },
    mounted(){
        //Gtag
        this.$gtag.screenview({
            app_name: Config.VUE_APP_APPLICATION_NAME,
            screen_name: 'partner_users_tab_svw',
        });
        this.setUserProperties();
    },
    methods: {
        setUserProperties(){
            if(this.me?.isPartnerUser){
                this.me?.allTenantPartners.forEach(c =>{
                    this.$gtag.event("partner_users_tab_svw",{
                        'tenant_id': this.me?.tenant?.id,
                        'tenant_name': this.me?.tenant?.name,
                        'partner_id': c.id,
                        'user_id': this.me?.id
                    });
                })
            }else{
                this.$gtag.event("partner_users_tab_svw", {
                    'tenant_id': this.me?.tenant?.id,
                    'tenant_name': this.me?.tenant?.name,
                    'partner_id': null,
                    'user_id': this.me?.id
                });
            }
        },
        editUser(item) {
            this.openEditUserDialog = true;
            this.$refs.editPartnerUserDialog.fillEditForm(item);
        },
        deleteUser(item) {
            this.selectedUsers = [];
            this.selectedUsers.push(item.node.id);
            this.openDeletePartnerDialog = true;
        },
        closeAndCleanDeletedUsers() {
            this.openDeletePartnerDialog = false;
            this.selectedUsers = [];
        },
        onClearReferenceNumber() {
            this.referenceNumberSearch = "";
        }
    }
};
</script>