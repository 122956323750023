<template>
    <div class="d-flex flex-row" :ripple="false">
        <!-- Left side  -->
        <v-card width="30" color="transparent" flat tile :class="mobileView.classCard" class="flex-grow-0">
            <template>
                <slot name="prepend-action"></slot>
            </template>
        </v-card>

        <!-- Main content -->
        <v-row class="flex-grow-1 ma-0">
            <v-col class="py-0" cols="12" md="12">
                <v-row class="py-1" dense>
                    <v-col
                        v-for="(item, idxc) in items"
                        :key="idxc"
                        :cols="item.col.xs || 12"
                        :sm="item.col.sm"
                        :md="item.col.md"
                        :lg="item.col.lg"
                        :xl="item.col.xl"
                        class="px-2"
                        :style="!(screenWidth < 1295) ? 'flex-basis: 14.2%; max-width: 14.2%;' : ''"
                    >
                        <div class="text-truncate">
                            <div :style="item.style">
                                <div class="text-uppercase user-select-text">
                                    <v-tooltip bottom v-if="item.value">
                                        <template v-slot:activator="{ on, attrs }">
                                            <div v-bind="attrs" v-on="on">
                                                <template v-if="!useIcons">
                                                    <div class="secondaryText font-weight-thin pb-1">
                                                        {{ item.title }}
                                                    </div>
                                                    <div class="primaryText font-weight-bold">{{ item.value }}</div>
                                                </template>
                                                <div v-else class="classText d-flex align-center">
                                                    <v-icon size="12" class="mr-1">{{ item.icon }}</v-icon>
                                                    <span v-if="item.value">{{ item.value }}</span>
                                                </div>
                                            </div>
                                        </template>
                                        <span v-if="item.value" class="class-text-tooltip user-select-text">{{ item.value }}</span>
                                    </v-tooltip>
                                    <div v-else>
                                        <div class="secondaryText font-weight-thin pb-1">
                                                        {{ item.title }}
                                        </div>
                                        <div class="primaryText font-weight-bold">
                                            
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </v-col>
                </v-row>
            </v-col>

            <!-- Right side -->
            <!-- <v-col v-if="!isSmallScreen" cols="1" class="d-flex align-center justify-center pa-0">
                <v-row :class="mobileView.headerRow" dense class="ma-0">
                    
                    <v-col v-if="!mobileBreakPoint" cols="4" class="d-flex justify-center mt-2">
                        <slot name="append-action"></slot>
                    </v-col>
                </v-row>
            </v-col>  -->
        </v-row>
    </div>
</template>

<script>
export default {
    name: "TripHeaderTemplate",
    props: {
        items: {
            type: Array,
            required: false,
            default: () => {
                return [];
            }
        },
        actionCard: {
            type: Array,
            required: false,
            default: () => {
                return [];
            }
        },
        // headers: {
        //     type: Array,
        //     required: false,
        //     default: () => {
        //         return [];
        //     }
        // },
        useIcons: {
            type: Boolean,
            required: false,
            default: false
        }
    },
    components: {},
    data() {
        return {};
    },
    computed: {
        // list() {
        //     let _list = this.items;

        //     _list = _list.sort((a, b) => {
        //         let indexA = this.headers.findIndex(header => header.code === `node.${a.tag}`);
        //         let indexB = this.headers.findIndex(header => header.code === `node.${b.tag}`);
        //         return indexA - indexB;
        //     });

        //     _list = _list.filter(item => {
        //         let header = this.headers.find(header => header.code === `node.${item.tag}`);
        //         return header?.enabled;
        //     });

        //     return _list;
        // },
        mobileBreakPoint() {
            let screen = this.$vuetify.breakpoint.width;
            //'mobile' : 'desktop'
            let isLessThan = screen < 1395;
            return isLessThan;
        },
        isSmallScreen() {
            return this.$vuetify.breakpoint.width < 960;
        },
        mobilePhone() {
            return this.$vuetify.breakpoint.width < 700;
        },
        screenWidth() {
            let _resposiveView = this.$vuetify.breakpoint.width;
            return _resposiveView;
        },
        mobileView() {
            let content = {
                widthCard: this.isSmallScreen ? (this.mobilePhone ? "90px" : "60px") : "30px",
                classCard: "d-flex align-center mr-1",
                headerRow: !this.mobileBreakPoint ? "d-flex justify-center" : "pl-1",
                headerCol: this.mobileBreakPoint ? "py-1" : "pa-0 mt-2 d-flex align-center"
            };

            return content;
        }
    },
    watch: {},
    mounted() {},
    methods: {
        clickBehavior() {
            this.$emit("on-click");
        }
    }
};
</script>
<style scoped lang="css">
.row-pointer >>> tbody tr :hover {
    cursor: pointer;
}
.center-loading {
    position: sticky;
    left: 50% !important;
}
.textTruncate {
    overflow: hidden;
    text-overflow: ellipsis;
    white-space: nowrap;
}
.classMainContent {
    padding-left: 4px !important;
    padding-right: 4px !important;
    margin-top: 1px !important;
    margin-bottom: 16px !important;
}
.classSecondaryContent {
    padding-left: 40px !important;
    padding-right: 40px !important;
    margin-top: 10px !important;
    margin-bottom: 10px !important;
}

.secondaryRowSize {
    width: 100% !important;
}

.classHeader {
    color: #555464 !important;
    white-space: nowrap;
    font-size: 12px;
    font-weight: 400;
    line-height: 14px;
    letter-spacing: 0em;
    text-align: left;
}

.class-text-tooltip {
    /* color: #0e0d35 !important; */
    white-space: nowrap;
    overflow: hidden;
    text-overflow: ellipsis;
    font-size: 12px;
    font-weight: 700;
    line-height: 13px;
    letter-spacing: 0em;
    text-align: left;
    margin-top: 3px;
}

.classText {
    color: #0e0d35 !important;
    font-size: 12px;
    font-weight: 700;
    line-height: 13px;
    letter-spacing: 0em;
    text-align: left;
    margin-top: 3px;
}

.textTitle {
    font-size: 32px !important;
    color: "#0e1e46" !important;
    font-weight: bold;
    word-break: normal !important;
    line-height: inherit !important;
    text-align: center !important;
}
.textSubtitle {
    font-size: 13px !important;
    color: "#868599" !important;
    height: 23px !important;
    font-weight: bold;
    white-space: nowrap;
    overflow: hidden;
    text-overflow: ellipsis;
}

.outlineD {
    border: 1px solid #e6e9f5;
    border-radius: 4px;
    padding: 0px 0px;
    font-size: 12px;
    font-weight: 600;
}

.backgroundColor {
    background-color: #f9fafe !important;
}

.textLeft {
    text-align: left !important;
}

.tabItemCl {
    background-color: var(--v-background-base);
}

.disabledItem {
    pointer-events: none;
    opacity: 0.4;
}

.classHeader2 {
    color: #fff !important;
    white-space: nowrap;
    overflow: hidden;
    text-overflow: ellipsis;
    font-size: 12px;
    font-weight: 400;
    line-height: 14px;
    letter-spacing: 0em;
    text-align: left;
}

.classText2 {
    color: #fff !important;
    white-space: nowrap;
    overflow: hidden;
    text-overflow: ellipsis;
    font-size: 12px;
    font-weight: 700;
    line-height: 13px;
    letter-spacing: 0em;
    text-align: left;
    margin-top: 3px;
}
</style>
