<template>
    <v-hover>
        <template v-slot="{ hover }">
            <v-card
                class="py-5"
                :class="shouldStickyFilter ? 'sticky-card' : ''"
                :elevation="hover ? 5 : 3"
                outlined
            >
                <v-row align="start" class="px-5 d-flex align-center">
                    <v-col cols="12" sm="10" lg="10">
                        <v-row>
                            <v-col
                                cols="auto"
                                v-for="f in dynamicFilters"
                                :key="f"
                                :class="getFilterChoiceClassByCode(f)"
                            >
                                <!--!Trip Reference Number -->
                                <v-text-field
                                    v-if="f === 'referenceNumber'"
                                    v-model="filter.bookingReferenceIcontains"
                                    :label="
                                        getFilterChoiceNameByCode(
                                            'referenceNumber'
                                        )
                                    "
                                    clearable
                                    hide-details
                                    dense
                                    class="maxWidth"
                                ></v-text-field>

                                <!--! Customer Order Number -->
                                <!-- <v-text-field
                                    v-if="f === 'orderNumber'"
                                    v-model="
                                        filter.customerOrderNumberIcontains
                                    "
                                    :label="
                                        getFilterChoiceNameByCode('orderNumber')
                                    "
                                    clearable
                                    hide-details
                                    dense
                                    class="maxWidth"
                                ></v-text-field> -->

                                <!--! Exporter Reference Number -->
                                <v-text-field
                                    v-if="f === 'exporterReferenceNumber'"
                                    v-model="
                                        filter.exporterReferenceNumberIcontains
                                    "
                                    :label="
                                        getFilterChoiceNameByCode('exporterReferenceNumber')
                                    "
                                    clearable
                                    hide-details
                                    dense
                                    class="maxWidth"
                                ></v-text-field>

                                <!--! Container Number -->
                                <v-text-field
                                    v-if="f === 'containerNumber'"
                                    v-model="
                                        filter.containerNumberIcontains
                                    "
                                    :label="
                                        getFilterChoiceNameByCode('containerNumber')
                                    "
                                    clearable
                                    hide-details
                                    dense
                                    class="maxWidth"
                                ></v-text-field>

                                <!--! Cto Number -->
                                <v-text-field
                                    v-if="f === 'ctoNumber'"
                                    v-model="
                                        filter.ctoNumberIcontains
                                    "
                                    :label="
                                        getFilterChoiceNameByCode('ctoNumber')
                                    "
                                    clearable
                                    hide-details
                                    dense
                                    class="maxWidth"
                                ></v-text-field>

                                <!--! tracker Number -->
                                <v-text-field
                                    v-if="f === 'trackerNumber'"
                                    v-model="
                                        filter.trackerNumberIcontains
                                    "
                                    :label="
                                        getFilterChoiceNameByCode('trackerNumber')
                                    "
                                    clearable
                                    hide-details
                                    dense
                                    class="maxWidth"
                                ></v-text-field>

                                <!--! Customer -->
                                <v-autocomplete
                                    v-if="f === 'customer'"
                                    v-model="filter.customerId"
                                    :items="customers"
                                    :label="getFilterChoiceNameByCode('customer')"
                                    :loading="$apollo.queries.customers.loading"
                                    @focus="$apollo.queries.customers.skip = false"
                                    item-text="Name"
                                    item-value="ConsigneeID"
                                    cache-items
                                    flat
                                    dense
                                    clearable
                                    class="maxWidth"
                                    :no-data-text="
                                        $apollo.queries.customers.loading
                                        ? 'Loading...'
                                            : 'No Data Available'
                                    "
                                ></v-autocomplete>

                                <!--! Exporter -->
                                <v-autocomplete
                                    v-if="f === 'exporter'"
                                    v-model="filter.exporterId"
                                    :items="exporters"
                                    :label="getFilterChoiceNameByCode('exporter')"
                                    :loading="$apollo.queries.exporters.loading"
                                    @focus="$apollo.queries.exporters.skip = false"
                                    item-text="ShipperDescription"
                                    item-value="ShipperCode"
                                    cache-items
                                    flat
                                    dense
                                    clearable
                                    class="maxWidth"
                                    :no-data-text="
                                        $apollo.queries.exporters.loading
                                        ? 'Loading...'
                                            : 'No Data Available'
                                    "
                                ></v-autocomplete>

                                <!--! Receiver -->
                                <v-autocomplete
                                    v-if="f === 'receiver'"
                                    v-model="filter.receiverId"
                                    :items="receivers"
                                    :label="getFilterChoiceNameByCode('receiver')"
                                    :loading="$apollo.queries.receivers.loading"
                                    @focus="$apollo.queries.receivers.skip = false"
                                    item-text="Name"
                                    item-value="ReceiverID"
                                    cache-items
                                    flat
                                    dense
                                    clearable
                                    class="maxWidth"
                                    :no-data-text="
                                        $apollo.queries.receivers.loading
                                        ? 'Loading...'
                                            : 'No Data Available'
                                    "
                                ></v-autocomplete>

                                <!--! Agent Codes -->
                                <v-autocomplete
                                    v-if="f === 'agentCode'"
                                    v-model="filter.agentCodeId"
                                    :items="agentCodes"
                                    :label="getFilterChoiceNameByCode('agentCode')"
                                    :loading="$apollo.queries.agentCodes.loading"
                                    @focus="$apollo.queries.agentCodes.skip = false"
                                    item-text="AgentDescription"
                                    item-value="AgentCode"
                                    cache-items
                                    flat
                                    dense
                                    clearable
                                    class="maxWidth"
                                    :no-data-text="
                                        $apollo.queries.agentCodes.loading
                                        ? 'Loading...'
                                            : 'No Data Available'
                                    "
                                ></v-autocomplete>

                                <!--! Voyage -->
                                <v-text-field
                                    v-if="f === 'voyageNumber'"
                                    v-model="filter.voyageNoIcontains"
                                    :label="
                                        getFilterChoiceNameByCode(
                                            'voyageNumber'
                                        )
                                    "
                                    clearable
                                    hide-details
                                    class="maxWidth"
                                    dense
                                ></v-text-field>

                                <!-- ! Contract Number -->
                                <v-text-field
                                    v-if="f === 'contractNumber'"
                                    v-model="filter.contractNumberIcontains"
                                    :label="
                                        getFilterChoiceNameByCode(
                                            'contractNumber'
                                        )
                                    "
                                    clearable
                                    class="maxWidth"
                                    hide-details
                                    dense
                                ></v-text-field>

                                <!-- ! Final Destination -->
                                <v-autocomplete
                                    v-if="f === 'finalDestination'"
                                    v-model="filter.finalDestinationId"
                                    :items="ports"
                                    :label="getFilterChoiceNameByCode('finalDestination')"
                                    :loading="$apollo.queries.ports.loading"
                                    @focus="$apollo.queries.ports.skip = false"
                                    item-text="Description"
                                    item-value="Code"
                                    cache-items
                                    flat
                                    dense
                                    clearable
                                    class="maxWidth"
                                    :no-data-text="
                                        $apollo.queries.ports.loading
                                        ? 'Loading...'
                                            : 'No Data Available'
                                    "
                                ></v-autocomplete>

                                <!-- ! POL Search -->
                                <v-autocomplete
                                    v-if="f === 'pol'"
                                    v-model="filter.polId"
                                    :items="ports"
                                    :label="getFilterChoiceNameByCode('pol')"
                                    :loading="$apollo.queries.ports.loading"
                                    @focus="$apollo.queries.ports.skip = false"
                                    item-text="Description"
                                    item-value="Code"
                                    cache-items
                                    flat
                                    dense
                                    clearable
                                    class="maxWidth"
                                    :no-data-text="
                                        $apollo.queries.ports.loading
                                        ? 'Loading...'
                                            : 'No Data Available'
                                    "
                                ></v-autocomplete>

                                <!-- ! pod search  -->
                                <v-autocomplete
                                    v-if="f === 'pod'"
                                    v-model="filter.podId"
                                    :items="ports"
                                    :label="getFilterChoiceNameByCode('pod')"
                                    :loading="$apollo.queries.ports.loading"
                                    @focus="$apollo.queries.ports.skip = false"
                                    item-text="Description"
                                    item-value="Code"
                                    cache-items
                                    flat
                                    dense
                                    clearable
                                    class="maxWidth"
                                    :no-data-text="
                                        $apollo.queries.ports.loading
                                        ? 'Loading...'
                                            : 'No Data Available'
                                    "
                                ></v-autocomplete>

                                <!-- ! Order Status -->
                                <v-select
                                    v-if="f === 'status'"
                                    v-model="filter.orderStatus"
                                    :items="statusChoices"
                                    :label="getFilterChoiceNameByCode('status')"
                                    item-text="text"
                                    item-value="value"
                                    hide-details
                                    dense
                                    clearable
                                    class="maxWidth"
                                    :menu-props="{
                                        zIndex: 2,
                                        offsetY: true
                                    }"
                                ></v-select>

                                <!-- ! Vessel -->
                                <v-autocomplete
                                    v-if="f === 'vessel'"
                                    v-model="filter.vesselNameId"
                                    :items="vessels"
                                    :label="getFilterChoiceNameByCode('vessel')"
                                    :loading="$apollo.queries.vessels.loading"
                                    @focus="$apollo.queries.vessels.skip = false"
                                    item-text="Description"
                                    item-value="Code"
                                    cache-items
                                    flat
                                    dense
                                    clearable
                                    class="maxWidth"
                                    :no-data-text="
                                        $apollo.queries.vessels.loading
                                        ? 'Loading...'
                                            : 'No Data Available'
                                    "
                                ></v-autocomplete>

                                <!-- ! Shipping Line -->
                                <v-autocomplete
                                    v-if="f === 'shippingLine'"
                                    v-model="filter.shippingLineId"
                                    :items="shippingLines"
                                    :label="getFilterChoiceNameByCode('shippingLine')"
                                    :loading="$apollo.queries.shippingLines.loading"
                                    @focus="$apollo.queries.shippingLines.skip = false"
                                    item-text="Description"
                                    item-value="Code"
                                    cache-items
                                    flat
                                    dense
                                    clearable
                                    class="maxWidth"
                                    :no-data-text="
                                        $apollo.queries.shippingLines.loading
                                        ? 'Loading...'
                                            : 'No Data Available'
                                    "
                                ></v-autocomplete>

                                <!-- ! Transport type -->
                                <v-autocomplete
                                    v-if="f === 'transportType'"
                                    v-model="filter.transportType"
                                    :label="getFilterChoiceNameByCode('transportType')"
                                    :items="transportTypeList"
                                    item-text="text"
                                    item-value="value"
                                    flat
                                    hide-no-data
                                    dense
                                    clearable
                                    class="maxWidth"
                                ></v-autocomplete>

                                <!-- ! Order Date -->
                                <DateRangeComponent
                                    v-if="f === 'orderDate'"
                                    v-model="orderDate"
                                    :label="
                                        getFilterChoiceNameByCode('orderDate')
                                    "
                                    clearable
                                    hide-details
                                    dense
                                    class="maxWidth"
                                ></DateRangeComponent>

                                <!-- ! Load Date -->
                                <DateRangeComponent
                                    v-if="f === 'loadDate'"
                                    v-model="loadDate"
                                    :label="
                                        getFilterChoiceNameByCode('loadDate')
                                    "
                                    clearable
                                    hide-details
                                    dense
                                    class="maxWidth"
                                ></DateRangeComponent>

                                <!-- !Commodity -->
                                <v-autocomplete
                                    v-if="f === 'commodity'"
                                    v-model="filter.commodityId"
                                    :label="getFilterChoiceNameByCode('commodity')"
                                    :items="commodities"
                                    :loading="$apollo.queries.commodities.loading"
                                    @focus="$apollo.queries.commodities.skip = false"
                                    item-text="CommodityDescription"
                                    item-value="CommodityCode"
                                    flat
                                    hide-no-data
                                    dense
                                    clearable
                                    class="maxWidth"
                                    :no-data-text="
                                        $apollo.queries.commodities.loading
                                        ? 'Loading...'
                                            : 'No Data Available'
                                    "
                                ></v-autocomplete>

                                <!-- ! stack status -->
                                <v-select
                                    v-if="f === 'stackStatus'"
                                    v-model="filter.stackStatus"
                                    :items="stackStatusList"
                                    :label="getFilterChoiceNameByCode('stackStatus')"
                                    item-text="text"
                                    item-value="value"
                                    hide-details
                                    dense
                                    clearable
                                    class="maxWidth"
                                    :menu-props="{
                                        zIndex: 2,
                                        offsetY: true
                                    }"
                                ></v-select>

                                <!-- ! Steri -->
                                <v-autocomplete
                                    v-if="f === 'steri'"
                                    v-model="filter.steri"
                                    placeholder="Select Steri"
                                    :label="getFilterChoiceNameByCode('steri')"
                                    :items="steriList"
                                    cache-items
                                    item-text="text"
                                    item-value="value"
                                    flat
                                    hide-no-data
                                    dense
                                    clearable
                                    class="maxWidth"
                                ></v-autocomplete>

                                <!-- ! container type -->
                                <v-autocomplete
                                    v-if="f === 'containerType'"
                                    v-model="filter.containerTypeId"
                                    :items="containerTypes"
                                    :label="getFilterChoiceNameByCode('containerType')"
                                    :loading="$apollo.queries.containerTypes.loading"
                                    @focus="$apollo.queries.containerTypes.skip = false"
                                    item-text="Description"
                                    item-value="Code"
                                    cache-items
                                    flat
                                    dense
                                    clearable
                                    class="maxWidth"
                                    :no-data-text="
                                        $apollo.queries.containerTypes.loading
                                        ? 'Loading...'
                                            : 'No Data Available'
                                    "
                                ></v-autocomplete>


                                <!-- !Order by -->
                                <!-- <v-text-field
                                    v-if="f === 'orderBy'"
                                    v-model="filter.orderByIcontains"
                                    :label="getFilterChoiceNameByCode('orderBy')"
                                    clearable
                                    hide-details
                                    dense
                                    class="maxWidth"
                                ></v-text-field> -->

                                <!-- !Inco term -->
                                <v-select
                                    v-if="f === 'incoTerm'"
                                    v-model="filter.incoTerm"
                                    :items="terms"
                                    :label="getFilterChoiceNameByCode('incoTerm')"
                                    item-text="text"
                                    item-value="value"
                                    hide-details
                                    dense
                                    clearable
                                    class="maxWidth"
                                    :menu-props="{
                                        zIndex: 2,
                                        offsetY: true
                                    }"
                                ></v-select>
                            </v-col>
                            <!-- !Sort By -->
                            <v-col class="col-auto col-md-4 col-lg-3 col-xl-3">
                                <v-row class="d-flex align-center ma-0 pa-0">
                                    <v-select
                                        v-model="sortBy"
                                        :items="sortByChoices"
                                        :label="$t('general.sort_by')"
                                        :item-value="item => item.code"
                                        :item-text="item => item.name"
                                        clearable
                                        hide-details
                                        dense
                                        color="primary"
                                        :menu-props="{
                                            zIndex: 2,
                                            offsetY: true
                                        }"
                                    >
                                        <template v-slot:append-outer>
                                            <v-btn
                                                class="mt-n1"
                                                small
                                                icon
                                                :disabled="!sortBy"
                                                @click="sortAsc = !sortAsc"
                                            >
                                                <v-icon color="grey darken-2">{{
                                                    sortAsc ? "north" : "south"
                                                }}</v-icon>
                                            </v-btn>
                                        </template>
                                    </v-select>
                                </v-row>
                            </v-col>
                        </v-row>
                    </v-col>
                    <!-- !Filters Menu -->
                     <v-spacer></v-spacer>
                    <v-col cols="auto">
                        <v-row 
                            class="d-flex align-center justify-end"
                        >
                            <!-- !Create Booking -->
                            <v-col cols="auto" class="d-flex justify-end align-center">
                                <CreateAndEditBooking
                                    :editMode="false"
                                    :templateMode="false"
                                    v-on:add-booking="onBookingCreated"
                                ></CreateAndEditBooking>
                            </v-col>
                            <v-col cols="auto" class="px-1">
                                <FilterMenu
                                    title="Customize"
                                    description="Select filters to display"
                                    :choices="_filterChoices"
                                    :open-on-hover="false"
                                    height="500px"
                                    width="250px"
                                    v-on:changed="onFilterMenuChanged"
                                >
                                </FilterMenu>
                            </v-col>
                        </v-row>
                    </v-col>
                </v-row>
            </v-card>
        </template>
    </v-hover>
</template>

<script>
import helper from "@/utils/helper.js";
/* import moment from "moment"; */
import FilterMenu from "@/components/base/FilterMenu.vue";
// import DateComponent from "@/components/base/DateComponent.vue";
import DateRangeComponent from "@/components/base/DateRangeComponent.vue";
import CreateAndEditBooking from "@/components/bookings/create/CreateAndEditBooking.vue";
import gql from "graphql-tag";
import { transportTypeList, statusChoices, stackStatusList, steriList, terms } from "@/components/bookings/constants/bookingConstants";

export default {
    name: "BookingFilter",

    components: {
        FilterMenu,
        // DateComponent,
        CreateAndEditBooking,
        DateRangeComponent
    },
    props: {
        filterCode: {
            type: String,
            default: ""
        },
        bookingFilter: {
            type: Object,
            default: () => {
                return {};
            }
        }
    },

    data() {
        return {
            fab: false,
            isLoading: false,
            sortAsc: true,
            shouldStickyFilter: false,
            filter: {
                first: 10,
                after: null,
                orderBy: '-createdAt',
                bookingReferenceIcontains: null,
                customerOrderNumberIcontains: null,
                customerId: null,
                customerIcontains: null,
                exporterId: null,
                exporterIcontains: null,
                receiverId: null,
                receiverIcontains: null,
                voyageNoIcontains: null,
                contractNumberIcontains: null,
                finalDestination: null,
                finalDestinationIcontains: null,
                orderStatus: null,
                polId: null,
                pol: null,
                transportType: null,
                podId: null,
                pod: null,
                vesselNameId: null,
                vesselNameIcontains: null,
                stackStatus: null,
                steri: null,
                incoTerm: null,
                orderByIcontains: null,
                commodityId: null,
                commodityIcontains: null,
                containerTypeId: null,
                containerTypeIcontains: null,
                orderDateLte: null,
                orderDateGte: null,
                loadDateLte: null,
                loadDateGte: null,
                shippingLineId: null,
                agentCodeId: null,
                containerNumberIcontains: null,
                ctoNumberIcontains: null,
                trackerNumberIcontains: null
            },
            // refreshTableVessel: false,
            // refreshTablePorts: false,
            filterChoices: null,
            defaultFilterChoices: [
                {
                    // for filter menu
                    code: "referenceNumber",
                    name: "Reference Number",
                    tag: "bookings.booking_reference",
                    enabled: true,
                    // for filter layout
                    class: "col-auto col-md-3 col-lg-2",
                    filterChoices: ["bookingReferenceIcontains"]
                },
                // {
                //     // for filter menu
                //     code: "orderNumber",
                //     name: "Order No.",
                //     tag: "bookings.order_no",
                //     enabled: false,
                //     // for filter layout
                //     class: "col-auto col-md-3 col-lg-2"
                // },
                {
                    // for filter menu
                    code: "exporterReferenceNumber",
                    name: "Exporter Reference No.",
                    tag: "bookings.exporter_ref_no",
                    enabled: false,
                    // for filter layout
                    class: "col-auto col-md-3 col-lg-2",
                    filterChoices: ["exporterReferenceNumberIcontains"]
                },
                {
                    // for filter menu
                    code: "customer",
                    name: "Customer",
                    tag: "bookings.customer",
                    enabled: false,
                    // for filter layout
                    class: "col-auto col-md-3 col-lg-2",
                    filterChoices: ["customerId","customerIcontains"]
                },
                {
                    // for filter menu
                    code: "exporter",
                    name: "Exporter",
                    tag: "bookings.exporter",
                    enabled: false,
                    // for filter layout
                    class: "col-auto col-md-3 col-lg-2",
                    filterChoices: ["exporterId","exporterIcontains"]
                },
                {
                    // for filter menu
                    code: "receiver",
                    name: "Receiver",
                    tag: "bookings.receiver",
                    enabled: false,
                    // for filter layout
                    class: "col-auto col-md-3 col-lg-2",
                    filterChoices: ["receiverId","receiverIcontains"]
                },
                {
                    // for filter menu
                    code: "voyageNumber",
                    name: "Voyage No.",
                    tag: "bookings.voyage",
                    enabled: false,
                    // for filter layout
                    class: "col-auto col-md-3 col-lg-2",
                    filterChoices: ["voyageNoIcontains"]
                },
                {
                    // for filter menu
                    code: "contractNumber",
                    name: "Contract No.",
                    tag: "bookings.contract_no",
                    enabled: false,
                    // for filter layout
                    class: "col-auto col-md-3 col-lg-2",
                    filterChoices: ["contractNumberIcontains"]
                },
                {
                    // for filter menu
                    code: "status",
                    name: "Order Status",
                    tag: "bookings.order_status",
                    enabled: true,
                    // for filter layout
                    class: "col-auto col-md-auto",
                    filterChoices: ["orderStatus"]
                },
                {
                    code: "pol",
                    name: "POL Code",
                    tag: "bookings.pol",
                    class: "col-auto col-md-3 col-lg-2",
                    enabled: false,
                    filterChoices: ["polId","pol"]
                },
                {
                    code: "pod",
                    name: "POD Code",
                    tag: "bookings.pod",
                    class: "col-auto col-md-3 col-lg-2",
                    enabled: false,
                    filterChoices: ["podId","pod"]
                },

                {
                    // for filter menu
                    code: "vessel",
                    name: "Vessel Name",
                    tag: "bookings.vessel",
                    enabled: false,
                    // for filter layout
                    class: "col-auto col-md-3 col-lg-2",
                    filterChoices: ["vesselNameId","vesselNameIcontains"]
                },
                {
                    // for filter menu
                    code: "transportType",
                    name: "Transport Type",
                    tag: "bookings.transport_type",
                    enabled: false,
                    // for filter layout
                    class: "col-auto col-md-3 col-lg-2",
                    filterChoices: ["transportType"]
                },
                {
                    // for filter menu
                    code: "stackStatus",
                    name: "Stack Status",
                    tag: "bookings.stack_status",
                    enabled: false,
                    // for filter layout
                    class: "col-auto col-md-3 col-lg-2",
                    filterChoices: ["stackStatus"]
                },
                {
                    // for filter menu
                    code: "steri",
                    name: "Steri",
                    tag: "bookings.steri",
                    enabled: false,
                    // for filter layout
                    class: "col-auto col-md-3 col-lg-2",
                    filterChoices: ["steri"]
                },
                {
                    // for filter menu
                    code: "incoTerm",
                    name: "Inco Term",
                    tag: "bookings.inco_term",
                    enabled: false,
                    // for filter layout
                    class: "col-auto col-md-3 col-lg-2",
                    filterChoices: ["incoTerm"]
                },
                {
                    // for filter menu
                    code: "commodity",
                    name: "Commodity",
                    tag: "bookings.commodity",
                    enabled: false,
                    // for filter layout
                    class: "col-auto col-md-3 col-lg-2",
                    filterChoices: ["commodityId","commodityIcontains"]
                },
                {
                    // for filter menu
                    code: "containerType",
                    name: "Container Type",
                    tag: "bookings.container_type",
                    enabled: false,
                    // for filter layout
                    class: "col-auto col-md-3 col-lg-2",
                    filterChoices: ["containerTypeId","containerTypeIcontains"]
                },
                // {
                //     // for filter menu
                //     code: "orderBy",
                //     name: "Order By",
                //     tag: "bookings.order_by",
                //     enabled: false,
                //     // for filter layout
                //     class: "col-auto col-md-3 col-lg-2"
                // },
                {
                    // for filter menu
                    code: "loadDate",
                    name: "Load Date",
                    tag: "bookings.load_date",
                    enabled: false,
                    // for filter layout
                    class: "col-auto col-md-3 col-lg-2",
                    filterChoices: ["loadDateLte","loadDateGte"]
                },
                {
                    // for filter menu
                    code: "orderDate",
                    name: "Order Date",
                    tag: "bookings.order_date",
                    enabled: false,
                    // for filter layout
                    class: "col-auto col-md-3 col-lg-2",
                    filterChoices: ["orderDateLte","orderDateGte"]
                },
                {
                    // for filter menu
                    code: "shippingLine",
                    name: "Shipping Line",
                    tag: "bookings.shipping_line",
                    enabled: false,
                    // for filter layout
                    class: "col-auto col-md-3 col-lg-2",
                    filterChoices: ["shippingLineId"]
                },
                {
                    // for filter menu
                    code: "agentCode",
                    name: "Agent Code",
                    tag: "bookings.agent_code",
                    enabled: false,
                    // for filter layout
                    class: "col-auto col-md-3 col-lg-2",
                    filterChoices: ["agentCodeId","agentCodeIcontains"]
                },
                {
                    // for filter menu
                    code: "containerNumber",
                    name: "Container Number",
                    tag: "bookings.container_number",
                    enabled: false,
                    // for filter layout
                    class: "col-auto col-md-3 col-lg-2",
                    filterChoices: ["containerNumberIcontains"]
                },
                {
                    // for filter menu
                    code: "trackerNumber",
                    name: "Tracker Number",
                    tag: "bookings.tracker_number",
                    enabled: false,
                    // for filter layout
                    class: "col-auto col-md-3 col-lg-2",
                    filterChoices: ["trackerNumberIcontains"]
                },
                {
                    // for filter menu
                    code: "ctoNumber",
                    name: "CTO Number",
                    tag: "bookings.cto_number",
                    enabled: false,
                    // for filter layout
                    class: "col-auto col-md-3 col-lg-2",
                    filterChoices: ["ctoNumberIcontains"]
                }
            ],

            sortByChoices: [
                {
                    code: "bookingReference",
                    name: "Booking Reference No."
                },
                // {
                //     code: "customerOrderNumber",
                //     name: "Customer Order No."
                // },
                // {
                //     code: "ExporterReferenceNumber",
                //     name: "Exporter Reference No."
                // },
                {
                    code: "orderStatus",
                    name: "Status"
                },
                {
                    code: "orderDate",
                    name: "Order Date"
                },
                {
                    code: "agentCode",
                    name: "Agent Code"
                },
                {
                    code: "createdAt",
                    name: "Date of Creation"
                }
            ],

            sortBy: null,
            statusChoices: statusChoices,

            transportModeChoices: [
                { text: "Contour", value: "contour" }
                /* { text: "Air", value: "air" },
                { text: "Road", value: "road" },
                { text: "Rail", value: "rail" },
                { text: "Sea", value: "sea" } */
            ],

            steriList: steriList,

            transportTypeList: transportTypeList,

            stackStatusList: stackStatusList,

            terms: terms
        };
    },

    apollo: {
        customers: {
            query: gql`
                query QxCustomers($consigneeId: String) {
                    qxCustomers(consigneeId: $consigneeId) {
                        ConsigneeID
                        Currency
                        DealTypeDescription
                        Name
                    }
                }
            `,
            variables() {
                // IMPORTANT: add same variables as refetch and fetchMore, otherwise merge result may contain duplicate keys
                return {
                    // consigneeId: ""
                };
            },
            // Additional options here
            fetchPolicy: "cache-first",
            nextFetchPolicy: "cache-and-network", // this setting can avoid query again after fetchMore
            update: data => data.qxCustomers,
            watchLoading(isLoading) {
                this.$emit("loading", isLoading);
            },
            skip: true
        },
        exporters: {
            query: gql`
                query QxExporters($consigneeId: String) {
                    qxExporters(consigneeId: $consigneeId) {
                        ConsigneeID
                        ConsigneeName
                        ShipperCode
                        ShipperDescription
                    }
                }
            `,
            variables() {
                // IMPORTANT: add same variables as refetch and fetchMore, otherwise merge result may contain duplicate keys
                return {
                    // consigneeId: ""
                };
            },
            // Additional options here
            fetchPolicy: "cache-first",
            nextFetchPolicy: "cache-and-network", // this setting can avoid query again after fetchMore
            update: data => data.qxExporters,
            watchLoading(isLoading) {
                this.$emit("loading", isLoading);
            },
            skip: true
        },
        receivers: {
            query: gql`
                query QxReceivers($consigneeId: String, $receiverId: String) {
                    qxReceivers(consigneeId: $consigneeId, receiverId: $receiverId) {
                        ConsigneeID
                        ConsigneeReceiverID
                        Name
                        ReceiverID
                    }
                }
            `,
            variables() {
                // IMPORTANT: add same variables as refetch and fetchMore, otherwise merge result may contain duplicate keys
                return {
                    // "consigneeId": null,
                    // "receiverId": null
                };
            },
            // Additional options here
            fetchPolicy: "cache-first",
            nextFetchPolicy: "cache-and-network", // this setting can avoid query again after fetchMore
            update: data => data.qxReceivers,
            watchLoading(isLoading) {
                this.$emit("loading", isLoading);
            },
            skip: true
        },
        agentCodes: {
            query: gql`
                query QxAgentCodes($receiverId: String, $consigneeId: String) {
                    qxAgentCodes(receiverId: $receiverId, consigneeId: $consigneeId) {
                        AgentID
                        AgentCode
                        AgentDescription
                        ConsigneeID
                        ConsigneeReceiverID
                        ReceiverID
                    }
                }
            `,
            variables() {
                // IMPORTANT: add same variables as refetch and fetchMore, otherwise merge result may contain duplicate keys
                return {
                    "receiverId": null,
                    "consigneeId": null
                };
            },
            // Additional options here
            fetchPolicy: "cache-first",
            nextFetchPolicy: "cache-and-network", // this setting can avoid query again after fetchMore
            update: data => data.qxAgentCodes,
            watchLoading(isLoading) {
                this.$emit("loading", isLoading);
            },
            skip: true
        },
        availableVessels: {
            query: gql`
                query QxAvailableVesselBookings($vesselBookingId: Int, $vesselCode: String, $polCode: String, $podCode: String) {
                    qxAvailableVesselBookings(vesselBookingId: $vesselBookingId, vesselCode: $vesselCode, polCode: $polCode, podCode: $podCode) {
                        VesselBookingID
                        VesselCode
                        StackOpenDate
                        StackCloseDate
                        StackStatus
                        ShippingNo
                        ETD
                        ETA
                        PortOfLoadingCode
                        PortOfDischargeCode
                        VoyageNumber
                    }
                }
            `,
            variables() {
                // IMPORTANT: add same variables as refetch and fetchMore, otherwise merge result may contain duplicate keys
                return {
                    // "vesselBookingId": null,
                    // "vesselCode": null,
                    // "polCode": null,
                    // "podCode": null
                };
            },
            // Additional options here
            fetchPolicy: "cache-first",
            nextFetchPolicy: "cache-and-network", // this setting can avoid query again after fetchMore
            update: data => data.qxAvailableVesselBookings,
            watchLoading(isLoading) {
                this.$emit("loading", isLoading);
            }
        },
        commodities: {
            query: gql`
                query QxCommodities($commodityId: String) {
                    qxCommodities(commodityId: $commodityId) {
                        CommodityCode
                        CommodityDescription
                    }
                }
            `,
            variables() {
                // IMPORTANT: add same variables as refetch and fetchMore, otherwise merge result may contain duplicate keys
                return {
                    // commodityId: ""
                };
            },
            // Additional options here
            fetchPolicy: "cache-first",
            nextFetchPolicy: "cache-and-network", // this setting can avoid query again after fetchMore
            update: data => data.qxCommodities,
            watchLoading(isLoading) {
                this.$emit("loading", isLoading);
            },
            skip: true
        },
        tempDevices: {
            query: gql`
                query QxTempDevices($tempDeviceId: String) {
                    qxTempDevices(tempDeviceId: $tempDeviceId) {
                        Code
                        Description
                    }
                }
            `,
            variables() {
                // IMPORTANT: add same variables as refetch and fetchMore, otherwise merge result may contain duplicate keys
                return {
                    // tempDeviceId: ""
                };
            },
            // Additional options here
            fetchPolicy: "cache-first",
            nextFetchPolicy: "cache-and-network", // this setting can avoid query again after fetchMore
            update: data => data.qxTempDevices,
            watchLoading(isLoading) {
                this.$emit("loading", isLoading);
            }
        },
        tempCodes: {
            query: gql`
                query QxTempCodes($tempCodeId: String) {
                    qxTempCodes(tempCodeId: $tempCodeId) {
                        Code
                        Description
                        Temperature
                    }
                }
            `,
            variables() {
                // IMPORTANT: add same variables as refetch and fetchMore, otherwise merge result may contain duplicate keys
                return {
                    // tempCodeId: ""
                };
            },
            // Additional options here
            fetchPolicy: "cache-first",
            nextFetchPolicy: "cache-and-network", // this setting can avoid query again after fetchMore
            update: data => data.qxTempCodes,
            watchLoading(isLoading) {
                this.$emit("loading", isLoading);
            }
        },
        containerTypes: {
            query: gql`
                query QxContainerTypes($containerTypeId: String) {
                    qxContainerTypes(containerTypeId: $containerTypeId) {
                        Code
                        Description
                    }
                }
            `,
            variables() {
                // IMPORTANT: add same variables as refetch and fetchMore, otherwise merge result may contain duplicate keys
                return {
                    // containerTypeId: ""
                };
            },
            // Additional options here
            fetchPolicy: "cache-first",
            nextFetchPolicy: "cache-and-network", // this setting can avoid query again after fetchMore
            update: data => data.qxContainerTypes,
            watchLoading(isLoading) {
                this.$emit("loading", isLoading);
            },
            skip: true
        },
        depots: {
            query: gql`
                query QxDepots($depotId: String) {
                    qxDepots(depotId: $depotId) {
                        Code
                        Description
                    }
                }
            `,
            variables() {
                // IMPORTANT: add same variables as refetch and fetchMore, otherwise merge result may contain duplicate keys
                return {
                    // depotId: ""
                };
            },
            // Additional options here
            fetchPolicy: "cache-first",
            nextFetchPolicy: "cache-and-network", // this setting can avoid query again after fetchMore
            update: data => data.qxDepots,
            watchLoading(isLoading) {
                this.$emit("loading", isLoading);
            }
        },
        logisticContacts: {
            query: gql`
                query QxLogisticContacts($userId: String) {
                    qxLogisticContacts(userId: $userId) {
                        FirstName
                        Surname
                        UserID
                        Username
                    }
                }
            `,
            variables() {
                // IMPORTANT: add same variables as refetch and fetchMore, otherwise merge result may contain duplicate keys
                return {
                    // userId: ""
                };
            },
            // Additional options here
            fetchPolicy: "cache-first",
            nextFetchPolicy: "cache-and-network", // this setting can avoid query again after fetchMore
            update: data => data.qxLogisticContacts,
            watchLoading(isLoading) {
                this.$emit("loading", isLoading);
            }
        },
        shippingLines: {
            query: gql`
                query QxShippingLines($shippingLineId: String) {
                    qxShippingLines(shippingLineId: $shippingLineId) {
                        Code
                        Description
                    }
                }
            `,
            variables() {
                // IMPORTANT: add same variables as refetch and fetchMore, otherwise merge result may contain duplicate keys
                return {
                    // "shippingLineId": null
                };
            },
            // Additional options here
            fetchPolicy: "cache-first",
            nextFetchPolicy: "cache-and-network", // this setting can avoid query again after fetchMore
            update: data => data.qxShippingLines,
            watchLoading(isLoading) {
                this.$emit("loading", isLoading);
            },
            skip: true
        },
        solasVerificationMethods: {
            query: gql`
                query QxSolasVerificationMethods($solasVerificationMethodId: String) {
                    qxSolasVerificationMethods(solasVerificationMethodId: $solasVerificationMethodId) {
                        Description
                        VerificationMethod
                    }
                }
            `,
            variables() {
                // IMPORTANT: add same variables as refetch and fetchMore, otherwise merge result may contain duplicate keys
                return {
                    // "solasVerificationMethodId": null
                };
            },
            // Additional options here
            fetchPolicy: "cache-first",
            nextFetchPolicy: "cache-and-network", // this setting can avoid query again after fetchMore
            update: data => data.qxSolasVerificationMethods,
            watchLoading(isLoading) {
                this.$emit("loading", isLoading);
            }
        },
        steriProtocols: {
            query: gql`
                query QxSteriProtocols($steriProtocolId: String) {
                    qxSteriProtocols(steriProtocolId: $steriProtocolId) {
                        Description
                        SteriProtocolID
                    }
                }
            `,
            variables() {
                // IMPORTANT: add same variables as refetch and fetchMore, otherwise merge result may contain duplicate keys
                return {
                    // "steriProtocolId": null
                };
            },
            // Additional options here
            fetchPolicy: "cache-first",
            nextFetchPolicy: "cache-and-network", // this setting can avoid query again after fetchMore
            update: data => data.qxSteriProtocols,
            watchLoading(isLoading) {
                this.$emit("loading", isLoading);
            }
        },
        transporterStatuses: {
            query: gql`
                query QxTransporterStatuses($transporterStatusId: Int) {
                    qxTransporterStatuses(transporterStatusId: $transporterStatusId) {
                        StatusID
                        Description
                        DualLoad
                    }
                }
            `,
            variables() {
                // IMPORTANT: add same variables as refetch and fetchMore, otherwise merge result may contain duplicate keys
                return {
                    // "transporterStatusId": null
                };
            },
            // Additional options here
            fetchPolicy: "cache-first",
            nextFetchPolicy: "cache-and-network", // this setting can avoid query again after fetchMore
            update: data => data.qxTransporterStatuses,
            watchLoading(isLoading) {
                this.$emit("loading", isLoading);
            }
        },
        transporters: {
            query: gql`
                query QxTransporters($transporterId: String) {
                    qxTransporters(transporterId: $transporterId) {
                        Code
                        Description
                    }
                }
            `,
            variables() {
                // IMPORTANT: add same variables as refetch and fetchMore, otherwise merge result may contain duplicate keys
                return {
                    // "transporterId": null
                };
            },
            // Additional options here
            fetchPolicy: "cache-first",
            nextFetchPolicy: "cache-and-network", // this setting can avoid query again after fetchMore
            update: data => data.qxTransporters,
            watchLoading(isLoading) {
                this.$emit("loading", isLoading);
            }
        },
        vents: {
            query: gql`
                query QxVents($ventId: String) {
                    qxVents(ventId: $ventId) {
                        Code
                        Description
                    }
                }
            `,
            variables() {
                // IMPORTANT: add same variables as refetch and fetchMore, otherwise merge result may contain duplicate keys
                return {
                    // "ventId": null
                };
            },
            // Additional options here
            fetchPolicy: "cache-first",
            nextFetchPolicy: "cache-and-network", // this setting can avoid query again after fetchMore
            update: data => data.qxVents,
            watchLoading(isLoading) {
                this.$emit("loading", isLoading);
            }
        },
        weighBridges: {
            query: gql`
                query QxWeighBridges($weighBridgeId: String) {
                    qxWeighBridges(weighBridgeId: $weighBridgeId) {
                        Code
                        Description
                    }
                }
            `,
            variables() {
                // IMPORTANT: add same variables as refetch and fetchMore, otherwise merge result may contain duplicate keys
                return {
                    // "weighBridgeId": null
                };
            },
            // Additional options here
            fetchPolicy: "cache-first",
            nextFetchPolicy: "cache-and-network",// this setting can avoid query again after fetchMore
            update: data => data.qxWeighBridges,
            watchLoading(isLoading) {
                this.$emit("loading", isLoading);
            }
        },
        ports: {
            query: gql`
                query QxPorts($portId: String) {
                    qxPorts(portId: $portId) {
                        Code
                        Description
                        TargetCountry
                        TargetRegion
                    }
                }
            `,
            variables() {
                // IMPORTANT: add same variables as refetch and fetchMore, otherwise merge result may contain duplicate keys
                return {
                    // "portId": null
                };
            },
            // Additional options here
            fetchPolicy: "cache-first",
            nextFetchPolicy: "cache-and-network", // this setting can avoid query again after fetchMore
            update: data => data.qxPorts,
            watchLoading(isLoading) {
                this.$emit("loading", isLoading);
            },
            skip: true
        },
        vessels: {
            query: gql`
                query QxVessels($vesselId: String) {
                    qxVessels(vesselId: $vesselId) {
                        Code
                        Description
                        VesselType
                    }
                }
            `,
            variables() {
                // IMPORTANT: add same variables as refetch and fetchMore, otherwise merge result may contain duplicate keys
                return {
                    // "vesselId": null
                };
            },
            // Additional options here
            fetchPolicy: "cache-first",
            nextFetchPolicy: "cache-and-network", // this setting can avoid query again after fetchMore
            update: data => data.qxVessels,
            watchLoading(isLoading) {
                this.$emit("loading", isLoading);
            },
            skip: true
        }
    },
    computed: {
        me() {
            return helper.me();
        },
        _filterChoices() {
            return this.filterChoices;
        },
        dynamicFilters() {
            return this.filterChoices?.filter(f => f.enabled).map(f => f.code);
        },
        //loadDate
        loadDate: {
            get() {
                const lte = this.filter?.loadDateLte?.slice(0, 10);
                const gte = this.filter?.loadDateGte?.slice(0, 10);
                
                if (!lte && !gte) return [];
                if (lte && gte) return [gte, lte]; // Return in chronological order
                return [lte || gte]; // Return whichever date exists
            },
            set(value) {
                if (!value || !Array.isArray(value)) {
                    this.filter.loadDateLte = null;
                    this.filter.loadDateGte = null;
                    return;
                }

                const [startDate, endDate] = value;
                this.filter.loadDateGte = startDate ? `${startDate}T00:00:00` : null;
                this.filter.loadDateLte = endDate ? `${endDate}T23:59:59` : 
                                        startDate ? `${startDate}T23:59:59` : null; 
            }
        },
        //orderDate
        orderDate: {
            get() {
                const lte = this.filter?.orderDateLte?.slice(0, 10);
                const gte = this.filter?.orderDateGte?.slice(0, 10);
                
                if (!lte && !gte) return [];
                if (lte && gte) return [gte, lte]; // Return in chronological order
                return [lte || gte]; // Return whichever date exists
            },
            set(value) {
                if (!value || !Array.isArray(value)) {
                    this.filter.orderDateLte = null;
                    this.filter.orderDateGte = null;
                    return;
                }

                const [startDate, endDate] = value;
                this.filter.orderDateGte = startDate ? `${startDate}T00:00:00` : null;
                this.filter.orderDateLte = endDate ? `${endDate}T23:59:59` : 
                                        startDate ? `${startDate}T23:59:59` : null; 
            }
        }
    },
    watch: {
        sortBy(val) {
            if (val) {
                this.filter.orderBy = this.sortAsc ? val : `-${val}`;
            } else {
                this.filter.orderBy = null;
            }
        },
        sortAsc(val) {
            if (this.sortBy) {
                this.filter.orderBy = val ? this.sortBy : `-${this.sortBy}`;
            } else {
                this.filter.orderBy = null;
            }
        }, 
        filter: {
            handler(value) {
                if (value) {
                    //check if filter in not in dynamicFilters then set to null
                    this.updateFilterOnChange(value);
                }
            },
            deep: true,
            immediate: true
        }
    },
    created() {
        this.hasPermission = helper.hasPermission;
        this.pushTo = helper.pushTo;
        let localFilterChoices = helper.getFilterChoices(
            this.defaultFilterChoices,
            "bookingTableFilters"
        );
        if(localFilterChoices){
            //check 
            this.filterChoices = localFilterChoices;
        }
    },
    mounted() {
        this.sortBy = "createdAt";
        let _filter = helper.getFilterValues("bookingTableFilters");
        
        if (_filter) {
            this.filter = _filter;
            
            if (_filter.orderDateGte) {
                this.orderDate = [
                    _filter.orderDateGte.split("T")[0],
                    _filter.orderDateLte.split("T")[0]
                ];
            }
            if(_filter.loadDateGte){
                this.loadDate = [
                    _filter.loadDateGte.split("T")[0],
                    _filter.loadDateLte.split("T")[0]
                ];
            }
            
            if (_filter?.orderBy?.includes("-")) {
                this.sortBy = _filter.orderBy.replace("-", "");
                this.sortAsc = false;
            } else {
                this.sortBy = _filter.orderBy;
                this.sortAsc = true;
            } 

            // if (_filter.vesselName) {
            //     this.refreshTableVessel = true;
            // }

            // if (_filter.pol || _filter.pod) {
            //     this.refreshTablePorts = true;
            // }
        }

        // watch filterChoices
        this.$watch(
            "filterChoices",
            function(value) {
                helper.updateMyPreferences({
                    bookingTableFilters: value
                });
            },
            { deep: true }
        );
    },
    methods: {
        getFilterChoiceClassByCode(code) {
            return this.filterChoices.find(item => {
                return item.code === code;
            })?.class;
        },
        getFilterChoiceNameByCode(code) {
            return this.$t(
                this.defaultFilterChoices.find(item => {
                    return item.code === code;
                })?.tag
            );
        },
        onFilterMenuChanged(newChoices) {
            this.filterChoices = newChoices;
            this.updateFilterOnChange(this.filter);
        },
        onBookingCreated(booking) {
            this.$emit("booking-created", booking);
        },
        updateFilterOnChange(value) {
            let newFilter = {...value};
                    
            if(this.filterChoices && this.filterChoices.length > 0){
                for(let key in newFilter){
                    let filterChoice = this.filterChoices.find(f => f.filterChoices.includes(key));
                    if(filterChoice && !filterChoice.enabled){
                        newFilter[key] = null;
                    }
                }
            }
            this.$emit(
                "filter-changed",
                JSON.parse(JSON.stringify(newFilter))
            );
            helper.setFilterValues("bookingTableFilters", newFilter);
        }
    }
};
</script>

<style scoped>
.sticky-card {
    position: -webkit-sticky;
    position: sticky;
    top: 46px;
    z-index: 2;
}
</style>
