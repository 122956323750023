export const transportTypeList = [
    { text: "Contour Logistics", value: "CON001" },
    { text: "Carrier", value: "CARRIER" },
    { text: "Own Transport", value: "OWN" }
];

export const statusList = [
    { text: "Pending", value: "pending", color: "#BBDEFB" },
    { text: "Approved", value: "approved", color: "#C8E6C9" },
    { text: "Declined", value: "declined", color: "#EF9A9A" }
];

export const orderStatusList = [
    { text: "Draft", value: "draft", color: "gray" },
    { text: "Confirmed", value: "confirmed", color: "#FFECB3" },
    { text: "Cancelled", value: "cancelled", color: "#FFCDD2" },
    { text: "Pending", value: "pending", color: "#BBDEFB" },
    { text: "Declined", value: "declined", color: "#EF9A9A" }
];

export const changeRequestStatusList = [
    { text: "Pending", value: "pending", color: "#BBDEFB" },
    { text: "Approved", value: "approved", color: "#C8E6C9" },
    { text: "Cancelled", value: "cancelled", color: "#FFCDD2" },
    { text: "Declined", value: "declined", color: "#EF9A9A" }
];

export const shippingLineStatusList = [
    { text: "Booked", value: "booked", color: "#C8E6C9" },
    { text: "Await Ref. Number", value: "await_ref_number", color: "#FFECB3" },
    { text: "Confirmed", value: "confirmed", color: "#FFECB3" },
    { text: "Cancelled", value: "cancelled", color: "#FFCDD2" },
    { text: "Change Request", value: "change_request", color: "#EF9A9A" }
];

export const statusChoices = [
    //DRAFT SAVED / PRELIMANRY / CONFIRMED / CANCELLED / CHANGE REQUEST
    { text: "All", value: null },
    { text: "Draft", value: "draft" },
    { text: "Pending", value: "pending" },
    { text: "Confirmed", value: "confirmed" },
    { text: "Cancelled", value: "cancelled" },
    { text: "Declined", value: "declined" },
    { text: "Change Request", value: "change_request" }
];

export const stackStatusList = [
    { text: "PROV", value: "prov" },
    { text: "FIRM", value: "firm" }
];

export const steriList = [
    { text: "STERI", value: "steri" },
    { text: "DIY STERI", value: "diysteri" },
    { text: "N/A", value: "n/a" }
];

export const terms = [
    {
        value: "CIF",
        text: "CIF(Cost, Insurance and Freight)"
    },
    {
        value: "FOB",
        text: "FOB(Free On Board)"
    }
];

export const bookingComments = [
    // { text: "Draft", value: "draft" },
    { text: "Booking Rejected", value: "booking_rejected" },
    { text: "Vessel Overbooked", value: "vessel_overbooked" }
];

export const orderStatus = [
    { text: "Draft", value: "draft" },
    { text: "Submit", value: "pending" }
];

export const payMode = [
    { text: "Prepaid", value: "prepaid" },
    { text: "Collect", value: "collect" },
    { text: "Elsewhere", value: "elsewhere" }
];

