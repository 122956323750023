import { VBtn } from 'vuetify/lib/components/VBtn';
import { VCard } from 'vuetify/lib/components/VCard';
import { VCardActions } from 'vuetify/lib/components/VCard';
import { VCardText } from 'vuetify/lib/components/VCard';
import { VCardTitle } from 'vuetify/lib/components/VCard';
import { VContainer } from 'vuetify/lib/components/VGrid';
import { VDialog } from 'vuetify/lib/components/VDialog';
import { VDivider } from 'vuetify/lib/components/VDivider';
import { VIcon } from 'vuetify/lib/components/VIcon';
import { VListItem } from 'vuetify/lib/components/VList';
import { VListItemAction } from 'vuetify/lib/components/VList';
import { VListItemContent } from 'vuetify/lib/components/VList';
import { VListItemIcon } from 'vuetify/lib/components/VList';
import { VListItemTitle } from 'vuetify/lib/components/VList';
import { VSelect } from 'vuetify/lib/components/VSelect';
import { VSpacer } from 'vuetify/lib/components/VGrid';
import { VTextField } from 'vuetify/lib/components/VTextField';

var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c(VContainer,{staticClass:"py-0 px-0",attrs:{"fluid":""}},[_c(VSelect,{ref:"select",attrs:{"items":_vm.views,"label":_vm.title,"item-text":function (item) { return item.name; },"item-value":function (item) { return item.code; },"hide-details":"","outlined":"","dense":"","color":"primary","menu-props":{
            offsetY: true,
            nudgeTop: 0
        },"disabled":_vm.disabled},scopedSlots:_vm._u([{key:"prepend-item",fn:function(){return [_c(VListItem,{attrs:{"dense":"","disabled":_vm.isDefaultView},on:{"click":_vm.onViewSave}},[_c(VListItemIcon,{staticClass:"mr-2"},[_c(VIcon,[_vm._v("save")])],1),_c(VListItemContent,[_c(VListItemTitle,[_c('div',{staticClass:"caption"},[_vm._v(_vm._s(_vm.$t("general.save"))+" view")])])],1)],1),_c(VListItem,{attrs:{"dense":""},on:{"click":_vm.openDialog}},[_c(VListItemIcon,{staticClass:"mr-2"},[_c(VIcon,[_vm._v(_vm._s(_vm.editSaveIcon))])],1),_c(VListItemContent,[_c(VListItemTitle,[_c('div',{staticClass:"caption"},[_vm._v(_vm._s(_vm.$t("general.save"))+" as view")])])],1)],1),_c(VListItem,{attrs:{"dense":""},on:{"click":_vm.editColumns}},[_c(VListItemIcon,{staticClass:"mr-2"},[_c(VIcon,[_vm._v(_vm._s(_vm.editColumnIcon))])],1),_c(VListItemContent,[_c(VListItemTitle,[_c('div',{staticClass:"caption"},[_vm._v("Edit columns")])])],1)],1),_c(VDivider,{staticClass:"append-divider"})]},proxy:true},{key:"selection",fn:function(ref){
        var item = ref.item;
return [_c('div',{staticClass:"classText"},[_vm._v(" "+_vm._s(item.name)+" ")])]}},{key:"item",fn:function(ref){
        var item = ref.item;
return [_c(VListItemIcon,{staticClass:"mr-2"},[_c(VIcon,[_vm._v(_vm._s(item.code === "default" ? "star" : "filter_alt"))])],1),_c(VListItemContent,[_c(VListItemTitle,[_c('div',{staticClass:"caption"},[_vm._v(_vm._s(item.name))])])],1),_c(VListItemAction,{staticClass:"my-1"},[_c(VBtn,{attrs:{"icon":"","disabled":item.code === 'default'},on:{"click":function($event){$event.stopPropagation();return _vm.onDeleteView(item.code)}}},[_c(VIcon,{attrs:{"small":""}},[_vm._v("delete")])],1)],1)]}}]),model:{value:(_vm.selectedView),callback:function ($$v) {_vm.selectedView=$$v},expression:"selectedView"}}),_c(VDialog,{attrs:{"persitent":"","width":"400"},model:{value:(_vm.dialog),callback:function ($$v) {_vm.dialog=$$v},expression:"dialog"}},[_c(VCard,[_c(VCardTitle,{staticClass:"primary white--text"},[(_vm.newViewName !== '')?_c('div',[_vm._v(" Edit view Manage ")]):_c('div',[_vm._v(" View Manage ")])]),_c(VCardText,{staticClass:"pa-4"},[_c(VTextField,{attrs:{"outlined":"","dense":"","rows":"5","placeholder":"Enter view template"},model:{value:(_vm.newViewName),callback:function ($$v) {_vm.newViewName=$$v},expression:"newViewName"}})],1),_c(VCardActions,[_c(VSpacer),_c(VBtn,{attrs:{"text":""},on:{"click":_vm.closeDialog}},[_vm._v(_vm._s(_vm.$t("general.cancel")))]),_c(VBtn,{attrs:{"color":"primary"},on:{"click":_vm.onViewSave}},[_vm._v(_vm._s(_vm.$t("general.save")))])],1)],1)],1),_c(VDialog,{attrs:{"persistent":"","max-width":"290"},model:{value:(_vm.deleteDialog),callback:function ($$v) {_vm.deleteDialog=$$v},expression:"deleteDialog"}},[_c(VCard,[_c(VCardTitle,{staticClass:"headline"},[_vm._v(" Delete View ")]),_c(VCardText,[_vm._v(" Are you sure you want to delete this view? ")]),_c(VCardActions,[_c(VSpacer),_c(VBtn,{attrs:{"text":""},on:{"click":_vm.onCancelDeleteView}},[_vm._v("Cancel")]),_c(VBtn,{attrs:{"color":"primary"},on:{"click":_vm.deleteView}},[_vm._v("OK")])],1)],1)],1)],1)}
var staticRenderFns = []

export { render, staticRenderFns }