<template>
    <div class="d-flex flex-row">
        <!-- Left side -->
        <!-- <v-card
            width="30"
            flat
            tile
            color="transparent"
            :class="mobileView.classCard"
            class="mb-2 mb-sm-0"
        >
            <v-icon size="20" color="transparent">
                edit
            </v-icon>
        </v-card> -->
        <v-card width="30" color="transparent" flat tile :class="mobileView.classCard" class="flex-grow-0">
            <template>
                <slot name="prepend-action"></slot>
            </template>
        </v-card>

        <!-- Main content -->
        <v-row dense class="flex-grow-1 ma-0">
            <v-col v-if="headerText" cols="12" md="12" class="subtitle-2 text-uppercase pa-2">
                <v-row dense>
                    <v-col class="px-2" cols="12" md="12">{{ headerText }}</v-col>
                </v-row>
            </v-col>
            <v-col v-if="!useTableLayout" class="pa-2" cols="12" md="12">
                <v-row class="py-1" dense>
                    <v-col
                        v-for="(item, idxc) in items"
                        :key="idxc"
                        :cols="item.col.xs || 12"
                        :sm="item.col.sm"
                        :md="item.col.md" 
                        :lg="item.col.lg"
                        :xl="item.col.xl"
                        class="px-2"
                        :style="!(screenWidth < 1295) ? 'flex-basis: 14.2%; max-width: 14.2%;' : ''"
                    >
                        <div v-if="item.title" class="secondaryText font-weight-thin text-uppercase">
                            {{ item.title }}
                        </div>
                        <div class="text-truncate">
                            <div :style="item.style">
                                <div class="text-uppercase user-select-text">
                                    <div v-if="item.value" @click.stop.prevent>
                                        <template v-if="item.value.length < 20">
                                            <span class="primaryText text-uppercase font-weight-bold user-select-text">
                                                {{ item.value }}
                                            </span>
                                        </template>
                                        <v-tooltip v-else bottom>
                                            <template v-slot:activator="{ on, attrs }">
                                                <span
                                                    v-bind="attrs"
                                                    v-on="on"
                                                    class="primaryText text-uppercase font-weight-bold"
                                                >
                                                    {{ item.value }}
                                                </span>
                                            </template>
                                            <span class="class-text-tooltip user-select-text">
                                                {{ item.value }}
                                            </span>
                                        </v-tooltip>
                                    </div>
                                </div>
 
                            </div>
                        </div>
                    </v-col>
                </v-row>
            </v-col>
            <v-col v-else class="pa-2" cols="12" md="12">
                <v-row class="pt-1 mb-0" dense v-if="screenWidth > 1295">
                    <v-col
                        v-for="(item, id) in freightHeaders(transportMode)"
                        :key="id"
                        :cols="item.col.xs || 12"
                        :sm="item.col.sm"
                        :md="item.col.md"
                        :lg="item.col.lg"
                        :xl="item.col.xl"
                        class="px-2"
                        :style="
                            !(screenWidth < 1295)
                                ? 'flex-basis: 14.2%; max-width: 14.2%;'
                                : ''
                        "
                    >
                        <div
                            class="secondaryText font-weight-thin text-uppercase"
                        >
                            {{ item.title }}
                        </div>
                    </v-col>
                </v-row>
                <!-- Freight Data -->
                <v-row
                    v-for="(freight, ids) in items"
                    :key="ids"
                    class="py-0 mt-0"
                    dense
                >
                    <v-col
                        v-for="(item, idx) in freight"
                        :key="idx"
                        :cols="item.col.xs || 12"
                        :sm="item.col.sm"
                        :md="item.col.md"
                        :lg="item.col.lg"
                        :xl="item.col.xl"
                        class="px-2"
                        :style="
                            !(screenWidth < 1295)
                                ? 'flex-basis: 14.2%; max-width: 14.2%;'
                                : ''
                        "
                    >
                        <div
                            v-if="item.title && screenWidth < 1295"
                            class="secondaryText font-weight-thin text-uppercase"
                        >
                            {{ item.title }}
                        </div>
                        <div class="text-truncate">
                            <div :style="item.style">
                                <div
                                    v-if="item.value"
                                    @click.stop.prevent
                                >
                                    <template
                                        v-if="item.value.length < 20"
                                    >
                                        <span
                                            class="primaryText text-uppercase font-weight-bold user-select-text"
                                        >
                                            {{ item.value }}
                                        </span>
                                    </template>
                                    <v-tooltip v-else bottom>
                                        <template
                                            v-slot:activator="{
                                                on,
                                                attrs
                                            }"
                                        >
                                            <span
                                                v-bind="attrs"
                                                v-on="on"
                                                class="primaryText text-uppercase font-weight-bold"
                                            >
                                                {{ item.value }}
                                            </span>
                                        </template>
                                        <span
                                            class="class-text-tooltip user-select-text"
                                        >
                                            {{ item.value }}
                                        </span>
                                    </v-tooltip>
                                </div>
                            </div>
                        </div>
                    </v-col>
                </v-row>
            </v-col>

            <!-- Right side -->
            <!-- <v-col v-if="!isSmallScreen" cols="1" class="pa-0">
                <v-card width="90" flat></v-card>
            </v-col> -->
        </v-row>
    </div>
</template>

<script>
export default {
    props: {
        items: {
            type: Array,
            required: false,
            default: () => {
                return [];
            }
        },
        tooltip: {
            type: Boolean,
            required: false,
            default: false
        },
        // headers: {
        //     type: Array,
        //     required: false,
        //     default: () => {
        //         return [];
        //     }
        // },
        enabledKeyValue: {
            type: Boolean,
            required: false,
            default: true
        },
        useIcons: {
            type: Boolean,
            required: false,
            default: false
        },
        headerText: {
            type: String,
            required: false,
            default: null
        },
        useTableLayout:{
            type: Boolean,
            required: false,
            default: false
        },
        transportMode:{
            type: String,
            required: false,
            default: null
        }
    },
    components: {},
    data() {
        return {};
    },
    computed: {
        // list() {
        //     let _list = this.items;

        //     _list = _list.sort((a, b) => {
        //         let indexA = this.headers.findIndex(header => header.code === `node.${a.tag}`);
        //         let indexB = this.headers.findIndex(header => header.code === `node.${b.tag}`);
        //         return indexA - indexB;
        //     });

        //     _list = _list.filter(item => {
        //         let header = this.headers.find(header => header.code === `node.${item.tag}`);
        //         return this.enabledKeyValue ? header?.enabled : !header?.enabled;
        //     });

        //     return _list;
        // },
        isSmallScreen() {
            return this.$vuetify.breakpoint.width < 960;
        },
        mobilePhone() {
            return this.$vuetify.breakpoint.width < 700;
        },
        screenWidth() {
            let _resposiveView = this.$vuetify.breakpoint.width;
            return _resposiveView;
        },
        mobileView() {
            return {
                widthCard: this.isSmallScreen ? this.mobilePhone ? "90px" : "60px" : "30px",
                classCard: this.isSmallScreen ? "mr-2 mt-2" : "d-flex align-center mr-1",
                headerRow: !this.isSmallScreen ? "d-flex justify-center" : "pl-1",
                headerCol: this.isSmallScreen ? "py-1" : "pa-0 mt-2 d-flex align-center"
            };
        }
    },
    watch: {},
    mounted() {},
    methods: {
        freightHeaders(transportMode) {
            let _freightCard = [];

            if (transportMode === "SEA") {
                _freightCard.splice(
                    2,
                    0,
                    {
                        title: this.$t("headers.container_number"),
                        tag: "containerNumber",
                        icon: null,
                        iconSize: 8,
                        colorIcon: "",
                        style: "",
                        align: "left",
                        col: {
                            xs: 12,
                            sm: this.screenWidth < 700 ? 6 : 4,
                            md: this.screenWidth < 1295 ? 3 : "auto",
                            lg: this.screenWidth < 1295 ? 3 : "auto",
                            xl: "auto"
                        }
                    },
                    {
                        title: this.$t("headers.bill"),
                        tag: "billOfLadingNumber",
                        icon: null,
                        iconSize: 8,
                        colorIcon: "",
                        style: "",
                        align: "left",
                        col: {
                            xs: 12,
                            sm: this.screenWidth < 700 ? 6 : 4,
                            md: this.screenWidth < 1295 ? 3 : "auto",
                            lg: this.screenWidth < 1295 ? 3 : "auto",
                            xl: "auto"
                        }
                    }
                );
            }

            if (transportMode === "AIR") {
                _freightCard.splice(2, 0, {
                    title: this.$t("headers.airwaybill_number"),
                    tag: "airwaybillNumber",
                    icon: null,
                    iconSize: 8,
                    colorIcon: "",
                    style: "",
                    align: "left",
                    col: {
                        xs: 12,
                        sm: this.screenWidth < 700 ? 6 : 4,
                        md: this.screenWidth < 1295 ? 3 : "auto",
                        lg: this.screenWidth < 1295 ? 3 : "auto",
                        xl: "auto"
                    }
                });
            }

            _freightCard.push(
                {
                    title: this.$t("headers.freight_ref_number"),
                    tag: "freightRefNumber",
                    icon: null,
                    iconSize: 8,
                    colorIcon: "",
                    style: "",
                    align: "left",
                    col: {
                        xs: 12,
                        sm: this.screenWidth < 700 ? 6 : 4,
                        md: this.screenWidth < 1295 ? 3 : "auto",
                        lg: this.screenWidth < 1295 ? 3 : "auto",
                        xl: "auto"
                    }
                },
                {
                    title: this.$t("headers.order_number"),
                    tag: "orderNumber",
                    icon: null,
                    iconSize: 8,
                    colorIcon: "",
                    style: "",
                    align: "left",
                    col: {
                        xs: 12,
                        sm: this.screenWidth < 700 ? 6 : 4,
                        md: this.screenWidth < 1295 ? 3 : "auto",
                        lg: this.screenWidth < 1295 ? 3 : "auto",
                        xl: "auto"
                    }
                },
                {
                    title: this.$t("headers.tracker"),
                    tag: "tracker",
                    icon: null,
                    iconSize: 8,
                    colorIcon: "",
                    style: "",
                    align: "left",
                    col: {
                        xs: 12,
                        sm: this.screenWidth < 700 ? 6 : 4,
                        md: this.screenWidth < 1295 ? 3 : "auto",
                        lg: this.screenWidth < 1295 ? 3 : "auto",
                        xl: "auto"
                    }
                },
                {
                    title: this.$t("headers.goods"),
                    tag: "goods",
                    icon: null,
                    iconSize: 8,
                    colorIcon: "",
                    style: "",
                    align: "left",
                    col: {
                        xs: 12,
                        sm: this.screenWidth < 700 ? 6 : 4,
                        md: this.screenWidth < 1295 ? 3 : "auto",
                        lg: this.screenWidth < 1295 ? 3 : "auto",
                        xl: "auto"
                    }
                }
            );

            return _freightCard;
        },
    }
};
</script>
<style scoped lang="css">
.row-pointer >>> tbody tr :hover {
    cursor: pointer;
}
.center-loading {
    position: sticky;
    left: 50% !important;
}
.textTruncate {
    overflow: hidden;
    text-overflow: ellipsis;
    white-space: nowrap;
}
.classMainContent {
    padding-left: 4px !important;
    padding-right: 4px !important;
    margin-top: 1px !important;
    margin-bottom: 16px !important;
}
.classSecondaryContent {
    padding-left: 40px !important;
    padding-right: 40px !important;
    margin-top: 10px !important;
    margin-bottom: 10px !important;
}

.secondaryRowSize {
    width: 100% !important;
}

.classHeader {
    color: #868599 !important;
    font-size: 12px;
    font-weight: 700;
    line-height: 13px;
    letter-spacing: 0em;
    text-align: left;
    margin-top: 3px;
    padding-bottom: 4px !important;
    padding-top: 4px !important;
}

.class-text-tooltip {
    /* color: #0e0d35 !important; */
    white-space: nowrap;
    overflow: hidden;
    text-overflow: ellipsis;
    font-size: 12px;
    font-weight: 700;
    line-height: 13px;
    letter-spacing: 0em;
    text-align: left;
    margin-top: 3px;
}

.classText {
    color: #0e0d35 !important;
    font-size: 12px;
    font-weight: 700;
    line-height: 13px;
    letter-spacing: 0em;
    text-align: left;
    margin-top: 3px;
    padding-bottom: 4px !important;
    padding-top: 4px !important;
}

.textTitle {
    font-size: 32px !important;
    color: "#0e1e46" !important;
    font-weight: bold;
    word-break: normal !important;
    line-height: inherit !important;
    text-align: center !important;
}
.textSubtitle {
    font-size: 13px !important;
    color: "#868599" !important;
    height: 23px !important;
    font-weight: bold;
    white-space: nowrap;
    overflow: hidden;
    text-overflow: ellipsis;
}

.outlineD {
    border: 1px solid #e6e9f5;
    border-radius: 4px;
    padding: 0px 0px;
    font-size: 12px;
    font-weight: 600;
}

.backgroundColor {
    background-color: #f9fafe !important;
}

.textLeft {
    text-align: left !important;
}

.tabItemCl {
    background-color: var(--v-background-base);
}

.disabledItem {
    pointer-events: none;
    opacity: 0.4;
}

.classHeader2 {
    color: #fff !important;
    white-space: nowrap;
    overflow: hidden;
    text-overflow: ellipsis;
    font-size: 12px;
    font-weight: 400;
    line-height: 14px;
    letter-spacing: 0em;
    text-align: left;
}

.classText2 {
    color: #fff !important;
    white-space: nowrap;
    overflow: hidden;
    text-overflow: ellipsis;
    font-size: 12px;
    font-weight: 700;
    line-height: 13px;
    letter-spacing: 0em;
    text-align: left;
    margin-top: 3px;
}
</style>
