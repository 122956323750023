<template>
    <v-container fluid class="pa-0">
        <v-btn
            v-scroll="onScroll"
            v-show="goTopShow"
            fab
            dark
            fixed
            bottom
            right
            small
            style="z-index: 1"
            color="primary"
            @click="toTop"
        >
            <v-icon>keyboard_arrow_up</v-icon>
        </v-btn>

        <!-- title and KPIs -->
        <div>
            <v-row class="ma-0 px-3 d-flex align-center">
                <v-col cols="3">
                    <h2
                        class="
                            ml-3
                            display-3
                            font-weight-medium
                            align-top
                        "
                    >
                        {{ $t("home.trackers") }}
                    </h2>
                </v-col>
                <v-spacer></v-spacer>
                <v-btn
                    v-if="canCreate && !openSelector"
                    color="primary"
                    @click="openAddTrackerDialog = true"
                    rounded
                    class="mr-2 mt-2"
                    >{{ $t("trackers.add_tracker") }}
                </v-btn>
                <v-btn
                    dark
                    rounded
                    color="primary"
                    class="mr-2 mt-2"
                    @click="openTrackerUploadHistoryDialog = true"
                >
                    <v-icon v-if="screenSize=='xs'">history</v-icon>
                    <span v-else>{{ $t("general.upload_history")}}</span>
                </v-btn>
                <!-- Tracker change tenant -->
                <TrackerChangeTenant
                    
                    v-if="isStaff && canEdit && openSelector"
                    :openTrackerChangeTenantDialog="
                        openTrackerChangeTenantDialog
                    "
                    :selectedTrackers="selectedTrackersToMove"
                    v-on:closeSelector=" closeSelector"
                    v-on:updateTrackersTable="
                        refreshTable
                    "
                >
                </TrackerChangeTenant>
                <!-- Tracker change location -->
                <TrackerChangeLocation
                    class="mt-2"
                    v-if="!isStaff && canEdit && openSelector"
                    :openTrackerChangeLocationDialog="
                        openTrackerChangeLocationDialog
                    "
                    :isPreview="false"
                    :selectedTrackers="selectedTrackersToMove"
                    v-on:closeSelector="closeSelector"
                    v-on:updateTrackersTable="
                        refreshTable
                    "
                >
                </TrackerChangeLocation>        
                <div v-if="canEdit">
                    <v-btn
                        v-if="!openSelector"
                        color="primary"
                        @click="openSelector = true"
                        rounded
                        class="mr-2 mt-2"
                    >{{ $t("general.select")}}</v-btn>
                    <v-btn
                        v-else
                        @click="openSelector = false"
                        text
                        rounded
                        class="mt-2"
                    >{{ $t("general.cancel")}}</v-btn>
                </div>
            </v-row>
        </div>

        <!-- filter -->
        <TrackerFilter
            class="mx-3"
            v-on:filter-changed="onFilterChanged"
            v-on:updateTrackerTable="refreshTable"
            :filterCode="filterCode"
        >
        </TrackerFilter>
        <!-- list -->
        <TrackerTable
            class="mx-3 mt-3 mb-3"
            ref="trackertable"
            :trackerFilter="trackerFilter"
            v-on:click:row="onTrackerSelected"
            :selector="openSelector"
            v-on:selectedTrackers="onSelectTrackersToMove"
        >
        </TrackerTable>
        <!-- Tracker History -->
        <TrackerUploadHistory
            :openTrackerUploadHistoryDialog="openTrackerUploadHistoryDialog"
            v-on:updateTrackersTable="refreshTable"
            v-on:close="openTrackerUploadHistoryDialog = false"
            :isStaff="isStaff"
        ></TrackerUploadHistory>

        <!-- tutorial -->
        <TutorialDialog :dialog.sync="tutorialDialog"></TutorialDialog>

        <!-- detail drawer -->
        <v-navigation-drawer
            v-model="trackerDetailDrawer"
            :width="trackerDetailDrawerWidth"
            :style="{ top: '46px', zIndex: 3 }"
            right
            app
            temporary
        >
            <div>
                <TrackerDetail
                    v-if="selectedTracker || idFromRouter"
                    :key="selectedTracker ? selectedTracker.id : idFromRouter"
                    :trackerProp="selectedTracker"
                    :trackerId="idFromRouter"
                    :isFullScreen="isTrackerDetailDrawerFullScreen"
                    v-on:changeFullScreen="
                        onTrackerDetailDrawerFullScreenChange
                    "
                    v-on:refreshDataTable="refreshTable"
                    v-on:close="trackerDetailDrawer = false"
                ></TrackerDetail>
            </div>
        </v-navigation-drawer>
        <!-- Add tracker dialog -->
        <AddTrackerDialog
            :openAddTrackerDialog="openAddTrackerDialog"
            v-on:updateTrackersTable=" 
                refreshTable
            "
            v-on:closeAddTrackerDialog="
                openAddTrackerDialog = false
            "
            :isStaff="isStaff"
        ></AddTrackerDialog>

       
    </v-container>
</template>

<script>
// import gql from "graphql-tag";
import helper from "@/utils/helper.js";
import TutorialDialog from "@/components/support/TutorialDialog.vue";
import AddTrackerDialog from "@/components/trackers/create/AddTrackerDialog.vue";
import TrackerTable from "@/components/trackers/TrackerTable.vue";
import TrackerFilter from "@/components/trackers/TrackerFilter.vue";
import TrackerDetail from "@/components/trackers/TrackerDetail.vue";
import TrackerUploadHistory from "@/components/trackers/TrackerUploadHistory.vue";
import { TrackerPermissions } from "@/core/enum";
import { eventBus } from "@/main.js";
import TrackerChangeLocation from "@/components/trackers/TrackerChangeLocation.vue";
import TrackerChangeTenant from "@/components/trackers/TrackerChangeTenant.vue";

export default {
    title: "Trackers",
    components: {
        TutorialDialog,
        TrackerTable,
        TrackerFilter,
        TrackerDetail,
        TrackerUploadHistory,
        AddTrackerDialog,
        TrackerChangeLocation,
        TrackerChangeTenant
    },

    apollo: {},

    data() {
        return {
            openTrackerUploadHistoryDialog: false,
            openTrackerChangeLocationDialog: false,
            openTrackerChangeTenantDialog: false,
            openSelector: false,
            openAddTrackerDialog: false,
            trackerDetailDrawer: false,
            idFromRouter: null,
            filterCode: "",
            goTopShow: false,
            isLoading: false,
            menu: false,
            trackerFilter: {
                orderBy: "-lastConnectionTime",
                first: 25,
                isArchived: false,
                status: "all"
            },
            selectedTrackersToMove: [],
            trackerDetailFullScreen: false,
            selectedTracker: null,

            tutorialDialog: false
        };
    },

    computed: {
        screenSize() {
            let _resposiveView = this.$vuetify.breakpoint.width;
            if (_resposiveView < 650) {
                return "xs";
            } else if (_resposiveView < 1000) {
                return "sm";
            } else if (_resposiveView < 1400) {
                return "md";
            } else if (_resposiveView < 1900) {
                return "lg";
            } else {
                return "xl";
            }
        },
        me() {
            return helper.me();
        },
        trackerDetailDrawerWidth() {
            if (this.trackerDetailFullScreen) {
                return "100%";
            }

            switch (this.$vuetify.breakpoint.name) {
                case "xs":
                    return "100%";
                case "sm":
                    return "100%";
                case "md":
                    return "100%";
                case "lg":
                    return "70%";
                case "xl":
                    return "70%";
                default:
                    return "100%";
            }
        },
        isTrackerDetailDrawerFullScreen() {
            return this.trackerDetailDrawerWidth === "100%";
        },
        isStaff() {
            return this.me.isStaff || false;
        },
        canEdit() {
            if (this.me?.isSuperuser) {
                return true;
            }
            return this.hasPermission(TrackerPermissions.EDIT_TRACKER);
        },
        canCreate() {
            if (this.me?.isSuperuser) {
                return true;
            }
            return this.hasPermission(TrackerPermissions.CREATE_TRACKER);
        },
        isLoggedInAsOtherUser() {
            return this.$store.state.user.loggedInAsOtherUser;
        }
    },

    watch: {
        trackerDetailDrawer(val) {
            if (!val) {
                this.selectedTracker = null;
                if (this.isStaff) {
                    if (this.$route.fullPath != "/trackersstock") {
                        this.$router.push({
                            path: "/trackersstock",
                            query: {},
                            params: {}
                        });
                    }
                } else {
                    if (this.$route.fullPath != "/trackers") {
                        this.$router.push({
                            path: "/trackers",
                            query: {},
                            params: {}
                        });
                    }
                }
                this.trackerDetailFullScreen = false;
            }
        },
        $route(to) {
            if (to.fullPath == "/trackers" || to.fullPath == "/trackersstock")
                return;
            if (to.query.id) {
                this.idFromRouter = to.query.id;
                this.trackerDetailDrawer = true;
            }
            if (to.params.id) {
                this.idFromRouter = to.params.id;
                this.trackerDetailDrawer = true;
                this.trackerDetailFullScreen = true;
            }
        }
    },
    created() {
        this.hasPermission = helper.hasPermission;
        this.pushTo = helper.pushTo;
    },
    mounted() {
        if (this.$route.query.code) this.filterCode = this.$route.query.code;
        if (this.$route.query.id) {
            this.idFromRouter = this.$route.query.id;
            this.trackerDetailDrawer = true;
        }
        if (this.$route.params.id) {
            this.idFromRouter = this.$route.params.id;
            this.trackerDetailDrawer = true;
            this.trackerDetailFullScreen = true;
        }
    },
    methods: {
        onCloseSelector() {
            this.openSelector = false;
            this.selectedTrackersToMove = [];
        },
        onSelectTrackersToMove(trackers) {
            this.selectedTrackersToMove = trackers;
        },
        closeSelector(val) {
            this.openSelector = val;
            this.selectedTrackersToMove = [];
        },
       /*  onUpdateSelector(val) {
            this.openSelector = val;
            this.selectedTrackersToMove = [];
        }, */
        onLoading(val) {
            this.isLoading = val;
        },

        // scroll to top of the page
        onScroll(e) {
            if (typeof window === "undefined") return;
            const top = window.pageYOffset || e.target.scrollTop || 0;
            this.goTopShow = top > 20;
        },

        toTop() {
            this.$vuetify.goTo(0);
        },
        refreshTable() {
            this.$refs.trackertable.$apollo.queries.trackers.refetch();
        },
        loadMore() {
            this.isLoading = false;
        },
        onFilterChanged(filter) {
            this.trackerFilter = filter;
        },
        onTrackerSelected(tracker) {
            this.selectedTracker = tracker.node;
            this.$router.push({ query: { id: tracker.node.id } });
            this.trackerDetailDrawer = true;
        },
        onTrackerDetailDrawerFullScreenChange(val) {
            this.trackerDetailFullScreen = val;
            if (val) {
                eventBus.$emit("closeMenu");
            }
        }
    }
};
</script>
<style scoped>
::v-deep .v-navigation-drawer__content::-webkit-scrollbar {
    width: 0px;
    background: transparent;
}
</style>
