import { VAutocomplete } from 'vuetify/lib/components/VAutocomplete';
import { VChip } from 'vuetify/lib/components/VChip';
import { VCol } from 'vuetify/lib/components/VGrid';
import { VRow } from 'vuetify/lib/components/VGrid';

var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c(VRow,{attrs:{"dense":""}},[_c(VCol,{attrs:{"cols":"12"}},[_c(VAutocomplete,{attrs:{"loading":_vm.loading,"items":_vm.consignor,"label":_vm.label,"dense":"","hide-selected":"","hide-details":"","clearable":"","item-text":"node.name","item-value":"node.id","outlined":_vm.outlined,"no-data-text":_vm.loading ? this.$t('loading.loading1') : this.$t('loading.no_available'),"multiple":""},on:{"focus":function($event){_vm.openPartnersList = true}},scopedSlots:_vm._u([{key:"selection",fn:function(ref){
var attrs = ref.attrs;
var item = ref.item;
return [_c(VChip,_vm._b({staticClass:"partner-chip",attrs:{"close":"","small":""},on:{"click:close":function($event){return _vm.remove(item)}}},'v-chip',attrs,false),[_c('span',{staticClass:"chip-text"},[_vm._v(_vm._s(item.node.name))])])]}}]),model:{value:(_vm.partners),callback:function ($$v) {_vm.partners=$$v},expression:"partners"}})],1)],1)}
var staticRenderFns = []

export { render, staticRenderFns }