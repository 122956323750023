<template>
    <v-hover>
        <template>
            <v-card class="py-2 transparent" outlined>
                <v-row dense>
                    <v-col>
                        <v-row class="d-flex align-center py-0" dense>
                            <!-- Search bar -->
                            <v-col cols="12" xs="12" sm="12" md="3" lg="3" xl="3">
                                <div>
                                    <SearchBar v-model="search" :label="$t('filters.search')" ref="searchBar">
                                        <template v-slot:prepend-inner>
                                            <div>
                                                <v-tooltip bottom>
                                                    <template v-slot:activator="{ on }">
                                                        <v-icon v-on="on" color="">
                                                            search
                                                        </v-icon>
                                                    </template>
                                                    <span>
                                                        {{ $t("trackers.search") }}
                                                    </span>
                                                </v-tooltip>
                                            </div>
                                        </template>
                                    </SearchBar>
                                </div>
                            </v-col>

                            <!-- Location type -->
                            <v-col cols="auto" v-if="tab === 'publicLocations'">
                                <v-menu
                                    ref="locationTypeMenu"
                                    max-width="300px"
                                    :close-on-content-click="false"
                                    :close-on-click="true"
                                    right
                                    bottom
                                    nudge-top="-6"
                                    offset-y
                                    @click.stop
                                    @input="onMenuStateChange($event, 'locationTypeMenu')"
                                >
                                    <template v-slot:activator="{ on, attrs }">
                                        <v-chip
                                            label
                                            class="elevation-0 text-capitalize classText content"
                                            v-on="on"
                                            v-bind="attrs"
                                            @click="addCode('locationType')"
                                        >
                                            <span v-if="localLocationType !== null">
                                                {{ $t('headers.location_type') + ': ' + getLocationTypeLabel(localLocationType) }}
                                            </span>
                                            <span v-else>
                                                {{ $t('headers.location_type') + ': ' + $t('general.all') }}
                                            </span>
                                        </v-chip>
                                    </template>
                                    <v-card height="100%" class="pa-1">
                                        <v-card-text class="mt-2">
                                            <v-row align="start" class="align-center" dense>
                                                <v-col cols="12" xs="12" sm="12" md="12" lg="12" xl="12">
                                                    <v-select
                                                        v-model="locationTypeLabel"
                                                        :items="locationTypeLabels"
                                                        :label="$t('trips.filter')"
                                                        readonly
                                                        disabled
                                                        dense
                                                        outlined
                                                        color="primary"
                                                        hide-details
                                                        :menu-props="{
                                                            offsetX: true,
                                                            right: true
                                                        }"
                                                    ></v-select>
                                                </v-col>
                                                <v-col cols="12" xs="12" sm="12" md="12" lg="12" xl="12">
                                                    <v-select
                                                        v-model="localLocationType"
                                                        :items="publicLocationsTypes"
                                                        item-text="text"
                                                        item-value="value"
                                                        label="Value"
                                                        outlined
                                                        hide-details
                                                        dense
                                                    ></v-select>
                                                </v-col>
                                            </v-row>
                                        </v-card-text>
                                        <v-card-actions class="pa-0">
                                            <v-spacer></v-spacer>
                                            <v-btn text @click="cancelFilter('locationTypeMenu')">
                                                Close
                                            </v-btn>
                                            <v-btn 
                                                color="primary" 
                                                @click="$emit('updateLocationType', localLocationType); $refs.locationTypeMenu.isActive = false;"
                                            >
                                                Apply
                                            </v-btn>
                                        </v-card-actions>
                                    </v-card>
                                </v-menu>
                            </v-col>

                            <!-- Filters -->
                            <v-col cols="auto" v-for="i in filterValuesObj" :key="i.id">
                                <v-menu
                                    :ref="getCode(i.id)"
                                    max-width="300px"
                                    :close-on-content-click="false"
                                    :close-on-click="true"
                                    right
                                    bottom
                                    nudge-top="-6"
                                    offset-y
                                    @click.stop
                                    @input="onMenuStateChange($event, getCode(i.id))"
                                >
                                    <template v-slot:activator="{ on, attrs }">
                                        <v-chip
                                            label
                                            close
                                            class="elevation-0 text-capitalize classText content"
                                            v-on="on"
                                            v-bind="attrs"
                                            @click:close="deleteFilterValue(i.id)"
                                            @click="addCode(getCode(i.id))"
                                        >
                                            <span v-if="i.id == ''"></span>
                                            {{ i.name + ":" + " " + i.value }}
                                        </v-chip>
                                    </template>
                                    <v-card height="100%" class="pa-1">
                                        <v-card-text class="mt-2">
                                            <FilterMenuTemplate
                                                ref="FilterMenuTemplate"
                                                v-model="menuObject"
                                                v-on:search-fields="searchFields"
                                                :isReadOnly="true"
                                                :currentCode="codeSelected"
                                                :tab="tab"
                                            ></FilterMenuTemplate>
                                        </v-card-text>
                                        <v-card-actions class="pa-0">
                                            <v-spacer></v-spacer>
                                            <v-btn text @click="cancelFilter(getCode(i.id))">
                                                Close
                                            </v-btn>
                                            <v-btn color="primary" @click="searchFields(menuObject, getCode(i.id))" 
                                                :disabled="!hasMenuValues">
                                                Apply
                                            </v-btn>
                                        </v-card-actions>
                                    </v-card>
                                </v-menu>
                            </v-col>

                            <!-- Add Filter -->
                            <v-col cols="auto">
                                <v-menu
                                    ref="addFilterMenu"
                                    max-width="300px"
                                    :close-on-content-click="false"
                                    :close-on-click="true"
                                    @click.stop
                                    right
                                    bottom
                                    nudge-top="-6"
                                    offset-y
                                    @input="onMenuStateChange($event, 'addFilterMenu')"
                                >
                                    <template v-slot:activator="{ on, attrs }">
                                        <v-btn color="primary" class="content" v-on="on" v-bind="attrs">
                                            {{ $t("general.add_filter") }}
                                        </v-btn>
                                    </template>
                                    <v-card height="100%" class="pa-1">
                                        <v-card-text class="mt-2">
                                            <FilterMenuTemplate
                                                v-model="menuObject"
                                                v-on:search-fields="searchFields"
                                                :isReadOnly="false"
                                                :currentCode.sync="codeSelected"
                                                :tab="tab"
                                            ></FilterMenuTemplate>
                                        </v-card-text>
                                        <v-card-actions class="pa-0">
                                            <v-spacer></v-spacer>
                                            <v-btn text @click="cancelFilter('addFilterMenu')">
                                                Close
                                            </v-btn>
                                            <v-btn
                                                color="primary"
                                                @click="searchFields(menuObject, 'addFilterMenu')"
                                                :disabled="!hasMenuValues || codeSelected === null || codeSelected === ''"
                                            >
                                                Apply
                                            </v-btn>
                                        </v-card-actions>
                                    </v-card>
                                </v-menu>
                            </v-col>
                        </v-row>
                    </v-col>

                    <!-- Sort by -->
                    <v-col cols="auto">
                        <v-row dense>
                            <v-col>
                                <v-select
                                    v-model="sortBy"
                                    :items="dynamicSortBy"
                                    :label="$t('general.sort_by')"
                                    :item-value="item => item.code"
                                    :item-text="item => item.name"
                                    clearable
                                    hide-details
                                    dense
                                    outlined
                                    color="primary"
                                    class="content-select"
                                >
                                    <template v-slot:append-outer>
                                        <v-btn class="mt-n1" small icon :disabled="!sortBy" @click="sortAsc = !sortAsc">
                                            <v-icon color="grey darken-2">{{ sortAsc ? "north" : "south" }}</v-icon>
                                        </v-btn>
                                    </template>
                                    <template #item="{item}">
                                        <span class="classText">
                                            {{ item.name }}
                                        </span>
                                    </template>
                                    <template v-slot:selection="{ item }">
                                        <div class="classText">
                                            {{ item.name }}
                                        </div>
                                    </template>
                                </v-select>
                            </v-col>
                        </v-row>
                    </v-col>
                </v-row>
            </v-card>
        </template>
    </v-hover>
</template>

<script>
import SearchBar from "@/components/base/SearchBar.vue";
import FilterMenuTemplate from "@/components/locations/cardtemplates/FilterMenuTemplate.vue";
import helper from "@/utils/helper.js";
import moment from "moment";
import { debounce } from "lodash";
import store from "@/store";
import gql from "graphql-tag";

export default {
    components: {
        SearchBar,
        FilterMenuTemplate
    },
    props: {
        title: {
            type: String,
            default: "Partner"
        },
        label: {
            type: String,
            default: null
        },
        partnerByType: {
            type: String,
            default: null
        },
        selectedLocations: {
            type: Object
        },
        tab: {
            type: String,
            default: "locations"
        },
        searchInput: {
            type: String,
            default: null
        },
        locationType: {
            type: String,
            default: null
        }
    },
    data() {
        return {
            menu: false,
            filter: {
                first: 15,
                after: null,
                search: null,
                locationType_In: null,
                nameIcontains: null,
                tenantPartnerNameIcontains: null,
                tenantPartnerPartnerTypeIexact: null,
                tagsIcontains: null,
                cityIcontains: null,
                countryIcontains: null,
                isTrackerStockLocation: null
            },
            weatherFilter: {
                first: 15,
                after: null,
                search: null,
                locationType_In: null,
                cityIcontains: null,
                countryIcontains: null
            },
            myFilter: {
                first: 15,
                after: null,
                search: null,
                nameIcontains: null,
                locationType_In: null,
                tenantPartnerNameIcontains: null,
                tenantPartnerPartnerTypeIexact: null,
                tagsIcontains: null,
                cityIcontains: null,
                countryIcontains: null
            },
            partnerFilter: {
                first: 15,
                after: null,
                search: null,
                nameIcontains: null,
                locationType_In: null,
                tenantPartnerNameIcontains: null,
                tenantPartnerPartnerTypeIexact: null,
                tagsIcontains: null,
                cityIcontains: null,
                countryIcontains: null,
                isTrackerStockLocation: null
            },
            publicFilter: {
                first: 15,
                after: null,
                search: null,
                nameIcontains: null,
                locationType_In: null,
                tenantPartnerNameIcontains: null,
                tenantPartnerPartnerTypeIexact: null,
                tagsIcontains: null,
                cityIcontains: null,
                countryIcontains: null
            },
            codeSelected: null,
            menuObject: {},
            copyMenuObject:{},
            enabledFilters: [],
            filterValuesObj: [],
            debounceUpdatePreferences: null,
            sortBy: 'name',
            sortAsc: false,
            partnersTypes: [
                {
                    text: this.$t("headers.consignor"),
                    value: "consignor"
                },
                {
                    text: this.$t("headers.consignee"),
                    value: "consignee"
                },
                {
                    text: this.$t("headers.forwarder"),
                    value: "forwarder"
                },
                {
                    text: this.$t("home.other"),
                    value: "other"
                }
            ],
            defaulttagsChoices: [
                {
                    text: this.$t("headers.warehouse"),
                    value: "warehouse"
                },
                {
                    text: this.$t("headers.border_post"),
                    value: "border_post"
                },
                {
                    text: this.$t("headers.cold_store"),
                    value: "cold_store"
                },
                {
                    text: this.$t("headers.empty_depot"),
                    value: "empty_depot"
                }
            ],
            publicLocationsTypes: [
                {
                    text: this.$t("headers.airport"),
                    value: "air_port"
                },
                {
                    text: this.$t("headers.sea_port"),
                    value: "sea_port"
                }
            ],
            localLocationType: null,
            locationTypeLabel: 'Location Types',
            locationTypeLabels: ['Location Types']
        };
    },
    created() {
        // Initialize debounced function
        this.debounceUpdatePreferences = debounce(this.updatePreferences, 500);

        // Load saved filters from preferences
        this.loadSavedFilters();
    },
    computed: {
        me() {
            return helper.me();
        },
        search: {
            get() {
                let _search = this.filter.search || "";
                return _search;
            },
            set(val) {
                this.filter.search = val;
                this.updateFilter("search", this.filter);
            }
        },
        dynamicSortBy() {
            let _default = [];
            // default filter choices
            let choices = [
                {
                    code: "name",
                    name: "Location Name",
                },
                {
                    code: "city",
                    name: "City",
                },
                {
                    code: "country",
                    name: "Country"
                },
                {
                    code: "scope",
                    name: "Scope",
                },
                {
                    code: "isTrackerStockLocation",
                    name: "Tracker Stock Location",
                },
                /* partner name */
                {
                    code: "coloboratorName",
                    name: "Coloborator Name",
                },
                /* partner type */
                {
                    code: "coloboratorType",
                    name: "Coloborator Type",
                },
                {
                    code: "warning",
                    name: "Warning Cities",
                }
            ];

            //sort by choices based on the tab
            switch (this.tab) {
                case "myLocations":
                    _default = choices.filter(item => {
                        return (
                            item.code !== "coloboratorName" &&
                            item.code !== "coloboratorType" &&
                            item.code !== "warning" &&
                            item.code !== "scope" &&
                            item.code !== "isTrackerStockLocation"
                        );
                    });
                    break;
                case "partnerLocations":
                    _default = choices.filter(item => {
                        return item.code !== "warning" && item.code !== "scope";
                    });
                    break;
                case "publicLocations":
                    _default = choices.filter(item => {
                        return (
                            item.code !== "coloboratorName" &&
                            item.code !== "coloboratorType" &&
                            item.code !== "scope" &&
                            item.code !== "warning" &&
                            item.code !== "isTrackerStockLocation"
                        );
                    });
                    break;
                case "weather":
                    _default = choices.filter(item => {
                        return (
                            item.code !== "coloboratorName" &&
                            item.code !== "coloboratorType" &&
                            item.code !== "isTrackerStockLocation" &&
                            item.code !== "warning"

                        );
                    });
                    break;
            }
            return _default;
        },
        hasMenuValues() {
            return Object.values(this.menuObject).some(value => 
                value !== null && value !== undefined && value !== ''
            );
        },
        locationTypeSelected() {
            return [
                { text: 'Location Types', value: 'locationType' }
            ];
        }
    },
    watch: {
        sortBy(newVal) {
            if (newVal) {
                // Update filter.orderBy while preserving sort direction
                this.filter.orderBy = this.sortAsc ? newVal : `-${newVal}`;
            } else {
                // If cleared, set to default
                this.sortBy = 'name';
                this.filter.orderBy = this.sortAsc ? 'name' : '-name';
            }
            this.updateFilter('sort', this.filter);
        },
        sortAsc(newVal) {
            const baseSort = this.sortBy || 'name';
            this.filter.orderBy = newVal ? baseSort : `-${baseSort}`;
            this.updateFilter('sort', this.filter);
        },
        'filter.orderBy'(newVal) {
            if (newVal) {
                this.sortAsc = !newVal.startsWith('-');
                this.sortBy = newVal.replace(/^-/, '');
            }
        },
        tab: {
            handler(newTab) {
                // Reset filters and load new ones based on tab
                this.resetFilters();
                this.loadSavedFilters();
            },
            immediate: false
        },
        locationType: {
            immediate: true,
            handler(val) {
                this.localLocationType = val;
            }
        }
    },
    methods: {
        updateFilter(updateFrom, filter) {
            //trigger filter chips
            const triggerFilter = async val => {
                await this.filterValues(val);
            };

            //update query values
            this.$emit("filter-changed", JSON.parse(JSON.stringify(filter)));

            //update filter enabled
            this.$emit("filter-enabled", this.enabledFilters);

            //update store
            if (updateFrom !== "search") {
                triggerFilter(filter);
            }

            this.debounceUpdatePreferences();
        },
        onMenuStateChange(val, menuRef) {
            if (!val) {
                this.cancelFilter(this.menuReference);
                this.menuReference = null;
            } else {
                if (this.menuReference && this.menuReference !== menuRef) {
                    //Close the previous menu
                    this.cancelFilter(this.menuReference);
                }
                //Set the current menu
                this.menuReference = menuRef;

                this.$nextTick(() => {
                    if (this.$refs[this.menuReference] && this.$refs[this.menuReference][0]) {
                        this.$refs[this.menuReference][0].isActive = true;
                    } else if (this.$refs[this.menuReference]) {
                        this.$refs[this.menuReference].isActive = true;
                    }
                });
            }
        },
        cancelFilter(refMenu) {
            //clear menuObject
            this.codeSelected = null;

            //reset menuObject with previous value 
            this.menuObject = {...this.copyMenuObject};

            //clear menu filter
            if (this.$refs[refMenu]) {
                if (this.$refs[refMenu][0]) {
                    this.$refs[refMenu][0].isActive = false;
                } else {
                    this.$refs[refMenu].isActive = false;
                }
            }
        },
        async searchFields(menuItems, refMenu) {
            let menuKeys = Object.keys(menuItems);

            menuKeys.forEach(key => {
                if (menuItems[key] != undefined) {
                        this.filter[key] = menuItems[key];
                } else {
                    this.filter[key] = null;
                }
            });

            //update copy menu object
            this.copyMenuObject = { ...menuItems };

           
            //trigger enabled filters
            this.triggerEnabledFilters();

            //update filter
            this.updateFilter("customUpdate", this.filter);

            //reset
            this.cancelFilter(refMenu);
        },
        triggerEnabledFilters() {
            if (!this.codeSelected) return;

            let codeIsFind = this.enabledFilters.find(e => e.code === this.getKey(this.codeSelected));

            if (!codeIsFind) {
                this.enabledFilters.push({
                    name: this.replaceName(this.codeSelected),
                    code: this.getKey(this.codeSelected),
                    enabled: true
                });
            }
        },
        addCode(code) {
            this.codeSelected = code;
        },
        async filterValues(object) {
            const filterKeysToIgnore = ["first", "after", "orderBy", "search"];
            
            // Use enabledFilters order instead of object keys
            let filteredKeys = this.enabledFilters
                .filter(filter => filter.enabled && !filterKeysToIgnore.includes(filter.code))
                .map(filter => filter.code);

            // Build initial filter values maintaining order from enabledFilters
            let values = await Promise.all(
                filteredKeys.map(async key => ({
                    id: key,
                    name: this.replaceName(key),
                    value: await this.valuePopulate(object[key], key)
                }))
            );

            this.filterValuesObj = values;
        },
        async deleteFilterValue(key) {
            
            this.menuObject[key] = null;
            this.filter[key] = null;

            // Remove the filter from enabledFilters
            this.enabledFilters = this.enabledFilters.filter(e => e.code !== key);
            

            // Update filter and trigger preference update
            this.updateFilter("delete", this.filter);
            
            // Force immediate preference update instead of debounced
            this.updatePreferences();
        },
        replaceName(key) {
            let replace = "";
            switch (key) {
                case "nameIcontains":
                    replace = this.$t("headers.name");
                    break;
                case "cityIcontains":
                    replace = this.$t("headers.city");
                    break;
                case "countryIcontains":
                    replace = this.$t("headers.country");
                    break;
                case "tagsIcontains":
                    replace = this.$t("headers.tags");
                    break;
                case "tenantPartnerNameIcontains":
                    replace = this.$t("headers.collaborator_name");
                    break;
                case "tenantPartnerPartnerTypeIexact":
                    replace = this.$t("headers.collaborator_type1");
                    break;
                case "isTrackerStockLocation":
                    replace = this.$t("headers.is_tracker_stock_location");
                    break;
            }
            return replace;
        },
        getKey(code) {
            let key = "";
            switch (code) {
                case "name":
                    key = "nameIcontains";
                    break;
                case "city":
                    key = "cityIcontains";
                    break;
                case "country":
                    key = "countryIcontains";
                    break;
                case "tags":
                    key = "tagsIcontains";
                    break;
                case "tenantPartnerName":
                    key = "tenantPartnerNameIcontains";
                    break;
                case "tenantPartnerPartnerType":
                    key = "tenantPartnerPartnerTypeIexact";
                    break;
                case "isTrackerStockLocation":
                    key = "isTrackerStockLocation";
                    break;
            }
            return key;
        },
        getCode(key) {
            let replace = key;
            switch (key) {
                case "nameIcontains":
                    replace = "name";
                    break;
                case "cityIcontains":
                    replace = "city";
                    break;
                case "countryIcontains":
                    replace = "country";
                    break;
                case "tagsIcontains":
                    replace = "tags";
                    break;
                case "tenantPartnerNameIcontains":
                    replace = "tenantPartnerName";
                    break;
                case "tenantPartnerPartnerTypeIexact":
                    replace = "tenantPartnerPartnerType";
                    break;
                case "isTrackerStockLocation":
                    replace = "isTrackerStockLocation";
                    break;
            }
            return replace;
        },
        async valuePopulate(item, key) {
            let _value = null;
            switch (key) {
                case "nameIcontains":
                case "cityIcontains":
                case "countryIcontains":
                case "tenantPartnerNameIcontains":
                    _value = item ? item : this.$t("general.all");
                    break;
                case "tenantPartnerPartnerTypeIexact":
                    _value = this.getPartnersTypes(item);
                    break;
                case "tagsIcontains":
                    _value = this.getTagsChoices(item);
                    break;
                case "isTrackerStockLocation":
                    _value = item ? this.$t("general.yes") : this.$t("general.no");
                    break;
            }
            return _value;
        },
        getTagsChoices(item) {
            const tagChoice = this.defaulttagsChoices.find(type => type.value === item);
            return tagChoice ? tagChoice.text : item;
        },
        async loadSavedFilters() {
            const preferences = store.state.user.me?.preferences;
            if (!preferences || !helper.isValidJson(preferences)) {
                this.resetFilters();
                return;
            }

            const parsedPreferences = JSON.parse(preferences);
            // Use tab name to get specific preferences
            const tabPreferences = parsedPreferences?.[`${this.tab}TablePreference`]?.[0];
            
            if (tabPreferences) {
                // Load sort preferences
                if (tabPreferences.sortPreference) {
                    const { orderBy } = tabPreferences.sortPreference;
                    if (orderBy) {
                        this.sortAsc = !orderBy.startsWith('-');
                        this.sortBy = orderBy.replace(/^-/, '');
                        this.filter.orderBy = orderBy;
                    }
                }

                // Load enabled filters
                if (tabPreferences.tableEnabledFilters) {
                    this.enabledFilters = tabPreferences.tableEnabledFilters;
                }

                // Load filter values
                if (tabPreferences.locationTableFilters) {
                    const savedFilters = tabPreferences.locationTableFilters;
                    this.filter = { ...this.filter, ...savedFilters };
                    this.menuObject = { ...this.menuObject, ...savedFilters };
                    this.copyMenuObject = { ...this.menuObject };

                    // Update filter values in the UI
                    await this.filterValues(this.filter);
                    this.updateFilter("load", this.filter);
                }
            }
        },
        updatePreferences() {
            const cleanEnabledFilters = this.enabledFilters.map(filter => ({
                name: filter.name,
                code: filter.code,
                enabled: true
            }));

            const filterValues = {};
            this.enabledFilters.forEach(filter => {
                if (this.filter[filter.code]) {
                    filterValues[filter.code] = this.filter[filter.code];
                }
            });

            // Use tab name for preferences key
            const preferences = {
                [`${this.tab}TablePreference`]: [
                    {
                        tableEnabledFilters: cleanEnabledFilters,
                        locationTableFilters: filterValues,
                        sortPreference: {
                            orderBy: this.filter.orderBy
                        }
                    }
                ]
            };

            helper.updateMyPreferences(preferences);
        },
        getPartnersTypes(item) {
            const partnerType = this.partnersTypes.find(type => type.value === item);
            return partnerType ? partnerType.text : item;
        },
        resetFilters() {
            // Reset all filter-related state
            this.filter = {
                first: 15,
                after: null,
                search: null,
                locationType_In: null,
                nameIcontains: null,
                tenantPartnerNameIcontains: null,
                tenantPartnerPartnerTypeIexact: null,
                tagsIcontains: null,
                cityIcontains: null,
                countryIcontains: null,
                isTrackerStockLocation: null
            };
            this.menuObject = {};
            this.copyMenuObject = {};
            this.enabledFilters = [];
            this.filterValuesObj = [];
            this.sortBy = 'name';
            this.sortAsc = false;
        },
        getLocationTypeLabel(locationType) {
            const locationTypeObj = this.publicLocationsTypes.find(type => type.value === locationType);
            return locationTypeObj ? locationTypeObj.text : locationType;
        },
        saveLocationType(menuItems, refMenu) {
            this.$emit('update:locationType', this.localLocationType);
            this.cancelFilter(refMenu);
        }
    }
};
</script>
<style scoped>
.content {
    height: 30px;
}
.content-select {
    padding: 0;
    max-width: 240px;
}
.classText {
    color: #0e0d35 !important;
    font-size: 13px;
    letter-spacing: 0em;
    text-align: left;
    white-space: nowrap; /* Prevents text from wrapping to the next line */
    overflow: hidden; /* Hides the overflowed content */
    text-overflow: ellipsis; /* Adds the ellipsis ('...') at the end of the truncated text */
    max-width: 100%;
}
</style>
