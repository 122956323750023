<template>
    <v-sheet class="pa-0" height="350px">
        <div>
            <v-card flat outlined :loading="$apollo.queries.trackerLocations.loading" v-resize="onResize">
                <!-- show loading -->
                <v-progress-linear
                    v-if="$apollo.queries.trackerLocations.loading"
                    dense
                    indeterminate
                    style="z-index: 800;"
                    color="primary"
                    class="mb-0"
                    :height="3"
                ></v-progress-linear>

                <v-overlay
                    v-if="noDataTracker && !$apollo.queries.trackerLocations.loading"
                    absolute
                    opacity
                    style="z-index: 800"
                >
                    <v-alert dense color="grey" type="warning">{{ $t("loading.no_trip") }}</v-alert>
                </v-overlay>

                <div
                    :style="{
                        height: 350 + 'px'
                    }"
                    id="alertMap"
                ></div>
            </v-card>
        </div>
    </v-sheet>
</template>

<script>
import gql from "graphql-tag";
L;
LDraw;
import L from "leaflet";
import LDraw from "leaflet-draw";
import helper from "@/utils/helper.js";
import { TenantPermissions } from "@/core/enum";
import Config from "@/utils/config.json";

export default {
    name: "AlertRuleTripMap",
    components: {},
    props: {
        viewType: {
            type: String,
            default: null
        },
        center: {
            type: Object,
            required: false,
            default: () => ({
                lat: 40.73061,
                lng: -73.935242
            })
        },
        startDate: {
            type: String
        },
        endDate: {
            type: String
        },
        alert: {
            type: Object,
            required: true
        }
        // center object { lat: 40.730610, lng: -73.935242 }
    },
    data() {
        return {
            trackerLocations: [],
            platform: null,
            apikey: Config.VUE_APP_MAP_API_KEY,
            here_map_url: `https://2.base.maps.ls.hereapi.com/maptile/2.1/maptile/newest/normal.day/{z}/{x}/{y}/512/png8?apiKey=${Config.VUE_APP_MAP_API_KEY}&ppi=320`,
            apiGoogleKey: Config.VUE_APP_GOOGLE_MAP_API_KEY,
            mapHeight: "400px",
            cardHeight: "400px",
            zoom: 2,
            map: null,
            ui: null,
            noDataTracker: true,
            loadingMarkers: true,
            colors: [
                "rgba(288, 0, 0, 1)",
                "rgba(0, 288, 0, 1)",
                "rgba(100, 0, 0, 1)",
                "rgba(0, 100, 0, 1)",
                "rgba(0, 0, 200, 1)"
            ],
            lines: [],
            markers: [],
            here_map_attribution: "",
            allMarkers: [],
            numberOfLoadedRoutes: 0,
            numberOfRoutes: 0
        };
    },
    updated() {
        //fix map render everytime the component updates
        this.resizeMap();
    },
    beforeCreate() {
        this.hasPermission = helper.hasPermission;
        this.hasOrganisationPermission = helper.hasOrganisationPermission;
        this.TenantPermissions = TenantPermissions;
    },
    created() {
        this.formatTemperature = helper.formatTemperature;
        this.getTemperatureSymbol = helper.getTemperatureSymbol;
        this.formatDateTime = helper.formatDateTime;
        this.relativeTime = helper.relativeTime;
    },
    computed: {
        me() {
            return helper.me();
        },
        deviceTime_Gte() {
            if (this.startDate != null) {
                return this.startDate;
            }
            return null;
        },
        deviceTime_Lte() {
            if (this.endDate != null) {
                return this.endDate;
            }
            return null;
        }
    },
    apollo: {
        trackerLocations: {
            query: gql`
                query TrackerLocations(
                    $deviceTimeGte: DateTime
                    $deviceTimeLte: DateTime
                    $tracker: ID
                    $orderBy: String
                ) {
                    trackerLocations(
                        deviceTime_Gte: $deviceTimeGte
                        deviceTime_Lte: $deviceTimeLte
                        tracker: $tracker
                        orderBy: $orderBy
                    ) {
                        edges {
                            node {
                                id
                                deviceTime
                                latitude
                                longitude
                                tracker {
                                    id
                                }
                            }
                        }
                    }
                }
            `,
            variables() {
                return {
                    tracker: this.alert.trackerMergedSensorData?.tracker.id,
                    deviceTimeLte: this.deviceTime_Lte,
                    deviceTimeGte: this.deviceTime_Gte,
                    orderBy: "deviceTime"
                };
            },
            fetchPolicy: "cache-and-network",
            nextFetchPolicy: "cache-first", // this setting can avoid query again after fetchMore
            debounce: 0,
            result({ loading, data }) {
                if (!loading) {
                    this.trackerLocations = data.trackerLocations;

                    if (data.trackerLocations.edges.length === 0) {
                        this.noDataTracker = true;
                        this.loadingMarkers = false;
                    } else {
                        this.noDataTracker = false;
                        this.createLine();
                    }

                    this.createAlert();
                    this.createLocations();
                }
            },
            skip() {
                return !this.alert.trackerMergedSensorData?.tracker.id;
            }
        }
    },
    mounted() {
        this.$nextTick(() => {
            this.buildMap();
            this.onResize();
            window.addEventListener("resize", this.onResize);
        });
        /*  this.buildMap();
        this.onResize();
        window.addEventListener("resize", this.onResize); */
    },
    watch: {
        allMarkers: {
            handler: function(val) {
                const removeLatLongUndefined = arr => {
                    return arr.filter(
                        item => Array.isArray(item) && item.length === 2 && item[0] != null && item[1] != null
                    );
                };

                // Use the filtered version of allMarkers
                let _allMarkers = removeLatLongUndefined(this.allMarkers);

                if (_allMarkers && _allMarkers.length > 0 && !this.noDataTracker) {
                    if (this.loadingMarkers) {
                        // Use the filtered _allMarkers for fitBounds
                        this.map.fitBounds(_allMarkers, { padding: [50, 50] });

                        if (this.map.getZoom() > 12) {
                            this.map.setZoom(12);
                        }

                        // Add tile layer to the map
                        let tileLayer = L.tileLayer(this.here_map_url, {
                            attribution: this.here_map_attribution
                        });

                        tileLayer.addTo(this.map);

                        // Use the filtered _allMarkers for resetZoom
                        this.resetZoom(_allMarkers);
                    }
                } else {
                    // Fallback to center and zoom if no markers
                    this.map.setView(this.center, this.zoom);
                }
            },
            deep: true
        }
    },
    methods: {
        buildMap() {
            //remove old map
            if (this.map != null) {
                this.map.off;
                this.map.remove();
            }

            // Set up the map
            const style = "normal.day.grey";
            const apikey = process.env["VUE_APP_MAP_API_KEY"];

            const hereTileUrl = `https://2.base.maps.ls.hereapi.com/maptile/2.1/maptile/newest/${style}/{z}/{x}/{y}/512/png8?apiKey=${apikey}&ppi=320`;

            this.map = L.map("alertMap", {
                center: this.center,
                zoom: this.zoom,
                maxZoom: 20,
                minZoom: 1,
                layers: [
                    L.tileLayer(hereTileUrl, {
                        attribution: this.here_map_attribution
                    })
                ]
            });

            this.map.zoomControl.setPosition("topright");
        },
        resetZoom(bounds) {
            L.Control.ResetView = L.Control.extend({
                onAdd: function(map) {
                    let container = L.DomUtil.create("div", "leaflet-bar leaflet-control leaflet-control-custom");

                    container.style.backgroundColor = "white";
                    container.innerHTML = `<i class='material-icons primary--text d-flex justify-center align-center mt-1'>
                                                my_location
                                            </i>`;

                    container.style.backgroundRepeat = "no-repeat";
                    container.style.backgroundPosition = "center";
                    container.style.backgroundSize = "20px 20px";
                    container.style.width = "50px";
                    container.style.height = "35px";

                    container.style.cursor = "pointer";
                    container.onclick = function() {
                        if (bounds) {
                            map.fitBounds(bounds);
                            if (map.getZoom() > 12) {
                                map.setZoom(12);
                            }
                        } else {
                            map.setView(this.center, this.zoom);
                        }
                    };
                    return container;
                }
            });

            L.control.resetView = function(opts) {
                return new L.Control.ResetView(opts);
            };

            L.control.resetView({ position: "bottomleft" }).addTo(this.map);
        },
        onResize() {
            this.$nextTick(() => {
                if (this.viewType == "tab") {
                    this.mapHeight = window.innerHeight - 251 + "px";
                    this.cardHeight = window.innerHeight - 251 - 120 + "px";
                } else if (this.viewType == "list") {
                    this.mapHeight = window.innerHeight * 0.5 + "px";
                    this.cardHeight = window.innerHeight * 0.4 + "px";
                } else {
                    this.mapHeight = window.innerHeight * 0.5 + "px";
                }
            });
        },
        resizeMap() {
            /*** THIS FUNCTION IS RESPONSIBLE FOR FIXING MAP RENDERING ISSUE(only 1 or 2 tiles in the corner), RUN IT ON THE RIGHT LIFECYCLE HOOK ***/
            setTimeout(() => {
                //mapObject is an object which gives you acess to leaflet methods
                this.map?.invalidateSize();
            }, 50);
        },
        createLine() {
            let counter = 0;
            let linePoints = [];
            let lineMarkers = [];

            let _origem = {
                lat: this.converStringToNumber(this.alert.trip?.originLocation?.latitude) || null,
                lng: this.converStringToNumber(this.alert.trip?.originLocation?.longitude) || null
            };

            if (_origem.lat && _origem.lng) {
                // Add the origin marker to the map:
                lineMarkers.push(
                    L.marker([_origem.lat, _origem.lng], { icon: L.icon({ iconUrl: "/img/marker-icon.png" }) })
                );

                // Add the origin to the line:
                linePoints.push([_origem.lat, _origem.lng]);
            }

            //current alert time
            const { alertTime, trackerMergedSensorData } = this.alert;
            let alertPositiontime = null;
            if (trackerMergedSensorData?.deviceTime) {
                alertPositiontime = trackerMergedSensorData.deviceTime;
            } else {
                alertPositiontime = alertTime;
            }

            this.trackerLocations.edges.map(data => {
                let point = data.node;
                if (point?.latitude && point?.longitude && new Date(point.deviceTime) <= new Date(alertPositiontime)) {
                    this.allMarkers.push([point?.latitude, point?.longitude]);
                    linePoints.push([point?.latitude, point?.longitude]);
                }
            });

            var polyline = L.polyline(linePoints, {
                color: this.colors[this.getIndex(counter)]
            });

            // Loop through your markers and compare each one to every other marker
            for (let i = 0; i < lineMarkers.length; i++) {
                for (let j = i + 1; j < lineMarkers.length; j++) {
                    const marker1 = lineMarkers[i];
                    const marker2 = lineMarkers[j];

                    // Calculate the distance between the two markers
                    const distance = marker1.getLatLng().distanceTo(marker2.getLatLng());

                    // If the distance is less than the threshold, remove one of the markers
                    if (distance < 10) {
                        lineMarkers.splice(j, 1);
                        j--;
                    }
                }
            }

            lineMarkers.forEach(marker => {
                marker.addTo(this.map);
            });

            // Add the polyline to the map:
            polyline.addTo(this.map);

            if (this.allMarkers?.length === 0) {
                let bounds = polyline.getBounds();
                let southWest = bounds.getSouthWest();
                let northEast = bounds.getNorthEast();
                let distance = 0.1;
                let newSouthWest = L.latLng(southWest.lat - distance, southWest.lng - distance);
                let newNorthEast = L.latLng(northEast.lat + distance, northEast.lng + distance);
                let newBounds = L.latLngBounds(newSouthWest, newNorthEast);
                this.map.fitBounds(newBounds);
            }
        },
        getIndex(val) {
            return val % this.colors.length;
        },
        createAlert() {
            let alert = this.alert;

            if (alert) {
                const { deviceTime, trackerMergedSensorData } = alert;

                if (trackerMergedSensorData) {
                    let { latitude, longitude } = trackerMergedSensorData;

                    let alertSvg =
                        '<?xml version="1.0" encoding="iso-8859-1"?><svg height="30px" width="30px" version="1.1" id="Layer_1" xmlns="http://www.w3.org/2000/svg" xmlns:xlink="http://www.w3.org/1999/xlink" viewBox="0 0 512 512" xml:space="preserve"><path style="fill:#FFDC35;" d="M507.494,426.066L282.864,53.536c-5.677-9.415-15.87-15.172-26.865-15.172 c-10.994,0-21.188,5.756-26.865,15.172L4.506,426.066c-5.842,9.689-6.015,21.774-0.451,31.625 c5.564,9.852,16.001,15.944,27.315,15.944h449.259c11.314,0,21.751-6.093,27.315-15.944 C513.509,447.839,513.336,435.755,507.494,426.066z"/><path style="fill:#FFDC35;" d="M255.999,38.365c-10.994,0-21.188,5.756-26.865,15.172L4.506,426.066 c-5.842,9.689-6.015,21.774-0.451,31.625c5.564,9.852,16.001,15.944,27.315,15.944h224.629L255.999,38.365L255.999,38.365z"/><path style="fill:#F20013;" d="M445.326,432.791H67.108c-3.591,0-6.911-1.909-8.718-5.012c-1.807-3.104-1.827-6.934-0.055-10.056 L247.23,85.028c1.792-3.155,5.139-5.106,8.767-5.107c0.001,0,0.003,0,0.004,0c3.626,0,6.974,1.946,8.767,5.099l189.324,332.694 c1.777,3.123,1.759,6.955-0.047,10.061S448.918,432.791,445.326,432.791z M84.436,412.616h343.543L256.013,110.423L84.436,412.616z" /><path style="fill:#FF4B00;" d="M256.332,412.616H84.436l171.576-302.192l-0.01-30.501h-0.005c-3.628,0.001-6.976,1.951-8.767,5.107 L58.336,417.722c-1.773,3.123-1.752,6.953,0.055,10.056c1.807,3.104,5.127,5.012,8.718,5.012h189.224v-20.175H256.332z"/><path style="fill:#533F29;" d="M279.364,376.883c0,12.344-10.537,23.182-22.88,23.182c-13.246,0-23.182-10.838-23.182-23.182 c0-12.644,9.935-23.182,23.182-23.182C268.826,353.701,279.364,364.238,279.364,376.883z M273.644,319.681 c0,9.333-10.236,13.246-17.462,13.246c-9.634,0-17.762-3.914-17.762-13.246c0-35.826-4.214-87.308-4.214-123.134 c0-11.741,9.634-18.365,21.977-18.365c11.741,0,21.677,6.623,21.677,18.365C277.858,232.373,273.644,283.855,273.644,319.681z"/></svg>';

                    if (!latitude || !longitude) {
                        let previousLocation = this.getPreviousLocationBeforeAlert(alert);

                        if (!previousLocation) return;
                        latitude = previousLocation?.node?.latitude;
                        longitude = previousLocation?.node?.longitude;
                    }

                    //show the alert on the current location
                    let alertIcon = null;
                    let _popupHTML = null;

                    alertIcon = L.divIcon({
                        html: alertSvg,
                        className: "custom-div-icon",
                        iconSize: [100, 100],
                        iconAnchor: [20, 20]
                    });

                    _popupHTML = `
                        <div>
                            <h3><b>${alert.tenantAlertRule.name}</b></h3>
                            <div>Recorded at ${this.formatDateTime(alert.deviceTime)}</div>
                            <div>Triggered at ${this.formatDateTime(alert.alertTime)}</div>
                        </div>
                        
                    </br>`;

                    let temperature = trackerMergedSensorData.temperature;
                    let light = trackerMergedSensorData.light;
                    let humidity = trackerMergedSensorData.humidity;
                    let externalTemperature = trackerMergedSensorData.externalTemperature;
                    let probeTemperature = trackerMergedSensorData.probeTemperature;
                    let pressure = trackerMergedSensorData.pressure;
                    let co2 = trackerMergedSensorData.co2;

                    if (
                        temperature != null ||
                        light != null ||
                        humidity != null ||
                        externalTemperature != null ||
                        probeTemperature != null ||
                        pressure != null ||
                        co2 != null
                    ) {
                        _popupHTML += `<div><h4>${this.$t("headers.sensor_data")}:</h4>`;

                        if (temperature != null) {
                            _popupHTML += `<b>${this.$t("general.temperature")}:</b> ${this.formatTemperature(
                                Number(temperature)
                            ).toFixed(2)} ${this.getTemperatureSymbol()}<br>`;
                        }
                        if (externalTemperature != null) {
                            _popupHTML += `<b>${this.$t("general.external_temperature")}:</b> ${this.formatTemperature(
                                Number(externalTemperature)
                            ).toFixed(2)} ${this.getTemperatureSymbol()}<br>`;
                        }
                        if (probeTemperature != null) {
                            _popupHTML += `<b>${this.$t("general.probe_temperature")}:</b> ${this.formatTemperature(
                                Number(probeTemperature)
                            ).toFixed(2)} ${this.getTemperatureSymbol()}<br>`;
                        }
                        if (humidity != null) {
                            _popupHTML += `<b>${this.$t("general.humidity")}:</b> ${humidity} %<br>`;
                        }
                        if (light != null) {
                            _popupHTML += `<b>${this.$t("general.light")}:</b> ${light} Lux<br>`;
                        }
                        if (pressure != null) {
                            _popupHTML += `<b>${this.$t("general.pressure")}:</b> ${pressure} <br>`;
                        }
                        if (co2 != null) {
                            _popupHTML += `<b>CO2:</b> ${co2} <br>`;
                        }
                        _popupHTML += `</div>`;
                    }

                    // _popupHTML += `<span>${this.relativeAlertTime(alert.alertTime)}</span>`;

                    let alertMarker = new L.marker(
                        [this.converStringToNumber(latitude), this.converStringToNumber(longitude)],
                        {
                            icon: alertIcon,
                            zIndexOffset: 0
                        }
                    );

                    alertMarker.bindPopup(_popupHTML);
                    alertMarker.addTo(this.map);
                    alertMarker.openPopup();

                    if (latitude && longitude) {
                        this.allMarkers.push([
                            this.converStringToNumber(latitude),
                            this.converStringToNumber(longitude)
                        ]);
                    }
                }
            }
        },
        getPreviousLocationBeforeAlert(alert) {
            if (!this.trackerLocations || !this.trackerLocations?.edges) return null;
            let locations = this.trackerLocations.edges.filter(
                c => c.node.tracker.id === alert.trackerMergedSensorData.tracker.id
            );

            if (locations) {
                let newData = locations
                    .filter(c => c.node.deviceTime < alert.deviceTime)
                    .sort((a, b) => new Date(b.deviceTime) - new Date(a.deviceTime));

                if (newData.length > 0) {
                    return newData[0];
                }
            }
            return null;
        },
        createAlertLastLocationPoint() {
            let alertSvg =
                '<?xml version="1.0" encoding="iso-8859-1"?><svg height="30px" width="30px" version="1.1" id="Layer_1" xmlns="http://www.w3.org/2000/svg" xmlns:xlink="http://www.w3.org/1999/xlink" viewBox="0 0 512 512" xml:space="preserve"><path style="fill:#FFDC35;" d="M507.494,426.066L282.864,53.536c-5.677-9.415-15.87-15.172-26.865-15.172 c-10.994,0-21.188,5.756-26.865,15.172L4.506,426.066c-5.842,9.689-6.015,21.774-0.451,31.625 c5.564,9.852,16.001,15.944,27.315,15.944h449.259c11.314,0,21.751-6.093,27.315-15.944 C513.509,447.839,513.336,435.755,507.494,426.066z"/><path style="fill:#FFDC35;" d="M255.999,38.365c-10.994,0-21.188,5.756-26.865,15.172L4.506,426.066 c-5.842,9.689-6.015,21.774-0.451,31.625c5.564,9.852,16.001,15.944,27.315,15.944h224.629L255.999,38.365L255.999,38.365z"/><path style="fill:#F20013;" d="M445.326,432.791H67.108c-3.591,0-6.911-1.909-8.718-5.012c-1.807-3.104-1.827-6.934-0.055-10.056 L247.23,85.028c1.792-3.155,5.139-5.106,8.767-5.107c0.001,0,0.003,0,0.004,0c3.626,0,6.974,1.946,8.767,5.099l189.324,332.694 c1.777,3.123,1.759,6.955-0.047,10.061S448.918,432.791,445.326,432.791z M84.436,412.616h343.543L256.013,110.423L84.436,412.616z" /><path style="fill:#FF4B00;" d="M256.332,412.616H84.436l171.576-302.192l-0.01-30.501h-0.005c-3.628,0.001-6.976,1.951-8.767,5.107 L58.336,417.722c-1.773,3.123-1.752,6.953,0.055,10.056c1.807,3.104,5.127,5.012,8.718,5.012h189.224v-20.175H256.332z"/><path style="fill:#533F29;" d="M279.364,376.883c0,12.344-10.537,23.182-22.88,23.182c-13.246,0-23.182-10.838-23.182-23.182 c0-12.644,9.935-23.182,23.182-23.182C268.826,353.701,279.364,364.238,279.364,376.883z M273.644,319.681 c0,9.333-10.236,13.246-17.462,13.246c-9.634,0-17.762-3.914-17.762-13.246c0-35.826-4.214-87.308-4.214-123.134 c0-11.741,9.634-18.365,21.977-18.365c11.741,0,21.677,6.623,21.677,18.365C277.858,232.373,273.644,283.855,273.644,319.681z"/></svg>';

            let lastLocation = this.trackerLocations.edges[this.trackerLocations.edges.length - 1]?.node;
            let alert = this.alert;

            if (lastLocation) {
                let alertIcon = null;
                let _popupHTML = null;

                alertIcon = L.divIcon({
                    html: alertSvg,
                    className: "custom-div-icon",
                    iconSize: [100, 100],
                    iconAnchor: [0, 0]
                });

                _popupHTML = `
                    <div>
                        <h3><b>${alert.tenantAlertRule.name}</b></h3>
                        <div>Recorded at ${this.formatDateTime(alert.deviceTime)}</div>
                        <div>Triggered at ${this.formatDateTime(alert.alertTime)}</div>
                    </div>
                    </br>
                `;

                if (alert.trackerMergedSensorData) {
                    let temperature = alert.trackerMergedSensorData.temperature;
                    let light = alert.trackerMergedSensorData.light;
                    let humidity = alert.trackerMergedSensorData.humidity;
                    let externalTemperature = alert.trackerMergedSensorData.externalTemperature;
                    let probeTemperature = alert.trackerMergedSensorData.probeTemperature;
                    let pressure = alert.trackerMergedSensorData.pressure;
                    let co2 = alert.trackerMergedSensorData.co2;

                    if (
                        temperature != null ||
                        light != null ||
                        humidity != null ||
                        externalTemperature != null ||
                        probeTemperature != null ||
                        pressure != null ||
                        co2 != null
                    ) {
                        _popupHTML += `<div><h4>${this.$t("headers.sensor_data")}:</h4>`;

                        if (temperature != null) {
                            _popupHTML += `<b>${this.$t("general.temperature")}:</b> ${this.formatTemperature(
                                Number(temperature)
                            ).toFixed(2)} ${this.getTemperatureSymbol()}<br>`;
                        }
                        if (externalTemperature != null) {
                            _popupHTML += `<b>${this.$t("general.external_temperature")}:</b> ${this.formatTemperature(
                                Number(externalTemperature)
                            ).toFixed(2)} ${this.getTemperatureSymbol()}<br>`;
                        }
                        if (probeTemperature != null) {
                            _popupHTML += `<b>${this.$t("general.probe_temperature")}:</b> ${this.formatTemperature(
                                Number(probeTemperature)
                            ).toFixed(2)} ${this.getTemperatureSymbol()}<br>`;
                        }
                        if (humidity != null) {
                            _popupHTML += `<b>${this.$t("general.humidity")}:</b> ${humidity} %<br>`;
                        }
                        if (light != null) {
                            _popupHTML += `<b>${this.$t("general.light")}:</b> ${light} Lux<br>`;
                        }

                        if (pressure != null) {
                            _popupHTML += `<b>${this.$t("general.pressure")}:</b> ${pressure} <br>`;
                        }
                        if (co2 != null) {
                            _popupHTML += `<b>CO2:</b> ${co2} <br>`;
                        }
                        _popupHTML += `</div>`;
                    }
                }

                // _popupHTML += `<div>${this.relativeAlertTime(alert.alertTime)}</div>`;

                let alertMarker = new L.marker(
                    [
                        this.converStringToNumber(lastLocation.latitude),
                        this.converStringToNumber(lastLocation.longitude)
                    ],
                    {
                        icon: alertIcon,
                        zIndexOffset: 0
                    }
                );

                alertMarker.bindPopup(_popupHTML);

                alertMarker.addTo(this.map);

                alertMarker.openPopup();

                if (lastLocation.latitude && lastLocation.longitude) {
                    this.allMarkers.push([
                        this.converStringToNumber(lastLocation.latitude),
                        this.converStringToNumber(lastLocation.longitude)
                    ]);
                }
            }
        },
        relativeAlertTime(time) {
            let now = new Date();
            let inputDate = new Date(time);

            if (isNaN(inputDate.getTime())) {
                return "Invalid date";
            }

            let formattedDate = helper.relativeTime(time);

            //let _text = `<b>${this.$t("general.last_known_location")} </b>` + formattedDate;
            let _text = formattedDate;

            return _text;
        },
        createLocations() {
            let destination_marker =
                '<svg width="45px" height="45px" viewBox="0 0 32 32" enable-background="new 0 0 32 32" version="1.1" xml:space="preserve" xmlns="http://www.w3.org/2000/svg" xmlns:xlink="http://www.w3.org/1999/xlink" fill="#000000"><g id="SVGRepo_bgCarrier" stroke-width="0"/><g id="SVGRepo_tracerCarrier" stroke-linecap="round" stroke-linejoin="round"/><g id="SVGRepo_iconCarrier"><g id="Layer_2"/><g id="Layer_3"/><g id="Layer_4"/><g id="Layer_5"/><g id="Layer_6"/><g id="Layer_7"/><g id="Layer_8"/><g id="Layer_9"/><g id="Layer_10"/><g id="Layer_11"/><g id="Layer_12"/><g id="Layer_13"/><g id="Layer_14"/><g id="Layer_15"/><g id="Layer_16"/><g id="Layer_17"/><g id="Layer_18"/><g id="Layer_19"/><g id="Maps_11_"/><g id="Maps_10_"/><g id="Maps_9_"/><g id="Maps_8_"/><g id="Maps_7_"/><g id="Maps_6_"/><g id="Maps_5_"/><g id="Maps_4_"/><g id="Maps_3_"/><g id="Maps_2_"/><g id="Maps_1_"><path d="M16,1C10.49,1,6,5.5,6,11.04c0,5.25,8.34,13.72,9.29,14.67C15.49,25.9,15.74,26,16,26s0.51-0.1,0.71-0.29 C17.66,24.76,26,16.29,26,11.04C26,5.5,21.51,1,16,1z" fill="#b54040"/><path d="M20,11.04c0,2.2-1.79,4-4,4s-4-1.8-4-4c0-2.21,1.79-4,4-4S20,8.83,20,11.04z" fill="#ededed"/><g><path d="M16,31c-0.2354,0-0.4595-0.0005-0.6943-0.0107C8.21,30.8652,1,28.5957,1,24.5 c0-2.0806,1.9492-3.8872,5.4893-5.0874c0.5225-0.1748,1.0903,0.103,1.2681,0.6265c0.1772,0.5229-0.103,1.0903-0.6265,1.2681 C4.583,22.1709,3,23.394,3,24.5c0,2.0215,5.0791,4.3623,12.3677,4.4902C15.5986,29,15.7925,29.001,16,29 c0.208,0,0.4009,0,0.6055-0.0088C23.9209,28.8623,29,26.5215,29,24.5c0-1.106-1.583-2.3291-4.1309-3.1929 c-0.5234-0.1777-0.8037-0.7451-0.6265-1.2681c0.1777-0.5234,0.7451-0.8022,1.2681-0.6265C29.0508,20.6128,31,22.4194,31,24.5 c0,4.0957-7.21,6.3652-14.3325,6.4902C16.4595,30.9995,16.2354,31,16,31z" fill="#ededed"/></g></g><g id="Maps"/></g></svg>';
            let origin_marker =
                '<svg width="45px" height="45px" viewBox="0 0 32 32" enable-background="new 0 0 32 32" version="1.1" xml:space="preserve" xmlns="http://www.w3.org/2000/svg" xmlns:xlink="http://www.w3.org/1999/xlink" fill="#000000"><g id="SVGRepo_bgCarrier" stroke-width="0"/><g id="SVGRepo_tracerCarrier" stroke-linecap="round" stroke-linejoin="round"/><g id="SVGRepo_iconCarrier"><g id="Layer_2"/><g id="Layer_3"/><g id="Layer_4"/><g id="Layer_5"/><g id="Layer_6"/><g id="Layer_7"/><g id="Layer_8"/><g id="Layer_9"/><g id="Layer_10"/><g id="Layer_11"/><g id="Layer_12"/><g id="Layer_13"/><g id="Layer_14"/><g id="Layer_15"/><g id="Layer_16"/><g id="Layer_17"/><g id="Layer_18"/><g id="Layer_19"/><g id="Maps_11_"/><g id="Maps_10_"/><g id="Maps_9_"/><g id="Maps_8_"/><g id="Maps_7_"/><g id="Maps_6_"/><g id="Maps_5_"/><g id="Maps_4_"/><g id="Maps_3_"/><g id="Maps_2_"/><g id="Maps_1_"><path d="M16,1C10.49,1,6,5.5,6,11.04c0,5.25,8.34,13.72,9.29,14.67C15.49,25.9,15.74,26,16,26s0.51-0.1,0.71-0.29 C17.66,24.76,26,16.29,26,11.04C26,5.5,21.51,1,16,1z" fill="#4db540"/><path d="M20,11.04c0,2.2-1.79,4-4,4s-4-1.8-4-4c0-2.21,1.79-4,4-4S20,8.83,20,11.04z" fill="#ededed"/><g><path d="M16,31c-0.2354,0-0.4595-0.0005-0.6943-0.0107C8.21,30.8652,1,28.5957,1,24.5 c0-2.0806,1.9492-3.8872,5.4893-5.0874c0.5225-0.1748,1.0903,0.103,1.2681,0.6265c0.1772,0.5229-0.103,1.0903-0.6265,1.2681 C4.583,22.1709,3,23.394,3,24.5c0,2.0215,5.0791,4.3623,12.3677,4.4902C15.5986,29,15.7925,29.001,16,29 c0.208,0,0.4009,0,0.6055-0.0088C23.9209,28.8623,29,26.5215,29,24.5c0-1.106-1.583-2.3291-4.1309-3.1929 c-0.5234-0.1777-0.8037-0.7451-0.6265-1.2681c0.1777-0.5234,0.7451-0.8022,1.2681-0.6265C29.0508,20.6128,31,22.4194,31,24.5 c0,4.0957-7.21,6.3652-14.3325,6.4902C16.4595,30.9995,16.2354,31,16,31z" fill="#ededed"/></g></g><g id="Maps"/></g></svg>';

            if (
                this.alert.trip?.originLocation &&
                this.alert.trip?.originLocation.latitude &&
                this.alert.trip?.originLocation.longitude
            ) {
                L.circle(
                    [
                        this.converStringToNumber(this.alert.trip?.originLocation.latitude),
                        this.converStringToNumber(this.alert.trip?.originLocation.longitude)
                    ],
                    {
                        radius: this.alert.trip?.originLocation.radius,
                        color: "success",
                        fillOpacity: 0.1
                    }
                ).addTo(this.map);
                let icon = L.divIcon({
                    html: origin_marker,
                    className: "custom-div-icon",
                    iconSize: [45, 45],
                    iconAnchor: [22.5, 22.5]
                });
                let marker = new L.marker(
                    [
                        this.converStringToNumber(this.alert.trip?.originLocation.latitude),
                        this.converStringToNumber(this.alert.trip?.originLocation.longitude)
                    ],
                    {
                        icon: icon
                    }
                );

                marker.bindPopup(
                    `<div style="font-size: 12px"><center><b>Origin</b><br>${this.alert.trip?.originLocation.name}</center></div>`
                );
                marker.addTo(this.map);

                this.allMarkers.push([
                    this.converStringToNumber(this.alert.trip?.originLocation.latitude),
                    this.converStringToNumber(this.alert.trip?.originLocation.longitude)
                ]);
            }

            if (
                this.alert.trip?.destinationLocation &&
                this.alert.trip?.destinationLocation.latitude &&
                this.alert.trip?.destinationLocation.longitude
            ) {
                L.circle(
                    [
                        this.converStringToNumber(this.alert.trip?.destinationLocation.latitude),
                        this.converStringToNumber(this.alert.trip?.destinationLocation.longitude)
                    ],
                    {
                        radius: this.alert.trip?.destinationLocation.radius,
                        color: "error",
                        fillOpacity: 0.1
                    }
                ).addTo(this.map);
                let icon = L.divIcon({
                    html: destination_marker,
                    className: "custom-div-icon",
                    iconSize: [45, 45],
                    iconAnchor: [22.5, 22.5]
                });
                let marker = new L.marker(
                    [
                        this.converStringToNumber(this.alert.trip?.destinationLocation.latitude),
                        this.converStringToNumber(this.alert.trip?.destinationLocation.longitude)
                    ],
                    {
                        icon: icon
                    }
                );

                marker.bindPopup(
                    `<div style="font-size: 12px"><center><b>Destination</b><br>${this.alert.trip?.destinationLocation.name}</center></div>`
                );
                marker.addTo(this.map);
                this.allMarkers.push([
                    this.converStringToNumber(this.alert.trip?.destinationLocation.latitude),
                    this.converStringToNumber(this.alert.trip?.destinationLocation.longitude)
                ]);
            }
        },

        converStringToNumber(val) {
            if (val == null) {
                return null;
            }

            if (isNaN(val)) {
                return val;
            } else {
                return Number(val);
            }
        }
    }
};
</script>

<style scoped>
#alertMap {
    /* width: 60vw; */
    width: 100%;
    height: 80vh;
    margin: 0;
    padding: 0;

    /* margin: 5% auto; */
    background-color: #ffffff;
}
.leaflet-popup-close-button {
    display: none;
}
</style>
