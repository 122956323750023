<template>
    <v-row align="start" class="align-center" dense>
        <v-col cols="12" xs="12" sm="12" md="12" lg="12" xl="12">
            <v-autocomplete
                v-model="codeSelected"
                :items="dynamicFilters"
                :label="$t('trips.filter')"
                item-text="name"
                item-value="code"
                clearable
                :readonly="isReadOnly"
                :disabled="isReadOnly"
                dense
                outlined
                color="primary"
                hide-details
                :menu-props="{
                    offsetX: true,
                    right: true
                }"
                @change="updateCodeSelected"
            ></v-autocomplete>
        </v-col>
        <v-col cols="12" xs="12" sm="12" md="12" lg="12" xl="12">
            <!-- ?Fake field -->
            <v-text-field
                v-if="codeSelected === '' || codeSelected === null"
                v-model="menuObject['']"
                :label="getFilterChoiceNameByCode('')"
                clearable
                hide-details
                dense
                outlined
                disabled
            ></v-text-field>

            <!-- Fields selection -->
            <v-text-field
                v-if="codeSelected === 'referenceNumber'"
                v-model="menuObject['referenceNumber_Icontains']"
                :label="getFilterChoiceNameByCode('referenceNumber')"
                clearable
                hide-details
                dense
                outlined
            ></v-text-field>

            <!--!Freights Reference Number -->
            <v-text-field
                v-if="codeSelected === 'freights'"
                v-model="menuObject['tripFreightSet_ReferenceNumber_Icontains']"
                :label="getFilterChoiceNameByCode('freights')"
                clearable
                hide-details
                dense
                outlined
            ></v-text-field>
            <!--!Trip Trackers -->
            <v-text-field
                v-if="codeSelected === 'tracker'"
                v-model="menuObject['trackerNumber']"
                :label="getFilterChoiceNameByCode('tracker')"
                clearable
                hide-details
                dense
                outlined
            >
                <template v-slot:append>
                    <v-tooltip bottom content-class="tooltipContent">
                        <template v-slot:activator="{ on }">
                            <v-icon v-on="on" color="primary">
                                info
                            </v-icon>
                        </template>
                        <span>{{ $t("general.info_tracker_empty") }}</span>
                    </v-tooltip>
                </template>
            </v-text-field>
            <!--!Trip Container Number -->
            <v-text-field
                v-if="codeSelected === 'containerNumber'"
                v-model="menuObject['containerNumber']"
                :label="getFilterChoiceNameByCode('containerNumber')"
                clearable
                hide-details
                dense
                outlined
            >
                <template v-slot:append>
                    <v-tooltip bottom content-class="tooltipContent">
                        <template v-slot:activator="{ on }">
                            <v-icon v-on="on" color="primary">
                                info
                            </v-icon>
                        </template>
                        <span>{{ $t("general.info_container_empty") }}</span>
                    </v-tooltip>
                </template>
            </v-text-field>
            <!-- !Trip Air Waybill Number -->
            <v-text-field
                v-if="codeSelected === 'airWaybillNumber'"
                v-model="menuObject['awbNumber']"
                :label="getFilterChoiceNameByCode('airWaybillNumber')"
                clearable
                hide-details
                dense
                outlined
            >
                <template v-slot:append>
                    <v-tooltip bottom content-class="tooltipContent">
                        <template v-slot:activator="{ on }">
                            <v-icon v-on="on" color="primary">
                                info
                            </v-icon>
                        </template>
                        <span>{{ $t("general.info_awb_empty") }}</span>
                    </v-tooltip>
                </template>
            </v-text-field>

            <!-- !Trip Bill of lading  -->
            <v-text-field
                v-if="codeSelected === 'blNumber'"
                v-model="menuObject['blNumber']"
                :label="getFilterChoiceNameByCode('blNumber')"
                clearable
                hide-details
                dense
                outlined
            >
                <template v-slot:append>
                    <v-tooltip bottom content-class="tooltipContent">
                        <template v-slot:activator="{ on }">
                            <v-icon v-on="on" color="primary">
                                info
                            </v-icon>
                        </template>
                        <span>{{ $t("general.info_bl_empty") }}</span>
                    </v-tooltip>
                </template>
            </v-text-field>

            <!-- !Last Location-->
            <v-text-field
                v-if="codeSelected === 'trackerLastLocation'"
                v-model="menuObject['trackerLastLocation_Icontains']"
                :label="getFilterChoiceNameByCode('trackerLastLocation')"
                clearable
                hide-details
                dense
                outlined

            ></v-text-field>

            <!-- !Origin -->
            <AutocompleteLocation
                v-if="codeSelected === 'origin'"
                v-model="menuObject['origin']"
                :label="getFilterChoiceNameByCode('origin')"
                :outlined="true"
                :clearable="true"
                :dense="true"
                :multiple="true"
            ></AutocompleteLocation>

            <!-- !Destination -->
            <AutocompleteLocation
                v-if="codeSelected === 'destination'"
                v-model="menuObject['destination']"
                :label="getFilterChoiceNameByCode('destination')"
                :outlined="true"
                :clearable="true"
                :dense="true"
                :multiple="true"
            ></AutocompleteLocation>

            <!-- !Order Number-->
            <v-text-field
                v-if="codeSelected === 'orderNumber'"
                v-model="menuObject['orderNumber']"
                :label="getFilterChoiceNameByCode('orderNumber')"
                clearable
                hide-details
                dense
                outlined
            >
                <template v-slot:append>
                    <v-tooltip bottom content-class="tooltipContent">
                        <template v-slot:activator="{ on }">
                            <v-icon v-on="on" color="primary">
                                info
                            </v-icon>
                        </template>
                        <span>{{ $t("general.info_order_empty") }}</span>
                    </v-tooltip>
                </template>
            </v-text-field>

            <!-- !Trade -->
            <v-text-field
                v-if="codeSelected === 'trade'"
                v-model="menuObject['trade_Icontains']"
                :label="getFilterChoiceNameByCode('trade')"
                clearable
                hide-details
                dense
                outlined
            ></v-text-field>

            <!-- Last Location-->
            <v-text-field
                v-if="codeSelected === 'contactPerson'"
                v-model="menuObject['tripFreightSet_Contact_Icontains']"
                :label="getFilterChoiceNameByCode('contactPerson')"
                clearable
                hide-details
                dense
                outlined
            ></v-text-field>

            <!-- ! POL Search -->
            <PortFilter
                v-if="codeSelected === 'pol'"
                v-model="menuObject['pol']"
                :label="getFilterChoiceNameByCode('pol')"
                :refreshTable="refreshTablePorts"
                :outlined="true"
            >
            </PortFilter>

            <!-- ! Transsshipment Search -->
            <PortFilter
                v-if="codeSelected === 'transshipment'"
                v-model="menuObject['transshipment']"
                :label="getFilterChoiceNameByCode('transshipment')"
                :refreshTable="refreshTablePorts"
                :outlined="true"
            >
            </PortFilter>

            <!-- ! pod search  -->
            <PortFilter
                v-if="codeSelected === 'pod'"
                v-model="menuObject['pod']"
                :label="getFilterChoiceNameByCode('pod')"
                :refreshTable="refreshTablePorts"
                :outlined="true"
            >
            </PortFilter>

            <!-- !Transport Mode -->
            <v-autocomplete
                v-if="codeSelected === 'transportMode'"
                v-model="menuObject['transportMode_In']"
                :items="transportModeChoices"
                :label="getFilterChoiceNameByCode('transportMode')"
                clearable
                hide-details
                item-text="text"
                item-value="value"
                dense
                color="primary"
                outlined
            ></v-autocomplete>

            <!-- !Relative Planned Departure Date -->
            <v-select
                v-if="codeSelected === 'relativePlannedDepartureDateRange'"
                v-model="relativePlannedDepartureDateRange"
                :items="relativePlannedDepartureDateRangeChoices"
                :label="getFilterChoiceNameByCode('relativePlannedDepartureDateRange')"
                clearable
                hide-details
                dense
                outlined
                color="primary"
            ></v-select>

            <!-- !Consignor -->
            <PartnerTripsConsignor
                v-if="codeSelected === 'consignor'"
                ref="consignor"
                :refreshTable="refreshTableConsignor"
                v-model="menuObject['consignor']"
                :label="getFilterChoiceNameByCode('consignor')"
                :name.sync="consignorName"
                :outlined="true"
            ></PartnerTripsConsignor>

            <!-- !Consignee -->
            <PartnerTripsConsignee
                v-if="codeSelected === 'consignee'"
                ref="consignee"
                :refreshTable="refreshTableConsignee"
                v-model="menuObject['consignee']"
                :label="getFilterChoiceNameByCode('consignee')"
                :name.sync="consigneeName"
                :outlined="true"
            ></PartnerTripsConsignee>

            <!-- !Forwarder -->
            <PartnerTripsForwarder
                v-if="codeSelected === 'forwarder'"
                ref="forwarder"
                :refreshTable="refreshTableForwarder"
                v-model="menuObject['forwarder']"
                :label="getFilterChoiceNameByCode('forwarder')"
                :name.sync="forwarderName"
                :outlined="true"
            ></PartnerTripsForwarder>

            <!-- !Carrier -->
            <PartnerTripsCarrier
                v-if="codeSelected === 'carrier'"
                ref="carrier"
                :refreshTable="refreshTableCarrier"
                v-model="menuObject['tenantCarrier']"
                :label="getFilterChoiceNameByCode('carrier')"
                :name.sync="carrierName"
                :outlined="true"
            ></PartnerTripsCarrier>
            <!-- !pol vessel -->
            <VesselFilter
                v-if="codeSelected === 'polvessel'"
                v-model="menuObject['polvessel']"
                :refreshTable="refreshTableVessel"
                :label="getFilterChoiceNameByCode('polvessel')"
                :name.sync="polvesselName"
                :outlined="true"
            >
            </VesselFilter>
            <!-- !t/s vessel -->
            <VesselFilter
                v-if="codeSelected === 'tsvessel'"
                v-model="menuObject['tsvessel']"
                :refreshTable="refreshTableVessel"
                :label="getFilterChoiceNameByCode('tsvessel')"
                :name.sync="tsvesselName"
                :outlined="true"
            >
            </VesselFilter>
            <!-- Transport Mode -->

            <!-- Alert Rule Name -->
            <v-autocomplete
                v-if="codeSelected === 'tenantAlertRuleId'"
                v-model="menuObject['tenantAlertRuleId']"
                :items="alertRules ? alertRules.edges : []"
                :label="getFilterChoiceNameByCode('tenantAlertRuleId')"
                item-text="node.name"
                item-value="node.id"
                hide-details
                hide-selected
                dense
                clearable
                outlined
                :disabled="alertRulesLoading"
                multiple
            >
                <template v-slot:selection="{ attrs, item }">
                    <v-chip v-bind="attrs" close small @click:close="remove('tenantAlertRuleId', item)">
                        {{ item.node.name }}
                    </v-chip>
                </template>
            </v-autocomplete>

            <!-- !Alert Code -->
            <v-autocomplete
                v-if="codeSelected === 'alertCode'"
                v-model="menuObject['alertCode']"
                :items="alertsType"
                :label="getFilterChoiceNameByCode('alertCode')"
                item-text="name"
                item-value="value"
                hide-details
                hide-selected
                dense
                clearable
                outlined
                multiple
            >
                <template v-slot:selection="{ attrs, item }">
                    <v-chip v-bind="attrs" close small @click:close="remove('alertCode', item)">
                        {{ item.name }}
                    </v-chip>
                </template>
            </v-autocomplete>
            <!-- Alert tag -->
            <v-autocomplete
                v-if="codeSelected === 'alertTag'"
                v-model="menuObject['alertTag']"
                :items="alertTags"
                :label="getFilterChoiceNameByCode('alertTag')"
                item-text="text"
                item-value="value"
                hide-details
                dense
                clearable
                outlined
            ></v-autocomplete>

            <!-- !Tenant -->
            <v-text-field
                v-if="codeSelected === 'sharedBy' && isSharedTrip"
                v-model="menuObject['tenant']"
                :label="getFilterChoiceNameByCode('sharedBy')"
                clearable
                hide-details
                dense
                outlined
            ></v-text-field>

            <!--!Trip Goods -->
            <v-text-field
                v-if="codeSelected === 'goods'"
                v-model="menuObject['goods']"
                :label="getFilterChoiceNameByCode('goods')"
                clearable
                hide-details
                dense
                outlined
            ></v-text-field>

            <!-- !booking -->
            <v-text-field
                v-if="codeSelected === 'booking'"
                v-model="menuObject['tripSeaTransportSetBookingConfirmationNumberIexact']"
                :label="getFilterChoiceNameByCode('booking')"
                clearable
                hide-details
                dense
                outlined
            ></v-text-field>

            <!-- !trip status -->
            <v-select
                v-if="codeSelected === 'status'"
                v-model="menuObject['status_In']"
                :items="statusChoices"
                :label="getFilterChoiceNameByCode('tripStatus')"
                clearable
                hide-details
                dense
                outlined
                color="primary"
            ></v-select>

            <v-menu
                ref="plannedDepartureDateMenu"
                v-if="codeSelected === 'plannedDepartureDate'"
                v-model="plannedDepartureDateMenu"
                :close-on-content-click="false"
                :return-value.sync="savedPlannedDepartureDateRange"
                transition="scale-transition"
                offset-y
                max-width="auto"
                min-width="auto"
                :disabled="!!relativePlannedDepartureDateRange"
            >
                <template
                    v-slot:activator="{
                        on,
                        attrs
                    }"
                >
                    <v-text-field
                        :value="plannedDepartureDateRangeText"
                        :label="getFilterChoiceNameByCode('plannedDepartureDate')"
                        readonly
                        clearable
                        hide-details
                        dense
                        @click:clear="onClearPlannedDepartureDates()"
                        v-bind="attrs"
                        v-on="on"
                        :disabled="!!relativePlannedDepartureDateRange"
                        outlined
                    ></v-text-field>
                </template>
                <v-date-picker
                    v-model="plannedDepartureDateRange"
                    :first-day-of-week="1"
                    range
                    no-title
                    scrollable
                    color="primary"
                >
                    <v-spacer></v-spacer>
                    <v-btn text rounded color="primary" @click="cancelPlannedDepartureDatePicker">
                        {{ $t("general.cancel") }}
                    </v-btn>
                    <v-btn
                        outlined
                        rounded
                        color="primary"
                        :disabled="plannedDepartureDateRange.length < 1"
                        @click="savePlannedDepartureDates"
                    >
                        OK
                    </v-btn>
                </v-date-picker>
            </v-menu>

            <v-menu
                v-if="codeSelected === 'plannedArrivalDate'"
                ref="plannedArrivalDateMenu"
                v-model="plannedArrivalDateMenu"
                :close-on-content-click="false"
                :return-value.sync="savedPlannedArrivalDateRange"
                transition="scale-transition"
                offset-y
                max-width="auto"
                min-width="auto"
                :disabled="!!relativePlannedArrivalDateRange"
            >
                <template
                    v-slot:activator="{
                        on,
                        attrs
                    }"
                >
                    <v-text-field
                        :value="plannedArrivalDateRangeText"
                        :label="getFilterChoiceNameByCode('plannedArrivalDate')"
                        readonly
                        clearable
                        hide-details
                        dense
                        @click:clear="onClearPlannedArrivalDates()"
                        v-bind="attrs"
                        v-on="on"
                        :disabled="!!relativePlannedArrivalDateRange"
                        outlined
                    ></v-text-field>
                </template>
                <v-date-picker
                    v-model="plannedArrivalDateRange"
                    :first-day-of-week="1"
                    range
                    no-title
                    scrollable
                    color="primary"
                >
                    <v-spacer></v-spacer>
                    <v-btn text rounded color="primary" @click="cancelPlannedArrivalDatePicker">
                        {{ $t("general.cancel") }}
                    </v-btn>
                    <v-btn
                        outlined
                        rounded
                        color="primary"
                        :disabled="plannedArrivalDateRange.length < 1"
                        @click="savePlannedArrivalDates"
                    >
                        OK
                    </v-btn>
                </v-date-picker>
            </v-menu>

            <v-menu
                v-if="codeSelected === 'vgm'"
                ref="vgmMenu"
                v-model="vgmMenu"
                :close-on-content-click="false"
                :return-value.sync="savedVgmCutOffDateRange"
                transition="scale-transition"
                offset-y
                max-width="auto"
                min-width="auto"
                :disabled="!!relativeVgmCutOffDateRange"
            >
                <template
                    v-slot:activator="{
                        on,
                        attrs
                    }"
                >
                    <v-text-field
                        :value="vgmCutOffDateRangeText"
                        :label="getFilterChoiceNameByCode('vgm')"
                        readonly
                        clearable
                        hide-details
                        dense
                        @click:clear="onClearVgmCutOffDate()"
                        v-bind="attrs"
                        v-on="on"
                        :disabled="!!relativeVgmCutOffDateRange"
                        outlined
                    ></v-text-field>
                </template>
                <v-date-picker
                    v-model="vgmCutOffDateRange"
                    :first-day-of-week="1"
                    range
                    no-title
                    scrollable
                    color="primary"
                >
                    <v-spacer></v-spacer>
                    <v-btn text rounded color="primary" @click="cancelVgmCutOffDatePicker">
                        {{ $t("general.cancel") }}
                    </v-btn>
                    <v-btn
                        outlined
                        rounded
                        color="primary"
                        :disabled="vgmCutOffDateRange.length < 1"
                        @click="saveVgmCutOffDates()"
                    >
                        OK
                    </v-btn>
                </v-date-picker>
            </v-menu>

            <v-menu
                v-if="codeSelected === 'cutOff'"
                ref="cutOffMenu"
                v-model="cutOffMenu"
                :close-on-content-click="false"
                :return-value.sync="savedCutOffDateRange"
                transition="scale-transition"
                offset-y
                max-width="auto"
                min-width="auto"
                :disabled="!!relativeCutOffDateTimeRange"
            >
                <template
                    v-slot:activator="{
                        on,
                        attrs
                    }"
                >
                    <v-text-field
                        :value="cutOffDateRangeText"
                        :label="getFilterChoiceNameByCode('cutOff')"
                        readonly
                        clearable
                        hide-details
                        dense
                        @click:clear="onClearCutOffDate()"
                        v-bind="attrs"
                        v-on="on"
                        :disabled="!!relativeCutOffDateTimeRange"
                        outlined
                    ></v-text-field>
                </template>
                <v-date-picker
                    v-model="cutOffDateRange"
                    :first-day-of-week="1"
                    range
                    no-title
                    scrollable
                    color="primary"
                >
                    <v-spacer></v-spacer>
                    <v-btn text rounded color="primary" @click="cancelCutOffDatePicker">
                        {{ $t("general.cancel") }}
                    </v-btn>
                    <v-btn
                        outlined
                        rounded
                        color="primary"
                        :disabled="cutOffDateRange.length < 1"
                        @click="saveCutOffDates()"
                    >
                        OK
                    </v-btn>
                </v-date-picker>
            </v-menu>
        </v-col>
    </v-row>
</template>

<script>
import helper from "@/utils/helper.js";
import moment from "moment";
import PartnerTripsConsignor from "@/components/partners/PartnerTripsConsignor.vue";
import PartnerTripsConsignee from "@/components/partners/PartnerTripsConsignee.vue";
import PartnerTripsForwarder from "@/components/partners/PartnerTripsForwarder.vue";
import PartnerTripsCarrier from "@/components/partners/PartnerTripsCarrier.vue";
import VesselFilter from "@/components/trips/other/VesselFilter.vue";
import AutocompleteLocation from "@/components/locations/AutocompleteLocation.vue";
import PortFilter from "@/components/trips/other/PortFilter.vue";
import gql from "graphql-tag";

export default {
    name: "FilterDialogTemplate",
    components: {
        PartnerTripsConsignor,
        PartnerTripsConsignee,
        PartnerTripsForwarder,
        PartnerTripsCarrier,
        VesselFilter,
        PortFilter,
        AutocompleteLocation
    },
    props: {
        value: {
            type: Object,
            required: true,
            default: () => ({})
        },
        isReadOnly: {
            type: Boolean,
            required: false,
            default: false
        },
        currentCode: {
            type: String,
            required: false,
            default: null
        },
        isSharedTrip: {
            type: Boolean,
            required: false,
            default: false
        }
    },
    data() {
        return {
            fab: false,
            isLoading: false,
            plannedDepartureDateMenu: false,
            plannedArrivalDateMenu: false,
            plannedDepartureDateRange: [],
            plannedArrivalDateRange: [],
            vgmCutOffDateRange: [],
            cutOffDateRange: [],
            savedPlannedDepartureDateRange: [],
            savedPlannedArrivalDateRange: [],
            savedVgmCutOffDateRange: [],
            savedCutOffDateRange: [],
            userFavoriteTrip: null,
            sortAsc: true,
            shouldStickyFilter: false,
            vgmMenu: false,
            cutOffMenu: false,
            refreshTableCarrier: false,
            refreshTableConsignor: false,
            refreshTableConsignee: false,
            refreshTableForwarder: false,
            refreshTableVessel: false,
            refreshTablePorts: false,
            filterChoices: null,
            widthMenu: 0,
            defaultFilterChoices: [
                {
                    // for filter menu
                    code: "referenceNumber",
                    name: "Reference Number",
                    tag: "headers.reference_number",
                    enabled: true
                    // for filter layout
                    // class: "col-auto col-md-3 col-lg-2"
                },
                {
                    // for filter menu
                    code: "freights",
                    name: "Freight Number",
                    tag: "headers.freight_number",
                    enabled: true
                    // for filter layout
                    // class: "col-auto col-md-3 col-lg-2"
                },
                {
                    // for filter menu
                    code: "tracker",
                    name: "Tracker Number",
                    tag: "headers.tracker_number",
                    enabled: true
                    // for filter layout
                    // class: "col-auto col-md-3 col-lg-2"
                },
                {
                    // for filter menu
                    code: "containerNumber",
                    name: "Container Number",
                    tag: "headers.container_number",
                    enabled: true
                    // for filter layout
                    // class: "col-auto col-md-3 col-lg-2"
                },
                {
                    // for filter menu
                    code: "status",
                    name: "Status",
                    tag: "headers.status",
                    enabled: true,
                    // for filter layout
                    class: "col-auto col-md-auto"
                },
                {
                    // for filter menu
                    code: "airWaybillNumber",
                    name: "AWB",
                    tag: "headers.awb_number",
                    enabled: true
                    // for filter layout
                    // class: "col-auto col-md-3 col-lg-2"
                },
                {
                    // for filter menu
                    code: "orderNumber",
                    name: "Order Number",
                    tag: "headers.order_number",
                    enabled: true
                    // for filter layout
                    // class: "col-auto col-md-3 col-lg-2"
                },
                {
                    // for filter menu
                    code: "blNumber",
                    name: "BL Number",
                    tag: "headers.bill_of_lading",
                    enabled: true
                    // for filter layout
                    // class: "col-auto col-md-3 col-lg-2"
                },
                {
                    // for filter menu
                    code: "plannedDepartureDate",
                    name: "Planned Departure Date",
                    tag: "headers.planned_departure",
                    enabled: true
                    // for filter layout
                    // class: "col-auto col-md-3 col-lg-2"
                },
                {
                    // for filter menu
                    code: "plannedArrivalDate",
                    name: "Planned Arrival Date",
                    tag: "headers.planned_arrival",
                    enabled: true
                    // for filter layout
                    // class: "col-auto col-md-3 col-lg-2"
                },
                {
                    code: "transportMode",
                    name: "Transport Mode",
                    tag: "headers.transport_mode",
                    // class: "col-auto col-md-3 col-lg-2",
                    enabled: true
                },
                {
                    code: "trackerLastLocation",
                    name: "Last Location",
                    tag: "headers.last_location",
                    // class: "col-auto col-md-3 col-lg-2",
                    enabled: true
                },
                {
                    code: "pol",
                    name: "POL Code",
                    tag: "headers.pol_code",
                    // class: "col-auto col-md-3 col-lg-2",
                    enabled: true
                },
                {
                    code: "transshipment",
                    name: "T/S Code",
                    tag: "headers.ts_code",
                    // class: "col-auto col-md-3 col-lg-2",
                    enabled: true
                },
                {
                    code: "pod",
                    name: "POD Code",
                    tag: "headers.pod_code",
                    // class: "col-auto col-md-3 col-lg-2",
                    enabled: true
                },
                {
                    code: "tenantAlertRuleId",
                    name: "Alert Name",
                    tag: "headers.alert_name",
                    enabled: true
                },
                {
                    code: "alertCode",
                    name: "Alert Type",
                    tag: "headers.alert_type",
                    // class: "col-auto col-md-3 col-lg-2",
                    enabled: true
                },

                {
                    code: "origin",
                    name: "Origin",
                    tag: "headers.origin",
                    enabled: true
                },

                {
                    code: "destination",
                    name: "Destination",
                    tag: "headers.destination",
                    enabled: true
                },

                {
                    code: "alertTag",
                    name: "Alert Tag",
                    tag: "headers.alert_tag",
                    //class: "col-auto col-md-3 col-lg-2",
                    enabled: true
                },
                {
                    // for filter menu
                    code: "sharedBy",
                    name: "Shared By",
                    tag: "headers.shared_by",
                    enabled: true
                    // for filter layout
                    // class: "col-auto col-md-3 col-lg-2"
                },
                {
                    // for filter menu
                    code: "consignor",
                    name: "Consignor",
                    tag: "headers.consignor",
                    enabled: true
                    // for filter layout
                    // class: "col-auto col-md-3 col-lg-2"
                },
                {
                    // for filter menu
                    code: "consignee",
                    name: "Consignee",
                    tag: "headers.consignee",
                    enabled: true
                    // for filter layout
                    // class: "col-auto col-md-3 col-lg-2"
                },
                {
                    // for filter menu
                    code: "forwarder",
                    name: "Forwarder",
                    tag: "headers.forwarder",
                    enabled: true
                    // for filter layout
                    // class: "col-auto col-md-3 col-lg-2"
                },
                {
                    // for filter menu
                    code: "carrier",
                    name: "Carrier",
                    tag: "headers.carrier",
                    enabled: true
                    // for filter layout
                    // class: "col-auto col-md-3 col-lg-2"
                },
                {
                    // for filter menu
                    code: "polvessel",
                    name: "POL Vessel",
                    tag: "headers.pol_vessel",
                    enabled: true
                    // for filter layout
                    // class: "col-auto col-md-3 col-lg-2"
                },
                {
                    // for filter menu
                    code: "tsvessel",
                    name: "T/S Vessel",
                    tag: "headers.ts_vessel",
                    enabled: true
                    // for filter layout
                    // class: "col-auto col-md-3 col-lg-2"
                },
                {
                    // for filter menu
                    code: "goods",
                    name: "Goods",
                    tag: "headers.goods",
                    enabled: true
                    // for filter layout
                    // class: "col-auto col-md-3 col-lg-2"
                },

                {
                    // for filter menu
                    code: "contactPerson",
                    name: "Contact Person",
                    tag: "forms.contact_person",
                    enabled: true
                    // for filter layout
                    // class: "col-auto col-md-3 col-lg-2"
                },
                {
                    // for filter menu
                    code: "trade",
                    name: "Trade",
                    tag: "headers.trade",
                    enabled: true
                    // for filter layout
                    // class: "col-auto col-md-3 col-lg-2"
                },
                {
                    // for filter menu
                    code: "booking",
                    name: "Booking No.",
                    tag: "headers.booking_confirmation_number",
                    enabled: true
                    // for filter layout
                    // class: "col-auto col-md-3 col-lg-2"
                },
                {
                    // for filter menu
                    code: "vgm",
                    name: "VMG Cut Off",
                    tag: "headers.vgm_cut_off_date",
                    enabled: true
                    // for filter layout
                    // class: "col-auto col-md-3 col-lg-2"
                },
                {
                    // for filter menu
                    code: "cutOff",
                    name: "Cut Off",
                    tag: "headers.cut_off_date_time",
                    enabled: true
                    // for filter layout
                    // class: "col-auto col-md-3 col-lg-2"
                }
            ],
            alertTags: [
                { value: "temperature", text: this.$t("general.temperature") },
                { value: "humidity", text: this.$t("general.humidity") },
                { value: "light", text: this.$t("general.light") },
                { value: "temperature_humidity", text: this.$t("general.temperature_humidity") },
                { value: "temperature_humidity_light", text: this.$t("general.temperature_humidity_light") },
                { value: "pressure", text: this.$t("general.pressure") }
            ],
            codeSelected: "",
            carrierName: null,
            consignorName: null,
            consigneeName: null,
            forwarderName: null,
            tsvesselName: null,
            polvesselName: null,
            relativePlannedDepartureDateRange: null,
            relativePlannedDepartureDateRangeChoices: [
                this.$t("date.last30"),
                this.$t("date.last90"),
                this.$t("date.last180"),
                this.$t("date.last360")
            ],
            relativePlannedArrivalDateRange: null,
            relativePlannedArrivalDateRangeChoices: [
                this.$t("date.last30"),
                this.$t("date.last90"),
                this.$t("date.last180"),
                this.$t("date.last360")
            ],
            transportModeChoices: [
                { text: this.$t("transport_mode_choice.air"), value: "air" },
                { text: this.$t("transport_mode_choice.road"), value: "road" },
                { text: this.$t("transport_mode_choice.rail"), value: "rail" },
                { text: this.$t("transport_mode_choice.sea"), value: "sea" }
            ],
            relativeCutOffDateTimeRange: null,
            relativeCutOffDateTimeRangeChoices: [
                this.$t("date.last30"),
                this.$t("date.last90"),
                this.$t("date.last180"),
                this.$t("date.last360")
            ],
            relativeVgmCutOffDateRange: null,
            relativeVgmCutOffDateRangeChoices: [
                this.$t("date.last30"),
                this.$t("date.last90"),
                this.$t("date.last180"),
                this.$t("date.last360")
            ],
            filterValuesObj: [],
            // Initialize menuWidth with the same width as the text field
            menuWidth: null,
            alertRulesLoading: false
        };
    },
    apollo: {
        alertRules: {
            query: gql`
                query tenantAlertRules {
                    tenantAlertRules {
                        edges {
                            node {
                                id
                                name
                            }
                        }
                    }
                }
            `,
            variables() {
                return {};
            },
            fetchPolicy: "cache-and-network",
            nextFetchPolicy: "cache-first",
            update: data => data.tenantAlertRules,
            watchLoading(isLoading) {
                this.alertRulesLoading = isLoading;
            },
            executeOnce: true
        }
    },
    watch: {
        relativePlannedDepartureDateRange(val) {
            const _index = this.relativePlannedDepartureDateRangeChoices.indexOf(val);
            let _startDate = null;
            let _endDate = null;
            switch (_index) {
                case 0:
                    // last month
                    _endDate = moment().format("YYYY-MM-DD");
                    _startDate = moment()
                        .date(0)
                        .startOf("month")
                        .format("YYYY-MM-DD");
                    this.plannedDepartureDateRange = [_startDate, _endDate];
                    this.savedPlannedDepartureDateRange = [_startDate, _endDate];
                    break;
                case 1:
                    // last quarter
                    _endDate = moment().format("YYYY-MM-DD");
                    _startDate = moment()
                        .subtract(3, "months")
                        .startOf("month")
                        .format("YYYY-MM-DD");
                    this.plannedDepartureDateRange = [_startDate, _endDate];
                    this.savedPlannedDepartureDateRange = [_startDate, _endDate];
                    break;
                case 2:
                    // last half year
                    _endDate = moment().format("YYYY-MM-DD");
                    _startDate = moment()
                        .subtract(6, "months")
                        .startOf("month")
                        .format("YYYY-MM-DD");
                    this.plannedDepartureDateRange = [_startDate, _endDate];
                    this.savedPlannedDepartureDateRange = [_startDate, _endDate];
                    break;
                case 3:
                    // last 1 year
                    _endDate = moment().format("YYYY-MM-DD");
                    _startDate = moment()
                        .subtract(12, "months")
                        .startOf("month")
                        .format("YYYY-MM-DD");
                    this.plannedDepartureDateRange = [_startDate, _endDate];
                    this.savedPlannedDepartureDateRange = [_startDate, _endDate];
                    break;
                default:
                    this.plannedDepartureDateRange = [];
                    this.savedPlannedDepartureDateRange = [];
            }
        },
        relativePlannedArrivalDateRange(val) {
            const _index = this.relativePlannedArrivalDateRangeChoices.indexOf(val);
            let _startDate = null;
            let _endDate = null;
            switch (_index) {
                case 0:
                    // last month
                    _endDate = moment().format("YYYY-MM-DD");
                    _startDate = moment()
                        .date(0)
                        .startOf("month")
                        .format("YYYY-MM-DD");
                    this.plannedArrivalDateRange = [_startDate, _endDate];
                    this.savedPlannedArrivalDateRange = [_startDate, _endDate];
                    break;
                case 1:
                    // last quarter
                    _endDate = moment().format("YYYY-MM-DD");
                    _startDate = moment()
                        .subtract(3, "months")
                        .startOf("month")
                        .format("YYYY-MM-DD");
                    this.plannedArrivalDateRange = [_startDate, _endDate];
                    this.savedPlannedArrivalDateRange = [_startDate, _endDate];
                    break;
                case 2:
                    // last half year
                    _endDate = moment().format("YYYY-MM-DD");
                    _startDate = moment()
                        .subtract(6, "months")
                        .startOf("month")
                        .format("YYYY-MM-DD");
                    this.plannedArrivalDateRange = [_startDate, _endDate];
                    this.savedPlannedArrivalDateRange = [_startDate, _endDate];
                    break;
                case 3:
                    // last 1 year
                    _endDate = moment().format("YYYY-MM-DD");
                    _startDate = moment()
                        .subtract(12, "months")
                        .startOf("month")
                        .format("YYYY-MM-DD");
                    this.plannedArrivalDateRange = [_startDate, _endDate];
                    this.savedPlannedArrivalDateRange = [_startDate, _endDate];
                    break;
                default:
                    this.plannedArrivalDateRange = [];
                    this.savedPlannedArrivalDateRange = [];
            }
        },
        savedPlannedDepartureDateRange(val) {
            if (val.length == 1) {
                this.menuObject["plannedDepartureDate_Gte"] = val[0] + "T00:00:00";
                this.menuObject["plannedDepartureDate_Lte"] = val[0] + "T23:59:59";
            } else if (val.length == 2) {
                this.menuObject["plannedDepartureDate_Gte"] = val[0] + "T00:00:00";
                this.menuObject["plannedDepartureDate_Lte"] = val[1] + "T23:59:59";
            } else {
                this.menuObject["plannedDepartureDate_Gte"] = null;
                this.menuObject["plannedDepartureDate_Lte"] = null;
            }
        },
        savedPlannedArrivalDateRange(val) {
            if (val.length == 1) {
                this.menuObject["plannedArrivalDate_Gte"] = val[0] + "T00:00:00";
                this.menuObject["plannedArrivalDate_Lte"] = val[0] + "T23:59:59";
            } else if (val.length == 2) {
                this.menuObject["plannedArrivalDate_Gte"] = val[0] + "T00:00:00";
                this.menuObject["plannedArrivalDate_Lte"] = val[1] + "T23:59:59";
            } else {
                this.menuObject["plannedArrivalDate_Gte"] = null;
                this.menuObject["plannedArrivalDate_Lte"] = null;
            }
        },
        relativeCutOffDateTimeRange(val) {
            const _index = this.relativeCutOffDateTimeRangeChoices.indexOf(val);
            let _startDate = null;
            let _endDate = null;
            switch (_index) {
                case 0:
                    // last month
                    _endDate = moment().format("YYYY-MM-DD");
                    _startDate = moment()
                        .date(0)
                        .startOf("month")
                        .format("YYYY-MM-DD");
                    this.cutOffDateRange = [_startDate, _endDate];
                    this.savedCutOffDateRange = [_startDate, _endDate];
                    break;
                case 1:
                    // last quarter
                    _endDate = moment().format("YYYY-MM-DD");
                    _startDate = moment()
                        .subtract(3, "months")
                        .startOf("month")
                        .format("YYYY-MM-DD");
                    this.cutOffDateRange = [_startDate, _endDate];
                    this.savedCutOffDateRange = [_startDate, _endDate];
                    break;
                case 2:
                    // last half year
                    _endDate = moment().format("YYYY-MM-DD");
                    _startDate = moment()
                        .subtract(6, "months")
                        .startOf("month")
                        .format("YYYY-MM-DD");
                    this.cutOffDateRange = [_startDate, _endDate];
                    this.savedCutOffDateRange = [_startDate, _endDate];
                    break;
                case 3:
                    // last 1 year
                    _endDate = moment().format("YYYY-MM-DD");
                    _startDate = moment()
                        .subtract(12, "months")
                        .startOf("month")
                        .format("YYYY-MM-DD");
                    this.cutOffDateRange = [_startDate, _endDate];
                    this.savedCutOffDateRange = [_startDate, _endDate];
                    break;
                default:
                    this.cutOffDateRange = [];
                    this.savedCutOffDateRange = [];
            }
        },
        relativeVgmCutOffDateRange(val) {
            const _index = this.relativeVgmCutOffDateRangeChoices.indexOf(val);
            let _startDate = null;
            let _endDate = null;
            switch (_index) {
                case 0:
                    // last month
                    _endDate = moment().format("YYYY-MM-DD");
                    _startDate = moment()
                        .date(0)
                        .startOf("month")
                        .format("YYYY-MM-DD");
                    this.vgmCutOffDateRange = [_startDate, _endDate];
                    this.savedVgmCutOffDateRange = [_startDate, _endDate];
                    break;
                case 1:
                    // last quarter
                    _endDate = moment().format("YYYY-MM-DD");
                    _startDate = moment()
                        .subtract(3, "months")
                        .startOf("month")
                        .format("YYYY-MM-DD");
                    this.vgmCutOffDateRange = [_startDate, _endDate];
                    this.savedVgmCutOffDateRange = [_startDate, _endDate];
                    break;
                case 2:
                    // last half year
                    _endDate = moment().format("YYYY-MM-DD");
                    _startDate = moment()
                        .subtract(6, "months")
                        .startOf("month")
                        .format("YYYY-MM-DD");
                    this.vgmCutOffDateRange = [_startDate, _endDate];
                    this.savedVgmCutOffDateRange = [_startDate, _endDate];
                    break;
                case 3:
                    // last 1 year
                    _endDate = moment().format("YYYY-MM-DD");
                    _startDate = moment()
                        .subtract(12, "months")
                        .startOf("month")
                        .format("YYYY-MM-DD");
                    this.vgmCutOffDateRange = [_startDate, _endDate];
                    this.savedVgmCutOffDateRange = [_startDate, _endDate];
                    break;
                default:
                    this.vgmCutOffDateRange = [];
                    this.savedVgmCutOffDateRange = [];
            }
        },
        savedVgmCutOffDateRange(val) {
            if (val.length == 1) {
                this.menuObject["tripSeaTransportSetVgmCutOffDateGte"] = val[0] + "T00:00:00";
                this.menuObject["tripSeaTransportSetVgmCutOffDateLte"] = val[0] + "T23:59:59";
            } else if (val.length == 2) {
                this.menuObject["tripSeaTransportSetVgmCutOffDateGte"] = val[0] + "T00:00:00";
                this.menuObject["tripSeaTransportSetVgmCutOffDateLte"] = val[1] + "T23:59:59";
            } else {
                this.menuObject["tripSeaTransportSetVgmCutOffDateGte"] = null;
                this.menuObject["tripSeaTransportSetVgmCutOffDateLte"] = null;
            }
        },
        savedCutOffDateRange(val) {
            if (val.length == 1) {
                this.menuObject["tripSeaTransportSetCutOffDateTimeGte"] = val[0] + "T00:00:00";
                this.menuObject["tripSeaTransportSetCutOffDateTimeLte"] = val[0] + "T23:59:59";
            } else if (val.length == 2) {
                this.menuObject["tripSeaTransportSetCutOffDateTimeGte"] = val[0] + "T00:00:00";
                this.menuObject["tripSeaTransportSetCutOffDateTimeLte"] = val[1] + "T23:59:59";
            } else {
                this.menuObject["tripSeaTransportSetCutOffDateTimeGte"] = null;
                this.menuObject["tripSeaTransportSetCutOffDateTimeLte"] = null;
            }
        },
        isSharedTrip: {
            handler(value) {
                if (value) {
                    this.defaultFilterChoices.push({
                        // for filter menu
                        code: "sharedBy",
                        name: "Shared By",
                        tag: "headers.shared_by",
                        enabled: false,
                        // for filter layout
                        class: "col-auto col-md-3 col-lg-2"
                    });
                } else {
                    this.defaultFilterChoices = this.defaultFilterChoices.filter(e => e.code !== "sharedBy");
                }
            },
            deep: true,
            immediate: true
        },
        value: {
            handler(val) {
                //update the dateranges
                if (val.plannedDepartureDate_Gte && val.plannedDepartureDate_Lte) {
                    this.plannedDepartureDateRange.push(val.plannedDepartureDate_Gte.split("T")[0]);
                    this.plannedDepartureDateRange.push(val.plannedDepartureDate_Lte.split("T")[0]);
                } else {
                    this.plannedDepartureDateRange = [];
                }

                if (val.plannedArrivalDate_Gte && val.plannedArrivalDate_Lte) {
                    this.plannedArrivalDateRange.push(val.plannedArrivalDate_Gte.split("T")[0]);
                    this.plannedArrivalDateRange.push(val.plannedArrivalDate_Lte.split("T")[0]);
                } else {
                    this.plannedArrivalDateRange = [];
                }

                if (val.tripSeaTransportSetVgmCutOffDateGte && val.tripSeaTransportSetVgmCutOffDateLte) {
                    this.vgmCutOffDateRange.push(val.tripSeaTransportSetVgmCutOffDateGte.split("T")[0]);
                    this.vgmCutOffDateRange.push(val.tripSeaTransportSetVgmCutOffDateLte.split("T")[0]);
                } else {
                    this.vgmCutOffDateRange = [];
                }

                if (val.tripSeaTransportSetCutOffDateTimeGte && val.tripSeaTransportSetCutOffDateTimeLte) {
                    this.cutOffDateRange.push(val.tripSeaTransportSetCutOffDateTimeGte.split("T")[0]);
                    this.cutOffDateRange.push(val.tripSeaTransportSetCutOffDateTimeLte.split("T")[0]);
                } else {
                    this.cutOffDateRange = [];
                }
            },
            deep: true,
            immediate: true
        },
        currentCode: {
            handler(val) {
                this.codeSelected = val;
            },
            immediate: true
        }
    },
    computed: {
        menuObject: {
            get() {
                return this.value || {};
            },
            set(val) {
                this.$emit("input", val);
            }
        },
        dynamicFilters() {
            return this.defaultFilterChoices
                .filter(filter => filter.enabled)
                .sort((a, b) => a.name.localeCompare(b.name));
        },
        statusChoices() {
            let _statusChoices = [
                { text: this.$t("general.all_trips"), value: null },
                { text: this.$t("general.active"), value: "active" },
                { text: this.$t("general.completed"), value: "completed" }
            ];
            return _statusChoices;
        },
        alertsType: function() {
            let _alertsType = [
                {
                    name: this.$t("general.all"),
                    value: "All"
                },
                {
                    name: this.$t("dashboard.etd_delay"),
                    value: "SEA_ETD_DELAY"
                },
                {
                    name: this.$t("dashboard.eta_delay"),
                    value: "SEA_ETA_DELAY"
                },
                {
                    name: this.$t("dashboard.ts_waiting"),
                    value: "SEA_TRANS_WAIT"
                },
                {
                    name: this.$t("dashboard.pod_waiting"),
                    value: "SEA_DEST_WAIT"
                },
                {
                    name: this.$t("dashboard.custom_alert"),
                    value: "CUSTOM_ALERT"
                }
            ];
            return _alertsType;
        },
        plannedDepartureDateRangeText() {
            if (this.plannedDepartureDateRange.length > 1) {
                let date1 = moment(this.plannedDepartureDateRange[0], "YYYY-MM-DD").format(helper.getDateFormat());
                let date2 = moment(this.plannedDepartureDateRange[1], "YYYY-MM-DD").format(helper.getDateFormat());

                return date1 + " ~ " + date2;
            } else if (this.plannedDepartureDateRange.length === 1) {
                return moment(this.plannedDepartureDateRange[0], "YYYY-MM-DD").format(helper.getDateFormat());
            } else {
                return this.plannedDepartureDateRange;
            }
        },
        plannedArrivalDateRangeText() {
            if (this.plannedArrivalDateRange.length > 1) {
                let date1 = moment(this.plannedArrivalDateRange[0], "YYYY-MM-DD").format(helper.getDateFormat());
                let date2 = moment(this.plannedArrivalDateRange[1], "YYYY-MM-DD").format(helper.getDateFormat());

                return date1 + " ~ " + date2;
            } else if (this.plannedArrivalDateRange.length === 1) {
                return moment(this.plannedArrivalDateRange[0], "YYYY-MM-DD").format(helper.getDateFormat());
            } else {
                return this.plannedArrivalDateRange;
            }
        },
        vgmCutOffDateRangeText() {
            if (this.vgmCutOffDateRange.length > 1) {
                let date1 = moment(this.vgmCutOffDateRange[0], "YYYY-MM-DD").format(helper.getDateFormat());
                let date2 = moment(this.vgmCutOffDateRange[1], "YYYY-MM-DD").format(helper.getDateFormat());
                return date1 + " ~ " + date2;
            } else if (this.vgmCutOffDateRange.length === 1) {
                return moment(this.vgmCutOffDateRange[0], "YYYY-MM-DD").format(helper.getDateFormat());
            } else {
                return this.vgmCutOffDateRange;
            }
        },
        cutOffDateRangeText() {
            if (this.cutOffDateRange.length > 1) {
                let date1 = moment(this.cutOffDateRange[0], "YYYY-MM-DD").format(helper.getDateFormat());
                let date2 = moment(this.cutOffDateRange[1], "YYYY-MM-DD").format(helper.getDateFormat());
                return date1 + " ~ " + date2;
            } else if (this.cutOffDateRange.length === 1) {
                return moment(this.cutOffDateRange[0], "YYYY-MM-DD").format(helper.getDateFormat());
            } else {
                return this.cutOffDateRange;
            }
        }
    },
    methods: {
        closeDialog() {
            this.dialog = false;
        },
        applyFilters() {
            // Logic to apply filters
            this.dialog = false;
        },
        // searchFields(menuObject) {
        //     //just emit
        //     this.$emit("search-fields", menuObject);
        // },
        cancelPlannedDepartureDatePicker() {
            this.plannedDepartureDateMenu = false;
        },
        cancelPlannedArrivalDatePicker() {
            this.plannedArrivalDateMenu = false;
        },
        cancelVgmCutOffDatePicker() {
            this.vgmMenu = false;
        },
        cancelCutOffDatePicker() {
            this.cutOffMenu = false;
        },
        onClearPlannedDepartureDates() {
            this.plannedDepartureDateRange = [];
            this.savedPlannedDepartureDateRange = [];
            this.relativePlannedDepartureDateRange = null;
        },
        onClearPlannedArrivalDates() {
            this.plannedArrivalDateRange = [];
            this.savedPlannedArrivalDateRange = [];
            this.relativePlannedArrivalDateRange = null;
        },
        onClearVgmCutOffDate() {
            this.vgmCutOffDateRange = [];
            this.savedVgmCutOffDateRange = [];
            this.relativeVgmCutOffDateRange = null;
        },
        onClearCutOffDate() {
            this.cutOffDateRange = [];
            this.savedCutOffDateRange = [];
            this.relativeCutOffDateTimeRange = null;
        },
        getFilterChoiceNameByCode(code) {
            /* return this.$t(
                this.defaultFilterChoices.find(item => {
                    return item.code === code;
                })?.tag
            ); */
            return "Value";
        },
        cancelFilter() {
            //clear menuObject
            this.codeSelected = null;
        },
        openFilterMenu() {
            this.menu = true;
        },
        savePlannedDepartureDates() {
            this.$nextTick(() => {
                if (this.$refs.plannedDepartureDateMenu) {
                    this.$refs.plannedDepartureDateMenu.save(this.plannedDepartureDateRange.sort());
                }
            });
        },
        savePlannedArrivalDates() {
            this.$nextTick(() => {
                if (this.$refs.plannedArrivalDateMenu) {
                    this.$refs.plannedArrivalDateMenu.save(this.plannedArrivalDateRange.sort());
                }
            });
        },
        saveVgmCutOffDates() {
            this.$nextTick(() => {
                if (this.$refs.vgmMenu) {
                    this.$refs.vgmMenu.save(this.vgmCutOffDateRange.sort());
                }
            });
        },
        saveCutOffDates() {
            this.$nextTick(() => {
                if (this.$refs.cutOffMenu) {
                    this.$refs.cutOffMenu.save(this.cutOffDateRange.sort());
                }
            });
        },
        updateCodeSelected(val) {
            this.$emit("update:currentCode", val);
        },
        remove(type, item) {
            if (type === "alertCode") {
                let index = this.menuObject["alertCode"].indexOf(item.value);
                if (index !== -1) {
                    this.menuObject["alertCode"].splice(index, 1);
                }
            } else if (type === "tenantAlertRuleId") {
                let index = this.menuObject["tenantAlertRuleId"].indexOf(item.value);
                if (index !== -1) {
                    this.menuObject["tenantAlertRuleId"].splice(index, 1);
                }
            }
        }
    }
};
</script>

<style scoped>
.filter-dialog-template {
    /* Add your styles here */
}
</style>
