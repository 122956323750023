import { render, staticRenderFns } from "./TripBodyTemplate.vue?vue&type=template&id=7c154c2c&scoped=true&"
import script from "./TripBodyTemplate.vue?vue&type=script&lang=js&"
export * from "./TripBodyTemplate.vue?vue&type=script&lang=js&"
import style0 from "./TripBodyTemplate.vue?vue&type=style&index=0&id=7c154c2c&scoped=true&lang=css&"


/* normalize component */
import normalizer from "!../../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "7c154c2c",
  null
  
)

export default component.exports