import { VBtn } from 'vuetify/lib/components/VBtn';
import { VCard } from 'vuetify/lib/components/VCard';
import { VCardActions } from 'vuetify/lib/components/VCard';
import { VCardText } from 'vuetify/lib/components/VCard';
import { VCardTitle } from 'vuetify/lib/components/VCard';
import { VChip } from 'vuetify/lib/components/VChip';
import { VCol } from 'vuetify/lib/components/VGrid';
import { VDataTable } from 'vuetify/lib/components/VDataTable';
import { VDialog } from 'vuetify/lib/components/VDialog';
import { VIcon } from 'vuetify/lib/components/VIcon';
import { VRow } from 'vuetify/lib/components/VGrid';
import { VSelect } from 'vuetify/lib/components/VSelect';
import { VTooltip } from 'vuetify/lib/components/VTooltip';
import Intersect from 'vuetify/lib/directives/intersect';
import Resize from 'vuetify/lib/directives/resize';

var render = function () {
var this$1 = this;
var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',[_c(VCard,{directives:[{def: Resize,name:"resize",rawName:"v-resize",value:(_vm.onResize),expression:"onResize"}],attrs:{"elevation":"5","outlined":""}},[_c(VCardText,{staticClass:"pb-3 pr-3"},[_c('div',{staticClass:"d-flex justify-end"},[_c('div',[_c(VTooltip,{attrs:{"bottom":""},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
var on = ref.on;
var attrs = ref.attrs;
return [_c(VBtn,_vm._g(_vm._b({attrs:{"icon":""},on:{"click":function($event){_vm.exportDataDialog ? (_vm.exportDataDialog = false) : (_vm.exportDataDialog = true)}}},'v-btn',attrs,false),on),[_c(VIcon,[_vm._v("download")])],1)]}}])},[_c('span',[_vm._v("Download loads for tomorrow")])]),_c(VBtn,{attrs:{"icon":""}},[_c(VIcon,{on:{"mouseenter":function($event){return _vm.startScrolling('left')},"mouseleave":_vm.stopScrolling}},[_vm._v("arrow_back")])],1),_c(VBtn,{attrs:{"icon":""}},[_c(VIcon,{on:{"mouseenter":function($event){return _vm.startScrolling('right')},"mouseleave":_vm.stopScrolling}},[_vm._v("arrow_forward")])],1)],1),_c('FilterMenu',{attrs:{"choices":_vm.headerChoices,"title":"Customize","description":"Select columns to display","open-on-hover":false,"height":"500px","width":"250px"},on:{"changed":_vm.onFilterMenuChanged}})],1),_c(VDataTable,{staticClass:"row-pointer",attrs:{"headers":_vm.dynamicHeaders,"items":_vm.bookingOrderQxs.edges,"item-key":"node.id","loading-text":"Loading...","loading":_vm.$apollo.queries.bookingOrderQxs.loading,"hide-default-footer":"","disable-pagination":"","disable-sort":"","loader-height":"2","mobile-breakpoint":0,"no-data-text":"No data","fixed-header":"","height":_vm.tableHeight},on:{"click:row":function (item) { return this$1.$emit('click:row', item); }},scopedSlots:_vm._u([{key:"item.node.etd",fn:function(ref){
var item = ref.item;
return [(item.node.etd)?_c('span',[_vm._v(_vm._s(_vm.qxFormatDateTime(item.node.etd)))]):_vm._e()]}},{key:"item.node.eta",fn:function(ref){
var item = ref.item;
return [(item.node.eta)?_c('span',[_vm._v(_vm._s(_vm.qxFormatDateTime(item.node.eta)))]):_vm._e()]}},{key:"item.node.orderDate",fn:function(ref){
var item = ref.item;
return [(item.node.orderDate)?_c('span',[_vm._v(_vm._s(_vm.qxFormatDateTime(item.node.orderDate)))]):_vm._e()]}},{key:"item.node.stackStatus",fn:function(ref){
var item = ref.item;
return [(item.node.stackStatus)?_c('span',[_vm._v(" "+_vm._s(_vm.stackStatusList.find(function (type) { return type.value.toLowerCase() === item.node.stackStatus.toLowerCase(); }).text)+" ")]):_vm._e()]}},{key:"item.node.orderStatus",fn:function(ref){
var item = ref.item;
return [(item.node.orderStatus)?_c('span',[_c(VChip,{attrs:{"color":_vm.getStatus('orderStatus', item.node.orderStatus).color}},[_vm._v(" "+_vm._s(_vm.getStatus("orderStatus", item.node.orderStatus).text)+" ")])],1):_vm._e()]}},{key:"item.node.shippingLineStatus",fn:function(ref){
var item = ref.item;
return [(item.node.shippingLineStatus)?_c('span',[_c(VChip,{attrs:{"color":_vm.getStatus('shippingLineStatus', item.node.shippingLineStatus).color}},[_vm._v(" "+_vm._s(_vm.getStatus("shippingLineStatus", item.node.shippingLineStatus).text)+" ")])],1):_vm._e()]}},{key:"item.node.latestChangeRequest.status",fn:function(ref){
var item = ref.item;
return [(item.node.latestChangeRequest)?_c('span',[_c(VChip,{attrs:{"color":_vm.getStatus('changeRequestStatus', item.node.latestChangeRequest.status).color}},[_vm._v(" "+_vm._s(_vm.getStatus("changeRequestStatus", item.node.latestChangeRequest.status).text)+" ")])],1):_vm._e()]}},{key:"item.numberOfPalletsPerShipment",fn:function(ref){
var item = ref.item;
return [_c('span',[_vm._v(" "+_vm._s(_vm.commaSeparate(item.node.bookingTransportOrderQxSet.edges.map(function (edge) { return edge.node.numberOfPalletsPerShipment; })))+" ")])]}},{key:"item.loadPointOne",fn:function(ref){
var item = ref.item;
return [_c('span',[_vm._v(" "+_vm._s(_vm.commaSeparate(item.node.bookingTransportOrderQxSet.edges.map(function (edge) { return edge.node.loadPoint1; })))+" ")])]}},{key:"item.loadPointTwo",fn:function(ref){
var item = ref.item;
return [_c('span',[_vm._v(" "+_vm._s(_vm.commaSeparate(item.node.bookingTransportOrderQxSet.edges.map(function (edge) { return edge.node.loadPoint2; })))+" ")])]}},{key:"item.loadDate",fn:function(ref){
var item = ref.item;
return [_c('span',[_vm._v(" "+_vm._s(_vm.commaSeparate(item.node.bookingTransportOrderQxSet.edges.map(function (edge) { return _vm.qxFormatDateTime(edge.node.loadDate); })))+" ")])]}},{key:"item.exporterReferenceNumber",fn:function(ref){
var item = ref.item;
return [_c('span',[_vm._v(" "+_vm._s(_vm.commaSeparate(item.node.bookingTransportOrderQxSet.edges.map(function (edge) { return edge.node.exporterReferenceNumber; })))+" ")])]}},{key:"item.bookingReference",fn:function(ref){
var item = ref.item;
return [_c('span',[_vm._v(" "+_vm._s(_vm.commaSeparate(item.node.bookingTransportOrderQxSet.edges.map(function (edge) { return edge.node.bookingReference; })))+" ")])]}},{key:"item.node.transportType",fn:function(ref){
var item = ref.item;
return [(item.node.transportType)?_c('span',[_vm._v(" "+_vm._s(_vm.transportTypeList.find(function (type) { return type.value === item.node.transportType; }).text)+" ")]):_vm._e()]}},{key:"item.node.incoTerm",fn:function(ref){
var item = ref.item;
return [(item.node.incoTerm)?_c('span',[_vm._v(" "+_vm._s(_vm.terms.find(function (type) { return type.value === item.node.incoTerm; }).text)+" ")]):_vm._e()]}}])},[_c('template',{slot:"body.append"},[_c('tr',[_c('td',{style:(_vm.centralizeLoadBtnClass),attrs:{"colspan":2}},[(_vm.hasMoreData)?_c(VBtn,{directives:[{def: Intersect,name:"intersect",rawName:"v-intersect",value:(_vm.onLoadMoreTriggerIntersect),expression:"onLoadMoreTriggerIntersect"}],staticClass:"mb-5",attrs:{"disabled":!_vm.hasMoreData,"loading":_vm.$apollo.queries.bookingOrderQxs.loading,"plain":""},on:{"click":_vm.loadMore}},[_vm._v(" Load more ")]):_vm._e()],1)])])],2)],1)],1),_c(VDialog,{attrs:{"max-width":"500","persistent":""},model:{value:(_vm.exportDataDialog),callback:function ($$v) {_vm.exportDataDialog=$$v},expression:"exportDataDialog"}},[_c(VCard,[_c(VCardTitle,{staticClass:"primary white--text"},[_c(VIcon,{staticClass:"mr-2",attrs:{"color":"white"}},[_vm._v("download")]),_vm._v(" "+_vm._s(_vm.$t("trips.download_timeline"))+" ")],1),_c(VCardText,[[_c(VRow,{staticClass:"mt-5 align-center"},[_c(VCol,{attrs:{"cols":"4"}},[_c('span',{staticClass:"ml-5 subtitle-1"},[_vm._v(_vm._s(_vm.$t("trips.file_format"))+":")])]),_c(VCol,{attrs:{"cols":"6"}},[_c(VSelect,{attrs:{"auto-select-first":"","items":_vm.fileFormatsAvailable},model:{value:(_vm.fileFormat),callback:function ($$v) {_vm.fileFormat=$$v},expression:"fileFormat"}})],1)],1)]],2),_c(VCardActions,{staticClass:"d-flex justify-end pb-5 pr-5"},[_c(VBtn,{attrs:{"text":"","rounded":"","disabled":_vm.isLoadingFile},on:{"click":_vm.closeAndResetInput}},[_vm._v(_vm._s(_vm.$t("general.close")))]),_c(VBtn,{attrs:{"color":"primary","text":"","loading":_vm.isLoadingFile,"rounded":"","outlined":"","dense":""},on:{"click":_vm.submitExportData}},[_vm._v(" "+_vm._s(_vm.$t("general.download"))+" ")])],1)],1)],1)],1)}
var staticRenderFns = []

export { render, staticRenderFns }