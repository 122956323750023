<template>
    <v-card-text class="pa-0">
    </v-card-text>
</template>



<script>

export default {
    name: "PartnerFooterTemplate",
    components: {
        
    },
    props: {
        partner: {
            type: Object,
            required: true
        }
    },
    data() {
        return {
                
        }
    },
    methods: {
    }
}
</script>

<style scoped>
</style>