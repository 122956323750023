<template>
    <v-container fluid>
        <v-row class="d-flex align-center justify-center px-10">
            
            <v-col xl="11" lg="12" md="12" sm="12" xs="12" cols="12" class="d-flex flex-row">
                <v-row>
                    <v-col cols="12" xs="12" sm="6" md="6" lg="6" xl="6">
                        <h2 class="modelTitle ">
                            {{ $t("home.trackers") }}
                        </h2>
                    </v-col>
                    <!-- <v-spacer></v-spacer>
                    <v-col cols="auto">
                        <v-btn color="primary" @click="addTracker()">
                            {{ $t("trackers.add_tracker") }}
                        </v-btn>
                    </v-col> -->
                </v-row>
            </v-col>
            <v-col xl="11" lg="12" md="12" sm="12" xs="12" cols="12" v-if="goTopShow">
                <!-- Scroll to the button-->
                <v-btn
                    v-scroll="onScroll"
                    v-show="goTopShow"
                    fab
                    dark
                    fixed
                    bottom
                    right
                    small
                    style="z-index: 1"
                    color="primary"
                    @click="toTop"
                >
                    <v-icon>keyboard_arrow_up</v-icon>
                </v-btn>

                <!-- Filter -->
                <!-- <TrackerFilter
                    class="mx-3"
                    ref="trackerFilter"
                    v-on:filter-changed="onFilterChanged"
                    v-on:history-filter-changed="onFilterChanged"
                    v-on:updateTrackerTable="refreshTable"
                    :filterCode="filterCode"
                    :isHistoryTab="isHistoryTab"
                ></TrackerFilter> -->
            </v-col>
            <v-col xl="11" lg="12" md="12" sm="12" xs="12" cols="12" class="py-0">
                <!-- tracker table -->
                <TableView
                    :isHistoryTab.sync="isHistoryTab"
                    v-on:click:row="onTrackerSelected"
                    v-on:selectedTrackers="onSelectTrackersToMove"
                />
            </v-col>
        </v-row>

        <!-- tracker detail drawer -->
        <v-navigation-drawer
            v-model="detailDrawer"
            :width="detailDrawerWidth"
            :style="{ top: '46px', zIndex: 3 }"
            right
            app
            disable-route-watcher
            temporary
            class="background"
        >
            <div>
                <DetailView
                    v-if="selectedTracker || idFromRouter"
                    :key="selectedTracker ? selectedTracker.id : idFromRouter"
                    :trackerProp="selectedTracker"
                    :trackerId="idFromRouter"
                    :isFullScreen.sync="isDetailDrawerFullScreen"
                    v-on:refreshDataTable="refreshTable"
                    v-on:close="detailDrawer = false"
                />
            </div>
        </v-navigation-drawer>

        <v-navigation-drawer
            v-model="historyDetailDrawer"
            :width="detailDrawerWidth"
            :style="{ top: '46px', zIndex: 3 }"
            right
            app
            disable-route-watcher
            temporary
        >
            <div>
                <HistoryDetailView
                    :key="selectedTrackerUploadLog ? selectedTrackerUploadLog.id : selectedTrackerUploadLog"
                    :historyDetail="selectedTrackerUploadLog"
                    :isFullScreen.sync="isDetailDrawerFullScreen"
                    v-on:close="historyDetailDrawer = false"
                />
            </div>
        </v-navigation-drawer>
    </v-container>
</template>
<script>
import helper from "@/utils/helper.js";
import { eventBus } from "@/main.js";
// import SearchAndFilter from '@/components/base/SearchAndFilterComponent.vue';
// import TrackerFilter from "@/components/trackers/TrackerFilterPreview.vue";
import TableView from "@/components/trackers/TrackerTablePreview.vue";
import DetailView from "@/components/trackers/TrackerDetailPreview.vue";
import HistoryDetailView from "@/components/trackers/TrackerHistoryDetailPreview.vue";

export default {
    title: "preview-trackers",
    components: {
        // SearchAndFilter,
        // TrackerFilter,
        TableView,
        DetailView,
        HistoryDetailView
    },
    apollo: {},
    data() {
        return {
            detailDrawer: false,
            historyDetailDrawer: false,
            detailDrawerFullScreen: false,
            openTrackerUploadHistoryDialog: false,
            openTrackerChangeLocationDialog: false,
            openTrackerChangeTenantDialog: false,
            openSelector: false,
            openAddTrackerDialog: false,
            idFromRouter: null,
            filterCode: "",
            goTopShow: false,
            isLoading: false,
            isDetailDrawerFullScreen: false,
            menu: false,
            filter: {
                orderBy: "-lastConnectionTime",
                first: 25,
                isArchived: false
            },
            trackerLogFilter: {
                orderBy: "-createdAt",
                first: 25
            },
            selectedTrackersToMove: [],
            selectedTracker: null,
            selectedTrackerUploadLog: null,
            tutorialDialog: false,
            isHistoryTab: false
        };
    },
    computed: {
        detailDrawerWidth() {
            if (this.isDetailDrawerFullScreen) {
                return "86%";
            }

            switch (this.$vuetify.breakpoint.name) {
                case "xs":
                    return "80%";
                case "sm":
                    return "80%";
                case "md":
                    return "80%";
                case "lg":
                    return "70%";
                case "xl":
                    return "70%";
                default:
                    return "100%";
            }
        }
    },
    mounted() {
        if (this.$route?.query?.code) this.filterCode = this.$route.query.code;

        if (this.$route?.query?.id) {
            this.idFromRouter = this.$route.query.id;
            this.detailDrawer = true;
        }

        /* if (this.$route?.params?.id) {
            this.idFromRouter = this.$route.params.id;
            this.trackerDetailDrawer = true;
            this.trackerDetailFullScreen = true;
        } */
    },
    watch: {
        isDetailDrawerFullScreen(val) {
            if (val) {
                if (val) {
                    eventBus.$emit("closeMenu");
                }
            }
        },
        detailDrawer(val) {
            if (!val) {
                if (this.$route?.query && Object.keys(this.$route.query).length > 0) {
                    this.$router.push({ query: {} }).catch(err => {
                        if (err.name !== "NavigationDuplicated") {
                            console.error(err);
                        }
                    });
                }
            }
        }
    },
    methods: {
        onCloseSelector() {
            this.openSelector = false;
            this.selectedTrackersToMove = [];
        },
        onSelectTrackersToMove(trackers) {
            this.selectedTrackersToMove = trackers;
        },
        closeSelector(val) {
            this.openSelector = val;
            this.selectedTrackersToMove = [];
        },
        onLoading(val) {
            this.isLoading = val;
        },

        // scroll to top of the page
        onScroll(e) {
            if (typeof window === "undefined") return;
            const top = window.pageYOffset || e.target.scrollTop || 0;
            this.goTopShow = top > 20;
        },

        toTop() {
            this.$vuetify.goTo(0);
        },
        refreshTable() {
            this.$refs.trackertable.$apollo.queries.trackers.refetch();
        },
        loadMore() {
            this.isLoading = false;
        },
        // onFilterChanged(filter) {
        //     if (!this.isHistoryTab) {
        //         this.filter = filter;
        //     } else {
        //         this.trackerLogFilter = filter;
        //     }
        // },
        onTrackerSelected(item, type = "tracker") {
            if (type === "history") {
                this.selectedTrackerUploadLog = item.node;
                this.historyDetailDrawer = true;
                return;
            }
            this.selectedTracker = item.node;
            // this.$router.push({ query: { id: tracker.node.id } });
            this.detailDrawer = true;
        },
        /* onTrackerDetailDrawerFullScreenChange(val) {
            console.log("onTrackerDetailDrawerFullScreenChange", val);
            this.detailDrawerFullScreen = val;
            if (val) {
                eventBus.$emit("closeMenu");
            }
        }, */
        // addTracker() {
        //     this.$router.push({
        //         path: "/previewaddtrackers",
        //         query: {
        //             create: true
        //         }
        //     });
        // }
    }
};
</script>
<style scoped>
.custom-height {
    height: 35px;
}

.v-input--selection-controls {
    margin-top: 0;
}
.v-select.fit {
    max-width: min-content;
    padding: 10px;
}
.v-select.fit .v-select__selection--comma {
    text-overflow: unset;
    padding-right: 1rem;
}

.v-select.fit .v-select__selections input {
    display: none;
}
.labelText {
    font-size: 32px;
    font-weight: 700;
    color: #0e0d35;
    line-height: 38.4px;
}

.titleText {
    font-size: 12px;
    font-weight: 700;
    color: #a4a4a4;
    line-height: 14.4px;
}
.modelTitle {
    font-size: 28px;
    font-weight: 700;
    line-height: 32px;
    text-align: left;
    margin-bottom: 4px;
}
</style>
