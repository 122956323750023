<template>
    <v-row dense>
        <v-col cols="12">
            <v-autocomplete
                v-model="partners"
                :loading="loading"
                :items="consignor"
                :label="label"
                @focus="openPartnersList = true"
                dense
                hide-selected
                hide-details
                clearable
                item-text="node.name"
                item-value="node.id"
                :outlined="outlined"
                :no-data-text="loading ? this.$t('loading.loading1') : this.$t('loading.no_available')"
                multiple
            >
                <template v-slot:selection="{ attrs, item }">
                    <v-chip v-bind="attrs" close small class="partner-chip" @click:close="remove(item)">
                        <span class="chip-text">{{ item.node.name }}</span>
                    </v-chip>
                </template>
            </v-autocomplete>
        </v-col>
    </v-row>
</template>

<script>
import gql from "graphql-tag";

export default {
    name: "PartnerList",
    props: {
        label: {
            type: String,
            default: ""
        },
        value: {
            type: Array,
            default: () => []
        },
        refreshTable: {
            type: Boolean,
            default: false
        },
        outlined: {
            type: Boolean,
            default: false
        }
    },
    apollo: {
        tenantPartners: {
            query: gql`
                query tenantPartners(
                    $first: Int
                    $last: Int
                    $before: String
                    $after: String
                    $orderBy: String
                    $nameIcontains: String
                    $partnerType: String
                ) {
                    tenantPartners(
                        first: $first
                        last: $last
                        before: $before
                        after: $after
                        orderBy: $orderBy
                        name_Icontains: $nameIcontains
                        partnerType: $partnerType
                    ) {
                        pageInfo {
                            hasNextPage
                            hasPreviousPage
                            startCursor
                            endCursor
                        }
                        edges {
                            node {
                                id
                                name
                                partnerType
                            }
                        }
                    }
                }
            `,
            variables() {
                return {
                    orderBy: "name",
                    partnerType: "consignor"
                };
            },
            fetchPolicy: "no-cache",
            /* nextFetchPolicy: "cache-first", */
            update: data => data.tenantPartners,
            result({ loading }) {
                this.loading = loading;
            },
            skip() {
                return false;
            }
        }
    },
    data() {
        return {
            partnerFilter: {
                first: 20
            },
            loading: false,
            shouldLoadMore: false,
            isInitialLoad: true,
            tenantPartners: { edges: [] },
            openPartnersList: false
        };
    },
    mounted() {
        this.loading = true;
    },
    watch: {
        openPartnersList() {}
    },
    computed: {
        hasMoreData() {
            return this.tenantPartners?.pageInfo?.hasNextPage;
        },
        partners: {
            get() {
                return this.value;
            },
            set(value) {
                if (value.length > 0) {
                    let list = this.tenantPartners?.edges.filter(e => value.includes(e.node.id));
                    this.$emit("update:name", list);
                } else {
                    this.$emit("update:name", []);
                }
                this.$emit("input", value);
            }
        },
        consignor() {
            return this.tenantPartners?.edges.filter(e => e.node.partnerType === "consignor");
        },
        shouldSkip() {
            return !this.openPartnersList && !this.refreshTable;
        }
    },
    methods: {
        remove (item) {
            this.partners.splice(this.partners.indexOf(item.node.id), 1)
        }
    }
};
</script>
<style scoped>
.partner-chip {
    max-width: 200px; 
}

.chip-text {
    white-space: nowrap;
    overflow: hidden;
    text-overflow: ellipsis;
    display: inline-block;
    max-width: calc(200px - 24px); 
}
</style>
