<template>
    <v-hover>
        <template v-slot="{}">
            <v-card class="mt-1 pl-5 pr-0 transparent" outlined>
                <v-row>
                    <v-col cols="12">
                        <v-row dense align="start" class="d-flex align-center py-0">
                            <v-col cols="3" class="py-0">
                                <SearchBar v-model="search" :label="$t('filters.search')" ref="searchBar">
                                    <template v-slot:prepend-inner>
                                        <div>
                                            <v-tooltip bottom>
                                                <template v-slot:activator="{ on }">
                                                    <v-icon v-on="on" color="">
                                                        search
                                                    </v-icon>
                                                </template>
                                                <span>
                                                    {{ $t("trips.searchFilter") }}
                                                </span>
                                            </v-tooltip>
                                        </div>
                                    </template>
                                </SearchBar>
                            </v-col>
                            <!-- Filters -->
                            <v-col cols="auto" v-for="i in filterValuesObj" :key="i.id">
                                <v-menu
                                    :ref="getCode(i.id)"
                                    max-width="300px"
                                    :close-on-content-click="false"
                                    :close-on-click="true"
                                    right
                                    bottom
                                    nudge-top="-6"
                                    offset-y
                                    @click.stop
                                    @input="onMenuStateChange($event, getCode(i.id))"
                                >
                                    <template v-slot:activator="{ on, attrs }">
                                        <v-chip
                                            label
                                            close
                                            class="elevation-0 text-capitalize classText content"
                                            v-on="on"
                                            v-bind="attrs"
                                            @click:close="deleteFilterValue(i.id)"
                                            @click="addCode(getCode(i.id))"
                                            v-if="i.id != 'alertTime_Gte' 
                                                    && i.id != 'alertTime_Lte' 
                                                    && i.id != 'plannedDepartureDate_Gte' 
                                                    && i.id != 'plannedDepartureDate_Lte' 
                                                    && i.id != 'plannedArrivalDate_Gte' 
                                                    && i.id != 'plannedArrivalDate_Lte'"
                                        >
                                            <span v-if="i.id == ''"></span>
                                            {{ i.name + ":" + " " + i.value }}
                                        </v-chip>
                                        <v-chip
                                            label
                                            close
                                            v-on="on"
                                            v-bind="attrs"
                                            class="elevation-0 text-capitalize classText content"
                                            @click:close="deleteFilterValue(i.id)"
                                            @click="addCode(getCode(i.id))"
                                            v-else
                                        >
                                            {{ i.name + ":" + " " + i.value }}
                                        </v-chip>
                                    </template>
                                    <v-card height="100%" class="pa-1">
                                        <v-card-text class="mt-2">
                                            <FilterMenuTemplate
                                                v-model="menuObject"
                                                v-on:search-fields="searchFields"
                                                :isReadOnly="true"
                                                :currentCode="codeSelected"
                                            ></FilterMenuTemplate>
                                        </v-card-text>
                                        <v-card-actions class="pa-0">
                                            <v-spacer></v-spacer>
                                            <v-btn text @click="cancelFilter(getCode(i.id))">
                                                Close
                                            </v-btn>
                                            <v-btn color="primary" @click="searchFields(menuObject, getCode(i.id))">
                                                Apply
                                            </v-btn>
                                        </v-card-actions>
                                    </v-card>
                                </v-menu>
                            </v-col>
                            <!-- Add Filter -->
                            <v-col cols="2">
                                <v-menu
                                    ref="addFilterMenu"
                                    max-width="300px"
                                    :close-on-content-click="false"
                                    :close-on-click="true"
                                    @click.stop
                                    right
                                    bottom
                                    nudge-top="-6"
                                    offset-y
                                    @input="onMenuStateChange($event, 'addFilterMenu')"
                                >
                                    <template v-slot:activator="{ on, attrs }">
                                        <v-btn color="primary" class="content" v-on="on" v-bind="attrs">
                                            <!-- <v-icon>add</v-icon> -->
                                            {{ $t("general.add_filter") }}
                                        </v-btn>
                                    </template>
                                    <v-card height="100%" class="pa-1">
                                        <v-card-text class="mt-2">
                                            <FilterMenuTemplate
                                                v-model="menuObject"
                                                v-on:search-fields="searchFields"
                                                :isReadOnly="false"
                                                :currentCode.sync="codeSelected"
                                            ></FilterMenuTemplate>
                                        </v-card-text>
                                        <v-card-actions class="pa-0">
                                            <v-spacer></v-spacer>
                                            <v-btn text @click="cancelFilter('addFilterMenu')">
                                                Close
                                            </v-btn>
                                            <v-btn
                                                color="primary"
                                                @click="searchFields(menuObject, 'addFilterMenu')"
                                                :disabled="codeSelected === null || codeSelected === ''"
                                            >
                                                Apply
                                            </v-btn>
                                        </v-card-actions>
                                    </v-card>
                                </v-menu>
                            </v-col>
                        </v-row>
                    </v-col>
                    <!-- <v-col cols="1" v-if="!me.isPartnerUser">
                        <v-btn small icon @click="openResolveOptions">
                            <v-icon>checklist_rtl</v-icon>
                        </v-btn>
                    </v-col> -->
                </v-row>
            </v-card>
        </template>
    </v-hover>
</template>

<script>
import SearchBar from "@/components/base/SearchBar.vue";
import FilterMenuTemplate from "@/components/alerts/cardtemplates/FilterMenuTemplate.vue";
import helper from "@/utils/helper.js";
import moment from "moment";
import { debounce } from "lodash";
import store from "@/store";
import gql from "graphql-tag";

export default {
    components: {
        SearchBar,
        FilterMenuTemplate
    },
    props: {
        tripFilter: {
            type: Object,
            required: false,
            default: () => {
                return {};
            }
        }
    },
    data() {
        return {
            menu: false,
            filter: {
                search: null,
                trip_ReferenceNumber_Icontains: null,
                trip_Status_Icontains: null,
                tenantAlertRule_Name_Icontains: null,
                tenantAlertRule_Tag_Icontains: null,
                tripFreight_ReferenceNumber_Icontains: null,
                tripFreight_ContainerNumber_Icontains: null,
                tripFreight_AirWaybillNumber_Icontains: null,
                tripFreightTracker_Tracker_SerialNumber_Icontains: null,
                trip_TenantCarrier: null,
                trip_TripSeaTransportSet_Vessel: null,
                alertTime_Gte: null,
                alertTime_Lte: null,
                plannedDepartureDate_Gte: null,
                plannedDepartureDate_Lte: null,
                plannedArrivalDate_Gte: null,
                plannedArrivalDate_Lte: null,
                pol: null,
                transshipment: null,
                pod: null,
                goods: null,
                trade_Icontains: null,
                tripFreightSet_Contact_Icontains: null,
                orderNumber: null,
                alertCode: []
            },
            codeSelected: null,
            menuObject: {},
            copyMenuObject:{},
            enabledFilters: [],
            filterValuesObj: [],
            plannedDepartureDateMenu: false,
            plannedDepartureDateRange: [],
            savedPlannedDepartureDateRange: [],
            relativePlannedDepartureDateRange: null,
            relativePlannedDepartureDateRangeChoices: [
                this.$t("date.last30"),
                this.$t("date.last90"),
                this.$t("date.last180"),
                this.$t("date.last360")
            ],
            plannedArrivalDateMenu: false,
            plannedArrivalDateRange: [],
            savedPlannedArrivalDateRange: [],
            relativePlannedArrivalDateRange: null,
            relativePlannedArrivalDateRangeChoices: [
                this.$t("date.last30"),
                this.$t("date.last90"),
                this.$t("date.last180"),
                this.$t("date.last360")
            ],
            alertTimeMenu: false,
            alertTimeRange: [],
            savedAlertTimeRange: [],
            relativeAlertTimeRange: null,
            relativeAlertTimeRangeChoices: [
                this.$t("date.last30"),
                this.$t("date.last90"),
                this.$t("date.last180"),
                this.$t("date.last360")
            ],
            debounceUpdatePreferences: null
        };
    },
    created() {
        // Initialize debounced function
        this.debounceUpdatePreferences = debounce(this.updatePreferences, 500);

        // Load saved filters from preferences
        this.loadSavedFilters();
    },
    computed: {
        me() {
            return helper.me();
        },
        search: {
            get() {
                let _search = this.filter.search || "";
                return _search;
            },
            set(val) {
                this.filter.search = val;
                this.updateFilter("search", this.filter);
            }
        },
        alertTimeRangeText() {
            if (this.alertTimeRange.length > 1) {
                let date1 = moment(this.alertTimeRange[0], "YYYY-MM-DD").format(helper.getDateFormat());
                let date2 = moment(this.alertTimeRange[1], "YYYY-MM-DD").format(helper.getDateFormat());
                if (date1 == date2) {
                    return date1;
                }
                return date1 + " ~ " + date2;
            } else if (this.alertTimeRange.length === 1) {
                return moment(this.alertTimeRange[0], "YYYY-MM-DD").format(helper.getDateFormat());
            } else {
                return this.alertTimeRange;
            }
        },
        plannedDepartureDateRangeText() {
            if (this.plannedDepartureDateRange.length > 1) {
                let date1 = moment(this.plannedDepartureDateRange[0], "YYYY-MM-DD").format(helper.getDateFormat());
                let date2 = moment(this.plannedDepartureDateRange[1], "YYYY-MM-DD").format(helper.getDateFormat());
                if (date1 == date2) {
                    return date1;
                }
                return date1 + " ~ " + date2;
            } else if (this.plannedDepartureDateRange.length === 1) {
                return moment(this.plannedDepartureDateRange[0], "YYYY-MM-DD").format(helper.getDateFormat());
            } else {
                return this.plannedDepartureDateRange;
            }
        },
        plannedArrivalDateRangeText() {
            if (this.plannedArrivalDateRange.length > 1) {
                let date1 = moment(this.plannedArrivalDateRange[0], "YYYY-MM-DD").format(helper.getDateFormat());
                let date2 = moment(this.plannedArrivalDateRange[1], "YYYY-MM-DD").format(helper.getDateFormat());
                if (date1 == date2) {
                    return date1;
                }
                return date1 + " ~ " + date2;
            } else if (this.plannedArrivalDateRange.length === 1) {
                return moment(this.plannedArrivalDateRange[0], "YYYY-MM-DD").format(helper.getDateFormat());
            } else {
                return this.plannedArrivalDateRange;
            }
        },
        alertsType: function() {
            let _alertsType = [
                {
                    name: this.$t("general.all"),
                    value: "All"
                },
                {
                    name: this.$t("dashboard.etd_delay"),
                    value: "SEA_ETD_DELAY"
                },
                {
                    name: this.$t("dashboard.eta_delay"),
                    value: "SEA_ETA_DELAY"
                },
                {
                    name: this.$t("dashboard.ts_waiting"),
                    value: "SEA_TRANS_WAIT"
                },
                {
                    name: this.$t("dashboard.pod_waiting"),
                    value: "SEA_DEST_WAIT"
                },
                {
                    name: this.$t("dashboard.custom_alert"),
                    value: "CUSTOM_ALERT"
                }
            ];
            return _alertsType;
        },
    },
    watch: {
        relativeAlertTimeRange(val) {
            const _index = this.relativeAlertTimeRangeChoices.indexOf(val);
            let _startDate = null;
            let _endDate = null;
            switch (_index) {
                case 0:
                    // last month
                    _endDate = moment().format("YYYY-MM-DD");
                    _startDate = moment()
                        .date(0)
                        .startOf("month")
                        .format("YYYY-MM-DD");
                    this.alertTimeRange = [_startDate, _endDate];
                    this.savedAlertTimeRange = [_startDate, _endDate];
                    break;
                case 1:
                    // last quarter
                    _endDate = moment().format("YYYY-MM-DD");
                    _startDate = moment()
                        .subtract(3, "months")
                        .startOf("month")
                        .format("YYYY-MM-DD");
                    this.alertTimeRange = [_startDate, _endDate];
                    this.savedAlertTimeRange = [_startDate, _endDate];
                    break;
                case 2:
                    // last half year
                    _endDate = moment().format("YYYY-MM-DD");
                    _startDate = moment()
                        .subtract(6, "months")
                        .startOf("month")
                        .format("YYYY-MM-DD");
                    this.alertTimeRange = [_startDate, _endDate];
                    this.savedAlertTimeRange = [_startDate, _endDate];
                    break;
                case 3:
                    // last 1 year
                    _endDate = moment().format("YYYY-MM-DD");
                    _startDate = moment()
                        .subtract(12, "months")
                        .startOf("month")
                        .format("YYYY-MM-DD");
                    this.alertTimeRange = [_startDate, _endDate];
                    this.savedAlertTimeRange = [_startDate, _endDate];
                    break;
                default:
                    this.alertTimeRange = [];
                    this.savedAlertTimeRange = [];
            }
        },
        savedAlertTimeRange(val) {
            if (val.length == 1) {
                this.menuObject["alertTime_Gte"] = val[0] + "T00:00:00";
                this.menuObject["alertTime_Lte"] = val[0] + "T23:59:59";
            } else if (val.length == 2) {
                this.menuObject["alertTime_Gte"] = val[0] + "T00:00:00";
                this.menuObject["alertTime_Lte"] = val[1] + "T23:59:59";
            } else {
                this.menuObject["alertTime_Gte"] = null;
                this.menuObject["alertTime_Lte"] = null;
            }
        },
        relativePlannedDepartureDateRange(val) {
            const _index = this.relativePlannedDepartureDateRangeChoices.indexOf(val);
            let _startDate = null;
            let _endDate = null;
            switch (_index) {
                case 0:
                    // last month
                    _endDate = moment().format("YYYY-MM-DD");
                    _startDate = moment()
                        .date(0)
                        .startOf("month")
                        .format("YYYY-MM-DD");
                    this.plannedDepartureDateRange = [_startDate, _endDate];
                    this.savedPlannedDepartureDateRange = [_startDate, _endDate];
                    break;
                case 1:
                    // last quarter
                    _endDate = moment().format("YYYY-MM-DD");
                    _startDate = moment()
                        .subtract(3, "months")
                        .startOf("month")
                        .format("YYYY-MM-DD");
                    this.plannedDepartureDateRange = [_startDate, _endDate];
                    this.savedPlannedDepartureDateRange = [_startDate, _endDate];
                    break;
                case 2:
                    // last half year
                    _endDate = moment().format("YYYY-MM-DD");
                    _startDate = moment()
                        .subtract(6, "months")
                        .startOf("month")
                        .format("YYYY-MM-DD");
                    this.plannedDepartureDateRange = [_startDate, _endDate];
                    this.savedPlannedDepartureDateRange = [_startDate, _endDate];
                    break;
                case 3:
                    // last 1 year
                    _endDate = moment().format("YYYY-MM-DD");
                    _startDate = moment()
                        .subtract(12, "months")
                        .startOf("month")
                        .format("YYYY-MM-DD");
                    this.plannedDepartureDateRange = [_startDate, _endDate];
                    this.savedPlannedDepartureDateRange = [_startDate, _endDate];
                    break;
                default:
                    this.plannedDepartureDateRange = [];
                    this.savedPlannedDepartureDateRange = [];
            }
        },
        relativePlannedArrivalDateRange(val) {
            const _index = this.relativePlannedArrivalDateRangeChoices.indexOf(val);
            let _startDate = null;
            let _endDate = null;
            switch (_index) {
                case 0:
                    // last month
                    _endDate = moment().format("YYYY-MM-DD");
                    _startDate = moment()
                        .date(0)
                        .startOf("month")
                        .format("YYYY-MM-DD");
                    this.plannedArrivalDateRange = [_startDate, _endDate];
                    this.savedPlannedArrivalDateRange = [_startDate, _endDate];
                    break;
                case 1:
                    // last quarter
                    _endDate = moment().format("YYYY-MM-DD");
                    _startDate = moment()
                        .subtract(3, "months")
                        .startOf("month")
                        .format("YYYY-MM-DD");
                    this.plannedArrivalDateRange = [_startDate, _endDate];
                    this.savedPlannedArrivalDateRange = [_startDate, _endDate];
                    break;
                case 2:
                    // last half year
                    _endDate = moment().format("YYYY-MM-DD");
                    _startDate = moment()
                        .subtract(6, "months")
                        .startOf("month")
                        .format("YYYY-MM-DD");
                    this.plannedArrivalDateRange = [_startDate, _endDate];
                    this.savedPlannedArrivalDateRange = [_startDate, _endDate];
                    break;
                case 3:
                    // last 1 year
                    _endDate = moment().format("YYYY-MM-DD");
                    _startDate = moment()
                        .subtract(12, "months")
                        .startOf("month")
                        .format("YYYY-MM-DD");
                    this.plannedArrivalDateRange = [_startDate, _endDate];
                    this.savedPlannedArrivalDateRange = [_startDate, _endDate];
                    break;
                default:
                    this.plannedArrivalDateRange = [];
                    this.savedPlannedArrivalDateRange = [];
            }
        },
        savedPlannedDepartureDateRange(val) {
            if (val.length == 1) {
                this.menuObject["plannedDepartureDate_Gte"] = val[0] + "T00:00:00";
                this.menuObject["plannedDepartureDate_Lte"] = val[0] + "T23:59:59";
            } else if (val.length == 2) {
                this.menuObject["plannedDepartureDate_Gte"] = val[0] + "T00:00:00";
                this.menuObject["plannedDepartureDate_Lte"] = val[1] + "T23:59:59";
            } else {
                this.menuObject["plannedDepartureDate_Gte"] = null;
                this.menuObject["plannedDepartureDate_Lte"] = null;
            }
        },
        savedPlannedArrivalDateRange(val) {
            if (val.length == 1) {
                this.menuObject["plannedArrivalDate_Gte"] = val[0] + "T00:00:00";
                this.menuObject["plannedArrivalDate_Lte"] = val[0] + "T23:59:59";
            } else if (val.length == 2) {
                this.menuObject["plannedArrivalDate_Gte"] = val[0] + "T00:00:00";
                this.menuObject["plannedArrivalDate_Lte"] = val[1] + "T23:59:59";
            } else {
                this.menuObject["plannedArrivalDate_Gte"] = null;
                this.menuObject["plannedArrivalDate_Lte"] = null;
            }
        },
    },
    methods: {
        updateFilter(updateFrom, filter) {
            //trigger filter chips
            const triggerFilter = async val => {
                await this.filterValues(val);
            };

            //update query values
            this.$emit("filter-changed", JSON.parse(JSON.stringify(filter)));

            //update filter enabled
            this.$emit("filter-enabled", this.enabledFilters);

            //update store
            if (updateFrom !== "search") {
                triggerFilter(filter);
            }

            this.debounceUpdatePreferences();
        },
        onMenuStateChange(val, menuRef) {
            if (!val) {
                this.cancelFilter(this.menuReference);
                this.menuReference = null;
            } else {
                if (this.menuReference && this.menuReference !== menuRef) {
                    //Close the previous menu
                    this.cancelFilter(this.menuReference);
                }
                //Set the current menu
                this.menuReference = menuRef;

                this.$nextTick(() => {
                    if (this.$refs[this.menuReference] && this.$refs[this.menuReference][0]) {
                        this.$refs[this.menuReference][0].isActive = true;
                    } else if (this.$refs[this.menuReference]) {
                        this.$refs[this.menuReference].isActive = true;
                    }
                });
            }
        },
        cancelFilter(refMenu) {
            //clear menuObject
            this.codeSelected = null;

            //reset menuObject with previous value 
            this.menuObject = {...this.copyMenuObject};

            //clear menu filter
            if (this.$refs[refMenu]) {
                if (this.$refs[refMenu][0]) {
                    this.$refs[refMenu][0].isActive = false;
                } else {
                    this.$refs[refMenu].isActive = false;
                }
            }
        },
        async searchFields(menuItems, refMenu) {
            let menuKeys = Object.keys(menuItems);

            menuKeys.forEach(key => {
                if (menuItems[key] != undefined) {
                    this.filter[key] = menuItems[key];

                    // Update the value in enabledFilters when applying a filter
                    const filterIndex = this.enabledFilters.findIndex(f => f.code === key);
                    if (filterIndex === -1 && this.codeSelected) {
                        this.enabledFilters.push({
                            name: this.replaceName(key),
                            code: key,
                            enabled: true
                        });
                    }
                } else {
                    this.filter[key] = null;
                }
            });

            //update copy menuObject
            this.copyMenuObject = {...menuItems}

            if (this.filter.alertTime_Gte || this.filter.alertTime_Lte) {
                this.alertTimeRange = [
                    this.filter.alertTime_Gte.split("T")[0],
                    this.filter.alertTime_Lte.split("T")[0]
                ];

                // Ensure both date filters are in enabledFilters
                const gteIndex = this.enabledFilters.findIndex(f => f.code === "alertTime_Gte");
                const lteIndex = this.enabledFilters.findIndex(f => f.code === "alertTime_Lte");

                if (gteIndex === -1) {
                    this.enabledFilters.push({
                        name: this.replaceName("alertTime_Gte"),
                        code: "alertTime_Gte",
                        enabled: true
                    });
                }

                if (lteIndex === -1) {
                    this.enabledFilters.push({
                        name: this.replaceName("alertTime_Lte"),
                        code: "alertTime_Lte",
                        enabled: true
                    });
                }
            }
            if (this.filter.plannedDepartureDate_Gte || this.filter.plannedDepartureDate_Lte) {
                this.plannedDepartureDateRange = [
                    this.filter.plannedDepartureDate_Gte.split("T")[0],
                    this.filter.plannedDepartureDate_Lte.split("T")[0]
                ];

                // Ensure both date filters are in enabledFilters
                const gteIndex = this.enabledFilters.findIndex(f => f.code === "plannedDepartureDate_Gte");
                const lteIndex = this.enabledFilters.findIndex(f => f.code === "plannedDepartureDate_Lte");

                if (gteIndex === -1) {
                    this.enabledFilters.push({
                        name: this.replaceName("plannedDepartureDate_Gte"),
                        code: "plannedDepartureDate_Gte",
                        enabled: true
                    });
                }

                if (lteIndex === -1) {
                    this.enabledFilters.push({
                        name: this.replaceName("plannedDepartureDate_Lte"),
                        code: "plannedDepartureDate_Lte",
                        enabled: true
                    });
                }
            }

            if (this.filter.plannedArrivalDate_Gte || this.filter.plannedArrivalDate_Lte) {
                this.plannedArrivalDateRange = [
                    this.filter.plannedArrivalDate_Gte.split("T")[0],
                    this.filter.plannedArrivalDate_Lte.split("T")[0]
                ];

                // Ensure both date filters are in enabledFilters
                const gteIndex = this.enabledFilters.findIndex(f => f.code === "plannedArrivalDate_Gte");
                const lteIndex = this.enabledFilters.findIndex(f => f.code === "plannedArrivalDate_Lte");

                if (gteIndex === -1) {
                    this.enabledFilters.push({
                        name: this.replaceName("plannedArrivalDate_Gte"),
                        code: "plannedArrivalDate_Gte",
                        enabled: true
                    });
                }   

                if (lteIndex === -1) {
                    this.enabledFilters.push({
                        name: this.replaceName("plannedArrivalDate_Lte"),
                        code: "plannedArrivalDate_Lte",
                        enabled: true
                    });
                }
            }

            //trigger enabled filters
            this.triggerEnabledFilters();

            //update filter
            this.updateFilter("customUpdate", this.filter);

            //reset
            this.cancelFilter(refMenu);
        },
        triggerEnabledFilters() {
            if (!this.codeSelected) return;

            let codeIsFind = this.enabledFilters.find(e => e.code === this.getKey(this.codeSelected));

            if (!codeIsFind) {
                this.enabledFilters.push({
                    name: this.replaceName(this.codeSelected),
                    code: this.getKey(this.codeSelected),
                    enabled: true
                });
            }
        },
        addCode(code) {
            this.codeSelected = code;
        },
        async filterValues(object) {
            const filterKeysToIgnore = ["first", "after", "orderBy", "search"];
            const dateRangePairs = [
                {
                    start: "plannedDepartureDate_Gte",
                    end: "plannedDepartureDate_Lte",
                    getText: () => String(this.plannedDepartureDateRangeText)
                },
                {
                    start: "plannedArrivalDate_Gte",
                    end: "plannedArrivalDate_Lte",
                    getText: () => String(this.plannedArrivalDateRangeText)
                },
                {
                    start: "alertTime_Gte",
                    end: "alertTime_Lte",
                    getText: () => String(this.alertTimeRangeText)
                }
            ];
            // const keys = JSON.parse(JSON.stringify(Object.keys(object)));
            
            // Get enabled filter keys
            let filteredKeys = Object.keys(object).filter(key => {
                let findCode = this.enabledFilters.find(e => {
                    return e.code === key;
                });
                return findCode?.enabled && !filterKeysToIgnore.includes(key);
            });

            // Build initial filter values
            let values = await Promise.all(
                filteredKeys.map(async key => ({
                    id: key,
                    name: this.replaceName(key),
                    value: await this.valuePopulate(object[key], key)
                }))
            );

            // Process date range pairs
            dateRangePairs.forEach(({ start, end, getText }) => {
                const startFilter = values.find(e => e.id === start);
                const endFilter = values.find(e => e.id === end);
                if (startFilter || endFilter) {
                    if (startFilter?.value !== endFilter?.value) {
                        if (startFilter) startFilter.value = getText();
                    }
                    // Remove end date from values
                    values = values.filter(e => e.id !== end);
                }
            });

            this.filterValuesObj = [...values];
        },
        async deleteFilterValue(key) {
            if (key == "alertTime_Gte" || key == "alertTime_Lte") {
                this.menuObject = Object.keys(this.menuObject).reduce((acc, key) => {
                    if (key !== "alertTime_Gte" && key !== "alertTime_Lte") {
                        acc[key] = this.menuObject[key];
                    }
                    return acc;
                }, {});

                this.alertTimeRange = [];
                this.filter["alertTime_Gte"] = null;
                this.filter["alertTime_Lte"] = null;

                // Remove both date filters from enabledFilters
                this.enabledFilters = this.enabledFilters.filter(
                    e => e.code !== "alertTime_Gte" && e.code !== "alertTime_Lte"
                );
            } else if (key == "plannedDepartureDate_Gte" || key == "plannedDepartureDate_Lte") {
                this.menuObject = Object.keys(this.menuObject).reduce((acc, key) => {
                    if (key !== "plannedDepartureDate_Gte" && key !== "plannedDepartureDate_Lte") {
                        acc[key] = this.menuObject[key];
                    }
                    return acc;
                }, {});

                this.plannedDepartureDateRange = [];
                this.filter["plannedDepartureDate_Gte"] = null;
                this.filter["plannedDepartureDate_Lte"] = null;

                // Remove both date filters from enabledFilters
                this.enabledFilters = this.enabledFilters.filter(
                    e => e.code !== "plannedDepartureDate_Gte" && e.code !== "plannedDepartureDate_Lte"
                );
            } else if (key == "plannedArrivalDate_Gte" || key == "plannedArrivalDate_Lte") {
                this.menuObject = Object.keys(this.menuObject).reduce((acc, key) => {
                    if (key !== "plannedArrivalDate_Gte" && key !== "plannedArrivalDate_Lte") {
                        acc[key] = this.menuObject[key];
                    }
                    return acc;
                }, {});

                this.plannedArrivalDateRange = [];
                this.filter["plannedArrivalDate_Gte"] = null;
                this.filter["plannedArrivalDate_Lte"] = null;

                // Remove both date filters from enabledFilters
                this.enabledFilters = this.enabledFilters.filter(
                    e => e.code !== "plannedArrivalDate_Gte" && e.code !== "plannedArrivalDate_Lte"
                );
            }
            else {
                this.menuObject[key] = null;
                this.filter[key] = null;

                // Remove the filter from enabledFilters
                this.enabledFilters = this.enabledFilters.filter(e => e.code !== key);
            }

            this.updateFilter("delete", this.filter);
        },
        async getVesselName(vesselId) {
            try {
                const { data } = await this.$apollo.query({
                    query: gql`
                        query Vessel($vesselId: ID!) {
                            vessel(id: $vesselId) {
                                id
                                name
                            }
                        }
                    `,
                    variables: {
                        vesselId: vesselId
                    }
                });

                return data.vessel.name;
            } catch (error) {
                console.error("Error fetching vessel name:", error);
                // Handle the error appropriately (e.g., returning a default value or rethrowing the error)
                throw error;
            }
        },
        async getPartnerName(partnerIds) {
            let _partner = [];
            try {
                const { data } = await this.$apollo.query({
                    query: gql`
                        query tenantPartners($orderBy: String, $globalIdIn: [ID]) {
                            tenantPartners(orderBy: $orderBy, globalIdIn: $globalIdIn) {
                                edges {
                                    node {
                                        id
                                        name
                                    }
                                }
                            }
                        }
                    `,
                    fetchPolicy: "no-cache",
                    variables: {
                        globalIdIn: partnerIds
                    }
                });

                //TenantPartner returns an array of partners
                if (data.tenantPartners.edges.length > 0) {
                    data.tenantPartners.edges.forEach(e => {
                        _partner.push(e.node.name);
                    });
                }

                return _partner.join(", ");
            } catch (error) {
                return null;
            }
        },
        replaceName(key) {
            let replace = "";
            switch (key) {
                case "trip_ReferenceNumber_Icontains":
                    replace = this.$t("headers.trip_ref");
                    break;
                case "trip_Status_Icontains":
                    replace = this.$t("headers.status");
                    break;
                case "tenantAlertRule_Name_Icontains":
                    replace = this.$t("headers.alert_name");
                    break;
                case "tenantAlertRule_Tag_Icontains":
                    replace = this.$t("headers.alert_tag");
                    break;
                case "tripFreight_ReferenceNumber_Icontains":
                    replace = this.$t("headers.freight_number");
                    break;
                case "tripFreight_ContainerNumber_Icontains":
                    replace = this.$t("headers.container_number");
                    break;
                case "tripFreight_AirWaybillNumber_Icontains":
                    replace = this.$t("headers.airwaybill_number");
                    break;
                case "tripFreightTracker_Tracker_SerialNumber_Icontains":
                    replace = this.$t("headers.tracker");
                    break;
                case "trip_TenantCarrier":
                    replace = this.$t("headers.carrier");
                    break;
                case "trip_TripSeaTransportSet_Vessel":
                    replace = this.$t("headers.vessel");
                    break;
                case "alertTime_Gte":
                case "alertTime_Lte":
                    replace = this.$t("headers.trigger_time");
                    break;
                case "plannedDepartureDate_Gte":
                case "plannedDepartureDate_Lte":
                    replace = this.$t("headers.planned_departure");
                    break;
                case "plannedArrivalDate_Gte":
                case "plannedArrivalDate_Lte":
                    replace = this.$t("headers.planned_arrival");
                    break;
                case "pol":
                    replace = this.$t("headers.pol");
                    break;
                case "transshipment":
                    replace = this.$t("headers.ts");
                    break;
                case "pod":
                    replace = this.$t("headers.pod");
                    break;
                case "goods":
                    replace = this.$t("headers.goods");
                    break;
                case "trade_Icontains":
                    replace = this.$t("headers.trade");
                    break;
                case "tripFreightSet_Contact_Icontains":
                    replace = this.$t("headers.contact");
                    break;
                case "orderNumber":
                    replace = this.$t("headers.orderNumber");
                    break;
                case "alertCode":
                    replace = this.$t("headers.alertCode");
                    break;
                case "Search":
                    replace = this.$t("headers.search");
                    break;
            }
            return replace;
        },
        getKey(code) {
            let key = "";
            switch (code) {
                case "trip_referenceNumber":
                    key = "trip_ReferenceNumber_Icontains";
                    break;
                case "trip_status":
                    key = "trip_Status_Icontains";
                    break;
                case "tenantAlertRule_name":
                    key = "tenantAlertRule_Name_Icontains";
                    break;
                case "tenantAlertRule_tag":
                    key = "tenantAlertRule_Tag_Icontains";
                    break;
                case "tripFreight_referenceNumber":
                    key = "tripFreight_ReferenceNumber_Icontains";
                    break;
                case "tripFreight_containerNumber":
                    key = "tripFreight_ContainerNumber_Icontains";
                    break;
                case "tripFreight_airWaybillNumber":
                    key = "tripFreight_AirWaybillNumber_Icontains";
                    break;
                case "tripFreightTracker_serialNumber":
                    key = "tripFreightTracker_Tracker_SerialNumber_Icontains";
                    break;
                case "trip_tenantCarrier":
                    key = "trip_TenantCarrier";
                    break;
                case "trip_tripSeaTransportSet_vessel":
                    key = "trip_TripSeaTransportSet_Vessel";
                    break;
                case "alertTime":
                    key = "alertTime_Gte";
                    break;
                case "plannedDepartureDate":
                    key = "plannedDepartureDate_Gte";
                    break;
                case "plannedArrivalDate":
                    key = "plannedArrivalDate_Gte";
                    break;
                case "pol":
                    key = "pol";
                    break;
                case "transshipment":
                    key = "transshipment";
                    break;
                case "pod":
                    key = "pod";
                    break;
                case "goods":
                    key = "goods";
                    break;
                case "trade":
                    key = "trade_Icontains";
                    break;
                case "contact":
                    key = "tripFreightSet_Contact_Icontains";
                    break;
                case "orderNumber":
                    key = "orderNumber";
                    break;
                case "alertCode":
                    key = "alertCode";
                    break;
                case "Search":
                    key = "search";
                    break;
            }
            return key;
        },
        getCode(key) {
            let replace = "";
            switch (key) {
                case "trip_ReferenceNumber_Icontains":
                    replace = "trip_referenceNumber";
                    break;
                case "trip_Status_Icontains":
                    replace = "trip_status";
                    break;
                case "tenantAlertRule_Name_Icontains":
                    replace = "tenantAlertRule_name";
                    break;
                case "tenantAlertRule_Tag_Icontains":
                    replace = "tenantAlertRule_tag";
                    break;
                case "tripFreight_ReferenceNumber_Icontains":
                    replace = "tripFreight_referenceNumber";
                    break;
                case "tripFreight_ContainerNumber_Icontains":
                    replace = "tripFreight_containerNumber";
                    break;
                case "tripFreight_AirWaybillNumber_Icontains":
                    replace = "tripFreight_airWaybillNumber";
                    break;
                case "tripFreightTracker_Tracker_SerialNumber_Icontains":
                    replace = "tripFreightTracker_serialNumber";
                    break;
                case "trip_TenantCarrier":
                    replace = "trip_tenantCarrier";
                    break;
                case "trip_TripSeaTransportSet_Vessel":
                    replace = "trip_tripSeaTransportSet_vessel";
                    break;
                case "alertTime_Gte":
                case "alertTime_Lte":
                    replace = "alertTime";
                    break;
                case "plannedDepartureDate_Gte":
                case "plannedDepartureDate_Lte":
                    replace = "plannedDepartureDate";
                    break;
                case "plannedArrivalDate_Gte":
                case "plannedArrivalDate_Lte":
                    replace = "plannedArrivalDate";
                    break;
                case "pol":
                    replace = "pol";
                    break;
                case "transshipment":
                    replace = "transshipment";
                    break;
                case "pod":
                    replace = "pod";
                    break;
                case "goods":
                    replace = "goods";
                    break;
                case "trade_Icontains":
                    replace = "trade";
                    break;
                case "tripFreightSet_Contact_Icontains":
                    replace = "contact";
                    break;
                case "orderNumber":
                    replace = "orderNumber";
                    break;
                case "alertCode":
                    replace = "alertCode";
                    break;
            }
            return replace;
        },
        async valuePopulate(item, key) {
            let _value = null;
            switch (key) {
                case "trip_ReferenceNumber_Icontains":
                case "trip_Status_Icontains":
                case "tenantAlertRule_Name_Icontains":
                case "tenantAlertRule_Tag_Icontains":
                case "tripFreight_ReferenceNumber_Icontains":
                case "tripFreight_ContainerNumber_Icontains":
                case "tripFreight_AirWaybillNumber_Icontains":
                case "tripFreightTracker_Tracker_SerialNumber_Icontains":
                case "trade_Icontains":
                case "tripFreightSet_Contact_Icontains":
                case "orderNumber":
                case "goods":
                    _value = item ? item : this.$t("general.all");
                    break;
                case "trip_TenantCarrier":
                    _value = item ? await this.getPartnerName(item) : this.$t("general.all");
                    break;  
                case "trip_TripSeaTransportSet_Vessel":
                    _value = item ? await this.getVesselName(item) : this.$t("general.all");
                    break;
                case "alertTime_Gte":
                case "alertTime_Lte":
                case "plannedDepartureDate_Gte":
                case "plannedDepartureDate_Lte":
                case "plannedArrivalDate_Gte":
                case "plannedArrivalDate_Lte":
                    _value = item ? item.split("T")[0] : null;
                    break;
                case "pol":
                case "pod":
                case "transshipment":
                    _value = item ? item : this.$t("general.all");
                    break;
                case "alertCode":
                    if (item && Array.isArray(item) && item.length > 0) {
                        _value = item
                            .map(code => {
                                const alert = this.alertsType.find(el => el.value === code);
                                return alert ? alert.name : code;
                            })
                            .join(", ");
                    } else {
                        _value = this.$t("general.all");
                    }
                    break;
            }
            return _value;
        },
        async loadSavedFilters() {
            const preferences = store.state.user.me?.preferences;
            if (preferences && helper.isValidJson(preferences)) {
                const parsedPreferences = JSON.parse(preferences);
                if (parsedPreferences?.alertTablePreference?.[0]) {
                    // Load enabled filters (without values)
                    if (parsedPreferences.alertTablePreference[0].tableEnabledFilters) {
                        this.enabledFilters = parsedPreferences.alertTablePreference[0].tableEnabledFilters;
                    }

                    // Load filter values separately
                    if (parsedPreferences.alertTablePreference[0].tripTableFilters) {
                        const savedFilters = parsedPreferences.alertTablePreference[0].tripTableFilters;
                        this.filter = { ...this.filter, ...savedFilters };
                        this.menuObject = { ...this.menuObject, ...savedFilters };

                        // Restore date range if it exists
                        if (savedFilters.alertTime_Gte && savedFilters.alertTime_Lte) {
                            this.alertTimeRange = [
                                savedFilters.alertTime_Gte.split("T")[0],
                                savedFilters.alertTime_Lte.split("T")[0]
                            ];
                        }

                        //copy default values
                        this.copyMenuObject = {...this.menuObject}

                        // Update filter values in the UI
                        await this.filterValues(this.filter);
                        this.updateFilter("load", this.filter);
                    }
                }
            }
        },
        updatePreferences() {
            // Create a clean version of enabledFilters without values
            const cleanEnabledFilters = this.enabledFilters.map(filter => ({
                name: filter.name,
                code: filter.code,
                enabled: true
            }));

            // Create a separate object for filter values
            const filterValues = {};
            this.enabledFilters.forEach(filter => {
                if (this.filter[filter.code]) {
                    filterValues[filter.code] = this.filter[filter.code];
                }
            });

            const preferences = {
                alertTablePreference: [
                    {
                        tableEnabledFilters: cleanEnabledFilters,
                        tripTableFilters: filterValues
                    }
                ]
            };

            helper.updateMyPreferences(preferences);
        },
        openResolveOptions() {
            this.$emit("open-resolve-options");
        }
    }
};
</script>
