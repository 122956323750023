<template>
    <v-row dense class="pl-5 pr-3 background" align="stretch" style="height: 100%;">
        <v-col cols="12" xs="12" sm="12" md="6" lg="6" xl="6" class="pt-4 d-flex background">
            <v-card outlined class="pa-3 rounded-lg flex-grow-1 overflow-y-auto overflow-x-hidden" style="max-height: 450px;" >
                <v-card flat >
                    <v-card-title class="textTitle pl-0">
                        <div class="mr-2">
                            <v-icon>settings_remote</v-icon>
                        </div>
                        <div>
                            {{ $t("headers.information") }}
                        </div>
                        <v-spacer></v-spacer>
                    </v-card-title>
                    <LoadingBar v-if="loading"></LoadingBar>
                    <!-- No data  -->
                    <div v-else class="px-2">
                        
                            <!-- Header content -->
                            <v-row class="pb-4 mt-2">
                                <v-col cols="12" xs="12" sm="6" md="4" lg="4" xl="4">
                                    <div class="textHeader">
                                        {{ $t("headers.package_number") }}
                                    </div>
                                    <div class="textBody">
                                        {{ tracker.packageNumber }}
                                    </div>
                                </v-col>
                                <v-col cols="12" xs="12" sm="6" md="4" lg="4" xl="4">
                                    <div class="textHeader">
                                        {{ $t("headers.main_tracker") }}
                                    </div>
                                    <div class="textBody">
                                        {{ tracker.mainTracker }}
                                    </div>
                                </v-col>
                                <v-col cols="12" xs="12" sm="6" md="4" lg="4" xl="4">
                                    <div class="textHeader">
                                        {{ $t("headers.location_stock") }}
                                    </div>
                                    <div class="textBody">
                                        {{ tracker.stockLocation ? tracker.stockLocation.name : "" }}
                                    </div>
                                </v-col>
                                <v-col cols="12" xs="12" sm="6" md="4" lg="4" xl="4">
                                    <div class="textHeader">
                                        {{ $t("headers.activation") }}
                                    </div>
                                    <div class="textBody">
                                        {{ formatDateTime(tracker.activatedDate) }}
                                    </div>
                                </v-col>
                                <v-col cols="12" xs="12" sm="6" md="4" lg="4" xl="4">
                                    <div class="textHeader">
                                        {{ $t("headers.last_date") }}
                                    </div>
                                    <div class="textBody">
                                        {{ formatDateTime(tracker.lastConnectionTime) }}
                                    </div>
                                </v-col>
                                <v-col cols="12" xs="12" sm="6" md="4" lg="4" xl="4">
                                    <div class="textHeader">
                                        {{ $t("headers.expiration") }}
                                    </div>
                                    <div class="textBody">
                                        {{ formatDateTime(tracker.expiryDate) }}
                                    </div>
                                </v-col>
                                <v-col cols="12" xs="12" sm="6" md="4" lg="4" xl="4">
                                    <div class="textHeader">
                                        {{ $t("headers.trip_number") }}
                                    </div>
                                    <div class="textBody">
                                        <span class="text-capitalize">
                                            {{ tracker.tripReferenceNumber }}
                                        </span>
                                    </div>
                                </v-col>
                            </v-row>

                            <!-- Sensor content -->
                            <v-card class="" flat>
                                <v-card-text class="pa-0">
                                    <v-row dense class="rounded-lg">
                                        <v-col
                                            v-for="sensor in trackerSensors(tracker)"
                                            :key="sensor.id"
                                            cols="12"
                                            xs="12"
                                            :sm="transitionScreen.smToXs ? 12 : 6"
                                            :md="transitionScreen.mdToSm ? 12 : 6"
                                            :lg="transitionScreen.lgToMd ? 6 : 4"
                                            xl="4"
                                        >
                                            <v-card outlined height="70" width="100%" class="px-2 pt-2">
                                                <v-row dense>
                                                    <v-col cols="12">
                                                        <v-row>
                                                            <v-col cols="auto" class="pr-0">
                                                                <div class="textBody mb-1">
                                                                    {{ sensor.value }}
                                                                </div>
                                                                <div class="text-no-wrap textHeader mb-1">
                                                                    {{ $t(sensor.text) }}
                                                                </div>
                                                            </v-col>
                                                            <v-spacer></v-spacer>
                                                            <v-col cols="auto">
                                                                <v-btn
                                                                    :style="`background-color: ${sensor.color}`"
                                                                    class="pa-1"
                                                                    elevation="0"
                                                                    icon
                                                                    small
                                                                >
                                                                    <v-img
                                                                        :style="`background-color:transparent; filter: invert(100%);`"
                                                                        :src="sensor.icon"
                                                                        width="18"
                                                                    ></v-img>
                                                                </v-btn>
                                                            </v-col>
                                                        </v-row>
                                                        <div class="textDetails text-no-wrap">
                                                            {{ sensor.formatDateTime }}
                                                        </div>
                                                    </v-col>
                                                    
                                                </v-row>
                                            </v-card>
                                        </v-col>
                                    </v-row>
                                </v-card-text>
                            </v-card>
                    </div>
                </v-card>
                <!-- <TrackerCharts :tracker="tracker"> </TrackerCharts> -->
            </v-card>
        </v-col>
        <v-col cols="12" xs="12" sm="12" md="6" lg="6" xl="6" class="pt-4 d-flex background">
            <v-card 
                flat 
                outlined 
                class="rounded-lg flex-grow-1 fill-height" 
                style="max-height: 450px; overflow: hidden;"
            >
                <TrackerMap 
                    :tracker="tracker" 
                    viewType="list" 
                    v-on:fullscreenChange="fullscreenChange"
                    class="fill-height"
                >
                </TrackerMap>
            </v-card>

        </v-col>
        <!-- <v-col cols="12" class="pt-4">
            <v-card outlined class="">
                <v-card-title class="textTitle">
                    <div class="mr-2">
                        <v-icon>history</v-icon>
                    </div>
                    <div>
                        {{ $t("headers.charts") }}
                    </div>
                    <v-spacer></v-spacer>
                </v-card-title>
                <TrackerCharts :tracker="tracker"> </TrackerCharts>
            </v-card>
        </v-col> -->
    </v-row>
</template>

<script>
import LoadingBar from "@/components/base/LoadingBar.vue";
import TrackerMap from "@/components/trackers/details/TrackerMap.vue";
import helper from "@/utils/helper.js";

export default {
    name: "TrackerDetailsPreview",
    props: {
        tracker: {
            type: Object,
            required: true
        },
        loading: {
            type: Boolean,
            required: false,
            default: false
        }
    },
    components: {
        LoadingBar,
        TrackerMap
    },
    data() {
        return {
            panel: [0],
            disabledPanel: false
        };
    },
    computed: {
        transitionScreen() {
            let _trasition = {
                smToXs: this.$vuetify.breakpoint.width < 640,
                mdToSm: this.$vuetify.breakpoint.width < 990,
                lgToMd: this.$vuetify.breakpoint.width < 1365
            };
            return _trasition;
        }
    },
    beforeCreate() {
        this.hasPermission = helper.hasPermission;
        this.hasOrganisationPermission = helper.hasOrganisationPermission;
    },
    created() {
        this.formatDateTime = helper.formatDateTime;
        this.formatTemperature = helper.formatTemperature;
        this.getTemperatureSymbol = helper.getTemperatureSymbol;
        this.unitPerBrand = helper.unitPerBrand;
    },
    methods: {
        trackerSensors(tracker) {
            let sensors = [];
            if (tracker) {
                let formatValue = Math.floor(tracker.lastBatteryValue ?? 0) + "%";
                let formatDate = this.formatDateTime(tracker.lastBatteryDeviceTime);

                if (tracker?.brand?.toLowerCase() !== "sensitech") {
                    sensors.push({
                        id: "4",
                        text: "general.battery",
                        formatDateTime: formatDate,
                        value: formatValue,
                        icon: require("@/assets/battery_charging_full.png"),
                        color: "#1DB32544"
                    });
                }

                if (tracker.lastTemperatureValue) {
                    let symbol = this.getTemperatureSymbol();
                    let formatValue = Math.floor(tracker.lastTemperatureValue) + symbol;
                    let formatDate = this.formatDateTime(tracker.lastTemperatureDeviceTime);
                    sensors.push({
                        id: "1",
                        text: "general.temperature",
                        value: formatValue,
                        formatDateTime: formatDate,
                        icon: require("@/assets/device_thermostat.png"),
                        color: "#377EF2"
                    });
                } else {
                    sensors.push({
                        id: "1",
                        text: "general.temperature",
                        value: 0,
                        formatDateTime: null,
                        icon: require("@/assets/device_thermostat.png"),
                        color: "#377EF2"
                    });
                }
                if (tracker.lastExternalTemperatureValue) {
                    let symbol = this.getTemperatureSymbol();
                    let formatValue = Math.floor(tracker.lastExternalTemperatureValue) + symbol;
                    let formatDate = this.formatDateTime(tracker.lastExternalTemperatureDeviceTime);
                    sensors.push({
                        id: "5",
                        text: "general.external_temperature",
                        value: formatValue,
                        formatDateTime: formatDate,
                        icon: require("@/assets/device_thermostat.png"),
                        color: "#377EF2"
                    });
                }
                if (tracker.lastProbeTemperatureValue) {
                    let symbol = this.getTemperatureSymbol();
                    let formatValue = Math.floor(tracker.lastProbeTemperatureValue) + symbol;
                    let formatDate = this.formatDateTime(tracker.lastProbeTemperatureDeviceTime);
                    sensors.push({
                        id: "6",
                        text: "general.probe_temperature",
                        value: formatValue,
                        formatDateTime: formatDate,
                        icon: require("@/assets/device_thermostat.png"),
                        color: "#377EF2"
                    });
                }
                if (tracker.lastHumidityValue) {
                    let formatValue = Math.floor(tracker.lastHumidityValue) + "%";
                    let formatDate = this.formatDateTime(tracker.lastHumidityDeviceTime);
                    sensors.push({
                        id: "3",
                        text: "general.humidity",
                        value: formatValue,
                        formatDateTime: formatDate,
                        icon: require("@/assets/water_drop.png"),
                        color: "#FF9D84"
                    });
                }

                if (tracker.lastLightValue) {
                    let formatValue = tracker.lastLightValue.toFixed(2) + "Lux";
                    let formatDate = this.formatDateTime(tracker.lastLightDeviceTime);
                    sensors.push({
                        id: "2",
                        text: "general.light",
                        value: formatValue,
                        formatDateTime: formatDate,
                        icon: require("@/assets/lightbulb.png"),
                        color: "#E3DE538F"
                    });
                }
                if (tracker.lastShockValue) {
                    let formatValue = Math.floor(tracker.lastShockValue) + "G";
                    let formatDate = this.formatDateTime(tracker.lastShockDeviceTime);
                    sensors.push({
                        id: "7",
                        text: "general.shock",
                        value: formatValue,
                        formatDateTime: formatDate,
                        icon: require("@/assets/bolt.png"),
                        color: "#B38EFF90"
                    });
                }
                //sort by id
                if (sensors.length > 0) {
                    sensors.sort((a, b) => (a.id > b.id ? 1 : -1));
                }

                return sensors;
            }
        },
        fullscreenChange(val) {
            this.$emit("updateFullscreen", val);
        }
    },
    mounted() {
        // Code to run when the component is mounted
    }
};
</script>

<style scoped>
.v-expansion-panel-content__wrap {
    padding: 0% !important;
}
.v-expansion-panel:not(:first-child)::after {
    border-top: none !important;
}
.txtIndex {
    max-height: 19px;
    font-size: 12.4px;
    font-weight: 720;
    color: #a4a4a8;
}
.txtBody {
    max-height: 19px;
    font-size: 12.3px;
    font-weight: 650;
}
.maxHeight {
    max-height: 18px;
}
.outlineD {
    border: 1px solid #e6e9f5;
    border-radius: 4px;
    color: #e6e9f5;
}
.contentBg {
    background-color: #f9fafe;
}
.cardContainer {
    /* height: 250px; */
    overflow-y: auto;
    margin-bottom: 12px;
}
.cardContainer::-webkit-scrollbar {
    width: 6px;
    border-radius: 5px;
}
.cardContainer::-webkit-scrollbar-track {
    box-shadow: inset 0 0 5px #cfd0d5ed;
    border-radius: 5px;
}
.cardContainer::-webkit-scrollbar-thumb {
    background-color: var(--scrollbar-color);
    border-radius: 5px;
}
.cardContainer::-webkit-scrollbar-thumb:hover {
    background-color: var(--scrollbar-color);
}
.textTitle {
    font-size: 20px;
    font-weight: 500;
    line-height: 22px;
    letter-spacing: 0em;
    text-align: left;
    color: #0e0d35;
}
.textTitle2 {
    font-size: 20px;
    font-weight: 500;
    line-height: 22px;
    letter-spacing: 0em;
    text-align: left;
    color: #ffffff;
}
.textHeader {
    font-size: 12px;
    font-weight: 400;
    line-height: 15px;
    letter-spacing: 0em;
    text-align: left;
    color: #555464;
    margin-bottom: 1px;
}
.textBody {
    font-size: 12px;
    font-weight: 700;
    line-height: 15px;
    letter-spacing: 0em;
    text-align: left;
    color: #0e0d35;
}
.textDetails {
    font-size: 10px;
    font-weight: 400;
    line-height: 10px;
    letter-spacing: 0em;
    text-align: left;
    color: #0e0d35;
}
.dialogCardContainer {
    max-width: 100%;
    max-height: 500px;
    overflow-y: auto;
}
</style>
