<template>
    <v-card-text class="pb-1 pt-2">
        <v-row dense align="center">
            
            <v-col cols="12">
                <v-row dense class="d-flex align-center justify-space-around" no-gutters>
                    <v-spacer />
                    <!-- Temperature -->
                    <v-col class="mr-3" cols="auto" v-if="item.node.lastTemperatureValue">
                        <v-tooltip bottom>
                            <template v-slot:activator="{ on, attrs }">
                                <v-badge
                                    color="#377EF2"
                                    offset-x="6" 
                                    offset-y="8"
                                    right
                                    class="mr-3"
                                >
                                    <template v-slot:badge>
                                        <div class="badge-content">
                                            {{ Math.floor(formatTemperature(item.node.lastTemperatureValue)) }}{{ getTemperatureSymbol() }}
                                        </div>
                                    </template>
                                    <v-icon
                                        v-bind="attrs"
                                        v-on="on"
                                        small
                                        :style="`opacity: 0.5;`"
                                        
                                    >
                                        thermostat
                                    </v-icon>
                                    <!-- <v-img
                                        v-bind="attrs"
                                        v-on="on"
                                        :src="require('@/assets/device_thermostat.png')"
                                        width="18"
                                        :style="`opacity: 0.5; filter: grayscale(100%);`"
                                        class="mr-3"
                                    ></v-img> -->
                                </v-badge>
                            </template>
                            <span>{{ $t("general.probe_temperature") }}</span>
                        </v-tooltip>
                    </v-col>

                    <!-- Light -->
                    <v-col class="mr-3" cols="auto" v-if="item.node.lastLightValue">
                        <v-tooltip bottom>
                            <template v-slot:activator="{ on, attrs }">
                                <v-badge
                                    color="#e3de53"
                                    offset-x="6" 
                                    offset-y="8"
                                    right
                                    class="mr-3"
                                >
                                    <template v-slot:badge>
                                        <div class="badge-content">
                                            {{ item.node.lastLightValue.toFixed(0) }}lx  
                                        </div>
                                    </template>
                                    <v-icon
                                        v-bind="attrs"
                                        v-on="on"
                                        small
                                        :style="`opacity: 0.5;`"
                                        
                                    >
                                        lightbulb
                                    </v-icon>
                                    <!-- <v-img
                                        v-bind="attrs"
                                        v-on="on"
                                        :src="require('@/assets/lightbulb.png')"
                                        width="18"
                                        :style="`opacity: 0.5; filter: grayscale(100%);`"
                                    ></v-img> -->
                                </v-badge>
                            </template>
                            <span>{{ $t("general.light") }}</span>
                        </v-tooltip>
                    </v-col>

                                        <!-- Humidity -->
                                        <v-col class="mr-3" cols="auto" v-if="item.node.lastHumidityValue">
                        <v-tooltip bottom>
                            <template v-slot:activator="{ on, attrs }">
                                <v-badge
                                    color="#ff9d84"
                                    offset-x="6" 
                                    offset-y="8"
                                    right
                                    class="mr-3"
                                >
                                    <template v-slot:badge>
                                        <div class="badge-content">
                                            {{ Math.floor(item.node.lastHumidityValue) }}%
                                        </div>
                                    </template>
                                    <v-icon
                                        v-bind="attrs"
                                        v-on="on"
                                        small
                                        :style="`opacity: 0.5;`"
                                        
                                    >
                                        water_drop
                                    </v-icon>
                                    <!-- <v-img
                                        v-bind="attrs"
                                        v-on="on"
                                        :src="require('@/assets/water_drop.png')"
                                        width="18"
                                        :style="`opacity: 0.5; filter: grayscale(100%);`"
                                    ></v-img> -->
                                </v-badge>
                            </template>
                            <span>{{ $t("general.humidity") }}</span>
                        </v-tooltip>
                    </v-col>

                    <!-- Battery -->
                    <v-col class="mr-3" cols="auto" v-if="item.node.brand.toLowerCase() !== 'sensitech'">
                        <v-tooltip bottom>
                            <template v-slot:activator="{ on, attrs }">
                                <v-badge
                                    :color="getBatteryLevel(item.node).color"
                                    offset-x="6" 
                                    offset-y="8"
                                    right
                                    class="mr-3"
                                >
                                    <template v-slot:badge>
                                        <div class="badge-content">
                                            {{ item.node.lastBatteryValue ? `${Math.floor(item.node.lastBatteryValue)}%` : '0%' }}
                                        </div>
                                    </template>
                                    <v-icon
                                        v-bind="attrs"
                                        v-on="on"
                                        small
                                        :style="`opacity: 0.5;`"
                                        
                                    >
                                        {{ getBatteryLevel(item.node).level }}
                                    </v-icon>
                                </v-badge>
                            </template>
                            <span>{{ $t("general.battery") }}</span>
                        </v-tooltip>
                    </v-col>

                    <!-- Shock -->
                    <v-col class="mr-3" cols="auto" v-if="item.node.lastShockValue">
                        <v-tooltip bottom>
                            <template v-slot:activator="{ on, attrs }">
                                <v-badge
                                    color="#B38EFF"
                                    offset-x="6" 
                                    offset-y="8"
                                    right
                                    class="mr-3"
                                >
                                    <template v-slot:badge>
                                        <div class="badge-content">
                                            {{ Math.floor(item.node.lastShockValue) }}G
                                        </div>
                                    </template>
                                    <v-icon
                                        v-bind="attrs"
                                        v-on="on"
                                        small
                                        :style="`opacity: 0.5;`"
                                        
                                    >
                                        bolt
                                    </v-icon>
                                    <!-- <v-img
                                        v-bind="attrs"
                                        v-on="on"
                                        :src="require('@/assets/bolt.png')"
                                        width="18"
                                        :style="`opacity: 0.5; filter: grayscale(100%);`"
                                    ></v-img> -->
                                </v-badge>
                            </template>
                            <span>{{ $t("general.shock") }}</span>
                        </v-tooltip>
                    </v-col>

                    <v-col class="mr-3" cols="auto" v-if="item.node.lastConnectionTime">
                        <v-tooltip bottom>
                            <template v-slot:activator="{ on, attrs }">
                                <v-badge
                                    color="grey"
                                    offset-x="6"
                                    offset-y="8"
                                    right
                                    class="mr-3"
                                >
                                    <template v-slot:badge>
                                        <div class="badge-content">
                                            {{ relativeTimeShort(item.node.lastConnectionTime) }}
                                        </div>
                                    </template>
                                    <v-icon
                                        v-bind="attrs"
                                        v-on="on"
                                        small
                                        :style="`opacity: 0.7;`"
                                    >
                                        schedule
                                    </v-icon>
                                </v-badge>
                            </template>
                            <span>{{ $t("trips.last_connection") }}</span>
                        </v-tooltip>
                    </v-col>

                    <!-- Menu -->
                    <v-col class="d-flex align-center justify-center ml-3" cols="auto" v-if="!isStaff && canEdit">
                        <div>
                            <TrackerChangeLocation
                                :isMenuItem="true"
                                :onlyIcon="true"
                                :selectedTrackers="
                                    selectedTrackersToMove
                                "
                                v-on:closeSelector="closeSelector"
                                v-on:updateTrackersTable="
                                    $emit('refresh')
                                "
                                v-on:menuItemClicked="
                                    assignToStockLocation(item)
                                "
                            >
                            </TrackerChangeLocation>
                        </div>
                    </v-col>
                </v-row>
            </v-col>
        </v-row>
    </v-card-text>
</template>



<script>
import helper from "@/utils/helper";
import { TrackerPermissions } from "@/core/enum";
import TrackerChangeLocation from "@/components/trackers/TrackerChangeLocation.vue";

export default {
    name: "TrackerFooterTemplate",
    components: {
        TrackerChangeLocation
    },
    props: {
        item: {
            type: Object,
            required: true
        },
        selectedTrackersToMove: {
            type: Array,
            required: false,
            default: () => []
        },
        openSelector: {
            type: Boolean,
            required: false,
            default: false
        }
    },
    data() {
        return {
                
        }
    },
    created() {
        this.formatDateTime = helper.formatDateTime;
        this.formatTemperature = helper.formatTemperature;
        this.hasPermission = helper.hasPermission;
        this.relativeTime = helper.relativeTime;
    },
    computed: {
        me() {
            return helper.me();
        },
        isStaff() {
            return this.me.isStaff || false;
        },
        canEdit() {
            if (this.me?.isSuperuser) {
                return true;
            }
            return this.hasPermission(TrackerPermissions.EDIT_TRACKER);
        },
    },
    methods: {
        trackerStatus(activatedDate) {
            let result = {
                text: this.$t("trackers.not_activated"),
                color1: "#E0E0E0",
                color2: "grey"
            };
            // activatedDate ? $t("trackers.activated") : $t("trackers.not_activated")
            if (activatedDate !== null) {
                result = {
                    text: this.$t("trackers.activated"),
                    color1: "#C8E6C9",
                    color2: "#4CAF50"
                };
            }
            return result;
        },
        getBatteryLevel(tracker) {
            if (tracker?.brand?.toLowerCase() === "sensitech") {
                return { level: "battery_unknown", color: "grey" };
            }

            let lastBatteryValue = tracker?.lastBatteryValue;

            if (tracker?.activatedDate !== null && tracker?.activatedDate !== undefined) {
                if (lastBatteryValue > 0 && lastBatteryValue < 20) {
                    return { level: "battery_2_bar", color: "red" };
                } else if (lastBatteryValue >= 20 && lastBatteryValue <= 60) {
                    return { level: "battery_4_bar", color: "orange" };
                } else if (lastBatteryValue > 60) {
                    return { level: "battery_6_bar", color: "green" };
                } else {
                    return { level: "battery_2_bar", color: "red" };
                }
            } else if (!lastBatteryValue) {
                return { level: "battery_unknown", color: "grey" };
            }
        },
        getTrackerValue(tracker) {
            return tracker?.brand?.toLowerCase() === "sensitech" ? "?" : tracker.lastBatteryValue || 0;
        },
        vTooltip(tracker) {
            const checkSensitechValues = tracker => {
                let isSensitech = tracker?.brand?.toLowerCase() === "sensitech";
                let isDecimal = tracker?.lastBatteryValue % 1 !== 0;
                let isBtwZeroAndOne = tracker?.lastBatteryValue > 0 && tracker?.lastBatteryValue < 1;
                return isSensitech && isDecimal && isBtwZeroAndOne;
            };

            let isInValid = checkSensitechValues(tracker);

            if (tracker?.activatedDate !== null && tracker?.activatedDate !== undefined) {
                if (tracker.lastBatteryValue === null || tracker.lastBatteryValue === undefined || isInValid) {
                    return this.$t("headers.unknown_baterry");
                } else {
                    return this.$t("headers.battery");
                }
            } else {
                return this.$t("headers.battery");
            }
        },
        assignToStockLocation(item) {
            this.$emit('assignToStockLocation', item);
        },
        closeSelector(val) {
            this.$emit('closeSelector', val);
        },
        getTemperatureSymbol() {
            let _preferences = this.$store.state.user.me?.preferences;
            if (_preferences != null) {
                let preferences = JSON.parse(_preferences);
                try {
                    return preferences.temperature.symbol;
                } catch {
                    return "°C";
                }
            }
        },
        relativeTimeShort(value) {
            if (!value) return "";
            let relativeTime = this.relativeTime(value);
            let splitTime = relativeTime.split(" ");

            if (splitTime[0] === "a") {
                splitTime[0] = "1";
            }

            switch (splitTime[1]) {
                case this.$t("date.minutes"):
                case this.$t("date.minute"):
                    //replace the index of the array
                    splitTime[0] = splitTime[0] + "m";
                    splitTime[1] = "";
                    break;
                case this.$t("date.hours"):
                case this.$t("date.hour"):
                    splitTime[0] = splitTime[0] + "h";
                    splitTime[1] = "";
                    break;
                case this.$t("date.days"):
                case this.$t("date.day"):
                    splitTime[0] = splitTime[0] + "d";
                    splitTime[1] = "";
                    break;
                case this.$t("date.weeks"):
                case this.$t("date.week"):
                    splitTime[0] = splitTime[0] + "w";
                    splitTime[1] = "";
                    break;
                case this.$t("date.months"):
                case this.$t("date.month"):
                    splitTime[0] = splitTime[0] + "mo";
                    splitTime[1] = "";
                    break;
                case this.$t("date.years"):
                case this.$t("date.year"):
                    splitTime[0] = splitTime[0] + "y";
                    splitTime[1] = "";
                    break;
            }

            return splitTime.join(" ");
        },
    }
}
</script>

<style scoped>
.badge-content {
    font-size: 10px;
    text-align: center;
    white-space: nowrap;
}

.mx-4 {
    margin-left: 24px !important;
    margin-right: 24px !important;
}

.v-badge {
    display: flex;
    justify-content: center;
    align-items: center;
}

.v-badge__badge {
    display: flex;
    justify-content: center;
    align-items: center;
}
</style>