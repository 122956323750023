import { VAlert } from 'vuetify/lib/components/VAlert';
import { VAutocomplete } from 'vuetify/lib/components/VAutocomplete';
import { VBtn } from 'vuetify/lib/components/VBtn';
import { VCard } from 'vuetify/lib/components/VCard';
import { VCardActions } from 'vuetify/lib/components/VCard';
import { VCardText } from 'vuetify/lib/components/VCard';
import { VCardTitle } from 'vuetify/lib/components/VCard';
import { VCol } from 'vuetify/lib/components/VGrid';
import { VContainer } from 'vuetify/lib/components/VGrid';
import { VDialog } from 'vuetify/lib/components/VDialog';
import { VIcon } from 'vuetify/lib/components/VIcon';
import { VRow } from 'vuetify/lib/components/VGrid';
import { VTooltip } from 'vuetify/lib/components/VTooltip';

var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c(VDialog,{attrs:{"max-width":"500","persistent":""},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
var on = ref.on;
var attrs = ref.attrs;
return [(!_vm.isMenuItem && !_vm.isPreview)?_c(VBtn,_vm._g(_vm._b({staticClass:"mr-2 mt-1",attrs:{"rounded":_vm.rounded,"color":"primary","width":"90"}},'v-btn',attrs,false),on),[_vm._v(" "+_vm._s(_vm.$t("trackers.move"))+" ")]):_vm._e(),(!_vm.isMenuItem && _vm.isPreview)?_c(VBtn,_vm._g(_vm._b({staticClass:"mr-2",attrs:{"rounded":_vm.rounded,"color":"primary","icon":""}},'v-btn',attrs,false),on),[_c(VIcon,[_vm._v(" check ")])],1):_vm._e(),(_vm.isMenuItem && !_vm.onlyIcon)?_c(VBtn,_vm._g(_vm._b({attrs:{"plain":""}},'v-btn',attrs,false),on),[_c(VIcon,{attrs:{"left":""}},[_vm._v(" add_location ")]),[_vm._v(" "+_vm._s(_vm.$t("trackers.assign_to"))+" "+_vm._s(_vm.$t("trackers.stock_location"))+" ")]],2):_vm._e(),(_vm.isMenuItem && _vm.onlyIcon)?_c(VTooltip,{attrs:{"bottom":""},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
var tooltip = ref.on;
return [_c(VBtn,_vm._g(_vm._b({staticClass:"pb-2",attrs:{"icon":"","color":"primary"},on:{"click":function($event){$event.stopPropagation();}}},'v-btn',attrs,false),Object.assign({}, tooltip, on)),[_c(VIcon,[_vm._v(" add_location ")])],1)]}}],null,true)},[_c('span',[_vm._v(_vm._s(_vm.$t("trackers.assign_to"))+" "+_vm._s(_vm.$t("trackers.stock_location")))])]):_vm._e()]}}]),model:{value:(_vm.trackerChangeLocationDialog),callback:function ($$v) {_vm.trackerChangeLocationDialog=$$v},expression:"trackerChangeLocationDialog"}},[_c(VCard,[_c(VCardTitle,{staticClass:"primary white--text"},[_c(VIcon,{staticClass:"mr-2",attrs:{"color":"white"}},[_vm._v("autorenew")]),_vm._v(_vm._s(_vm.$t("general.change"))+" "+_vm._s(_vm.$t("trackers.tracker_location"))+" ")],1),(_vm.noTrackersError)?_c(VAlert,{staticStyle:{"margin-bottom":"0%","border-radius":"0% 0% 4% 4%"},attrs:{"dense":"","type":"error","elevation":"2"}},[_vm._v(" "+_vm._s(_vm.$t("trackers.no_tracker"))+" ")]):_vm._e(),_c(VCardText,{staticStyle:{"padding-top":"20px"}},[_c(VContainer,{attrs:{"fluid":""}},[_c('h3',{staticStyle:{"margin-bottom":"1%","margin-top":"1%"}},[_vm._v(" "+_vm._s(_vm.$t("trackers.stock_location"))+": ")]),_c(VRow,{attrs:{"align":"center"}},[_c(VCol,{staticClass:"d-flex",attrs:{"cols":"12","sm":"12"}},[_c(VAutocomplete,{attrs:{"clearable":"","hide-selected":"","hide-details":"","auto-select-first":"","dense":"","outlined":"","items":_vm.tenantLocations.edges,"no-data-text":_vm.$t('loading.no_available'),"item-text":"node.name","item-value":"node.id"},model:{value:(_vm.pendingLocation),callback:function ($$v) {_vm.pendingLocation=$$v},expression:"pendingLocation"}})],1)],1)],1)],1),_c(VCardActions,{staticClass:"d-flex justify-end pb-5 pr-5"},[_c(VBtn,{attrs:{"text":"","rounded":""},on:{"click":function($event){_vm.trackerChangeLocationDialog = false;
                    _vm.noTrackersError = false;}}},[_vm._v(_vm._s(_vm.$t("general.close")))]),_c(VBtn,{attrs:{"color":"primary","rounded":"","loading":_vm.isLoadingChangeTracker},on:{"click":_vm.changeTrackersStockLocation}},[_vm._v(" "+_vm._s(_vm.$t("general.apply_changes"))+" ")])],1)],1)],1)}
var staticRenderFns = []

export { render, staticRenderFns }