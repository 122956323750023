<template>
    <v-row dense class="pl-5 pr-3 background d-flex" align="stretch" style="height: 100%;">
        <v-col cols="12" class="pt-4 d-flex">
            <v-card outlined class="flex-grow-1 overflow-y-auto">
                <v-card-title class="textTitle">
                    <div class="mr-2">
                        <v-icon>bar_chart</v-icon>
                    </div>
                    <div>
                        {{ $t("headers.charts") }}
                    </div>
                    <v-spacer></v-spacer>
                </v-card-title>
                <TrackerCharts :tracker="tracker" />
            </v-card>
        </v-col>
    </v-row>
</template>

<script>
import TrackerCharts from "@/components/trackers/details/TrackerCharts.vue";

export default {
    name: "TrackerChartsPreview",
    components: {
        TrackerCharts
    },
    props: {
        tracker: {
            type: Object,
            required: true
        }
    }
}
</script>