<template>
    <v-container fluid>
        <v-row class="d-flex align-center justify-center px-10" dense>
            <!-- Title -->
            <v-col xl="11" lg="12" md="12" sm="12" xs="12" cols="12">
                <v-row>
                    <v-col cols="12" xs="12" sm="6" md="6" lg="6" xl="6">
                        <div>
                            <h2 class="modelTitle">
                                {{ !isSharedTrip ? $t("headers.trips") : $t("home.shared_trips") }}
                            </h2>
                        </div>
                        <span v-if="isSharedTrip" class="d-flex align-center">
                            ({{ $t("permissions.shared_trip") }})
                        </span>
                    </v-col>
                </v-row>
            </v-col>
            <!-- Scroll to the button-->
            <v-col xl="11" lg="12" md="12" sm="12" xs="12" cols="12">
                <!-- Scroll to the button-->
                <v-btn
                    v-scroll="onScroll"
                    v-show="goTopShow"
                    fab
                    dark
                    fixed
                    bottom
                    right
                    small
                    style="z-index: 1"
                    color="primary"
                    @click="toTop"
                >
                    <v-icon>keyboard_arrow_up</v-icon>
                </v-btn>
            </v-col>
            <!-- Tabs -->
            <v-col xl="11" lg="12" md="12" sm="12" xs="12" cols="12">
                <CustomTabs v-model="tab" :tabs="tabs" :draggable="false" :append="true">
                    <template v-slot:append>
                        <v-btn
                            color="primary"
                            @click="createTrip()"
                            v-if="!isSharedTrip"
                            style="
                                        margin-top: -0.5em;
                                        height: 38px;
                                    "
                        >
                            {{ $t("trips.create_trip_2") }}
                        </v-btn>
                    </template>
                </CustomTabs>
            </v-col>
            <!-- Table -->
            <v-col xl="11" lg="12" md="12" sm="12" xs="12" cols="12">
                <!-- Table -->
                <v-tabs-items v-model="tab" class="mt-2">
                    <v-tab-item v-for="(item, i) in tabs" :key="i" :value="item.value" class="background">
                        <TripTableNew
                            ref="tripTable"
                            :tripFilter="tripFilter"
                            :filterSelected="filterSelected"
                            v-on:click:row="onTripSelected"
                            :isSharedTrip.sync="isSharedTrip"
                            :tab="tab"
                            :headersEnabled="dynamicHeaders"
                            v-on:loading="loadHeaders"
                            :useIcons="useIcons"
                        >
                            <template #addFilter>
                                <div>
                                    <TripFilterNew
                                        :isSharedTrip.sync="isSharedTrip"
                                        :filterCode.sync="filterCode"
                                        :filterSelected="filterSelected"
                                        :disabled="tableIsLoading"
                                        :filterPreferences="tripTablePreference"
                                        v-on:filter-changed="onFilterChanged"
                                        v-on:trip-created="onTripCreated"
                                    >
                                        <template v-slot:view-manage>
                                            <PreviewManageView
                                                v-model="filterSelected"
                                                :views.sync="definedTableViews"
                                                v-on:save-view="onSaveView"
                                                v-on:delete-view="onDeleteView"
                                                v-on:input-change="onChangeView"
                                                v-on:edit-columns="onEditColumns"
                                                :disabled="tableIsLoading"
                                            ></PreviewManageView>
                                        </template>
                                    </TripFilterNew>
                                </div>
                            </template>
                        </TripTableNew>
                    </v-tab-item>
                </v-tabs-items>
            </v-col>
        </v-row>
        <!-- tutorial -->
        <TutorialDialog :dialog.sync="tutorialDialog"></TutorialDialog>
        <!-- detail drawer -->
        <v-navigation-drawer
            v-model="tripDetailDrawer"
            :width="tripDetailDrawerWidth"
            :style="{ top: '46px', zIndex: 3 }"
            right
            app
            disable-route-watcher
            temporary
        >
            <div>
                <TripDetail
                    v-if="selectedTrip || idFromRouter"
                    :key="selectedTrip ? selectedTrip.id : idFromRouter"
                    :tripProp.sync="selectedTrip"
                    :tripId="idFromRouter"
                    :isFullScreen="isTripDetailDrawerFullScreen"
                    v-on:changeFullScreen="onTripDetailDrawerFullScreenChange"
                    v-on:close="tripDetailDrawer = false"
                    :isSharedTrip.sync="isSharedTrip"
                    v-on:trip-created="onTripCreated"
                    v-on:trip-updated="onTripUpdated"
                ></TripDetail>
            </div>
        </v-navigation-drawer>
        <!-- <v-navigation-drawer
            v-model="columnDrawer"
            :width="200"
            :style="{ top: '46px', zIndex: 3 }"
            right
            app
            disable-route-watcher
            temporary
        >
            <div>
                Hello
            </div>
        </v-navigation-drawer> -->
        <v-navigation-drawer
            v-model="columnDrawer"
            :width="300"
            :style="{ top: '46px', zIndex: 3 }"
            right
            app
            temporary
            disable-route-watcher
        >
            <FilterMenu
                :title="$t('dashboard.customize')"
                :description="$t('general.select_columns')"
                :choices="headerChoices"
                :open-on-hover="false"
                :height="'calc(100vh - 46px)'"
                v-on:changed="onFilterMenuChanged"
                :is-active="columnDrawer"
                :use-drawer-style="true"
                :minSelection="7"
                :showIconSwitch="false"
                v-on:update-useIcons="useIcons = !useIcons"
            ></FilterMenu>
        </v-navigation-drawer>
    </v-container>
</template>

<script>
import helper from "@/utils/helper.js";
import TutorialDialog from "@/components/support/TutorialDialog.vue";
import PreviewManageView from "@/components/base/PreviewManageView.vue";
import TripFilterNew from "@/components/trips/TripFilterNew.vue";
import TripTableNew from "@/components/trips/TripTableNew.vue";
import TripDetail from "@/components/trips/TripDetailNew.vue";
import CustomTabs from "@/components/base/CustomTabs.vue";
import FilterMenu from "@/components/base/CustomizableColumnMenu.vue";
import { eventBus } from "@/main.js";

export default {
    title: "Trips new UI",
    components: {
        TutorialDialog,
        TripTableNew,
        TripDetail,
        CustomTabs,
        PreviewManageView,
        TripFilterNew,
        FilterMenu
    },
    apollo: {},
    data() {
        return {
            filterCode: "",
            tripDetailDrawer: false,
            goTopShow: false,
            isLoading: false,
            menu: false,
            idFromRouter: null,
            isRouting: false,
            tripFilter: {
                first: 10,
                search: null
            },
            tableIsLoading: false,
            selectedTrip: null,
            tripDetailFullScreen: false,
            tutorialDialog: false,
            tab: null,
            filterSelected: "default",
            viewSelected: "default",
            tabs: [
                {
                    text: "trips",
                    value: "trips"
                },
                {
                    text: "favorites",
                    value: "favorite"
                }
            ],
            headerChoices: [],
            enabledFilters: [],
            defaultHeaderChoices: [
                {
                    // for filter menu
                    code: "node.referenceNumber",
                    name: "Reference Name",
                    tag: "headers.reference_number",
                    enabled: true,
                    // for table header
                    text: "Reference Name",
                    align: "left",
                    value: "node.referenceNumber",
                    fixedPosition: true
                },
                {
                    // for filter menu
                    code: "node.origin",
                    name: "Origin",
                    tag: "headers.origin",
                    enabled: true,
                    // for table header
                    text: "Planned Arrival",
                    align: "left",
                    value: "node.origin",
                    fixedPosition: false
                },
                {
                    // for filter menu
                    code: "node.destination",
                    name: "Destination",
                    tag: "headers.destination",
                    enabled: true,
                    // for table header
                    text: "Planned Arrival",
                    align: "left",
                    value: "node.destination",
                    fixedPosition: false
                },
                {
                    // for filter menu
                    code: "node.plannedDepartureDate",
                    name: "Planned Departure",
                    tag: "headers.planned_departure",
                    enabled: true,
                    // for table header
                    text: "Planned Departure",
                    align: "left",
                    value: "node.plannedDepartureDate",
                    fixedPosition: false
                },
                {
                    // for filter menu
                    code: "node.plannedArrivalDate",
                    name: "Planned Arrival",
                    tag: "headers.planned_arrival",
                    enabled: true,
                    // for table header
                    text: "Planned Arrival",
                    align: "left",
                    value: "node.plannedArrivalDate",
                    fixedPosition: false
                },
                {
                    // for filter menu
                    code: "node.carrier",
                    name: "Carrier",
                    tag: "headers.carrier",
                    enabled: true,
                    // for table header
                    text: "Carrier",
                    align: "left",
                    value: "carrier",
                    fixedPosition: false
                },
                {
                    // for filter menu
                    code: "node.vessel",
                    name: "Vessel",
                    tag: "headers.vessel",
                    enabled: false,
                    // for table header
                    text: "Vessel",
                    align: "left",
                    value: "node.vessel",
                    fixedPosition: false
                },
                {
                    // for filter menu
                    code: "node.consignor",
                    name: "Consignor",
                    tag: "headers.consignor",
                    enabled: false,
                    // for table header
                    text: "Consignor",
                    align: "left",
                    value: "consignor",
                    fixedPosition: false
                },
                {
                    // for filter menu
                    code: "node.consignee",
                    name: "Consignee",
                    tag: "headers.consignee",
                    enabled: false,
                    // for table header
                    text: "Consignee",
                    align: "left",
                    value: "consignee",
                    fixedPosition: false
                },
                {
                    // for filter menu
                    code: "node.forwarder",
                    name: "Forwarder",
                    tag: "headers.forwarder",
                    enabled: false,
                    // for table header
                    text: "Forwarder",
                    align: "left",
                    value: "forwarder",
                    fixedPosition: false
                },
                {
                    // for filter menu
                    code: "node.other",
                    name: "Other",
                    tag: "headers.other",
                    enabled: false,
                    // for table header
                    text: "Other",
                    align: "left",
                    value: "other",
                    fixedPosition: false
                },
                {
                    // for filter menu - add last connection
                    code: "node.lastConnection",
                    name: "Last Connection",
                    tag: "headers.last_connection",
                    enabled: false,
                    // for table header
                    text: "Last Connection",
                    align: "left",
                    value: "node.lastConnection",
                    fixedPosition: false
                },
                {
                    // for filter menu - add last temperature
                    code: "node.lastTemperature",
                    name: "Last Temperature",
                    tag: "headers.trackerLastTemperature",
                    enabled: false,
                    // for table header
                    text: "Last Temperature",
                    align: "left",
                    value: "node.lastTemperature",
                    fixedPosition: false
                },
                {
                    // for filter menu - last location
                    code: "node.lastLocation",
                    name: "Last Location",
                    tag: "headers.trackerLastLocation",
                    enabled: false,
                    // for table header
                    text: "Last Location",
                    align: "left",
                    value: "node.lastLocation",
                    fixedPosition: false
                },
                {
                    // for filter menu - last location
                    code: "node.trade",
                    name: "Trade",
                    tag: "headers.trade",
                    enabled: false,
                    // for table header
                    text: "Trade",
                    align: "left",
                    value: "node.trade",
                    fixedPosition: false
                }
            ],
            //column drawer
            columnDrawer: false,
            shouldRefreshTableData: false,
            definedTableViews: [],
            tripTablePreference: [],
            tableColumnLimit: 7,
            useIcons: false
        };
    },
    computed: {
        me() {
            return helper.me();
        },
        defaultTripTablePreference() {
            return {
                view: {
                    name: "Default",
                    code: "default",
                    icon: "favorite",
                    enabled: true
                },
                tripTableFilters: {
                    first: 6
                },
                tripFilterEnabled: [
                    {
                        code: "status_In",
                        enabled: true,
                        name: "Status"
                    }
                ],
                tripTablePreferences: this.defaultHeaderChoices
            };
        },
        tripDetailDrawerWidth() {
            if (this.tripDetailFullScreen) {
                switch (this.$vuetify.breakpoint.name) {
                    case "xs":
                        return "70%";
                    case "sm":
                        return "70%";
                    case "md":
                        return "70%";
                    case "lg":
                        return "85%";
                    case "xl":
                        return "85%";
                    default:
                        return "85%";
                }
            }

            switch (this.$vuetify.breakpoint.name) {
                case "xs":
                    return "70%";
                case "sm":
                    return "70%";
                case "md":
                    return "70%";
                case "lg":
                    return "70%";
                case "xl":
                    return "70%";
                default:
                    return "85%";
            }
        },
        isTripDetailDrawerFullScreen() {
            return this.tripDetailDrawerWidth === "85%";
        },
        isSharedTrip() {
            // return this.$route?.query?.type?.toLowerCase() === "shared";
            return this.$route?.name === "previewsharedtrips";
        },
        isLoggedInAsOtherUser() {
            return this.$store.state.user.loggedInAsOtherUser;
        },
        switchTableHeaders() {
            return this.isSharedTrip ? `sharedTrip-default` : "tripTable-default";
        },
        dynamicHeaders: {
            get() {
                let _headers = [...this.headerChoices];
                this.defaultHeaderChoices.forEach(choice => {
                    if (!_headers.find(c => c.code === choice.code)) {
                        _headers.push(choice);
                    }
                });

                _headers = _headers.map(header => {
                    return {
                        ...header,
                        text: this.$t(header.tag)
                    };
                });

                //return first 6 columns
                let firstLimitEnabled = _headers.filter(header => header.enabled);
                let sliceHeaders = [];
                if (firstLimitEnabled.length > this.tableColumnLimit) {
                    sliceHeaders = _headers.slice(0, this.tableColumnLimit);
                } else if (firstLimitEnabled.length < this.tableColumnLimit) {
                    sliceHeaders = _headers.slice(0, this.tableColumnLimit);
                    //enbaled all the slice headers
                    sliceHeaders = sliceHeaders.map(header => {
                        return {
                            ...header,
                            enabled: true
                        };
                    });
                } else {
                    sliceHeaders = firstLimitEnabled;
                }

                return sliceHeaders;
            },
            set(value) {
                //console.log(value);
                this.headerChoices = value.sort((a, b) => (a.enabled === b.enabled ? 0 : a.enabled ? -1 : 1));
            }
        }
    },
    watch: {
        tripDetailDrawer(val) {
            if (!val) {
                this.resetRoute();
            }
        },
        useIcons(val) {
            console.log(val);
        }
    },
    beforeRouteEnter(to, from, next) {
        next(vm => {
            vm.handleRouteChange(to, from, next);
        });
    },
    beforeRouteUpdate(to, from, next) {
        if (!this.isRouting) this.handleRouteChange(to, from, next);
        else {
            next();
        }
    },
    created() {
        this.hasPermission = helper.hasPermission;
        this.pushTo = helper.pushTo;
        this.checkIfTripIsShared = helper.checkIfTripIsShared;
        this.isTenantUser = helper.isTenantUser;
        this.isTenantPartner = helper.isTenantPartner;
    },
    async mounted() {
        if (this.$route.query.code) this.filterCode = this.$route.query.code;
        let _createTrip = this.$store.getters["trip/getCreatedTrip"];
        let _cancelTripChanges = this.$store.getters["trip/getCancelTrip"];

        await this.setTripTablePreference();

        this.setDefinedTableViews();

        this.setHeaderChoices();

        if (_createTrip) {
            this.onTripCreated(_createTrip);
        } else if (_cancelTripChanges) {
            this.onTripCancel(_cancelTripChanges);
        }
    },
    methods: {
        async setTripTablePreference() {
            let _tripTablePreference = await helper.getFilterValuesFromPreferences("tripTablePreference");
            //add default trip table preference, if not exists
            if (_tripTablePreference) {
                let defaultTripTablePreferenceExists = _tripTablePreference?.find(item => item.view.code === "default");
                if (!defaultTripTablePreferenceExists) {
                    //check if any view is enabled
                    let anyViewEnabled = _tripTablePreference.find(item => item.view.enabled === true);
                    if (anyViewEnabled) {
                        //disable the default view
                        this.defaultTripTablePreference.view.enabled = false;
                    }
                    _tripTablePreference.push(this.defaultTripTablePreference);
                }
                this.tripTablePreference = _tripTablePreference;
            } else {
                this.tripTablePreference = [this.defaultTripTablePreference];
            }
        },
        setDefinedTableViews() {
            this.definedTableViews = [];
            let localDefinedViews = [
                {
                    name: "Default",
                    code: "default",
                    icon: "favorite",
                    enabled: true
                }
            ];

            this.tripTablePreference.forEach(item => {
                localDefinedViews.push(item.view);
            });
            this.definedTableViews = [...localDefinedViews];

            //set the filter selected
            if (this.filterCode) {
                this.filterSelected = "default";
            } else {
                this.filterSelected =
                    this.tripTablePreference.find(item => item?.view?.enabled === true)?.view?.code || "default";
            }
        },
        setHeaderChoices() {
            this.headerChoices = [];
            let _default = [...this.defaultHeaderChoices];

            let _filterPreference = this.tripTablePreference;
            let filter = _filterPreference.find(item => item?.view?.enabled === true);

            if (filter && !Array.isArray(filter)) {
                if (filter.tripTablePreferences?.length > 0) {
                    this.headerChoices = filter.tripTablePreferences.map(choice => choice);
                }
            } else if (filter && Array.isArray(filter)) {
                this.headerChoices = filter[0].tripTablePreferences.map(choice => choice);
            }
            //loop through the _default and check if the choice is in the headerChoices. if not, add it
            _default.forEach(choice => {
                if (!this.headerChoices.find(c => c.code === choice.code)) {
                    this.headerChoices.push(choice);
                }
            });
            // check if fixedPosition
            this.headerChoices = this.headerChoices.map(choice => {
                return {
                    ...choice,
                    fixedPosition: choice.fixedPosition
                        ? choice.fixedPosition
                        : choice.code === "node.referenceNumber"
                        ? true
                        : false,
                    enabled: choice.enabled ? choice.enabled : choice.fixedPosition ? true : false
                };
            });
        },
        loadHeaders(value) {
            this.shouldRefreshTableData = value;
            //this.tableIsLoading = value; -- this disabled the filter menus
            // this.setHeaderChoices();
        },
        onFilterMenuChanged(newChoices) {
            this.dynamicHeaders = newChoices.map(choice => choice);
        },
        createTrip() {
            this.$router.push({
                path: "/newtrips",
                query: {
                    create: true
                }
            });
        },
        handleRouteChange(to, from, next) {
            this.isRouting = true;

            if (this.selectedTrip == null) {
                if (to.path === "/previewtrips") {
                    if (this.isTenantUser() && !this.isTenantPartner()) {
                        if (to.query.id) {
                            this.idFromRouter = to.query.id;
                            this.tripDetailDrawer = true;
                        }
                        next();
                        this.isRouting = false;
                    } else if (this.isTenantUser() && this.isTenantPartner()) {
                        if (to.query?.id) {
                            this.checkIfTripIsShared(to.query?.id).then(res => {
                                if (res) {
                                    this.$router.push({
                                        path: "/previewsharedtrips",
                                        query: {
                                            id: this.$route.query?.id
                                        }
                                    });
                                    if (to.query.id) {
                                        this.idFromRouter = to.query.id;
                                        this.tripDetailDrawer = true;
                                    }
                                } else {
                                    if (to.query.id) {
                                        this.idFromRouter = to.query.id;
                                        this.tripDetailDrawer = true;
                                    }
                                    next();
                                }
                                this.isRouting = false;
                            });
                        } else {
                            next();
                            this.isRouting = false;
                        }
                    } else {
                        this.$router.push({
                            path: "/previewsharedtrips",
                            query: {
                                id: to.query?.id
                            }
                        });

                        if (to.query.id) {
                            this.idFromRouter = to.query.id;
                            this.tripDetailDrawer = true;
                        }

                        this.isRouting = false;
                    }
                } else if (to.path === "/previewsharedtrips") {
                    if (to.query.id) {
                        this.idFromRouter = to.query.id;
                        this.tripDetailDrawer = true;
                    }
                    next();
                    this.isRouting = false;
                } else {
                    next(false);
                    this.isRouting = false;
                }
            } else {
                next();
                this.isRouting = false;
            }
        },
        onLoading(val) {
            this.isLoading = val;
        },
        onScroll(e) {
            if (typeof window === "undefined") return;
            const top = window.pageYOffset || e.target.scrollTop || 0;
            this.goTopShow = top > 20;
        },
        toTop() {
            this.$vuetify.goTo(0);
        },
        loadMore() {
            this.isLoading = false;
        },
        onTripSelected(trip) {
            this.selectedTrip = trip.node;
            this.isSharedTrip
                ? this.$router.push({
                      query: { id: trip.node.id, type: "shared" }
                  })
                : this.$router.push({ query: { id: trip.node.id } });
            this.tripDetailDrawer = true;
        },
        onTripDetailDrawerFullScreenChange(val) {
            this.tripDetailFullScreen = val;
            if (val) {
                eventBus.$emit("closeMenu");
            }
        },
        onTripCreated(trip) {
            this.selectedTrip = trip;
            this.$router.push({ query: { id: trip.id } });
            this.tripDetailDrawer = true;
            if (this.$refs.tripTable?.$apollo) {
                this.$refs.tripTable?.$apollo?.queries?.trips?.refetch();
            }

            if (trip) {
                this.$store.dispatch("trip/destroyStorage");
            }
        },
        onTripUpdated(trip) {
            this.selectedTrip = trip;
            if (!this.$refs.tripTable?.$apollo) this.$refs.tripTable[0]?.$apollo.queries.trips.refetch();
            else this.$refs.tripTable?.$apollo.queries.trips.refetch();
        },
        onTripCancel(trip) {
            this.selectedTrip = trip;
            this.$router.push({ query: { id: trip.id } });
            this.tripDetailDrawer = true;
            if (trip) {
                this.$store.dispatch("trip/destroyStorage");
            }
        },
        resetRoute() {
            this.selectedTrip = null;
            this.tripDetailFullScreen = false;
            this.idFromRouter = null;
            this.$router.push({ query: null });
        },
        onFilterChanged(filter, enabledFilters) {
            this.tripFilter = filter;
            if (enabledFilters) {
                this.enabledFilters = [...enabledFilters];
            }
        },
        onSelectedFilter(filter) {
            this.enabledFilters = [...filter];
        },
        async onChangeView(view) {
            let currentTablePreference = this.tripTablePreference.find(item => item.view.code === view.code);

            if (currentTablePreference) {
                let newTablePreferenceToUpdate = this.tripTablePreference.map(item => {
                    // Update the view if it exists
                    if (item.view.code === view.code) {
                        return {
                            view: view,
                            tripTableFilters: currentTablePreference.tripTableFilters,
                            tripFilterEnabled: currentTablePreference.tripFilterEnabled,
                            tripTablePreferences: currentTablePreference.tripTablePreferences
                        };
                    } else {
                        // Set enabled to false for all other views
                        return {
                            ...item,
                            view: {
                                ...item.view,
                                enabled: false
                            }
                        };
                    }
                });
                //update the trip table preference
                this.tripTablePreference = [...newTablePreferenceToUpdate];
                // await this.setTripTablePreference();
                this.setDefinedTableViews();
                this.setHeaderChoices();
                let preferences = {
                    tripTablePreference: this.tripTablePreference
                };

                // Await the update of preferences
                await helper.updateMyPreferencesSync(preferences);

                //update trip filter
                // this.tripFilter = currentTablePreference.tripTableFilters;
            }
        },
        async onSaveView(newView) {
            try {
                /**
                 * what could have changed
                 * 1. View (enabled view)
                 * 2. filters (enabled filters)
                 * 3. columns (edited columns)
                 * 4. table preferences (header choices)
                 */
                let userTripTablePreference = this.tripTablePreference;
                let newFilterToUpdate = [];
                if (userTripTablePreference) {
                    let viewExists = userTripTablePreference.find(item => item.view.code === newView.code)
                        ? true
                        : false;
                    //TODO: if view exists, update it and set the other views to false
                    if (viewExists) {
                        newFilterToUpdate = userTripTablePreference.map(item => {
                            // Update the view if it exists
                            if (item.view.code === newView.code) {
                                return {
                                    view: newView,
                                    tripTableFilters: this.tripFilter,
                                    tripFilterEnabled: this.enabledFilters,
                                    tripTablePreferences: this.headerChoices
                                };
                            } else {
                                // Set enabled to false for all other views
                                return {
                                    ...item,
                                    view: {
                                        ...item.view,
                                        enabled: false
                                    }
                                };
                            }
                        });
                    } else {
                        newFilterToUpdate = userTripTablePreference.map(item => {
                            return {
                                ...item,
                                view: {
                                    ...item.view,
                                    enabled: false
                                }
                            };
                        });

                        newFilterToUpdate.push({
                            view: newView,
                            tripTableFilters: this.tripFilter,
                            tripFilterEnabled: this.enabledFilters,
                            tripTablePreferences: this.headerChoices
                        });
                    }
                } else {
                    // Commented out shared trip - not needed
                    /*  if (this.isSharedTrip) {
                        newFilterToUpdate = {
                            sharedTripTablePreference: [
                                {
                                    view: newView,
                                    tripTableFilters: this.tripFilter,
                                    tripFiltersEnabled: this.enabledFilters,
                                    tripTablePreferences: this.headerChoices
                                }
                            ]
                        };
                    } else  {
                    }*/
                    newFilterToUpdate = {
                        tripTablePreference: [
                            {
                                view: newView,
                                tripTableFilters: this.tripFilter,
                                tripFilterEnabled: this.enabledFilters,
                                tripTablePreferences: this.headerChoices
                            }
                        ]
                    };
                }
                //update the preferences array
                this.tripTablePreference = [...newFilterToUpdate];

                // Update the preferences
                let preferences = {
                    tripTablePreference: this.tripTablePreference
                };

                // Await the update of preferences
                await helper.updateMyPreferencesSync(preferences);
                // await this.setTripTablePreference();
                this.setDefinedTableViews();
                this.setHeaderChoices();
                // this.filterSelected = newView.code;

                //show dialog
                const payload = {
                    color: "blue",
                    message: this.$t("general.update_successfully")
                };
                this.$store.dispatch("snackbar/showMessage", payload);
            } catch (error) {
                console.error(error);
            }
        },
        async onDeleteView(view) {
            let _newFilterPreference = this.tripTablePreference.filter(item => item.view.code !== view);
            //update the preferences
            let preferences = {
                tripTablePreference: _newFilterPreference
            };
            // //update the preferences array

            await helper.updateMyPreferencesSync(preferences);

            await this.setTripTablePreference();
            this.setDefinedTableViews();
            //show dialog
            const payload = {
                color: "blue",
                message: this.$t("general.update_successfully")
            };
            this.$store.dispatch("snackbar/showMessage", payload);
        },
        onEditColumns() {
            //TODO: show right column drawer
            this.columnDrawer = true;
        }
    }
};
</script>
<style scoped>
::v-deep .v-navigation-drawer__content::-webkit-scrollbar {
    width: 0px;
    background: transparent;
}
.modelTitle {
    font-size: 28px;
    font-weight: 700;
    line-height: 32px;
    text-align: left;
    margin-bottom: 4px;
}
</style>
