<template>
    <v-container fluid class="pa-4 scrollable">
        <v-row dense align="stretch">
            <!-- Left Section -->
            <!-- ALERTS -->
            <v-col cols="12" xl="7" lg="7" md="12" sm="12" style="height: calc(100vh - 100px);">
                <v-card class="mt-1" style="height: 100%; display: flex; flex-direction: column;">
                    <v-card-title class="subtitle-2 grey--text d-flex justify-space-between align-center">
                        <span>{{ $t("dashboard.overview_alerts") }}</span>
                        <v-btn
                            small
                            icon
                            @click="onOpenResolveOptions"
                            v-if="!me.isPartnerUser"
                            :color="openResolveOptions ? 'primary' : ''"
                        >
                            <v-icon>checklist_rtl</v-icon>
                        </v-btn>
                    </v-card-title>
                    <!--<AlertsTable :isShowPreview="isShowPreview"/>-->
                    <AlertsFilter
                        v-on:filter-changed="onFilterChanged"
                        v-on:filter-enabled="onSelectedFilter"
                        v-on:open-resolve-options="onOpenResolveOptions"
                    ></AlertsFilter>
                    <AlertsTable :tripFilter="tripFilter" :isShowPreview="isShowPreview" :openResolveOptions="openResolveOptions"></AlertsTable>
                </v-card>
            </v-col>
            <!-- Right Section -->
            <v-col cols="12" xl="5" lg="5" md="12" sm="12" style="height: calc(100vh - 100px);">
                <v-card class="mt-1" style="height: 100%; display: flex; flex-direction: column;">
                    <v-card-title class="subtitle-2 grey--text d-flex justify-space-between align-center">
                        <span>{{ $t("dashboard.overview_kpis") }}</span>
                        <v-btn small icon @click="isActive = true" :color="isActive ? 'primary' : ''">
                            <v-icon>edit</v-icon>
                        </v-btn>
                    </v-card-title>
                    <v-card-text style="flex: 1; overflow-y: auto;">
                        <v-row dense>
                            <!-- KPIs -->
                            <v-col
                                v-for="kpi in dynamicChoices.filter(kpi => kpi.tag !== 'dashboard.table' && kpi.tag !== 'dashboard.active_trips_map')"
                                :key="kpi.tag"
                                cols="12"
                                xl="4"
                                lg="6"
                                md="4"
                                sm="6"
                                xs="12"
                            >
                                <v-card
                                    :loading="$apollo.queries.tenantDashboards.loading"
                                    :loader-height="3"
                                    @click="travelTo(kpi)"
                                    height="100%"
                                    outlined
                                >
                                    <v-card-title class="py-2 px-3 px-sm-4">
                                        <v-row>
                                            <v-col cols="12">
                                                <!-- Title and Icon Section -->
                                                <div class="d-flex align-top flex-grow-1 width-100">
                                                    <h6
                                                        class="subtitle-2 grey--text text-left mb-0 mr-2"
                                                        style="word-break: break-word; min-width: 0;"
                                                    >
                                                        {{ $t(kpi.tag) }}
                                                    </h6>
                                                    <v-spacer></v-spacer>
                                                    <v-btn
                                                        outlined
                                                        :color="kpi.color"
                                                        elevation="0"
                                                        fab
                                                        x-small
                                                        dense
                                                        class="flex-shrink-0"
                                                    >
                                                        <v-icon class="d-flex align-center justify-center ">
                                                            {{ kpi.icon }}
                                                        </v-icon>
                                                    </v-btn>
                                                </div>

                                                <!-- Value and Timestamp Section -->
                                                <div class="d-flex align-center width-100 mt-2 mt-sm-0">
                                                    <h2 class="font-weight-regular text-truncate mb-0">
                                                        {{ kpi.value }}
                                                    </h2>
                                                    <v-spacer></v-spacer>
                                                    <v-tooltip bottom>
                                                        <template v-slot:activator="{ on, attrs }">
                                                            <span
                                                                v-bind="attrs"
                                                                v-on="on"
                                                                class="text-caption text--disabled text-truncate ml-2 mt-3"
                                                            >
                                                                {{ formatDateTime(kpi.lastRefreshedAt) }}
                                                            </span>
                                                        </template>
                                                        <span>{{ $t("dashboard.last_refreshed_time") }}</span>
                                                    </v-tooltip>
                                                </div>
                                            </v-col>
                                        </v-row>
                                    </v-card-title>
                                </v-card>
                            </v-col>
                            <v-col cols="12" v-if="showMap">
                                <ClusterMap
                                    :loadingQuery="$apollo.queries.tripFreightTrackers.loading"
                                    :data="trackersLocations"
                                >
                                </ClusterMap>
                            </v-col>
                            <v-col cols="12" class="text-center" v-if="dynamicChoices.length === 0 && !showMap">
                                <v-img :src="require('@/assets/empty_data_small.png')"></v-img>
                                <v-card flat color="transparent">
                                    <v-card-title class="justify-center py-0">
                                        <div class="textTitle py-0">
                                            {{ $t("dashboard.empty_widgets_title") }}
                                        </div>
                                    </v-card-title>
                                    <v-card-text class="px-0 py-0">
                                        <div class="textSubtitle py-0">
                                            {{ $t("dashboard.empty_widgets_subtitle") }}
                                        </div>
                                    </v-card-text>
                                    <v-card-actions class="justify-center">
                                        <v-btn color="primary" small @click="isActive = true">
                                            {{ $t("dashboard.add_widget") }}
                                        </v-btn>
                                    </v-card-actions>
                                </v-card>
                            </v-col>
                        </v-row>
                    </v-card-text>
                </v-card>
            </v-col>
        </v-row>

        <!-- Filter Drawer -->
        <v-navigation-drawer v-model="isActive" :width="300" :style="{ top: '46px', zIndex: 3 }" right app temporary>
            <FilterMenu
                :title="$t('dashboard.customize_kpis')"
                :description="$t('dashboard.customize_kpis_description')"
                :choices="filterChoices"
                :open-on-hover="false"
                :height="'calc(100vh - 46px)'"
                v-on:changed="onFilterMenuChanged"
                :is-active="isActive"
                :use-drawer-style="true"
            ></FilterMenu>
        </v-navigation-drawer>
    </v-container>
</template>

<script>
//import VueApexCharts from "vue-apexcharts";
import gql from "graphql-tag";
import helper from "@/utils/helper.js";
import FilterMenu from "@/components/base/CustomizableColumnMenu.vue";
import ClusterMap from "@/components/base/ClusterMap.vue";
import AlertsTable from "@/components/alerts/AlertsTable.vue";
import AlertsFilter from "@/components/alerts/AlertsFilter.vue";
export default {
    title: "Home",
    components: {
        //apexcharts: VueApexCharts ,
        FilterMenu,
        ClusterMap,
        AlertsTable,
        AlertsFilter
    },
    apollo: {
        tripFreightTrackers: {
            query: gql`
                query TripFreightTrackers($status: String) {
                    tripFreightTrackers(status: $status) {
                        edges {
                            node {
                                id
                                trip {
                                    id
                                    referenceNumber
                                }
                                tracker {
                                    id
                                    serialNumber
                                    lastLongitude
                                    lastLatitude
                                }
                            }
                        }
                    }
                }
            `,
            variables() {
                return {
                    status: "active"
                };
            },
            // Additional options here
            fetchPolicy: "cache-and-network",
            nextFetchPolicy: "cache-first", // this setting can avoid query again after fetchMore
            update: data => data.tripFreightTrackers,
            result({ loading, data }) {
                if (!loading) {
                    const { tripFreightTrackers } = data;
                    const tripMap = new Map();

                    tripFreightTrackers.edges.forEach(({ node }) => {
                        let {
                            trip: { id: tripId, referenceNumber },
                            tracker: { id: trackerId, lastLatitude, lastLongitude, serialNumber }
                        } = node;

                        if (lastLatitude && lastLongitude) {
                            let tripData = tripMap.get(tripId);

                            if (!tripData) {
                                tripData = {
                                    trip: {
                                        id: tripId,
                                        referenceNumber: referenceNumber,
                                        tracker: []
                                    },
                                    points: [],
                                    trackerIds: new Set()
                                };
                                tripMap.set(tripId, tripData);
                            }

                            if (!tripData.trackerIds.has(trackerId)) {
                                tripData.trackerIds.add(trackerId);
                                tripData.points.push([lastLatitude, lastLongitude]);
                                tripData.trip.tracker.push({
                                    id: trackerId,
                                    serialNumber: serialNumber
                                });
                            }
                        }
                    });

                    this.trackersLocations = Array.from(tripMap.values()).map(({ trip, points }) => ({ trip, points }));
                }
            },
            skip() {
                return !this.showMap;
            }
        },
        tenantDashboards: {
            query: gql`
                query TenantDashboards {
                    tenantDashboards {
                        edges {
                            node {
                                id
                                tenant {
                                    id
                                }
                                value
                                dashboardCode
                                lastRefreshedAt
                            }
                        }
                    }
                }
            `,
            variables() {},
            // Additional options here
            fetchPolicy: "cache-and-network",
            nextFetchPolicy: "cache-first", // this setting can avoid query again after fetchMore
            update: data => data.tenantDashboards,
            // debounce: 800,
            skip() {
                return false;
            },
            pollInterval: 60000 // ms
        }
    },
    data() {
        return {
            first: 20,
            ind: 0,
            tenantDashboards: {},
            detailDialog: false,
            editAlertsDialog: false,
            loading: false,
            alertRule: {},
            tripAlerts: {
                pageInfo: {
                    endCursor: null,
                    startCursor: null,
                    hasPreviousPage: false,
                    hasNextPage: false
                },
                edges: []
            },
            trackersLocations: [],
            toggleButtton: "month",
            overview: {
                monthOption: {
                    chart: {
                        id: "month-bar",
                        zoom: {
                            enabled: false
                        }
                    },
                    xaxis: {
                        categories: ["Jan", "Feb", "Mar", "Apr"]
                    },
                    /* stroke: {
                        curve: "smooth"
                    }, */
                    colors: ["#00ACC1", "#00ACC1"]
                },
                weekOption: {
                    chart: {
                        id: "week-bar"
                    },
                    xaxis: {
                        categories: ["week 1", "week 2", "week 3", "week 4"]
                    }
                    /* stroke: {
                        curve: "smooth"
                    }  */
                },
                monthSalesPerformance: [
                    {
                        name: "series-1",
                        data: [1, 2, 4, 5]
                    }
                ],
                weekSalesPerformance: [
                    {
                        name: "series-2",
                        data: [6, 3, 6, 8]
                    }
                ]
            },
            performance: {
                monthOption: {
                    chart: {
                        id: "month-bar"
                    },
                    xaxis: {
                        categories: []
                    },
                    colors: ["#00ACC1", "#00ACC1"]
                },
                monthPerformance: [
                    {
                        name: "series-3",
                        data: []
                    }
                ]
            },
            isActive: false,
            tripFilter: {
                first: 10,
                search: null
            },
            enabledFilters: [],
            openResolveOptions: false
            // headers: [
            //     {
            //         text: "Trip",
            //         sortable: false,
            //         align: "left",
            //         value: "trip",
            //         isSelected: false
            //     },
            //     {
            //         text: "Alert",
            //         sortable: false,
            //         value: "alerts",
            //         isSelected: false
            //     },
            //     {
            //         text: "Status",
            //         sortable: false,
            //         align: "left",
            //         value: "status",
            //         isSelected: false
            //     },
            //     {
            //         text: "Alert Time",
            //         sortable: false,
            //         align: "left",
            //         value: "alertTime",
            //         isSelected: false
            //     },
            //     {
            //         text: "Event Time",
            //         sortable: false,
            //         align: "left",
            //         value: "deviceTime",
            //         isSelected: false
            //     },
            //     {
            //         text: "Alert Trigger",
            //         align: "left",
            //         value: "alertDescription",
            //         sortable: false
            //     },
            //     {
            //         text: "Freight",
            //         sortable: false,
            //         align: "left",
            //         value: "freight",
            //         isSelected: false
            //     },
            //     {
            //         text: "Tracker",
            //         sortable: false,
            //         align: "left",
            //         value: "tracker",
            //         isSelected: false
            //     },
            //     {
            //         text: "Action",
            //         sortable: false,
            //         align: "left",
            //         value: "actions",
            //         isSelected: false
            //     }
            // ]
        };
    },
    computed: {
        screenSize() {
            let _resposiveView = this.$vuetify.breakpoint.width;
            if (_resposiveView < 650) {
                return "xs";
            } else if (_resposiveView < 1000) {
                return "sm";
            } else if (_resposiveView < 1400) {
                return "md";
            } else if (_resposiveView < 1900) {
                return "lg";
            } else {
                return "xl";
            }
        },
        me() {
            return helper.me();
        },
        hasMoreData() {
            return this.tripAlerts?.pageInfo?.hasNextPage;
        },
        isShowPreview() {
            let localShowPreview = this.$store.getters["user/showPreview"];
            if (localShowPreview === null) {
                return false;
            }

            if (localShowPreview === "true") return true;
            if (localShowPreview === "false") return false;
            else return localShowPreview;
        },
        dashboardKPIs() {
            return this.prepareDashboardKPIs();
        },
        filterChoices: {
            get() {
                let _filterChoices = helper.getTableHeaders("dashboardKpIs", this.dashboardKPIs);
                _filterChoices = _filterChoices.filter(
                    kpi => !(kpi.name === "Available Trackers" && this.me.isPartnerUser && !this.me.isTenantUser)
                );
                
                return _filterChoices;
            },
            set(value) {
                helper.updateMyPreferences({
                    dashboardKpIs: value
                });
            }
        },
        dynamicChoices() {
            let _choices = [];

            this.filterChoices.forEach(choice => {
                this.dashboardKPIs.find(kpi => {
                    // if (kpi.code != "TABLE" && kpi.code != "ACTIVE_TRIP_MAP") {
                        if (kpi.code === choice.code && choice.enabled) {
                            _choices.push(kpi);
                        }
                    // }
                });
            });
            
            _choices = _choices.filter(
                kpi => !(kpi.name === "Available Trackers" && this.me.isPartnerUser && !this.me.isTenantUser)
            );
            return _choices;
        },
        showMap() {
            let _show = false;
            this.filterChoices.forEach(choice => {
                if (choice.code === "ACTIVE_TRIP_MAP" && choice.enabled && this.me.isTenantUser) {
                    _show = true;
                }
            });
            return _show;
        },
        headers: function() {
            let _headers = [
                {
                    text: this.$t("headers.trip"),
                    sortable: false,
                    align: "left",
                    value: "trip",
                    isSelected: false
                },
                {
                    text: this.$t("headers.alert"),
                    sortable: false,
                    value: "alerts",
                    isSelected: false
                },
                {
                    text: this.$t("headers.status"),
                    sortable: false,
                    align: "left",
                    value: "status",
                    isSelected: false
                },
                {
                    text: this.$t("headers.trigger_time"),
                    sortable: false,
                    align: "left",
                    value: "alertTime",
                    isSelected: false
                },
                {
                    text: this.$t("headers.device_time"),
                    sortable: false,
                    align: "left",
                    value: "deviceTime",
                    isSelected: false
                },
                {
                    text: this.$t("headers.alert_trigger"),
                    align: "left",
                    value: "alertDescription",
                    sortable: false
                },
                {
                    text: this.$t("headers.freight"),
                    sortable: false,
                    align: "left",
                    value: "freight",
                    isSelected: false
                },
                {
                    text: this.$t("headers.tracker"),
                    sortable: false,
                    align: "left",
                    value: "tracker",
                    isSelected: false
                },
                {
                    text: this.$t("headers.action"),
                    sortable: false,
                    align: "left",
                    value: "actions",
                    isSelected: false
                }
            ];
            return _headers;
        }
    },
    created() {
        this.formatDateTime = helper.formatDateTime;
        this.hasPermission = helper.hasPermission;
        this.getTemperatureUnit = helper.getTemperatureSymbol;
        this.celsiusToFahrenheit = helper.celsiusToFahrenheit;
    },
    watch: {
        isShowPreview: {
            handler: function(val) {
                this.prepareDashboardKPIs();
            }
        }
    },
    mounted() {},
    methods: {
        prepareDashboardKPIs() {
            let _kpi = [];

            let _tables = this.tenantDashboards;

            const _tripsActive = this.tenantDashboards?.edges?.find(e => e.node.dashboardCode === "TOTAL_OPEN_TRIPS");

            _kpi.push({
                name: "Active Trips",
                tag: "dashboard.active_trips_map",
                enabled: false,
                code: "ACTIVE_TRIP_MAP"
            });

            let openTripsSum = 0;
            let seaEtdDelaySum = 0;
            let seaTransWaitSum = 0;
            let seaEtaDealySum = 0;
            let seaDestWaitSum = 0;
            let unresolvedAlertsSum = 0;
            let availableTrackersSum = 0;
            let unresolvedTagTemperatureSum = 0;
            let unresolvedTagLightSum = 0;
            let unresolvedTagShockSum = 0;
            let unresolvedTagHumiditySum = 0;
            let unresolvedTagPressureSum = 0;
            let unresolvedTagTemperatureHumiditySum = 0;
            let unresolvedTagTemperatureHumidityLightSum = 0;

            let _tripLink = this.me.isPartnerUser && !this.me.isTenantUser ? "sharedtrips" : "/trips";
            if (this.isShowPreview === true) {
                _tripLink = this.me.isPartnerUser && !this.me.isTenantUser ? "previewsharedtrips" : "/previewtrips";
            }

            if (this.tenantDashboards && this.tenantDashboards.edges) {
                for (let i = 0; i < this.tenantDashboards.edges.length; i++) {
                    const edge = this.tenantDashboards.edges[i];
                    const node = edge.node;
                    const dashboardCode = node.dashboardCode;

                    switch (dashboardCode) {
                        case "TOTAL_OPEN_TRIPS":
                            openTripsSum += node.value || 0;
                            break;
                        case "SEA_ETD_DELAY":
                            seaEtdDelaySum += node.value || 0;
                            break;
                        case "SEA_TRANS_WAIT":
                            seaTransWaitSum += node.value || 0;
                            break;
                        case "SEA_ETA_DELAY":
                            seaEtaDealySum += node.value || 0;
                            break;
                        case "SEA_DEST_WAIT":
                            seaDestWaitSum += node.value || 0;
                            break;
                        case "UNRESOLVED_ALERTS":
                            unresolvedAlertsSum += node.value || 0;
                            break;
                        case "AVAILABLE_TRACKERS":
                            availableTrackersSum += node.value || 0;
                            break;
                        case "UNRESOLVED_TAG_TEMPERATURE":
                            unresolvedTagTemperatureSum += node.value || 0;
                            break;
                        case "UNRESOLVED_TAG_LIGHT":
                            unresolvedTagLightSum += node.value || 0;
                            break;
                        case "UNRESOLVED_TAG_SHOCK":
                            unresolvedTagShockSum += node.value || 0;
                            break;
                        case "UNRESOLVED_TAG_HUMIDITY":
                            unresolvedTagHumiditySum += node.value || 0;
                            break;
                        case "UNRESOLVED_TAG_PRESSURE":
                            unresolvedTagPressureSum += node.value || 0;
                            break;
                        case "UNRESOLVED_TAG_TEMPERATURE_HUMIDITY":
                            unresolvedTagTemperatureHumiditySum += node.value || 0;
                            break;
                        case "UNRESOLVED_TAG_TEMPERATURE_HUMIDITY_LIGHT":
                            unresolvedTagTemperatureHumidityLightSum += node.value || 0;
                            break;
                    }
                }
            }

            _kpi.push({
                name: "Active Trips",
                tag: "dashboard.active_trips",
                enabled: true,
                code: "TOTAL_OPEN_TRIPS",
                value: openTripsSum,
                lastRefreshedAt: _tripsActive?.node?.lastRefreshedAt,
                icon: "near_me",
                color: "secondary lighten-1",
                link: _tripLink
            });

            _kpi.push({
                name: "ETD Delays",
                tag: "dashboard.etd_delays",
                enabled: false,
                code: "SEA_ETD_DELAY",
                value: seaEtdDelaySum,
                lastRefreshedAt: _tripsActive?.node?.lastRefreshedAt,
                icon: "hourglass_top",
                color: "secondary lighten-1",
                link: _tripLink
            });

            _kpi.push({
                name: "T/S Waitings",
                tag: "dashboard.ts_waitings",
                enabled: false,
                code: "SEA_TRANS_WAIT",
                value: seaTransWaitSum,
                lastRefreshedAt: _tripsActive?.node?.lastRefreshedAt,
                icon: "multiple_stop",
                color: "secondary lighten-1",
                link: _tripLink
            });

            _kpi.push({
                name: "ETA Delays",
                tag: "dashboard.eta_delays",
                enabled: false,
                code: "SEA_ETA_DELAY",
                value: seaEtaDealySum,
                lastRefreshedAt: _tripsActive?.node?.lastRefreshedAt,
                icon: "schedule",
                color: "secondary lighten-1",
                link: _tripLink
            });

            _kpi.push({
                name: "POD Waitings",
                tag: "dashboard.pod_waitings",
                enabled: false,
                code: "SEA_DEST_WAIT",
                value: seaDestWaitSum,
                lastRefreshedAt: _tripsActive?.node?.lastRefreshedAt,
                icon: "sports_score",
                color: "secondary lighten-1",
                link: _tripLink
            });

            _kpi.push({
                name: "Unresolved Alerts",
                tag: "dashboard.unresolved_alerts",
                enabled: true,
                code: "UNRESOLVED_ALERTS",
                value: unresolvedAlertsSum,
                lastRefreshedAt: _tripsActive?.node?.lastRefreshedAt,
                icon: "notifications",
                color: "secondary lighten-1",
                link: _tripLink
            });

            _kpi.push({
                name: "Available Trackers",
                tag: "dashboard.available_trackers",
                enabled: true,
                code: "AVAILABLE_TRACKERS",
                value: availableTrackersSum,
                lastRefreshedAt: _tripsActive?.node?.lastRefreshedAt,
                icon: "settings_remote",
                color: "secondary lighten-1",
                link: !this.isShowPreview ? "/trackers" : "/previewtrackers"
            });

            _kpi.push({
                name: "Unresolved Tag Temperature",
                tag: "dashboard.unresolved_tag_temperature",
                enabled: false,
                code: "UNRESOLVED_TAG_TEMPERATURE",
                value: unresolvedTagTemperatureSum,
                lastRefreshedAt: _tripsActive?.node?.lastRefreshedAt,
                icon: "thermostat",
                color: "secondary lighten-1",
                link: _tripLink
            });

            _kpi.push({
                name: "Unresolved Tag Humidity",
                tag: "dashboard.unresolved_tag_humidity",
                enabled: false,
                code: "UNRESOLVED_TAG_HUMIDITY",
                value: unresolvedTagHumiditySum,
                lastRefreshedAt: _tripsActive?.node?.lastRefreshedAt,
                icon: "water_drop",
                color: "secondary lighten-1",
                link: _tripLink
            });

            _kpi.push({
                name: "Unresolved Tag light",
                tag: "dashboard.unresolved_tag_light",
                enabled: false,
                code: "UNRESOLVED_TAG_LIGHT",
                value: unresolvedTagLightSum,
                lastRefreshedAt: _tripsActive?.node?.lastRefreshedAt,
                icon: "lightbulb",
                color: "secondary lighten-1",
                link: _tripLink
            });

            _kpi.push({
                name: "Unresolved Tag Shock",
                tag: "dashboard.unresolved_tag_shock",
                enabled: false,
                code: "UNRESOLVED_TAG_SHOCK",
                value: unresolvedTagShockSum,
                lastRefreshedAt: _tripsActive?.node?.lastRefreshedAt,
                icon: "bolt",
                color: "secondary lighten-1",
                link: _tripLink
            });

            _kpi.push({
                name: "Unresolved Tag Pressure",
                tag: "dashboard.unresolved_tag_pressure",
                enabled: false,
                code: "UNRESOLVED_TAG_PRESSURE",
                value: unresolvedTagPressureSum,
                lastRefreshedAt: _tripsActive?.node?.lastRefreshedAt,
                icon: "compress",
                color: "secondary lighten-1",
                link: _tripLink
            });

            _kpi.push({
                name: "Unresolved Tag Temperature Humidity",
                tag: "dashboard.unresolved_tag_temperature_humidity",
                enabled: false,
                code: "UNRESOLVED_TAG_TEMPERATURE_HUMIDITY",
                value: unresolvedTagTemperatureHumiditySum,
                lastRefreshedAt: _tripsActive?.node?.lastRefreshedAt,
                icon: "thermostat",
                color: "secondary lighten-1",
                link: _tripLink
            });

            _kpi.push({
                name: "Unresolved Tag Temperature Humidity Light",
                tag: "dashboard.unresolved_tag_temperature_humidity_light",
                enabled: false,
                code: "UNRESOLVED_TAG_TEMPERATURE_HUMIDITY_LIGHT",
                value: unresolvedTagTemperatureHumidityLightSum,
                lastRefreshedAt: _tripsActive?.node?.lastRefreshedAt,
                icon: "thermostat",
                color: "secondary lighten-1",
                link: _tripLink
            });
            return _kpi;
        },
        onFilterMenuChanged(newChoices) {
            this.filterChoices = newChoices.map(choice => choice);
        },
        redirect(id) {
            if (this.isShowPreview === true) {
                let redirectRoute = this.me.isPartnerUser && !this.me.isTenantUser ? "/previewsharedtrips" : "/previewtrips";
                this.$router.push({
                    path: redirectRoute,
                    query: { id: id, tab: "alerts" }
                });
            } else {
                let redirectRoute = this.me.isPartnerUser && !this.me.isTenantUser ? "/sharedtrips" : "/trips";
                this.$router.push({
                    path: redirectRoute,
                    query: { id: id, tab: "alerts" }
                });
            }
        },
        checkAlert(item) {
            let alert = [];
            let newObj = JSON.parse(item);

            if (newObj?.Location?.Condition) alert.push("location");

            if (newObj?.Sensor?.length > 0) {
                newObj.Sensor.forEach(item => {
                    alert.push(item.Type);
                });
            }

            return alert;
        },
        onLoadMoreTriggerIntersect(entries) {
            this.shouldLoadMore = entries[0].isIntersecting;

            if (this.shouldLoadMore && this.tripAlerts?.pageInfo?.hasNextPage) {
                this.loadMore();
            }
        },
        loadMore() {
            if (this.tripAlerts?.pageInfo?.hasNextPage) {
                this.$apollo.queries.tripAlerts.fetchMore({
                    variables: {
                        after: this.tripAlerts.pageInfo.endCursor,
                        isResolved: false
                    }
                });
            }
        },
        alertRuleDetail(item) {
            this.alertRule = { ...item };
            this.detailDialog = true;
        },
        travelTo(obj) {
            this.$router.push({
                path: obj.link,
                query: { code: obj.code }
            });
        },
        onFilterChanged(filter) {
            this.tripFilter = filter;
        },
        onSelectedFilter(filter) {
            this.enabledFilters = [...filter];
        },
        onOpenResolveOptions() {
            this.openResolveOptions = !this.openResolveOptions;
        }
    }
};
</script>

<style scoped>
.no-wrap {
    white-space: nowrap;
}

.scrollable {
    overflow-x: auto;
}
</style>
