import { VCard } from 'vuetify/lib/components/VCard';
import { VCardText } from 'vuetify/lib/components/VCard';
import { VCol } from 'vuetify/lib/components/VGrid';
import { VRow } from 'vuetify/lib/components/VGrid';

var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c(VCardText,{staticClass:"pb-0"},[_c('div',{staticClass:"d-flex flex-row align-center justify-center",attrs:{"ripple":false}},[_c(VCard,{staticClass:"d-flex-grow-0 mr-2",attrs:{"width":!_vm.isStaff && _vm.canEdit && _vm.openSelector ? '70' : '40',"color":"transparent","flat":"","tile":""}},[[_vm._t("prepend-action")]],2),_c(VRow,{attrs:{"dense":"","align":"center"}},[_c(VCol,{staticClass:"pa-0 text-uppercase user-select-text",attrs:{"cols":"12","xs":"12","sm":"12","md":"12","lg":"12","xl":"12"}},[_c(VRow,{staticClass:"pa-0",attrs:{"dense":""}},[_c(VCol,{staticClass:"align-center justify-start pa-1",style:({
                            'flex-basis': _vm.$vuetify.breakpoint.lgAndUp ? '14.2%' : '',
                            'max-width': _vm.$vuetify.breakpoint.lgAndUp ? '14.2%' : ''
                        }),attrs:{"cols":"12","xl":"auto","lg":"auto","md":"3","sm":"6","xs":"12"}},[_c('div',{staticClass:"mt-1 textLeft"},[_c('div',{staticClass:"font-weight-thin secondaryText text-truncate"},[_vm._v(" "+_vm._s(_vm.$t("headers.serial_number"))+" ")]),_c('div',{staticClass:"font-weight-bold primaryText text-truncate"},[_vm._v(" "+_vm._s(_vm.item.node.serialNumber || '    -')+" ")])])]),_c(VCol,{staticClass:"align-center justify-start pa-1",style:({
                            'flex-basis': _vm.$vuetify.breakpoint.lgAndUp ? '14.2%' : '',
                            'max-width': _vm.$vuetify.breakpoint.lgAndUp ? '14.2%' : ''
                        }),attrs:{"cols":"12","xl":"auto","lg":"auto","md":"3","sm":"6","xs":"12"}},[_c('div',{staticClass:"mt-1 textLeft"},[_c('div',{staticClass:"font-weight-thin secondaryText text-truncate"},[_vm._v(" "+_vm._s(_vm.$t("headers.trip_number"))+" ")]),_c('div',{staticClass:"font-weight-bold primaryText text-truncate"},[_vm._v(" "+_vm._s(_vm.item.node.tripReferenceNumber || '    -')+" ")])])]),_c(VCol,{staticClass:"align-center justify-start pa-1",style:({
                            'flex-basis': _vm.$vuetify.breakpoint.lgAndUp ? '14.2%' : '',
                            'max-width': _vm.$vuetify.breakpoint.lgAndUp ? '14.2%' : ''
                        }),attrs:{"cols":"12","xl":"auto","lg":"auto","md":"3","sm":"6","xs":"12"}},[_c('div',{staticClass:"mt-1 textLeft"},[_c('div',{staticClass:"font-weight-thin secondaryText text-truncate"},[_vm._v(" "+_vm._s(_vm.$t("headers.brand"))+" ")]),_c('div',{staticClass:"font-weight-bold primaryText text-truncate"},[_vm._v(" "+_vm._s(_vm.item.node.brand || '    -')+" ")])])]),_c(VCol,{staticClass:"align-center justify-start pa-1",style:({
                            'flex-basis': _vm.$vuetify.breakpoint.lgAndUp ? '14.2%' : '',
                            'max-width': _vm.$vuetify.breakpoint.lgAndUp ? '14.2%' : ''
                        }),attrs:{"cols":"12","xl":"auto","lg":"auto","md":"3","sm":"6","xs":"12"}},[_c('div',{staticClass:"mt-1 textLeft"},[_c('div',{staticClass:"font-weight-thin secondaryText text-truncate"},[_vm._v(" "+_vm._s(_vm.$t("headers.activation"))+" ")]),_c('div',{staticClass:"font-weight-bold primaryText text-truncate"},[_vm._v(" "+_vm._s(_vm.formatDateTime(_vm.item.node.activatedDate) || '    -')+" ")])])]),_c(VCol,{staticClass:"align-center justify-start pa-1",style:({
                            'flex-basis': _vm.$vuetify.breakpoint.lgAndUp ? '14.2%' : '',
                            'max-width': _vm.$vuetify.breakpoint.lgAndUp ? '14.2%' : ''
                        }),attrs:{"cols":"12","xl":"auto","lg":"auto","md":"3","sm":"6","xs":"12"}},[_c('div',{staticClass:"mt-1 textLeft"},[_c('div',{staticClass:"font-weight-thin secondaryText text-truncate"},[_vm._v(" "+_vm._s(_vm.$t("headers.expiration"))+" ")]),_c('div',{staticClass:"font-weight-bold primaryText text-truncate"},[_vm._v(" "+_vm._s(_vm.formatDateTime(_vm.item.node.expiryDate) || '    -')+" ")])])]),_c(VCol,{staticClass:"align-center justify-start pa-1",style:({
                            'flex-basis': _vm.$vuetify.breakpoint.lgAndUp ? '14.2%' : '',
                            'max-width': _vm.$vuetify.breakpoint.lgAndUp ? '14.2%' : ''
                        }),attrs:{"cols":"12","xl":"auto","lg":"auto","md":"3","sm":"6","xs":"12"}},[_c('div',{staticClass:"mt-1 textLeft"},[_c('div',{staticClass:"font-weight-thin secondaryText text-truncate"},[_vm._v(" "+_vm._s(_vm.$t("headers.last_date"))+" ")]),_c('div',{staticClass:"font-weight-bold primaryText text-truncate"},[_vm._v(" "+_vm._s(_vm.formatDateTime(_vm.item.node.lastConnectionTime) || '    -')+" ")])])]),_c(VCol,{staticClass:"align-center justify-start pa-1",style:({
                            'flex-basis': _vm.$vuetify.breakpoint.lgAndUp ? '14.2%' : '',
                            'max-width': _vm.$vuetify.breakpoint.lgAndUp ? '14.2%' : ''
                        }),attrs:{"cols":"12","xl":"auto","lg":"auto","md":"3","sm":"6","xs":"12"}},[_c('div',{staticClass:"mt-1 textLeft"},[_c('div',{staticClass:"font-weight-thin secondaryText text-truncate"},[_vm._v(" "+_vm._s(_vm.$t("headers.stock_location"))+" ")]),_c('div',{staticClass:"font-weight-bold primaryText text-truncate"},[_vm._v(" "+_vm._s(_vm.item.node.stockLocation ? _vm.item.node.stockLocation.name : '    -')+" ")])])])],1)],1)],1)],1)])}
var staticRenderFns = []

export { render, staticRenderFns }