<template>
    <!--single date select-->
    <div>
        <!--multiple dates select-->
        <v-menu
            ref="menu"
            v-model="menu"
            :close-on-content-click="false"
            transition="scale-transition"
            offset-y
            min-width="auto"
        >
            <template v-slot:activator="{ on, attrs }">
                <v-text-field
                    v-model="showTheDate"
                    class="
                    font-weight-regular body-2 
                    flex-wrap 
                    font-weight-medium 
                    included
                "
                    :clearable="clearField"
                    @click:clear="onClear"
                    v-on:input="$emit('input', $event)"
                    @click="clearCache"
                    :label="label"
                    v-on="on"
                    v-bind="attrs"
                    :dense="dense"
                    :outlined="outlined"
                    :rules="rules"
                    :disabled="disabled"
                    :readonly="readOnly"
                    @blur="date = parseDate(localValue)"
                    style="width:235px;"
                    :filled="filled"
                    :prepend-inner-icon="icon"
                    :hide-details="hideDetails"
                >
                </v-text-field>
            </template>
            <v-form
                ref="datePickerRef"
                v-click-outside="{
                    handler: clickOutside,
                    include: include
                }"
            >
                <v-date-picker
                    ref="datePickerRef"
                    v-model="localValue"
                    @change="onChangeDate"
                    aria-placeholder="Sales date"
                    range
                    no-title
                    :pickerDate.sync="pickerDate"
                ></v-date-picker>
            </v-form>
        </v-menu>
    </div>
</template>
<script>
import moment from "moment";
import helper from "@/utils/helper";

export default {
    name: "date-component",
    props: {
        value: {
            type: Array,
            default: () => {
                return [];
            }
        },
        disabled: {
            type: Boolean,
            default: false
        },
        filled: {
            type: Boolean,
            default: false
        },
        dense: {
            type: Boolean,
            default: false
        },
        outlined: {
            type: Boolean,
            default: false
        },
        label: String,
        icon: String,
        rules: Array,
        show: {
            type: Boolean,
            default: true
        },
        hideDetails: {
            type: Boolean,
            default: true
        },
        clearField: {
            type: Boolean,
            default: true
        },
        alertMessage: {
            type: Number,
            default: null
        },
        readOnly: {
            type: Boolean,
            default: false
        },
        iconToolTip: {
            type: String,
            default: null
        }
    },
    data: function() {
        return {
            menu: false,
            date: null,
            range: true,
            pickerDate: null,
            localValue: []
        };
    },
    computed: {
        showTheDate: {
            get() {
                if (this.localValue.length > 1) {
                    let date1 = moment(
                        this.localValue[0],
                        "YYYY-MM-DD"
                    ).format(helper.getDateFormat());
                    let date2 = moment(
                        this.localValue[1],
                        "YYYY-MM-DD"
                    ).format(helper.getDateFormat());
                    return date1 + " ~ " + date2;
                } else if (this.localValue.length === 1) {
                    return moment(this.localValue[0], "YYYY-MM-DD").format(
                        helper.getDateFormat()
                    );
                } else {
                    return "";
                }
            },
            set(value) {
                if (value === null) return "";
                return value;
            }
        }
    },
    mounted() {
        this.localValue = this.value;
    },
    watch: {
        showTheDate(v) {
            if (v === null) {
                const today = new Date().toISOString().split("T")[0];
                this.pickerDate = today;
            }
        }
    },
    methods: {
        clickOutside() {
            /*If the trader click one time in date and click outside send a array
                of size one
                */
            if (this.$refs.datePickerRef?.validate() && this.menu) {
                this.$emit("input", this.localValue);
            }
        },
        clearCache() {
            //clear the cache in child and father side
            this.localValue = [];
            this.$emit("input", this.localValue);
        },
        onClear() {
            // clear the cache on father side
            this.localValue = [];
            this.$emit("input", this.localValue);
        },
        include() {
            // Call the value in the include class
            return [document.querySelector(".included")];
        },
        onChangeDate() {
            if (Array.isArray(this.localValue)) {
                /*Check if the values of date range is diferent and if this true
                organize the date range in order
                */
                if (
                    this.localValue.length > 1 &&
                    !(this.localValue[0] === this.localValue[1])
                ) {
                    this.localValue.sort((a, b) => {
                        let dateA = new Date(a),
                            dateB = new Date(b);
                        if (dateB > dateA) {
                            return -1;
                        } else {
                            return 1;
                        }
                    });
                } else {
                    /* If the trader click two times in the same date value this
                        will send a array of size one.
                    */
                    this.localValue.pop(this.localValue[1]);
                }
            }
        },
        parseDate(date) {
            if (date) {
                if (date.length > 0) return [];
                return null;
            }
            // const [year, month, day] = date.split("-");
            // return `${year}-${month?.padStart(2, "0")}-${day?.padStart(
            //     2,
            //     "0"
            // )}`;
        }
    }
};
</script>
