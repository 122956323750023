import { VCard } from 'vuetify/lib/components/VCard';
import { VCol } from 'vuetify/lib/components/VGrid';
import { VRow } from 'vuetify/lib/components/VGrid';
import { VTooltip } from 'vuetify/lib/components/VTooltip';

var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',{staticClass:"d-flex flex-row"},[_c(VCard,{staticClass:"flex-grow-0",class:_vm.mobileView.classCard,attrs:{"width":"30","color":"transparent","flat":"","tile":""}},[[_vm._t("prepend-action")]],2),_c(VRow,{staticClass:"flex-grow-1 ma-0",attrs:{"dense":""}},[(_vm.headerText)?_c(VCol,{staticClass:"subtitle-2 text-uppercase pa-2",attrs:{"cols":"12","md":"12"}},[_c(VRow,{attrs:{"dense":""}},[_c(VCol,{staticClass:"px-2",attrs:{"cols":"12","md":"12"}},[_vm._v(_vm._s(_vm.headerText))])],1)],1):_vm._e(),(!_vm.useTableLayout)?_c(VCol,{staticClass:"pa-2",attrs:{"cols":"12","md":"12"}},[_c(VRow,{staticClass:"py-1",attrs:{"dense":""}},_vm._l((_vm.items),function(item,idxc){return _c(VCol,{key:idxc,staticClass:"px-2",style:(!(_vm.screenWidth < 1295) ? 'flex-basis: 14.2%; max-width: 14.2%;' : ''),attrs:{"cols":item.col.xs || 12,"sm":item.col.sm,"md":item.col.md,"lg":item.col.lg,"xl":item.col.xl}},[(item.title)?_c('div',{staticClass:"secondaryText font-weight-thin text-uppercase"},[_vm._v(" "+_vm._s(item.title)+" ")]):_vm._e(),_c('div',{staticClass:"text-truncate"},[_c('div',{style:(item.style)},[_c('div',{staticClass:"text-uppercase user-select-text"},[(item.value)?_c('div',{on:{"click":function($event){$event.stopPropagation();$event.preventDefault();}}},[(item.value.length < 20)?[_c('span',{staticClass:"primaryText text-uppercase font-weight-bold user-select-text"},[_vm._v(" "+_vm._s(item.value)+" ")])]:_c(VTooltip,{attrs:{"bottom":""},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
var on = ref.on;
var attrs = ref.attrs;
return [_c('span',_vm._g(_vm._b({staticClass:"primaryText text-uppercase font-weight-bold"},'span',attrs,false),on),[_vm._v(" "+_vm._s(item.value)+" ")])]}}],null,true)},[_c('span',{staticClass:"class-text-tooltip user-select-text"},[_vm._v(" "+_vm._s(item.value)+" ")])])],2):_vm._e()])])])])}),1)],1):_c(VCol,{staticClass:"pa-2",attrs:{"cols":"12","md":"12"}},[(_vm.screenWidth > 1295)?_c(VRow,{staticClass:"pt-1 mb-0",attrs:{"dense":""}},_vm._l((_vm.freightHeaders(_vm.transportMode)),function(item,id){return _c(VCol,{key:id,staticClass:"px-2",style:(!(_vm.screenWidth < 1295)
                            ? 'flex-basis: 14.2%; max-width: 14.2%;'
                            : ''),attrs:{"cols":item.col.xs || 12,"sm":item.col.sm,"md":item.col.md,"lg":item.col.lg,"xl":item.col.xl}},[_c('div',{staticClass:"secondaryText font-weight-thin text-uppercase"},[_vm._v(" "+_vm._s(item.title)+" ")])])}),1):_vm._e(),_vm._l((_vm.items),function(freight,ids){return _c(VRow,{key:ids,staticClass:"py-0 mt-0",attrs:{"dense":""}},_vm._l((freight),function(item,idx){return _c(VCol,{key:idx,staticClass:"px-2",style:(!(_vm.screenWidth < 1295)
                            ? 'flex-basis: 14.2%; max-width: 14.2%;'
                            : ''),attrs:{"cols":item.col.xs || 12,"sm":item.col.sm,"md":item.col.md,"lg":item.col.lg,"xl":item.col.xl}},[(item.title && _vm.screenWidth < 1295)?_c('div',{staticClass:"secondaryText font-weight-thin text-uppercase"},[_vm._v(" "+_vm._s(item.title)+" ")]):_vm._e(),_c('div',{staticClass:"text-truncate"},[_c('div',{style:(item.style)},[(item.value)?_c('div',{on:{"click":function($event){$event.stopPropagation();$event.preventDefault();}}},[(item.value.length < 20)?[_c('span',{staticClass:"primaryText text-uppercase font-weight-bold user-select-text"},[_vm._v(" "+_vm._s(item.value)+" ")])]:_c(VTooltip,{attrs:{"bottom":""},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
                                        var on = ref.on;
                                        var attrs = ref.attrs;
return [_c('span',_vm._g(_vm._b({staticClass:"primaryText text-uppercase font-weight-bold"},'span',attrs,false),on),[_vm._v(" "+_vm._s(item.value)+" ")])]}}],null,true)},[_c('span',{staticClass:"class-text-tooltip user-select-text"},[_vm._v(" "+_vm._s(item.value)+" ")])])],2):_vm._e()])])])}),1)})],2)],1)],1)}
var staticRenderFns = []

export { render, staticRenderFns }