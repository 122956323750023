<template>
    <v-card elevation="5" outlined v-resize="onResize">
        <v-card-text class="pb-3 pr-3">
            <div class="d-flex justify-end">
                <div>
                    <v-btn icon>
                        <v-icon
                            @mouseenter="startScrolling('left')"
                            @mouseleave="stopScrolling"
                            >arrow_back</v-icon
                        >
                    </v-btn>
                    <v-btn icon>
                        <v-icon
                            @mouseenter="startScrolling('right')"
                            @mouseleave="stopScrolling"
                            >arrow_forward</v-icon
                        >
                    </v-btn>
                </div>
                <!-- filter menu -->
                <FilterMenu
                    :title="$t('dashboard.customize')"
                    :description="$t('dashboard.display')"
                    :choices="headerChoices"
                    :open-on-hover="false"
                    height="500px"
                    width="250px"
                    v-on:changed="onFilterMenuChanged"
                >
                </FilterMenu>
            </div>
            <v-data-table
                :headers="dynamicHeaders"
                :items="trips.edges"
                item-key="node.id"
                :loading-text="$t('loading.loading1')"
                :loading="$apollo.queries.trips.loading"
                hide-default-footer
                disable-pagination
                disable-sort
                loader-height="2"
                :mobile-breakpoint="
                    screenSize === 'xs' || screenSize === 'sm' ? 800 : 0
                "
                :no-data-text="$t('loading.no_data')"
                fixed-header
                :height="tableHeight"
                @click:row="item => this.$emit('click:row', item)"
                class="row-pointer"
            >
                <template v-slot:loading>
                    <tr >
                        <td
                            :colspan="2"
                            :style="centralizeLoadBtnClass"
                        >
                            <div>
                                {{ $t('loading.loading1') }}
                            </div>
                        </td>
                    </tr>
                </template>
                <!-- is pinned -->
                <template v-slot:item.isFavorite="{ item, index }">
                    <!-- <v-icon small v-if="item.node.isUserPinned">push_pin</v-icon> -->
                    <v-icon
                        small
                        v-if="item.node.isUserFavorite"
                        color="warning"
                    >
                        star
                    </v-icon>
                </template>

                <!-- alerts -->
                <template v-slot:item.node.numberOfUnresolvedAlerts="{ item }">
                    <span>
                        <v-row dense class="mx-0 px-0">
                            <v-icon
                                v-if="item.node.numberOfUnresolvedAlerts > 0"
                                class="mr-2"
                                color="warning"
                                small
                            >
                                warning
                            </v-icon>
                            {{ item.node.numberOfUnresolvedAlerts }}
                        </v-row>
                    </span>
                </template>

                <!-- containers -->
                <template v-slot:item.containers="{ item }">
                    <div>
                        <v-chip
                            v-for="freight in item.node.tripFreightSet.edges.filter(
                                item => item.node.containerNumber
                            )"
                            :key="freight.node.id"
                            class="mr-1 my-1"
                            small
                            @click.stop="
                                goToFreight(freight.node.id, item.node.id)
                            "
                        >
                            {{ freight.node.containerNumber }}
                        </v-chip>
                    </div>
                </template>

                <!-- airwaybill -->
                <template v-slot:item.airwaybill="{ item }">
                    <div>
                        <v-chip
                            v-for="freight in item.node.tripFreightSet.edges.filter(
                                item => item.node.airWaybillNumber
                            )"
                            :key="freight.node.id"
                            class="mr-1 my-1"
                            small
                            @click.stop="
                                goToFreight(freight.node.id, item.node.id)
                            "
                            >
                                <div v-if="freight.node.airWaybillNumber">
                                    {{ formatAirwaybillNumber(freight.node.airWaybillNumber)}} | {{ freight.node.airWaybillNumber }} 
                                </div>
                                <div v-else></div>
                            </v-chip
                        >
                    </div>
                </template>

                <!-- trackers -->
                <template v-slot:item.trackers="{ item }">
                    <div
                        v-for="element in item.node.tripFreightSet.edges"
                        :key="element.node.id"
                    >
                        <v-tooltip
                            bottom
                            v-for="freightTracker in element.node
                                .tripFreightTrackerSet.edges"
                            :key="freightTracker.node.id"
                        >
                            <template v-slot:activator="{ on, attrs }">
                                <v-chip
                                    v-bind="attrs"
                                    v-on="on"
                                    class="mr-1 my-1"
                                    :color="
                                        freightTracker.node.tracker
                                            .activatedDate
                                            ? 'primary'
                                            : ''
                                    "
                                    @click.stop="
                                        goToFreight(
                                            element.node.id,
                                            item.node.id
                                        )
                                    "
                                    small
                                    outlined
                                >
                                    {{
                                        freightTracker.node.tracker.serialNumber
                                    }}
                                </v-chip>
                            </template>
                            <span>
                                {{
                                    freightTracker.node.tracker.activatedDate
                                        ? `${$t("general.activated")} ${$t(
                                              "general.on"
                                          )} ${formatDateTime(
                                              freightTracker.node.tracker
                                                  .activatedDate
                                          )}`
                                        : $t("general.not_activated")
                                }}
                            </span>
                        </v-tooltip>
                        <v-tooltip
                            bottom
                            v-if="
                                getExternalTrackerAdditionalInfo(
                                    element.node.additionalInfo
                                )
                            "
                        >
                            <template v-slot:activator="{ on, attrs }">
                                <v-chip
                                    v-bind="attrs"
                                    v-on="on"
                                    class="mr-1 my-1"
                                    small
                                    outlined
                                >
                                    {{
                                        getExternalTrackerAdditionalInfo(
                                            element.node.additionalInfo
                                        ).serialNumber
                                    }}
                                </v-chip>
                            </template>
                            <span>
                                {{
                                    getExternalTrackerAdditionalInfo(
                                        element.node.additionalInfo
                                    ).type
                                }}
                            </span>
                        </v-tooltip>
                        <!-- <v-tooltip
                            bottom
                            v-if="
                                getExternalTrackerAdditionalInfo(
                                    element.node.additionalInfo
                                )
                            "
                        >
                            <template v-slot:activator="{ on, attrs }">
                                <v-chip
                                    v-bind="attrs"
                                    v-on="on"
                                    class="mr-1 my-1"
                                    small
                                    outlined
                                >
                                    {{
                                        getExternalTrackerAdditionalInfo(
                                            element.node.additionalInfo
                                        ).serialNumber
                                    }}
                                </v-chip>
                            </template>
                            <span>
                                {{
                                    getExternalTrackerAdditionalInfo(
                                        element.node.additionalInfo
                                    ).type
                                }}
                            </span>
                        </v-tooltip> -->
                    </div>
                </template>

                <!-- Planned Departure -->
                <template v-slot:item.node.plannedDepartureDate="{ item }">
                    <span>{{
                        formatDateTime(item.node.plannedDepartureDate)
                    }}</span>
                </template>

                <!-- Planned Arrival -->
                <template v-slot:item.node.plannedArrivalDate="{ item }">
                    <span>{{
                        formatDateTime(item.node.plannedArrivalDate)
                    }}</span>
                </template>

                <!-- Vessel -->
                <template v-slot:item.node.vessel="{ item }">
                    <v-chip
                        v-for="vessel in getAllVessels(
                            item.node.tripSeaTransportSet
                        )"
                        :key="vessel.id"
                        class="mr-1 my-1"
                        small
                        >{{ vessel.name }}</v-chip
                    >
                </template>

                <!-- POD ETA -->
                <template v-slot:item.node.polEtd="{ item }">
                    <v-tooltip bottom>
                        <template v-slot:activator="{ on, attrs }">
                            <span
                            v-bind="attrs"
                            v-on="on">
                                <v-row no-gutters>
                                    <v-col>
                                        {{
                                            getPolEtd(item.node.tripSeaTransportSet)
                                                .date
                                        }}
                                    </v-col>
                                    <v-col>
                                        <div :class="getPolEtd(item.node.tripSeaTransportSet).color">
                                            <span>
                                                {{getPolEtd(item.node.tripSeaTransportSet).text}}
                                            </span>
                                        </div>
                                    </v-col>
                                </v-row>
                            </span>
                        </template>
                        <span>{{getPolEtd(item.node.tripSeaTransportSet).original}}</span>
                    </v-tooltip>
                </template>

                <!-- POL ETD -->
                <template v-slot:item.node.podEta="{ item }">
                    <v-tooltip bottom>
                        <template v-slot:activator="{ on, attrs }">
                            <span
                            v-bind="attrs"
                            v-on="on">
                                <v-row no-gutters>
                                    <v-col>
                                        {{
                                            getPodEta(item.node.tripSeaTransportSet)
                                                .date
                                        }}
                                    </v-col>
                                    <v-col>
                                        <div :class="getPodEta(item.node.tripSeaTransportSet).color">
                                            <span>
                                                {{ getPodEta(item.node.tripSeaTransportSet).text }}
                                            </span>
                                        </div>
                                    </v-col>
                                </v-row>
                            </span>
                        </template>
                        <span>{{getPodEta(item.node.tripSeaTransportSet).original}}</span>
                    </v-tooltip>
                </template>

                <!-- partners -->
                <!-- <template v-slot:item.partners="{ item }">
                    <div
                        v-for="partner in item.node.tripPartnerSet.edges"
                        :key="partner.node.id"
                    >
                        <span class="text-no-wrap">
                            {{ partner.node.tenantPartner.partnerType }}:
                            <v-chip class="mr-1 my-1" small>
                                {{ partner.node.tenantPartner.name }}
                            </v-chip>
                        </span>
                    </div>
                </template> -->
                <!-- consignors -->
                <template v-slot:item.consignor="{ item }">
                    <div
                        v-for="partner in item.node.tripPartnerSet.edges"
                        :key="partner.node.id"
                    >
                        <span
                            class="text-no-wrap"
                            v-if="
                                partner.node.tenantPartner.partnerType ===
                                    'consignor'
                            "
                        >
                            <v-chip class="mr-1 my-1" small>
                                {{ partner.node.tenantPartner.name }}
                            </v-chip>
                        </span>
                    </div>
                </template>

                <!-- Last Connection Time -->
                <template v-slot:item.node.trackerLastConnectionTime="{ item }">
                    <span v-if="item.node.trackerLastConnectionTime">{{
                        formatDateTime(item.node.trackerLastConnectionTime)
                    }}</span>
                </template>

                <!-- Last Location -->
                <template v-slot:item.node.trackerLastLocation="{ item }">
                    <v-chip v-if="item.node.trackerLastLocation" small>{{
                        item.node.trackerLastLocation
                    }}</v-chip>
                    <v-chip
                        small
                        v-if="item.node.trackerLastTemperatureValue"
                        class="ml-3"
                        ><b
                            >{{
                                formatTemperature(
                                    item.node.trackerLastTemperatureValue
                                ).toFixed(0)
                            }}
                            {{ getTemperatureSymbol() }}</b
                        ></v-chip
                    >
                </template>

                <!-- consignees -->
                <template v-slot:item.consignee="{ item }">
                    <div
                        v-for="partner in item.node.tripPartnerSet.edges"
                        :key="partner.node.id"
                    >
                        <span
                            class="text-no-wrap"
                            v-if="
                                partner.node.tenantPartner.partnerType ===
                                    'consignee'
                            "
                        >
                            <v-chip class="mr-1 my-1" small>
                                {{ partner.node.tenantPartner.name }}
                            </v-chip>
                        </span>
                    </div>
                </template>

                <!-- forwarders -->
                <template v-slot:item.forwarder="{ item }">
                    <div
                        v-for="partner in item.node.tripPartnerSet.edges"
                        :key="partner.node.id"
                    >
                        <span
                            class="text-no-wrap"
                            v-if="
                                partner.node.tenantPartner.partnerType ===
                                    'forwarder'
                            "
                        >
                            <v-chip class="mr-1 my-1" small>
                                {{ partner.node.tenantPartner.name }}
                            </v-chip>
                        </span>
                    </div>
                </template>

                <!-- carriers -->
                <template v-slot:item.carrier="{ item }">
                    <div v-if="item.node.tenantCarrier">
                        <v-chip class="mr-1 my-1" small>
                            {{ item.node.tenantCarrier.name }}
                        </v-chip>
                    </div>
                </template>

                <!-- others -->
                <template v-slot:item.other="{ item }">
                    <div
                        v-for="partner in item.node.tripPartnerSet.edges"
                        :key="partner.node.id"
                    >
                        <span
                            class="text-no-wrap"
                            v-if="
                                partner.node.tenantPartner.partnerType ===
                                    'other'
                            "
                        >
                            <v-chip class="mr-1 my-1" small>
                                {{ partner.node.tenantPartner.name }}
                            </v-chip>
                        </span>
                    </div>
                </template>
                <template v-slot:item.POL="{ item }">
                    <div
                        v-for="e in item.node.tripSeaTransportSet.edges"
                        :key="e.node.id"
                    >
                        <span
                            class="text-no-wrap"
                            v-if="e.node.portTransportType === 'POL'"
                        >
                            <v-chip
                                v-if="
                                    e.node.portCode
                                        ? e.node.portCode.length > 0
                                        : false
                                "
                                class="mr-1 my-1"
                                small
                            >
                                {{ e.node.portCode }}
                            </v-chip>
                        </span>
                    </div>
                </template>
                <template v-slot:item.transshipment="{ item }">
                    <div
                        v-for="e in item.node.tripSeaTransportSet.edges"
                        :key="e.node.id"
                    >
                        <span
                            class="text-no-wrap"
                            v-if="
                                e.node.portTransportType === 'Transshipment' ||
                                    e.node.portTransportType === 'T/S'
                            "
                        >
                            <v-chip
                                v-if="
                                    e.node.portCode
                                        ? e.node.portCode.length > 0
                                        : false
                                "
                                class="mr-1 my-1"
                                small
                            >
                                {{ e.node.portCode }}
                            </v-chip>
                        </span>
                    </div>
                </template>
                <template v-slot:item.POD="{ item }">
                    <div
                        v-for="e in item.node.tripSeaTransportSet.edges"
                        :key="e.node.id"
                    >
                        <span
                            class="text-no-wrap"
                            v-if="e.node.portTransportType === 'POD'"
                        >
                            <v-chip
                                v-if="
                                    e.node.portCode
                                        ? e.node.portCode.length > 0
                                        : false
                                "
                                class="mr-1 my-1"
                                small
                            >
                                {{ e.node.portCode }}
                            </v-chip>
                        </span>
                    </div>
                </template>

                <!-- freights -->
                <template v-slot:item.freights="{ item }">
                    <div>
                        <v-chip
                            v-for="freight in item.node.tripFreightSet.edges.filter(
                                item => item.node.referenceNumber
                            )"
                            :key="freight.node.id"
                            class="mr-1 my-1"
                            small
                            @click.stop="
                                goToFreight(freight.node.id, item.node.id)
                            "
                        >
                            {{ freight.node.referenceNumber }}
                        </v-chip>
                    </div>
                </template>

                <!-- goods -->
                <template v-slot:item.goods="{ item }">
                    <div>
                        <v-chip
                            v-for="goods in getAllGoods(
                                item.node.tripFreightSet
                            )"
                            :key="goods"
                            class="mr-1 my-1"
                            small
                        >
                            {{ goods }}
                        </v-chip>
                    </div>
                </template>

                <!-- infinit loading trigger -->
                <template slot="body.append">
                    <tr >
                        <td
                            :colspan="2"
                            :style="centralizeLoadBtnClass"
                        >
                            <v-btn
                                v-if="hasMoreData"
                                v-intersect="onLoadMoreTriggerIntersect"
                                :disabled="!hasMoreData"
                                :loading="$apollo.queries.trips.loading"
                                plain
                                class="mb-5"
                                @click="loadMore"
                            >
                                {{ $t("loading.load_more") }}
                            </v-btn>
                        </td>
                    </tr>
                </template>
            </v-data-table>
        </v-card-text>

        <!-- infinit loading trigger -->
        <!-- <v-card-actions class="justify-center">
            <v-btn
                v-if="hasMoreData"
                v-intersect="onLoadMoreTriggerIntersect"
                :disabled="!hasMoreData"
                :loading="$apollo.queries.trips.loading"
                plain
                class="mb-5"
                @click="loadMore"
            >
                Load more
            </v-btn>
        </v-card-actions> -->
    </v-card>
</template>

<script>
import gql from "graphql-tag";
import FilterMenu from "@/components/base/FilterMenu.vue";
import helper from "../../utils/helper";

const GET_TRIPS = gql`
    query trips(
        $first: Int
        $last: Int
        $before: String
        $after: String
        $referenceNumber_Icontains: String
        $tripFreightSet_ContainerNumber_Icontains: String
        $tripFreightSet_AirWaybillNumber_Icontains: String
        $tripFreightTrackerSet_Tracker_SerialNumber_Icontains: String
        $tenantPartnerId: ID
        $status_In: [String]
        $plannedDepartureDate_Gte: DateTime
        $plannedDepartureDate_Lte: DateTime
        $plannedArrivalDate_Gte: DateTime
        $plannedArrivalDate_Lte: DateTime
        $orderBy: String
        $pol: String
        $transshipment: String
        $transportMode_In: [String]
        $pod: String
        $alertCode: [String]
        $tripAlertSetIsResolved: Boolean
        $tenantCarrier: ID
        $consignor: [String]
        $consignee: [String]
        $forwarder: [String]
        $goods: String
        $isFavorite: Boolean
        $tripFreightSet_ReferenceNumber_Icontains: String
        $polvessel: ID
        $tsvessel: ID
        $trackerLastLocation_Icontains: String
        $alertTag: String
        $trade_Icontains: String
    ) {
        trips(
            first: $first
            last: $last
            before: $before
            after: $after
            referenceNumber_Icontains: $referenceNumber_Icontains
            tripFreightSet_ContainerNumber_Icontains: $tripFreightSet_ContainerNumber_Icontains
            tripFreightSet_AirWaybillNumber_Icontains: $tripFreightSet_AirWaybillNumber_Icontains
            tripFreightTrackerSet_Tracker_SerialNumber_Icontains: $tripFreightTrackerSet_Tracker_SerialNumber_Icontains
            tripPartnerSet_TenantPartner: $tenantPartnerId
            status_In: $status_In
            plannedDepartureDate_Gte: $plannedDepartureDate_Gte
            plannedDepartureDate_Lte: $plannedDepartureDate_Lte
            plannedArrivalDate_Gte: $plannedArrivalDate_Gte
            plannedArrivalDate_Lte: $plannedArrivalDate_Lte
            orderBy: $orderBy
            pol: $pol
            transshipment: $transshipment
            transportMode_In: $transportMode_In
            alertCode: $alertCode
            tripAlertSet_IsResolved: $tripAlertSetIsResolved
            pod: $pod
            tenantCarrier: $tenantCarrier
            consignor: $consignor
            consignee: $consignee
            forwarder: $forwarder
            goods: $goods
            tripFreightSet_ReferenceNumber_Icontains: $tripFreightSet_ReferenceNumber_Icontains
            isFavorite: $isFavorite
            polvessel: $polvessel
            tsvessel: $tsvessel
            trackerLastLocation_Icontains: $trackerLastLocation_Icontains
            alertTag: $alertTag
            trade_Icontains: $trade_Icontains
        ) {
            pageInfo {
                startCursor
                endCursor
                hasPreviousPage
                hasNextPage
            }
            edges {
                node {
                    id
                    createdAt
                    tenantCarrier {
                        id
                        name
                    }
                    originLocation {
                        id
                        name
                        city
                        country
                    }
                    destinationLocation {
                        id
                        name
                        city
                        country
                    }
                    referenceNumber
                    transportMode
                    plannedDepartureDate
                    plannedArrivalDate
                    status
                    isArchived
                    isPrivate
                    numberOfFreights
                    numberOfPartners
                    numberOfDocuments
                    numberOfComments
                    numberOfUnresolvedAlerts
                    trackerLastConnectionTime
                    trackerLastLocation
                    trackerLastTemperatureValue
                    tripPartnerSet {
                        edges {
                            node {
                                id
                                tenantPartner {
                                    id
                                    name
                                    partnerType
                                }
                            }
                        }
                    }
                    tripFreightSet {
                        edges {
                            node {
                                id
                                referenceNumber
                                containerNumber
                                airWaybillNumber
                                billOfLadingNumber
                                goods
                                additionalInfo
                                tripFreightTrackerSet {
                                    edges {
                                        node {
                                            id
                                            tracker {
                                                id
                                                serialNumber
                                                activatedDate
                                                
                                            }
                                        }
                                    }
                                }
                            }
                        }
                    }
                    tripSeaTransportSet(orderBy: "sequence") {
                        edges {
                            node {
                                id
                                sequence
                                portCode
                                portTransportType
                                portName
                                vessel {
                                    id
                                    name
                                }
                                estimatedArrivalDate
                                originalEstimatedArrivalDate
                                actualArrivalDate
                                originalEstimatedDepartureDate
                                estimatedDepartureDate
                                actualDepartureDate
                            }
                        }
                    }
                    isUserFavorite
                }
            }
        }
    }
`;

const GET_SHARED_TRIPS = gql`
    query trips(
        $first: Int
        $last: Int
        $before: String
        $after: String
        $referenceNumber_Icontains: String
        $tripFreightSet_ContainerNumber_Icontains: String
        $tripFreightSet_AirWaybillNumber_Icontains: String
        $tripFreightTrackerSet_Tracker_SerialNumber_Icontains: String
        $tenantPartnerId: ID
        $status_In: [String]
        $plannedDepartureDate_Gte: DateTime
        $plannedDepartureDate_Lte: DateTime
        $plannedArrivalDate_Gte: DateTime
        $plannedArrivalDate_Lte: DateTime
        $orderBy: String
        $pol: String
        $transshipment: String
        $transportMode_In: [String]
        $pod: String
        $alertCode: [String]
        $tripAlertSetIsResolved: Boolean
        $tenantCarrier: ID
        $consignor: [String]
        $consignee: [String]
        $forwarder: [String]
        $goods: String
        $isFavorite: Boolean
        $tripFreightSet_ReferenceNumber_Icontains: String
        $polvessel: ID
        $tsvessel: ID
        $trackerLastLocation_Icontains: String
        $tenant: String
        $sharedtrips: String
        $userFavoriteTripSetIdIsnull: Boolean
        $userId: ID
        $alertTag: String
        $trade_Icontains: String
    ) {
        trips(
            first: $first
            last: $last
            before: $before
            after: $after
            referenceNumber_Icontains: $referenceNumber_Icontains
            tripFreightSet_ContainerNumber_Icontains: $tripFreightSet_ContainerNumber_Icontains
            tripFreightSet_AirWaybillNumber_Icontains: $tripFreightSet_AirWaybillNumber_Icontains
            tripFreightTrackerSet_Tracker_SerialNumber_Icontains: $tripFreightTrackerSet_Tracker_SerialNumber_Icontains
            tripPartnerSet_TenantPartner: $tenantPartnerId
            status_In: $status_In
            plannedDepartureDate_Gte: $plannedDepartureDate_Gte
            plannedDepartureDate_Lte: $plannedDepartureDate_Lte
            plannedArrivalDate_Gte: $plannedArrivalDate_Gte
            plannedArrivalDate_Lte: $plannedArrivalDate_Lte
            orderBy: $orderBy
            pol: $pol
            transshipment: $transshipment
            transportMode_In: $transportMode_In
            alertCode: $alertCode
            tripAlertSet_IsResolved: $tripAlertSetIsResolved
            pod: $pod
            tenantCarrier: $tenantCarrier
            consignor: $consignor
            consignee: $consignee
            forwarder: $forwarder
            goods: $goods
            tripFreightSet_ReferenceNumber_Icontains: $tripFreightSet_ReferenceNumber_Icontains
            isFavorite: $isFavorite
            polvessel: $polvessel
            tsvessel: $tsvessel
            trackerLastLocation_Icontains: $trackerLastLocation_Icontains
            tenant_Name_Icontains: $tenant
            sharedtrips: $sharedtrips
            userFavoriteTripSet_Id_Isnull: $userFavoriteTripSetIdIsnull
            alertTag: $alertTag
            trade_Icontains: $trade_Icontains
        ) {
            pageInfo {
                startCursor
                endCursor
                hasPreviousPage
                hasNextPage
            }
            edges {
                node {
                    id
                    createdAt
                    tenant {
                        id
                        name
                        organisationPermissions
                    }
                    tenantCarrier {
                        id
                        name
                    }
                    modifiedAt
                    referenceNumber
                    transportMode
                    plannedDepartureDate
                    plannedArrivalDate
                    status
                    isArchived
                    trade
                    logco
                    loadPoint
                    loadDate
                    numberOfFreights
                    numberOfPartners
                    numberOfDocuments
                    numberOfComments
                    numberOfExternalShares
                    numberOfUnresolvedAlerts
                    trackerLastConnectionTime
                    trackerLastLocation
                    trackerLastTemperatureValue
                    tripPartnerSet {
                        edges {
                            node {
                                id
                                tenantPartner {
                                    id
                                    name
                                    partnerType
                                    organisationPermissions
                                    tenantPartnerUserSet(user: $userId) {
                                        totalCount
                                    }
                                }
                            }
                        }
                    }
                    tripFreightSet {
                        edges {
                            node {
                                id
                                referenceNumber
                                containerNumber
                                airWaybillNumber
                                billOfLadingNumber
                                goods
                                additionalInfo
                                tripFreightTrackerSet {
                                    edges {
                                        node {
                                            id
                                            tracker {
                                                id
                                                serialNumber
                                                activatedDate
                                            }
                                        }
                                    }
                                }
                            }
                        }
                    }
                    tripSeaTransportSet(orderBy: "sequence") {
                        edges {
                            node {
                                id
                                sequence
                                portCode
                                portTransportType
                                vesselInputType
                                vesselInputValue
                                estimatedArrivalDate
                                originalEstimatedArrivalDate
                                actualArrivalDate
                                estimatedDepartureDate
                                originalEstimatedDepartureDate
                                actualDepartureDate
                                vessel {
                                    id
                                    name
                                }
                            }
                        }
                    }
                    isUserFavorite
                    isUserPinned
                }
            }
        }
    }
`;

export default {
    props: {
        tripFilter: {
            type: Object,
            required: false,
            default: () => {
                return {};
            }
        },
        isSharedTrip: {
            type: Boolean,
            required: false,
            default: false
        }
    },
    components: { FilterMenu },

    apollo: {
        trips: {
            query() {
                return this.isSharedTrip ? GET_SHARED_TRIPS : GET_TRIPS;
            },
            variables() {
                // IMPORTANT: add same variables as refetch and fetchMore, otherwise merge result may contain duplicate keys
                let request = {
                    ...this.tripFilter
                };
                if (this.isSharedTrip) {
                    request = {
                        ...this.tripFilter,
                        sharedtrips: "sharedtrips",
                        userId: this.me.id
                    };
                }
                if(request?.tripFreightSet_AirWaybillNumber_Icontains){
                    request.tripFreightSet_AirWaybillNumber_Icontains = this.removeHifenFromAirwaybill(request.tripFreightSet_AirWaybillNumber_Icontains);
                }
                return request;
            },
            // Additional options here
            fetchPolicy: "cache-and-network",
            nextFetchPolicy: "cache-first", // this setting can avoid query again after fetchMore
            update: data => data.trips,
            watchLoading(isLoading) {
                this.$emit("loading", isLoading);
                if (isLoading) {
                    this.loadHeaders();
                }
            },
            debounce: 800

            // skip() {
            //     // console.log(this.isTripsRoute)
            //     return !this.isTripsRoute;
            // }
            // pollInterval: 60000 // ms
        }
    },
    beforeCreate() {
        this.formatAirwaybillNumber = helper.formatAirwaybillNumber;
        this.removeHifenFromAirwaybill = helper.removeHifenFromAirwaybill;
    },
    created() {
        this.getTemperatureSymbol = helper.getTemperatureSymbol;
        this.calculateDelays = helper.calculateDelays;
        this.updateDateTimeTimezone = helper.updateDateTimeTimezone;
    },
    data() {
        return {
            shouldLoadMore: false,
            isInitialLoad: true,
            selectedHeaders: ["node.referenceNumber", "node.status"],
            trips: { edges: [] },
            tableHeight: 800,
            tableHeaderChoices: [],
            tableHeaderFilter: {
                referenceNumber: true,
                status: true,
                containers: true,
                alerts: true
            },
            headerChoices: [],
            defaultHeaderChoices: [
                {
                    // for filter menu
                    code: "node.referenceNumber",
                    name: "Reference Name",
                    tag: "headers.reference_number",
                    enabled: true,
                    // for table header
                    text: "Reference Name",
                    align: "left",
                    value: "node.referenceNumber"
                },
                {
                    // for filter menu
                    code: "node.transportMode",
                    name: "Transport Mode",
                    tag: "headers.transport_mode",
                    enabled: true,
                    // for table header
                    text: "Transport Mode",
                    align: "left",
                    value: "node.transportMode"
                },
                {
                    // for filter menu
                    code: "node.vessel",
                    name: "Vessel",
                    tag: "headers.vessel",
                    enabled: true,
                    // for table header
                    text: "Vessel",
                    align: "left",
                    value: "node.vessel"
                },
                {
                    // for filter menu
                    code: "node.status",
                    name: "Status",
                    tag: "headers.status",
                    enabled: true,
                    // for table header
                    text: "Status",
                    align: "left",
                    value: "node.status"
                },
                {
                    // for filter menu
                    code: "node.plannedDepartureDate",
                    name: "Planned Departure",
                    tag: "headers.planned_departure",
                    enabled: true,
                    // for table header
                    text: "Planned Departure",
                    align: "left",
                    value: "node.plannedDepartureDate"
                },
                {
                    // for filter menu
                    code: "node.plannedArrivalDate",
                    name: "Planned Arrival",
                    tag: "headers.planned_arrival",
                    enabled: true,
                    // for table header
                    text: "Planned Arrival",
                    align: "left",
                    value: "node.plannedArrivalDate"
                },
                {
                    // for filter menu
                    code: "containers",
                    name: "Containers",
                    tag: "headers.container",
                    enabled: true,
                    // for table header
                    text: "Containers",
                    align: "left",
                    value: "containers"
                },
                {
                    // for filter menu
                    code: "freights",
                    name: "Freight Number",
                    tag: "headers.freight_number",
                    enabled: true,
                    // for table header
                    text: "Freight Number",
                    align: "left",
                    value: "freights"
                },
                {
                    // for filter menu
                    code: "airwaybill",
                    name: "AWB",
                    tag: "others.awb",
                    enabled: true,
                    // for table header
                    text: "AWB",
                    align: "left",
                    value: "airwaybill"
                },
                {
                    // for filter menu
                    code: "trackers",
                    name: "Trackers",
                    tag: "home.trackers",
                    enabled: true,
                    // for table header
                    text: "Trackers",
                    align: "left",
                    value: "trackers"
                },
                {
                    // for filter menu
                    code: "consignor",
                    name: "Consignor",
                    tag: "headers.consignor",
                    enabled: false,
                    // for table header
                    text: "Consignor",
                    align: "left",
                    value: "consignor"
                },
                {
                    // for filter menu
                    code: "consignee",
                    name: "Consignee",
                    tag: "headers.consignee",
                    enabled: false,
                    // for table header
                    text: "Consignee",
                    align: "left",
                    value: "consignee"
                },
                {
                    // for filter menu
                    code: "carrier",
                    name: "Carrier",
                    tag: "headers.carrier",
                    enabled: false,
                    // for table header
                    text: "Carrier",
                    align: "left",
                    value: "carrier"
                },
                {
                    // for filter menu
                    code: "forwarder",
                    name: "Forwarder",
                    tag: "headers.forwarder",
                    enabled: false,
                    // for table header
                    text: "Forwarder",
                    align: "left",
                    value: "forwarder"
                },
                {
                    // for filter menu
                    code: "other",
                    name: "Other",
                    tag: "home.other",
                    enabled: false,
                    // for table header
                    text: "Other",
                    align: "left",
                    value: "other"
                },
                {
                    // for filter menu
                    code: "goods",
                    name: "Goods",
                    tag: "headers.goods",
                    enabled: false,
                    // for table header
                    text: "Goods",
                    align: "left",
                    value: "goods"
                },
                {
                    // for filter menu
                    code: "POL",
                    name: "POL",
                    tag: "others.pol",
                    enabled: false,
                    // for table header
                    text: "POL",
                    align: "left",
                    value: "POL"
                },
                {
                    // for filter menu
                    code: "transshipment",
                    name: "T/S",
                    tag: "others.ts",
                    enabled: false,
                    // for table header
                    text: "T/S",
                    align: "left",
                    value: "transshipment"
                },
                {
                    // for filter menu
                    code: "POD",
                    name: "POD",
                    tag: "others.pod",
                    enabled: false,
                    // for table header
                    text: "POD",
                    align: "left",
                    value: "POD"
                },
                {
                    // for filter menu
                    code: "node.numberOfFreights",
                    name: "#" + "Freights",
                    tag: "headers.freights",
                    enabled: true,
                    // for table header
                    text: "#" + "Freights",
                    align: "right",
                    value: "node.numberOfFreights"
                },
                {
                    // for filter menu
                    code: "node.numberOfPartners",
                    name: "#" + "Partners",
                    tag: "headers.partners1",
                    enabled: true,
                    // for table header
                    text: "#" + "Partners",
                    align: "right",
                    value: "node.numberOfPartners"
                },
                {
                    // for filter menu
                    code: "node.numberOfDocuments",
                    name: "#" + "Documents",
                    tag: "headers.documents1",
                    enabled: true,
                    // for table header
                    text: "#" + "Documents",
                    align: "right",
                    value: "node.numberOfDocuments"
                },
                {
                    // for filter menu
                    code: "node.numberOfUnresolvedAlerts",
                    name: "#" + "Alerts",
                    tag: "headers.alerts1",
                    enabled: true,
                    // for table header
                    text: "#" + "Alerts",
                    align: "right",
                    value: "node.numberOfUnresolvedAlerts"
                },
                {
                    // for filter menu
                    code: "node.trackerLastConnectionTime",
                    name: "Last Connection",
                    tag: "headers.last_connection",
                    enabled: true,
                    // for table header
                    text: "Last Connection",
                    align: "left",
                    value: "node.trackerLastConnectionTime"
                },
                {
                    // for filter menu
                    code: "node.trackerLastLocation",
                    name: "Last Location",
                    tag: "headers.last_location",
                    enabled: true,
                    // for table header
                    text: "Last Location",
                    align: "left",
                    value: "node.trackerLastLocation"
                },
                {
                    code: "tenant",
                    name: "Tenant",
                    tag: "trackers.tenant",
                    enabled: true,
                    // for table header
                    text: "Tenant",
                    align: "left",
                    value: "node.tenant.name"
                },
                {
                    // for filter menu
                    code: "node.polEtd",
                    name: "POL ETD",
                    tag: "others.pol_etd",
                    enabled: true,
                    // for table header
                    text: "POL ETD",
                    align: "left",
                    value: "node.polEtd"
                },
                {
                    // for filter menu
                    code: "node.podEta",
                    name: "POD ETA",
                    tag: "others.pod_eta",
                    enabled: true,
                    // for table header
                    text: "POD ETA",
                    align: "left",
                    value: "node.podEta"
                }
            ]
        };
    },
    computed: {
        screenSize() {
            let _resposiveView = this.$vuetify.breakpoint.width;
            if (_resposiveView < 650) {
                return "xs";
            } else if (_resposiveView < 1000) {
                return "sm";
            } else if (_resposiveView < 1400) {
                return "md";
            } else if (_resposiveView < 1900) {
                return "lg";
            } else {
                return "xl";
            }
        },
        me() {
            return helper.me();
        },
        centralizeLoadBtnClass() {
            let _centralizeLoadBtnClass = {
                position: "sticky",
                left: "50%",
            };
            return _centralizeLoadBtnClass;
        }, 
        // infinite
        hasMoreData() {
            return this.trips?.pageInfo?.hasNextPage;
        },
        isTripsRoute() {
            let route = this.$route;
            return route.name == "trips" && route?.query?.id !== null;
        },
        switchTableHeaders() {
            return this.isSharedTrip
                ? "sharedTripTableHeaders"
                : "tripTableHeaders";
        },
        dynamicHeaders() {
            let _headers = this.headerChoices.filter(header => header.enabled);
            _headers = _headers.map(header => {
                return {
                    ...header,
                    text: this.$t(header.tag)
                };
            });

            // put favorite in the first column
            _headers.unshift({
                text: "",
                align: "left",
                value: "isFavorite",
                width: "64px",
                sortable: false
            });

            // put header filter in the last column
            _headers.push({
                text: "",
                align: "right",
                value: "headerFilter",
                sortable: false
            });

            return _headers;
        }
    },
    methods: {
        loadHeaders() {
            let _default = [...this.defaultHeaderChoices];

            let newChoices = this.isSharedTrip
                ? _default
                : _default.filter(header => header.code !== "tenant");

            if (newChoices.some(choice => typeof choice === "undefined")) {
                // console.error("Undefined item found in newChoices");
            } else {
                this.headerChoices = helper.getTableHeaders(
                    `${this.switchTableHeaders}`,
                    newChoices
                );
            }
        },
        onFilterMenuChanged(newChoices) {
            this.headerChoices = newChoices.map(choice => choice);
            if (this.isSharedTrip) {
                helper.updateMyPreferences({
                    sharedTripTableHeaders: newChoices
                });
            } else {
                helper.updateMyPreferences({
                    tripTableHeaders: newChoices
                });
            }
        },
        onResize() {
            this.$nextTick(() => {
                this.tableHeight = window.innerHeight - 180 + "px";
            });
        },
        startScrolling(direction) {
            this.scrollInterval = setInterval(() => {
                const tableContainer = document.querySelector(
                    ".v-data-table__wrapper"
                );
                const scrollAmount = direction === "left" ? -10 : 10;
                tableContainer.scrollLeft += scrollAmount;
            }, 10);
        },
        stopScrolling() {
            clearInterval(this.scrollInterval);
        },
        formatDateTime(datetime) {
            return helper.formatDateTime(datetime);
        },
        formatTemperature(temperature) {
            return helper.formatTemperature(temperature);
        },
        onLoadMoreTriggerIntersect(entries) {
            this.shouldLoadMore = entries[0].isIntersecting;

            if (this.shouldLoadMore && this.trips?.pageInfo?.hasNextPage) {
                this.loadMore();
            }
        },
        refetchDate() {
            // use timeout to debouce
            if (this.timeout) clearTimeout(this.timeout);

            this.timeout = setTimeout(() => {
                this.$apollo.queries.trips.refetch(this.filter);
            }, 800); // delay
        },
        loadMore() {
            if (this.trips?.pageInfo?.hasNextPage) {
                this.$apollo.queries.trips.fetchMore({
                    variables: {
                        after: this.trips.pageInfo.endCursor
                    }
                });
            }
        },
        getAllVessels(tripSeaTransportSet) {
            let vessels = [];
            let uniqueVessels = new Set();
            tripSeaTransportSet?.edges.forEach(edge => {
                const vessel = edge.node?.vessel;
                if (vessel && !uniqueVessels.has(vessel.id)) {
                    uniqueVessels.add(vessel.id);
                    vessels.push(vessel);
                }
            });
            return vessels;
        },
        getAllGoods(tripFreightSet) {
            let goodsToReturn = [];
            let uniqueGoods = new Set();
            tripFreightSet?.edges.forEach(edge => {
                const goods = edge.node?.goods;
                if (goods && !uniqueGoods.has(goods.toLowerCase())) {
                    uniqueGoods.add(goods.toLowerCase());
                    goodsToReturn.push(goods);
                }
            });
            return goodsToReturn;
        },
        goToFreight(freight, trip) {
            this.$router.push({
                name: "trips",
                query: {
                    id: trip,
                    tab: "freights",
                    freight: freight
                }
            });
        },
        getPolEtd(tripSeaTransportSet) {
            let date = null;
            let offset = 0;
            let actual = null;
            let original = null;
            tripSeaTransportSet?.edges.forEach(edge => {
                if (edge.node?.portTransportType === "POL") {
                    date = edge.node?.estimatedDepartureDate;
                    offset = this.calculateDelays(
                        edge.node?.originalEstimatedDepartureDate,
                        edge.node?.estimatedDepartureDate,
                        edge.node?.actualDepartureDate
                    );
                    actual = edge.node?.actualDepartureDate;
                    // original = edge.node?.originalEstimatedDepartureDate || edge.node?.estimatedDepartureDate;
                    if(edge.node?.originalEstimatedDepartureDate) {
                        original = `Original ETD: ${this.formatDateTime(this.updateDateTimeTimezone(edge.node?.originalEstimatedDepartureDate))?.split(" ")[0]}`
                    }else if(edge.node?.estimatedDepartureDate){
                        original = `ETD: ${this.formatDateTime(this.updateDateTimeTimezone(edge.node?.estimatedDepartureDate))?.split(" ")[0]}`
                    }
                }
            });
            let event = offset < 0 && !actual ? this.$t("date.to_go") :
            offset < 0 && actual ? this.$t("date.earlier") : this.$t("date.late");
            
            // this.$t(header.tag)
            let color = offset > 0 ? "error--text" : "success--text";
            let text = (offset == 0 || offset === undefined) ? '' : `${Math.abs(offset)} ${this.$t("date.days2")} ${event}`;
            return {
                date: this.formatDateTime(this.updateDateTimeTimezone(date))?.split(" ")[0],
                offset: offset,
                event: event,
                color: color,
                text: text,
                original: original
            };
        },
        getPodEta(tripSeaTransportSet) {
            let date = null;
            let offset = 0;
            let actual = null;
            let original = null;
            tripSeaTransportSet?.edges.forEach(edge => {
                if (edge.node?.portTransportType === "POD") {
                    date = edge.node?.estimatedArrivalDate;
                    offset = this.calculateDelays(
                        edge.node?.originalEstimatedArrivalDate,
                        edge.node?.estimatedArrivalDate,
                        edge.node?.actualArrivalDate
                    );
                    actual = edge.node?.actualArrivalDate;
                    if(edge.node?.originalEstimatedArrivalDate) {
                        original = `Original ETA: ${this.formatDateTime(this.updateDateTimeTimezone(edge.node?.originalEstimatedArrivalDate))?.split(" ")[0]}`
                    }else if(edge.node?.estimatedArrivalDate){
                        original = `ETA: ${this.formatDateTime(this.updateDateTimeTimezone(edge.node?.estimatedArrivalDate))?.split(" ")[0]}`
                    }
                }
            });
            
            let event = offset < 0 && !actual ? this.$t("date.to_go") :
            offset < 0 && actual ? this.$t("date.earlier") : this.$t("date.late");

            let color = offset > 0 ? "error--text" : "success--text";
            let text = (offset == 0 || offset === undefined) ? '' : `${Math.abs(offset)} ${this.$t("date.days2")} ${event}`;
            return {
                date: this.formatDateTime(this.updateDateTimeTimezone(date))?.split(" ")[0],
                offset: offset,
                event: event,
                color: color,
                text: text,
                original: original
            };
        },
        getDays(start, end) {
            let Difference_In_Days = null;
            if (
                start === null ||
                end === null ||
                start === undefined ||
                end === undefined ||
                start.length === 0 ||
                end.length === 0
            ) {
                Difference_In_Days = null;
            } else {
                let startDate = new Date(start);
                let endDate = new Date(end);
                let Difference_In_Time =
                    endDate.getTime() - startDate.getTime();
                Difference_In_Days = Math.round(
                    Difference_In_Time / (1000 * 3600 * 24)
                );
            }

            return Difference_In_Days?.toFixed(0);
        },
        getExternalTrackerAdditionalInfo(additionalInfo) {
            let additionalInfoToReturn = null;
            if (additionalInfo) {
                let additionalInfoObj = JSON.parse(additionalInfo);
                if (additionalInfoObj?.externalTracker) {
                    additionalInfoToReturn = additionalInfoObj?.externalTracker;
                }
            }
            return additionalInfoToReturn;
        }
        /* mobileWidth(screen) {
            switch(screen){
                case 'xs':
                    return 'width: 60%';
                case 'sm':
                    return 'width: 100%';
                default:
                    return '';
            }
        }, */
    }
};
</script>
<style scoped lang="css">
.row-pointer >>> tbody tr :hover {
    cursor: pointer;
}
.center-loading {
    position: sticky;
    left: 50% !important;
}
</style>
