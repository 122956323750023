import { VBtn } from 'vuetify/lib/components/VBtn';
import { VCard } from 'vuetify/lib/components/VCard';
import { VCardActions } from 'vuetify/lib/components/VCard';
import { VCardText } from 'vuetify/lib/components/VCard';
import { VChip } from 'vuetify/lib/components/VChip';
import { VCol } from 'vuetify/lib/components/VGrid';
import { VHover } from 'vuetify/lib/components/VHover';
import { VIcon } from 'vuetify/lib/components/VIcon';
import { VMenu } from 'vuetify/lib/components/VMenu';
import { VRow } from 'vuetify/lib/components/VGrid';
import { VSelect } from 'vuetify/lib/components/VSelect';
import { VSpacer } from 'vuetify/lib/components/VGrid';
import { VTooltip } from 'vuetify/lib/components/VTooltip';

var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c(VHover,[[_c(VCard,{staticClass:"py-2 transparent",attrs:{"outlined":""}},[_c(VRow,{attrs:{"dense":""}},[_c(VCol,[_c(VRow,{staticClass:"d-flex align-center py-0",attrs:{"dense":""}},[_c(VCol,{attrs:{"cols":"12","xs":"12","sm":"12","md":"3","lg":"3","xl":"3"}},[_c('div',[_c('SearchBar',{ref:"searchBar",attrs:{"label":_vm.$t('filters.search')},scopedSlots:_vm._u([{key:"prepend-inner",fn:function(){return [_c('div',[_c(VTooltip,{attrs:{"bottom":""},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
var on = ref.on;
return [_c(VIcon,_vm._g({attrs:{"color":""}},on),[_vm._v(" search ")])]}}])},[_c('span',[_vm._v(" "+_vm._s(_vm.$t("trackers.search"))+" ")])])],1)]},proxy:true}]),model:{value:(_vm.search),callback:function ($$v) {_vm.search=$$v},expression:"search"}})],1)]),(_vm.tab === 'publicLocations')?_c(VCol,{attrs:{"cols":"auto"}},[_c(VMenu,{ref:"locationTypeMenu",attrs:{"max-width":"300px","close-on-content-click":false,"close-on-click":true,"right":"","bottom":"","nudge-top":"-6","offset-y":""},on:{"click":function($event){$event.stopPropagation();},"input":function($event){return _vm.onMenuStateChange($event, 'locationTypeMenu')}},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
var on = ref.on;
var attrs = ref.attrs;
return [_c(VChip,_vm._g(_vm._b({staticClass:"elevation-0 text-capitalize classText content",attrs:{"label":""},on:{"click":function($event){return _vm.addCode('locationType')}}},'v-chip',attrs,false),on),[(_vm.localLocationType !== null)?_c('span',[_vm._v(" "+_vm._s(_vm.$t('headers.location_type') + ': ' + _vm.getLocationTypeLabel(_vm.localLocationType))+" ")]):_c('span',[_vm._v(" "+_vm._s(_vm.$t('headers.location_type') + ': ' + _vm.$t('general.all'))+" ")])])]}}],null,false,517241275)},[_c(VCard,{staticClass:"pa-1",attrs:{"height":"100%"}},[_c(VCardText,{staticClass:"mt-2"},[_c(VRow,{staticClass:"align-center",attrs:{"align":"start","dense":""}},[_c(VCol,{attrs:{"cols":"12","xs":"12","sm":"12","md":"12","lg":"12","xl":"12"}},[_c(VSelect,{attrs:{"items":_vm.locationTypeLabels,"label":_vm.$t('trips.filter'),"readonly":"","disabled":"","dense":"","outlined":"","color":"primary","hide-details":"","menu-props":{
                                                        offsetX: true,
                                                        right: true
                                                    }},model:{value:(_vm.locationTypeLabel),callback:function ($$v) {_vm.locationTypeLabel=$$v},expression:"locationTypeLabel"}})],1),_c(VCol,{attrs:{"cols":"12","xs":"12","sm":"12","md":"12","lg":"12","xl":"12"}},[_c(VSelect,{attrs:{"items":_vm.publicLocationsTypes,"item-text":"text","item-value":"value","label":"Value","outlined":"","hide-details":"","dense":""},model:{value:(_vm.localLocationType),callback:function ($$v) {_vm.localLocationType=$$v},expression:"localLocationType"}})],1)],1)],1),_c(VCardActions,{staticClass:"pa-0"},[_c(VSpacer),_c(VBtn,{attrs:{"text":""},on:{"click":function($event){return _vm.cancelFilter('locationTypeMenu')}}},[_vm._v(" Close ")]),_c(VBtn,{attrs:{"color":"primary"},on:{"click":function($event){_vm.$emit('updateLocationType', _vm.localLocationType); _vm.$refs.locationTypeMenu.isActive = false;}}},[_vm._v(" Apply ")])],1)],1)],1)],1):_vm._e(),_vm._l((_vm.filterValuesObj),function(i){return _c(VCol,{key:i.id,attrs:{"cols":"auto"}},[_c(VMenu,{ref:_vm.getCode(i.id),refInFor:true,attrs:{"max-width":"300px","close-on-content-click":false,"close-on-click":true,"right":"","bottom":"","nudge-top":"-6","offset-y":""},on:{"click":function($event){$event.stopPropagation();},"input":function($event){_vm.onMenuStateChange($event, _vm.getCode(i.id))}},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
                                                    var on = ref.on;
                                                    var attrs = ref.attrs;
return [_c(VChip,_vm._g(_vm._b({staticClass:"elevation-0 text-capitalize classText content",attrs:{"label":"","close":""},on:{"click:close":function($event){return _vm.deleteFilterValue(i.id)},"click":function($event){_vm.addCode(_vm.getCode(i.id))}}},'v-chip',attrs,false),on),[(i.id == '')?_c('span'):_vm._e(),_vm._v(" "+_vm._s(i.name + ":" + " " + i.value)+" ")])]}}],null,true)},[_c(VCard,{staticClass:"pa-1",attrs:{"height":"100%"}},[_c(VCardText,{staticClass:"mt-2"},[_c('FilterMenuTemplate',{ref:"FilterMenuTemplate",refInFor:true,attrs:{"isReadOnly":true,"currentCode":_vm.codeSelected,"tab":_vm.tab},on:{"search-fields":_vm.searchFields},model:{value:(_vm.menuObject),callback:function ($$v) {_vm.menuObject=$$v},expression:"menuObject"}})],1),_c(VCardActions,{staticClass:"pa-0"},[_c(VSpacer),_c(VBtn,{attrs:{"text":""},on:{"click":function($event){_vm.cancelFilter(_vm.getCode(i.id))}}},[_vm._v(" Close ")]),_c(VBtn,{attrs:{"color":"primary","disabled":!_vm.hasMenuValues},on:{"click":function($event){_vm.searchFields(_vm.menuObject, _vm.getCode(i.id))}}},[_vm._v(" Apply ")])],1)],1)],1)],1)}),_c(VCol,{attrs:{"cols":"auto"}},[_c(VMenu,{ref:"addFilterMenu",attrs:{"max-width":"300px","close-on-content-click":false,"close-on-click":true,"right":"","bottom":"","nudge-top":"-6","offset-y":""},on:{"click":function($event){$event.stopPropagation();},"input":function($event){return _vm.onMenuStateChange($event, 'addFilterMenu')}},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
                                                    var on = ref.on;
                                                    var attrs = ref.attrs;
return [_c(VBtn,_vm._g(_vm._b({staticClass:"content",attrs:{"color":"primary"}},'v-btn',attrs,false),on),[_vm._v(" "+_vm._s(_vm.$t("general.add_filter"))+" ")])]}}])},[_c(VCard,{staticClass:"pa-1",attrs:{"height":"100%"}},[_c(VCardText,{staticClass:"mt-2"},[_c('FilterMenuTemplate',{attrs:{"isReadOnly":false,"currentCode":_vm.codeSelected,"tab":_vm.tab},on:{"search-fields":_vm.searchFields,"update:currentCode":function($event){_vm.codeSelected=$event},"update:current-code":function($event){_vm.codeSelected=$event}},model:{value:(_vm.menuObject),callback:function ($$v) {_vm.menuObject=$$v},expression:"menuObject"}})],1),_c(VCardActions,{staticClass:"pa-0"},[_c(VSpacer),_c(VBtn,{attrs:{"text":""},on:{"click":function($event){return _vm.cancelFilter('addFilterMenu')}}},[_vm._v(" Close ")]),_c(VBtn,{attrs:{"color":"primary","disabled":!_vm.hasMenuValues || _vm.codeSelected === null || _vm.codeSelected === ''},on:{"click":function($event){return _vm.searchFields(_vm.menuObject, 'addFilterMenu')}}},[_vm._v(" Apply ")])],1)],1)],1)],1)],2)],1),_c(VCol,{attrs:{"cols":"auto"}},[_c(VRow,{attrs:{"dense":""}},[_c(VCol,[_c(VSelect,{staticClass:"content-select",attrs:{"items":_vm.dynamicSortBy,"label":_vm.$t('general.sort_by'),"item-value":function (item) { return item.code; },"item-text":function (item) { return item.name; },"clearable":"","hide-details":"","dense":"","outlined":"","color":"primary"},scopedSlots:_vm._u([{key:"append-outer",fn:function(){return [_c(VBtn,{staticClass:"mt-n1",attrs:{"small":"","icon":"","disabled":!_vm.sortBy},on:{"click":function($event){_vm.sortAsc = !_vm.sortAsc}}},[_c(VIcon,{attrs:{"color":"grey darken-2"}},[_vm._v(_vm._s(_vm.sortAsc ? "north" : "south"))])],1)]},proxy:true},{key:"item",fn:function(ref){
                                                    var item = ref.item;
return [_c('span',{staticClass:"classText"},[_vm._v(" "+_vm._s(item.name)+" ")])]}},{key:"selection",fn:function(ref){
                                                    var item = ref.item;
return [_c('div',{staticClass:"classText"},[_vm._v(" "+_vm._s(item.name)+" ")])]}}]),model:{value:(_vm.sortBy),callback:function ($$v) {_vm.sortBy=$$v},expression:"sortBy"}})],1)],1)],1)],1)],1)]],2)}
var staticRenderFns = []

export { render, staticRenderFns }