<template>
    <v-card-text>
        <v-row class="text-uppercase user-select-text">
            <v-col
                v-for="item in tableCard(location)"
                :key="item.text"
                :cols="item.cols.xs"
                :xs="item.cols.xs"
                :sm="item.cols.sm"
                :md="item.cols.md"
                :lg="item.cols.lg"
                :xl="item.cols.xl"
            >
                <div v-if="item.tag === 'tags'">
                    <div class="secondaryText font-weight-thin text-truncate">
                        {{ item.title }}
                    </div>
                    <v-row class="d-flex flex-row justify-start align-center" dense>
                        <v-col
                            v-for="(tag, i) in item.value"
                            :key="i"
                            class="primaryText font-weight-bold text-truncate mr-2"
                            :style="item.style"
                            cols="auto"
                        >
                            <v-icon
                                v-if="item.icon"
                                size="8"
                                class="ml-1"
                                :color="item.colorIcon"
                                >{{ item.icon }}</v-icon
                            >
                            <span class="mx-1">
                                {{ tag.text }}
                            </span>
                        </v-col>
                    </v-row>
                </div>
                <div v-else>
                    <div class="secondaryText font-weight-thin text-truncate">
                        {{ item.title }}
                    </div>
                    <div class="primaryText font-weight-bold text-truncate" :style="item.style">
                        <v-icon
                            v-if="item.icon"
                            size="8"
                            class="mr-1"
                            :color="item.colorIcon"
                            >{{ item.icon }}</v-icon
                        >
                        <span>
                            {{ item.value }}
                        </span>
                    </div>
                </div>
            </v-col>
        </v-row>
    </v-card-text>
</template>
<script>
import helper from "@/utils/helper";

export default {
    name: "LocationHeaderTemplate",
    components: {
    },
    props: {
        location: {
            type: Object,
            required: true
        },
        tabFilter: {
            type: String,
            required: true
        }
    },
    data() {
        return {
                
        }
    },
    created() {
    },
    computed: {
        me() {
            return helper.me();
        },
        tagsChoices: function() {
            let _tagsChoices = [
                {
                    text: this.$t("headers.warehouse"),
                    value: "warehouse"
                },
                {
                    text: this.$t("headers.border_post"),
                    value: "border_post"
                },
                {
                    text: this.$t("headers.cold_store"),
                    value: "cold_store"
                },
                {
                    text: this.$t("headers.empty_depot"),
                    value: "empty_depot"
                }
            ];
            return _tagsChoices;
        }
    },
    methods: {
        tableCard(location) {
            const containerStyle = tag => {
                return `
                    background-color: ${this.statusColor(tag).color1};
                    border-radius: 4px;
                    padding:2px;
                    margin: 3px 4px 0px 0px;
                    width: auto;
                    display: flex;
                    justify-content: center;
                    align-items: center;
                `;
            };

            const containerStyle2 = tag => {
                return `
                    background-color: ${this.statusColor(tag).color1};
                    border-radius: 4px;
                    padding:2px;
                    width: auto;
                    display: flex;
                    justify-content: center;
                    align-items: center;
                `;
            };

            const getPayload = item => {
                if (this.tabFilter === "myLocations") {
                    const { name, city, country, fullAddress, locationType, tags } = item.node;
                    let _tags = this.convertJsonToArray(tags);

                    return [
                        {
                            title: this.$t("headers.name"),
                            tag: "locationName",
                            icon: null,
                            colorIcon: null,
                            style: "",
                            align: "left",
                            value: name ? name : "N/A",
                            cols: {
                                xs: 12,
                                sm: 12,
                                md: 4,
                                lg: 2,
                                xl: 2
                            }
                        },
                        {
                            title: this.$t("partners.city"),
                            tag: "city",
                            icon: null,
                            colorIcon: null,
                            style: "",
                            align: "left",
                            value: `${city}, ${country}`,
                            cols: {
                                xs: 12,
                                sm: 12,
                                md: 4,
                                lg: 3,
                                xl: 3
                            }
                        },
                        {
                            title: this.$t("headers.address"),
                            tag: "address",
                            icon: null,
                            colorIcon: null,
                            style: "",
                            align: "left",
                            value: fullAddress,
                            cols: {
                                xs: 12,
                                sm: 12,
                                md: 4,
                                lg: 4,
                                xl: 4
                            }
                        },
                        // {
                        //     title: this.$t("partners.country"),
                        //     tag: "country",
                        //     icon: null,
                        //     colorIcon: null,
                        //     style: "",
                        //     align: "left",
                        //     value: country,
                        //     cols: 2
                        // },
                        {
                            title: this.$t("headers.tags"),
                            tag: "tags",
                            color: "primary",
                            icon: null,
                            colorIcon: this.statusColor(tags).color2,
                            style: containerStyle(tags),
                            align: "center",
                            value: _tags,
                            cols: {
                                xs: 12,
                                sm: 6,
                                md: 4,
                                lg: 2,
                                xl: 2
                            }
                        },
                        {
                            title: this.$t("headers.scope"),
                            tag: "scope",
                            icon: null,
                            colorIcon: null,
                            style: "",
                            align: "center",
                            value: this.replaceScopeName(locationType),
                            cols: {
                                xs: 12,
                                sm: 6,
                                md: 4,
                                lg: 1,
                                xl: 1
                            }
                        }
                    ];
                } else if (this.tabFilter === "partnerLocations") {
                    const { name, city, country, fullAddress, isTrackerStockLocation, tenantPartner } = item.node;
                    return [
                        {
                            title: this.$t("headers.name"),
                            tag: "locationName",
                            icon: null,
                            colorIcon: null,
                            style: "",
                            align: "left",
                            value: name,
                            cols: {
                                xs: 12,
                                sm: 12,
                                md: 4,
                                lg: 2,
                                xl: 2
                            }
                        },
                        {
                            title: this.$t("partners.city"),
                            tag: "city",
                            icon: null,
                            colorIcon: null,
                            style: "",
                            align: "left",
                            value: `${city}, ${country}`,
                            cols: {
                                xs: 12,
                                sm: 12,
                                md: 4,
                                lg: 2,
                                xl: 2
                            }
                        },
                        {
                            title: this.$t("headers.address"),
                            tag: "address",
                            icon: null,
                            colorIcon: null,
                            style: "",
                            align: "left",
                            value: fullAddress,
                            cols: {
                                xs: 12,
                                sm: 12,
                                md: 4,
                                lg: 3,
                                xl: 3
                            }
                        },
                        // {
                        //     title: this.$t("partners.country"),
                        //     tag: "country",
                        //     icon: null,
                        //     colorIcon: null,
                        //     style: "",
                        //     align: "left",
                        //     value: country,
                        //     cols: 1
                        // },
                        {
                            title: this.$t("headers.collaborator"),
                            tag: "partnerName",
                            icon: null,
                            colorIcon: null,
                            style: "",
                            align: "left",
                            value: tenantPartner?.name,
                            cols: {
                                xs: 12,
                                sm: 12,
                                md: 4,
                                lg: 2,
                                xl: 2
                            }
                        },
                        {
                            title: this.$t("headers.collaborator_type1"),
                            tag: "partnerType",
                            icon: null,
                            colorIcon: null,
                            style: "",
                            align: "left",
                            value: tenantPartner?.partnerType,
                            cols: {
                                xs: 6,
                                sm: 6,
                                md: 4,
                                lg: 2,
                                xl: 2
                            }
                        },
                        {
                            title: this.$t("trackers.stock_location"),
                            tag: "stockLocation",
                            color: "primary",
                            icon: null,
                            colorIcon: this.statusColor(isTrackerStockLocation).color2,
                            style: containerStyle2(isTrackerStockLocation),
                            align: "center",
                            value: isTrackerStockLocation ? "Yes" : "No",
                            cols: {
                                xs: 6,
                                sm: 6,
                                md: 4,
                                lg: 1,
                                xl: 1
                            }
                        }
                    ];
                } else if (this.tabFilter === "publicLocations") {
                    const { name, city, country, code, locationType } = item.node;
                    return [
                        {
                            title: this.$t("headers.name"),
                            tag: "locationName",
                            icon: null,
                            colorIcon: null,
                            style: "",
                            align: "left",
                            value: name,
                            cols: {
                                xs: 12,
                                sm: 6,
                                md: 4,
                                lg: 4,
                                xl: 4
                            }
                        },
                        {
                            title: this.$t("partners.city"),
                            tag: "city",
                            icon: null,
                            colorIcon: null,
                            style: "",
                            align: "left",
                            value: city,
                            cols: {
                                xs: 12,
                                sm: 6,
                                md: 4,
                                lg: 3,
                                xl: 3
                            }
                        },
                        {
                            title: this.$t("partners.country"),
                            tag: "country",
                            icon: null,
                            colorIcon: null,
                            style: "",
                            align: "left",
                            value: country,
                            cols: {
                                xs: 12,
                                sm: 6,
                                md: 4,
                                lg: 2,
                                xl: 2
                            }
                        },
                        {
                            title: this.$t("headers.code"),
                            tag: "code",
                            icon: null,
                            colorIcon: null,
                            style: "",
                            align: "left",
                            value: code,
                            cols: {
                                xs: 12,
                                sm: 6,
                                md: 4,
                                lg: 2,
                                xl: 2
                            }
                        },
                        {
                            title: this.$t("headers.location_type"),
                            tag: "locationType",
                            icon: null,
                            colorIcon: null,
                            style: "",
                            align: "center",
                            value: this.replaceScopeName(locationType),
                            cols: {
                                xs: 12,
                                sm: 6,
                                md: 4,
                                lg: 1,
                                xl: 1
                            }
                        }
                    ];
                } else if (this.tabFilter === "weather") {
                    const { name, city, country, code, locationType } = item.node;
                    return {
                        name,
                        city,
                        country,
                        code,
                        locationType
                    };
                } else {
                    return [];
                }
            };

            const payload = getPayload(location);

            return payload;
        },
        convertJsonToArray(tags) {
            if (tags) {
                try {
                    let tagsArray = JSON.parse(tags.replace(/'/g, '"'));
                    let _tagsArray = this.tagsChoices.reduce((acc, tag) => {
                        let isUnique = !acc.find(t => t.value === tag.value);
                        let isPresent = tagsArray.find(t => t === tag.value);
                        if (isUnique && isPresent) {
                            acc.push(tag);
                        }
                        return acc;
                    }, []);

                    return _tagsArray;
                } catch (error) {
                    console.log(error);
                    return null;
                }
            }
            return null;
        },
        replaceScopeName(item) {
            if (item === "TENANT_LOCATION") {
                return this.$t("alert_rules.public");
            } else if (item === "PRIVATE_LOCATION") {
                return this.$t("alert_rules.nonpublic");
            } else if (item.trim() === "AIR_PORT") {
                return this.$t("headers.airport");
            } else if (item.trim() === "SEA_PORT") {
                return this.$t("headers.sea_port");
            } else {
                return item;
            }
        },
        statusColor(status) {
            if (status) {
                return {
                    color1: "#C8E6C9",
                    color2: "#1B5E20"
                };
            } else {
                return {
                    color1: "#FFCDD2",
                    color2: "#F44336"
                };
            }
        },
        statusText(status) {
            if (status) {
                return this.$t("headers.enabled");
            } else {
                return this.$t("headers.disabled");
            }
        },
    }
}
</script>
<style scoped>
.secondaryText {
    color: #555464 !important;
    font-size: 12px;
    line-height: 14px;
    letter-spacing: 0em;
}

.primaryText {
    color: #0e0d35 !important;
    font-size: 12px;
    line-height: 13px;
    letter-spacing: 0em;
    margin-top: 3px;
}
.textLeft {
text-align: left !important;
}
</style>