<template>
    <v-card flat>
        <v-card-text>
            <div>
                <v-row>
                    <v-col cols="12" xs="12" sm="5" md="3" lg="3" xl="3">
                        <v-select
                            filled
                            outline
                            solo
                            dense
                            hide-details
                            :label="$t('headers.select_date')"
                            :items="trackerDateRange"
                            v-model="relativeTrackerDateRange"
                        ></v-select>
                    </v-col>
                    <v-col cols="12" xs="12" sm="5" md="3" lg="3" xl="3">
                        <v-menu
                            ref="dateRangeMenu"
                            v-model="dateRangeMenu"
                            :close-on-content-click="false"
                            transition="scale-transition"
                            offset-y
                            min-width="auto"
                        >
                            <template v-slot:activator="{ on, attrs }">
                                <v-text-field
                                    :value="dateRangeText"
                                    :label="$t('headers.date_range')"
                                    readonly
                                    hide-details
                                    filled
                                    outline
                                    solo
                                    dense
                                    v-bind="attrs"
                                    v-on="on"
                                ></v-text-field>
                            </template>
                            <v-date-picker
                                v-model="dateRange"
                                :first-day-of-week="1"
                                range
                                no-title
                                scrollable
                                color="primary"
                            >
                                <v-spacer></v-spacer>
                                <v-btn text rounded color="primary" @click="cancelDatePicker">
                                    {{ $t("general.cancel") }}
                                </v-btn>
                                <v-btn
                                    outlined
                                    rounded
                                    color="primary"
                                    :disabled="dateRange.length < 1"
                                    @click="saveDatePicker"
                                >
                                    OK
                                </v-btn>
                            </v-date-picker>
                        </v-menu>
                    </v-col>
                    <v-spacer></v-spacer>
                    <div class="mt-3 mr-3">
                        <ExportTrackerDataDialog
                            :tracker.sync="tracker"
                            :filterStartTime.sync="deviceTime_Gte"
                            :filterEndTime.sync="deviceTime_Lte"
                        ></ExportTrackerDataDialog>
                    </div>
                </v-row>
                <v-row class="mb-10">
                    <v-col cols="12" md="6">
                        <v-card flat class="d-flex justify-center">
                            <LoadingBar v-if="$apollo.queries.trackerTemperature.loading"></LoadingBar>

                            <apexcharts
                                v-else
                                :disabled="$apollo.queries.trackerTemperature.loading"
                                :width="chartWidth"
                                type="line"
                                :options="options"
                                :series="series"
                            ></apexcharts>
                        </v-card>
                    </v-col>
                    <v-col cols="12" md="6" v-if="hasLight">
                        <v-card flat class="d-flex justify-center">
                            <LoadingBar v-if="$apollo.queries.trackerLights.loading"></LoadingBar>
                            <apexcharts
                                v-else
                                :disabled="$apollo.queries.trackerLights.loading"
                                :width="chartWidth"
                                type="line"
                                :options="options2"
                                :series="series2"
                            ></apexcharts>
                        </v-card>
                    </v-col>
                    <v-col cols="12" md="6" v-if="hasHumidity">
                        <v-card flat class="d-flex justify-center">
                            <LoadingBar v-if="$apollo.queries.trackerHumiditys.loading"></LoadingBar>
                            <apexcharts
                                v-else
                                :disabled="$apollo.queries.trackerHumiditys.loading"
                                :width="chartWidth"
                                type="line"
                                :options="options3"
                                :series="series3"
                            ></apexcharts
                        ></v-card>
                    </v-col>
                    <v-col cols="12" md="6" v-if="hasShock">
                        <v-card flat class="d-flex justify-center">
                            <LoadingBar v-if="$apollo.queries.trackerShocks.loading"></LoadingBar>
                            <apexcharts
                                v-else
                                :disabled="$apollo.queries.trackerShocks.loading"
                                :width="chartWidth"
                                type="line"
                                :options="options4"
                                :series="series4"
                            ></apexcharts>
                        </v-card>
                    </v-col>
                </v-row>
            </div>
        </v-card-text>
    </v-card>
</template>

<script>
import VueApexCharts from "vue-apexcharts";
import LoadingBar from "@/components/base/LoadingBar.vue";
import gql from "graphql-tag";
import helper from "../../../utils/helper";
import moment from "moment";
import ExportTrackerDataDialog from "@/components/trackers/other/ExportTrackerDataDialog.vue";
import Config from "@/utils/config.json";

export default {
    props: {
        tracker: {
            type: Object,
            required: true
        }
    },
    components: {
        apexcharts: VueApexCharts,
        LoadingBar,
        ExportTrackerDataDialog
    },
    data() {
        return {
            trackerTemperature: { edges: [] },
            trackerHumiditys: { edges: [] },
            trackerLights: { edges: [] },
            trackerShocks: { edges: [] },
            trackerDateRange: [
                { text: this.$t("date.last24"), value: "last24hours" },
                { text: this.$t("date.last48"), value: "last48hours" },
                { text: this.$t("date.last72"), value: "last72hours" },
                { text: this.$t("date.last7"), value: "last7days" },
                { text: this.$t("date.last30"), value: "last30days" },
                { text: this.$t("date.last60"), value: "last60days" },
                { text: this.$t("date.last90"), value: "last90days" },
                { text: this.$t("date.custom"), value: "custom" }
            ],
            relativeTrackerDateRange: "last24hours",
            hasHumidity: false,
            hasLight: false,
            hasShock: false,
            dateRange: [],
            savedDateRange: [],
            dateRangeMenu: false,
            deviceTime_Gte: null,
            deviceTime_Lte: null,
            options: {
                chart: {
                    id: "temperature-chart",
                    height: "100%",
                    width: "100%",
                    animations: {
                        enabled: false
                    },
                    toolbar: {
                        show: true
                    }
                },
                grid: {
                    show: true,
                    borderColor: "#90A4AE",
                    strokeDashArray: 1,
                    position: "back",
                    xaxis: {
                        lines: {
                            show: true
                        }
                    },
                    yaxis: {
                        lines: {
                            show: true
                        }
                    },
                    row: {
                        colors: "",
                        opacity: 0.5
                    },
                    column: {
                        colors: "",
                        opacity: 0.5
                    },
                    padding: {
                        top: 0,
                        right: 16,
                        bottom: 0,
                        left: 16
                    }
                },
                noData: {
                    text: this.$t("loading.no_available"),
                    align: "center",
                    verticalAlign: "middle"
                },
                zoom: {
                    autoScaleYaxis: true
                },
                xaxis: {
                    type: "datetime",
                    min: null, //start date
                    max: null, // end date
                    tickAmount: 4
                },
                yaxis: {
                    title: {
                        text: [this.$t("general.temperature")]
                    }
                },
                colors: ["#1B6A99FF", "#71C4ECFF", "#71C4ECFF"],
                tooltip: {
                    x: {
                        show: false,
                        format: "dd MMM yyyy HH:mm"
                    }
                },
                stroke: {
                    width: [2, 2, 2],
                    dashArray: [0, 0, 0]
                },
                fill: {
                    opacity: 1
                }
            },
            series: [],
            options2: {
                chart: {
                    id: "light-chart",
                    height: "100%",
                    width: "100%",
                    animations: {
                        enabled: false
                    },
                    toolbar: {
                        show: true
                    }
                },
                grid: {
                    show: true,
                    borderColor: "#90A4AE",
                    strokeDashArray: 1,
                    position: "back",
                    xaxis: {
                        lines: {
                            show: true
                        }
                    },
                    yaxis: {
                        lines: {
                            show: true
                        }
                    },
                    row: {
                        colors: "",
                        opacity: 0.5
                    },
                    column: {
                        colors: "",
                        opacity: 0.5
                    },
                    padding: {
                        top: 0,
                        right: 16,
                        bottom: 0,
                        left: 16
                    }
                },
                noData: {
                    text: this.$t("loading.no_available"),
                    align: "center",
                    verticalAlign: "middle"
                },
                zoom: {
                    autoScaleYaxis: true
                },
                xaxis: {
                    type: "datetime",
                    min: null, //start date
                    max: null, // end date
                    tickAmount: 4
                },
                yaxis: {
                    title: {
                        text: this.$t("general.light") + " (Lux)"
                    }
                },
                colors: ["#DEBA04FF"],
                tooltip: {
                    x: {
                        show: false,
                        format: "dd MMM yyyy HH:mm"
                    }
                },
                stroke: {
                    width: [2, 2, 2],
                    dashArray: [0, 0, 0]
                },
                fill: {
                    opacity: 1
                }
            },
            series2: [],
            options3: {
                chart: {
                    id: "humidity-chart",
                    height: "100%",
                    width: "100%",
                    animations: {
                        enabled: false
                    },
                    toolbar: {
                        show: true
                    }
                },
                grid: {
                    show: true,
                    borderColor: "#90A4AE",
                    strokeDashArray: 1,
                    position: "back",
                    xaxis: {
                        lines: {
                            show: true
                        }
                    },
                    yaxis: {
                        lines: {
                            show: true
                        }
                    },
                    row: {
                        colors: "",
                        opacity: 0.5
                    },
                    column: {
                        colors: "",
                        opacity: 0.5
                    },
                    padding: {
                        top: 0,
                        right: 16,
                        bottom: 0,
                        left: 16
                    }
                },
                noData: {
                    text: this.$t("loading.no_available"),
                    align: "center",
                    verticalAlign: "middle"
                },
                zoom: {
                    autoScaleYaxis: true
                },
                xaxis: {
                    type: "datetime",
                    min: null, //start date
                    max: null, // end date
                    tickAmount: 4
                },
                yaxis: {
                    title: {
                        text: this.$t("general.humidity") + " %"
                    }
                },
                colors: ["#07A98BFF"],
                tooltip: {
                    x: {
                        show: false,
                        format: "dd MMM yyyy HH:mm"
                    }
                },
                stroke: {
                    width: [2, 2, 2],
                    dashArray: [0, 0, 0]
                },
                fill: {
                    opacity: 1
                }
            },
            series3: [],
            options4: {
                chart: {
                    id: "shock-chart",
                    type: "line",
                    height: "100%",
                    width: "100%",
                    animations: {
                        enabled: false
                    },
                    toolbar: {
                        show: true
                    }
                },
                grid: {
                    show: true,
                    borderColor: "#90A4AE",
                    strokeDashArray: 1,
                    position: "back",
                    xaxis: {
                        lines: {
                            show: true
                        }
                    },
                    yaxis: {
                        lines: {
                            show: true
                        }
                    },
                    row: {
                        colors: "",
                        opacity: 0.5
                    },
                    column: {
                        colors: "",
                        opacity: 0.5
                    },
                    padding: {
                        top: 0,
                        right: 16,
                        bottom: 0,
                        left: 16
                    }
                },
                noData: {
                    text: this.$t("loading.no_available"),
                    align: "center",
                    verticalAlign: "middle"
                },
                zoom: {
                    autoScaleYaxis: true
                },
                
                xaxis: {
                    type: "datetime",
                    min: null, //start date
                    max: null, // end date
                    tickAmount: 4
                },
                yaxis: {
                    title: {
                        text: this.$t("general.shock")
                    }
                },
                colors: ["#FF0000FF"],
                tooltip: {
                    x: {
                        show: false,
                        format: "dd MMM yyyy HH:mm"
                    }
                },
                stroke: {
                    width: [2, 2, 2],
                    dashArray: [0, 0, 0]
                },
                fill: {
                    opacity: 1
                }
            },
            series4: []
        };
    },
    created() {
        this.formatDateTime = helper.formatDateTime;
        this.formatTemperature = helper.formatTemperature;
        this.updateDateTimeTimezone = helper.updateDateTimeTimezone;
        this.getTimezone = helper.getTimezone;
        this.getTemperatureSymbol = helper.getTemperatureSymbol;
        this.options.yaxis.title.text = this.$t("general.temperature") + this.getTemperatureSymbol();

        this.deviceTime_Lte = this.updateDateTimeTimezone(moment().toISOString());
        this.deviceTime_Gte = this.updateDateTimeTimezone(
            moment(this.deviceTime_Lte)
                .subtract(1, "days")
                .toISOString()
        );

        this.dateRange = [this.deviceTime_Gte.slice(0, 10), this.deviceTime_Lte.slice(0, 10)];
        this.updateMinMax(this.deviceTime_Gte, this.deviceTime_Lte);
    },
    mounted() {
        if (this.tracker.lastHumidityValue != null) {
            this.hasHumidity = true;
        }
        if (this.tracker.lastLightValue != null) {
            this.hasLight = true;
        }
        if (this.tracker.lastShockValue != null) {
            this.hasShock = true;
        }
        //Gtag
        this.$gtag.screenview({
            app_name: Config.VUE_APP_APPLICATION_NAME,
            screen_name: "tracker_charts_tab_svw"
        });
        this.setUserProperties();
    },
    computed: {
        dateRangeText() {
            if (this.dateRange.length > 1) {
                let date1 = moment(this.dateRange[0], "YYYY-MM-DD").format(helper.getDateFormat());
                let date2 = moment(this.dateRange[1], "YYYY-MM-DD").format(helper.getDateFormat());
                return date1 + " ~ " + date2;
            } else if (this.dateRange.length === 1) {
                return moment(this.dateRange[0], "YYYY-MM-DD").format(helper.getDateFormat());
            } else {
                return this.dateRange;
            }
        },
        chartWidth() {
            if (this.$vuetify.breakpoint.xsOnly) {
                return 300;
            } else if (this.$vuetify.breakpoint.smOnly) {
                return 300;
            } else if (this.$vuetify.breakpoint.mdOnly) {
                return 300;
            } else if (this.$vuetify.breakpoint.lgOnly) {
                return 400;
            } else if (this.$vuetify.breakpoint.xlOnly) {
                return 500;
            } else {
                return 500;
            }
        }
    },
    apollo: {
        trackerTemperature: {
            query: gql`
                query trackerTemperature(
                    $trackerId: ID!
                    $deviceTime_Gte: DateTime
                    $deviceTime_Lte: DateTime
                    $orderBy: String
                ) {
                    trackerTemperatures(
                        tracker: $trackerId
                        deviceTime_Gte: $deviceTime_Gte
                        deviceTime_Lte: $deviceTime_Lte
                        orderBy: $orderBy
                    ) {
                        edges {
                            node {
                                deviceTime
                                value
                                receiveTime
                            }
                        }
                    }
                    trackerExternalTemperatures(
                        tracker: $trackerId
                        deviceTime_Gte: $deviceTime_Gte
                        deviceTime_Lte: $deviceTime_Lte
                        orderBy: $orderBy
                    ) {
                        edgeCount
                        edges {
                            node {
                                deviceTime
                                value
                                receiveTime
                            }
                        }
                    }
                    trackerProbeTemperatures(
                        tracker: $trackerId
                        deviceTime_Gte: $deviceTime_Gte
                        deviceTime_Lte: $deviceTime_Lte
                        orderBy: $orderBy
                    ) {
                        edgeCount
                        edges {
                            node {
                                deviceTime
                                value
                                receiveTime
                            }
                        }
                    }
                }
            `,
            variables() {
                return {
                    trackerId: this.tracker.id,
                    deviceTime_Gte: this.deviceTime_Gte,
                    deviceTime_Lte: this.deviceTime_Lte,
                    orderBy: "deviceTime"
                };
            },
            skip() {
                return !this.deviceTime_Gte;
            },
            // Additional options here
            fetchPolicy: "cache-and-network",
            nextFetchPolicy: "cache-first", // this setting can avoid query again after fetchMore
            update: data => data.trackerTemperature,
            debounce: 200,
            result(data) {
                this.series = [
                    {
                        name: this.$t("general.temperature"),
                        data: [
                            ...data.data.trackerTemperatures.edges.map(item => {
                                return [
                                    new Date(this.updateDateTimeTimezone(item.node.deviceTime)).getTime(),
                                    this.formatTemperature(item.node.value).toFixed(2)
                                ];
                            })
                        ]
                    }
                ];
                if (data.data.trackerExternalTemperatures.edgeCount > 0) {
                    this.series.push({
                        name: this.$t("general.external_temperature"),
                        data: [
                            ...data.data.trackerExternalTemperatures.edges.map(item => {
                                return [
                                    new Date(this.updateDateTimeTimezone(item.node.deviceTime)).getTime(),
                                    this.formatTemperature(item.node.value).toFixed(2)
                                ];
                            })
                        ]
                    });
                }
                if (data.data.trackerProbeTemperatures.edgeCount > 0) {
                    this.series.push({
                        name: this.$t("general.probe_temperature"),
                        data: [
                            ...data.data.trackerProbeTemperatures.edges.map(item => {
                                return [
                                    new Date(this.updateDateTimeTimezone(item.node.deviceTime)).getTime(),
                                    this.formatTemperature(item.node.value).toFixed(2)
                                ];
                            })
                        ]
                    });
                }
            }
        },
        trackerLights: {
            query: gql`
                query trackerLights(
                    $trackerId: ID!
                    $deviceTime_Gte: DateTime
                    $deviceTime_Lte: DateTime
                    $orderBy: String
                ) {
                    trackerLights(
                        tracker: $trackerId
                        deviceTime_Gte: $deviceTime_Gte
                        deviceTime_Lte: $deviceTime_Lte
                        orderBy: $orderBy
                    ) {
                        edges {
                            node {
                                deviceTime
                                value
                                receiveTime
                            }
                        }
                    }
                }
            `,
            variables() {
                return {
                    trackerId: this.tracker.id,
                    deviceTime_Gte: this.deviceTime_Gte,
                    deviceTime_Lte: this.deviceTime_Lte,
                    orderBy: "deviceTime"
                };
            },

            // Additional options here
            skip() {
                return !this.hasLight;
            },
            fetchPolicy: "cache-and-network",
            nextFetchPolicy: "cache-first", // this setting can avoid query again after fetchMore
            update: data => data.trackerLights,
            debounce: 200,
            result(data) {
                this.series2 = [
                    {
                        name: this.$t("general.light"),
                        data: [
                            ...data.data.trackerLights.edges.map(item => {
                                return [
                                    new Date(this.updateDateTimeTimezone(item.node.deviceTime)).getTime(),
                                    item.node.value.toFixed(2)
                                ];
                            })
                        ]
                    }
                ];
            }
        },
        trackerHumiditys: {
            query: gql`
                query trackerHumiditys(
                    $trackerId: ID!
                    $deviceTime_Gte: DateTime
                    $deviceTime_Lte: DateTime
                    $orderBy: String
                ) {
                    trackerHumiditys(
                        tracker: $trackerId
                        deviceTime_Gte: $deviceTime_Gte
                        deviceTime_Lte: $deviceTime_Lte
                        orderBy: $orderBy
                    ) {
                        edges {
                            node {
                                deviceTime
                                value
                                receiveTime
                            }
                        }
                    }
                }
            `,
            variables() {
                return {
                    trackerId: this.tracker.id,
                    deviceTime_Gte: this.deviceTime_Gte,
                    deviceTime_Lte: this.deviceTime_Lte,
                    orderBy: "deviceTime"
                };
            },

            // Additional options here
            skip() {
                return !this.hasHumidity;
            },
            fetchPolicy: "cache-and-network",
            nextFetchPolicy: "cache-first", // this setting can avoid query again after fetchMore
            update: data => data.trackerHumiditys,
            debounce: 200,
            result(data) {
                this.series3 = [
                    {
                        name: "Humidity",
                        data: [
                            ...data.data.trackerHumiditys.edges.map(item => {
                                return [
                                    new Date(this.updateDateTimeTimezone(item.node.deviceTime)).getTime(),
                                    item.node.value.toFixed(2)
                                ];
                            })
                        ]
                    }
                ];
            }
        },
        trackerShocks: {
            query: gql`
                query trackerShocks(
                    $trackerId: ID!
                    $deviceTime_Gte: DateTime
                    $deviceTime_Lte: DateTime
                    $orderBy: String
                ) {
                    trackerShocks(
                        tracker: $trackerId
                        deviceTime_Gte: $deviceTime_Gte
                        deviceTime_Lte: $deviceTime_Lte
                        orderBy: $orderBy
                    ) {
                        edges {
                            node {
                                deviceTime
                                value
                                receiveTime
                            }
                        }
                    }
                }
            `,
            variables() {
                return {
                    trackerId: this.tracker.id,
                    deviceTime_Gte: this.deviceTime_Gte,
                    deviceTime_Lte: this.deviceTime_Lte,
                    orderBy: "deviceTime"
                };
            },

            // Additional options here
            skip() {
                return !this.hasShock;
            },
            fetchPolicy: "cache-and-network",
            nextFetchPolicy: "cache-first", // this setting can avoid query again after fetchMore
            update: data => data.trackerShocks,
            debounce: 200,
            result(data) {
                this.series4 = [
                    {
                        name: "Shock",
                        data: [
                            ...data.data.trackerShocks.edges.map(item => {
                                return [
                                    new Date(this.updateDateTimeTimezone(item.node.deviceTime)).getTime(),
                                    item.node.value.toFixed(2)
                                ];
                            })
                        ]
                    }
                ];
            }
        }
    },

    watch: {
        savedDateRange() {
            if (this.savedDateRange.length == 2) {
                this.deviceTime_Gte = this.savedDateRange[0].includes("T")
                    ? this.savedDateRange[0]
                    : this.savedDateRange[0] + "T00:00:00";
                this.deviceTime_Lte = this.savedDateRange[1].includes("T")
                    ? this.savedDateRange[1]
                    : this.savedDateRange[1] + "T23:59:59";
                this.updateMinMax(this.deviceTime_Gte, this.deviceTime_Lte);
            } else if (this.savedDateRange.length == 1) {
                this.deviceTime_Gte = this.savedDateRange[0] + "T00:00:00";
                this.deviceTime_Lte = this.savedDateRange[0] + "T23:59:59";
                this.updateMinMax(this.deviceTime_Gte, this.deviceTime_Lte);
            }

            this.$apollo.queries.trackerTemperature.refetch();
        },
        relativeTrackerDateRange(val) {
            let _now = this.updateDateTimeTimezone(moment().toISOString());
            let _startDate = null;
            let _endDate = null;
            switch (val) {
                case "last24hours":
                    _endDate = _now;
                    _startDate = moment(_now)
                        .subtract(1, "days")
                        .toISOString();
                    this.updateMinMax(_startDate, _endDate);
                    this.dateRange = [_startDate.slice(0, 10), _endDate.slice(0, 10)];
                    this.savedDateRange = [_startDate, _endDate];
                    break;
                case "last48hours":
                    _endDate = _now;
                    _startDate = moment(_now)
                        .subtract(2, "days")
                        .toISOString();
                    this.updateMinMax(_startDate, _endDate);
                    this.dateRange = [_startDate.slice(0, 10), _endDate.slice(0, 10)];
                    this.savedDateRange = [_startDate, _endDate];
                    break;
                case "last72hours":
                    _endDate = _now;
                    _startDate = moment(_now)
                        .subtract(3, "days")
                        .toISOString();
                    this.updateMinMax(_startDate, _endDate);
                    this.dateRange = [_startDate.slice(0, 10), _endDate.slice(0, 10)];
                    this.savedDateRange = [_startDate, _endDate];
                    break;
                case "last7days":
                    _endDate = _now;
                    _startDate = moment(_now)
                        .subtract(7, "days")
                        .toISOString();
                    this.updateMinMax(_startDate, _endDate);
                    this.dateRange = [_startDate.slice(0, 10), _endDate.slice(0, 10)];
                    this.savedDateRange = [_startDate, _endDate];
                    break;
                case "last30days":
                    _endDate = _now;
                    _startDate = moment(_now)
                        .subtract(1, "months")
                        .toISOString();
                    this.updateMinMax(_startDate, _endDate);
                    this.dateRange = [_startDate.slice(0, 10), _endDate.slice(0, 10)];
                    this.savedDateRange = [_startDate, _endDate];
                    break;
                case "last60days":
                    _endDate = _now;
                    _startDate = moment(_now)
                        .subtract(2, "months")
                        .toISOString();
                    this.updateMinMax(_startDate, _endDate);
                    this.dateRange = [_startDate.slice(0, 10), _endDate.slice(0, 10)];
                    this.savedDateRange = [_startDate, _endDate];
                    break;
                case "last90days":
                    _endDate = _now;
                    _startDate = moment(_now)
                        .subtract(3, "months")
                        .toISOString();
                    this.updateMinMax(_startDate, _endDate);
                    this.dateRange = [_startDate.slice(0, 10), _endDate.slice(0, 10)];
                    this.savedDateRange = [_startDate, _endDate];
                    break;
                case "custom":
                    break;
                default:
                    this.dateRange = [];
                    this.savedDateRange = [];
            }
        }
    },
    methods: {
        setUserProperties() {
            if (this.me?.isPartnerUser) {
                this.me?.allTenantPartners.forEach(c => {
                    this.$gtag.event("tracker_charts_tab_svw", {
                        tenant_id: this.me?.tenant?.id,
                        tenant_name: this.me?.tenant?.name,
                        partner_id: c.id,
                        user_id: this.me?.id
                    });
                });
            } else {
                this.$gtag.event("tracker_charts_tab_svw", {
                    tenant_id: this.me?.tenant?.id,
                    tenant_name: this.me?.tenant?.name,
                    partner_id: null,
                    user_id: this.me?.id
                });
            }
        },
        onClearDates() {
            this.dateRange = [];
            this.savedDateRange = [];
        },
        cancelDatePicker() {
            this.dateRangeMenu = false;
            if (this.savedDateRange.length == 0) {
                this.dateRange = [];
            }
        },
        formatDate(date) {
            if (!date) return null;
            const [year, month, day] = date.split("-");
            return `${day}/${month}/${year}`;
        },
        saveDatePicker() {
            this.dateRangeMenu = false;
            this.savedDateRange = this.dateRange;
            this.relativeTrackerDateRange = "custom";
        },
        updateMinMax(start, end) {
            let min = new Date(start).getTime();
            let max = new Date(end).getTime();
            let _min = min - 1000 * 60 * 60 * 1;
            let _max = max + 1000 * 60 * 60 * 3;

            this.options.xaxis.min = _min;
            this.options.xaxis.max = _max;
            this.options2.xaxis.min = _min;
            this.options2.xaxis.max = _max;
            this.options3.xaxis.min = _min;
            this.options3.xaxis.max = _max;
            this.options4.xaxis.min = _min;
            this.options4.xaxis.max = _max;
        }
    }
};
</script>
