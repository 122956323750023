<template>
    <v-row align="start" class="align-center" dense>
        <v-col cols="12" xs="12" sm="12" md="12" lg="12" xl="12">
            <v-select
                v-model="codeSelected"
                :items="dynamicFilters"
                :label="$t('trips.filter')"
                item-text="name"
                item-value="code"
                clearable
                :readonly="isReadOnly"
                :disabled="isReadOnly"
                dense
                outlined
                color="primary"
                hide-details
                :menu-props="{
                    offsetX: true,
                    right: true
                }"
                @change="updateCodeSelected"
            ></v-select>
        </v-col>
        <v-col cols="12" xs="12" sm="12" md="12" lg="12" xl="12">
            <!-- ?Fake field -->
            <v-text-field
                v-if="codeSelected === '' || codeSelected === null"
                v-model="menuObject['']"
                :label="getFilterChoiceNameByCode('')"
                clearable
                hide-details
                dense
                outlined
                disabled
            ></v-text-field>

            <!-- Trip Reference Number -->
            <v-text-field
                v-if="codeSelected === 'trip_referenceNumber'"
                v-model="menuObject['trip_ReferenceNumber_Icontains']"
                :label="getFilterChoiceNameByCode('trip_referenceNumber')"
                clearable
                hide-details
                dense
                outlined
            ></v-text-field>

            <!-- Trip Status -->
            <v-select
                v-if="codeSelected === 'trip_status'"
                v-model="menuObject['trip_Status_Icontains']"
                :items="statusChoices"
                :label="getFilterChoiceNameByCode('tripStatus')"
                clearable
                hide-details
                dense
                outlined
                color="primary"
            ></v-select>

            <!-- Alert Rule Name -->
            <v-autocomplete
                v-if="codeSelected === 'tenantAlertRule_name'"
                v-model="menuObject['tenantAlertRule_Name_Icontains']"
                :items="alertRuleNames"
                :label="getFilterChoiceNameByCode('tenantAlertRule_name')"
                item-text="text"
                item-value="value"
                hide-details
                dense
                clearable
                outlined
                :disabled="alertRulesLoading"
            ></v-autocomplete>

            <!-- Alert Rule Tag -->
            <v-autocomplete
                v-if="codeSelected === 'tenantAlertRule_tag'"
                v-model="menuObject['tenantAlertRule_Tag_Icontains']"
                :items="alertTags"
                :label="getFilterChoiceNameByCode('tenantAlertRule_tag')"
                item-text="text"
                item-value="value"
                clearable
                hide-details
                dense
                outlined
            ></v-autocomplete>

            <!-- Trip Freight Reference Number -->
            <v-text-field
                v-if="codeSelected === 'tripFreight_referenceNumber'"
                v-model="menuObject['tripFreight_ReferenceNumber_Icontains']"
                :label="getFilterChoiceNameByCode('tripFreight_referenceNumber')"
                clearable
                hide-details
                dense
                outlined
            ></v-text-field>

            <!-- Trip Freight Container Number -->
            <v-text-field
                v-if="codeSelected === 'tripFreight_containerNumber'"
                v-model="menuObject['tripFreight_ContainerNumber_Icontains']"
                :label="getFilterChoiceNameByCode('tripFreight_containerNumber')"
                clearable
                hide-details
                dense
                outlined
            ></v-text-field>

            <!-- Trip Freight Air Waybill Number -->
            <v-text-field
                v-if="codeSelected === 'tripFreight_airWaybillNumber'"
                v-model="menuObject['tripFreight_AirWaybillNumber_Icontains']"
                :label="getFilterChoiceNameByCode('tripFreight_airWaybillNumber')"
                clearable
                hide-details
                dense
                outlined
            ></v-text-field>

            <!-- Trip Freight Tracker Serial Number -->
            <v-text-field
                v-if="codeSelected === 'tripFreightTracker_serialNumber'"
                v-model="menuObject['tripFreightTracker_Tracker_SerialNumber_Icontains']"
                :label="getFilterChoiceNameByCode('tripFreightTracker_serialNumber')"
                clearable
                hide-details
                dense
                outlined
            ></v-text-field>

            <!-- Trip Carrier Name -->
            <PartnerTripsCarrier
                v-if="codeSelected === 'trip_tenantCarrier'"
                ref="carrier"
                :refreshTable="refreshTableCarrier"
                v-model="menuObject['trip_TenantCarrier']"
                :label="getFilterChoiceNameByCode('trip_tenantCarrier')"
                :name.sync="carrierName"
                :outlined="true"
            ></PartnerTripsCarrier>
            <!-- <v-text-field
               
                v-model=""
                :label=""
                clearable
                hide-details
                dense
                outlined
            ></v-text-field> -->

            <!-- Trip Vessel Name -->
            <VesselFilter
                v-if="codeSelected === 'trip_tripSeaTransportSet_vessel'"
                v-model="menuObject['trip_TripSeaTransportSet_Vessel']"
                :refreshTable="refreshTableVessel"
                :label="getFilterChoiceNameByCode('trip_tripSeaTransportSet_vessel')"
                :name.sync="polvesselName"
                :outlined="true"
            >
            </VesselFilter>
            <!-- !Relative Planned Departure Date -->
            <v-select
                v-if="codeSelected === 'relativeAlertTimeRange'"
                v-model="relativeAlertTimeRange"
                :items="relativeAlertTimeRangeChoices"
                :label="getFilterChoiceNameByCode('relativeAlertTimeRange')"
                clearable
                hide-details
                dense
                outlined
                color="primary"
            ></v-select>

            <!-- Alert Trigger Date -->
            <v-menu
                ref="alertTimeMenu"
                v-if="codeSelected === 'alertTime'"
                v-model="alertTimeMenu"
                :close-on-content-click="false"
                :return-value.sync="savedAlertTimeRange"
                transition="scale-transition"
                offset-y
                max-width="auto"
                min-width="auto"
                :disabled="!!relativeAlertTimeRange"
            >
                <template
                    v-slot:activator="{
                        on,
                        attrs
                    }"
                >
                    <v-text-field
                        :value="alertTimeRangeText"
                        :label="getFilterChoiceNameByCode('alertTime')"
                        readonly
                        clearable
                        hide-details
                        dense
                        @click:clear="onClearAlertTime()"
                        v-bind="attrs"
                        v-on="on"
                        :disabled="!!relativeAlertTimeRange"
                        outlined
                    ></v-text-field>
                </template>
                <v-date-picker
                    v-model="alertTimeRange"
                    :first-day-of-week="1"
                    range
                    no-title
                    scrollable
                    color="primary"
                >
                    <v-spacer></v-spacer>
                    <v-btn text rounded color="primary" @click="cancelAlertTimePicker">
                        {{ $t("general.cancel") }}
                    </v-btn>
                    <v-btn
                        outlined
                        rounded
                        color="primary"
                        :disabled="alertTimeRange.length < 1"
                        @click="saveAlertTime"
                    >
                        OK
                    </v-btn>
                </v-date-picker>
            </v-menu>

            <!-- Trade -->
            <v-text-field
                v-if="codeSelected === 'trade'"
                v-model="menuObject['trade_Icontains']"
                :label="getFilterChoiceNameByCode('trade')"
                clearable
                hide-details
                dense
                outlined
            ></v-text-field>

            <!-- POL Code -->
            <PortFilter
                v-if="codeSelected === 'pol'"
                v-model="menuObject['pol']"
                :label="getFilterChoiceNameByCode('pol')"
                :refreshTable="refreshTablePorts"
                :outlined="true"
            >
            </PortFilter>

            <!-- POD Code -->
            <PortFilter
                v-if="codeSelected === 'pod'"
                v-model="menuObject['pod']"
                :label="getFilterChoiceNameByCode('pod')"
                :refreshTable="refreshTablePorts"
                :outlined="true"
            >
            </PortFilter>

            <!-- Transshipment Code -->
            <PortFilter
                v-if="codeSelected === 'transshipment'"
                v-model="menuObject['transshipment']"
                :label="getFilterChoiceNameByCode('transshipment')"
                :refreshTable="refreshTablePorts"
                :outlined="true"
            >
            </PortFilter>

            <!-- Goods -->
            <v-text-field
                v-if="codeSelected === 'goods'"
                v-model="menuObject['goods']"
                :label="getFilterChoiceNameByCode('goods')"
                clearable
                hide-details
                dense
                outlined
            ></v-text-field>

            <!-- Planned Departure Date -->
            <v-menu
                ref="plannedDepartureDateMenu"
                v-if="codeSelected === 'plannedDepartureDate'"
                v-model="plannedDepartureDateMenu"
                :close-on-content-click="false"
                :return-value.sync="savedPlannedDepartureDateRange"
                transition="scale-transition"
                offset-y
                max-width="auto"
                min-width="auto"
                :disabled="!!relativePlannedDepartureDateRange"
            >
                <template
                    v-slot:activator="{
                        on,
                        attrs
                    }"
                >
                    <v-text-field
                        :value="plannedDepartureDateRangeText"
                        :label="getFilterChoiceNameByCode('plannedDepartureDate')"
                        readonly
                        clearable
                        hide-details
                        dense
                        @click:clear="onClearPlannedDepartureDates()"
                        v-bind="attrs"
                        v-on="on"
                        outlined
                    ></v-text-field>
                </template>
                <v-date-picker
                    v-model="plannedDepartureDateRange"
                    :first-day-of-week="1"
                    range
                    no-title
                    scrollable
                    color="primary"
                >
                    <v-spacer></v-spacer>
                    <v-btn text rounded color="primary" @click="cancelPlannedDepartureDatePicker">
                        {{ $t("general.cancel") }}
                    </v-btn>
                    <v-btn
                        outlined
                        rounded
                        color="primary"
                        :disabled="plannedDepartureDateRange.length < 1"
                        @click="savePlannedDepartureDates"
                    >
                        OK
                    </v-btn>
                </v-date-picker>
            </v-menu>

            <!-- Planned Arrival Date -->
            <v-menu
                v-if="codeSelected === 'plannedArrivalDate'"
                ref="plannedArrivalDateMenu"
                v-model="plannedArrivalDateMenu"
                :close-on-content-click="false"
                :return-value.sync="savedPlannedArrivalDateRange"
                transition="scale-transition"
                offset-y
                max-width="auto"
                min-width="auto"
            >
                <template
                    v-slot:activator="{
                        on,
                        attrs
                    }"
                >
                    <v-text-field
                        :value="plannedArrivalDateRangeText"
                        :label="getFilterChoiceNameByCode('plannedArrivalDate')"
                        readonly
                        clearable
                        hide-details
                        dense
                        @click:clear="onClearPlannedArrivalDates()"
                        v-bind="attrs"
                        v-on="on"
                        outlined
                    ></v-text-field>
                </template>
                <v-date-picker
                    v-model="plannedArrivalDateRange"
                    :first-day-of-week="1"
                    range
                    no-title
                    scrollable
                    color="primary"
                >
                    <v-spacer></v-spacer>
                    <v-btn text rounded color="primary" @click="cancelPlannedArrivalDatePicker">
                        {{ $t("general.cancel") }}
                    </v-btn>
                    <v-btn
                        outlined
                        rounded
                        color="primary"
                        :disabled="plannedArrivalDateRange.length < 1"
                        @click="savePlannedArrivalDates"
                    >
                        OK
                    </v-btn>
                </v-date-picker>
            </v-menu>

            <!-- Contact Person -->
            <v-text-field
                v-if="codeSelected === 'contactPerson'"
                v-model="menuObject['tripFreightSet_Contact_Icontains']"
                :label="getFilterChoiceNameByCode('contactPerson')"
                clearable
                hide-details
                dense
                outlined
            ></v-text-field>

            <!-- Order Number -->
            <v-text-field
                v-if="codeSelected === 'orderNumber'"
                v-model="menuObject['orderNumber']"
                :label="getFilterChoiceNameByCode('orderNumber')"
                clearable
                hide-details
                dense
                outlined
            >
                <template v-slot:append>
                    <v-tooltip bottom content-class="tooltipContent">
                        <template v-slot:activator="{ on }">
                            <v-icon v-on="on" color="primary">
                                info
                            </v-icon>
                        </template>
                        <span>{{ $t("general.info_order_empty") }}</span>
                    </v-tooltip>
                </template>
            </v-text-field>

            <!-- Alert Code -->
            <v-autocomplete
                v-if="codeSelected === 'alertCode'"
                v-model="menuObject['alertCode']"
                :items="alertsType"
                :label="getFilterChoiceNameByCode('alertCode')"
                item-text="name"
                item-value="value"
                hide-details
                hide-selected
                dense
                clearable
                outlined
                multiple
            >
                <template v-slot:selection="{ attrs, item }">
                    <v-chip v-bind="attrs" close small @click:close="remove('alertCode', item)">
                        {{ item.name }}
                    </v-chip>
                </template>
            </v-autocomplete>

        </v-col>
    </v-row>
</template>

<script>
import helper from "@/utils/helper.js";
import moment from "moment";
import gql from "graphql-tag";
import PartnerTripsCarrier from "@/components/partners/PartnerTripsCarrier.vue";
import VesselFilter from "@/components/trips/other/VesselFilter.vue";
import PortFilter from "@/components/trips/other/PortFilter.vue";

export default {
    name: "AlertsFilterMenuTemplate",
    components: {
        VesselFilter,
        PartnerTripsCarrier,
        PortFilter
    },
    props: {
        value: {
            type: Object,
            required: true,
            default: () => ({})
        },
        isReadOnly: {
            type: Boolean,
            required: false,
            default: false
        },
        currentCode: {
            type: String,
            required: false,
            default: null
        },
        isSharedTrip: {
            type: Boolean,
            required: false,
            default: false
        }
    },
    data() {
        return {
            codeSelected: null,
            alertTimeMenu: false,
            alertTimeRange: [],
            relativeAlertTimeRange: null,
            relativeAlertTimeRangeChoices: [
                this.$t("date.last30"),
                this.$t("date.last90"),
                this.$t("date.last180"),
                this.$t("date.last360")
            ],
            savedAlertTimeRange: [],
            defaultFilterChoices: [
                {
                    code: "trip_referenceNumber",
                    name: "Trip Reference Number",
                    tag: "headers.trip_reference_number",
                    enabled: true
                },
                {
                    code: "trip_status",
                    name: "Status",
                    tag: "headers.trip_status",
                    enabled: true
                },
                {
                    code: "tenantAlertRule_name",
                    name: "Alert Name",
                    tag: "headers.alert_name",
                    enabled: true
                },
                {
                    code: "tenantAlertRule_tag",
                    name: "Alert Tag",
                    tag: "headers.alert_tag",
                    enabled: true
                },
                {
                    code: "tripFreight_referenceNumber",
                    name: "Freight Reference Number",
                    tag: "headers.freight_reference_number",
                    enabled: true
                },
                {
                    code: "tripFreight_containerNumber",
                    name: "Container Number",
                    tag: "headers.freight_container_number",
                    enabled: true
                },
                {
                    code: "tripFreight_airWaybillNumber",
                    name: "Air Waybill Number",
                    tag: "headers.freight_air_waybill_number",
                    enabled: true
                },
                {
                    code: "tripFreightTracker_serialNumber",
                    name: "Tracker Number",
                    tag: "headers.freight_tracker_serial_number",
                    enabled: true
                },
                {
                    code: "trip_tenantCarrier",
                    name: "Carrier",
                    tag: "headers.carrier_name",
                    enabled: true
                },
                {
                    code: "trip_tripSeaTransportSet_vessel",
                    name: "Vessel",
                    tag: "headers.vessel_name",
                    enabled: true
                },
                {
                    code: "alertTime",
                    name: "Alert Trigger Date",
                    tag: "headers.alert_trigger_date",
                    enabled: true
                },
                {
                    code: "trade",
                    name: "Trade",
                    tag: "headers.trade",
                    enabled: true
                },
                {
                    code: "pol",
                    name: "POL Code",
                    tag: "headers.pol_code",
                    enabled: true
                },
                {
                    code: "pod",
                    name: "POD Code",
                    tag: "headers.pod_code",
                    enabled: true
                },
                {
                    code: "transshipment",
                    name: "T/S Code",
                    tag: "headers.ts_code",
                    enabled: true
                },
                {
                    code: "goods",
                    name: "Goods",
                    tag: "headers.goods",
                    enabled: true
                },
                {
                    code: "plannedDepartureDate",
                    name: "Planned Departure Date",
                    tag: "headers.planned_departure",
                    enabled: true
                },
                {
                    code: "plannedArrivalDate",
                    name: "Planned Arrival Date",
                    tag: "headers.planned_arrival",
                    enabled: true
                },
                {
                    code: "contactPerson",
                    name: "Contact Person",
                    tag: "forms.contact_person",
                    enabled: true
                },
                {
                    code: "orderNumber",
                    name: "Order Number",
                    tag: "headers.order_number",
                    enabled: true
                },
                {
                    code: "alertCode",
                    name: "Alert Type",
                    tag: "headers.alert_type",
                    enabled: true
                },
            ],
            carrierName: "",
            refreshTableCarrier: false,
            polvesselName: "",
            refreshTableVessel: false,
            alertRules: [],
            alertRulesLoading: false,
            alertTags: [
                { value: "temperature", text: this.$t("general.temperature") },
                { value: "humidity", text: this.$t("general.humidity") },
                { value: "light", text: this.$t("general.light") },
                {
                    value: "temperature_humidity",
                    text: this.$t("general.temperature_humidity")
                },
                {
                    value: "temperature_humidity_light",
                    text: this.$t("general.temperature_humidity_light")
                },
                { value: "pressure", text: this.$t("general.pressure") }
            ],
            refreshTablePorts: false,
            plannedDepartureDateMenu: false,
            plannedArrivalDateMenu: false,
            plannedDepartureDateRange: [],
            plannedArrivalDateRange: [],
            savedPlannedDepartureDateRange: [],
            savedPlannedArrivalDateRange: [],
            relativePlannedArrivalDateRange: null,
            relativePlannedArrivalDateRangeChoices: [
                this.$t("date.last30"),
                this.$t("date.last90"),
                this.$t("date.last180"),
                this.$t("date.last360")
            ],
            relativePlannedDepartureDateRange: null,
            relativePlannedDepartureDateRangeChoices: [
                this.$t("date.last30"),
                this.$t("date.last90"),
                this.$t("date.last180"),
                this.$t("date.last360")
            ],
        };
    },
    apollo: {
        alertRules: {
            query: gql`
                query tenantAlertRules {
                    tenantAlertRules {
                        edges {
                            node {
                                id
                                name
                            }
                        }
                    }
                }
            `,
            variables() {
                return {};
            },
            fetchPolicy: "cache-and-network",
            nextFetchPolicy: "cache-first",
            update: data => data.tenantAlertRules,
            watchLoading(isLoading) {
                this.alertRulesLoading = isLoading;
            },
            executeOnce: true
        }
    },
    watch: {
        currentCode: {
            handler(val) {
                this.codeSelected = val;
            },
            immediate: true
        },
        savedAlertTimeRange(val) {
            if (val.length == 1) {
                this.menuObject["alertTime_Gte"] = val[0] + "T00:00:00";
                this.menuObject["alertTime_Lte"] = val[0] + "T23:59:59";
            } else if (val.length > 1) {
                this.menuObject["alertTime_Gte"] = val[0] + "T00:00:00";
                this.menuObject["alertTime_Lte"] = val[1] + "T23:59:59";
            } else {
                this.menuObject["alertTime_Gte"] = null;
                this.menuObject["alertTime_Lte"] = null;
            }
        },
        relativePlannedArrivalDateRange(val) {
            const _index = this.relativePlannedArrivalDateRangeChoices.indexOf(val);
            let _startDate = null;
            let _endDate = null;
            switch (_index) {
                case 0:
                    // last month
                    _endDate = moment().format("YYYY-MM-DD");
                    _startDate = moment()
                        .date(0)
                        .startOf("month")
                        .format("YYYY-MM-DD");
                    this.plannedArrivalDateRange = [_startDate, _endDate];
                    this.savedPlannedArrivalDateRange = [_startDate, _endDate];
                    break;
                case 1:
                    // last quarter
                    _endDate = moment().format("YYYY-MM-DD");
                    _startDate = moment()
                        .subtract(3, "months")
                        .startOf("month")
                        .format("YYYY-MM-DD");
                    this.plannedArrivalDateRange = [_startDate, _endDate];
                    this.savedPlannedArrivalDateRange = [_startDate, _endDate];
                    break;
                case 2:
                    // last half year
                    _endDate = moment().format("YYYY-MM-DD");
                    _startDate = moment()
                        .subtract(6, "months")
                        .startOf("month")
                        .format("YYYY-MM-DD");
                    this.plannedArrivalDateRange = [_startDate, _endDate];
                    this.savedPlannedArrivalDateRange = [_startDate, _endDate];
                    break;
                case 3:
                    // last 1 year
                    _endDate = moment().format("YYYY-MM-DD");
                    _startDate = moment()
                        .subtract(12, "months")
                        .startOf("month")
                        .format("YYYY-MM-DD");
                    this.plannedArrivalDateRange = [_startDate, _endDate];
                    this.savedPlannedArrivalDateRange = [_startDate, _endDate];
                    break;
                default:
                    this.plannedArrivalDateRange = [];
                    this.savedPlannedArrivalDateRange = [];
            }
        },
        savedPlannedArrivalDateRange(val) {
            if (val.length == 1) {
                this.menuObject["plannedArrivalDate_Gte"] = val[0] + "T00:00:00";
                this.menuObject["plannedArrivalDate_Lte"] = val[0] + "T23:59:59";
            } else if (val.length == 2) {
                this.menuObject["plannedArrivalDate_Gte"] = val[0] + "T00:00:00";
                this.menuObject["plannedArrivalDate_Lte"] = val[1] + "T23:59:59";
            } else {
                this.menuObject["plannedArrivalDate_Gte"] = null;
                this.menuObject["plannedArrivalDate_Lte"] = null;
            }
        },
        relativePlannedDepartureDateRange(val) {
            const _index = this.relativePlannedDepartureDateRangeChoices.indexOf(val);
            let _startDate = null;
            let _endDate = null;
            switch (_index) {
                case 0:
                    // last month
                    _endDate = moment().format("YYYY-MM-DD");
                    _startDate = moment()
                        .date(0)
                        .startOf("month")
                        .format("YYYY-MM-DD");
                    this.plannedDepartureDateRange = [_startDate, _endDate];
                    this.savedPlannedDepartureDateRange = [_startDate, _endDate];
                    break;
                case 1:
                    // last quarter
                    _endDate = moment().format("YYYY-MM-DD");
                    _startDate = moment()
                        .subtract(3, "months")
                        .startOf("month")
                        .format("YYYY-MM-DD");
                    this.plannedDepartureDateRange = [_startDate, _endDate];
                    this.savedPlannedDepartureDateRange = [_startDate, _endDate];
                    break;
                case 2:
                    // last half year
                    _endDate = moment().format("YYYY-MM-DD");
                    _startDate = moment()
                        .subtract(6, "months")
                        .startOf("month")
                        .format("YYYY-MM-DD");
                    this.plannedDepartureDateRange = [_startDate, _endDate];
                    this.savedPlannedDepartureDateRange = [_startDate, _endDate];
                    break;
                case 3:
                    // last 1 year
                    _endDate = moment().format("YYYY-MM-DD");
                    _startDate = moment()
                        .subtract(12, "months")
                        .startOf("month")
                        .format("YYYY-MM-DD");
                    this.plannedDepartureDateRange = [_startDate, _endDate];
                    this.savedPlannedDepartureDateRange = [_startDate, _endDate];
                    break;
                default:
                    this.plannedDepartureDateRange = [];
                    this.savedPlannedDepartureDateRange = [];
            }
        },
        savedPlannedDepartureDateRange(val) {
            if (val.length == 1) {
                this.menuObject["plannedDepartureDate_Gte"] = val[0] + "T00:00:00";
                this.menuObject["plannedDepartureDate_Lte"] = val[0] + "T23:59:59";
            } else if (val.length == 2) {
                this.menuObject["plannedDepartureDate_Gte"] = val[0] + "T00:00:00";
                this.menuObject["plannedDepartureDate_Lte"] = val[1] + "T23:59:59";
            } else {
                this.menuObject["plannedDepartureDate_Gte"] = null;
                this.menuObject["plannedDepartureDate_Lte"] = null;
            }
        },
        relativeAlertTimeRange(val) {
            const _index = this.relativeAlertTimeRangeChoices.indexOf(val);
            let _startDate = null;
            let _endDate = null;
            switch (_index) {
                case 0:
                    // last month
                    _endDate = moment().format("YYYY-MM-DD");
                    _startDate = moment()
                        .date(0)
                        .startOf("month")
                        .format("YYYY-MM-DD");
                    this.alertTimeRange = [_startDate, _endDate];
                    this.savedAlertTimeRange = [_startDate, _endDate];
                    break;
                case 1:
                    // last quarter
                    _endDate = moment().format("YYYY-MM-DD");
                    _startDate = moment()
                        .subtract(3, "months")
                        .startOf("month")
                        .format("YYYY-MM-DD");
                    this.alertTimeRange = [_startDate, _endDate];
                    this.savedAlertTimeRange = [_startDate, _endDate];
                    break;
                case 2:
                    // last half year
                    _endDate = moment().format("YYYY-MM-DD");
                    _startDate = moment()
                        .subtract(6, "months")
                        .startOf("month")
                        .format("YYYY-MM-DD");
                    this.alertTimeRange = [_startDate, _endDate];
                    this.savedAlertTimeRange = [_startDate, _endDate];
                    break;
                case 3:
                    // last 1 year
                    _endDate = moment().format("YYYY-MM-DD");
                    _startDate = moment()
                        .subtract(12, "months")
                        .startOf("month")
                        .format("YYYY-MM-DD");
                    this.alertTimeRange = [_startDate, _endDate];
                    this.savedAlertTimeRange = [_startDate, _endDate];
                    break;
                default:
                    this.alertTimeRange = [];
                    this.savedAlertTimeRange = [];
            }
        },
        value: {
            handler(val) {
                if (val.alertTime_Gte && val.alertTime_Lte) {
                    this.alertTimeRange.push(val.alertTime_Gte.split("T")[0]);
                    this.alertTimeRange.push(val.alertTime_Lte.split("T")[0]);
                } else {
                    this.alertTimeRange = [];
                }
            },
            deep: true,
            immediate: true
        }
    },
    computed: {
        menuObject: {
            get() {
                return this.value || {};
            },
            set(val) {
                this.$emit("input", val);
            }
        },
        dynamicFilters() {
            return this.defaultFilterChoices.filter(filter => filter.enabled).sort((a, b) => a.name.localeCompare(b.name));
        },
        alertTimeRangeText() {
            if (this.alertTimeRange.length > 1) {
                let date1 = moment(this.alertTimeRange[0], "YYYY-MM-DD").format(helper.getDateFormat());
                let date2 = moment(this.alertTimeRange[1], "YYYY-MM-DD").format(helper.getDateFormat());

                return date1 + " ~ " + date2;
            } else if (this.alertTimeRange.length === 1) {
                return moment(this.alertTimeRange[0], "YYYY-MM-DD").format(helper.getDateFormat());
            } else {
                return this.alertTimeRange;
            }
        },
        statusChoices() {
            let _statusChoices = [
                { text: this.$t("general.all"), value: null },
                { text: this.$t("general.active"), value: "active" },
                { text: this.$t("general.completed"), value: "completed" }
            ];
            return _statusChoices;
        },
        alertRuleNames() {
            if (this.alertRules.edges) {
                return this.alertRules.edges.map(edge => edge.node.name);
            }
            return [];
        },
        plannedDepartureDateRangeText() {
            if (this.plannedDepartureDateRange.length > 1) {
                let date1 = moment(this.plannedDepartureDateRange[0], "YYYY-MM-DD").format(helper.getDateFormat());
                let date2 = moment(this.plannedDepartureDateRange[1], "YYYY-MM-DD").format(helper.getDateFormat());

                return date1 + " ~ " + date2;
            } else if (this.plannedDepartureDateRange.length === 1) {
                return moment(this.plannedDepartureDateRange[0], "YYYY-MM-DD").format(helper.getDateFormat());
            } else {
                return this.plannedDepartureDateRange;
            }
        },
        plannedArrivalDateRangeText() {
            if (this.plannedArrivalDateRange.length > 1) {
                let date1 = moment(this.plannedArrivalDateRange[0], "YYYY-MM-DD").format(helper.getDateFormat());
                let date2 = moment(this.plannedArrivalDateRange[1], "YYYY-MM-DD").format(helper.getDateFormat());

                return date1 + " ~ " + date2;
            } else if (this.plannedArrivalDateRange.length === 1) {
                return moment(this.plannedArrivalDateRange[0], "YYYY-MM-DD").format(helper.getDateFormat());
            } else {
                return this.plannedArrivalDateRange;
            }
        },
        alertsType: function() {
            let _alertsType = [
                {
                    name: this.$t("general.all"),
                    value: "All"
                },
                {
                    name: this.$t("dashboard.etd_delay"),
                    value: "SEA_ETD_DELAY"
                },
                {
                    name: this.$t("dashboard.eta_delay"),
                    value: "SEA_ETA_DELAY"
                },
                {
                    name: this.$t("dashboard.ts_waiting"),
                    value: "SEA_TRANS_WAIT"
                },
                {
                    name: this.$t("dashboard.pod_waiting"),
                    value: "SEA_DEST_WAIT"
                },
                {
                    name: this.$t("dashboard.custom_alert"),
                    value: "CUSTOM_ALERT"
                }
            ];
            return _alertsType;
        },
    },
    methods: {
        getFilterChoiceNameByCode(code) {
            return "Value";
        },
        updateCodeSelected(val) {
            this.$emit("update:currentCode", val);
        },
        cancelAlertTimePicker() {
            this.alertTimeMenu = false;
        },
        saveAlertTime() {
            this.$nextTick(() => {
                if (this.$refs.alertTimeMenu) {
                    this.$refs.alertTimeMenu.save(this.alertTimeRange.sort());
                }
            });
        },
        onClearAlertTime() {
            this.alertTimeRange = [];
            this.savedAlertTimeRange = [];
            this.relativeAlertTimeRange = null;
        },
        onClearPlannedDepartureDates() {
            this.plannedDepartureDateRange = [];
            this.savedPlannedDepartureDateRange = [];
        },
        onClearPlannedArrivalDates() {
            this.plannedArrivalDateRange = [];
            this.savedPlannedArrivalDateRange = [];
        },
        cancelPlannedDepartureDatePicker() {
            this.plannedDepartureDateMenu = false;
        },
        cancelPlannedArrivalDatePicker() {
            this.plannedArrivalDateMenu = false;
        },
        savePlannedDepartureDates() {
            this.$nextTick(() => {
                if (this.$refs.plannedDepartureDateMenu) {
                    this.$refs.plannedDepartureDateMenu.save(this.plannedDepartureDateRange.sort());
                }
            });
        },
        savePlannedArrivalDates() {
            this.$nextTick(() => {
                if (this.$refs.plannedArrivalDateMenu) {
                    this.$refs.plannedArrivalDateMenu.save(this.plannedArrivalDateRange.sort());
                }
            });
        },
        remove(type, item) {
            if(type === "alertCode"){
                let index = this.menuObject['alertCode'].indexOf(item.value);   
                if(index !== -1){
                    this.menuObject['alertCode'].splice(index, 1)
                }
            }else if(type === "tenantAlertRuleId"){
                let index = this.menuObject['tenantAlertRuleId'].indexOf(item.value);
                if(index !== -1){
                    this.menuObject['tenantAlertRuleId'].splice(index, 1)
                }
            }
        },

    }
};
</script>
