<template>
    <div class="px-5">
        <!-- SEARCH BAR -->
        <v-card class="py-4 mt-4 mb-4" flat>
            <v-row align="start" class="d-flex align-center background px-4">
                <!---- SEARCH BAR ---->
                <v-col cols="12" lg="12" sm="12" class="py-2">
                    <SearchBar
                        v-model="search"
                        :label="this.$t('general.search') + ' ' + this.$t('headers.trips')"
                    ></SearchBar>
                </v-col>
            </v-row>
        </v-card>
        <v-card flat class="px-4 rounded-lg mb-2" outlined>
            <v-card-title>
                <v-row>
                    <v-col cols="auto">
                        <v-row dense>
                            <v-col>
                                <div>
                                    <v-img :src="require('@/assets/local_shipping.png')" width="26"></v-img>
                                </div>
                            </v-col>
                            <v-col cols="auto">
                                {{ $t("headers.trips") }}
                            </v-col>
                        </v-row>
                    </v-col>
                    <v-spacer></v-spacer>
                    <v-col cols="auto">
                        <slot name="sortBy"></slot>
                    </v-col>
                </v-row>
            </v-card-title>
            <v-card-text>
                <v-card v-if="computeTrips.lenght === 0">
                    No trips found
                </v-card>
                <v-card
                    v-for="(trip, i) in computeTrips"
                    :key="i"
                    class="mb-4 rounded-lg px-3 backgroundContent"
                    outlined
                    flat
                    @click="redirectTotrip(trip.id)"
                >
                    <v-card-text>
                        <!-- TABLE -->
                        <v-row>
                            <v-col
                                v-for="item in cardBody(trip)"
                                :key="item.text"
                                cols="12"
                                xs="12"
                                sm="6"
                                md="4"
                                lg=""
                                xl=""
                                class="textLeft"
                            >
                                <div>
                                    <div class="textHeader text-truncate">
                                        {{ item.title }}
                                    </div>
                                    <div class="textBody text-truncate d-flex align-start" :style="item.style">
                                        <v-icon v-if="item.icon" size="8" class="mr-1 pt-1" :color="item.colorIcon">{{
                                            item.icon
                                        }}</v-icon>
                                        <span class="col-auto pa-0"> {{ item.value }} </span>
                                    </div>
                                </div>
                            </v-col>
                        </v-row>
                    </v-card-text>
                </v-card>
            </v-card-text>
            <!-- Infinite  -->
            <v-card-actions class="justify-center">
                <slot name="loadMore"></slot>
            </v-card-actions>
        </v-card>
    </div>
</template>
<script>
import helper from "@/utils/helper.js";
import SearchBar from "@/components/base/SearchBar.vue";
import Config from "@/utils/config.json";

export default {
    name: "PartnerTripForm",
    components: { SearchBar },
    props: {
        trips: {
            type: Array,
            default: () => []
        },
        searchInput: {
            type: String,
            default: ""
        }
    },
    data() {
        return {
            isLoadingSavePermission: false,
            enableOrDisablePermissionBtn: true,
            selectedPermissions: [],
            selectedPermissionsCopy: [],
            search: ""
        };
    },
    watch: {
        search(val) {
            if (val.length > 0) {
                this.$emit("update:searchInput", val);
            } else {
                this.$emit("update:searchInput", null);
            }
        }
    },
    computed: {
        computeTrips() {
            let _trips = [];
            if (this.trips?.length > 0) {
                _trips = this.trips.map(item => {
                    return item.node;
                });
            }
            return _trips;
        }
    },
    mounted() {},
    methods: {
        redirectTotrip(id) {
            this.$router.push({
                name: "previewtrips",
                query: { id: id, tab: "summary" }
            });
        },
        formatDateTime(datetime) {
            return helper.formatDateTime(datetime);
        },
        cardBody(item) {
            const { referenceNumber, transportMode, status, plannedDepartureDate, plannedArrivalDate } = item;

            let containerStyle = `
                    background-color: ${this.statusColor(status).color1};	
                    border-radius: 4px;
                    width: 90px;
                    display: flex;
                    justify-content: center;
                    align-items: center;
                    padding-top: 2px;
            `;

            let _body = [
                {
                    title: this.$t("headers.reference_number"),
                    tag: "referenceNumber",
                    icon: null,
                    colorIcon: null,
                    style: "",
                    align: "left",
                    value: referenceNumber
                },
                {
                    title: this.$t("headers.transport_mode"),
                    tag: "transportMode",
                    icon: null,
                    colorIcon: null,
                    style: "",
                    align: "left",
                    value: transportMode
                },
                {
                    title: this.$t("headers.planned_departure"),
                    tag: "plannedDepartureDate",
                    icon: null,
                    colorIcon: null,
                    style: "",
                    align: "left",
                    value: this.formatDateTime(plannedDepartureDate)
                },
                {
                    title: this.$t("headers.planned_arrival"),
                    tag: "plannedArrivalDate",
                    icon: null,
                    colorIcon: null,
                    style: "",
                    align: "left",
                    value: this.formatDateTime(plannedArrivalDate)
                },
                {
                    title: this.$t("headers.status"),
                    tag: "status",
                    icon: "circle",
                    colorIcon: this.statusColor(status).color2,
                    style: containerStyle,
                    align: "center",
                    value: this.textCapitalize(status)
                }
            ];

            return _body;
        },
        statusColor(item) {
            let color = "";
            if (item !== null) {
                switch (item) {
                    case "ACTIVE":
                        color = {
                            color1: "#C8E6C9",
                            color2: "#1B5E20"
                        };
                        break;
                    case "COMPLETED":
                        color = {
                            color1: "#E0E0E0",
                            color2: "#616161"
                        };
                        break;
                    default:
                        color = {
                            color1: "#FFE0B2",
                            color2: "#FF6D00"
                        };
                        break;
                }
            }
            return color;
        },
        textCapitalize(text) {
            if (text === "ACTIVE") {
                return this.$t("general.active");
            } else {
                return this.$t("general.completed");
            }
        }
    }
};
</script>
<style scoped>
.textLeft {
    text-align: left !important;
}
.textHeader {
    font-size: 11px;
    font-weight: 400;
    line-height: 12px;
    letter-spacing: 0em;
    text-align: left;
    color: #868599;
    height: 18px;
}
.textBody {
    font-size: 12px;
    font-weight: 700;
    line-height: 14px;
    letter-spacing: 0em;
    text-align: left;
    color: #0e0d35;
    height: 18px;
}
.outline {
    border: 1px solid #ebebf5;
    box-sizing: border-box;
    border-radius: 4px;
}
.backgroundContent {
    background: #f9fafe;
}
</style>
