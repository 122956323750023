<template>
    <v-card>
        <v-card-text class="pb-3 pr-3">
            <div class="d-flex justify-end">
                <div>
                    <v-btn icon>
                        <v-icon
                            @mouseenter="startScrolling('left')"
                            @mouseleave="stopScrolling"
                            >arrow_back</v-icon
                        >
                    </v-btn>
                    <v-btn icon>
                        <v-icon
                            @mouseenter="startScrolling('right')"
                            @mouseleave="stopScrolling"
                            >arrow_forward</v-icon
                        >
                    </v-btn>
                </div>
                <!-- filter menu -->
                <FilterMenu
                    :title="$t('dashboard.customize')"
                    :description="$t('dashboard.display')"
                    :choices="headerChoices"
                    :open-on-hover="false"
                    height="500px"
                    width="250px"
                    v-on:changed="onFilterMenuChanged"
                >
                </FilterMenu>
            </div>
            <v-data-table
                v-model="selected"
                :headers="dynamicHeaders"
                :items="
                    bookingOrderTemplatesQx ? bookingOrderTemplatesQx.edges : []
                "
                item-key="node.id"
                :loading-text="$t('loading.loading1')"
                :loading="$apollo.queries.bookingOrderTemplatesQx.loading"
                hide-default-footer
                disable-pagination
                disable-sort
                loader-height="2"
                :show-select="selectMode"
                :mobile-breakpoint="
                    screenSize === 'xs' || screenSize === 'sm' ? 800 : 0
                "
                :no-data-text="$t('loading.no_data')"
                fixed-header
                :height="tableHeight"
                @click:row="item => this.$emit('click:row', item)"
                class="row-pointer"
            >
                <!-- goods -->
                <template v-slot:item.customer="{ item }">
                    <div>
                        {{
                            triggerJson(item.node.bookingJson, "customer") !==
                            "None"
                                ? triggerJson(item.node.bookingJson, "customer")
                                : ""
                        }}
                    </div>
                </template>
                <template v-slot:item.exporter="{ item }">
                    <div>
                        {{
                            triggerJson(item.node.bookingJson, "exporter") !==
                            "None"
                                ? triggerJson(item.node.bookingJson, "exporter")
                                : ""
                        }}
                    </div>
                </template>
                <template v-slot:item.receiver="{ item }">
                    <div>
                        {{
                            triggerJson(item.node.bookingJson, "receiver") !==
                            "None"
                                ? triggerJson(item.node.bookingJson, "receiver")
                                : ""
                        }}
                    </div>
                </template>
                <template v-slot:item.vesselName="{ item }">
                    <div>
                        {{
                            triggerJson(item.node.bookingJson, "vesselName") !==
                            "None"
                                ? triggerJson(
                                      item.node.bookingJson,
                                      "vesselName"
                                  )
                                : ""
                        }}
                    </div>
                </template>
                <template v-slot:item.commodity="{ item }">
                    <div>
                        {{
                            triggerJson(item.node.bookingJson, "commodity") !==
                            "None"
                                ? triggerJson(
                                      item.node.bookingJson,
                                      "commodity"
                                  )
                                : ""
                        }}
                    </div>
                </template>
                <template v-slot:item.shippingLine="{ item }">
                    <div>
                        {{
                            triggerJson(
                                item.node.bookingJson,
                                "shippingLine"
                            ) !== "None"
                                ? triggerJson(
                                      item.node.bookingJson,
                                      "shippingLine"
                                  )
                                : ""
                        }}
                    </div>
                </template>
                <template v-slot:item.containerType="{ item }">
                    <div>
                        {{
                            triggerJson(
                                item.node.bookingJson,
                                "containerType"
                            ) !== "None"
                                ? triggerJson(
                                      item.node.bookingJson,
                                      "containerType"
                                  )
                                : ""
                        }}
                    </div>
                </template>
                <template v-slot:item.steri="{ item }">
                    <div>
                        {{
                            triggerJson(item.node.bookingJson, "steri") !==
                            "None"
                                ? triggerJson(item.node.bookingJson, "steri")
                                : ""
                        }}
                    </div>
                </template>
                <template v-slot:item.incoTerm="{ item }">
                    <div>
                        {{
                            triggerJson(item.node.bookingJson, "incoTerm") !==
                            "None"
                                ? triggerJson(item.node.bookingJson, "incoTerm")
                                : ""
                        }}
                    </div>
                </template>
                <template v-slot:item.transportType="{ item }">
                    <div>
                        {{
                            triggerJson(
                                item.node.bookingJson,
                                "transportType"
                            ) !== "None"
                                ? triggerJson(
                                      item.node.bookingJson,
                                      "transportType"
                                  )
                                : ""
                        }}
                    </div>
                </template>
                <template v-slot:item.pol="{ item }">
                    <div>
                        {{
                            triggerJson(item.node.bookingJson, "pol") !== "None"
                                ? triggerJson(item.node.bookingJson, "pol")
                                : ""
                        }}
                    </div>
                </template>
                <template v-slot:item.pod="{ item }">
                    <div>
                        {{
                            triggerJson(item.node.bookingJson, "pod") !== "None"
                                ? triggerJson(item.node.bookingJson, "pod")
                                : ""
                        }}
                    </div>
                </template>
                <template v-slot:item.weighBridge="{ item }">
                    <div>
                        {{
                            triggerJson(
                                item.node.bookingJson,
                                "weighBridge"
                            ) !== "None"
                                ? triggerJson(
                                      item.node.bookingJson,
                                      "weighBridge"
                                  )
                                : ""
                        }}
                    </div>
                </template>
                <template slot="body.append">
                    <tr>
                        <td
                            :colspan="dynamicHeaders.length"
                            class="text-center"
                        >
                            <v-btn
                                v-if="hasMoreData"
                                v-intersect="onLoadMoreTriggerIntersect"
                                :disabled="!hasMoreData"
                                :loading="
                                    $apollo.queries.bookingOrderTemplatesQx
                                        .loading
                                "
                                plain
                                class="mb-5"
                                @click="loadMore"
                            >
                                {{ $t("loading.load_more") }}
                            </v-btn>
                        </td>
                    </tr>
                </template>
            </v-data-table>
        </v-card-text>
    </v-card>
</template>
<script>
import FilterMenu from "@/components/base/FilterMenu.vue";
import helper from "@/utils/helper";
import gql from "graphql-tag";

export default {
    props: {
        bookingTemplatesFilter: {
            type: Object,
            required: false,
            default: () => {
                return {};
            }
        },
        selectMode: {
            type: Boolean,
            required: false,
            default: false
        }
    },
    components: {
        FilterMenu
    },
    apollo: {
        bookingOrderTemplatesQx: {
            query: gql`
                query bookingOrderTemplatesQx(
                    $name: String
                    $description: String
                    $lockTemplate: Boolean
                    $customerId: String
                    $exporterId: String
                    $receiverId: String
                    $tenantPartnerId: String
                    $agentCodeId: String
                    $incoTerm: String
                    $shippingLineId: String
                    $vesselId: String
                    $transportType: String
                    $polId: String
                    $podId: String
                    $containerTypeId: String
                    $orderBy: String
                    $last: Int
                    $first: Int
                    $after: String
                    $before: String
                    $offset: Int
                ) {
                    bookingOrderTemplatesQx(
                        name_Icontains: $name
                        description_Icontains: $description
                        lockTemplate: $lockTemplate
                        customerId: $customerId
                        exporterId: $exporterId
                        receiverId: $receiverId
                        tenantPartnerId: $tenantPartnerId
                        agentCodeId: $agentCodeId
                        incoTerm: $incoTerm
                        shippingLineId: $shippingLineId
                        vesselId: $vesselId
                        transportType: $transportType
                        polId: $polId
                        podId: $podId
                        containerTypeId: $containerTypeId
                        orderBy: $orderBy
                        last: $last
                        first: $first
                        after: $after
                        before: $before
                        offset: $offset
                    ) {
                        edges {
                            node {
                                id
                                name
                                description
                                bookingJson
                                lockTemplate
                                tenant {
                                    id
                                }
                                tenantPartner {
                                    id
                                }
                            }
                        }
                    }
                }
            `,
            variables() {
                // IMPORTANT: add same variables as refetch and fetchMore, otherwise merge result may contain duplicate keys
                return this.bookingTemplatesFilter;
            },
            // Additional options here
            fetchPolicy: "cache-and-network",
            nextFetchPolicy: "cache-first", // this setting can avoid query again after fetchMore
            update: data => data.bookingOrderTemplatesQx,
            watchLoading(isLoading) {
                this.$emit("loading", isLoading);
                if (isLoading) {
                    this.loadHeaders();
                }
            },
            skip: false
        }
    },
    data: () => ({
        defaultHeaderChoices: [
            {
                // for filter menu
                code: "node.name",
                name: "Name",
                tag: "bookings.template_name",
                enabled: true,
                // for table header
                text: "Name",
                align: "left",
                value: "node.name"
            },
            {
                // for filter menu
                code: "node.description",
                name: "Description",
                tag: "bookings.template_description",
                enabled: true,
                // for table header
                text: "Name",
                align: "left",
                value: "node.description"
            },
            {
                // for filter menu
                code: "customer",
                name: "Customer",
                tag: "bookings.customer",
                enabled: true,
                // for table header
                text: "Customer",
                align: "left",
                value: "customer"
            },
            {
                // for filter menu
                code: "exporter",
                name: "Exporter",
                tag: "bookings.exporter",
                enabled: true,
                // for table header
                text: "Exporter",
                align: "left",
                value: "exporter"
            },
            {
                // for filter menu
                code: "receiver",
                name: "Receiver",
                tag: "bookings.receiver",
                enabled: true,
                // for table header
                text: "Receiver",
                align: "left",
                value: "receiver"
            },
            {
                // for filter menu
                code: "vessel",
                name: "Vessel",
                tag: "bookings.vessel",
                enabled: true,
                // for table header
                text: "Vessel",
                align: "left",
                value: "vesselName"
            },
            {
                // for filter menu
                code: "commodities",
                name: "commodity",
                tag: "bookings.commodity",
                enabled: true,
                // for table header
                text: "Commodity",
                align: "left",
                value: "commodity"
            },
            {
                // for filter menu
                code: "shippingLine",
                name: "Shipping Line",
                tag: "bookings.shipping_line",
                enabled: true,
                // for table header
                text: "Shipping Line",
                align: "left",
                value: "shippingLine"
            },
            {
                // for filter menu
                code: "containerType",
                name: "Container Type",
                tag: "bookings.container_type",
                enabled: true,
                // for table header
                text: "Container Type",
                align: "left",
                value: "containerType"
            },
            {
                // for filter menu
                code: "steri",
                name: "Steri",
                tag: "bookings.steri",
                enabled: true,
                // for table header
                text: "Steri",
                align: "left",
                value: "steri"
            },
            {
                // for filter menu
                code: "incoTerm",
                name: "Inco Term",
                tag: "bookings.inco_term",
                enabled: true,
                // for table header
                text: "Inco Term",
                align: "left",
                value: "incoTerm"
            },
            {
                // for filter menu
                code: "transportType",
                name: "Transport Type",
                tag: "bookings.transport_type",
                enabled: true,
                // for table header
                text: "Transport Type",
                align: "left",
                value: "transportType"
            },
            {
                // for filter menu
                code: "POL",
                name: "POL",
                tag: "bookings.pol",
                enabled: false,
                // for table header
                text: "POL",
                align: "left",
                value: "pol"
            },
            {
                // for filter menu
                code: "POD",
                name: "POD",
                tag: "bookings.pod",
                enabled: false,
                // for table header
                text: "POD",
                align: "left",
                value: "pod"
            },
            {
                // for filter menu
                code: "POD",
                name: "POD",
                tag: "bookings.pod",
                enabled: false,
                // for table header
                text: "POD",
                align: "left",
                value: "pod"
            },
            {
                // for filter menu
                code: "weighBridge",
                name: "Weigh Bridge",
                tag: "bookings.weigh_bridge",
                enabled: false,
                // for table header
                text: "Weigh Bridge",
                align: "left",
                value: "weighBridge"
            }
        ],
        items: [],
        loading: false,
        pagination: {},
        search: "",
        selected: [],
        totalItems: 0,
        headerChoices: [],
        tableHeight: 800
    }),
    computed: {
        selectedItems() {
            return this.selected.map(item => item.id);
        },
        screenSize() {
            let _resposiveView = this.$vuetify.breakpoint.width;
            if (_resposiveView < 650) {
                return "xs";
            } else if (_resposiveView < 1000) {
                return "sm";
            } else if (_resposiveView < 1400) {
                return "md";
            } else if (_resposiveView < 1900) {
                return "lg";
            } else {
                return "xl";
            }
        },
        hasMoreData() {
            return this.bookingOrderTemplatesQx?.pageInfo?.hasNextPage;
        },
        dynamicHeaders() {
            let _headers = this.headerChoices.filter(header => header.enabled);
            _headers = _headers.map(header => {
                return {
                    ...header,
                    text: this.$t(header.tag)
                };
            });

            // put header filter in the last column
            _headers.push({
                text: "",
                align: "right",
                value: "headerFilter",
                sortable: false
            });

            return _headers;
        }, 
        me() {
            return helper.me();
        },
    },
    created() {
        this.headerChoices = helper.getTableHeaders(
            "bookingTemplatesTableHeaders",
            this.defaultHeaderChoices
        );
    },
    mounted() {
        this.loadMore();
        // watch headerChoices
        this.$watch(
            "headerChoices",
            function(value) {
                helper.updateMyPreferences({
                    bookingTemplatesTableHeaders: value
                });
            },
            { deep: true }
        );
    },
    methods: {
        loadHeaders() {
            let _default = [...this.defaultHeaderChoices];

            let newChoices = this.isSharedTrip
                ? _default
                : _default.filter(header => header.code !== "tenant");

            if (newChoices.some(choice => typeof choice === "undefined")) {
                // console.error("Undefined item found in newChoices");
            } else {
                this.headerChoices = helper.getTableHeaders(
                    "bookingTemplatesTableHeaders",
                    newChoices
                );
            }
        },
        deleteSelected() {
            this.isLoading = true;
            let selectedIds = this.selected.map(selected => selected.node.id);

            this.$apollo
                .mutate({
                    mutation: gql`
                        mutation deleteBookingOrderTemplateQx(
                            $input:DeleteBookingOrderTemplateQxInput!
                        ) {
                            deleteBookingOrderTemplateQx(input: $input) {
                                success
                            }
                        }
                    `,
                    variables: {
                        input: {
                            templateIds: selectedIds,
                            tenantId: this.me.tenant.id
                        }
                    }
                })
                .then(() => {
                    this.$apollo.queries.bookingOrderTemplatesQx.refetch();
                    this.selected = [];
                })
                .finally(() => {
                    this.$emit("deleted-templates", false);
                }); 
        },
        triggerJson(json, key) {
            let _json = JSON.parse(json);
            return _json[key];
        },
        onFilterMenuChanged(newChoices) {
            this.headerChoices = newChoices.map(choice => choice);
            helper.updateMyPreferences({
                bookingTemplatesTableHeaders: newChoices
            });
        },
        refetchDate() {
            // use timeout to debouce
            if (this.timeout) clearTimeout(this.timeout);

            this.timeout = setTimeout(() => {
                this.$apollo.queries.bookingOrderTemplatesQx.refetch(
                    this.filter
                );
            }, 800); // delay
        },
        loadMore() {
            if (this.trips?.pageInfo?.hasNextPage) {
                this.$apollo.queries.trips.fetchMore({
                    variables: {
                        after: this.trips.pageInfo.endCursor
                    }
                });
            }
        },
        startScrolling(direction) {
            this.scrollInterval = setInterval(() => {
                const tableContainer = document.querySelector(
                    ".v-data-table__wrapper"
                );
                const scrollAmount = direction === "left" ? -10 : 10;
                tableContainer.scrollLeft += scrollAmount;
            }, 10);
        },
        stopScrolling() {
            clearInterval(this.scrollInterval);
        }
    }
};
</script>
<style scoped></style>
