<template>
    <v-container fluid>
        <v-row>
            <v-col cols="12">
                <v-card outlined class="rounded-lg pa-4">
                    <v-card-title class="mb-4">
                        <v-row>
                            <v-col>
                                <span class="title">
                                    {{ $t("trips.create_trip") }}
                                </span>
                            </v-col>
                        </v-row>
                    </v-card-title>
                    <v-card-text class="text-left">
                        <v-row>
                            <v-col cols="8">
                                <div class="body">{{ $t("trips.automatic_trip") }}</div>
                                <div class="exampleText">{{ $t("general.create_automate_trip") }}</div>
                            </v-col>
                            <v-col cols="4" class="d-flex align-center justify-end py-0">
                                <v-switch
                                    :value="tripSettings.autoCreateTrip"
                                    class="switch-margin"
                                    color="primary"
                                    inset
                                    hide-details
                                    @change="updateTripSettings('auto', $event, true)"
                                >
                                </v-switch>
                            </v-col>
                        </v-row>
                        <v-row>
                            <v-col cols="12">
                                <v-divider></v-divider>
                            </v-col>
                        </v-row>
                        <v-row class="pt-2">
                            <v-col cols="8" class="">
                                <div class="body">{{ $t("trips.default_transport") }}</div>
                                <div class="exampleText">{{ $t("general.create_automate_tranport_mode") }}</div>
                            </v-col>
                            <v-spacer></v-spacer>
                            <v-col cols="4" class="d-flex align-center justify-end py-0">
                                <v-select
                                    :value="tripSettings.transportModeChoice"
                                    :items="transportModeChoices"
                                    clearable
                                    dense
                                    hide-details
                                    color="primary"
                                    outlined
                                    @change="updateTripSettings('mode', $event, true)"
                                    :placeholder="$t('trips.default_transport')"
                                ></v-select>
                            </v-col>
                        </v-row>
                    </v-card-text>
                </v-card>
            </v-col>
            <v-col cols="12">
                <v-card outlined class="rounded-lg pa-4">
                    <v-card-title class="mb-4">
                        <v-row>
                            <v-col cols="4">
                                <span class="title">
                                    {{ $t("trips.auto_complete") }} {{ $t("home.settings") }}
                                </span>
                            </v-col>
                            <v-spacer></v-spacer>
                            <v-btn
                                v-if="!editMode"
                                @click="editMode = true"
                                color="primary"
                                text
                                class="text-capitalize"
                            >
                                <v-icon>edit</v-icon>
                            </v-btn>
                            <v-btn v-if="editMode" @click="resetEditMode" color="primary" text class="text-capitalize">
                                {{ $t("general.cancel") }}
                            </v-btn>
                            <v-btn v-if="editMode" elevation="0" color="primary" @click="updateOrganisationPreferences">
                                {{ $t("general.save") }}
                            </v-btn>
                        </v-row>
                    </v-card-title>
                    <v-card-text>
                        <v-row class="pt-2">
                            <v-col cols="8">
                                <span class="body">{{ $t("trips.tracker_arrives") }}</span>
                            </v-col>
                            <v-col cols="4" class="d-flex align-center justify-end py-0">
                                <v-switch
                                    :value="tripSettings.autoCompleteTripTracker.enabled"
                                    class="switch-margin"
                                    color="primary"
                                    hide-details
                                    inset
                                    @change="updateTripSettings('trackerEnabled', $event, false)"
                                    :disabled="!editMode"
                                >
                                </v-switch>
                            </v-col>
                        </v-row>
                        <v-row v-if="tripSettings.autoCompleteTripTracker.enabled">
                            <v-col cols="8">
                                <span class="ml-5 body">{{ $t("trips.automatically_complete") }} </span>
                            </v-col>
                            <v-col cols="4">
                                <div class="body">
                                    {{ $t("general.time_format") }}
                                </div>
                                <v-row>
                                    <v-col cols="4" class="d-flex align-center justify-end">
                                        <v-text-field
                                            :value="tripSettings.autoCompleteTripTracker.offset"
                                            hide-details
                                            min="0"
                                            type="number"
                                            color="primary"
                                            outlined
                                            dense
                                            :disabled="!editMode"
                                            @change="updateTripSettings('trackerOffset', $event, false)"
                                        ></v-text-field>
                                    </v-col>
                                    <v-col cols="5" class="d-flex align-center justify-end">
                                        <v-select
                                            :value="tripSettings.autoCompleteTripTracker.unit"
                                            :items="unitChoices"
                                            hide-details
                                            color="primary"
                                            dense
                                            outlined
                                            :disabled="!editMode"
                                            @change="updateTripSettings('trackerUnit', $event, false)"
                                        ></v-select>
                                    </v-col>
                                </v-row>
                            </v-col>
                        </v-row>
                        <v-row>
                            <v-col cols="12">
                                <v-divider></v-divider>
                            </v-col>
                        </v-row>
                        <!-- Auto Complete Trip Vessel ETA -->
                        <v-row class="pt-2">
                            <v-col cols="8">
                                <span class="body">{{ $t("trips.vessel_later") }} </span> 
                            </v-col>
                            <v-col cols="4" class="d-flex align-center justify-end py-0">
                                <v-switch
                                    :value="tripSettings.autoCompleteTripVesselETA.enabled"
                                    class="switch-margin"
                                    color="primary"
                                    hide-details
                                    inset
                                    @change="updateTripSettings('vesselETAEnabled', $event, false)"
                                    :disabled="!editMode"
                                >
                                </v-switch>
                            </v-col>
                        </v-row>
                        <v-row v-if="tripSettings.autoCompleteTripVesselETA.enabled">
                            <v-col cols="8">
                                <span class="ml-5 body">{{ $t("trips.automatically_completes") }} </span>
                            </v-col>
                            <v-col cols="4">
                                <div class="body">
                                    {{ $t("general.time_format") }}
                                </div>
                                <v-row>
                                    <v-col cols="4" class="d-flex align-center justify-end">
                                        <v-text-field
                                            :value="tripSettings.autoCompleteTripVesselETA.offset"
                                            hide-details
                                            min="0"
                                            type="number"
                                            dense
                                            color="primary"
                                            outlined
                                            :disabled="!editMode"
                                            @change="updateTripSettings('vesselETAOffset', $event, false)"
                                        ></v-text-field>
                                    </v-col>
                                    <v-col cols="5" class="d-flex align-center justify-end">
                                        <v-select
                                            :value="tripSettings.autoCompleteTripVesselETA.unit"
                                            :items="unitChoices"
                                            hide-details
                                            dense
                                            color="primary"
                                            outlined
                                            :disabled="!editMode"
                                            @change="updateTripSettings('vesselETAUnit', $event, false)"
                                        ></v-select>
                                    </v-col>
                                </v-row>
                            </v-col>
                        </v-row>
                        <v-row>
                            <v-col cols="12">
                                <v-divider></v-divider>
                            </v-col>
                        </v-row>
                        <!-- Auto Complete Trip Vessel ATA -->
                        <v-row class="pt-2">
                            <v-col cols="8" >
                                <span class="body">{{ $t("trips.vessel_laters") }} </span>
                            </v-col>
                            <v-col cols="4" class="d-flex align-center justify-end py-0">
                                <v-switch
                                    :value="tripSettings.autoCompleteTripVesselATA.enabled"
                                    class="switch-margin"
                                    color="primary"
                                    inset
                                    hide-details
                                    @change="updateTripSettings('vesselATAEnabled', $event, false)"
                                    :disabled="!editMode"
                                >
                                </v-switch>
                            </v-col>
                        </v-row>
                        <v-row  v-if="tripSettings.autoCompleteTripVesselATA.enabled">
                            <v-col cols="8">
                                <span class="ml-5 body">{{ $t("trips.automatically_completes") }} </span>
                            </v-col>
                            <v-col cols="4">
                                <div class="body">
                                    {{ $t("general.time_format") }}
                                </div>
                                <v-row>
                                    <v-col cols="4" class="d-flex align-center justify-end">
                                        <v-text-field
                                            :value="tripSettings.autoCompleteTripVesselATA.offset"
                                            hide-details
                                            min="0"
                                            type="number"
                                            dense
                                            color="primary"
                                            outlined
                                            :disabled="!editMode"
                                            @change="updateTripSettings('vesselATAOffset', $event, false)"
                                        ></v-text-field>
                                    </v-col>
                                    <v-col cols="5" class="d-flex align-center justify-end">
                                        <v-select
                                            :value="tripSettings.autoCompleteTripVesselATA.unit"
                                            :items="unitChoices"
                                            hide-details
                                            dense
                                            color="primary"
                                            outlined
                                            :disabled="!editMode"
                                            @change="updateTripSettings('vesselATAUnit', $event, false)"
                                        ></v-select>
                                    </v-col>
                                </v-row>
                            </v-col>
                        </v-row>
                        <v-row>
                            <v-col cols="12">
                                <v-divider></v-divider>
                            </v-col>
                        </v-row>
                        <!-- Auto Complete Trip Carrier -->
                        <v-row class="pt-2">
                            <v-col cols="8" >
                                <span class="body">{{ $t("trips.carrier_reports") }} </span>
                            </v-col>
                            <v-col cols="4" class="d-flex align-center justify-end py-0">
                                <v-switch
                                    :value="tripSettings.autoCompleteTripCarrier.enabled"
                                    class="switch-margin"
                                    color="primary"
                                    inset
                                    hide-details
                                    @change="updateTripSettings('carrierEnabled', $event, false)"
                                    :disabled="!editMode"
                                >
                                </v-switch>
                            </v-col>
                        </v-row>
                        <v-row v-if="tripSettings.autoCompleteTripCarrier.enabled">
                            <v-col cols="8">
                                <span class="ml-5 body">{{ $t("trips.automatically_completes") }} </span>
                            </v-col>
                            <v-col cols="4">
                                <div class="body">
                                    {{ $t("general.time_format") }}
                                </div>
                                <v-row>
                                    <v-col cols="4" class="d-flex align-center justify-end">
                                        <v-text-field
                                            :value="tripSettings.autoCompleteTripCarrier.offset"
                                            hide-details
                                            min="0"
                                            type="number"
                                            dense
                                            color="primary"
                                            outlined
                                            :disabled="!editMode"
                                            @change="updateTripSettings('carrierOffset', $event, false)"
                                        ></v-text-field>
                                    </v-col>
                                    <v-col cols="5" class="d-flex align-center justify-end">
                                        <v-select
                                            :value="tripSettings.autoCompleteTripCarrier.unit"
                                            :items="unitChoices"
                                            hide-details
                                            dense
                                            color="primary"
                                            outlined
                                            :disabled="!editMode"
                                            @change="updateTripSettings('carrierUnit', $event, false)"
                                        ></v-select>
                                    </v-col>
                                </v-row>
                            </v-col>
                        </v-row>
                        <v-row>
                            <v-col cols="12">
                                <v-divider></v-divider>
                            </v-col>
                        </v-row>
                        <!-- Auto Complete Trip Tracker Activated -->
                        <v-row class="pt-2">
                            <v-col cols="8" >
                                <span class="body">{{ $t("trips.tracker_activate") }} </span>
                            </v-col>
                            <v-col cols="4" class="d-flex align-center justify-end py-0">
                                <v-switch
                                    :value="tripSettings.autoCompleteTripTrackerActivated.enabled"
                                    class="switch-margin"
                                    color="primary"
                                    inset
                                    hide-details
                                    @change="updateTripSettings('trackerActivatedEnabled', $event, false)"
                                    :disabled="!editMode"
                                >
                                </v-switch>
                            </v-col>
                        </v-row>
                        <v-row v-if="tripSettings.autoCompleteTripTrackerActivated.enabled">
                            <v-col cols="8" >
                                <span class="ml-5 body">{{ $t("trips.automatically_completes") }} </span>
                            </v-col>
                            <v-col cols="4">
                                <div class="body">
                                    {{ $t("general.time_format") }}
                                </div>
                                <v-row>
                                    <v-col cols="4" class="d-flex align-center justify-end">
                                        <v-text-field
                                            :value="tripSettings.autoCompleteTripTrackerActivated.offset"
                                            hide-details
                                            min="0"
                                            type="number"
                                            dense
                                            color="primary"
                                            outlined
                                            :disabled="!editMode"
                                            @change="updateTripSettings('trackerActivatedOffset', $event, false)"
                                        ></v-text-field>
                                    </v-col>
                                    <v-col cols="5" class="d-flex align-center justify-end">
                                        <v-select
                                            :value="tripSettings.autoCompleteTripTrackerActivated.unit"
                                            :items="unitChoices"
                                            hide-details
                                            dense
                                            color="primary"
                                            outlined
                                            :disabled="!editMode"
                                            @change="updateTripSettings('trackerActivatedUnit', $event, false)"
                                        ></v-select>
                                    </v-col>
                                </v-row>
                            </v-col>
                        </v-row>

                        <!-- Auto Send Trip Completed Report-->
                        <v-row>
                            <v-col cols="12">
                                <v-divider></v-divider>
                            </v-col>
                        </v-row>
                        
                        <v-row class="pt-2">
                            <v-col cols="4" >
                                <span class="body">{{ $t("trips.trip_complete_report") }} </span>
                            </v-col>
                            <v-spacer></v-spacer>
                            <v-col cols="8" class="d-flex align-center justify-end py-0">
                                <v-switch
                                    :value="tripSettings.autoSendTripCompletedReport.enabled"
                                    class="switch-margin"
                                    color="primary"
                                    inset
                                    hide-details
                                    @change="updateTripSettings('tripCompletedReportEnabled', $event, false)"
                                    :disabled="!editMode"
                                >
                                </v-switch>
                            </v-col>
                        </v-row>
                        <v-row v-if="tripSettings.autoSendTripCompletedReport.enabled">
                            <v-col cols="4" >
                                <span class="ml-5 body">{{ $t("trips.send_trip_complete_report") }} </span>
                            </v-col>
                            <v-col cols="8">
                                <div class="body">
                                    {{ $t("trips.trip_complete_report_recipients") }}
                                </div>
                                <v-row>
                                    <v-col cols="8" class="d-flex align-center justify-end">
                                        <v-text-field
                                            v-model="tripSettings.autoSendTripCompletedReport.recipients"
                                            hide-details
                                            type="text"
                                            dense
                                            color="primary"
                                            outlined
                                            :disabled="!editMode"
                                        ></v-text-field>
                                    </v-col>
                                </v-row>
                            </v-col>
                        </v-row>                        
                    </v-card-text>
                </v-card>
            </v-col>
        </v-row>
    </v-container>
</template>

<script>
// import NoPermission from "@/components/base/NoPermission.vue";
import helper from "@/utils/helper.js";
import gql from "graphql-tag";
import Config from "@/utils/config.json";

export default {
    props: {
        tenant: {
            type: Object
        }
    },
    data() {
        return {
            selectedTransportModes: [],
            defaultPreferences: {
                autoCreateTrip: false,
                transportModeChoice: "",
                autoCompleteTripTracker: {
                    enabled: false,
                    unit: "hours",
                    offset: 0
                },
                autoCompleteTripVesselETA: {
                    enabled: false,
                    unit: "hours",
                    offset: 0
                },
                autoCompleteTripVesselATA: {
                    enabled: false,
                    unit: "hours",
                    offset: 0
                },
                autoCompleteTripCarrier: {
                    enabled: false,
                    unit: "hours",
                    offset: 0
                },
                autoCompleteTripTrackerActivated: {
                    enabled: false,
                    unit: "hours",
                    offset: 0
                },
                autoSendTripCompletedReport: {
                    enabled: false,
                    recipients: null
                }
            },
            editMode: false,
            loadingBtn: false
        };
    },

    computed: {
        me() {
            return helper.me();
        },
        tripSettings: {
            get() {
                return this.defaultPreferences;
            },
            set(value) {
                this.defaultPreferences = value;
            }
        },
        transportModeChoices: function() {
            let _transportModeChoices = [
                { text: this.$t("transport_mode_choice.air"), value: "air" },
                { text: this.$t("transport_mode_choice.road"), value: "road" },
                { text: this.$t("transport_mode_choice.rail"), value: "rail" },
                { text: this.$t("transport_mode_choice.sea"), value: "sea" }
            ];
            return _transportModeChoices;
        },
        unitChoices: function() {
            let _unitChoices = [
                { text: this.$t("general.hours"), value: "hours" },
                { text: this.$t("date.days"), value: "days" }
            ];
            return _unitChoices;
        }
    },
    watch: {},
    mounted() {
        this.populateForm();
    },
    methods: {
        populateForm() {
            if (this.isValidObject(this.tenant?.organisationPreferences)) {
                this.tripSettings = this.addAutoCompleteIfMissing(JSON.parse(this.tenant?.organisationPreferences));
            }
            //Gtag
            this.$gtag.screenview({
                app_name: Config.VUE_APP_APPLICATION_NAME,
                screen_name: "settings_details_svw"
            });
            this.setUserProperties();
        },
        setUserProperties() {
            if (this.me?.isPartnerUser) {
                this.me?.allTenantPartners.forEach(c => {
                    this.$gtag.event("settings_details_svw", {
                        tenant_id: this.me?.tenant?.id,
                        tenant_name: this.me?.tenant?.name,
                        partner_id: c.id,
                        user_id: this.me?.id
                    });
                });
            } else {
                this.$gtag.event("settings_details_svw", {
                    tenant_id: this.me?.tenant?.id,
                    tenant_name: this.me?.tenant?.name,
                    partner_id: null,
                    user_id: this.me?.id
                });
            }
        },
        resetEditMode() {
            this.editMode = false;
            this.defaultPreferences = {
                autoCreateTrip: false,
                transportModeChoice: [],
                autoCompleteTripTracker: {
                    enabled: false,
                    unit: "hours",
                    offset: 0
                },
                autoCompleteTripVesselETA: {
                    enabled: false,
                    unit: "hours",
                    offset: 0
                },
                autoCompleteTripVesselATA: {
                    enabled: false,
                    unit: "hours",
                    offset: 0
                },
                autoCompleteTripCarrier: {
                    enabled: false,
                    unit: "hours",
                    offset: 0
                },
                autoCompleteTripTrackerActivated: {
                    enabled: false,
                    unit: "days",
                    offset: 0
                },
                autoSendTripCompletedReport: {
                    enabled: false,
                    recipients: null
                }
            };
            this.tripSettings = this.isValidObject(this.tenant?.organisationPreferences)
                ? this.addAutoCompleteIfMissing(JSON.parse(this.tenant?.organisationPreferences))
                : this.defaultPreferences;
        },
        updateTripSettings(key, value, autosave) {
            let _preferences = {
                autoCreateTrip: this.tripSettings.autoCreateTrip,
                transportModeChoice: this.tripSettings.transportModeChoice,
                autoCompleteTripTracker: {
                    enabled: this.tripSettings.autoCompleteTripTracker.enabled,
                    unit: this.tripSettings.autoCompleteTripTracker.unit,
                    offset: this.tripSettings.autoCompleteTripTracker.offset
                },
                autoCompleteTripVesselETA: {
                    enabled: this.tripSettings.autoCompleteTripVesselETA.enabled,
                    unit: this.tripSettings.autoCompleteTripVesselETA.unit,
                    offset: this.tripSettings.autoCompleteTripVesselETA.offset
                },
                autoCompleteTripVesselATA: {
                    enabled: this.tripSettings.autoCompleteTripVesselATA.enabled,
                    unit: this.tripSettings.autoCompleteTripVesselATA.unit,
                    offset: this.tripSettings.autoCompleteTripVesselATA.offset
                },
                autoCompleteTripCarrier: {
                    enabled: this.tripSettings.autoCompleteTripCarrier.enabled,
                    unit: this.tripSettings.autoCompleteTripCarrier.unit,
                    offset: this.tripSettings.autoCompleteTripCarrier.offset
                },
                autoCompleteTripTrackerActivated: {
                    enabled: this.tripSettings.autoCompleteTripTrackerActivated.enabled,
                    unit: this.tripSettings.autoCompleteTripTrackerActivated.unit,
                    offset: this.tripSettings.autoCompleteTripTrackerActivated.offset
                },
                autoSendTripCompletedReport: {
                    enabled: this.tripSettings.autoSendTripCompletedReport.enabled,
                    recipients: this.tripSettings.autoSendTripCompletedReport.recipients
                }
            };

            switch (key) {
                case "auto":
                    _preferences.autoCreateTrip = value;
                    this.tripSettings = _preferences;
                    break;
                case "mode":
                    _preferences.transportModeChoice = value;
                    this.tripSettings = _preferences;
                    break;
                case "trackerUnit":
                    _preferences.autoCompleteTripTracker.unit = value;
                    this.tripSettings = _preferences;
                    break;
                case "trackerOffset":
                    if (value < 0 || value == null || value == "") {
                        value = 0;
                    }
                    _preferences.autoCompleteTripTracker.offset = value;
                    this.tripSettings = _preferences;
                    break;
                case "trackerEnabled":
                    _preferences.autoCompleteTripTracker.enabled = value;
                    this.tripSettings = _preferences;
                    if (!value) {
                        this.tripSettings.autoCompleteTripTracker.offset = 0;
                        this.tripSettings.autoCompleteTripTracker.unit = "hours";
                    }
                    break;
                case "vesselETAUnit":
                    _preferences.autoCompleteTripVesselETA.unit = value;
                    this.tripSettings = _preferences;
                    break;
                case "vesselETAOffset":
                    if (value < 0 || value == null || value == "") {
                        value = 0;
                    }
                    _preferences.autoCompleteTripVesselETA.offset = value;
                    this.tripSettings = _preferences;
                    break;
                case "vesselETAEnabled":
                    _preferences.autoCompleteTripVesselETA.enabled = value;
                    this.tripSettings = _preferences;
                    if (!value) {
                        this.tripSettings.autoCompleteTripVesselETA.offset = 0;
                        this.tripSettings.autoCompleteTripVesselETA.unit = "hours";
                    }
                    break;
                case "vesselATAUnit":
                    _preferences.autoCompleteTripVesselATA.unit = value;
                    this.tripSettings = _preferences;
                    break;
                case "vesselATAOffset":
                    if (value < 0 || value == null || value == "") {
                        value = 0;
                    }
                    _preferences.autoCompleteTripVesselATA.offset = value;
                    this.tripSettings = _preferences;
                    break;
                case "vesselATAEnabled":
                    _preferences.autoCompleteTripVesselATA.enabled = value;
                    this.tripSettings = _preferences;
                    if (!value) {
                        this.tripSettings.autoCompleteTripVesselATA.offset = 0;
                        this.tripSettings.autoCompleteTripVesselATA.unit = "hours";
                    }
                    break;
                case "carrierUnit":
                    _preferences.autoCompleteTripCarrier.unit = value;
                    this.tripSettings = _preferences;
                    break;
                case "carrierOffset":
                    if (value < 0 || value == null || value == "") {
                        value = 0;
                    }
                    _preferences.autoCompleteTripCarrier.offset = value;
                    this.tripSettings = _preferences;
                    break;
                case "carrierEnabled":
                    _preferences.autoCompleteTripCarrier.enabled = value;
                    this.tripSettings = _preferences;
                    if (!value) {
                        this.tripSettings.autoCompleteTripCarrier.offset = 0;
                        this.tripSettings.autoCompleteTripCarrier.unit = "hours";
                    }
                    break;
                case "trackerActivatedUnit":
                    _preferences.autoCompleteTripTrackerActivated.unit = value;
                    this.tripSettings = _preferences;
                    break;
                case "trackerActivatedOffset":
                    if (value < 0 || value == null || value == "") {
                        value = 0;
                    }
                    _preferences.autoCompleteTripTrackerActivated.offset = value;
                    this.tripSettings = _preferences;
                    break;
                case "trackerActivatedEnabled":
                    _preferences.autoCompleteTripTrackerActivated.enabled = value;
                    this.tripSettings = _preferences;
                    if (!value) {
                        this.tripSettings.autoCompleteTripTrackerActivated.offset = 0;
                        this.tripSettings.autoCompleteTripTrackerActivated.unit = "hours";
                    }
                    break;
                case "tripCompletedReportEnabled":
                    _preferences.autoSendTripCompletedReport.enabled = value;
                    this.tripSettings = _preferences;
                    if (!value) {
                        this.tripSettings.autoSendTripCompletedReport.recipients = null;
                    }
                    break;
                default:
                    break;
            }

            //update preferences after 1 second
            if (autosave) {
                setTimeout(() => {
                    this.updateOrganisationPreferences();
                }, 1000);
            }
        },
        updateOrganisationPreferences() {
            this.loadingBtn = true;
            let _preferences = JSON.stringify(this.tripSettings);

            this.$apollo
                .mutate({
                    mutation: gql`
                        mutation UpdateOrganisationPreferences($input: UpdateOrganisationPreferencesInput!) {
                            updateOrganisationPreferences(input: $input) {
                                tenant {
                                    id
                                    name
                                    organisationPermissions
                                    organisationPreferences
                                }
                            }
                        }
                    `,
                    variables: {
                        input: {
                            tenantId: this.me?.tenant ? this.me?.tenant.id : this.tenant.id,
                            preferences: _preferences
                        }
                    }
                })
                .then(() => {
                    this.editMode = false;
                    this.loadingBtn = false;
                    const payload = {
                        color: "blue",
                        message: this.$t("general.update_successfully")
                    };
                    this.$store.dispatch("snackbar/showMessage", payload);
                });
        },
        isValidObject(obj) {
            if (obj == null || obj == undefined || obj == "") {
                return false;
            } else {
                let _obj = JSON.parse(obj);
                if (Object.keys(_obj).length == 0) {
                    return false;
                } else {
                    return true;
                }
            }
        },
        addAutoCompleteIfMissing(obj) {
            if (obj.autoCompleteTripTracker == undefined) {
                obj.autoCompleteTripTracker = {
                    enabled: false,
                    unit: "hours",
                    offset: 0
                };
            }
            if (obj.autoCompleteTripVesselETA == undefined) {
                obj.autoCompleteTripVesselETA = {
                    enabled: false,
                    unit: "hours",
                    offset: 0
                };
            }
            if (obj.autoCompleteTripVesselATA == undefined) {
                obj.autoCompleteTripVesselATA = {
                    enabled: false,
                    unit: "hours",
                    offset: 0
                };
            }
            if (obj.autoCompleteTripCarrier == undefined) {
                obj.autoCompleteTripCarrier = {
                    enabled: false,
                    unit: "hours",
                    offset: 0
                };
            }
            if (obj.autoCompleteTripTrackerActivated == undefined) {
                obj.autoCompleteTripTrackerActivated = {
                    enabled: false,
                    unit: "days",
                    offset: 0
                };
            }
            if (obj.autoSendTripCompletedReport == undefined) {
                obj.autoSendTripCompletedReport = {
                    enabled: false,
                    recipients: null
                };
            }

            return obj;
        }
    }
};
</script>

<style scoped>
.title {
    color: #0e0d35;
    font-size: 18px;
    font-weight: 600;
    line-height: 21.6px;
    text-align: left;
}
.subTitle {
    font-size: 14px;
    font-weight: 500;
}
.header {
    font-size: 16px;
    font-weight: 700;
}
.subHeader {
    font-size: 14px;
    font-weight: 700;
}
.body {
    color: #0e0d35;
    font-size: 12px;
    font-weight: 700;
    line-height: 14.4px;
    text-align: left;
}
.exampleText {
    color: #868599;
    font-size: 10px;
    font-weight: 400;
    line-height: 12px;
    text-align: left;
}
.warningMessage {
    color: #868599;
    font-size: 10px;
    font-weight: 700;
    line-height: 12px;
    text-align: left;
}
.tabText {
    font-size: 16px;
    font-weight: 700;
}
</style>
