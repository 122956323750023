<template>
    <v-hover>
        <template v-slot="{ hover }">
            <v-card
                v-resize="onResize"
                class="py-5"
                :class="shouldStickyFilter ? 'sticky-card' : ''"
                :elevation="hover ? 5 : 3"
                outlined
            >
                <v-row align="start" class="px-5 d-flex align-center">
                    <v-col cols="8" sm="9" lg="10">
                        <v-row>
                            <v-col
                                v-for="f in dynamicFilters"
                                :key="f"
                                :class="getFilterChoiceClassByCode(f)"
                            >
                                <v-text-field
                                    v-if="f === 'partnerName'"
                                    v-model="filter.nameIcontains"
                                    :label="$t('headers.partner_name')"
                                    clearable
                                    hide-details
                                    dense
                                ></v-text-field>

                                <v-text-field
                                    v-if="f === 'referenceNumber'"
                                    v-model="filter.referenceNumberIcontains"
                                    :label="$t('headers.reference_number')"
                                    clearable
                                    hide-details
                                    dense
                                ></v-text-field>

                                <v-autocomplete
                                    v-if="f === 'carrierType'"
                                    v-model="filter.carrierType"
                                    :items="transportModeChoices"
                                    :label="$t('headers.carrier_type')"
                                    clearable
                                    hide-details
                                    dense
                                    color="primary"
                                    :menu-props="{
                                        zIndex: 3 
                                    }"
                                ></v-autocomplete>
                            </v-col>

                            <!-- sort by -->
                            <v-col class="col-auto col-md-3 col-lg-2 col-xl-2">
                                <v-row class="d-flex align-center ma-0 pa-0">
                                    <v-select
                                        v-model="sortBy"
                                        :items="sortByChoices"
                                        :label="$t('general.sort_by')"
                                        :item-text="item => item.name"
                                        :item-value="item => item.code"
                                        clearable
                                        hide-details
                                        dense
                                        color="primary"
                                        :menu-props="{
                                            zIndex: 3
                                        }"
                                    >
                                        <template v-slot:append-outer>
                                            <v-btn
                                                class="mt-n1"
                                                small
                                                icon
                                                :disabled="!sortBy"
                                                @click="sortAsc = !sortAsc"
                                            >
                                                <v-icon color="grey darken-2">{{
                                                    sortAsc ? "north" : "south"
                                                }}</v-icon>
                                            </v-btn>
                                        </template>
                                    </v-select>
                                </v-row>
                            </v-col>
                        </v-row>
                    </v-col>
                    <AddPartnerDialog
                        :openAddPartnerDialog="openAddPartnerDialog"
                        :partnerByType.sync="partnerType"
                        v-on:refreshPartnersTable="$emit('refreshDataTable')"
                        v-on:closeAddPartnerDialog="
                            openAddPartnerDialog = false
                        "
                        v-on:item-created="onCarrierCreated"
                    ></AddPartnerDialog>
                    <!-- filters menu -->
                    <v-col cols="4" sm="3" lg="2" class="py-2 pr-4 text-right">
                        <v-row class="d-flex align-center justify-end">
                           
                            <!-- filters menu -->
                            <FilterMenu
                                :title="$t('dashboard.customize')"
                                :description="$t('dashboard.display_filters')"
                                :choices="filterChoices"
                                :open-on-hover="false"
                                height="500px"
                                
                                v-on:changed="onFilterMenuChanged"
                            >
                            </FilterMenu>
                        </v-row>
                    </v-col>
                </v-row>
            </v-card>
        </template>
    </v-hover>
</template>

<script>
import helper from "@/utils/helper.js";
import FilterMenu from "@/components/base/FilterMenu.vue";
import AddPartnerDialog from "@/components/partners/create/AddPartnerDialog.vue";

export default {
    components: { FilterMenu, AddPartnerDialog },
    apollo: {},

    data() {
        return {
            isLoading: false,
            fab: false,
            menu: false,
            sortAsc: true,
            shouldStickyFilter: false,

            partnerType: "carrier",
            openAddPartnerDialog: false,

            // --------------------> FILTERS <----------------------- \\
            defaultFilterChoices: [
                {
                    code: "partnerName",
                    name: "Partner Name",
                    tag: "headers.partner_name",
                    class: "col-auto col-md-3 col-lg-2",
                    enabled: true,
                    order: 0
                },
                {
                    code: "referenceNumber",
                    name: "Reference Number",
                    tag: "headers.reference_number",
                    class: "col-auto col-md-3 col-lg-2",
                    enabled: true,
                    order: 1
                },
                {
                    code: "carrierType",
                    name: "Carrier Type",
                    tag: "headers.carrier_type",
                    class: "col-auto col-md-3 col-lg-2",
                    enabled: true,
                    order: 2
                }
            ],

            filterChoices: null,

            sortByChoices: [
                {
                    code: "name",
                    name: this.$t("headers.partner_name")
                },
                {
                    code: "openTrips",
                    name: this.$t("headers.open_trips1")
                },
                {
                    code: "totalTrips",
                    name: this.$t("headers.total_trips")
                }
            ],

            sortBy: null,

            // transportModeChoices: [
            //     { text: this.$t("transport_mode_choice.air"), value: "air" },
            //     { text: this.$t("transport_mode_choice.road"), value: "road" },
            //     { text: this.$t("transport_mode_choice.rail"), value: "rail" },
            //     { text: this.$t("transport_mode_choice.sea"), value: "sea" }
            // ],

            // graphql query filter
            filter: {
                first: 15,
                after: null,
                nameIcontains: null,
                referenceNumberIcontains: null,
                carrierType: null,
                partnerType: "carrier",
                orderBy: null
            }
        };
    },

    computed: {
        me() {
            return helper.me();
        },
        dynamicFilters() {
            return this.filterChoices.filter(f => f.enabled).map(f => f.code);
        },
        transportModeChoices: function() {
            let _transportModeChoices = [
                { text: this.$t("transport_mode_choice.air"), value: "air" },
                { text: this.$t("transport_mode_choice.road"), value: "road" },
                { text: this.$t("transport_mode_choice.rail"), value: "rail" },
                { text: this.$t("transport_mode_choice.sea"), value: "sea" }
            ];
            return _transportModeChoices;
        }
    },
    watch: {
        filter: {
            handler(value) {
                if (value) {
                    this.$emit(
                        "filter-changed",
                        JSON.parse(JSON.stringify(value))
                    );
                }
            },
            deep: true
        },
        sortBy(val) {
            if (val) {
                this.filter.orderBy = this.sortAsc ? val : `-${val}`;
            } else {
                this.filter.orderBy = null;
            }
        },
        sortAsc(val) {
            if (this.sortBy) {
                this.filter.orderBy = val ? this.sortBy : `-${this.sortBy}`;
            } else {
                this.filter.orderBy = null;
            }
        }
    },
    created() {
        this.hasPermission = helper.hasPermission;
        this.pushTo = helper.pushTo;
        this.filterChoices = helper.getFilterChoices(
            this.defaultFilterChoices,
            "partnerTableFilters"
        );
    },
    mounted() {
        this.onResize();

        this.$watch(
            "filterChoices",
            function(value) {
                helper.updateMyPreferences({
                    partnerTableFilters: value
                });
            },
            { deep: true }
        );
    },
    methods: {
        onResize() {
            // this.shouldStickyFilter =
            //     window.innerHeight >= 900 && window.innerWidth >= 1000;
        },
        // dynamic filter
        getFilterChoiceClassByCode(code) {
            return this.filterChoices.find(item => {
                return item.code === code;
            })?.class;
        },

        formatDate(date) {
            if (!date) return null;

            const [year, month, day] = date.split("-");
            return `${day}/${month}/${year}`;
        },
        onFilterMenuChanged(newChoices) {
            this.filterChoices = newChoices;
        },
        onCarrierCreated(val) {
            this.$emit("carrier-created", val);
        }
    }
};
</script>

<style scoped>
.sticky-card {
    position: -webkit-sticky;
    position: sticky;
    top: 46px;
    z-index: 2;
}
</style>
