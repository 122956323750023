<template>
    <v-dialog v-model="openAlertDetails" :max-width="dinamicWidth" persistent scrollable @click:outside="openAlertDetails = false">
        <template v-slot:activator="{ on, attrs }">
            <v-btn class="pa-2 mx-1" :icon="icon" :plain="plain" v-bind="attrs" v-on="on" :x-small="xSmall">
                <v-tooltip bottom>
                    <template v-slot:activator="{ on, attrs }">
                        <v-icon v-bind="attrs" v-on="on">visibility </v-icon>
                        <span class="ml-2" v-if="plain">{{ $t("headers.details") }}</span>
                    </template>
                    <span>{{ $t("headers.details") }}</span>
                </v-tooltip>
            </v-btn>
        </template>
        <v-card class="background">
            <v-card-title >
                <v-icon v-if="tripAlert" class="mr-2" color="black">notifications</v-icon>
                {{ tripAlert ? tripAlert.tenantAlertRule.name : "" }}
                <v-spacer></v-spacer>
                <v-btn @click="redirect(dashboardTripId)" class="mr-2" v-if="dashboardTripId" icon>
                    <v-icon>
                        open_in_new
                    </v-icon>
                </v-btn>
                <v-btn icon @click="openAlertDetails = false">
                    <v-icon>
                        close
                    </v-icon>
                </v-btn>
            </v-card-title>
            <v-card-text :loading="$apollo.queries.tripAlert.loading">
                <LoadingBar v-if="$apollo.queries.tripAlert.loading || !tripAlert"></LoadingBar>
                <v-row v-else>
                    <v-col
                        class="d-flex flex-wrap justify-center align-start px-0 pb-0"
                        cols="12" xs="12" sm="12" md="5" lg="5" xl="5"
                    >
                        <v-row class="subtitle-1 ma-0" style="width: 100%">
                            <v-col cols="12" xs="12" sm="12" md="12" lg="12" xl="12" class="pa-1">
                                <v-card 
                                    class="rounded-lg pa-3 pt-2 fixed-height-top-cards overflow-auto"
                                    outlined
                                    flat
                                    style="width: 100%"
                                >
                                    <v-row class="pt-2 pb-2">
                                        <v-col class="d-flex flex-row" cols="12" >
                                                <v-img
                                                    :src="require('@/assets/warning.png')"
                                                    max-width="25"
                                                    max-height="25"
                                                    class="mr-3"
                                                ></v-img>
                                            
                                                <div class="textTitle text-no-wrap">{{ $t("headers.alert_detail") }}</div>
                                            </v-col>
                                    </v-row>

                                    <v-row class="d-flex flex-wrap">
                                        <v-col cols="6" xs="12" sm="6" md="6" lg="6" xl="6" class="py-0">
                                            <div class="txtIndex text-start">
                                                {{ $t("headers.name") }}
                                            </div>
                                            <div class="txtBody text-start">
                                                {{ tripAlert.tenantAlertRule.name ? tripAlert.tenantAlertRule.name : '&nbsp;&nbsp;&nbsp;&nbsp;-' }}
                                            </div>
                                        </v-col>
                                        <v-col cols="6" xs="12" sm="6" md="6" lg="6" xl="6" class="py-0">
                                            <div class="txtIndex text-start">
                                                {{ $t("headers.severity") }}
                                            </div>
                                            <div class="txtBody text-start">
                                                {{
                                                        tripAlert.tenantAlertRule.alertSeverity ? alertChoicesTitle(tripAlert.tenantAlertRule.alertSeverity) : '&nbsp;&nbsp;&nbsp;&nbsp;-'
                                                }}
                                            </div>
                                        </v-col>
                                        <v-col cols="12" xs="12" sm="12" md="12" lg="12" xl="12" class="py-0" >
                                            <div class="txtIndex text-start">
                                                {{ $t("headers.description") }}
                                            </div>
                                            <div class="txtBody text-start" >
                                                {{ tripAlert.tenantAlertRule.description ? tripAlert.tenantAlertRule.description : '&nbsp;&nbsp;&nbsp;&nbsp;-' }}
                                            </div>
                                        </v-col>
                                    </v-row>
                                    <v-expansion-panels
                                        v-model="panel"
                                        multiple
                                        accordion
                                        class="rounded mt-4 py-0"
                                        flat
                                    >
                                        <v-expansion-panel class="mb-2 outlineD contentBg rounded-lg" >
                                            <v-expansion-panel-header class="contentBg rounded-lg my-0 px-6">
                                                <v-row>
                                                    <v-icon class="mr-2">bolt</v-icon>
                                                    <span class="mt-1">{{
                                                        $t("headers.triggers")
                                                    }}</span>
                                                </v-row>
                                            </v-expansion-panel-header>
                                            <v-expansion-panel-content class="py-0 px-4" v-if="tripAlert? tripAlert.tenantAlertRule.standardAlertCode: false">
                                                
                                                <td class="txtBody text-start py-2">
                                                    {{ tripAlert.tenantAlertRule.standardAlertCode ? getStandardAlertRuleText() : '&nbsp;&nbsp;&nbsp;&nbsp;-' }}
                                                </td>

                                            </v-expansion-panel-content>
                                            <v-expansion-panel-content class="py-0 px-4" v-else>
                                                <v-card class="py-3" flat>
                                                    <v-row class="mt-1" align-stretch>
                                                        <v-col cols="12" class="d-flex align-center py-0">
                                                            <span class="txtBody mt-2">{{ $t("headers.sensor") }}:</span>
                                                            <span class="ml-2 txtIndex">
                                                                {{ triggers.edges.map(edge => JSON.parse(edge.node.triggerJson).Sensor ? JSON.parse(edge.node.triggerJson).Sensor.map(s => sensorText(s)).join(', ') : '&nbsp;&nbsp;&nbsp;&nbsp;-' ).join('; ') || '&nbsp;&nbsp;&nbsp;&nbsp;-' }}
                                                            </span>
                                                        </v-col>

                                                         
                                                        <v-col cols="12" class="d-flex align-center py-0">
                                                            <span class="txtBody mt-2">{{ $t("headers.location") }}:</span>
                                                            <span class="ml-2 txtIndex">
                                                                {{ triggers.edges.map(edge => locationText(JSON.parse(edge.node.triggerJson))).filter(Boolean).join('; ') || '&nbsp;&nbsp;&nbsp;&nbsp;-' }}
                                                            </span>
                                                        </v-col>

                                                       
                                                        <v-col cols="12" class="d-flex align-center py-0">
                                                            <span class="txtBody mt-2">{{ $t("headers.timer") }}:</span>
                                                            <span class="ml-2 txtIndex">
                                                                {{ triggers.edges.map(edge => timerText(JSON.parse(edge.node.triggerJson))).filter(Boolean).join('; ') || '&nbsp;&nbsp;&nbsp;&nbsp;-' }}
                                                            </span>
                                                        </v-col>
                                                    </v-row>
                                                </v-card>
                                            </v-expansion-panel-content>
                                        </v-expansion-panel>
                                        <v-expansion-panel class="mb-2 outlineD contentBg rounded-lg" >
                                            <v-expansion-panel-header class="contentBg rounded-lg my-0 px-6">
                                                <v-row class="">
                                                    <v-icon class="mr-2">group</v-icon>
                                                    <span class="mt-1">{{
                                                        $t("home.action_groups")
                                                    }}</span>
                                                </v-row>
                                            </v-expansion-panel-header>
                                            <v-expansion-panel-content>
                                                <LoadingBar
                                                    v-if="$apollo.queries.actionGroups.loading"
                                                ></LoadingBar>
                                                <v-card v-else class="py-3" flat>
                                                    <v-row dense class="px-2">
                                                        <v-col
                                                            cols="12"
                                                            v-for="group in groups"
                                                            :key="group.node.id"
                                                        >
                                                            <v-card
                                                                class="d-flex py-4 px-3 align-center "
                                                                flat
                                                            >
                                                                <v-row dense class="d-flex align-center justify-space-between">
                                                                    <v-col
                                                                        cols="4" xs="12" sm="12" md="4" lg="4" xl="4"
                                                                        class="txtBody mb-0"
                                                                    >
                                                                        <span
                                                                            >{{
                                                                                group.node
                                                                                    .tenantActionGroup
                                                                                    .name
                                                                            }}
                                                                        </span>
                                                                    </v-col>

                                                                    <v-col cols="auto">
                                                                        <v-badge
                                                                            bordered
                                                                            bottom
                                                                            color="primary"
                                                                            :content="
                                                                                group.node
                                                                                    .tenantActionGroup
                                                                                    .tenantActionGroupUserSet
                                                                                    .totalCount >
                                                                                0
                                                                                    ? group.node
                                                                                        .tenantActionGroup
                                                                                        .tenantActionGroupUserSet
                                                                                        .totalCount
                                                                                    : '0'
                                                                            "
                                                                            overlap
                                                                            offset-x="10"
                                                                            offset-y="10"
                                                                        >
                                                                            <v-tooltip bottom>
                                                                                <template
                                                                                    v-slot:activator="{
                                                                                        on,
                                                                                        attrs
                                                                                    }"
                                                                                >
                                                                                    <v-icon
                                                                                        v-bind="
                                                                                            attrs
                                                                                        "
                                                                                        v-on="
                                                                                            on
                                                                                        "
                                                                                    >
                                                                                        person
                                                                                    </v-icon>
                                                                                </template>
                                                                                <span>{{
                                                                                    $t(
                                                                                        "home.users"
                                                                                    )
                                                                                }}</span>
                                                                            </v-tooltip>
                                                                        </v-badge>
                                                                    </v-col>
                                                                    <v-col cols="auto">
                                                                        <v-badge
                                                                            bordered
                                                                            bottom
                                                                            color="primary"
                                                                            :content="
                                                                                convertString(
                                                                                    group.node
                                                                                        .tenantActionGroup
                                                                                        .externalEmails
                                                                                ).length > 0
                                                                                    ? convertString(
                                                                                        group
                                                                                            .node
                                                                                            .tenantActionGroup
                                                                                            .externalEmails
                                                                                    ).length
                                                                                    : '0'
                                                                            "
                                                                            overlap
                                                                            offset-x="10"
                                                                            offset-y="10"
                                                                        >
                                                                            <v-tooltip bottom>
                                                                                <template
                                                                                    v-slot:activator="{
                                                                                        on,
                                                                                        attrs
                                                                                    }"
                                                                                >
                                                                                    <v-icon
                                                                                        v-bind="
                                                                                            attrs
                                                                                        "
                                                                                        v-on="
                                                                                            on
                                                                                        "
                                                                                    >
                                                                                        email
                                                                                    </v-icon>
                                                                                </template>
                                                                                <span>{{
                                                                                    $t(
                                                                                        "general.external_emails"
                                                                                    )
                                                                                }}</span>
                                                                            </v-tooltip>
                                                                        </v-badge>
                                                                    </v-col>
                                                                    <v-col cols="auto">
                                                                        <v-badge
                                                                            bordered
                                                                            bottom
                                                                            :color="
                                                                                group.node
                                                                                    .tenantActionGroup
                                                                                    .shouldEmailNotification
                                                                                    ? 'green'
                                                                                    : 'red lighten-3'
                                                                            "
                                                                            :icon="
                                                                                group.node
                                                                                    .tenantActionGroup
                                                                                    .shouldEmailNotification
                                                                                    ? 'done'
                                                                                    : 'close'
                                                                            "
                                                                            overlap
                                                                            offset-x="10"
                                                                            offset-y="10"
                                                                        >
                                                                            <v-tooltip bottom>
                                                                                <template
                                                                                    v-slot:activator="{
                                                                                        on,
                                                                                        attrs
                                                                                    }"
                                                                                >
                                                                                    <v-icon
                                                                                        v-bind="
                                                                                            attrs
                                                                                        "
                                                                                        v-on="
                                                                                            on
                                                                                        "
                                                                                    >
                                                                                        warning
                                                                                    </v-icon>
                                                                                </template>
                                                                                <span>{{
                                                                                    $t(
                                                                                        "general.email_notification"
                                                                                    )
                                                                                }}</span>
                                                                            </v-tooltip>
                                                                        </v-badge>
                                                                    </v-col>
                                                                    <v-col cols="auto">
                                                                        <v-badge
                                                                            bordered
                                                                            bottom
                                                                            :color="
                                                                                group.node
                                                                                    .tenantActionGroup
                                                                                    .shouldInboxNotification
                                                                                    ? 'green'
                                                                                    : 'red lighten-3'
                                                                            "
                                                                            :icon="
                                                                                group.node
                                                                                    .tenantActionGroup
                                                                                    .shouldInboxNotification
                                                                                    ? 'done'
                                                                                    : 'close'
                                                                            "
                                                                            overlap
                                                                            offset-x="10"
                                                                            offset-y="10"
                                                                        >
                                                                            <v-tooltip bottom>
                                                                                <template
                                                                                    v-slot:activator="{
                                                                                        on,
                                                                                        attrs
                                                                                    }"
                                                                                >
                                                                                    <v-icon
                                                                                        v-bind="
                                                                                            attrs
                                                                                        "
                                                                                        v-on="
                                                                                            on
                                                                                        "
                                                                                    >
                                                                                        notifications
                                                                                    </v-icon>
                                                                                </template>
                                                                                <span>{{
                                                                                    $t(
                                                                                        "general.inbox_notification"
                                                                                    )
                                                                                }}</span>
                                                                            </v-tooltip>
                                                                        </v-badge>
                                                                    </v-col>
                                                                </v-row>
                                                            </v-card>
                                                        </v-col>
                                                    </v-row>
                                                </v-card>
                                            </v-expansion-panel-content>
                                        </v-expansion-panel>
                                        <v-expansion-panel class="mb-2 outlineD contentBg rounded-lg" >
                                            <v-expansion-panel-header class="contentBg rounded-lg my-0 px-6">
                                                <v-row>
                                                    <v-icon class="mr-2">alarm_on</v-icon>
                                                    <span class="mt-1">{{
                                                        $t("alert_rules.alert_automation")
                                                    }}</span>
                                                </v-row>
                                            </v-expansion-panel-header>
                                            <v-expansion-panel-content v-if="validAutomateStep">
                                                <v-card class="py-3" flat>
                                                <v-row dense class="px-2 ">
                                                    <v-col
                                                        cols="12"
                                                        v-if="tripAlert.tenantAlertRule.applyToAllNewTrips"
                                                    >
                                                        <v-card class="py-5 px-3" flat >
                                                            <div class="d-flex flex-row">
                                                                <div class="mr-2">
                                                                    <span>{{
                                                                        $t(
                                                                            "alert_rules.apply_trips"
                                                                        )
                                                                    }}</span>
                                                                </div>
                                                                <v-spacer></v-spacer>
                                                                <v-badge
                                                                    bordered
                                                                    bottom
                                                                    :color="
                                                                        tripAlert.tenantAlertRule.applyToAllNewTrips
                                                                            ? 'green'
                                                                            : 'red lighten-3'
                                                                    "
                                                                    :icon="
                                                                        tripAlert.tenantAlertRule.applyToAllNewTrips
                                                                            ? 'done'
                                                                            : 'close'
                                                                    "
                                                                    overlap
                                                                    offset-x="10"
                                                                    offset-y="10"
                                                                    class="mr-2"
                                                                >
                                                                    <v-tooltip bottom>
                                                                        <template
                                                                            v-slot:activator="{
                                                                                on,
                                                                                attrs
                                                                            }"
                                                                        >
                                                                            <v-icon
                                                                                v-bind="attrs"
                                                                                v-on="on"
                                                                            >
                                                                                notifications
                                                                            </v-icon>
                                                                        </template>
                                                                        <span>{{
                                                                            $t(
                                                                                "alert_rules.notify_enable"
                                                                            )
                                                                        }}</span>
                                                                    </v-tooltip>
                                                                </v-badge>
                                                            </div>
                                                        </v-card>
                                                    </v-col>
                                                    <v-col cols="12" v-if="validPartners">
                                                        <v-card class="py-5 px-3" flat>
                                                            <div class="d-flex flex-row">
                                                                <div class="mr-2">
                                                                    <span>{{
                                                                        $t(
                                                                            "alert_rules.apply_new"
                                                                        )
                                                                    }}</span>
                                                                </div>
                                                                <v-spacer></v-spacer>
                                                                <v-badge
                                                                    bordered
                                                                    bottom
                                                                    :color="
                                                                        validPartners
                                                                            ? 'green'
                                                                            : 'red lighten-3'
                                                                    "
                                                                    :icon="
                                                                        validPartners
                                                                            ? 'done'
                                                                            : 'close'
                                                                    "
                                                                    overlap
                                                                    offset-x="10"
                                                                    offset-y="10"
                                                                    class="mr-2"
                                                                >
                                                                    <v-tooltip bottom>
                                                                        <template
                                                                            v-slot:activator="{
                                                                                on,
                                                                                attrs
                                                                            }"
                                                                        >
                                                                            <v-icon
                                                                                v-bind="attrs"
                                                                                v-on="on"
                                                                            >
                                                                                notifications
                                                                            </v-icon>
                                                                        </template>
                                                                        <span>{{
                                                                            $t(
                                                                                "alert_rules.notify_enable"
                                                                            )
                                                                        }}</span>
                                                                    </v-tooltip>
                                                                </v-badge>
                                                            </div>
                                                        </v-card>
                                                    </v-col>
                                                    <v-col cols="12" v-if="validArray(automate.carrier) || validArray(automate.consignor) || validArray(automate.forwarder) || validArray(automate.consignee) || validArray(automate.other)">
                                                        <v-row dense class="my-2">
                                                            <v-col
                                                                cols="12"
                                                                v-if="
                                                                    validArray(
                                                                        automate.carrier
                                                                    ) && validPartners
                                                                "
                                                                class="py-0"
                                                            >
                                                                <v-card
                                                                    flat
                                                                    class="py-1 "
                                                                >
                                                                    <div class="px-3">
                                                                        <span>{{
                                                                            $t("home.carriers")
                                                                        }}</span>
                                                                    </div>
                                                                    <div
                                                                        class="d-flex flex-column"
                                                                    >
                                                                        <v-chip
                                                                            v-for="(carrier,
                                                                            index) in automate.carrier"
                                                                            :key="index"
                                                                            class="ma-1"
                                                                            color=""
                                                                            text-color="white"
                                                                            small
                                                                        >
                                                                            {{ carrier.name }}
                                                                        </v-chip>
                                                                    </div>
                                                                </v-card>
                                                            </v-col>
                                                            <v-col
                                                                cols="12"
                                                                v-if="
                                                                    validArray(
                                                                        automate.consignor
                                                                    ) && validPartners
                                                                "
                                                                class="py-0"
                                                            >
                                                                <v-card
                                                                    flat
                                                                    class="py-1"
                                                                    
                                                                >
                                                                    <div class="px-3">
                                                                        <span>
                                                                            {{
                                                                                $t(
                                                                                    "headers.consignor"
                                                                                )
                                                                            }}
                                                                        </span>
                                                                    </div>
                                                                    <div
                                                                        class="d-flex flex-column"
                                                                    >
                                                                        <v-chip
                                                                            v-for="(consignor,
                                                                            index) in automate.consignor"
                                                                            :key="index"
                                                                            class="ma-1"
                                                                            color=""
                                                                            text-color="white"
                                                                            small
                                                                        >
                                                                            {{ consignor.name }}
                                                                        </v-chip>
                                                                    </div>
                                                                </v-card>
                                                            </v-col>
                                                            <v-col
                                                                cols="12"
                                                                v-if="
                                                                    validArray(
                                                                        automate.forwarder
                                                                    ) && validPartners
                                                                "
                                                                class="py-0"
                                                            >
                                                                <v-card
                                                                    flat
                                                                    class="py-1"
                                                                    
                                                                >
                                                                    <div class="px-3">
                                                                        {{
                                                                            $t(
                                                                                "headers.forwarder"
                                                                            )
                                                                        }}
                                                                    </div>
                                                                    <div
                                                                        class="d-flex flex-column"
                                                                    >
                                                                        <v-chip
                                                                            v-for="(forwarder,
                                                                            index) in automate.forwarder"
                                                                            :key="index"
                                                                            class="ma-1"
                                                                            color=""
                                                                            text-color="white"
                                                                            small
                                                                        >
                                                                            {{ forwarder.name }}
                                                                        </v-chip>
                                                                    </div>
                                                                </v-card>
                                                            </v-col>
                                                            <v-col
                                                                cols="12"
                                                                class="py-0"
                                                                v-if="
                                                                    validArray(
                                                                        automate.consignee
                                                                    ) && validPartners
                                                                "
                                                            >
                                                                <v-card
                                                                    flat
                                                                    class="py-1"
                                                                    
                                                                >
                                                                    <div class="px-3">
                                                                        {{
                                                                            $t(
                                                                                "headers.consignee"
                                                                            )
                                                                        }}
                                                                    </div>
                                                                    <div
                                                                        class="d-flex flex-column"
                                                                    >
                                                                        <v-chip
                                                                            v-for="(consignee,
                                                                            index) in automate.consignee"
                                                                            :key="index"
                                                                            class="ma-1"
                                                                            color=""
                                                                            text-color="white"
                                                                            small
                                                                        >
                                                                            {{ consignee.name }}
                                                                        </v-chip>
                                                                    </div>
                                                                </v-card>
                                                            </v-col>
                                                            <v-col
                                                                cols="12"
                                                                v-if="
                                                                    validArray(
                                                                        automate.other
                                                                    ) && validPartners
                                                                "
                                                                class="py-0"
                                                            >
                                                                <v-card
                                                                    flat
                                                                    class="py-1"
                                                                    
                                                                >
                                                                    <div class="px-2">
                                                                        {{$t('home.others')}}
                                                                    </div>
                                                                    <div
                                                                        class="d-flex flex-column"
                                                                    >
                                                                        <v-chip
                                                                            v-for="(other,
                                                                            index) in automate.other"
                                                                            :key="index"
                                                                            class="ma-1"
                                                                            color=""
                                                                            text-color="white"
                                                                            small
                                                                        >
                                                                            {{ other.name }}
                                                                        </v-chip>
                                                                    </div>
                                                                </v-card>
                                                            </v-col>
                                                        </v-row>
                                                    </v-col>
                                                </v-row>
                                            </v-card>
                                            </v-expansion-panel-content>
                                            <v-expansion-panel-content v-else>
                                                <v-card class="px-4 pt-2" flat>
                                                    <div class="txtBody text-start">
                                                        {{ $t('alert_rules.no_automation') }}
                                                    </div>
                                                </v-card>
                                            </v-expansion-panel-content>
                                        </v-expansion-panel>
                                    </v-expansion-panels>
                                </v-card>
                            </v-col>
                            <v-col cols="12" xs="12" sm="12" md="12" lg="12" xl="12" class="pa-1">
                                <v-card 
                                    class="rounded-lg pa-3 pt-2 overflow-auto fixed-height-bottom-cards"
                                    outlined
                                    flat
                                >
                                    <v-row class="pt-2 pb-2" >
                                        <v-col class="d-flex flex-row" cols="12">
                                            <v-icon 
                                                size="25" 
                                                :color="tripAlert? tripAlert.trip.status === 'ACTIVE' ? '#4CAF50' : 'black' : 'black'" 
                                                class="mr-3"
                                            >
                                                {{ getTransportModeIcon }}
                                            </v-icon>
                                            <div class="textTitle text-no-wrap">{{ $t("headers.trip_information") }}</div>
                                        </v-col>
                                    </v-row>
                                    <v-row class="d-flex flex-wrap">
                                        <v-col cols="12" xs="12" sm="12" md="6" lg="6" xl="6" class="py-0">
                                            <div class="txtIndex text-start">
                                                {{ $t("headers.trip_reference") }}
                                            </div>
                                            <div class="txtBody text-start">
                                                {{ tripAlert.trip ? tripAlert.trip.referenceNumber : '&nbsp;&nbsp;&nbsp;&nbsp;-' }}
                                            </div>
                                        </v-col>
                                        <v-col cols="12" xs="12" sm="12" md="6" lg="6" xl="6" class="py-0">
                                            <div class="txtIndex text-start">
                                                {{ $t("headers.carrier") }}
                                            </div>
                                            <div class="txtBody text-start">
                                                {{ getPartnerCarrier ? getPartnerCarrier : '&nbsp;&nbsp;&nbsp;&nbsp;-' }}
                                            </div>
                                        </v-col>
                                        <v-col cols="12" xs="12" sm="12" md="6" lg="6" xl="6" class="py-0">
                                            <div class="txtIndex text-start">
                                                {{ $t("headers.origin") }}
                                            </div>
                                            <div class="txtBody text-start">
                                                {{ getOrigin || '&nbsp;&nbsp;&nbsp;&nbsp;-' }}
                                            </div>
                                        </v-col>
                                        <v-col cols="12" xs="12" sm="12" md="6" lg="6" xl="6" class="py-0">
                                            <div class="txtIndex text-start">
                                                {{ $t("headers.destination") }}
                                            </div>
                                            <div class="txtBody text-start">
                                                {{ getDestination || '&nbsp;&nbsp;&nbsp;&nbsp;-' }}
                                            </div>
                                        </v-col>
                                        <v-col cols="12" xs="12" sm="12" md="6" lg="6" xl="6" class="py-0">
                                            <div class="txtIndex text-start">
                                                {{ $t("headers.planned_departure_date") }}
                                            </div>
                                            <div class="txtBody text-start">
                                                {{ getPlannedDepartureDate ? getPlannedDepartureDate : '&nbsp;&nbsp;&nbsp;&nbsp;-' }}
                                            </div>
                                        </v-col>
                                        <v-col cols="12" xs="12" sm="12" md="6" lg="6" xl="6" class="py-0">
                                            <div class="txtIndex text-start">
                                                {{ $t("headers.planned_arrival_date") }}
                                            </div>
                                            <div class="txtBody text-start">
                                                {{ getPlannedArrivalDate ? getPlannedArrivalDate : '&nbsp;&nbsp;&nbsp;&nbsp;-' }}
                                            </div>
                                        </v-col>
                                        <v-col cols="12" xs="12" sm="12" md="6" lg="6" xl="6" class="py-0">
                                            <div class="txtIndex text-start">
                                                {{ $t("headers.freight_reference") }}
                                            </div>
                                            <div class="txtBody text-start">
                                                <v-tooltip bottom v-if="hasMultipleFreightReferences()">
                                                    <template v-slot:activator="{ on, attrs }">
                                                        <span v-bind="attrs" v-on="on">
                                                            {{ getFirstFreightReference() ? getFirstFreightReference() : '&nbsp;&nbsp;&nbsp;&nbsp;-' }}
                                                            {{ hasMultipleFreightReferences() ? ', ...' : '' }}
                                                        </span>
                                                    </template>
                                                    <span style="white-space: pre-line">
                                                        {{ getAllFreightReferences() }}
                                                    </span>
                                                </v-tooltip>
                                                <span v-else>
                                                    {{ getFirstFreightReference() ? getFirstFreightReference() : '&nbsp;&nbsp;&nbsp;&nbsp;-' }}
                                                </span>
                                            </div>
                                        </v-col>
                                        <v-col cols="12" xs="12" sm="12" md="6" lg="6" xl="6" class="py-0">
                                            <div class="txtIndex text-start">
                                                {{ tripAlert.tripFreight.containerNumber ? $t("headers.container_number") : $t("headers.awb_number") }}
                                            </div>
                                            <div class="txtBody text-start">
                                                <v-tooltip bottom v-if="hasMultipleFreights()">
                                                    <template v-slot:activator="{ on, attrs }">
                                                        <span v-bind="attrs" v-on="on">
                                                            {{ getFirstFreightNumber() ? getFirstFreightNumber() : '&nbsp;&nbsp;&nbsp;&nbsp;-' }}
                                                            {{ hasMultipleFreights() ? ', ...' : '' }}
                                                        </span>
                                                    </template>
                                                    <span style="white-space: pre-line">
                                                        {{ getAllFreightNumbers() }}
                                                    </span>
                                                </v-tooltip>
                                                <span v-else>
                                                    {{ getFirstFreightNumber() ? getFirstFreightNumber() : '&nbsp;&nbsp;&nbsp;&nbsp;-' }}
                                                </span>
                                            </div>
                                        </v-col>
                                        <v-col cols="12" xs="12" sm="12" md="6" lg="6" xl="6" class="py-0">
                                            <div class="txtIndex text-start">
                                                {{ $t("headers.consignee") }}
                                            </div>
                                            <div class="txtBody text-start">
                                                <v-tooltip bottom v-if="getPartnerConsignee.includes(',')">
                                                    <template v-slot:activator="{ on, attrs }">
                                                        <span v-bind="attrs" v-on="on">
                                                            {{ getPartnerConsignee ? getPartnerConsignee : '&nbsp;&nbsp;&nbsp;&nbsp;-' }}
                                                        </span>
                                                    </template>
                                                    <span style="white-space: pre-line">
                                                        {{ getPartnerConsignee }}
                                                    </span>
                                                </v-tooltip>
                                                <span v-else>
                                                    {{ getPartnerConsignee ? getPartnerConsignee : '&nbsp;&nbsp;&nbsp;&nbsp;-' }}
                                                </span>
                                            </div>
                                        </v-col>
                                        <v-col cols="12" xs="12" sm="12" md="6" lg="6" xl="6" class="py-0">
                                            <div class="txtIndex text-start">
                                                {{ $t("headers.consignor") }}
                                            </div>
                                            <div class="txtBody text-start">
                                                <v-tooltip bottom v-if="getPartnerConsignor.includes(',')">
                                                    <template v-slot:activator="{ on, attrs }">
                                                        <span v-bind="attrs" v-on="on">
                                                            {{ getPartnerConsignor ? getPartnerConsignor : '&nbsp;&nbsp;&nbsp;&nbsp;-' }}
                                                            <!-- {{ hasMultipleFreightReferences() ? ', ...' : '' }} -->
                                                        </span>
                                                    </template>
                                                    <span style="white-space: pre-line">
                                                        {{ getPartnerConsignor }}
                                                    </span>
                                                </v-tooltip>
                                                <span v-else>
                                                    {{ getPartnerConsignor ? getPartnerConsignor : '&nbsp;&nbsp;&nbsp;&nbsp;-' }}
                                                </span>
                                            </div>
                                        </v-col>
                                    </v-row>
                                </v-card>
                            </v-col> 
                        </v-row>
                    </v-col>
                    <v-col
                        class="d-flex flex-wrap justify-center align-start px-0 pb-0"
                        cols="12" xs="12" sm="12" md="7" lg="7" xl="7"
                    >
                        <v-row class="subtitle-1 ma-0" style="width: 100%" v-if="tripAlert ? tripAlert.tenantAlertRule.isStandardAlert : false">
                            <v-col cols="12" xs="12" sm="12" md="12" lg="12" xl="12" class="pa-1">
                                <v-card 
                                    class="rounded-lg pa-3 pt-2 fixed-height-top-cards overflow-auto"
                                    outlined
                                    flat
                                    style="width: 100%"
                                >

                                    <v-row class="pt-2 pb-2">
                                        <v-col class="d-flex flex-row" cols="12" >
                                                <v-img
                                                    :src="require('@/assets/directions_boat.png')"
                                                    max-width="25"
                                                    max-height="25"
                                                    class="mr-3"
                                                ></v-img>
                                            
                                                <div class="textTitle text-no-wrap">{{ $t("headers.information") }}</div>
                                            </v-col>
                                    </v-row>
                                    <v-row dense class="mt-2">
                                        <v-col cols="12" class="py-1">
                                            <v-row no-gutters align="center">
                                                <v-col cols="6" class="txtIndex text-start">
                                                    {{ $t("headers.vessel") }}
                                                </v-col>
                                                <v-col cols="6" class="txtBody text-start">
                                                    {{ standardAlertInfo.vesselName }}
                                                </v-col>
                                            </v-row>
                                        </v-col>

                                        <v-col cols="12" class="py-1">
                                            <v-row no-gutters align="center">
                                                <v-col cols="6" class="txtIndex text-start">
                                                    {{ $t("trips.voyage") }}
                                                </v-col>
                                                <v-col cols="6" class="txtBody text-start">
                                                    {{ standardAlertInfo.voyage }}
                                                </v-col>
                                            </v-row>
                                        </v-col>

                                        <v-col cols="12" class="py-1">
                                            <v-row no-gutters align="center">
                                                <v-col cols="6" class="txtIndex text-start">
                                                    {{ this.standardAlertInfo.dateType }}
                                                </v-col>
                                                <v-col cols="6" class="txtBody text-start">
                                                    {{ standardAlertInfo.date }}
                                                </v-col>
                                            </v-row>
                                        </v-col>

                                        <v-col cols="12" class="py-1">
                                            <v-row no-gutters align="center">
                                                <v-col cols="6" class="txtIndex text-start">
                                                    {{ $t("headers.port") }}
                                                </v-col>
                                                <v-col cols="6" class="txtBody text-start">
                                                    {{ standardAlertInfo.port }}
                                                </v-col>
                                            </v-row>
                                        </v-col>

                                        <v-col cols="12" class="py-1">
                                            <v-row no-gutters align="center">
                                                <v-col cols="6" class="txtIndex text-start">
                                                    {{ $t("headers.container_number") }}
                                                </v-col>
                                                <v-col cols="6" class="txtBody text-start">
                                                    {{ standardAlertInfo.container }}
                                                </v-col>
                                            </v-row>
                                        </v-col>
                                    </v-row>
                                </v-card>
                            </v-col>
                        </v-row>
                        <v-row class="subtitle-1 ma-0" style="width: 100%" v-else>
                            <v-col cols="12" xs="12" sm="12" md="12" lg="12" xl="12" class="pa-1" >
                                <div width="100%">
                                    <AlertRuleTripMap
                                        v-if="showMap"
                                        ref="alertRuleTripMap"
                                        :alert="tripAlert"
                                        :startDate="startTime"
                                        :endDate="endTime"
                                    ></AlertRuleTripMap>
                                </div>
                            </v-col>
                            <v-col cols="12" xs="12" sm="12" md="12" lg="12" xl="12"  class="pa-1">
                                <LoadingBar v-if="isLoadingChart"></LoadingBar>
                                <div v-else>
                                    <v-card 
                                        class="rounded-lg px-3 pt-2 fixed-height-bottom-cards" 
                                        outlined
                                        flat
                                    >
                                        <v-row class="pt-2 pb-2" >
                                            <v-col class="d-flex flex-row pb-0" cols="12">
                                                <v-icon size="25" color="black" class="mr-2">bar_chart</v-icon>
                                                <div class="textTitle text-no-wrap">{{ $t("headers.chart") }}</div>
                                            </v-col>
                                        </v-row>
                                    
                                        <div >  
                                            <TripFreightTimelineChart
                                                v-if="trackerId"
                                                :minTemperature="minTemperature"
                                                :maxTemperature="maxTemperature"
                                                :minHumidity="minHumidity"
                                                :maxHumidity="maxHumidity"
                                                :minLight="minLight"
                                                :maxLight="maxLight"
                                                :minShock="minShock"
                                                :maxShock="maxShock"
                                                :chartStartTime="startTime"
                                                :chartEndTime="endTime"
                                                :currentStartTime="startTime"
                                                :currentEndTime="currentEndTime"
                                                :alertTrigger="tripAlertTriggers"
                                                :tripAlert="_tripAlert"
                                                :flat="true"
                                                :outlined="false"
                                                :temperatureSeries="mergeSensorData.temperature"
                                                :probeTemperaturesSeries="mergeSensorData.probeTemperature"
                                                :externalTemperaturesSeries="mergeSensorData.externalTemperature"
                                                :lightSeries="mergeSensorData.light"
                                                :humiditySeries="mergeSensorData.humidity"
                                                :shockSeries="mergeSensorData.shock"
                                                :selected="selectedSensors"
                                            ></TripFreightTimelineChart>
                                        </div>
                                    </v-card>
                                </div>
                            </v-col>
                        </v-row>
                    </v-col>
                </v-row>
            </v-card-text>
        </v-card>
    </v-dialog>
</template>

<script>
import gql from "graphql-tag";
import LoadingBar from "@/components/base/LoadingBar.vue";
import TripFreightTimelineChart from "@/components/trips/details/TripFreightTimelineChart.vue";
import AlertRuleTripMap from "@/components/alertrules/other/AlertRuleTripMap.vue";
import moment from "moment";
import helper from "@/utils/helper.js";

export default {
    props: {
        tripAlertId: {
            type: String,
            required: true
        },
        alertTime: {
            type: String
        },
        deviceTime: {
            type: String
        },
        dashboardTripId: {
            type: String,
            default: null
        },
        plain: {
            type: Boolean,
            default: false
        },
        tripId: {
            type: String,
            default: null
        },
        icon: {
            type: Boolean,
            default: true
        },
        xSmall: {
            type: Boolean,
            default: false
        }
    },
    components: {
        LoadingBar,
        TripFreightTimelineChart,
        AlertRuleTripMap
    },
    data() {
        return {
            openAlertDetails: false,
            tripAlert: null,
            trackerId: null,
            tripTimeRange: [],
            // showMap: true, //this is used to fix a bug related to the map and the DOM
            trigger: null,
            triggers: { edges: [] },
            panel: [0],
            automate: {},
            centerBounds: {
                lat: 40.73061,
                lng: -73.935242
            },
            tripAlertTriggers: [],
            sensorTypeChoices: [
                {
                    text: this.$t("general.temperature"),
                    value: "temperature",
                    icon: "thermostat",
                    unit: "temperature"
                },
                {
                    text: this.$t("general.external_temperature"),
                    value: "externalTemperature",
                    icon: "thermostat",
                    unit: "temperature"
                },
                {
                    text: this.$t("general.probe_temperature"),
                    value: "probeTemperature",
                    icon: "thermostat",
                    unit: "temperature"
                },
                {
                    text: this.$t("general.humidity"),
                    value: "humidity",
                    icon: "water_drop",
                    unit: "%"
                },
                {
                    text: this.$t("general.light"),
                    value: "light",
                    icon: "lightbulb",
                    unit: "Lux"
                },
                {
                    text: this.$t("general.shock"),
                    value: "shock",
                    icon: "bolt",
                    unit: "G"
                },
                {
                    text: this.$t("general.pressure"),
                    value: "pressure",
                    icon: "tire_repair",
                    unit: "Psi"
                }
            ],
            locationConditionChoices: [
                { text: this.$t("general.enter"), value: "enter" },
                { text: this.$t("general.exit"), value: "exit" },
                { text: this.$t("general.inside"), value: "inside" },
                { text: this.$t("general.outside"), value: "outside" }
            ]
            // standardAlertInfo: {
            //     vesselName: null,
            //     voyage: null,
            //     dateType: null,
            //     date: null,
            //     port: null,
            //     container: null
            // }
        };
    },
    apollo: {
        trackerDeviceTimeRange: {
            query: gql`
                query trackerDeviceTimeRange($tripId: ID, $trackerId: ID) {
                    trackerDeviceTimeRange(tripId: $tripId, trackerId: $trackerId)
                }
            `,
            variables() {
                return {
                    tripId: this.tripId ? this.tripId : null,
                    trackerId: null
                };
            },
            // Additional options here
            fetchPolicy: "cache-and-network",
            nextFetchPolicy: "cache-first", // this setting can avoid query again after fetchMore
            result({ data }) {
                if (data?.trackerDeviceTimeRange) {
                    this.tripTimeRange = JSON.parse(data.trackerDeviceTimeRange);
                }
            },
            debounce: 0,
            skip() {
                return false;
            }
        },
        tripAlert: {
            query: gql`
                query TripAlert($tripAlertId: ID!) {
                    tripAlert(id: $tripAlertId) {
                        id
                        trackerMergedSensorData {
                            id
                            deviceTime
                            longitude
                            latitude
                            temperature
                            humidity
                            light
                            externalTemperature
                            probeTemperature
                            pressure
                            co2
                            tracker {
                                id
                            }
                        }
                        deviceTime
                        alertTime
                        alertJson
                        tripFreight {
                            id
                            referenceNumber
                            airWaybillNumber
                            containerNumber
                        }
                        tenantAlertRule {
                            id
                            name
                            tag
                            isEnabled
                            description
                            alertSeverity
                            isStandardAlert
                            standardAlertCode
                            applyToCarriers
                            applyToConsignors
                            applyToForwarders
                            applyToConsignees
                            applyToOthers
                            applyToAllNewTrips
                        }
                        trip {
                            id
                            status
                            transportMode
                            referenceNumber
                            plannedDepartureDate
                            plannedArrivalDate
                            tripFreightSet {
                                edges {
                                    node {
                                        id
                                        referenceNumber
                                        airWaybillNumber
                                        containerNumber
                                        goods
                                    }
                                }
                            }
                            tenantCarrier {
                                id
                                name
                            }
                            tripPartnerSet {
                                edges {
                                    node {
                                        id
                                        tenantPartner {
                                            id
                                            name
                                            partnerType
                                        }
                                    }
                                }
                            }
                            originLocation {
                                id
                                latitude
                                longitude
                                name
                                radius
                            }
                            destinationLocation {
                                id
                                latitude
                                longitude
                                name
                                radius
                            }
                            tripSeaTransportSet(orderBy: "sequence") {
                                edges {
                                    node {
                                        id
                                        sequence
                                        portTransportType
                                        vessel {
                                            id
                                            name
                                        }
                                        estimatedArrivalDate
                                        actualArrivalDate
                                        estimatedDepartureDate
                                        actualDepartureDate
                                        vesselInputType
                                        vesselInputValue
                                        voyage
                                        portName
                                        portCode
                                    }
                                }
                            }
                        }
                    }
                }
            `,
            variables() {
                return {
                    tripAlertId: this.tripAlertId
                };
            },
            // Additional options here
            fetchPolicy: "cache-and-network",
            nextFetchPolicy: "cache-first",
            result({ data, loading }) {
                if (!loading) {
                    if (data.tripAlert) {
                        this.tripAlert = data.tripAlert;
                        this.trackerId = data.tripAlert?.trackerMergedSensorData?.tracker.id;

                        this.centerBounds = {
                            lat: data.tripAlert?.trackerMergedSensorData?.latitude,
                            lng: data.tripAlert?.trackerMergedSensorData?.longitude
                        };
                        if (data.tripAlert?.alertJson) {
                            this.tripAlertTriggers = [data.tripAlert?.alertJson];
                            this.trigger = JSON.parse(data.tripAlert?.alertJson);
                        }
                    }
                }
            },
            skip() {
                return !this.openAlertDetails;
            }
        },
        trackerMergedSensorDatas: {
            query: gql`
                query TrackerMergedSensorDatas(
                    $tracker: ID!
                    $deviceTimeGte: DateTime
                    $deviceTimeLte: DateTime
                    $orderBy: String
                ) {
                    trackerMergedSensorDatas(
                        tracker: $tracker
                        deviceTime_Lte: $deviceTimeLte
                        deviceTime_Gte: $deviceTimeGte
                        orderBy: $orderBy
                    ) {
                        edges {
                            node {
                                id
                                deviceTime
                                receiveTime
                                light
                                shock
                                humidity
                                temperature
                                externalTemperature
                                probeTemperature
                                latitude
                                longitude
                            }
                        }
                    }
                }
            `,
            variables() {
                return {
                    tracker: this.trackerId,
                    deviceTimeGte: this.startTime,
                    deviceTimeLte: this.endTime,
                    orderBy: "deviceTime"
                };
            },
            // Additional options here
            fetchPolicy: "cache-and-network",
            nextFetchPolicy: "cache-first", // this setting can avoid query again after fetchMore
            update: data => data.trackerMergedSensorDatas,
            skip() {
                let _trackerId = this.trackerId ? this.trackerId : null;
                return _trackerId !== null ? false : true;
            }
        },
        actionGroups: {
            query: gql`
                query TenantAlertRuleActionGroups(
                    $tenantAlertRuleId: ID!
                    $first: Int
                    $search: String
                ) {
                    tenantAlertRuleActionGroups(
                        tenantAlertRuleId: $tenantAlertRuleId
                        first: $first
                        search: $search
                    ) {
                        edges {
                            node {
                                id
                                tenantActionGroup {
                                    id
                                    name
                                    externalEmails
                                    shouldEmailNotification
                                    shouldInboxNotification
                                    tenantActionGroupUserSet {
                                        totalCount
                                    }
                                }
                            }
                        }
                    }
                }
            `,
            variables() {
                return {
                    tenantAlertRuleId: this.tripAlert?.tenantAlertRule.id,
                    first: 20,
                    search: this.searchInput
                };
            },
            fetchPolicy: "network-only",
            update: data => {
                return data.tenantAlertRuleActionGroups;
            },
            skip() {
                return !this.tripAlert?.tenantAlertRule?.id;
            }
        },
        triggers: {
            query: gql`
                query TenantAlertRuleTriggers(
                    $tenantAlertRuleId: ID!
                    $first: Int
                ) {
                    tenantAlertRuleTriggers(
                        tenantAlertRuleId: $tenantAlertRuleId
                        first: $first
                    ) {
                        edges {
                            node {
                                id
                                triggerJson
                            }
                        }
                    }
                }
            `,
            variables() {
                return {
                    tenantAlertRuleId: this.tripAlert?.tenantAlertRule.id,
                    first: 20
                };
            },
            fetchPolicy: "network-only",
            update: data => {
                return data.tenantAlertRuleTriggers;
            },
            skip() {
                return !this.tripAlert?.tenantAlertRule?.id;
            }
        }
    },

    computed: {
        me() {
            return helper.me();
        },
        availableSensorsData() {
            let sensors = [];
            if (this.mergeSensorData.temperature?.length > 0) {
                sensors.push("temperature");
            }
            if (this.mergeSensorData.probeTemperatures?.length > 0) {
                sensors.push("probeTemperature");
            }
            if (this.mergeSensorData.externalTemperature?.length > 0) {
                sensors.push("externalTemperature");
            }
            if (this.mergeSensorData.light?.length > 0) {
                sensors.push("light");
            }
            if (this.mergeSensorData.humidity?.length > 0) {
                sensors.push("humidity");
            }
            if (this.mergeSensorData.shock?.length > 0) {
                sensors.push("shock");
            }
            return sensors;
        },
        groups() {
            let _actionGroups = this.actionGroups.edges.filter(
                group => group.node
            );
            return _actionGroups;
        },
        dinamicWidth() {
           // let isStandardAlert = this.tripAlert ? this.tripAlert.tenantAlertRule.isStandardAlert : false;
            return "1200";
        },
        showMap() {
            return this.openAlertDetails;
        },
        isLoadingChart() {
            return this.$apollo.queries.trackerMergedSensorDatas.loading;
        },
        startTime() {
            let _start = this.updateDateTimeTimezone(this.tripTimeRange.start_date);
            return _start;
        },
        endTime() {
            let _end = this.updateDateTimeTimezone(this.tripTimeRange.end_date);
            return _end;
        },
        currentStartTime() {
            let _alert = this.updateDateTimeTimezone(this.alertTime || this.deviceTime);
            let _start = "";
            if (_alert) {
                _start = moment(_alert)
                    .subtract(1, "hours")
                    .toISOString();
            }
            return _start;
        },
        currentEndTime() {
            let _alert = this.updateDateTimeTimezone(this.alertTime || this.deviceTime);
            let _end = null;
            if (_alert) {
                _end = moment(_alert)
                    .add(1, "hours")
                    .toISOString();
            }
            return _end;
        },
        mergeSensorData() {
            const sensorTypes = [
                "light",
                "shock",
                "humidity",
                "temperature",
                "externalTemperature",
                "probeTemperatures"
            ];
            let object = {};
            for (const sensor of sensorTypes) {
                if (!this.$apollo.queries.trackerMergedSensorDatas.loading) {
                    object[sensor] = this.sensorData(sensor);
                } else {
                    object[sensor] = [];
                }
            }

            return object;
        },
        selectedSensors() {
            let sensors = [];
            if (this.trigger) {
                if (this.trigger.Sensor && this.trigger.TriggerType !== "location") {
                    this.trigger.Sensor.forEach(sensor => {
                        sensors.push(sensor.Type);
                    });
                } else {
                    if (this.availableSensorsData.length > 0) {
                        sensors = this.availableSensorsData;
                    } else {
                        sensors = ["temperature", "light"];
                    }
                }
            }

            return sensors;
        },
        _tripAlert() {
            let alert = [];
            if (this.tripAlert) {
                alert.push({
                    deviceTime: this.tripAlert.deviceTime
                        ? this.convertDateTimeTimezoneToMilliseconds(this.tripAlert.deviceTime)
                        : this.convertDateTimeTimezoneToMilliseconds(this.tripAlert.alertTime),

                    type: this.alertType(this.tripAlert.alertJson)
                });
            }

            return alert;
        },
        minTemperature() {
            let _min = 0;

            let temperature = this.mergeSensorData.temperature.map(item => item[1]) || [];

            let probeTemperature = this.mergeSensorData.probeTemperatures.map(item => item[1]) || [];

            let externalTemperature = this.mergeSensorData.externalTemperature.map(item => item[1]) || [];

            let min = [...temperature, ...probeTemperature, ...externalTemperature].filter(item => item !== null);

            _min = min.length > 0 ? min.reduce((a, b) => Math.min(a, b)) : 0;
            _min = Math.floor(_min);

            if (this.tripAlert?.alertJson != null) {
                let alert = JSON.parse(this.tripAlert.alertJson);
                if (alert?.Sensor && alert?.Sensor?.length > 0) {
                    alert.Sensor.forEach(sensor => {
                        if (sensor.Type == "temperature") {
                            if (sensor.Condition == "in" || sensor.Condition == "out") {
                                if (sensor.Value[0] < _min) {
                                    _min = Math.floor(sensor.Value[0]);
                                }
                            } else {
                                if (sensor.Value < _min) {
                                    _min = Math.floor(sensor.Value);
                                }
                            }
                        }
                    });
                }
            }

            return _min - 5;
        },
        maxTemperature() {
            let _max = 0;

            let temperature = this.mergeSensorData.temperature.map(item => item[1]) || [];

            let probeTemperature = this.mergeSensorData.probeTemperatures.map(item => item[1]) || [];

            let externalTemperature = this.mergeSensorData.externalTemperature.map(item => item[1]) || [];

            let max = [...temperature, ...probeTemperature, ...externalTemperature].filter(item => item !== null);

            _max = max.length > 0 ? max.reduce((a, b) => Math.max(a, b)) : 100;
            _max = Math.round(_max);

            if (this.tripAlert?.alertJson != null) {
                let alert = JSON.parse(this.tripAlert.alertJson);
                if (alert?.Sensor && alert?.Sensor?.length > 0) {
                    alert.Sensor.forEach(sensor => {
                        if (sensor.Type == "temperature") {
                            if (sensor.Condition == "in" || sensor.Condition == "out") {
                                if (sensor.Value[1] > _max) {
                                    _max = Math.round(sensor.Value[1]);
                                }
                            } else {
                                if (sensor.Value > _max) {
                                    _max = Math.round(sensor.Value);
                                }
                            }
                        }
                    });
                }
            }

            return _max + 5;
        },
        minHumidity() {
            let _min = 0;

            let humidity = this.mergeSensorData.humidity.map(item => item[1]) || [];

            let min = humidity.filter(item => item !== null);

            _min = min.length > 0 ? min.reduce((a, b) => Math.min(a, b)) : 0;
            _min = Math.floor(_min);

            if (this.tripAlert?.alertJson != null) {
                let alert = JSON.parse(this.tripAlert.alertJson);
                if (alert?.Sensor && alert?.Sensor?.length > 0) {
                    alert.Sensor.forEach(sensor => {
                        if (sensor.Type == "humidity") {
                            if (sensor.Condition == "in" || sensor.Condition == "out") {
                                if (sensor.Value[0] < _min) {
                                    _min = Math.floor(sensor.Value[0]);
                                }
                            } else {
                                if (sensor.Value < _min) {
                                    _min = Math.floor(sensor.Value);
                                }
                            }
                        }
                    });
                }
            }

            return _min - 0.01;
        },
        maxHumidity() {
            let _max = 0;

            let humidity = this.mergeSensorData.humidity.map(item => item[1]) || [];

            let max = humidity.filter(item => item !== null);

            _max = max.length > 0 ? max.reduce((a, b) => Math.max(a, b)) : 100;
            _max = Math.round(_max);

            if (this.tripAlert?.alertJson != null) {
                let alert = JSON.parse(this.tripAlert.alertJson);
                if (alert?.Sensor && alert?.Sensor?.length > 0) {
                    alert.Sensor.forEach(sensor => {
                        if (sensor.Type == "humidity") {
                            if (sensor.Condition == "in" || sensor.Condition == "out") {
                                if (sensor.Value[1] > _max) {
                                    _max = Math.round(sensor.Value[1]);
                                }
                            } else {
                                if (sensor.Value > _max) {
                                    _max = Math.round(sensor.Value);
                                }
                            }
                        }
                    });
                }
            }

            return _max + 0.01;
        },
        minLight() {
            let _min = 0;

            let light = this.mergeSensorData.light.map(item => item[1]) || [];

            let min = light.filter(item => item !== null);

            _min = min.length > 0 ? min.reduce((a, b) => Math.min(a, b)) : 0;
            _min = Math.floor(_min);

            if (this.tripAlert?.alertJson != null) {
                let alert = JSON.parse(this.tripAlert.alertJson);
                if (alert?.Sensor && alert?.Sensor?.length > 0) {
                    alert.Sensor.forEach(sensor => {
                        if (sensor.Type == "light") {
                            if (sensor.Condition == "in" || sensor.Condition == "out") {
                                if (sensor.Value[0] < _min) {
                                    _min = Math.floor(sensor.Value[0]);
                                }
                            } else {
                                if (sensor.Value < _min) {
                                    _min = Math.floor(sensor.Value);
                                }
                            }
                        }
                    });
                }
            }

            return _min - 1;
        },
        maxLight() {
            let _max = 0;

            let light = this.mergeSensorData.light.map(item => item[1]) || [];

            let max = light.filter(item => item !== null);

            _max = max.length > 0 ? max.reduce((a, b) => Math.max(a, b)) : 100;
            _max = Math.round(_max);

            if (this.tripAlert?.alertJson != null) {
                let alert = JSON.parse(this.tripAlert.alertJson);
                if (alert?.Sensor && alert?.Sensor?.length > 0) {
                    alert.Sensor.forEach(sensor => {
                        if (sensor.Type == "light") {
                            if (sensor.Condition == "in" || sensor.Condition == "out") {
                                if (sensor.Value[1] > _max) {
                                    _max = Math.round(sensor.Value[1]);
                                }
                            } else {
                                if (sensor.Value > _max) {
                                    _max = Math.round(sensor.Value);
                                }
                            }
                        }
                    });
                }
            }
            return _max + 100;
        },
        minShock() {
            let _min = 0;

            let shock = this.mergeSensorData.shock.map(item => item[1]) || [];

            let min = shock.filter(item => item !== null);

            _min = min.length > 0 ? min.reduce((a, b) => Math.min(a, b)) : 0;
            _min = Math.floor(_min);

            if (this.tripAlert?.alertJson != null) {
                let alert = JSON.parse(this.tripAlert.alertJson);
                if (alert?.Sensor && alert?.Sensor?.length > 0) {
                    alert.Sensor.forEach(sensor => {
                        if (sensor.Type == "shock") {
                            if (sensor.Condition == "in" || sensor.Condition == "out") {
                                if (sensor.Value[0] < _min) {
                                    _min = Math.floor(sensor.Value[0]);
                                }
                            } else {
                                if (sensor.Value < _min) {
                                    _min = Math.floor(sensor.Value);
                                }
                            }
                        }
                    });
                }
            }

            return _min - 1;
        },
        maxShock() {
            let _max = 0;

            let shock = this.mergeSensorData.shock.map(item => item[1]) || [];

            let max = shock.filter(item => item !== null);

            _max = max.length > 0 ? max.reduce((a, b) => Math.max(a, b)) : 100;
            _max = Math.round(_max);

            if (this.tripAlert?.alertJson != null) {
                let alert = JSON.parse(this.tripAlert.alertJson);
                if (alert?.Sensor && alert?.Sensor?.length > 0) {
                    alert.Sensor.forEach(sensor => {
                        if (sensor.Type == "shock") {
                            if (sensor.Condition == "in" || sensor.Condition == "out") {
                                if (sensor.Value[1] > _max) {
                                    _max = Math.round(sensor.Value[1]);
                                }
                            } else {
                                if (sensor.Value > _max) {
                                    _max = Math.round(sensor.Value);
                                }
                            }
                        }
                    });
                }
            }

            return _max + 10;
        },
        standardAlertInfo() {
            let standardAlertInfo = {
                vesselName: null,
                voyage: null,
                dateType: null,
                date: null,
                port: null,
                container: null
            };
            let code = this.tripAlert.tenantAlertRule.standardAlertCode;
            let containers = this.tripAlert.trip?.tripFreightSet?.edges.map(freight => {
                if (freight?.node?.containerNumber !== null || freight?.node?.containerNumber !== "") {
                    return freight?.node?.containerNumber;
                }
            });
            standardAlertInfo.container = containers.filter(item => item !== null).join(", ");

            let obj = {},
                sliced_obj = {},
                obj_1 = {};
            switch (code) {
                case "SEA_ETD_DELAY":
                    obj = this.tripAlert.trip?.tripSeaTransportSet?.edges[0];
                    standardAlertInfo.vesselName = `${obj?.node?.vessel?.name}(${obj?.node?.vesselInputValue})`;
                    standardAlertInfo.voyage = `${obj?.node?.voyage}`;
                    standardAlertInfo.date = `${this.formatDateTime(obj?.node?.estimatedDepartureDate)}`;
                    standardAlertInfo.port = `${obj?.node?.portName}`;
                    standardAlertInfo.dateType = "ETD";
                    break;
                case "SEA_ETA_DELAY":
                    sliced_obj = this.tripAlert.trip?.tripSeaTransportSet?.edges.slice(-2);
                    obj = sliced_obj[1];
                    obj_1 = sliced_obj[0];
                    standardAlertInfo.vesselName = `${obj_1?.node?.vessel?.name}(${obj_1?.node?.vesselInputValue})`;
                    standardAlertInfo.voyage = `${obj?.node?.voyage}`;
                    standardAlertInfo.date = `${this.formatDateTime(obj?.node?.estimatedArrivalDate)}`;
                    standardAlertInfo.port = `${obj?.node?.portName}`;
                    standardAlertInfo.dateType = "ETA";
                    break;
                case "SEA_TRANS_WAIT":
                    this.tripAlert.trip?.tripSeaTransportSet?.edges.forEach(item => {
                        if (
                            item.node?.portTransportType.toLowerCase() === "t/s" &&
                            (item.node?.actualArrivalDate !== null || item.node?.actualArrivalDate !== "")
                        ) {
                            standardAlertInfo.vesselName = `${item?.node?.vessel?.name}(${item?.node?.vesselInputValue})`;
                            standardAlertInfo.voyage = `${item?.node?.voyage}`;
                            standardAlertInfo.date = `${this.formatDateTime(item?.node?.estimatedDepartureDate)}`;
                            standardAlertInfo.port = `${item.node?.portName}`;
                            standardAlertInfo.dateType = "ETD";
                        }
                    });
                    break;
                case "SEA_DEST_WAIT":
                    sliced_obj = this.tripAlert.trip?.tripSeaTransportSet?.edges.slice(-2);
                    obj = sliced_obj[1];
                    obj_1 = sliced_obj[0];
                    standardAlertInfo.vesselName = `${obj_1?.node?.vessel?.name}(${obj_1?.node?.vesselInputValue})`;
                    standardAlertInfo.voyage = `${obj?.node?.voyage}`;
                    standardAlertInfo.date = `${this.formatDateTime(obj?.node?.actualArrivalDate)}`;
                    standardAlertInfo.port = `${obj.node?.portName}`;
                    standardAlertInfo.dateType = "ATA";
                    break;
                default:
                    break;
            }
            return standardAlertInfo;
        },
        validAutomateStep() {
            if (!this.tripAlert?.tenantAlertRule) return false;

            return (
              this.tripAlert.tenantAlertRule.applyToAllNewTrips ||
              this.convertString(this.tripAlert.tenantAlertRule.applyToCarriers).length > 0 ||
              this.convertString(this.tripAlert.tenantAlertRule.applyToConsignors).length > 0 ||
              this.convertString(this.tripAlert.tenantAlertRule.applyToForwarders).length > 0 ||
              this.convertString(this.tripAlert.tenantAlertRule.applyToConsignees).length > 0 ||
              this.convertString(this.tripAlert.tenantAlertRule.applyToOthers).length > 0
            );
        },
        validPartners() {
            if (!this.tripAlert?.tenantAlertRule) return false;
            
            return !!(
              (!this.tripAlert.tenantAlertRule.applyToAllNewTrips &&
                this.convertString(this.tripAlert.tenantAlertRule.applyToCarriers).length > 0) ||
              this.convertString(this.tripAlert.tenantAlertRule.applyToConsignors).length > 0 ||
              this.convertString(this.tripAlert.tenantAlertRule.applyToForwarders).length > 0 ||
              this.convertString(this.tripAlert.tenantAlertRule.applyToConsignees).length > 0 ||
              this.convertString(this.tripAlert.tenantAlertRule.applyToOthers).length > 0
            );
        },
        partnerListIds() {
            if (!this.tripAlert?.tenantAlertRule) return [];
            
            let _partners = [];
            const rule = this.tripAlert.tenantAlertRule;

            if (rule.applyToCarriers) {
              _partners = _partners.concat(this.convertString(rule.applyToCarriers));
            }
            if (rule.applyToConsignors) {
              _partners = _partners.concat(this.convertString(rule.applyToConsignors));
            }
            if (rule.applyToForwarders) {
              _partners = _partners.concat(this.convertString(rule.applyToForwarders));
            }
            if (rule.applyToConsignees) {
              _partners = _partners.concat(this.convertString(rule.applyToConsignees));
            }
            if (rule.applyToOthers) {
              _partners = _partners.concat(this.convertString(rule.applyToOthers));
            }

            return _partners;
        },
        getTransportModeIcon() {
            switch (this.tripAlert?.trip?.transportMode) {
                case 'SEA':
                    return 'directions_boat';
                case 'AIR':
                    return 'flight';
                case 'ROAD':
                    return 'local_shipping';
                case 'RAIL':
                    return 'directions_railway';
                default:
                    return 'local_shipping';
            }
        },
        getPartnerCarrier(){
            return this.tripAlert.trip?.tenantCarrier?.name;
        },
        getPartnerConsignee(){
            let consignee = this.tripAlert.trip?.tripPartnerSet?.edges
            .filter(partner => partner?.node?.tenantPartner?.partnerType === 'consignee')
            .map(partner => partner?.node?.tenantPartner?.name);
            return this.formatItemsPerLine(consignee, 3);
        },
        getPartnerConsignor(){
            let consignor = this.tripAlert.trip?.tripPartnerSet?.edges
            .filter(partner => partner?.node?.tenantPartner?.partnerType === 'consignor')
            .map(partner => partner?.node?.tenantPartner?.name);
            return this.formatItemsPerLine(consignor, 3);
        },
        getPlannedDepartureDate(){
            let localDate = this.tripAlert.trip?.plannedDepartureDate;
            if (this.tripAlert.trip?.transportMode === "SEA") {
                if (!localDate) {
                    let polNode = this.tripAlert.trip?.tripSeaTransportSet?.edges[0]?.node;
                    localDate = polNode?.originalEstimatedDepartureDate ?? polNode?.estimatedDepartureDate;
                }
            }
            return localDate ? this.formatDateTime(localDate) : '';
        },
        getPlannedArrivalDate(){
            let localDate = this.tripAlert.trip?.plannedArrivalDate;    
            if (this.tripAlert.trip?.transportMode === "SEA") {
                if (!localDate) {  
                    let podNode = this.tripAlert.trip?.tripSeaTransportSet?.edges.slice(-1)[0]?.node;
                    localDate = podNode?.originalEstimatedArrivalDate ?? podNode?.estimatedArrivalDate;
                }
            }
            return localDate ? this.formatDateTime(localDate) : '';
        },
        getOrigin(){
            let _origin = null;
            if (this.tripAlert?.trip?.originLocation) {
                if (this.tripAlert?.trip?.originLocation?.city && this.tripAlert?.trip?.originLocation?.city !== "")
                    _origin = `${this.tripAlert?.trip?.originLocation?.city}`;

                if (!_origin) _origin = `${this.tripAlert?.trip?.originLocation?.name}`;
            } else if (!this.tripAlert?.trip?.originLocation && this.tripAlert?.trip?.transportMode?.toLowerCase() === "sea") {
                _origin = this.tripAlert?.trip?.tripSeaTransportSet?.edges
                    .find(edge => edge?.node?.portTransportType == "POL")
                    ?.node?.portName?.toLowerCase();
            }

            return _origin;
        },
        getDestination(){
            let _destination = null;
            if (this.tripAlert?.trip?.destinationLocation) {
                _destination = `${this.tripAlert?.trip?.destinationLocation?.name}`;
            }else if (!this.tripAlert?.trip?.destinationLocation && this.tripAlert?.trip?.transportMode?.toLowerCase() === "sea") {
                _destination = this.tripAlert?.trip?.tripSeaTransportSet?.edges
                    .find(edge => edge?.node?.portTransportType == "POD")
                    ?.node?.portName?.toLowerCase();
            }
            return _destination;
        }
    },
    created() {
        this.updateDateTimeTimezone = helper.updateDateTimeTimezone;
        this.formatDateTime = helper.formatDateTime;
        this.relativeTime = helper.relativeTime;
        this.formatTemperature = helper.formatTemperature;
        this.getTemperatureSymbol = helper.getTemperatureSymbol;
        this.getTemperatureUnit = helper.getTemperatureSymbol;
        this.convertDateTimeTimezoneToMilliseconds = helper.convertDateTimeTimezoneToMilliseconds;
    },
    methods: {
        setStandardAlertInfo() {
            let code = this.tripAlert.tenantAlertRule.standardAlertCode;
            let containers = this.tripAlert.trip?.tripFreightSet?.edges.map(freight => {
                if (freight?.node?.containerNumber !== null || freight?.node?.containerNumber !== "")
                    return freight?.node?.containerNumber;
            });
            this.standardAlertInfo.container = containers.join(",");

            let obj = {};
            switch (code) {
                case "SEA_ETD_DELAY":
                    obj = this.tripAlert.trip?.tripSeaTransportSet?.edges[0];
                    this.standardAlertInfo.vesselName = `${obj?.node?.vessel?.name}(${obj?.node?.vesselInputValue})`;
                    this.standardAlertInfo.voyage = `${obj?.node?.voyage}`;
                    this.standardAlertInfo.date = `${this.formatDateTime(obj?.node?.estimatedDepartureDate)}`;
                    this.standardAlertInfo.port = `${obj?.node?.portName}`;
                    this.standardAlertInfo.dateType = "ETD";
                    break;
                case "SEA_ETA_DELAY":
                    obj = this.tripAlert.trip?.tripSeaTransportSet?.edges.slice(-1);
                    this.standardAlertInfo.vesselName = `${obj?.node?.vessel?.name}(${obj?.node?.vesselInputValue})`;
                    this.standardAlertInfo.voyage = `${obj?.node?.voyage}`;
                    this.standardAlertInfo.date = `${this.formatDateTime(obj?.node?.estimatedArrivalDate)}`;
                    this.standardAlertInfo.port = `${obj?.node?.portName}`;
                    this.standardAlertInfo.dateType = "ETA";
                    break;
                case "SEA_TRANS_WAIT":
                    this.tripAlert.trip?.tripSeaTransportSet?.edges.forEach(item => {
                        if (
                            item.node?.portTransportType.toLowerCase() === "t/s" &&
                            (item.node?.actualArrivalDate !== null || item.node?.actualArrivalDate !== "")
                        ) {
                            this.standardAlertInfo.vesselName = `${item?.node?.vessel?.name}(${item?.node?.vesselInputValue})`;
                            this.standardAlertInfo.voyage = `${item?.node?.voyage}`;
                            this.standardAlertInfo.date = `${this.formatDateTime(item?.node?.estimatedDepartureDate)}`;
                            this.standardAlertInfo.port = `${item.node?.portName}`;
                            this.standardAlertInfo.dateType = "ETD";
                        }
                    });
                    break;
                case "SEA_DEST_WAIT":
                    obj = this.tripAlert.trip?.tripSeaTransportSet?.edges.slice(-1);
                    this.standardAlertInfo.vesselName = `${obj?.node?.vessel?.name}(${obj?.node?.vesselInputValue})`;
                    this.standardAlertInfo.voyage = `${obj?.node?.voyage}`;
                    this.standardAlertInfo.date = `${this.formatDateTime(obj?.node?.actualArrivalDate)}`;
                    this.standardAlertInfo.port = `${obj.node?.portName}`;
                    this.standardAlertInfo.dateType = "ATA";
                    break;
                default:
                    break;
            }
        },
        sensorData(sensor) {
            let result = [];
            if (!this.trackerMergedSensorDatas?.edges) {
                return [];
            }
            this.trackerMergedSensorDatas?.edges?.forEach(device => {
                const _time = this.convertDateTimeTimezoneToMilliseconds(device.node?.deviceTime);

                if (sensor === "temperature" || sensor === "externalTemperature" || sensor === "probeTemperature") {
                    if (device.node?.[sensor] != null) {
                        let value = parseFloat(device?.node?.[sensor]).toFixed(2);
                        result.push([_time, this.formatTemperature(value)]);
                    }
                } else {
                    if (device.node?.[sensor] != null) {
                        let value = parseFloat(device?.node?.[sensor]).toFixed(2);
                        result.push([_time, value]);
                    }
                }
            });

            return result;
        },
        redirect(id) {
            let localShowPreview = this.$store.getters["user/showPreview"];

            if (this.me.isPartnerUser && !this.me.isTenantUser && localShowPreview === true) {
                this.$router.push({
                    path: "/previewsharedtrips",
                    query: { id: id, tab: "alerts" }
                });
            } else if (this.me.isPartnerUser && !this.me.isTenantUser && localShowPreview === false) {
                this.$router.push({
                    path: "/sharedtrips",
                    query: { id: id, tab: "alerts" }
                });
            } else if (localShowPreview === true) {
                this.$router.push({
                    path: "/previewtrips",
                    query: { id: id, tab: "alerts" }
                });
            } else {
                this.$router.push({
                    path: "/trips",
                    query: { id: id, tab: "alerts" }
                });
            }
        },
        redirectSumarytab(id) {
            this.$router.push({
                path: "/trips",
                query: { id: id, tab: "summary" }
            });
        },
        getTime(date) {
            return this.formatDateTime(date)?.slice(11, 16);
        },
        alertType(item) {
            let alert = [];
            let newObj = JSON.parse(item);

            if (newObj?.Location?.Condition) alert.push("location");

            if (newObj?.Sensor?.length > 0) {
                newObj.Sensor.forEach(item => {
                    alert.push(item.Type);
                });
            }

            return alert;
        },
        getStandardAlertRuleText() {
            let code = this.tripAlert.tenantAlertRule.standardAlertCode;

            let timerInHours = this.tripAlert?.alertJson
                ? JSON.parse(this.tripAlert.alertJson).RemainMinutes / 60
                : null;
            let text = null;
            switch (code) {
                case "SEA_ETD_DELAY":
                    if (timerInHours) {
                        text = `
                            ${this.$t("alert_rules.vessel_departure")} ${timerInHours} ${this.$t("general.hours")}
                        `;
                    } else {
                        text = this.$t("alert_rules.vessel_delay");
                    }
                    break;
                case "SEA_ETA_DELAY":
                    if (timerInHours) {
                        text = `${this.$t("alert_rules.vessel_arrival_delay")} ${timerInHours} ${this.$t(
                            "general.hours"
                        )}`;
                    } else {
                        text = this.$t("alert_rules.vessel_arrival");
                    }
                    break;
                case "SEA_TRANS_WAIT":
                    if (timerInHours) {
                        text = `${this.$t("alert_rules.transhipment_delay")} ${timerInHours} ${this.$t(
                            "general.hours"
                        )}`;
                    } else {
                        text = this.$t("alert_rules.transhipment_expectation");
                    }
                    break;
                case "SEA_DEST_WAIT":
                    if (timerInHours) {
                        text = `${this.$t("alert_rules.freight_delay")} ${timerInHours} ${this.$t("general.hours")}`;
                    } else {
                        text = this.$t("alert_rules.freight_delay");
                    }
                    break;
                default:
                    break;
            }

            return text;
        },
        sensorText(sensor) {
            let _choice = this.sensorTypeChoices.find(sensorTypeChoice => sensorTypeChoice.value === sensor.Type);
            let text = _choice.text + " ";

            if (sensor.Condition == "in") {
                text += this.$t("general.between") + " ";
                if (_choice.unit == "temperature") {
                    if (this.getTemperatureUnit() == "ºF")
                        text +=
                            this.celsiusToFahrenheit(sensor.Value[0]) +
                            " " +
                            this.getTemperatureUnit() +
                            " " +
                            this.$t("general.and") +
                            " " +
                            this.celsiusToFahrenheit(sensor.Value[1]) +
                            " " +
                            this.getTemperatureUnit();
                    else
                        text +=
                            sensor.Value[0] +
                            " " +
                            this.getTemperatureUnit() +
                            " " +
                            this.$t("general.and") +
                            " " +
                            sensor.Value[1] +
                            " " +
                            this.getTemperatureUnit();
                } else {
                    text +=
                        sensor.Value[0] +
                        " " +
                        _choice.unit +
                        " " +
                        this.$t("general.and") +
                        " " +
                        sensor.Value[1] +
                        " " +
                        _choice.unit;
                }
            } else if (sensor.Condition == "out") {
                text += this.$t("general.out") + " ";
                if (_choice.unit == "temperature") {
                    if (this.getTemperatureUnit() == "ºF")
                        text +=
                            this.celsiusToFahrenheit(sensor.Value[0]) +
                            " " +
                            this.getTemperatureUnit() +
                            " " +
                            this.$t("general.to") +
                            " " +
                            this.celsiusToFahrenheit(sensor.Value[1]) +
                            " " +
                            this.getTemperatureUnit();
                    else
                        text +=
                            sensor.Value[0] +
                            " " +
                            this.getTemperatureUnit() +
                            " " +
                            this.$t("general.to") +
                            " " +
                            sensor.Value[1] +
                            " " +
                            this.getTemperatureUnit();
                } else {
                    text +=
                        sensor.Value[0] +
                        " " +
                        _choice.unit +
                        " " +
                        this.$t("general.to") +
                        " " +
                        sensor.Value[1] +
                        " " +
                        _choice.unit;
                }
            } else {
                if (sensor.Condition == "gt") text += this.$t("general.above1") + " ";
                else if (sensor.Condition == "lt") text += this.$t("general.above1") + " ";
                if (_choice.unit == "temperature")
                    if (this.getTemperatureUnit() == "ºF")
                        text +=
                            this.celsiusToFahrenheit(sensor.Value[0] || sensor.Value) + " " + this.getTemperatureUnit();
                    else text += (sensor.Value[0] || sensor.Value) + " " + this.getTemperatureUnit();
                else text += (sensor.Value[0] || sensor.Value) + " " + _choice.unit;
            }
            return text;
        },
        locationText(trigger) {
            let _locations = "";
            if (trigger?.Location?.Departure) {
                _locations += "Departure";
            }
            if (trigger?.Location?.InTransit) {
                if (_locations != "") _locations += ", ";
                _locations += "In transit";
            }
            if (trigger?.Location?.Arrival) {
                if (_locations != "") _locations += ", ";
                _locations += "Arrival";
            }
            if (trigger?.Location?.Origin) {
                _locations += "Origin";
            }
            if (trigger?.Location?.Destination) {
                if (_locations != "") _locations += ", ";
                _locations += "Destination";
            }
            if (trigger?.Location?.Public) {
                if (_locations != "") _locations += ", ";
                _locations += "Public";
            }
            if (trigger?.Location?.Custom?.length > 0) {
                if (_locations != "") _locations += " and ";
                _locations +=
                    trigger.Location.Custom.length + " Custom Locations";
            }
            return _locations;
        },
        timerText(trigger) {
            if (trigger?.RemainMinutes == null || trigger?.RemainMinutes == 0) {
                return this.$t("general.immediately");
            } else if (trigger?.RemainMinutes == 60) {
                return "1 " + this.$t("date.hour");
            } else return trigger?.RemainMinutes / 60 + " " + this.$t("date.hours");
        },
        alertChoicesTitle(alert) {
            let choices = [
                { text: this.$t("general.informational"), value: "info" },
                { text: this.$t("general.warning"), value: "warning" },
                { text: this.$t("general.critical"), value: "critical" }
            ];

            if (alert !== null) {
                let choice = choices.find(
                    choice => choice.value === alert.toLowerCase()
                );
                return choice.text;
            } else {
                return "";
            }
        },
        convertString(array) {
            let _array = [];
            if (array?.length > 0 && Array.isArray(array) === false) {
                _array = JSON.parse(array.replace(/'/g, '"')).map(str => str);
            }
            return _array || [];
        },
        hasMultipleFreights() {
            return this.tripAlert?.trip?.tripFreightSet?.edges?.length  > 1;
        },
        getFirstFreightNumber() {
            const firstFreight = this.tripAlert.trip?.tripFreightSet?.edges[0]?.node;
            return firstFreight?.containerNumber || firstFreight?.airWaybillNumber || '';
        },
        getAllFreightNumbers() {
            const freightNumbers = this.tripAlert.trip?.tripFreightSet?.edges.map(freight => {
                const node = freight?.node;
                return node?.containerNumber || node?.airWaybillNumber;
            });
            let validFreightNumbers = freightNumbers.filter(num => num !== null && num !== "")
            return this.formatItemsPerLine(validFreightNumbers, 3);
        },
        hasMultipleFreightReferences() {
            return this.tripAlert?.trip?.tripFreightSet?.edges?.length > 1;
        },
        getFirstFreightReference() {
            let firstFreight = this.tripAlert.trip?.tripFreightSet?.edges[0]?.node?.referenceNumber;
            return firstFreight || '';
        },
        getAllFreightReferences() {
            let references = this.tripAlert.trip?.tripFreightSet?.edges.map(freight => {
                if (freight?.node?.referenceNumber !== null || freight?.node?.referenceNumber !== "") {
                    return freight?.node?.referenceNumber;
                }
            });
            
            // Filter out nulls and format with 3 per line
            let validRefs = references.filter(ref => ref !== null);
            return this.formatItemsPerLine(validRefs, 3);
        },
        formatItemsPerLine(items, itemsPerLine) {
            if (!items || !items.length) return '';
            
            return items
                .reduce((acc, item, i) => {
                    if (i % itemsPerLine === 0) {
                        acc.push([item]);
                    } else {
                        acc[acc.length - 1].push(item);
                    }
                    return acc;
                }, [])
                .map((line, lineIndex, allLines) => {
                    // Add comma at the end of the line if it's not the last line
                    return lineIndex < allLines.length - 1 ? 
                        line.join(', ') + ',' : 
                        line.join(', ');
                })
                .join('\n');
        },
        validArray(array) {
            if (array === null || array === undefined) {
                return false;
            } else if (Array.isArray(array) && array.length > 0) {
                return true;
            }
            return false;
        }
    },
    watch: {
        validPartners(val) {
            if (!val) {
                this.automate = {};
            }
        }
    }
};
</script>
<style scoped>
.maxWidth {
    max-width: 350px;
    min-width: 290px;
}
.maxHeight {
    max-height: 350px;
    min-height: 290px;
}
.content {
    max-width: 1000px;
    max-height: 800px;
}
.chartContainer {
    height: 280px;
}
.txtIndex {
    min-height: 19px;
    padding-bottom: 0px;
    font-size: 12.4px;
    font-weight: 400;
    color: #555464;
    white-space: normal;
    word-wrap: break-word;
    overflow-wrap: break-word;
    height: auto;
    margin-bottom: 0px;
    line-height: 15px;
}

.txtBody {
    min-height: 19px;
    padding-bottom: 5px;
    font-size: 12.3px;
    font-weight: 650;
    white-space: normal;
    word-wrap: break-word;
    overflow-wrap: break-word;
    height: auto;
    letter-spacing: 0em;
    margin-bottom: 10px;
    line-height: 15px;
}

.txtTitle {
    min-height: 19px;
    padding-bottom: 5px;
    font-size: 15px;
    font-weight: 720;
    white-space: normal;
    word-wrap: break-word;
    overflow-wrap: break-word;
    letter-spacing: 0em;
    height: auto;
    margin-bottom: 10px;
}
.textTitle {
    font-size: 20px;
    font-weight: 500;
    line-height: 22px;
    letter-spacing: 0em;
    text-align: left;
    color: #0e0d35;
}

.h-100 {
    height: 100%;
}

.fixed-height-bottom-cards {
    height: 304px;
}

.fixed-height-top-cards {
    height: 350px;
    overflow-y: auto;
}

.fixed-height-card::-webkit-scrollbar {
    width: 8px;
}

.fixed-height-card::-webkit-scrollbar-track {
    background: #f1f1f1;
    border-radius: 4px;
}

.fixed-height-card::-webkit-scrollbar-thumb {
    background: #888;
    border-radius: 4px;
}

.fixed-height-card::-webkit-scrollbar-thumb:hover {
    background: #555;
}

</style>
