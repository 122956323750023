<template>
    <v-card elevation="0" outlined v-resize="onResize" color="transparent">
        <!-- V-CARD -->
        <v-card-text class="pa-0">
            <!-- Body -->
            <v-row dense>
                <v-col cols="12" class="py-0">
                    <div v-if="partnerIsLoading && !hasData">
                        <LoadingBar></LoadingBar>
                    </div>
                    <div v-else>
                        <div v-if="hasData">
                            <v-card
                                v-for="partner in tenantPartners.edges"
                                :key="partner.node.id"
                                class="mb-4 rounded-lg px-3"
                                height=""
                                outlined
                                flat
                                @click.stop="partnerSelect(partner)"
                            >
                                <PartnerHeaderTemplate :partner="partner" :type="type"/>
                                <PartnerFooterTemplate :partner="partner"/>
                            </v-card>
                        </div>
                        <div v-else>
                            <v-row class="d-flex align-center justify-center">
                                <v-col cols="12" xs="12" sm="12" md="6" lg="5" xl="5" class="text-center">
                                    <v-img class="mt-3" :src="require('@/assets/empty_data_small.png')"></v-img>
                                    <v-card flat color="transparent">
                                        <v-card-title class="justify-center">
                                            <div class="textTitle">
                                                {{ $t("general.empty_data_title") }}
                                            </div>
                                        </v-card-title>
                                        <v-card-text class="px-0">
                                            <div class="textSubtitle">
                                                {{ $t("partners.empty_data_subtitle") }}
                                            </div>
                                        </v-card-text>
                                        <v-card-actions class="px-10 d-flex justify-center">
                                            <slot name="actionButton"></slot>
                                        </v-card-actions>
                                    </v-card>
                                </v-col>
                            </v-row>
                        </div>
                    </div>
                </v-col>
            </v-row>
        </v-card-text>
        <v-card-actions class="justify-center">
            <v-btn
                v-if="hasMoreData"
                v-intersect="onLoadMoreTriggerIntersect"
                :disabled="!hasMoreData"
                :loading="$apollo.queries.tenantPartners.loading"
                plain
                class="mb-5"
                @click="loadMore"
            >
                {{ $t("loading.load_more") }}
            </v-btn>
        </v-card-actions>
    </v-card>
</template>

<script>
import gql from "graphql-tag";
import helper from "../../utils/helper";
import LoadingBar from "@/components/base/LoadingBar.vue";
import PartnerHeaderTemplate from "@/components/partners/cardtemplates/PartnerHeaderTemplate.vue";
import PartnerFooterTemplate from "@/components/partners/cardtemplates/PartnerFooterTemplate.vue";

export default {
    name: "PartnerTable",
    props: {
        partnerFilter: {
            type: Object,
            required: false,
            default: () => {
                return {};
            }
        },
        type: {
            type: String,
            required: false,
            default: null
        }
    },
    components: {
        LoadingBar,
        PartnerHeaderTemplate,
        PartnerFooterTemplate
    },
    apollo: {
        tenantPartners: {
            query: gql`
                query tenantPartners(
                    $first: Int
                    $last: Int
                    $before: String
                    $after: String
                    $orderBy: String
                    $nameIcontains: String
                    $referenceNumberIcontains: String
                    $partnerType: String
                    $notification: Boolean
                    $carrier: String
                    $search: String
                ) {
                    tenantPartners(
                        first: $first
                        last: $last
                        before: $before
                        after: $after
                        orderBy: $orderBy
                        name_Icontains: $nameIcontains
                        referenceNumber_Icontains: $referenceNumberIcontains
                        partnerType: $partnerType
                        notificationEnabled: $notification
                        carrier_Name_Icontains: $carrier
                        search: $search
                    ) {
                        pageInfo {
                            hasNextPage
                            hasPreviousPage
                            startCursor
                            endCursor
                        }
                        edges {
                            node {
                                id
                                name
                                description
                                referenceNumber
                                openTrips
                                totalTrips
                                totalUsers
                                partnerType
                                carrier {
                                    id
                                    name
                                    carrierType
                                }
                                notificationEnabled
                                organisationPermissions
                                tenantPartnerUserSet {
                                    totalCount
                                }
                            }
                        }
                    }
                }
            `,
            variables() {
                let request = {
                    ...this.partnerFilter,
                    ...this.tableFilter
                };
                return request;
            },
            fetchPolicy: "cache-and-network",
            nextFetchPolicy: "cache-first",
            update: data => data.tenantPartners,
            watchLoading(isLoading) {
                this.partnerIsLoading = isLoading;
                this.$emit("loading", isLoading);
            },
            debounce: 800,
            skip() {
                return false;
            }
        }
    },
    data() {
        return {
            shouldLoadMore: false,
            isInitialLoad: true,
            tenantPartners: { edges: [] },
            openAddPartnerUser: false,
            tableHeight: 800,
            headerChoices: null,
            tableFilter: {
                first: 10,
                after: null,
                name_Icontains: null
            },
            filter: null,
            tab: null,
            partnerIsLoading: true
        };
    },
    computed: {
        loading() {
            return this.$apollo.queries.tenantPartners.loading;
        },
        hasData() {
            return this.tenantPartners?.edges?.length > 0;
        },
        isSmallScreen() {
            return this.$vuetify.breakpoint.width < 1320;
        },
        isCarrier() {
            return this.type?.toLowerCase() === "carrier";
        },
        hasMoreData() {
            return this.tenantPartners?.pageInfo?.hasNextPage;
        },
        screenSize() {
            let _resposiveView = this.$vuetify.breakpoint.width;
            if (_resposiveView < 650) {
                return "xs";
            } else if (_resposiveView < 1000) {
                return "sm";
            } else if (_resposiveView < 1400) {
                return "md";
            } else if (_resposiveView < 1900) {
                return "lg";
            } else {
                return "xl";
            }
        }
    },
    watch: {
        type: {
            handler(val, oldVal) {
                let _filter = { ...this.tableFilter };
                if (val !== oldVal) {
                    _filter.partnerType = val;
                    this.tableFilter = _filter;
                    this.tenantPartners = { edges: [] };
                }
            },
            immediate: true,
            deep: true
        },
        partnerFilter: {
            handler(newValue, oldValue) {
                let newValueString = JSON.parse(JSON.stringify(newValue));
                let oldValueString = JSON.parse(JSON.stringify(oldValue));
                if (newValueString !== oldValueString) {
                    this.partnerIsLoading = true;
                    this.tenantPartners = { edges: [] };
                }
            },
            deep: true
        },
        tableFilter: {
            handler(newValue, oldValue) {
                let newValueString = JSON.parse(JSON.stringify(newValue));
                let oldValueString = JSON.parse(JSON.stringify(oldValue));
                if (newValueString !== oldValueString) {
                    this.partnerIsLoading = true;
                    this.tenantPartners = { edges: [] };
                }
            },
            deep: true
        }
    },
    methods: {
        createPartner() {
            this.$emit("click:add");
        },
        partnerSelect(partner) {
            this.$emit("click:row", partner);
        },
        onResize() {
            this.$nextTick(() => {
                this.tableHeight = window.innerHeight - 145 + "px";
            });
        },
        onFilterMenuChanged(newChoices) {
            this.headerChoices = newChoices;
        },
        onLoadMoreTriggerIntersect(entries) {
            this.shouldLoadMore = entries[0].isIntersecting;

            if (this.shouldLoadMore && this.tenantPartners?.pageInfo?.hasNextPage) {
                this.loadMore();
            }
        },
        refreshQuery() {
            return this.$apollo.queries.tenantPartners.refetch();
        },
        loadMore() {
            if (this.tenantPartners?.pageInfo?.hasNextPage) {
                this.$apollo.queries.tenantPartners.fetchMore({
                    variables: {
                        after: this.tenantPartners.pageInfo.endCursor
                    }
                });
            }
        },
        statusColor(status) {
            if (status) {
                return {
                    color1: "#C8E6C9",
                    color2: "#1B5E20"
                };
            } else {
                return {
                    color1: "#FFCDD2",
                    color2: "#F44336"
                };
            }
        },
        statusText(status) {
            if (status) {
                return this.$t("headers.enabled");
            } else {
                return this.$t("headers.disabled");
            }
        }
    }
};
</script>

<style scoped lang="css">
.row-pointer >>> tbody tr :hover {
    cursor: pointer;
}
.textLeft {
    text-align: left !important;
}
.textHeader {
    font-size: 11px;
    font-weight: 400;
    line-height: 12px;
    letter-spacing: 0em;
    text-align: left;
    color: #868599;
    height: 18px;
}
.textBody {
    font-size: 12px;
    font-weight: 700;
    line-height: 14px;
    letter-spacing: 0em;
    text-align: left;
    color: #0e0d35;
    height: 18px;
}
</style>
