<template>
    <v-row dense>
        <v-col cols="12">
            <v-autocomplete
                v-model="partners"
                :loading="loading"
                :items="carrier"
                :label="label"
                @focus="openPartnersList = true"
                dense
                hide-selected
                hide-details
                clearable
                item-text="node.name"
                item-value="node.id"
                :outlined="outlined"
                :no-data-text="loading ? this.$t('loading.loading1') : this.$t('loading.no_available')"
            >
            </v-autocomplete>
        </v-col>
    </v-row>
</template>

<script>
import gql from "graphql-tag";

export default {
    name: "PartnerList",
    props: {
        label: {
            type: String,
            default: ""
        },
        value: {
            type: String,
            default: ""
        },
        refreshTable: {
            type: Boolean,
            default: false
        },
        outlined: {
            type: Boolean,
            default: false
        }
    },
    apollo: {
        tenantPartners: {
            query: gql`
                query tenantPartners(
                    $first: Int
                    $last: Int
                    $before: String
                    $after: String
                    $orderBy: String
                    $nameIcontains: String
                    $partnerType: String
                ) {
                    tenantPartners(
                        first: $first
                        last: $last
                        before: $before
                        after: $after
                        orderBy: $orderBy
                        name_Icontains: $nameIcontains
                        partnerType: $partnerType
                    ) {
                        pageInfo {
                            hasNextPage
                            hasPreviousPage
                            startCursor
                            endCursor
                        }
                        edges {
                            node {
                                id
                                name
                                partnerType
                            }
                        }
                    }
                }
            `,
            variables() {
                return {
                    orderBy: "name",
                    partnerType: "carrier"
                };
            },
            fetchPolicy: "no-cache",
            update: data => data.tenantPartners,
            result({ loading }) {
                this.loading = loading;
            },
            skip() {
                return false;
            }
        }
    },
    data() {
        return {
            partnerFilter: {
                first: 20
            },
            loading: false,
            shouldLoadMore: false,
            isInitialLoad: true,
            tenantPartners: { edges: [] },
            openPartnersList: false,
            partnerType: "",
            partnerName: ""
        };
    },
    mounted() {
        this.loading = true;
    },
    watch: {
        openPartnersList() {}
    },
    computed: {
        hasMoreData() {
            return this.tenantPartners?.pageInfo?.hasNextPage;
        },
        partners: {
            get() {
                return this.value;
            },
            set(value) {
                if (value) {
                    let list = this.tenantPartners?.edges.filter(e => e.node.id === value);
                    this.$emit("update:name", list[0].node.name);
                } else {
                    this.$emit("update:name", "");
                }
                this.$emit("input", value);
            }
        },
        carrier() {
            return this.tenantPartners?.edges.filter(e => e.node.partnerType === "carrier");
        },
        shouldSkip() {
            return !this.openPartnersList && !this.refreshTable;
        }
    },
    methods: {}
};
</script>
