<template>
    <v-row no-gutters>
        <v-col>
            <v-tabs
                v-model="tab"
                background-color="transparent"
                class="tabs-container"
                next-icon="chevron_right"
                prev-icon="chevron_left"
                show-arrows
            >
                <draggable v-model="localTabs" class="draggable-container">
                    <v-tab
                        v-for="item in localTabs"
                        :value="item.id"
                        :key="item.id"
                        class="tab-item"
                        @click.stop="$emit('onClick', true)"
                    >
                        <div class="text-capitalize tab-text">
                            {{ item.text }}
                        </div>
                    </v-tab>
                </draggable>
            </v-tabs>
           <!--  <v-divider></v-divider> -->
        </v-col>
       <!-- <v-spacer></v-spacer> -->
        <v-col 
            cols="auto" v-if="append" 
            class="pa-0 ma-0 d-flex justify-end"
            style="position: relative; z-index: 1; top:15px; left: 0px; margin-top: 6px;"
        >
            <slot name="append"></slot>
           
        </v-col>
    </v-row>
</template>

<script>
import draggable from "vuedraggable";

export default {
    name: "CustomTabs",
    components: {
        draggable
    },
    props: {
        selectedTab: {
            type: Object,
            default: () => {}
        },
        tabs: {
            type: Array,
            default: () => []
        },
        value: {
            type: String,
            default: null
        },
        draggable: {
            type: Boolean,
            default: true
        },
        append: {
            type: Boolean,
            default: false
        }
    },
    data() {
        return {
            tab: null
        };
    },
    computed: {
        localTabs: {
            get() {
                return this.tabs;
            },
            set(val) {
                // Update the local copy of the tabs
                let _localTabs = val.map(({ text, value }, index) => {
                    return {
                        id: index,
                        text,
                        value
                    };
                });
                if (this.draggable) {
                    this.$emit("updateTabs", _localTabs);
                }
            }
        }
    },
    mounted() {
        /* if(this.tabs){
            this.tab = this.tabs.findIndex(item => item.value === this.value) || 0;
            console.log(this.value); 
            console.log(this.tabs);  
        } */
    },
    watch: {
        tab(val) {
            let type = this.localTabs[val];
            this.$emit("input", type.value);
            this.$emit("update:selectedTab", type);
        },
        value(val) {
            this.tab = this.localTabs.findIndex(tab => tab.value === val);
        }
    },
    methods: {}
};
</script>

<style scoped>
.tabs-container {
    display: flex;
    flex-direction: row; /* Align tabs horizontally */
    justify-content: flex-start; /* Aligns tabs to the left */
}

.draggable-container {
    display: flex;
    flex-direction: row;
}

.tab-item {
    min-width: auto; /* Remove default min-width */
    padding: 0 8px; /* Reduce padding */
    margin: 0; /* Remove margin */
}

.tab-text {
    padding: 0;
    margin: 0;
    text-align: left; /* Align text to the left */
}
</style>
