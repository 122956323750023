import { VCard } from 'vuetify/lib/components/VCard';
import { VCol } from 'vuetify/lib/components/VGrid';
import { VIcon } from 'vuetify/lib/components/VIcon';
import { VRow } from 'vuetify/lib/components/VGrid';
import { VTooltip } from 'vuetify/lib/components/VTooltip';

var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',{staticClass:"d-flex flex-row",attrs:{"ripple":false}},[_c(VCard,{staticClass:"flex-grow-0",class:_vm.mobileView.classCard,attrs:{"width":"30","color":"transparent","flat":"","tile":""}},[[_vm._t("prepend-action")]],2),_c(VRow,{staticClass:"flex-grow-1 ma-0"},[_c(VCol,{staticClass:"py-0",attrs:{"cols":"12","md":"12"}},[_c(VRow,{staticClass:"py-1",attrs:{"dense":""}},_vm._l((_vm.items),function(item,idxc){return _c(VCol,{key:idxc,staticClass:"px-2",style:(!(_vm.screenWidth < 1295) ? 'flex-basis: 14.2%; max-width: 14.2%;' : ''),attrs:{"cols":item.col.xs || 12,"sm":item.col.sm,"md":item.col.md,"lg":item.col.lg,"xl":item.col.xl}},[_c('div',{staticClass:"text-truncate"},[_c('div',{style:(item.style)},[_c('div',{staticClass:"text-uppercase user-select-text"},[(item.value)?_c(VTooltip,{attrs:{"bottom":""},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
var on = ref.on;
var attrs = ref.attrs;
return [_c('div',_vm._g(_vm._b({},'div',attrs,false),on),[(!_vm.useIcons)?[_c('div',{staticClass:"secondaryText font-weight-thin pb-1"},[_vm._v(" "+_vm._s(item.title)+" ")]),_c('div',{staticClass:"primaryText font-weight-bold"},[_vm._v(_vm._s(item.value))])]:_c('div',{staticClass:"classText d-flex align-center"},[_c(VIcon,{staticClass:"mr-1",attrs:{"size":"12"}},[_vm._v(_vm._s(item.icon))]),(item.value)?_c('span',[_vm._v(_vm._s(item.value))]):_vm._e()],1)],2)]}}],null,true)},[(item.value)?_c('span',{staticClass:"class-text-tooltip user-select-text"},[_vm._v(_vm._s(item.value))]):_vm._e()]):_c('div',[_c('div',{staticClass:"secondaryText font-weight-thin pb-1"},[_vm._v(" "+_vm._s(item.title)+" ")]),_c('div',{staticClass:"primaryText font-weight-bold"})])],1)])])])}),1)],1)],1)],1)}
var staticRenderFns = []

export { render, staticRenderFns }