<template>
    <div>
        <v-autocomplete
            v-model="location"
            :loading="loading"
            :items="tenantLocations.edges"
            :no-data-text="$t('loading.no_available')"
            :label="label"
            item-text="node.city"
            item-value="node.id"
            :outlined="outlined"
            :clearable="clearable"
            :dense="dense"
            :multiple="multiple"
        >
            <template v-slot:item="data">
                <v-row class="ma-0 body-2" dense>
                    <v-col cols="12" class="text-truncate pl-0 ml-0 caption">
                        {{ data.item.node.name }}, {{ data.item.node.city }}
                    </v-col>
                </v-row>
            </template>
        </v-autocomplete>
    </div>
</template>
<script>
import gql from "graphql-tag";
export default {
    props: {
        value: {
            type: Array,
            default: () => []
        },
        outlined: {
            type: Boolean,
            default: false
        },
        clearable: {
            type: Boolean,
            default: false
        },
        dense: {
            type: Boolean,
            default: false
        },
        label: {
            type: String,
            default: ""
        },
        multiple: {
            type: Boolean,
            default: false
        }
    },
    apollo: {
        tenantLocations: {
            query: gql`
                query tenantLocations(
                    $search: String
                    $first: Int
                    $last: Int
                    $before: String
                    $after: String
                ) {
                    tenantLocations(
                        search: $search
                        first: $first
                        last: $last
                        before: $before
                        after: $after
                        orderBy: "name"
                        locationType_In: ["tenant_location", "partner_location", "private_location"]
                    ) {
                        pageInfo {
                            hasNextPage
                            hasPreviousPage
                            startCursor
                            endCursor
                        }
                        edges {
                            node {
                                id
                                name
                                fullAddress
                                city
                                state
                                country
                            }
                        }
                    }
                }
            `,
            variables() {},
            fetchPolicy: "cache-and-network",
            nextFetchPolicy: "cache-first",
            debounce: 800,
            update: data => {
                return data.tenantLocations;
            },
            skip: false
        }
    },
    data() {
        return {
            tenantLocations: {
                edges: []
            }
        };
    },
    computed: {
        location: {
            get() {   
                return this.value;
            },
            set(value) {
                this.$emit("input", value);
            }
        },
        loading() {
            return this.$apollo.queries.tenantLocations.loading;
        }
    }
};
</script>
<style></style>