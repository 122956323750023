import { VBtn } from 'vuetify/lib/components/VBtn';
import { VCard } from 'vuetify/lib/components/VCard';
import { VCardActions } from 'vuetify/lib/components/VCard';
import { VCardText } from 'vuetify/lib/components/VCard';
import { VChip } from 'vuetify/lib/components/VChip';
import { VCol } from 'vuetify/lib/components/VGrid';
import { VHover } from 'vuetify/lib/components/VHover';
import { VIcon } from 'vuetify/lib/components/VIcon';
import { VMenu } from 'vuetify/lib/components/VMenu';
import { VRow } from 'vuetify/lib/components/VGrid';
import { VSpacer } from 'vuetify/lib/components/VGrid';
import { VTooltip } from 'vuetify/lib/components/VTooltip';

var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c(VHover,{scopedSlots:_vm._u([{key:"default",fn:function(ref){return [_c(VCard,{staticClass:"mt-1 pl-5 pr-0 transparent",attrs:{"outlined":""}},[_c(VRow,[_c(VCol,{attrs:{"cols":"12"}},[_c(VRow,{staticClass:"d-flex align-center py-0",attrs:{"dense":"","align":"start"}},[_c(VCol,{staticClass:"py-0",attrs:{"cols":"3"}},[_c('SearchBar',{ref:"searchBar",attrs:{"label":_vm.$t('filters.search')},scopedSlots:_vm._u([{key:"prepend-inner",fn:function(){return [_c('div',[_c(VTooltip,{attrs:{"bottom":""},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
var on = ref.on;
return [_c(VIcon,_vm._g({attrs:{"color":""}},on),[_vm._v(" search ")])]}}],null,true)},[_c('span',[_vm._v(" "+_vm._s(_vm.$t("trips.searchFilter"))+" ")])])],1)]},proxy:true}],null,true),model:{value:(_vm.search),callback:function ($$v) {_vm.search=$$v},expression:"search"}})],1),_vm._l((_vm.filterValuesObj),function(i){return _c(VCol,{key:i.id,attrs:{"cols":"auto"}},[_c(VMenu,{ref:_vm.getCode(i.id),refInFor:true,attrs:{"max-width":"300px","close-on-content-click":false,"close-on-click":true,"right":"","bottom":"","nudge-top":"-6","offset-y":""},on:{"click":function($event){$event.stopPropagation();},"input":function($event){_vm.onMenuStateChange($event, _vm.getCode(i.id))}},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
var on = ref.on;
var attrs = ref.attrs;
return [(i.id != 'alertTime_Gte' 
                                                && i.id != 'alertTime_Lte' 
                                                && i.id != 'plannedDepartureDate_Gte' 
                                                && i.id != 'plannedDepartureDate_Lte' 
                                                && i.id != 'plannedArrivalDate_Gte' 
                                                && i.id != 'plannedArrivalDate_Lte')?_c(VChip,_vm._g(_vm._b({staticClass:"elevation-0 text-capitalize classText content",attrs:{"label":"","close":""},on:{"click:close":function($event){return _vm.deleteFilterValue(i.id)},"click":function($event){_vm.addCode(_vm.getCode(i.id))}}},'v-chip',attrs,false),on),[(i.id == '')?_c('span'):_vm._e(),_vm._v(" "+_vm._s(i.name + ":" + " " + i.value)+" ")]):_c(VChip,_vm._g(_vm._b({staticClass:"elevation-0 text-capitalize classText content",attrs:{"label":"","close":""},on:{"click:close":function($event){return _vm.deleteFilterValue(i.id)},"click":function($event){_vm.addCode(_vm.getCode(i.id))}}},'v-chip',attrs,false),on),[_vm._v(" "+_vm._s(i.name + ":" + " " + i.value)+" ")])]}}],null,true)},[_c(VCard,{staticClass:"pa-1",attrs:{"height":"100%"}},[_c(VCardText,{staticClass:"mt-2"},[_c('FilterMenuTemplate',{attrs:{"isReadOnly":true,"currentCode":_vm.codeSelected},on:{"search-fields":_vm.searchFields},model:{value:(_vm.menuObject),callback:function ($$v) {_vm.menuObject=$$v},expression:"menuObject"}})],1),_c(VCardActions,{staticClass:"pa-0"},[_c(VSpacer),_c(VBtn,{attrs:{"text":""},on:{"click":function($event){_vm.cancelFilter(_vm.getCode(i.id))}}},[_vm._v(" Close ")]),_c(VBtn,{attrs:{"color":"primary"},on:{"click":function($event){_vm.searchFields(_vm.menuObject, _vm.getCode(i.id))}}},[_vm._v(" Apply ")])],1)],1)],1)],1)}),_c(VCol,{attrs:{"cols":"2"}},[_c(VMenu,{ref:"addFilterMenu",attrs:{"max-width":"300px","close-on-content-click":false,"close-on-click":true,"right":"","bottom":"","nudge-top":"-6","offset-y":""},on:{"click":function($event){$event.stopPropagation();},"input":function($event){return _vm.onMenuStateChange($event, 'addFilterMenu')}},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
                                                var on = ref.on;
                                                var attrs = ref.attrs;
return [_c(VBtn,_vm._g(_vm._b({staticClass:"content",attrs:{"color":"primary"}},'v-btn',attrs,false),on),[_vm._v(" "+_vm._s(_vm.$t("general.add_filter"))+" ")])]}}],null,true)},[_c(VCard,{staticClass:"pa-1",attrs:{"height":"100%"}},[_c(VCardText,{staticClass:"mt-2"},[_c('FilterMenuTemplate',{attrs:{"isReadOnly":false,"currentCode":_vm.codeSelected},on:{"search-fields":_vm.searchFields,"update:currentCode":function($event){_vm.codeSelected=$event},"update:current-code":function($event){_vm.codeSelected=$event}},model:{value:(_vm.menuObject),callback:function ($$v) {_vm.menuObject=$$v},expression:"menuObject"}})],1),_c(VCardActions,{staticClass:"pa-0"},[_c(VSpacer),_c(VBtn,{attrs:{"text":""},on:{"click":function($event){return _vm.cancelFilter('addFilterMenu')}}},[_vm._v(" Close ")]),_c(VBtn,{attrs:{"color":"primary","disabled":_vm.codeSelected === null || _vm.codeSelected === ''},on:{"click":function($event){return _vm.searchFields(_vm.menuObject, 'addFilterMenu')}}},[_vm._v(" Apply ")])],1)],1)],1)],1)],2)],1)],1)],1)]}}])})}
var staticRenderFns = []

export { render, staticRenderFns }