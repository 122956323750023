<template>
    <v-container fluid class="py-0 px-0">
        <v-select
            v-model="selectedView"
            ref="select"
            :items="views"
            :label="title"
            :item-text="item => item.name"
            :item-value="item => item.code"
            hide-details
            outlined
            dense
            color="primary"
            :menu-props="{
                offsetY: true,
                nudgeTop: 0
            }"
            :disabled="disabled"
        >
            <template #prepend-item>
                <v-list-item dense @click="onViewSave" :disabled="isDefaultView">
                    <v-list-item-icon class="mr-2">
                        <v-icon>save</v-icon>
                    </v-list-item-icon>
                    <v-list-item-content>
                        <v-list-item-title>
                            <div class="caption">{{ $t("general.save") }} view</div>
                        </v-list-item-title>
                    </v-list-item-content>
                </v-list-item>
                <v-list-item dense @click="openDialog">
                    <v-list-item-icon class="mr-2">
                        <v-icon>{{ editSaveIcon }}</v-icon>
                    </v-list-item-icon>
                    <v-list-item-content>
                        <v-list-item-title>
                            <div class="caption">{{ $t("general.save") }} as view</div>
                        </v-list-item-title>
                    </v-list-item-content>
                </v-list-item>
                <v-list-item dense @click="editColumns">
                    <v-list-item-icon class="mr-2">
                        <v-icon>{{ editColumnIcon }}</v-icon>
                    </v-list-item-icon>
                    <v-list-item-content>
                        <v-list-item-title>
                            <div class="caption">Edit columns</div>
                        </v-list-item-title>
                    </v-list-item-content>
                </v-list-item>
                <v-divider class="append-divider"></v-divider>
            </template>
            <template v-slot:selection="{ item }">
                <div class="classText">
                    {{ item.name }}
                </div>
            </template>
            <template #item="{item}">
                <v-list-item-icon class="mr-2">
                    <v-icon>{{ item.code === "default" ? "star" : "filter_alt" }}</v-icon>
                </v-list-item-icon>
                <v-list-item-content>
                    <v-list-item-title>
                        <div class="caption">{{ item.name }}</div>
                    </v-list-item-title>
                </v-list-item-content>
                <v-list-item-action class="my-1">
                    <v-btn icon @click.stop="onDeleteView(item.code)" :disabled="item.code === 'default'">
                        <v-icon small>delete</v-icon>
                    </v-btn>
                </v-list-item-action>
            </template>
        </v-select>

        <!-- Save dialog -->
        <v-dialog v-model="dialog" persitent width="400">
            <v-card>
                <v-card-title class="primary white--text">
                    <div v-if="newViewName !== ''">
                        Edit view Manage
                    </div>
                    <div v-else>
                        View Manage
                    </div>
                </v-card-title>
                <v-card-text class="pa-4">
                    <v-text-field
                        v-model="newViewName"
                        outlined
                        dense
                        rows="5"
                        placeholder="Enter view template"
                    ></v-text-field>
                </v-card-text>
                <v-card-actions>
                    <v-spacer></v-spacer>
                    <v-btn text @click="closeDialog">{{ $t("general.cancel") }}</v-btn>
                    <v-btn color="primary" @click="onViewSave">{{ $t("general.save") }}</v-btn>
                </v-card-actions>
            </v-card>
        </v-dialog>

        <!-- Delete dialog -->
        <v-dialog v-model="deleteDialog" persistent max-width="290">
            <v-card>
                <v-card-title class="headline">
                    Delete View
                </v-card-title>
                <v-card-text>
                    Are you sure you want to delete this view?
                </v-card-text>
                <v-card-actions>
                    <v-spacer></v-spacer>
                    <v-btn text @click="onCancelDeleteView">Cancel</v-btn>
                    <v-btn color="primary" @click="deleteView">OK</v-btn>
                </v-card-actions>
            </v-card>
        </v-dialog>
    </v-container>
</template>
<script>
import { mdiContentSaveEdit, mdiPlaylistEdit  } from '@mdi/js';

export default {
    name: "PreviewManageView",
    props: {
        views: {
            type: Array,
            required: false,
            default: () => []
        },
        value: {
            type: String,
            default: null
        },
        title: {
            type: String,
            default: function() {
                return this.$t('general.view_management');
            }
        },
        disabled: {
            type: Boolean,
            default: false
        }
    },
    data() {
        return {
            newViewName: "",
            dialog: false,
            deleteDialog: false,
            defaultView: {
                name: "Default",
                code: "default",
                enabled: true,
                icon: "favorite"
            },
            //icons
            editSaveIcon: mdiContentSaveEdit,
            editColumnIcon: mdiPlaylistEdit,
            viewToDelete: null
        };
    },
    computed: {
        selectedView:{
            get(){
                return this.value;
            },
            set(value){
                let _selectedView = this.views.find(view => view.code === value);
                _selectedView.enabled = true;
                this.$emit("input-change", _selectedView);
            }
        },
        isDefaultView() {
            return this.selectedView.toLowerCase() === "default";
        },
    },
    watch: {},
    methods: {
        generateViewCode(name) {
            return name.replace(/\s+/g, "").toLowerCase();
        },
        onViewSave(){
            let newView = {
                name: this.defaultView.name,
                code: this.defaultView.code,
                enabled: true
            };

            if(this.newViewName !== "") {
                let code = this.generateViewCode(this.newViewName);
                newView.name = this.newViewName;
                newView.code = code;

                this.newViewName = "";
                //close dialog
                this.closeDialog();
            }else{
                newView.name = this.views.find(view => view.code === this.selectedView)?.name || this.defaultView.name;
                newView.code = this.selectedView;
            }
        
            this.$emit("save-view", newView);
        },
        openDialog() {
            this.dialog = true;
            /* this.currentViewTemplate = this.selectedView?.template || ""; */
        },
        closeDialog() {
            this.dialog = false;
        },
        editColumns() {
            this.$refs.select.blur();
            this.$emit("edit-columns");
        },
        onCancelDeleteView() {
            this.deleteDialog = false;
            this.viewToDelete = null;
        },
        onDeleteView(view) {
            this.viewToDelete = view;
            this.deleteDialog = true;
        },
        deleteView() {
            //remove view
            // let view = this.views.find(view => view.code === this.selectedView);
            this.$emit("delete-view", this.viewToDelete);
            this.deleteDialog = false;
            this.viewToDelete = null;
        }
    }
};
</script>
<style scoped>
.classText {
    color: #0e0d35 !important;
    font-size: 13px;
    font-weight: 500;
    line-height: 14px;
    letter-spacing: 0em;
    text-align: left;
    max-width: 100%;
}

.append {
    position: sticky;
    bottom: 4px;
    width: 100%;
    display: flex;
    justify-content: center;
    background: rgb(254, 254, 254);
}
.append-divider {
    position: sticky;
}
.align-left {
    text-align: left;
    justify-content: flex-start; /* Ensure content is aligned to the left */
}
</style>
