<template>
    <v-hover>
        <template>
            <v-card class="py-2 transparent" outlined>
                <v-row dense>
                    <v-col>
                        <v-row class="d-flex align-center py-0" dense>
                            <!-- Search bar -->
                            <v-col cols="12" xs="12" sm="12" md="3" lg="3" xl="3">
                                <div>
                                    <SearchBar v-model="search" :label="$t('filters.search')" ref="searchBar">
                                        <template v-slot:prepend-inner>
                                            <div>
                                                <v-tooltip bottom>
                                                    <template v-slot:activator="{ on }">
                                                        <v-icon v-on="on" color="">
                                                            search
                                                        </v-icon>
                                                    </template>
                                                    <span>
                                                        {{ $t("trackers.search") }}
                                                    </span>
                                                </v-tooltip>
                                            </div>
                                        </template>
                                    </SearchBar>
                                </div>
                            </v-col>
                            
                            <!-- Filters -->
                            <v-col cols="auto" v-for="i in filterValuesObj" :key="i.id">
                                <v-menu
                                    :ref="getCode(i.id)"
                                    max-width="300px"
                                    :close-on-content-click="false"
                                    :close-on-click="true"
                                    right
                                    bottom
                                    nudge-top="-6"
                                    offset-y
                                    @click.stop
                                    @input="onMenuStateChange($event, getCode(i.id))"
                                >
                                    <template v-slot:activator="{ on, attrs }">
                                        <v-chip
                                            label
                                            close
                                            class="elevation-0 text-capitalize classText content"
                                            v-on="on"
                                            v-bind="attrs"
                                            @click:close="deleteFilterValue(i.id)"
                                            @click="addCode(getCode(i.id))"
                                            v-if="i.id != 'lastConnectionTime_Gte' 
                                                    && i.id != 'lastConnectionTime_Lte' 
                                                    && i.id != 'expiryDate_Gte' 
                                                    && i.id != 'expiryDate_Lte' 
                                                    && i.id != 'activatedDate_Gte' 
                                                    && i.id != 'activatedDate_Lte'"
                                        >
                                            <span v-if="i.id == ''"></span>
                                            {{ i.name + ":" + " " + i.value }}
                                        </v-chip>
                                        <v-chip
                                            label
                                            close
                                            v-on="on"
                                            v-bind="attrs"
                                            class="elevation-0 text-capitalize classText content"
                                            @click:close="deleteFilterValue(i.id)"
                                            @click="addCode(getCode(i.id))"
                                            v-else
                                        >
                                            {{ i.name + ":" + " " + i.value }}
                                        </v-chip>
                                    </template>
                                    <v-card height="100%" class="pa-1">
                                        <v-card-text class="mt-2">
                                            <FilterMenuTemplate
                                                ref="FilterMenuTemplate"
                                                v-model="menuObject"
                                                v-on:search-fields="searchFields"
                                                :isReadOnly="true"
                                                :currentCode="codeSelected"
                                            ></FilterMenuTemplate>
                                        </v-card-text>
                                        <v-card-actions class="pa-0">
                                            <v-spacer></v-spacer>
                                            <v-btn text @click="cancelFilter(getCode(i.id))">
                                                Close
                                            </v-btn>
                                            <v-btn color="primary" @click="searchFields(menuObject, getCode(i.id))"
                                                :disabled="!hasValidMenuValue">
                                                Apply
                                            </v-btn>
                                        </v-card-actions>
                                    </v-card>
                                </v-menu>
                            </v-col>

                            <!-- Add Filter -->
                            <v-col cols="auto">
                                <v-menu
                                    ref="addFilterMenu"
                                    max-width="300px"
                                    :close-on-content-click="false"
                                    :close-on-click="true"
                                    @click.stop
                                    right
                                    bottom
                                    nudge-top="-6"
                                    offset-y
                                    @input="onMenuStateChange($event, 'addFilterMenu')"
                                >
                                    <template v-slot:activator="{ on, attrs }">
                                        <v-btn color="primary" class="content" v-on="on" v-bind="attrs">
                                            {{ $t("general.add_filter") }}
                                        </v-btn>
                                    </template>
                                    <v-card height="100%" class="pa-1">
                                        <v-card-text class="mt-2">
                                            <FilterMenuTemplate
                                                ref="FilterMenuTemplate"
                                                v-model="menuObject"
                                                v-on:search-fields="searchFields"
                                                :isReadOnly="false"
                                                :currentCode.sync="codeSelected"
                                            ></FilterMenuTemplate>
                                        </v-card-text>
                                        <v-card-actions class="pa-0">
                                            <v-spacer></v-spacer>
                                            <v-btn text @click="cancelFilter('addFilterMenu')">
                                                Close
                                            </v-btn>
                                            <v-btn
                                                color="primary"
                                                @click="searchFields(menuObject, 'addFilterMenu')"
                                                :disabled="codeSelected === null || codeSelected === '' || !hasValidMenuValue"
                                            >
                                                Apply
                                            </v-btn>
                                        </v-card-actions>
                                    </v-card>
                                </v-menu>
                            </v-col>
                        </v-row>
                    </v-col>

                    <!-- Sort by -->
                    <v-col cols="auto">
                        <v-row dense>
                            <v-col>
                                <v-select
                                    v-model="sortBy"
                                    :items="sortByChoices"
                                    :label="$t('general.sort_by')"
                                    :item-value="item => item.code"
                                    :item-text="item => item.name"
                                    clearable
                                    hide-details
                                    dense
                                    outlined
                                    color="primary"
                                    class="content-select"
                                >
                                    <template v-slot:append-outer>
                                        <v-btn class="mt-n1" small icon :disabled="!sortBy" @click="sortAsc = !sortAsc">
                                            <v-icon color="grey darken-2">{{ sortAsc ? "north" : "south" }}</v-icon>
                                        </v-btn>
                                    </template>
                                    <template #item="{item}">
                                        <span class="classText">
                                            {{ item.name }}
                                        </span>
                                    </template>
                                    <template v-slot:selection="{ item }">
                                        <div class="classText">
                                            {{ item.name }}
                                        </div>
                                    </template>
                                </v-select>
                            </v-col>
                        </v-row>
                    </v-col>
                </v-row>
            </v-card>
        </template>
    </v-hover>
</template>

<script>
import SearchBar from "@/components/base/SearchBar.vue";
import FilterMenuTemplate from "@/components/trackers/cardtemplates/FilterMenuTemplate.vue";
import helper from "@/utils/helper.js";
import moment from "moment";
import { debounce } from "lodash";
import store from "@/store";
import gql from "graphql-tag";

export default {
    components: {
        SearchBar,
        FilterMenuTemplate
    },
    props: {
    },
    data() {
        return {
            menu: false,
            filter: {
                first:25,
                orderBy: "-lastConnectionTime",
                search: null,
                serialNumber_Icontains: null,
                brand_Icontains: null,
                packageNumber_Icontains: null,
                comment_Icontains: null,
                lastConnectionTime_Gte: null,
                lastConnectionTime_Lte: null,
                expiryDate_Gte: null,
                expiryDate_Lte: null,
                activatedDate_Gte: null,
                activatedDate_Lte: null,
                tripReference: null,
                isArchived: null,
                isBeaconTracker: null,
                status: null,
            },
            codeSelected: null,
            menuObject: {},
            copyMenuObject:{},
            enabledFilters: [],
            filterValuesObj: [],
            activatedDateMenu: false,
            activatedDateRange: [],
            savedActivatedDateRange: [],
            relativeActivatedDateRange: null,
            relativeActivatedDateRangeChoices: [
                this.$t("date.last30"),
                this.$t("date.last90"),
                this.$t("date.last180"),
                this.$t("date.last360")
            ],
            lastConnectionTimeDateMenu: false,
            lastConnectionTimeRange: [],
            savedLastConnectionTimeRange: [],
            relativeLastConnectionTimeRange: null,
            relativeLastConnectionTimeRangeChoices: [
                this.$t("date.last30"),
                this.$t("date.last90"),
                this.$t("date.last180"),
                this.$t("date.last360")
            ],
            expiryDateMenu: false,
            expiryDateRange: [],
            savedExpiryDateRange: [],
            relativeExpiryDateRange: null,
            relativeExpiryDateRangeChoices: [
                this.$t("date.last30"),
                this.$t("date.last90"),
                this.$t("date.last180"),
                this.$t("date.last360")
            ],
            debounceUpdatePreferences: null,
            brandsChoices: [
                { text: "Sensitech", value: "Sensitech" },
                { text: "Tive", value: "Tive" },
                { text: "DeltaTrak", value: "DeltaTrak" },
                { text: "Copeland(Emerson)", value: "Copeland" },
                { text: "Frigga", value: "Frigga" }
            ],
            sortBy: 'lastConnectionTime',
            sortAsc: false,
            sortByChoices: [
                {
                    code: "serialNumber",
                    name: this.$t("headers.serial_number")
                },
                {
                    code: "brand",
                    name: this.$t("headers.brand")
                },
                {
                    code: "isArchived",
                    name: this.$t("headers.archived")
                },
                {
                    code: "lastConnectionTime",
                    name: this.$t("headers.last_connection")
                },
                {
                    code: "activatedDate",
                    name: "Activation Date"
                },
                {
                    code: "expiryDate",
                    name: this.$t("headers.expiry_date")
                }
            ],
        };
    },
    created() {
        // Initialize debounced function
        this.debounceUpdatePreferences = debounce(this.updatePreferences, 500);

        // Load saved filters from preferences
        this.loadSavedFilters();
    },
    computed: {
        me() {
            return helper.me();
        },
        search: {
            get() {
                let _search = this.filter.search || "";
                return _search;
            },
            set(val) {
                this.filter.search = val;
                this.updateFilter("search", this.filter);
            }
        },
        activatedDateRangeText() {
            if (this.activatedDateRange.length > 1) {
                let date1 = moment(this.activatedDateRange[0], "YYYY-MM-DD").format(helper.getDateFormat());
                let date2 = moment(this.activatedDateRange[1], "YYYY-MM-DD").format(helper.getDateFormat());
                if (date1 == date2) {
                    return date1;
                }
                return date1 + " ~ " + date2;
            } else if (this.activatedDateRange.length === 1) {
                return moment(this.activatedDateRange[0], "YYYY-MM-DD").format(helper.getDateFormat());
            } else {
                return this.activatedDateRange;
            }
        },
        lastConnectionTimeRangeText() {
            if (this.lastConnectionTimeRange.length > 1) {
                let date1 = moment(this.lastConnectionTimeRange[0], "YYYY-MM-DD").format(helper.getDateFormat());
                let date2 = moment(this.lastConnectionTimeRange[1], "YYYY-MM-DD").format(helper.getDateFormat());
                if (date1 == date2) {
                    return date1;
                }
                return date1 + " ~ " + date2;
            }
            else if (this.lastConnectionTimeRange.length === 1) {
                return moment(this.lastConnectionTimeRange[0], "YYYY-MM-DD").format(helper.getDateFormat());
            } else {
                return this.lastConnectionTimeRange;
            }
        },
        expiryDateRangeText() {
            if (this.expiryDateRange.length > 1) {
                let date1 = moment(this.expiryDateRange[0], "YYYY-MM-DD").format(helper.getDateFormat());
                let date2 = moment(this.expiryDateRange[1], "YYYY-MM-DD").format(helper.getDateFormat());
                if (date1 == date2) {
                    return date1;
                }
                return date1 + " ~ " + date2;
            }
            else if (this.expiryDateRange.length === 1) {
                return moment(this.expiryDateRange[0], "YYYY-MM-DD").format(helper.getDateFormat());
            } else {
                return this.expiryDateRange;
            }
        },
        hasValidMenuValue() {
            if (!this.codeSelected) return false;

            const key = this.getKey(this.codeSelected);
            let value = this.menuObject[key];

            // For date fields, check the FilterMenuTemplate component
            switch (this.codeSelected) {
                
                case 'lastConnectionTime':
                    value=this.$refs.FilterMenuTemplate.lastConnectionRange? this.$refs.FilterMenuTemplate.lastConnectionRange[0] : null;
                    break;  
                case 'expiryDate':
                    value=this.$refs.FilterMenuTemplate.expiryDateRange? this.$refs.FilterMenuTemplate.expiryDateRange[0] : null;
                    break;
                case 'activatedDate':
                    value=this.$refs.FilterMenuTemplate.activatedDateRange? this.$refs.FilterMenuTemplate.activatedDateRange[0] : null;
                    break;                    
                    
            }
            return value !== null && value !== undefined && value !== '';
        }
    },
    watch: {
        relativeActivatedDateRange(val) {
            const _index = this.relativeActivatedDateRangeChoices.indexOf(val);
            let _startDate = null;
            let _endDate = null;
            switch (_index) {
                case 0:
                    // last month
                    _endDate = moment().format("YYYY-MM-DD");
                    _startDate = moment()
                        .date(0)
                        .startOf("month")
                        .format("YYYY-MM-DD");
                    this.activatedDateRange = [_startDate, _endDate];
                    this.savedActivatedDateRange = [_startDate, _endDate];
                    break;
                case 1:
                    // last quarter
                    _endDate = moment().format("YYYY-MM-DD");
                    _startDate = moment()
                        .subtract(3, "months")
                        .startOf("month")
                        .format("YYYY-MM-DD");
                    this.activatedDateRange = [_startDate, _endDate];
                    this.savedActivatedDateRange = [_startDate, _endDate];
                    break;
                case 2:
                    // last half year
                    _endDate = moment().format("YYYY-MM-DD");
                    _startDate = moment()
                        .subtract(6, "months")
                        .startOf("month")
                        .format("YYYY-MM-DD");
                    this.activatedDateRange = [_startDate, _endDate];
                    this.savedActivatedDateRange = [_startDate, _endDate];
                    break;
                case 3:
                    // last 1 year
                    _endDate = moment().format("YYYY-MM-DD");
                    _startDate = moment()
                        .subtract(12, "months")
                        .startOf("month")
                        .format("YYYY-MM-DD");
                    this.alertTimeRange = [_startDate, _endDate];
                    this.savedAlertTimeRange = [_startDate, _endDate];
                    break;
                default:
                    this.alertTimeRange = [];
                    this.savedAlertTimeRange = [];
            }
        },
        savedAlertTimeRange(val) {
            if (val.length == 1) {
                this.menuObject["alertTime_Gte"] = val[0] + "T00:00:00";
                this.menuObject["alertTime_Lte"] = val[0] + "T23:59:59";
            } else if (val.length == 2) {
                this.menuObject["alertTime_Gte"] = val[0] + "T00:00:00";
                this.menuObject["alertTime_Lte"] = val[1] + "T23:59:59";
            } else {
                this.menuObject["alertTime_Gte"] = null;
                this.menuObject["alertTime_Lte"] = null;
            }
        },
        relativeLastConnectionTimeRange(val) {
            const _index = this.relativeLastConnectionTimeRangeChoices.indexOf(val);
            let _startDate = null;
            let _endDate = null;
            switch (_index) {
                case 0:
                    // last month
                    _endDate = moment().format("YYYY-MM-DD");
                    _startDate = moment()
                        .date(0)
                        .startOf("month")
                        .format("YYYY-MM-DD");
                    this.lastConnectionTimeRange = [_startDate, _endDate];
                    this.savedLastConnectionTimeRange = [_startDate, _endDate];
                    break;
                case 1: 
                    // last quarter
                    _endDate = moment().format("YYYY-MM-DD");
                    _startDate = moment()
                        .subtract(3, "months")
                        .startOf("month")
                        .format("YYYY-MM-DD");
                    this.lastConnectionTimeRange = [_startDate, _endDate];
                    this.savedLastConnectionTimeRange = [_startDate, _endDate];
                    break;
                case 2:
                    // last half year
                    _endDate = moment().format("YYYY-MM-DD");
                    _startDate = moment()
                        .subtract(6, "months")
                        .startOf("month")
                        .format("YYYY-MM-DD");
                    this.lastConnectionTimeRange = [_startDate, _endDate];
                    this.savedLastConnectionTimeRange = [_startDate, _endDate];
                    break;
                case 3:
                    // last 1 year
                    _endDate = moment().format("YYYY-MM-DD");
                    _startDate = moment()
                        .subtract(12, "months")
                        .startOf("month")
                        .format("YYYY-MM-DD");
                    this.lastConnectionTimeRange = [_startDate, _endDate];
                    this.savedLastConnectionTimeRange = [_startDate, _endDate];
                    break;
                default:
                    this.lastConnectionTimeRange = [];
                    this.savedLastConnectionTimeRange = [];
            }
        },
        savedLastConnectionTimeRange(val) {
            if (val.length == 1) {
                this.menuObject["lastConnectionTime_Gte"] = val[0] + "T00:00:00";
                this.menuObject["lastConnectionTime_Lte"] = val[0] + "T23:59:59";
            } else if (val.length == 2) {
                this.menuObject["lastConnectionTime_Gte"] = val[0] + "T00:00:00";
                this.menuObject["lastConnectionTime_Lte"] = val[1] + "T23:59:59";
            } else {
                this.menuObject["lastConnectionTime_Gte"] = null;
                this.menuObject["lastConnectionTime_Lte"] = null;
            }
        },
        relativeExpiryDateRange(val) {
            const _index = this.relativeExpiryDateRangeChoices.indexOf(val);
            let _startDate = null;
            let _endDate = null;
            switch (_index) {
                case 0:
                    // last month
                    _endDate = moment().format("YYYY-MM-DD");
                    _startDate = moment()
                        .date(0)
                        .startOf("month")
                        .format("YYYY-MM-DD");
                    this.expiryDateRange = [_startDate, _endDate];
                    this.savedExpiryDateRange = [_startDate, _endDate];
                    break;
                case 1:
                    // last quarter
                    _endDate = moment().format("YYYY-MM-DD");
                    _startDate = moment()
                        .subtract(3, "months")
                        .startOf("month")
                        .format("YYYY-MM-DD");
                    this.expiryDateRange = [_startDate, _endDate];
                    this.savedExpiryDateRange = [_startDate, _endDate];
                    break;
                case 2:
                    // last half year
                    _endDate = moment().format("YYYY-MM-DD");
                    _startDate = moment()
                        .subtract(6, "months")
                        .startOf("month")
                        .format("YYYY-MM-DD");
                    this.expiryDateRange = [_startDate, _endDate];
                    this.savedExpiryDateRange = [_startDate, _endDate];
                    break;
                case 3:
                    // last 1 year
                    _endDate = moment().format("YYYY-MM-DD");
                    _startDate = moment()
                        .subtract(12, "months")
                        .startOf("month")
                        .format("YYYY-MM-DD");
                    this.expiryDateRange = [_startDate, _endDate];
                    this.savedExpiryDateRange = [_startDate, _endDate];
                    break;
                default:
                    this.expiryDateRange = [];
                    this.savedExpiryDateRange = [];
            }
        },
        savedExpiryDateRange(val) {
            if (val.length == 1) {
                this.menuObject["expiryDate_Gte"] = val[0] + "T00:00:00";
                this.menuObject["expiryDate_Lte"] = val[0] + "T23:59:59";
            } else if (val.length == 2) {
                this.menuObject["expiryDate_Gte"] = val[0] + "T00:00:00";
                this.menuObject["expiryDate_Lte"] = val[1] + "T23:59:59";
            } else {
                this.menuObject["expiryDate_Gte"] = null;
                this.menuObject["expiryDate_Lte"] = null;
            }
        },
        sortBy(newVal) {
            if (newVal) {
                // Update filter.orderBy while preserving sort direction
                this.filter.orderBy = this.sortAsc ? newVal : `-${newVal}`;
            } else {
                // If cleared, set to default
                this.sortBy = 'lastConnectionTime';
                this.filter.orderBy = this.sortAsc ? 'lastConnectionTime' : '-lastConnectionTime';
            }
            this.updateFilter('sort', this.filter);
        },
        sortAsc(newVal) {
            const baseSort = this.sortBy || 'lastConnectionTime';
            this.filter.orderBy = newVal ? baseSort : `-${baseSort}`;
            this.updateFilter('sort', this.filter);
        },
        'filter.orderBy'(newVal) {
            if (newVal) {
                this.sortAsc = !newVal.startsWith('-');
                this.sortBy = newVal.replace(/^-/, '');
            }
        }
    },
    methods: {
        updateFilter(updateFrom, filter) {
            //trigger filter chips
            const triggerFilter = async val => {
                await this.filterValues(val);
            };

            //update query values
            this.$emit("filter-changed", JSON.parse(JSON.stringify(filter)));

            //update filter enabled
            this.$emit("filter-enabled", this.enabledFilters);

            //update store
            if (updateFrom !== "search") {
                triggerFilter(filter);
            }

            this.debounceUpdatePreferences();
        },
        onMenuStateChange(val, menuRef) {
            if (!val) {
                this.cancelFilter(this.menuReference);
                this.menuReference = null;
            } else {
                if (this.menuReference && this.menuReference !== menuRef) {
                    //Close the previous menu
                    this.cancelFilter(this.menuReference);
                }
                //Set the current menu
                this.menuReference = menuRef;

                this.$nextTick(() => {
                    if (this.$refs[this.menuReference] && this.$refs[this.menuReference][0]) {
                        this.$refs[this.menuReference][0].isActive = true;
                    } else if (this.$refs[this.menuReference]) {
                        this.$refs[this.menuReference].isActive = true;
                    }
                });
            }
        },
        cancelFilter(refMenu) {
            //clear menuObject
            this.codeSelected = null;

            //reset menuObject with previous value 
            this.menuObject = {...this.copyMenuObject};

            //clear menu filter
            if (this.$refs[refMenu]) {
                if (this.$refs[refMenu][0]) {
                    this.$refs[refMenu][0].isActive = false;
                } else {
                    this.$refs[refMenu].isActive = false;
                }
            }
        },
        async searchFields(menuItems, refMenu) {
            let menuKeys = Object.keys(menuItems);

            menuKeys.forEach(key => {
                if (menuItems[key] != undefined) {
                        this.filter[key] = menuItems[key];
                } else {
                    this.filter[key] = null;
                }
            });

            //update copy menu object
            this.copyMenuObject = { ...menuItems };

            if (this.filter.activatedDate_Gte || this.filter.activatedDate_Lte) {
                this.activatedDateRange = [
                    this.filter.activatedDate_Gte.split("T")[0],
                    this.filter.activatedDate_Lte.split("T")[0]
                ];

                // Ensure both date filters are in enabledFilters
                const gteIndex = this.enabledFilters.findIndex(f => f.code === "activatedDate_Gte");
                const lteIndex = this.enabledFilters.findIndex(f => f.code === "activatedDate_Lte");

                if (gteIndex === -1) {
                    this.enabledFilters.push({
                        name: this.replaceName("activatedDate_Gte"),
                        code: "activatedDate_Gte",
                        enabled: true
                    });
                }

                if (lteIndex === -1) {
                    this.enabledFilters.push({
                        name: this.replaceName("activatedDate_Lte"),
                        code: "activatedDate_Lte",
                        enabled: true
                    });
                }
            }
            if (this.filter.lastConnectionTime_Gte || this.filter.lastConnectionTime_Lte) {
                this.lastConnectionTimeRange = [
                    this.filter.lastConnectionTime_Gte.split("T")[0],
                    this.filter.lastConnectionTime_Lte.split("T")[0]
                ];

                // Ensure both date filters are in enabledFilters
                const gteIndex = this.enabledFilters.findIndex(f => f.code === "lastConnectionTime_Gte");
                const lteIndex = this.enabledFilters.findIndex(f => f.code === "lastConnectionTime_Lte");

                if (gteIndex === -1) {
                    this.enabledFilters.push({
                        name: this.replaceName("lastConnectionTime_Gte"),
                        code: "lastConnectionTime_Gte",
                        enabled: true
                    });
                }

                if (lteIndex === -1) {
                    this.enabledFilters.push({
                        name: this.replaceName("lastConnectionTime_Lte"),
                        code: "lastConnectionTime_Lte",
                        enabled: true
                    });
                }
            }

            if (this.filter.expiryDate_Gte || this.filter.expiryDate_Lte) {
                this.expiryDateRange = [
                    this.filter.expiryDate_Gte.split("T")[0],
                    this.filter.expiryDate_Lte.split("T")[0]
                ];

                // Ensure both date filters are in enabledFilters
                const gteIndex = this.enabledFilters.findIndex(f => f.code === "expiryDate_Gte");
                const lteIndex = this.enabledFilters.findIndex(f => f.code === "expiryDate_Lte");

                if (gteIndex === -1) {
                    this.enabledFilters.push({
                        name: this.replaceName("expiryDate_Gte"),
                        code: "expiryDate_Gte",
                        enabled: true
                    });
                }   

                if (lteIndex === -1) {
                    this.enabledFilters.push({
                        name: this.replaceName("expiryDate_Lte"),
                        code: "expiryDate_Lte",
                        enabled: true
                    });
                }
            }

            //trigger enabled filters
            this.triggerEnabledFilters();

            //update filter
            this.updateFilter("customUpdate", this.filter);

            //reset
            this.cancelFilter(refMenu);
        },
        triggerEnabledFilters() {
            if (!this.codeSelected) return;

            let codeIsFind = this.enabledFilters.find(e => e.code === this.getKey(this.codeSelected));

            if (!codeIsFind) {
                this.enabledFilters.push({
                    name: this.replaceName(this.codeSelected),
                    code: this.getKey(this.codeSelected),
                    enabled: true
                });
            }
        },
        addCode(code) {
            this.codeSelected = code;
        },
        async filterValues(object) {
            const filterKeysToIgnore = ["first", "after", "orderBy", "search"];
            const dateRangePairs = [
                {
                    start: "activatedDate_Gte",
                    end: "activatedDate_Lte",
                    getText: () => String(this.activatedDateRangeText)
                },
                {
                    start: "lastConnectionTime_Gte",
                    end: "lastConnectionTime_Lte",
                    getText: () => String(this.lastConnectionTimeRangeText)
                },
                {
                    start: "expiryDate_Gte",
                    end: "expiryDate_Lte",
                    getText: () => String(this.expiryDateRangeText)
                }
            ];
            
            // Use enabledFilters order instead of object keys
            let filteredKeys = this.enabledFilters
                .filter(filter => filter.enabled && !filterKeysToIgnore.includes(filter.code))
                .map(filter => filter.code);

            // Build initial filter values maintaining order from enabledFilters
            let values = await Promise.all(
                filteredKeys.map(async key => ({
                    id: key,
                    name: this.replaceName(key),
                    value: await this.valuePopulate(object[key], key)
                }))
            );

            // Process date range pairs while maintaining order
            dateRangePairs.forEach(({ start, end, getText }) => {
                const startIndex = values.findIndex(e => e.id === start);
                const endIndex = values.findIndex(e => e.id === end);
                
                if (startIndex !== -1 || endIndex !== -1) {
                    if (startIndex !== -1) {
                        values[startIndex].value = getText();
                    }
                    // Remove end date from values if it exists
                    if (endIndex !== -1) {
                        values.splice(endIndex, 1);
                    }
                }
            });

            this.filterValuesObj = values;
        },
        async deleteFilterValue(key) {
            if (key == "activatedDate_Gte" || key == "activatedDate_Lte") {
                // Clear both menuObject and filter values
                this.menuObject = Object.keys(this.menuObject).reduce((acc, key) => {
                    if (key !== "activatedDate_Gte" && key !== "activatedDate_Lte") {
                        acc[key] = this.menuObject[key];
                    }
                    return acc;
                }, {});

                this.menuObject = {};
                this.activatedDateRange = [];
                this.filter["activatedDate_Gte"] = null;
                this.filter["activatedDate_Lte"] = null;

                // Remove both date filters from enabledFilters
                this.enabledFilters = this.enabledFilters.filter(
                    e => e.code !== "activatedDate_Gte" && e.code !== "activatedDate_Lte"
                );
            } else if (key == "lastConnectionTime_Gte" || key == "lastConnectionTime_Lte") {
                this.menuObject = Object.keys(this.menuObject).reduce((acc, key) => {
                    if (key !== "lastConnectionTime_Gte" && key !== "lastConnectionTime_Lte") {
                        acc[key] = this.menuObject[key];
                    }
                    return acc;
                }, {});

                this.menuObject = {};
                this.lastConnectionTimeRange = [];
                this.filter["lastConnectionTime_Gte"] = null;
                this.filter["lastConnectionTime_Lte"] = null;

                // Remove both date filters from enabledFilters
                this.enabledFilters = this.enabledFilters.filter(
                    e => e.code !== "lastConnectionTime_Gte" && e.code !== "lastConnectionTime_Lte"
                );
            } else if (key == "expiryDate_Gte" || key == "expiryDate_Lte") {
                this.menuObject = Object.keys(this.menuObject).reduce((acc, key) => {
                    if (key !== "expiryDate_Gte" && key !== "expiryDate_Lte") {
                        acc[key] = this.menuObject[key];
                    }
                    return acc;
                }, {});

                this.menuObject = {};
                this.expiryDateRange = [];
                this.filter["expiryDate_Gte"] = null;
                this.filter["expiryDate_Lte"] = null;

                // Remove both date filters from enabledFilters
                this.enabledFilters = this.enabledFilters.filter(
                    e => e.code !== "expiryDate_Gte" && e.code !== "expiryDate_Lte"
                );
            } else {
                this.menuObject[key] = null;
                this.filter[key] = null;

                // Remove the filter from enabledFilters
                this.enabledFilters = this.enabledFilters.filter(e => e.code !== key);
            }

            // Update filter and trigger preference update
            this.updateFilter("delete", this.filter);
            
            // Force immediate preference update instead of debounced
            this.updatePreferences();
        },
        replaceName(key) {
            let replace = "";
            switch (key) {
                case "serialNumber_Icontains":
                    replace = this.$t("headers.serial_number");
                    break;
                case "brand_Icontains":
                    replace = this.$t("headers.brand");
                    break;
                case "packageNumber_Icontains":
                    replace = this.$t("headers.package_number");
                    break;
                case "comment_Icontains":
                    replace = this.$t("headers.comment");
                    break;
                case "lastConnectionTime_Gte":
                case "lastConnectionTime_Lte":
                    replace = this.$t("headers.last_connection");
                    break;
                case "expiryDate_Gte":
                case "expiryDate_Lte":
                    replace = this.$t("headers.expiry_date");
                    break;
                case "activatedDate_Gte":
                case "activatedDate_Lte":
                    replace = this.$t("headers.activated_date");
                    break;
                case "tripReference":
                    replace = this.$t("headers.trip_ref");
                    break;
                case "isArchived":
                    replace = this.$t("headers.archived");
                    break;
                case "isBeaconTracker":
                    replace = this.$t("headers.beacon_tracker");
                    break;
                case "search":
                    replace = this.$t("headers.search");
                    break;
                case "status":
                    replace = this.$t("headers.status");
                    break;
            }
            return replace;
        },
        getKey(code) {
            let key = "";
            switch (code) {
                case "serialNumber":
                    key = "serialNumber_Icontains";
                    break;
                case "brand":
                    key = "brand_Icontains";
                    break;
                case "packageNumber":
                    key = "packageNumber_Icontains";
                    break;
                case "comment":
                    key = "comment_Icontains";
                    break;
                case "lastConnectionTime":
                    key = "lastConnectionTime_Gte";
                    break;
                case "expiryDate":
                    key = "expiryDate_Gte";
                    break;
                case "activatedDate":
                    key = "activatedDate_Gte";
                    break;
                case "trip_referenceNumber":
                    key = "tripReference";
                    break;
                case "archived":
                    key = "isArchived";
                    break;
                case "beaconTracker":
                    key = "isBeaconTracker";
                    break;
                case "search":
                    key = "search";
                    break;
                case "status":
                    key = "status";
                    break;
            }
            return key;
        },
        getCode(key) {
            let replace = key;
            switch (key) {
                case "serialNumber_Icontains":
                    replace = "serialNumber";
                    break;
                case "brand_Icontains":
                    replace = "brand";
                    break;
                case "packageNumber_Icontains":
                    replace = "packageNumber";
                    break;
                case "comment_Icontains":
                    replace = "comment";
                    break;
                case "lastConnectionTime_Gte":
                case "lastConnectionTime_Lte":
                    replace = "lastConnectionTime";
                    break;
                case "expiryDate_Gte":
                case "expiryDate_Lte":
                    replace = "expiryDate";
                    break;
                case "activatedDate_Gte":
                case "activatedDate_Lte":
                    replace = "activatedDate";
                    break;
                case "tripReference":
                    replace = "trip_referenceNumber";
                    break;
                case "isArchived":
                    replace = "archived";
                    break;
                case "isBeaconTracker":
                    replace = "beaconTracker";
                    break;
                case "search":
                    replace = "search";
                    break;
                case "status":
                    replace = "status";
                    break;
            }
            return replace;
        },
        async valuePopulate(item, key) {
            let _value = null;
            switch (key) {
                case "serialNumber_Icontains":
                case "packageNumber_Icontains":
                case "comment_Icontains":
                case "tripReference":
                    _value = item ? item : this.$t("general.all");
                    break;
                case "isArchived":
                case "isBeaconTracker":
                    _value = item === true ? this.$t("general.yes") : item === false ? this.$t("general.no") : this.$t("general.all");
                    break;
                case "brand_Icontains":
                    _value = item ? await this.getBrandName(item) : this.$t("general.all");
                    break;
                case "activatedDate_Gte":
                case "activatedDate_Lte":
                case "lastConnectionTime_Gte":
                case "lastConnectionTime_Lte":
                case "expiryDate_Gte":
                case "expiryDate_Lte":
                    _value = item ? item.split("T")[0] : null;
                    break;
                case "status":
                    _value = item === 'active' ? this.$t("general.active") : item === 'inactive' ? this.$t("general.inactive") : this.$t("general.all");
                    break;
            }
            return _value;
        },
        async loadSavedFilters() {
            const preferences = store.state.user.me?.preferences;
            if (preferences && helper.isValidJson(preferences)) {
                const parsedPreferences = JSON.parse(preferences);
                if (parsedPreferences?.trackerTablePreference?.[0]) {
                    // Load sort preferences
                    if (parsedPreferences.trackerTablePreference[0].sortPreference) {
                        const { orderBy } = parsedPreferences.trackerTablePreference[0].sortPreference;
                        if (orderBy) {
                            // Set sortAsc based on whether orderBy starts with '-'
                            this.sortAsc = !orderBy.startsWith('-');
                            // Set sortBy removing the '-' if present
                            this.sortBy = orderBy.replace(/^-/, '');
                            this.filter.orderBy = orderBy;
                        }
                    }

                    // Load enabled filters (without values)
                    if (parsedPreferences.trackerTablePreference[0].tableEnabledFilters) {
                        this.enabledFilters = parsedPreferences.trackerTablePreference[0].tableEnabledFilters;
                    }

                    // Load filter values separately
                    if (parsedPreferences.trackerTablePreference[0].tripTableFilters) {
                        const savedFilters = parsedPreferences.trackerTablePreference[0].tripTableFilters;
                        this.filter = { ...this.filter, ...savedFilters };
                        this.menuObject = { ...this.menuObject, ...savedFilters };

                        // Restore date range if it exists
                        if (savedFilters.lastConnectionTime_Gte && savedFilters.lastConnectionTime_Lte) {
                            this.lastConnectionTimeRange = [
                                savedFilters.lastConnectionTime_Gte.split("T")[0],
                                savedFilters.lastConnectionTime_Lte.split("T")[0]
                            ];
                        }

                        if (savedFilters.expiryDate_Gte && savedFilters.expiryDate_Lte) {
                            this.expiryDateRange = [
                                savedFilters.expiryDate_Gte.split("T")[0],
                                savedFilters.expiryDate_Lte.split("T")[0]
                            ];
                        }

                        if (savedFilters.activatedDate_Gte && savedFilters.activatedDate_Lte) {
                            this.activatedDateRange = [
                                savedFilters.activatedDate_Gte.split("T")[0],
                                savedFilters.activatedDate_Lte.split("T")[0]
                            ];
                        }

                        //copy default values
                        this.copyMenuObject = {...this.menuObject}

                        // Update filter values in the UI
                        await this.filterValues(this.filter);
                        this.updateFilter("load", this.filter);
                    }
                }
            }
        },
        updatePreferences() {
            // Create a clean version of enabledFilters without values
            const cleanEnabledFilters = this.enabledFilters.map(filter => ({
                name: filter.name,
                code: filter.code,
                enabled: true
            }));

            // Create a separate object for filter values
            const filterValues = {};
            this.enabledFilters.forEach(filter => {
                if (this.filter[filter.code]) {
                    filterValues[filter.code] = this.filter[filter.code];
                }
            });

            const preferences = {
                trackerTablePreference: [
                    {
                        tableEnabledFilters: cleanEnabledFilters,
                        tripTableFilters: filterValues,
                        sortPreference: {
                            orderBy: this.filter.orderBy
                        }
                    }
                ]
            };

            helper.updateMyPreferences(preferences);
        },
        openResolveOptions() {
            this.$emit("open-resolve-options");
        },
        async getBrandName(item) {
            return this.brandsChoices.find(e => e.value === item)?.text || item;
        }
    }
};
</script>
<style scoped>
.content {
    height: 30px;
}
.content-select {
    padding: 0;
    max-width: 240px;
}
.classText {
    color: #0e0d35 !important;
    font-size: 13px;
    letter-spacing: 0em;
    text-align: left;
    white-space: nowrap; /* Prevents text from wrapping to the next line */
    overflow: hidden; /* Hides the overflowed content */
    text-overflow: ellipsis; /* Adds the ellipsis ('...') at the end of the truncated text */
    max-width: 100%;
}
</style>
