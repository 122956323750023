<template>
    <div class="" :ripple="false">
        <!-- <v-card width="30px" flat color="transparent">
            <slot name="prepend-action"></slot>
        </v-card> -->
        <v-row v-if="pregressiveTimelineData" dense class="pt-1" justify="center">
            <v-col cols="11" >
                <ProgressiveTimeline :steps="pregressiveTimelineData" :transportMode="transportMode" :enableTooltip="true" />
            </v-col>

            <!-- <v-col cols="1" class="pt-2 d-flex justify-end">
                <slot name="append-action"></slot>
            </v-col> -->
        </v-row>
        <v-row dense>
            <v-col
                ref="tripInfoCard"
                cols="12"
                xs="8"
                sm="8"
                md="10"
                lg="10"
                xl="10"
                class="py-0 pl-0"
            >
                <!-- Chips -->
                <div class="d-flex flex-row">
                    <div v-for="(item, idxc) in localFooterDetails" :key="idxc" class="textTruncate">
                        <div
                            class="classText text-uppercase"
                            @click.stop.prevent
                            style="user-select: text;"
                        >
                            <v-tooltip bottom v-if="item.valueDisplay.length > 0">
                                <template
                                    v-slot:activator="{
                                        on,
                                        attrs
                                    }"
                                >
                                    <v-chip
                                        class="ma-2"
                                        label
                                        v-bind="attrs"
                                        v-on="on"
                                        small
                                        :color="item.color"
                                        @click="captureNumber(item.id)"
                                    >
                                        <v-icon size="8" class="mr-1" left :color="item.colorIcon">
                                            circle
                                        </v-icon>
                                        {{ item.value }}
                                    </v-chip>
                                </template>
                                <span class="class-text-tooltip" @click.stop.prevent style="user-select: text;">
                                    <div v-if="item.valueDisplay">
                                        {{ item.valueDisplay }}
                                    </div>
                                </span>
                            </v-tooltip>
                            <v-chip
                                class="ma-2"
                                @click="captureNumber(item.id)"
                                label
                                small
                                :color="item.color"
                                v-else
                            >
                                {{ item.value }}
                            </v-chip>
                        </div>
                    </div>
                    <div v-if="isMdAndDown && numberOfFreight > 0" class="textTruncate">
                        <div class="classText text-uppercase">
                            <v-tooltip bottom>
                                <template v-slot:activator="{ on, attrs }">
                                    <v-chip class="ma-2" label small v-bind="attrs" v-on="on">
                                        {{ numberOfFreight }} {{ $t("general.more") }}
                                    </v-chip>
                                </template>
                                <span class="class-text-tooltip" @click.stop.prevent style="user-select: text;">
                                    {{ moreFreight }}
                                </span>
                            </v-tooltip>
                        </div>

                    </div>
                </div>
            </v-col>
            <v-col cols="12" xs="4" sm="4" md="2" class="d-flex justify-end mt-3">
                <v-card flat color="transparent">
                    <slot name="app-action-icons"></slot>
                </v-card>
            </v-col>
        </v-row>
    </div>
</template>

<script>
import ProgressiveTimeline from "./ProgressiveTripRoute.vue";

export default {
    props: {
        pregressiveTimelineData: {
            type: Array,
            required: false,
            default: () => {
                return [];
            }
        },
        footerDetails: {
            type: Array,
            required: false,
            default: () => {
                return [];
            }
        },
        chips: {
            type: Boolean,
            default: false
        },
        dense: {
            type: Boolean,
            default: false
        },
        useIcons: {
            type: Boolean,
            required: false,
            default: false
        },
        transportMode: {
            type: String,
            required: true
        }
    },
    components: {
        ProgressiveTimeline
    },
    data() {
        return {};
    },
    computed: {
        isSmAndDown() {
            return this.$vuetify.breakpoint.smAndDown;
        },
        isMdAndDown() {
            return this.$vuetify.breakpoint.mdAndDown;
        },
        localFooterDetails() {
            if (this.isSmAndDown) {
                return this.footerDetails.slice(0, 2);
            }
            if (this.isMdAndDown) {
                return this.footerDetails.slice(0, 3);
            }

            return this.footerDetails;
        },
        numberOfFreight() {
            if (this.isSmAndDown && this.footerDetails.length > 2) {
                return this.footerDetails.length - 2;
            }
            if (this.isMdAndDown && this.footerDetails.length > 3) {
                return this.footerDetails.length - 3;
            }

            return 0;
        },
        moreFreight() {
            if (this.isSmAndDown) {
                return this.footerDetails.slice(2).map((item) => item.value).join(", ");
            }
            if (this.isMdAndDown) {
                return this.footerDetails.slice(3).map((item) => item.value).join(", ");
            }

            return "";
        }
    },
    watch: {},
    mounted() {},
    methods: {
        captureNumber(value) {
            this.$emit("select-id", value);
        },
        clickBehavior() {
            this.$emit("on-click");
        }
    }
};
</script>
<style scoped lang="css">
.row-pointer >>> tbody tr :hover {
    cursor: pointer;
}
.center-loading {
    position: sticky;
    left: 50% !important;
}
.textTruncate {
    overflow: hidden;
    text-overflow: ellipsis;
    white-space: nowrap;
}
.classMainContent {
    padding-left: 4px !important;
    padding-right: 4px !important;
    margin-top: 1px !important;
    margin-bottom: 16px !important;
}
.classSecondaryContent {
    padding-left: 40px !important;
    padding-right: 40px !important;
    margin-top: 10px !important;
    margin-bottom: 10px !important;
}

.secondaryRowSize {
    width: 100% !important;
}

.classHeader {
    color: #555464 !important;
    font-size: 10px;
    font-weight: 400;
    line-height: 13px;
    letter-spacing: 0em;
    text-align: left;
    margin-top: 2px;
}

.class-text-tooltip {
    /* color: #0e0d35 !important; */
    white-space: nowrap;
    overflow: hidden;
    text-overflow: ellipsis;
    font-size: 12px;
    font-weight: 700;
    line-height: 13px;
    letter-spacing: 0em;
    text-align: left;
    margin-top: 3px;
}

.classText {
    color: #0e0d35 !important;
    font-size: 12px;
    font-weight: 500;
    line-height: 13px;
    letter-spacing: 0em;
    text-align: left;
    margin-top: 3px;
}

.classText3 {
    font-size: 12px;
    font-weight: 700;
    line-height: 11px;
    letter-spacing: 0em;
    text-align: left;
    margin-top: 1px;
}

.textTitle {
    font-size: 32px !important;
    color: "#0e1e46" !important;
    font-weight: bold;
    word-break: normal !important;
    line-height: inherit !important;
    text-align: center !important;
}
.textSubtitle {
    font-size: 13px !important;
    color: "#868599" !important;
    height: 23px !important;
    font-weight: bold;
    white-space: nowrap;
    overflow: hidden;
    text-overflow: ellipsis;
}

.outlineD {
    border: 1px solid #e6e9f5;
    border-radius: 4px;
    padding: 0px 0px;
    font-size: 12px;
    font-weight: 600;
}

.backgroundColor {
    background-color: #f9fafe !important;
}

.textLeft {
    text-align: left !important;
}

.tabItemCl {
    background-color: var(--v-background-base);
}

.disabledItem {
    pointer-events: none;
    opacity: 0.4;
}

.classHeader2 {
    color: #fff !important;
    white-space: nowrap;
    overflow: hidden;
    text-overflow: ellipsis;
    font-size: 12px;
    font-weight: 400;
    line-height: 14px;
    letter-spacing: 0em;
    text-align: left;
}

.classText2 {
    color: #fff !important;
    white-space: nowrap;
    overflow: hidden;
    text-overflow: ellipsis;
    font-size: 12px;
    font-weight: 700;
    line-height: 13px;
    letter-spacing: 0em;
    text-align: left;
    margin-top: 3px;
}
</style>
