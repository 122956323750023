import { VCard } from 'vuetify/lib/components/VCard';
import { VCardTitle } from 'vuetify/lib/components/VCard';
import { VCol } from 'vuetify/lib/components/VGrid';
import { VIcon } from 'vuetify/lib/components/VIcon';
import { VRow } from 'vuetify/lib/components/VGrid';
import { VSpacer } from 'vuetify/lib/components/VGrid';

var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c(VRow,{staticClass:"pl-5 pr-3 background d-flex",staticStyle:{"height":"100%"},attrs:{"dense":"","align":"stretch"}},[_c(VCol,{staticClass:"pt-4 d-flex",attrs:{"cols":"12"}},[_c(VCard,{staticClass:"flex-grow-1 overflow-y-auto",attrs:{"outlined":""}},[_c(VCardTitle,{staticClass:"textTitle"},[_c('div',{staticClass:"mr-2"},[_c(VIcon,[_vm._v("bar_chart")])],1),_c('div',[_vm._v(" "+_vm._s(_vm.$t("headers.charts"))+" ")]),_c(VSpacer)],1),_c('TrackerCharts',{attrs:{"tracker":_vm.tracker}})],1)],1)],1)}
var staticRenderFns = []

export { render, staticRenderFns }