import { VCard } from 'vuetify/lib/components/VCard';
import { VChip } from 'vuetify/lib/components/VChip';
import { VCol } from 'vuetify/lib/components/VGrid';
import { VIcon } from 'vuetify/lib/components/VIcon';
import { VRow } from 'vuetify/lib/components/VGrid';
import { VTooltip } from 'vuetify/lib/components/VTooltip';

var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',{attrs:{"ripple":false}},[(_vm.pregressiveTimelineData)?_c(VRow,{staticClass:"pt-1",attrs:{"dense":"","justify":"center"}},[_c(VCol,{attrs:{"cols":"11"}},[_c('ProgressiveTimeline',{attrs:{"steps":_vm.pregressiveTimelineData,"transportMode":_vm.transportMode,"enableTooltip":true}})],1)],1):_vm._e(),_c(VRow,{attrs:{"dense":""}},[_c(VCol,{ref:"tripInfoCard",staticClass:"py-0 pl-0",attrs:{"cols":"12","xs":"8","sm":"8","md":"10","lg":"10","xl":"10"}},[_c('div',{staticClass:"d-flex flex-row"},[_vm._l((_vm.localFooterDetails),function(item,idxc){return _c('div',{key:idxc,staticClass:"textTruncate"},[_c('div',{staticClass:"classText text-uppercase",staticStyle:{"user-select":"text"},on:{"click":function($event){$event.stopPropagation();$event.preventDefault();}}},[(item.valueDisplay.length > 0)?_c(VTooltip,{attrs:{"bottom":""},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
                                var on = ref.on;
                                var attrs = ref.attrs;
return [_c(VChip,_vm._g(_vm._b({staticClass:"ma-2",attrs:{"label":"","small":"","color":item.color},on:{"click":function($event){return _vm.captureNumber(item.id)}}},'v-chip',attrs,false),on),[_c(VIcon,{staticClass:"mr-1",attrs:{"size":"8","left":"","color":item.colorIcon}},[_vm._v(" circle ")]),_vm._v(" "+_vm._s(item.value)+" ")],1)]}}],null,true)},[_c('span',{staticClass:"class-text-tooltip",staticStyle:{"user-select":"text"},on:{"click":function($event){$event.stopPropagation();$event.preventDefault();}}},[(item.valueDisplay)?_c('div',[_vm._v(" "+_vm._s(item.valueDisplay)+" ")]):_vm._e()])]):_c(VChip,{staticClass:"ma-2",attrs:{"label":"","small":"","color":item.color},on:{"click":function($event){return _vm.captureNumber(item.id)}}},[_vm._v(" "+_vm._s(item.value)+" ")])],1)])}),(_vm.isMdAndDown && _vm.numberOfFreight > 0)?_c('div',{staticClass:"textTruncate"},[_c('div',{staticClass:"classText text-uppercase"},[_c(VTooltip,{attrs:{"bottom":""},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
                                var on = ref.on;
                                var attrs = ref.attrs;
return [_c(VChip,_vm._g(_vm._b({staticClass:"ma-2",attrs:{"label":"","small":""}},'v-chip',attrs,false),on),[_vm._v(" "+_vm._s(_vm.numberOfFreight)+" "+_vm._s(_vm.$t("general.more"))+" ")])]}}],null,false,1728909040)},[_c('span',{staticClass:"class-text-tooltip",staticStyle:{"user-select":"text"},on:{"click":function($event){$event.stopPropagation();$event.preventDefault();}}},[_vm._v(" "+_vm._s(_vm.moreFreight)+" ")])])],1)]):_vm._e()],2)]),_c(VCol,{staticClass:"d-flex justify-end mt-3",attrs:{"cols":"12","xs":"4","sm":"4","md":"2"}},[_c(VCard,{attrs:{"flat":"","color":"transparent"}},[_vm._t("app-action-icons")],2)],1)],1)],1)}
var staticRenderFns = []

export { render, staticRenderFns }