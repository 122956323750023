<template>
    <v-card elevation="0" v-resize="onResize" color="transparent">
        <v-card-text class="pa-0">
            <v-row dense>
                <v-col cols="12">
                    <slot name="addFilter"></slot>
                </v-col>
                <v-col cols="12" class="d-flex align-center justify-center" v-if="tripIsLoading && !hasTrips">
                    <LoadingBar></LoadingBar>
                </v-col>
                <v-col cols="12" v-else>
                    <div v-if="hasTrips">
                        <div v-for="(trip, i) in trips.edges" :key="i">
                            <v-card
                                outlined
                                @click.prevent.stop="detailsPage(trip)"
                                :ripple="false"
                                class="rounded-lg mb-2"
                            >
                                <v-row class="px-2 py-0">
                                    <v-col cols="12" class="pb-2">
                                        <TripHeaderTemplate
                                            :id="trip.node.id"
                                            :items="mainCard(trip,'header')"
                                            :headers="headersEnabled"
                                            :useIcons="useIcons"
                                        >
                                            <template #prepend-action>
                                                <v-icon
                                                    :color="transport(trip).colorIcon"
                                                    :size="transport(trip).iconSize"
                                                    :style="transport(trip).style"
                                                >
                                                    {{ transport(trip).icon }}
                                                </v-icon>
                                            </template>
                                        </TripHeaderTemplate>
                                    </v-col>
                                </v-row>
                                <div class="d-flex align-center" v-if="!monitorExpansionPanels[trip.node.id]">
                                    <v-divider class="flex-grow-1"></v-divider>
                                    <v-btn
                                        fab
                                        @click.stop="toggleDetails(trip.node.id)"
                                        class="mx-1"
                                        color="primary"
                                        height="20"
                                        width="20"
                                    >
                                        <v-icon>keyboard_arrow_down</v-icon>
                                    </v-btn>
                                </div>
                                <v-divider v-else></v-divider>
                                <v-expansion-panels v-model="panel[trip.node.id]" accordion flat tile class="my-0 ">
                                    <v-expansion-panel
                                        v-for="(panel, index) in panelDisplay"
                                        :key="index"
                                        class="transparent"
                                    >
                                        <v-expansion-panel-content
                                            v-if="monitorExpansionPanels[trip.node.id]"
                                            class="pa-0 rounded-b-0 rounded-t-0 white outlinedSide transparent"
                                        >
                                            <v-card flat outlined tile color="transparent" class="px-2 py-1">
                                                <!-- <v-card-title class="subtitle-2 pb-2">
                                                    <span>{{ $t("trips.trip_info") }}</span>
                                                </v-card-title> -->
                                                <!-- <v-divider></v-divider> -->
                                                <div class="pa-0">
                                                    <TripBodyTemplate
                                                        :headers="headersEnabled"
                                                        :items="mainCard(trip,'body')"
                                                        :enabledKeyValue="false"
                                                        :arrayIndex="0"
                                                        :headerText="$t('trips.trip_info')"
                                                    />
                                                </div>
                                            </v-card>
                                            <!-- <v-divider></v-divider> -->
                                            <v-card class="px-2" flat outlined tile color="transparent">
                                                <!-- <v-divider></v-divider> -->
                                                <div class="pa-0">
                                                    <TripBodyTemplate
                                                        :headers="headersEnabled"
                                                        :items="prepareFreightExpandInfo(trip)"
                                                        :enabledKeyValue="false"
                                                        :arrayIndex="0"
                                                        :headerText="$t('trips.freight_info')"
                                                        :useTableLayout="true"
                                                        :transportMode="trip.node.transportMode"
                                                    />
                                                </div>
                                            </v-card>
                                        </v-expansion-panel-content>
                                    </v-expansion-panel>
                                </v-expansion-panels>
                                <div class="d-flex align-center" v-if="monitorExpansionPanels[trip.node.id]">
                                    <v-divider class="flex-grow-1"></v-divider>
                                    <v-btn
                                        fab
                                        @click.stop="toggleDetails(trip.node.id)"
                                        class="mx-1"
                                        color="error"
                                        height="20"
                                        width="20"
                                    >
                                        <v-icon>keyboard_arrow_up</v-icon>
                                    </v-btn>
                                </div>
                                <v-row class="pa-2">
                                    <v-col cols="12" class="pt-0">
                                        <TripFooterTemplate
                                            :pregressiveTimelineData="getDataVisualTrip(trip)"
                                            :transportMode="trip.node.transportMode"
                                            :footerDetails="footerDetailsRow(trip)"
                                            :dense="true"
                                            v-on:select-id="onIdSelected(trip, $event)"
                                            :useIcons="useIcons"
                                        >
                                            <template slot="app-action-icons">
                                                <v-row>
                                                    <v-col
                                                        v-for="(item, idx) in actionCard(trip)"
                                                        :key="idx"
                                                        cols="auto"
                                                        class="px-2"
                                                    >
                                                        <v-badge
                                                            bordered
                                                            :color="item.badgeColor.color"
                                                            offset-x="10"
                                                            offset-y="8"
                                                            @click.native.stop="item.actions"
                                                            v-if="item.value > 0"
                                                        >
                                                            <template v-slot:badge>
                                                                <div :style="`color: ${item.badgeColor.colorText}`">
                                                                    {{ item.value }}
                                                                </div>
                                                            </template>
                                                            <v-tooltip bottom>
                                                                <template v-slot:activator="{ on, attrs }">
                                                                    <v-icon
                                                                        v-if="item.icon"
                                                                        v-bind="attrs"
                                                                        :size="item.iconSize"
                                                                        v-on="on"
                                                                        :color="item.colorIcon"
                                                                    >
                                                                        {{ item.icon }}
                                                                    </v-icon>
                                                                </template>
                                                                <span class="text">{{ item.title }}</span>
                                                            </v-tooltip>
                                                        </v-badge>
                                                        <div v-else>
                                                            <v-icon
                                                                v-if="item.icon"
                                                                :size="item.iconSize"
                                                                :color="item.colorIcon"
                                                            >
                                                                {{ item.icon }}
                                                            </v-icon>
                                                        </div>
                                                    </v-col>
                                                    <v-col cols="auto" class="pl-2 pr-1">
                                                        <v-tooltip bottom>
                                                            <template v-slot:activator="{ on, attrs }">
                                                                <v-icon
                                                                    v-bind="attrs"
                                                                    v-on="on"
                                                                    :size="18"
                                                                    @click.stop="
                                                                        favoriteBtn(
                                                                            trip.node.id,
                                                                            !trip.node.isUserFavorite
                                                                        )
                                                                    "
                                                                    :color="trip.node.isUserFavorite ? 'secondary' : ''"
                                                                >
                                                                    {{
                                                                        trip.node.isUserFavorite
                                                                            ? bookmarkFilledIcon
                                                                            : bookmarkIcon
                                                                    }}
                                                                </v-icon>
                                                            </template>
                                                            <span>{{ $t("general.favorites") }}</span>
                                                        </v-tooltip>
                                                    </v-col>
                                                    <v-col cols="auto" class="pl-1">
                                                        <v-tooltip botto>
                                                            <template v-slot:activator="{ on, attrs }">
                                                                <v-icon
                                                                    v-bind="attrs"
                                                                    v-on="on"
                                                                    :size="18"
                                                                    @click.stop="onShareTrip(trip.node.id)"
                                                                >
                                                                    share
                                                                </v-icon>
                                                            </template>
                                                            <span>{{ $t("headers.share_trip") }}</span>
                                                        </v-tooltip>
                                                    </v-col>
                                                </v-row>
                                            </template>
                                        </TripFooterTemplate>
                                    </v-col>
                                </v-row>
                            </v-card>
                        </div>
                    </div>
                    <!-- No trips content -->
                    <div v-else>
                        <v-row class="d-flex align-center justify-center">
                            <v-col cols="4" class="text-center">
                                <v-img class="mt-3" :src="require('@/assets/empty_data_small.png')" />
                                <v-card flat color="transparent">
                                    <v-card-title>
                                        <div class="textTitle">
                                            {{
                                                tab === "trips"
                                                    ? $t("general.empty_data_title")
                                                    : $t("general.empty_data_title_favorite")
                                            }}
                                        </div>
                                    </v-card-title>
                                    <v-card-text class="px-0">
                                        <div class="textSubtitle" v-if="tab === 'trips'">
                                            {{ $t("general.empty_data_subtitle") }}
                                        </div>
                                        <div v-else>
                                            <div class="textSubtitle">
                                                {{ $t("general.step") }} 1:
                                                {{ $t("general.empty_data_subtitle_favorite_1") }}
                                                <v-icon>more_vert</v-icon>
                                            </div>
                                            <div class="textSubtitle">
                                                {{ $t("general.step") }} 2:
                                                {{ $t("general.empty_data_subtitle_favorite_2") }}
                                                <v-icon>star_border</v-icon>
                                                {{ $t("general.favorites") }}
                                            </div>
                                        </div>
                                    </v-card-text>
                                    <v-card-actions class="px-10" v-if="!isSharedTrip">
                                        <v-btn color="primary" @click="createTrip()" block>
                                            {{ $t("trips.create_trip_2") }}
                                        </v-btn>
                                    </v-card-actions>
                                </v-card>
                            </v-col>
                        </v-row>
                    </div>
                </v-col>
            </v-row>
        </v-card-text>
        <!-- Load more button -->
        <v-card-actions class="justify-center mb-5">
            <v-btn
                v-if="hasMoreData"
                v-intersect="onLoadMoreTriggerIntersect"
                :disabled="!hasMoreData"
                :loading="$apollo.queries.trips.loading"
                plain
                class="mb-5"
                @click="loadMore"
            >
                Load more
            </v-btn>
        </v-card-actions>
        <!--  Freight Dialog -->
        <FreightInfoDialog
            v-if="tripObject !== null && freightDialog"
            v-model="freightDialog"
            :tripProp="tripObject"
            :currentFreight="freightObj"
            :isSharedTrip="isSharedTrip"
            v-on:more-details="detailsPage"
        >
        </FreightInfoDialog>
    </v-card>
</template>

<script>
import gql from "graphql-tag";
import helper from "../../utils/helper";
import LoadingBar from "@/components/base/LoadingBar.vue";
import TripHeaderTemplate from "@/components/trips/cardtemplates/TripHeaderTemplate.vue";
import TripFooterTemplate from "@/components/trips/cardtemplates/TripFooterTemplate.vue";
import TripBodyTemplate from "@/components/trips/cardtemplates/TripBodyTemplate.vue";
import FreightInfoDialog from "@/components/trips/other/FreightInfoDialog.vue";
import {
    mdiTransitConnectionHorizontal,
    mdiBookmarkOutline,
    mdiPaperclip,
    mdiBookmark,
    mdiChevronUpCircleOutline,
    mdiChevronDownCircleOutline
} from "@mdi/js";

const GET_TRIPS = gql`
    query trips(
        $first: Int
        $last: Int
        $before: String
        $after: String
        $referenceNumber_Icontains: String
        $tripFreightSet_ContainerNumber_Icontains: String
        $tripFreightSet_AirWaybillNumber_Icontains: String
        $tripFreightSet_OrderNumber_Icontains: String
        $tripFreightSet_Contact_Icontains: String
        $tenantPartnerId: ID
        $status_In: [String]
        $plannedDepartureDate_Gte: DateTime
        $plannedDepartureDate_Lte: DateTime
        $plannedArrivalDate_Gte: DateTime
        $plannedArrivalDate_Lte: DateTime
        $orderBy: String
        $pol: String
        $transshipment: String
        $transportMode_In: [String]
        $pod: String
        $alertCode: [String]
        $tripAlertSetIsResolved: Boolean
        $tenantCarrier: ID
        $consignor: [String]
        $consignee: [String]
        $forwarder: [String]
        $goods: String
        $isFavorite: Boolean
        $tripFreightSet_ReferenceNumber_Icontains: String
        $polvessel: ID
        $tsvessel: ID
        $trackerLastLocation_Icontains: String
        $search: String
        $trackerSerialNumber: String
        $alertTag: String
        $trade_Icontains: String
        $awbNumber: String
        $containerNumber: String
        $trackerNumber: String
        $blNumber: String
        $orderNumber: String
        $tenantAlertRuleId: [ID],
        $eventSource: String
        $eventSourceType: String
        $origin: [ID]
        $destination: [ID]
    ) {
        trips(
            first: $first
            last: $last
            before: $before
            after: $after
            referenceNumber_Icontains: $referenceNumber_Icontains
            tripFreightSet_ContainerNumber_Icontains: $tripFreightSet_ContainerNumber_Icontains
            tripFreightSet_AirWaybillNumber_Icontains: $tripFreightSet_AirWaybillNumber_Icontains
            tripFreightSet_OrderNumber_Icontains: $tripFreightSet_OrderNumber_Icontains
            tripFreightSet_Contact_Icontains: $tripFreightSet_Contact_Icontains
            tripPartnerSet_TenantPartner: $tenantPartnerId
            status_In: $status_In
            plannedDepartureDate_Gte: $plannedDepartureDate_Gte
            plannedDepartureDate_Lte: $plannedDepartureDate_Lte
            plannedArrivalDate_Gte: $plannedArrivalDate_Gte
            plannedArrivalDate_Lte: $plannedArrivalDate_Lte
            orderBy: $orderBy
            pol: $pol
            transshipment: $transshipment
            transportMode_In: $transportMode_In
            alertCode: $alertCode
            tripAlertSet_IsResolved: $tripAlertSetIsResolved
            pod: $pod
            tenantCarrier: $tenantCarrier
            consignor: $consignor
            consignee: $consignee
            forwarder: $forwarder
            goods: $goods
            tripFreightSet_ReferenceNumber_Icontains: $tripFreightSet_ReferenceNumber_Icontains
            isFavorite: $isFavorite
            polvessel: $polvessel
            tsvessel: $tsvessel
            trackerLastLocation_Icontains: $trackerLastLocation_Icontains
            search: $search
            trackerSerialNumber: $trackerSerialNumber
            alertTag: $alertTag
            trade_Icontains: $trade_Icontains
            awbNumber: $awbNumber
            containerNumber: $containerNumber
            trackerNumber: $trackerNumber
            blNumber: $blNumber
            orderNumber: $orderNumber
            tripAlertSet_TenantAlertRule_In: $tenantAlertRuleId
            origin: $origin
            destination: $destination
        ) {
            pageInfo {
                startCursor
                endCursor
                hasPreviousPage
                hasNextPage
            }
            edges {
                node {
                    id
                    createdAt
                    tenant {
                        id
                    }
                    tenantCarrier {
                        id
                        name
                    }
                    originLocation {
                        id
                        name
                        city
                    }
                    destinationLocation {
                        id
                        name
                        city
                    }
                    referenceNumber
                    transportMode
                    status
                    plannedDepartureDate
                    plannedArrivalDate
                    numberOfFreights
                    numberOfUnresolvedAlerts
                    numberOfDocuments
                    trackerLastConnectionTime
                    trackerLastLocation
                    trackerLastTemperatureValue
                    tripAlertSet {
                        edges {
                            node {
                                id
                                resolvedTime
                                isResolved
                                tripFreight {
                                    id
                                    referenceNumber
                                }
                            }
                        }
                    }
                    tripPartnerSet {
                        edges {
                            node {
                                id
                                tenantPartner {
                                    id
                                    name
                                    partnerType
                                }
                            }
                        }
                    }
                    tripFreightSet {
                        edges {
                            node {
                                id
                                referenceNumber
                                orderNumber
                                containerNumber
                                airWaybillNumber
                                billOfLadingNumber
                                goods
                                additionalInfo
                                freightHasAlert
                                tripFreightTrackerSet {
                                    edges {
                                        node {
                                            id
                                            tracker {
                                                id
                                                serialNumber
                                                activatedDate
                                                lastConnectionTime
                                            }
                                        }
                                    }
                                }
                            }
                        }
                    }
                    tripSeaTransportSet(orderBy: "sequence") {
                        edges {
                            node {
                                id
                                portCode
                                portTransportType
                                portName
                                vessel {
                                    id
                                    name
                                }
                                voyage
                                estimatedArrivalDate
                                originalEstimatedArrivalDate
                                actualArrivalDate
                                originalEstimatedDepartureDate
                                estimatedDepartureDate
                                actualDepartureDate
                                vgmCutOffDate
                                cutOffDateTime
                            }
                        }
                    }
                    isUserFavorite
                    tripRoadTransportSet(eventSourceType: $eventSourceType) {
                        edges {
                            node {
                            id
                            eventType
                            eventTime
                            eventName
                            eventSource
                            tripFreightTracker {
                                    id
                                }
                            }
                        }
                    }
                    tripAirTransportSet(eventSource: $eventSource) {
                        edges {
                            node {
                            id
                            eventType
                            eventTime
                            eventName
                            eventSource
                            tripFreightTracker {
                                    id
                                }
                            }
                        }
                    }
                }
            }
        }
    }
`;

const GET_SHARED_TRIPS = gql`
    query trips(
        $first: Int
        $last: Int
        $before: String
        $after: String
        $referenceNumber_Icontains: String
        $tripFreightSet_ContainerNumber_Icontains: String
        $tripFreightSet_AirWaybillNumber_Icontains: String
        $tripFreightSet_OrderNumber_Icontains: String
        $tripFreightSet_Contact_Icontains: String
        $tenantPartnerId: ID
        $status_In: [String]
        $plannedDepartureDate_Gte: DateTime
        $plannedDepartureDate_Lte: DateTime
        $plannedArrivalDate_Gte: DateTime
        $plannedArrivalDate_Lte: DateTime
        $orderBy: String
        $pol: String
        $transshipment: String
        $transportMode_In: [String]
        $pod: String
        $alertCode: [String]
        $tripAlertSetIsResolved: Boolean
        $tenantCarrier: ID
        $consignor: [String]
        $consignee: [String]
        $forwarder: [String]
        $goods: String
        $isFavorite: Boolean
        $tripFreightSet_ReferenceNumber_Icontains: String
        $polvessel: ID
        $tsvessel: ID
        $trackerLastLocation_Icontains: String
        $tenant: String
        $sharedtrips: String
        $userFavoriteTripSetIdIsnull: Boolean
        $trackerSerialNumber: String
        $alertTag: String
        $trade_Icontains: String
        $awbNumber: String
        $containerNumber: String
        $trackerNumber: String
        $blNumber: String
        $orderNumber: String
        $search: String
        $tenantAlertRuleId: [ID],
        $eventSource: String
        $eventSourceType: String
    ) {
        trips(
            first: $first
            last: $last
            before: $before
            after: $after
            referenceNumber_Icontains: $referenceNumber_Icontains
            tripFreightSet_ContainerNumber_Icontains: $tripFreightSet_ContainerNumber_Icontains
            tripFreightSet_AirWaybillNumber_Icontains: $tripFreightSet_AirWaybillNumber_Icontains
            tripFreightSet_OrderNumber_Icontains: $tripFreightSet_OrderNumber_Icontains
            tripFreightSet_Contact_Icontains: $tripFreightSet_Contact_Icontains
            tripPartnerSet_TenantPartner: $tenantPartnerId
            status_In: $status_In
            plannedDepartureDate_Gte: $plannedDepartureDate_Gte
            plannedDepartureDate_Lte: $plannedDepartureDate_Lte
            plannedArrivalDate_Gte: $plannedArrivalDate_Gte
            plannedArrivalDate_Lte: $plannedArrivalDate_Lte
            orderBy: $orderBy
            pol: $pol
            transshipment: $transshipment
            transportMode_In: $transportMode_In
            alertCode: $alertCode
            tripAlertSet_IsResolved: $tripAlertSetIsResolved
            pod: $pod
            tenantCarrier: $tenantCarrier
            consignor: $consignor
            consignee: $consignee
            forwarder: $forwarder
            goods: $goods
            tripFreightSet_ReferenceNumber_Icontains: $tripFreightSet_ReferenceNumber_Icontains
            isFavorite: $isFavorite
            polvessel: $polvessel
            tsvessel: $tsvessel
            trackerLastLocation_Icontains: $trackerLastLocation_Icontains
            tenant_Name_Icontains: $tenant
            sharedtrips: $sharedtrips
            userFavoriteTripSet_Id_Isnull: $userFavoriteTripSetIdIsnull
            trackerSerialNumber: $trackerSerialNumber
            alertTag: $alertTag
            trade_Icontains: $trade_Icontains
            awbNumber: $awbNumber
            containerNumber: $containerNumber
            trackerNumber: $trackerNumber
            blNumber: $blNumber
            orderNumber: $orderNumber
            search: $search
            tripAlertSet_TenantAlertRule_In: $tenantAlertRuleId
        ) {
            pageInfo {
                startCursor
                endCursor
                hasPreviousPage
                hasNextPage
            }
            edges {
                node {
                    id
                    createdAt
                    tenant {
                        id
                        name
                    }
                    tenantCarrier {
                        id
                        name
                    }
                    modifiedAt
                    originLocation {
                        id
                        name
                        city
                    }
                    destinationLocation {
                        id
                        name
                        city
                    }
                    referenceNumber
                    transportMode
                    status
                    plannedDepartureDate
                    numberOfUnresolvedAlerts
                    numberOfDocuments
                    trackerLastLocation
                    trackerLastConnectionTime
                    trackerLastTemperatureValue
                    tripPartnerSet {
                        edges {
                            node {
                                id
                                tenantPartner {
                                    id
                                    name
                                    partnerType
                                }
                            }
                        }
                    }
                    tripFreightSet {
                        edges {
                            node {
                                id
                                referenceNumber
                                containerNumber
                                airWaybillNumber
                                billOfLadingNumber
                                goods
                                additionalInfo
                                freightHasAlert
                                tripFreightTrackerSet {
                                    edges {
                                        node {
                                            id
                                            tracker {
                                                id
                                                serialNumber
                                                activatedDate
                                                lastConnectionTime
                                            }
                                        }
                                    }
                                }
                            }
                        }
                    }
                    tripSeaTransportSet(orderBy: "sequence") {
                        edges {
                            node {
                                id
                                portCode
                                portTransportType
                                vesselInputType
                                vesselInputValue
                                estimatedArrivalDate
                                originalEstimatedArrivalDate
                                actualArrivalDate
                                estimatedDepartureDate
                                originalEstimatedDepartureDate
                                actualDepartureDate
                                vessel {
                                    id
                                    name
                                }
                            }
                        }
                    }
                    isUserFavorite
                    tripRoadTransportSet(eventSourceType: $eventSourceType) {
                        edges {
                            node {
                            id
                            eventType
                            eventTime
                            eventName
                            eventSource
                            tripFreightTracker {
                                    id
                                }
                            }
                        }
                    }
                    tripAirTransportSet(eventSource: $eventSource) {
                        edges {
                            node {
                            id
                            eventType
                            eventTime
                            eventName
                            eventSource
                            tripFreightTracker {
                                    id
                                }
                            }
                        }
                    }
                }
            }
        }
    }
`;

export default {
    props: {
        tripFilter: {
            type: Object,
            required: false,
            default: () => {
                return {};
            }
        },
        isSharedTrip: {
            type: Boolean,
            required: false,
            default: false
        },
        tab: {
            type: String,
            required: false,
            default: "trips"
        },
        headersEnabled: {
            type: Array,
            required: false,
            default: () => {
                return [];
            }
        },
        filterSelected: {
            type: String,
            default: null
        },
        useIcons: {
            type: Boolean,
            required: false,
            default: false
        }
    },
    components: {
        LoadingBar,
        //SortBy,
        TripHeaderTemplate,
        TripFooterTemplate,
        TripBodyTemplate,
        FreightInfoDialog
    },
    apollo: {
        trips: {
            query() {
                return this.isSharedTrip ? GET_SHARED_TRIPS : GET_TRIPS;
            },
            variables() {
                // IMPORTANT: add same variables as refetch and fetchMore, otherwise merge result may contain duplicate keys
                let request = {
                    ...this.tripFilter,
                    eventSource: "flight_detect",
                    eventSourceType: "city_location"
                };

                if (this.isSharedTrip) {
                    request = {
                        ...request,
                        sharedtrips: "sharedtrips",
                        userId: this.me.id
                    };
                }
                if (request?.tripFreightSet_AirWaybillNumber_Icontains) {
                    request.tripFreightSet_AirWaybillNumber_Icontains = this.removeHifenFromAirwaybill(
                        request.tripFreightSet_AirWaybillNumber_Icontains
                    );
                }

                if (this.tab === "favorite") {
                    request.isFavorite = true;
                } else {
                    request.isFavorite = null;
                }

                return request;
            },
            // Additional options here
            fetchPolicy: "cache-and-network",
            nextFetchPolicy: "cache-first", // this setting can avoid query again after fetchMore
            update: data => data.trips,
            watchLoading(isLoading) {
                this.tripIsLoading = isLoading;
                this.$emit("loading", isLoading);
            },
            debounce: 800
        }
    },
    beforeCreate() {
        this.formatAirwaybillNumber = helper.formatAirwaybillNumber;
        this.removeHifenFromAirwaybill = helper.removeHifenFromAirwaybill;
    },
    created() {
        this.updateDateTimeTimezone = helper.updateDateTimeTimezone;
        this.getTemperatureSymbol = helper.getTemperatureSymbol;
        this.calculateDelaysInDay = helper.calculateDelaysInDay;
        this.formatDateTime = helper.formatDateTime;
        this.formatTemperature = helper.formatTemperature;
    },
    data() {
        return {
            tableFilter: {
                first: 10,
                status_In: "active",
                orderBy: "-createdAt",
                isFavorite: null,
                eventSource: "flight_detect"
            },
            loadHeaders: false,
            infiniteDot: "",
            showAddNewTripPage: false,
            shouldLoadMore: false,
            isInitialLoad: true,
            trips: { edges: [] },
            tripIsLoading: true,
            tableHeight: 800,
            tableHeaderChoices: [],
            tableHeaderFilter: {
                referenceNumber: true,
                status: true,
                containers: true,
                alerts: true
            },
            storeFilter: {},
            sortBy: null,
            sortAsc: true,
            menu: false,
            panel: {},
            monitorExpansionPanels: {},
            freightObj: null,
            tripObject: null,
            freightDialog: false,
            panelDisplay: [
                {
                    title: "Content",
                    content: "TripInfo"
                },
                {
                    title: "Footer",
                    content: "Action"
                }
            ],
            transitIcon: mdiTransitConnectionHorizontal,
            bookmarkIcon: mdiBookmarkOutline,
            bookmarkFilledIcon: mdiBookmark,
            attached: mdiPaperclip,
            arrowUp: mdiChevronUpCircleOutline,
            arrowDown: mdiChevronDownCircleOutline
        };
    },
    computed: {
        hasTrips() {
            return this.trips.edges.length > 0;
        },
        layoutTransitionLgToMd() {
            return this.$vuetify.breakpoint.width <= 1100;
        },
        layoutTransitionMdToSm() {
            return this.$vuetify.breakpoint.width <= 960;
        },
        hideToMobileScreen() {
            return !(this.$vuetify.breakpoint.width < 600);
        },
        screenWidth() {
            let _resposiveView = this.$vuetify.breakpoint.width;
            return _resposiveView;
        },
        smallScreen() {
            let screen = this.$vuetify.breakpoint.width;
            return screen < 1170;
        },
        me() {
            return helper.me();
        },
        domIsAlreadyRendered() {
            return (
                this.$refs.tripInfoCard !== undefined &&
                this.$refs.tripInfoCard !== null &&
                this.$refs.freightInfo !== undefined &&
                this.$refs.freightInfo !== null
            );
        },
        sortByChoices: function() {
            let _sortByChoices = [
                {
                    code: "referenceNumber",
                    name: this.$t("headers.reference_number")
                },
                {
                    code: "status",
                    name: this.$t("headers.status")
                },
                {
                    code: "createdAt",
                    name: this.$t("headers.created_at")
                },
                {
                    code: "modifiedAt",
                    name: this.$t("headers.modified_at")
                },
                {
                    code: "plannedDepartureDate",
                    name: this.$t("headers.planned_departure")
                },
                {
                    code: "consignor",
                    name: this.$t("headers.consignor")
                },
                {
                    code: "consignee",
                    name: this.$t("headers.consignee")
                },
                {
                    code: "forwarder",
                    name: this.$t("headers.forwarder")
                },
                {
                    code: "tenant_carrier",
                    name: this.$t("headers.carrier")
                }
            ];
            return _sortByChoices;
        },
        centralizeLoadBtnClass() {
            let _centralizeLoadBtnClass = {
                position: "sticky",
                left: "50%"
            };
            return _centralizeLoadBtnClass;
        },
        hasMoreData() {
            return this.trips?.pageInfo?.hasNextPage;
        },
        isTripsRoute() {
            let route = this.$route;
            return route.name == "trips" && route?.query?.id !== null;
        },
        switchTableHeaders() {
            return this.isSharedTrip ? "sharedTripTableHeaders" : "tripTableHeaders";
        },
        mobileView() {
            let isSmallScreen = this.$vuetify.breakpoint.width < 960;
            let mobilePhone = this.$vuetify.breakpoint.width < 700;
            return {
                widthCard: isSmallScreen ? (mobilePhone ? "90px" : "60px") : "30px",
                classCard: isSmallScreen ? "mr-2 mt-2" : "d-flex align-center mr-1",
                headerRow: !isSmallScreen ? "d-flex justify-center" : "pl-1",
                headerCol: isSmallScreen ? "py-1" : "pa-0 mt-2 d-flex align-center"
            };
        }
    },
    watch: {
        // filterSelected: {
        //     handler(newValue, oldValue) {
        //         if (newValue !== oldValue) {
        //             this.tripIsLoading = true;
        //             this.trips = { edges: [] };
        //             this.$apollo.queries.trips.refetch();
        //         }
        //     }
        // },
        tab: {
            handler() {
                this.tripIsLoading = true;
                this.trips = { edges: [] };
            }
        },
        tripFilter: {
            handler(newValue, oldValue) {
                if (JSON.stringify(newValue) !== JSON.stringify(oldValue)) {
                    this.tripIsLoading = true;
                    this.trips = { edges: [] };
                    // this.$apollo.queries.trips.refetch();
                }
            }
        }
    },
    mounted() {},
    methods: {
        copyToClipboard(text) {
            let dummy = document.createElement("textarea");
            document.body.appendChild(dummy);
            dummy.value = text;
            dummy.select();
            document.execCommand("copy");
            document.body.removeChild(dummy);

            const payload = {
                color: "primary",
                message: this.$t("general.copied")
            };
            this.$store.dispatch("snackbar/showMessage", payload);
        },
        toggleDetails(id) {
            // Toggles the panel state between 0 (closed) and 1 (open)
            this.$set(this.panel, id, this.panel[id] === 0 ? 1 : 0);
            this.monitorExpansionPanels[id] = this.panel[id] === 1 ? false : true;
        },
        transport(item) {
            const { status, transportMode } = item.node;

            const iconRotate = mode => {
                return mode && mode === "AIR" ? "transform: rotate(45deg);" : "";
            };
            const statusColor = status => {
                return status === "ACTIVE" ? "#4CAF50" : "";
            };

            return {
                title: null,
                tag: "icon",
                icon: this.getIcon(transportMode),
                iconSize: 20,
                colorIcon: statusColor(status),
                style: iconRotate(transportMode),
                align: "left",
                value: null,
                col: {
                    xs: "auto",
                    sm: "auto",
                    md: "auto",
                    lg: "auto",
                    xl: "auto"
                }
            };
        },
        mainCard(item, part) {
            let {
                referenceNumber,
                plannedDepartureDate,
                tenantCarrier,
                tripPartnerSet,
                plannedArrivalDate
            } = item.node;

            let _mainCard = [];

            if (item.node.transportMode === "SEA") {
                let { tripSeaTransportSet } = item.node;
                if (!plannedDepartureDate) {
                    let polNode = tripSeaTransportSet.edges[0]?.node;
                    plannedDepartureDate = polNode?.originalEstimatedDepartureDate ?? polNode?.estimatedDepartureDate;
                }
            }

            /* block code */
            const containerStyle = tag => {
                return `background-color: ${
                    this.statusColor(tag).color1
                }; border-radius: 4px; width: 70px; display: flex; justify-content: center; align-items: center;`;
            };

            const formatDate = date => {
                return date ? this.formatDateTime(date) : "";
            };

            /* Partners show partner name  */
            const _consignees = tripPartnerSet.edges.filter(
                ({ node }) => node.tenantPartner.partnerType === "consignee"
            ).map(({ node }) => node.tenantPartner.name).join(", ");
            
            const _consignors = tripPartnerSet.edges
                .filter(({ node }) => node.tenantPartner.partnerType === "consignor")
                .map(({ node }) => node.tenantPartner.name).join(", ");

            const _forwarders = tripPartnerSet.edges
                .filter(({ node }) => node.tenantPartner.partnerType === "forwarder")
                .map(({ node }) => node.tenantPartner.name).join(", ");

            _mainCard.push({
                title: this.$t("headers.reference_number"),
                tag: "referenceNumber",
                icon: "tag",
                iconSize: 8,
                colorIcon: null,
                style: "",
                align: "left",
                value: referenceNumber ?? "",
                col: {
                    xs: 12,
                    sm: this.screenWidth < 700 ? 6 : 4,
                    md: this.screenWidth < 1295 ? 3 : "auto",
                    lg: this.screenWidth < 1295 ? 3 : "auto",
                    xl: "auto"
                }
            });
            _mainCard.push({
                title: this.$t("headers.consignee"),
                tag: "consignee",
                icon: "card_travel",
                iconSize: 8,
                colorIcon: "",
                style: "",
                align: "left",
                value: _consignees ?? "",
                col: {
                    xs: 12,
                    sm: this.screenWidth < 700 ? 6 : 4,
                    md: this.screenWidth < 1295 ? 3 : "auto",
                    lg: this.screenWidth < 1295 ? 3 : "auto",
                    xl: "auto"
                }
            });
            _mainCard.push({
                title: this.$t("headers.consignor"),
                tag: "consignor",
                icon: "inventory",
                iconSize: 8,
                colorIcon: "",
                style: "",
                align: "left",
                value: _consignors ?? "",
                col: {
                    xs: 12,
                    sm: this.screenWidth < 700 ? 6 : 4,
                    md: this.screenWidth < 1295 ? 3 : "auto",
                    lg: this.screenWidth < 1295 ? 3 : "auto",
                    xl: "auto"
                }
            });
            _mainCard.push({
                title: this.$t("headers.forwarder"),
                tag: "forwarder",
                icon: "airport_shuttle",
                iconSize: 8,
                colorIcon: "",
                style: "",
                align: "left",
                value: _forwarders ?? "",
                col: {
                    xs: 12,
                    sm: this.screenWidth < 700 ? 6 : 4,
                    md: this.screenWidth < 1295 ? 3 : "auto",
                    lg: this.screenWidth < 1295 ? 3 : "auto",
                    xl: "auto"
                }
            });
            _mainCard.push({
                title: this.$t("headers.carrier"),
                tag: "carrier",
                icon: "commute",
                iconSize: 8,
                colorIcon: "",
                style: "",
                align: "left",
                value: tenantCarrier?.name ?? "",
                col: {
                    xs: 12,
                    sm: this.screenWidth < 700 ? 6 : 4,
                    md: this.screenWidth < 1295 ? 3 : "auto",
                    lg: this.screenWidth < 1295 ? 3 : "auto",
                    xl: "auto"
                }
            });
            _mainCard.push({
                title: this.$t("headers.origin"),
                tag: "origin",
                icon: "explore",
                iconSize: 8,
                colorIcon: "",
                style: "",
                align: "left",
                value: this.getOrigin(item.node) ?? "",
                col: {
                    xs: 12,
                    sm: this.screenWidth < 700 ? 6 : 4,
                    md: this.screenWidth < 1295 ? 3 : "auto",
                    lg: this.screenWidth < 1295 ? 3 : "auto",
                    xl: "auto"
                }
            });
            _mainCard.push({
                title: this.$t("headers.planned_departure"),
                tag: "plannedDepartureDate",
                icon: "schedule",
                iconSize: 8,
                colorIcon: "",
                style: "",
                align: "left",
                value: formatDate(plannedDepartureDate) ?? "",
                col: {
                    xs: 12,
                    sm: this.screenWidth < 700 ? 6 : 4,
                    md: this.screenWidth < 1295 ? 3 : "auto",
                    lg: this.screenWidth < 1295 ? 3 : "auto",
                    xl: "auto"
                }
            });
            _mainCard.push({
                title: this.$t("headers.destination"),
                tag: "destination",
                icon: "flag",
                iconSize: 8,
                colorIcon: "",
                style: "",
                align: "left",
                value: this.getDestination(item.node) ?? "",
                col: {
                    xs: 12,
                    sm: this.screenWidth < 700 ? 6 : 4,
                    md: this.screenWidth < 1295 ? 3 : "auto",
                    lg: this.screenWidth < 1295 ? 3 : "auto",
                    xl: "auto"
                }
            });
            _mainCard.push({
                title: this.$t("headers.planned_arrival"),
                tag: "plannedArrivalDate",
                icon: "schedule",
                iconSize: 8,
                colorIcon: "",
                style: "",
                align: "left",
                value: formatDate(plannedArrivalDate) ?? "",
                col: {
                    xs: 12,
                    sm: this.screenWidth < 700 ? 6 : 4,
                    md: this.screenWidth < 1295 ? 3 : "auto",
                    lg: this.screenWidth < 1295 ? 3 : "auto",
                    xl: "auto"
                }
            });
            //last connection
            _mainCard.push({
                title: this.$t("headers.last_connection"),
                tag: "lastConnection",
                icon: "schedule",
                iconSize: 8,
                colorIcon: "",
                style: "",
                align: "left",
                value: formatDate(item.node?.trackerLastConnectionTime) ?? "",
                col: {
                    xs: 12,
                    sm: this.screenWidth < 700 ? 6 : 4,
                    md: this.screenWidth < 1295 ? 3 : 2,
                    lg: this.screenWidth < 1295 ? 3 : 2,
                    xl: "auto"
                }
            });
            //last temperature
            _mainCard.push({
                title: this.$t("headers.trackerLastTemperature"),
                tag: "lastTemperature",
                icon: "thermostat",
                iconSize: 8,
                colorIcon: "",
                style: "",
                align: "left",
                value: item.node.trackerLastTemperatureValue
                    ? `${this.formatTemperature(item.node.trackerLastTemperatureValue).toFixed(
                          2
                      )} ${this.getTemperatureSymbol()}`
                    : "",
                col: {
                    xs: 12,
                    sm: this.screenWidth < 700 ? 6 : 4,
                    md: this.screenWidth < 1295 ? 3 : 2,
                    lg: this.screenWidth < 1295 ? 3 : 2,
                    xl: "auto"
                }
            });
            //last location
            _mainCard.push({
                title: this.$t("headers.trackerLastLocation"),
                tag: "lastLocation",
                icon: "location_on",
                iconSize: 8,
                colorIcon: "",
                style: "",
                align: "left",
                value: item.node?.trackerLastLocation ?? "",
                col: {
                    xs: 12,
                    sm: this.screenWidth < 700 ? 6 : 4,
                    md: this.screenWidth < 1295 ? 3 : 2,
                    lg: this.screenWidth < 1295 ? 3 : 2,
                    xl: "auto"
                }
            });

            //trade
            _mainCard.push({
                title: this.$t("headers.trade"),
                tag: "trade",
                icon: "trade",
                iconSize: 8,
                colorIcon: "",
                style: "",
                align: "left",
                value: item.node?.trade ?? "",
                col: {
                    xs: 12,
                    sm: this.screenWidth < 700 ? 6 : 4,
                    md: this.screenWidth < 1295 ? 3 : 2,
                    lg: this.screenWidth < 1295 ? 3 : 2,
                    xl: "auto"
                }
            });

            //vessel
            _mainCard.push({
                title: this.$t("headers.vessel"),
                tag: "vessel",
                icon: "sailing",
                iconSize: 8,
                colorIcon: "",
                style: "",
                align: "left",
                value: item.node.transportMode === "SEA" ? this.getVessel(item.node)[0] ?? "" : "",
                tooltip: null,
                col: {
                    xs: 12,
                    sm: this.screenWidth < 700 ? 6 : 4,
                    md: this.screenWidth < 1295 ? 3 : 2,
                    lg: this.screenWidth < 1295 ? 3 : 2,
                    xl: "auto"
                }
            });
            // if (item.node.transportMode === "SEA") {

            // }
            // let result ={
            //     headers: this.headersEnabled || [],
            //     items: _mainCard || []
            // }
            _mainCard = _mainCard.sort((a, b) => {
                let indexA = this.headersEnabled.findIndex(header => header.code === `node.${a.tag}`);
                let indexB = this.headersEnabled.findIndex(header => header.code === `node.${b.tag}`);
                return indexA - indexB;
            }).filter(item => {
                let header = this.headersEnabled.find(header => header.code === `node.${item.tag}`);
                return part === 'header' ? header?.enabled : !header?.enabled;
            });

            // _list = _list.filter(item => {
            //     let header = this.headers.find(header => header.code === `node.${item.tag}`);
            //     return header?.enabled;
            // });

            return _mainCard;
        },
        getVessel(item) {
            let _vessels = [];
            if (item.transportMode === "SEA") {
                let { tripSeaTransportSet } = item;
                tripSeaTransportSet.edges.forEach(({ node }) => {
                    if (node.vessel?.name) {
                        _vessels.push(node.vessel.name);
                    }
                });
            }
            return _vessels;
        },
        getDataVisualTrip(item) {
            // const { tripSeaTransportSet } = item.node;
            let _card = [];
            // let _index = 0;
            const transportMode = item.node.transportMode;

            if(transportMode === "AIR") {
                _card = this.getAirTripVisual(item);
            }else if(transportMode === "ROAD") {
                _card = this.getRoadTripVisual(item);
            }else if(transportMode === "SEA") {
                _card = this.getSeaTripVisual(item);
            }

            return _card;
        },
        getAirTripVisual(item) {
            const { tripAirTransportSet } = item.node;
            let _card = [];
            // let _index = 0;
            tripAirTransportSet.edges.forEach(({ node }, index) => {
                const checkTransit = (element) => element.portName === node.eventName && element.code === "transit";
                let transitExistIndex = _card.findIndex(checkTransit);
                if(transitExistIndex !== -1){
                    if(node.eventType === 'flight_depart'){
                        _card[transitExistIndex].atd = node.eventTime;
                    }else if(node.eventType === 'flight_arrive'){
                        _card[transitExistIndex].ata = node.eventTime;
                    }
                    return;
                }
                _card.push({
                    code: index === 0 ? "origin" 
                    : index === tripAirTransportSet.edges.length - 1 ? "destination" 
                    : "transit",
                    title: index === 0 ? this.$t("trips.departure1") : index === tripAirTransportSet.edges.length - 1 ? this.$t("trips.arrival1") : this.$t("trips.transit1"),
                    icon: index === 0 ? "trip_origin" : index === tripAirTransportSet.edges.length - 1 ? "flag" : this.transitIcon,
                    portCode: "",
                    portName: node.eventName,
                    originalEtd: null,
                    eta: null,
                    etd: null,
                    atd: node.eventType === 'flight_depart' ? node.eventTime : null,
                    ata: node.eventType === 'flight_arrive' ? node.eventTime : null,
                    delay: "",
                    delayColor: "",
                    vgmCutOffDate: null,
                    cutOffDateTime: null,
                    transport: null,
                    voyage: null,
                });
            });

            if(_card.length < 1) {
                _card.push({
                    code: "origin",
                    title: this.$t("trips.departure1"),
                    icon: "trip_origin",
                    portCode: "",
                    portName: this.$t("trips.departure1") + " " + this.$t("headers.airport"),
                    etd: null,
                    atd: null,
                    originalEtd: null,
                    delay: "",
                    delayColor: null,
                    vgmCutOffDate: null,
                    cutOffDateTime: null,
                    transport: null,
                    voyage: null,
                },{
                    code: "destination",
                    title: this.$t("trips.arrival1"),
                    icon: "flag",
                    portCode: "",
                    portName: this.$t("trips.arrival1") + " " + this.$t("headers.airport"),
                    eta: null,
                    ata: null,
                    originalEta: null,
                    delay: "",
                    delayColor: null,
                    transport: null,
                    voyage: null,
                });
            }
            return _card;
        },
        getRoadTripVisual(item) {
            const { tripRoadTransportSet } = item.node;
            let _card = [];
            // if (tripRoadTransportSet.edges.length < 1) {
            //     return [];
            // }

            let firstItem = tripRoadTransportSet.edges[0];
            // let lastItem = tripRoadTransportSet.edges[tripRoadTransportSet.edges.length - 1];
            _card.push({
                code: "origin",
                title: this.$t("trips.departure1"),
                icon: "trip_origin",
                portCode: "",
                portName: item.node?.originLocation?.name ? item.node?.originLocation?.name : 
                firstItem?.eventName ? firstItem?.eventName : this.$t("trips.departure1") + " " + this.$t("headers.location"),
                originalEtd: item.node?.plannedDepartureDate,
                etd: item.node?.plannedDepartureDate,
                atd: tripRoadTransportSet.edges.length > 0 ? tripRoadTransportSet.edges[0]?.node?.eventTime : null,
                delay: "",
                delayColor: "",
            });
            _card.push({
                code: "destination",
                title: this.$t("trips.arrival1"),
                icon: "flag",
                portCode: "",
                portName: item.node?.destinationLocation?.name || this.$t("trips.arrival1") + " " + this.$t("headers.location"),
                originalEta: item.node?.plannedArrivalDate,
                eta: item.node?.plannedArrivalDate,
                ata: null,
                delay: "",
                delayColor: "",
            });
            return _card;
        },
        getSeaTripVisual(item) {
            const { tripSeaTransportSet } = item.node;
            let _card = [];
            let _index = 0;
            const transportMode = item.node.transportMode;

            if (transportMode === "SEA") {
                tripSeaTransportSet.edges.forEach(({ node }) => {
                    let _portType = node.portTransportType;
                    let _portCode = node.portCode;
                    let _portName = node.portName;
                    let _estimatedArrivalDate = node.estimatedArrivalDate;
                    let _actualArrivalDate = node.actualArrivalDate;
                    let _estimatedDepartureDate = node.estimatedDepartureDate;
                    let _actualDepartureDate = node.actualDepartureDate;
                    let _originalEtd = node.originalEstimatedDepartureDate;
                    let _originalEta = node.originalEstimatedArrivalDate;
                    let _vgmCutOffDate = node.vgmCutOffDate;
                    let _cutOffDateTime = node.cutOffDateTime;
                    let _vessel = node.vessel?.name;
                    let _voyage = node.voyage;

                    _card.push({
                        code: null,
                        title: null,
                        icon: null,
                        portCode: null,
                        portName: null,
                        originalEtd: null,
                        etd: null,
                        eta: null,
                        atd: null,
                        ata: null,
                        delay: null,
                        delayColor: null,
                        vgmCutOffDate: null,
                        cutOffDateTime: null,
                        transport: _vessel,
                        voyage: _voyage
                    });

                    if (_portType === "POL") {
                        _card[_index].code = "origin";
                        _card[_index].title = _portType;
                        _card[_index].icon = "trip_origin";
                        _card[_index].portCode = _portCode ? `(${_portCode})` : `(${this.$t("general.unknown")})`;
                        _card[_index].portName = _portName || this.$t("trips.departure1") + " " + this.$t("headers.sea_port");
                        _card[_index].originalEtd = _originalEtd;
                        _card[_index].etd = _estimatedDepartureDate;
                        _card[_index].atd = _actualDepartureDate;
                        const { offset, color, text } = this.getDelay(node, "etd");
                        _card[_index].delay = offset !== "0" ? text : "";
                        _card[_index].delayColor = color;
                        _card[_index].vgmCutOffDate = _vgmCutOffDate;
                        _card[_index].cutOffDateTime = _cutOffDateTime;
                        _card[_index].transport = _vessel;
                        _card[_index].voyage = _voyage;
                    }

                    if (_portType === "T/S") {
                        _card[_index].code = "transit";
                        _card[_index].title = _portType;
                        _card[_index].icon = this.transitIcon; //"transit_enterexit";
                        _card[_index].portCode = _portCode ? `(${_portCode})` : `(${this.$t("general.unknown")})`;
                        _card[_index].portName = _portName || this.$t("trips.transit1") + " " + this.$t("headers.sea_port");
                        _card[_index].originalEtd = _originalEtd;
                        _card[_index].etd = _estimatedDepartureDate;
                        _card[_index].atd = _actualDepartureDate;
                        _card[_index].eta = _estimatedArrivalDate;
                        _card[_index].ata = _actualArrivalDate;
                        const { offset, color, text } = this.getDelay(node, "eta");
                        _card[_index].delay = offset !== "0" ? text : "";
                        _card[_index].delayColor = color;
                        _card[_index].vgmCutOffDate = _vgmCutOffDate;
                        _card[_index].cutOffDateTime = _cutOffDateTime;
                        _card[_index].transport = _vessel;
                        _card[_index].voyage = _voyage;
                    }

                    if (_portType === "POD") {
                        _card[_index].code = "destination";
                        _card[_index].title = _portType;
                        _card[_index].icon = "flag";
                        _card[_index].portCode = _portCode ? `(${_portCode})` : `(${this.$t("general.unknown")})`;
                        _card[_index].portName = _portName || this.$t("trips.arrival1") + " " + this.$t("headers.sea_port");
                        _card[_index].eta = _estimatedArrivalDate;
                        _card[_index].ata = _actualArrivalDate;
                        _card[_index].originalEta = _originalEta;
                        const { offset, color, text } = this.getDelay(node, "eta");
                        _card[_index].delay = offset !== "0" ? text : "";
                        _card[_index].delayColor = color;
                        _card[_index].transport = _vessel;
                        _card[_index].voyage = _voyage;
                    }
                    _index++;
                });
            }

            if (_card.length > 0) {
                if (_card[0].code !== "origin") {
                    _card.unshift({});
                    _card[0].code = "origin";
                    _card[0].title = "POL";
                    _card[0].icon = "trip_origin";
                    _card[0].portCode = `(${this.$t("general.unknown")})`;
                    _card[0].portName = this.$t("trips.departure1") + " " + this.$t("headers.sea_port");
                    _card[0].etd = null;
                    _card[0].atd = null;
                    _card[0].originalEtd = null;
                    _card[0].delay = "";
                    _card[0].delayColor = null;
                    _card[0].vgmCutOffDate = null;
                    _card[0].cutOffDateTime = null;
                    _card[0].transport = null;
                    _card[0].voyage = null;
                }

                if (_card[_card.length - 1].code !== "destination") {
                    _card.push({});
                    _card[_card.length - 1].code = "destination";
                    _card[_card.length - 1].title = "POD";
                    _card[_card.length - 1].icon = "flag";
                    _card[_card.length - 1].portCode = `(${this.$t("general.unknown")})`;
                    _card[_card.length - 1].portName = this.$t("trips.arrival1") + " " + this.$t("headers.sea_port");
                    _card[_card.length - 1].eta = null;
                    _card[_card.length - 1].ata = null;
                    _card[_card.length - 1].originalEta = null;
                    _card[_card.length - 1].delay = "";
                    _card[_card.length - 1].delayColor = null;
                    _card[_card.length - 1].transport = null;
                    _card[_card.length - 1].voyage = null;
                }
            } else {
                _card.push({});
                _card[0].code = "origin";
                _card[0].title = "POL";
                _card[0].icon = "trip_origin";
                _card[0].portCode = `(${this.$t("general.unknown")})`;
                _card[0].portName = this.$t("trips.departure1") + " " + this.$t("headers.sea_port");
                _card[0].etd = null;
                _card[0].atd = null;
                _card[0].originalEtd = null;
                _card[0].delay = "";
                _card[0].delayColor = null;
                _card[0].vgmCutOffDate = null;
                _card[0].cutOffDateTime = null;
                _card[0].transport = null;
                _card[0].voyage = null;

                _card.push({});
                _card[1].code = "destination";
                _card[1].title = "POD";
                _card[1].icon = "flag";
                _card[1].portCode = `(${this.$t("general.unknown")})`;
                _card[1].portName = this.$t("trips.arrival1") + " " + this.$t("headers.sea_port");
                _card[1].eta = null;
                _card[1].ata = null;
                _card[1].originalEta = null;
                _card[1].delay = "";
                _card[1].delayColor = null;
                _card[1].transport = null;
                _card[1].voyage = null;
            }

            return _card;
        },
        secondaryCard(item) {
            const { transportMode } = item.node;
            let _card = [];

            if (transportMode === "SEA") {
                const { tripSeaTransportSet } = item.node;
                _card.push(
                    {
                        id: 0,
                        title: this.$t("others.pol"),
                        tag: "pol",
                        icon: "logout",
                        iconSize: 8,
                        colorIcon: "",
                        style: "",
                        align: "left",
                        value: [
                            {
                                outputValue: null,
                                delay: null,
                                delayColor: null,
                                tooltip: null,
                                title: "ETD",
                                tag: "polETD",
                                date: null,
                                icon: "schedule",
                                iconSize: 8,
                                colorIcon: "",
                                style: "",
                                align: "left",
                                value: null,
                                type: "sea"
                            }
                        ],
                        col: {
                            xs: 12,
                            sm: this.screenWidth < 700 ? 6 : 4,
                            md: 6,
                            lg: 4,
                            xl: 4
                        }
                    },
                    {
                        id: 1,
                        title: this.$t("others.ts"),
                        tag: "transshipment",
                        icon: "logout",
                        iconSize: 8,
                        colorIcon: "",
                        style: "",
                        align: "left",
                        value: [
                            {
                                outputValue: null,
                                delay: null,
                                delayColor: null,
                                tooltip: null,
                                title: "ETD",
                                tag: "polETD",
                                date: null,
                                icon: "schedule",
                                iconSize: 8,
                                colorIcon: "",
                                style: "",
                                align: "left",
                                value: null,
                                type: "sea"
                            }
                        ],
                        col: {
                            xs: 12,
                            sm: this.screenWidth < 700 ? 6 : 4,
                            md: 6,
                            lg: 4,
                            xl: 4
                        }
                    },
                    {
                        id: 2,
                        title: this.$t("others.pod"),
                        tag: "pod",
                        icon: "sports_score",
                        iconSize: 8,
                        colorIcon: "",
                        style: "",
                        align: "left",
                        value: [
                            {
                                outputValue: null,
                                delay: null,
                                delayColor: null,
                                tooltip: null,
                                title: "ETA",
                                tag: "podETA",
                                date: null,
                                icon: "schedule",
                                iconSize: 8,
                                colorIcon: "",
                                style: "",
                                align: "left",
                                value: null,
                                type: "sea"
                            }
                        ],
                        col: {
                            xs: 12,
                            sm: this.screenWidth < 700 ? 6 : 4,
                            md: 6,
                            lg: 4,
                            xl: 4
                        }
                    }
                );

                let transshipmentCount = 0;

                tripSeaTransportSet.edges.forEach(({ node }) => {
                    let _portType = node.portTransportType;
                    let _portCode = node.portCode;
                    let _estimatedArrivalDate = node.estimatedArrivalDate;
                    let _actualArrivalDate = node.actualArrivalDate;
                    let _estimatedDepartureDate = node.estimatedDepartureDate;
                    let _actualDepartureDate = node.actualDepartureDate;

                    if (_portType === "POL") {
                        _card[0].value[0].outputValue = _portCode;
                        _card[0].value[0].date = _actualDepartureDate
                            ? this.formatDateTime(_actualDepartureDate)
                            : this.formatDateTime(_estimatedDepartureDate);
                        _card[0].value[0].title = _actualDepartureDate ? "ATD" : "ETD";
                        const { offset, color, text } = this.getDelay(node, "etd");
                        _card[0].value[0].delay = offset !== "0" ? text : "";
                        _card[0].value[0].delayColor = color;
                    }

                    if (_portType === "POD") {
                        _card[2].value[0].outputValue = _portCode;
                        _card[2].value[0].date = _actualArrivalDate
                            ? this.formatDateTime(_actualArrivalDate)
                            : this.formatDateTime(_estimatedArrivalDate);
                        _card[2].value[0].title = _actualArrivalDate ? "ATA" : "ETA";
                        const { offset, color, text } = this.getDelay(node, "eta");
                        _card[2].value[0].delay = offset !== "0" ? text : "";
                        _card[2].value[0].delayColor = color;
                    }

                    if (_portType === "T/S") {
                        transshipmentCount++;

                        let transshipmentData = {
                            outputValue: _portCode,
                            delay: null,
                            delayColor: null,
                            tooltip: null,
                            title: _actualDepartureDate ? "ATD" : "ETD",
                            tag: "transshipment",
                            date: _actualDepartureDate
                                ? this.formatDateTime(_actualDepartureDate)
                                : this.formatDateTime(_estimatedDepartureDate),
                            icon: "schedule",
                            iconSize: 8,
                            colorIcon: "",
                            style: "",
                            align: "left",
                            value: null
                        };

                        const { offset, color, text } = this.getDelay(node, "etd");
                        transshipmentData.delay = offset !== "0" ? text : "";
                        transshipmentData.delayColor = color;

                        if (transshipmentCount === 1) {
                            _card[1].value[0] = transshipmentData;
                        } else {
                            _card[1].value.push(transshipmentData);
                        }
                    }
                });
            } else {
                const { trackerLastLocation, trackerLastConnectionTime, trackerLastTemperatureValue } = item.node;

                _card.push(
                    {
                        id: 0,
                        title: this.$t("headers.last_connection"),
                        tag: "lastConnection",
                        icon: "schedule",
                        iconSize: 8,
                        colorIcon: "",
                        style: "",
                        align: "left",
                        value: [
                            {
                                outputValue: trackerLastConnectionTime
                                    ? this.formatDateTime(trackerLastConnectionTime)
                                    : "",
                                delay: null,
                                delayColor: null,
                                tooltip: null,
                                title: "Last Connection",
                                tag: "lastConnection",
                                date: null, // Valor adicional
                                icon: "schedule",
                                iconSize: 8,
                                colorIcon: "",
                                style: "",
                                align: "left",
                                value: null,
                                type: "tracker"
                            }
                        ],
                        col: {
                            xs: 12,
                            sm: this.screenWidth < 700 ? 6 : 4,
                            md: 4,
                            lg: 2,
                            xl: 2
                        }
                    },
                    {
                        id: 1,
                        title: this.$t("headers.trackerLastTemperature"),
                        tag: "lastTemperature",
                        icon: "thermostat",
                        iconSize: 8,
                        colorIcon: "",
                        style: "",
                        align: "left",
                        value: [
                            {
                                outputValue: trackerLastTemperatureValue
                                    ? `${this.formatTemperature(trackerLastTemperatureValue).toFixed(
                                          2
                                      )} ${this.getTemperatureSymbol()}`
                                    : "",
                                delay: null,
                                delayColor: null,
                                tooltip: null,
                                title: "Temperature",
                                tag: "lastTemperature",
                                date: null, // Valor adicional
                                icon: "thermostat",
                                iconSize: 8,
                                colorIcon: "",
                                style: "",
                                align: "left",
                                value: null,
                                type: "tracker"
                            }
                        ],
                        col: {
                            xs: 12,
                            sm: this.screenWidth < 700 ? 6 : 4,
                            md: 4,
                            lg: 2,
                            xl: 2
                        }
                    },
                    {
                        id: 2,
                        title: this.$t("headers.trackerLastLocation"),
                        tag: "lastLocation",
                        icon: "location_on",
                        iconSize: 8,
                        colorIcon: "",
                        style: "",
                        align: "left",
                        value: [
                            {
                                outputValue: trackerLastLocation,
                                delay: null,
                                delayColor: null,
                                tooltip: null,
                                title: "Last Location",
                                tag: "lastLocation",
                                date: null, // Valor adicional
                                icon: "location_on",
                                iconSize: 8,
                                colorIcon: "",
                                style: "",
                                align: "left",
                                value: null,
                                type: "tracker"
                            }
                        ],
                        col: {
                            xs: 12,
                            sm: this.screenWidth < 700 ? 6 : 4,
                            md: 4,
                            lg: 2,
                            xl: 2
                        }
                    }
                );
            }

            _card = _card.sort((a, b) => a.id - b.id);
            return _card;
        },
        trackerColorStatus(item) {
            let statusColor = "gray lighten-2";
            if (item.activatedDate) {
                //if activated and last connection time is more than 12 hours, return red
                //calculate the difference between activated date and last connection time in hours and minutes
                let lastConnection = item?.lastConnectionTime ? new Date(item?.lastConnectionTime) : new Date();
                let now = new Date(new Date());
                let diffInMs = Math.abs(lastConnection - now);
                let diffInHours = Math.floor(diffInMs / (1000 * 60 * 60));
                let diffInMinutes = Math.floor((diffInMs % (1000 * 60 * 60)) / (1000 * 60));

                if (diffInHours >= 12) {
                    statusColor = "red lighten-2";
                } else {
                    statusColor = "blue lighten-2";
                }
            }

            return statusColor;
        },
        footerDetailsRow(trip) {
            const { tripFreightSet, transportMode } = trip.node || {};
            if (!tripFreightSet || !tripFreightSet.edges) {
                return [];
            }

            let _footerDetailsRow = [];
            // let tripAlerts = trip.tripAlertSet?.edges ?? [];

            _footerDetailsRow = tripFreightSet.edges.reduce((acc, item) => {
                const { node } = item;
                if (!node) {
                    return acc;
                }

                let trackers = node.tripFreightTrackerSet.edges.length > 0 ? this.trackerSerie(node) : [];
                let _tracker = {
                    serial: trackers[0]?.serial ?? "",
                    activatedDate: trackers[0]?.activatedDate ?? null,
                    lastConnectionTime: trackers[0]?.lastConnectionTime ?? null
                };

                if (transportMode === "SEA") {
                    if (node.containerNumber || node.billOfLadingNumber || node.referenceNumber) {
                        acc.push({
                            id: node.id,
                            title: this.$t("headers.container_number"),
                            tag: "containerNumber",
                            icon: null,
                            iconSize: 8,
                            colorIcon: this.trackerColorStatus(_tracker),
                            color: node.tripFreight?.freightHasAlert ? "red lighten-3" : "",
                            style: "",
                            align: "left",
                            value: node.containerNumber
                                ? node.containerNumber
                                : node.billOfLadingNumber
                                ? node.billOfLadingNumber
                                : node.referenceNumber,
                            valueDisplay: _tracker.serial,
                            col: {
                                xs: 12,
                                sm: "auto",
                                md: "auto",
                                lg: "auto",
                                xl: "auto"
                            }
                        });
                    }
                } else if (transportMode === "AIR") {
                    if (node.airWaybillNumber || node.referenceNumber) {
                        acc.push({
                            id: node.id,
                            title: this.$t("headers.airwaybill_number"),
                            tag: "airWaybillNumber",
                            icon: null,
                            iconSize: 8,
                            colorIcon: this.trackerColorStatus(_tracker),
                            color: node.tripFreight?.freightHasAlert ? "red lighten-3" : "",
                            style: "",
                            align: "left",
                            value: node.airWaybillNumber
                                ? node.airWaybillNumber
                                : node.orderNumber
                                ? node.orderNumber
                                : node.referenceNumber,
                            valueDisplay: _tracker.serial,
                            col: {
                                xs: 12,
                                sm: "auto",
                                md: "auto",
                                lg: "auto",
                                xl: "auto"
                            }
                        });
                    }
                } else {
                    if (node.orderNumber || node.referenceNumber) {
                        acc.push({
                            id: node.id,
                            title: this.$t("headers.order_number"),
                            tag: "orderNumber",
                            icon: null,
                            iconSize: 8,
                            colorIcon: this.trackerColorStatus(_tracker),
                            color: node.tripFreight?.freightHasAlert ? "red lighten-3" : "",
                            style: "",
                            align: "left",
                            value: node.orderNumber ? node.orderNumber : node.referenceNumber,
                            valueDisplay: _tracker.serial,
                            col: {
                                xs: 12,
                                sm: "auto",
                                md: "auto",
                                lg: "auto",
                                xl: "auto"
                            }
                        });
                    }
                }

                return acc;
            }, []);

            return _footerDetailsRow;
        },
        prepareFreightExpandInfo(trip) {
            let _result = [];
            let freights = trip.node.tripFreightSet.edges;
            let transportMode = trip.node.transportMode;
            freights.forEach(freight => {
                _result.push(this.freightInfo(freight, transportMode));
            });
            return _result;
        },
        freightInfo(item, transportMode) {
            if (!item || !item.node) return [];
            const {
                referenceNumber,
                orderNumber,
                goods,
                containerNumber,
                airWaybillNumber,
                billOfLadingNumber
            } = item.node;

            let _freightCard = [];
            let _tracker = this.trackerSerie(item.node)[0]?.serial ?? "";

            if (transportMode === "SEA") {
                _freightCard.splice(
                    2,
                    0,
                    {
                        title: this.$t("headers.container_number"),
                        tag: "containerNumber",
                        icon: null,
                        iconSize: 8,
                        colorIcon: "",
                        style: "",
                        align: "left",
                        value: containerNumber,
                        col: {
                            xs: 12,
                            sm: this.screenWidth < 700 ? 6 : 4,
                            md: this.screenWidth < 1295 ? 3 : "auto",
                            lg: this.screenWidth < 1295 ? 3 : "auto",
                            xl: "auto"
                        }
                    },
                    {
                        title: this.$t("headers.bill"),
                        tag: "billOfLadingNumber",
                        icon: null,
                        iconSize: 8,
                        colorIcon: "",
                        style: "",
                        align: "left",
                        value: billOfLadingNumber,
                        col: {
                            xs: 12,
                            sm: this.screenWidth < 700 ? 6 : 4,
                            md: this.screenWidth < 1295 ? 3 : "auto",
                            lg: this.screenWidth < 1295 ? 3 : "auto",
                            xl: "auto"
                        }
                    }
                );
            }

            if (transportMode === "AIR") {
                _freightCard.splice(2, 0, {
                    title: this.$t("headers.airwaybill_number"),
                    tag: "airwaybillNumber",
                    icon: null,
                    iconSize: 8,
                    colorIcon: "",
                    style: "",
                    align: "left",
                    value: airWaybillNumber,
                    col: {
                        xs: 12,
                        sm: this.screenWidth < 700 ? 6 : 4,
                        md: this.screenWidth < 1295 ? 3 : "auto",
                        lg: this.screenWidth < 1295 ? 3 : "auto",
                        xl: "auto"
                    }
                });
            }

            _freightCard.push(
                {
                    title: this.$t("headers.freight_ref_number"),
                    tag: "freightRefNumber",
                    icon: null,
                    iconSize: 8,
                    colorIcon: "",
                    style: "",
                    align: "left",
                    value: referenceNumber,
                    col: {
                        xs: 12,
                        sm: this.screenWidth < 700 ? 6 : 4,
                        md: this.screenWidth < 1295 ? 3 : "auto",
                        lg: this.screenWidth < 1295 ? 3 : "auto",
                        xl: "auto"
                    }
                },
                {
                    title: this.$t("headers.order_number"),
                    tag: "orderNumber",
                    icon: null,
                    iconSize: 8,
                    colorIcon: "",
                    style: "",
                    align: "left",
                    value: orderNumber,
                    col: {
                        xs: 12,
                        sm: this.screenWidth < 700 ? 6 : 4,
                        md: this.screenWidth < 1295 ? 3 : "auto",
                        lg: this.screenWidth < 1295 ? 3 : "auto",
                        xl: "auto"
                    }
                },
                {
                    title: this.$t("headers.tracker"),
                    tag: "tracker",
                    icon: null,
                    iconSize: 8,
                    colorIcon: "",
                    style: "",
                    align: "left",
                    value: _tracker,
                    col: {
                        xs: 12,
                        sm: this.screenWidth < 700 ? 6 : 4,
                        md: this.screenWidth < 1295 ? 3 : "auto",
                        lg: this.screenWidth < 1295 ? 3 : "auto",
                        xl: "auto"
                    }
                },
                {
                    title: this.$t("headers.goods"),
                    tag: "goods",
                    icon: null,
                    iconSize: 8,
                    colorIcon: "",
                    style: "",
                    align: "left",
                    value: goods,
                    col: {
                        xs: 12,
                        sm: this.screenWidth < 700 ? 6 : 4,
                        md: this.screenWidth < 1295 ? 3 : "auto",
                        lg: this.screenWidth < 1295 ? 3 : "auto",
                        xl: "auto"
                    }
                }
            );

            return _freightCard;
        },
        freightHeaders(transportMode) {
            let _freightCard = [];

            if (transportMode === "SEA") {
                _freightCard.splice(
                    2,
                    0,
                    {
                        title: this.$t("headers.container_number"),
                        tag: "containerNumber",
                        icon: null,
                        iconSize: 8,
                        colorIcon: "",
                        style: "",
                        align: "left",
                        col: {
                            xs: 12,
                            sm: this.screenWidth < 700 ? 6 : 4,
                            md: this.screenWidth < 1295 ? 3 : "auto",
                            lg: this.screenWidth < 1295 ? 3 : "auto",
                            xl: "auto"
                        }
                    },
                    {
                        title: this.$t("headers.bill"),
                        tag: "billOfLadingNumber",
                        icon: null,
                        iconSize: 8,
                        colorIcon: "",
                        style: "",
                        align: "left",
                        col: {
                            xs: 12,
                            sm: this.screenWidth < 700 ? 6 : 4,
                            md: this.screenWidth < 1295 ? 3 : "auto",
                            lg: this.screenWidth < 1295 ? 3 : "auto",
                            xl: "auto"
                        }
                    }
                );
            }

            if (transportMode === "AIR") {
                _freightCard.splice(2, 0, {
                    title: this.$t("headers.airwaybill_number"),
                    tag: "airwaybillNumber",
                    icon: null,
                    iconSize: 8,
                    colorIcon: "",
                    style: "",
                    align: "left",
                    col: {
                        xs: 12,
                        sm: this.screenWidth < 700 ? 6 : 4,
                        md: this.screenWidth < 1295 ? 3 : "auto",
                        lg: this.screenWidth < 1295 ? 3 : "auto",
                        xl: "auto"
                    }
                });
            }

            _freightCard.push(
                {
                    title: this.$t("headers.freight_ref_number"),
                    tag: "freightRefNumber",
                    icon: null,
                    iconSize: 8,
                    colorIcon: "",
                    style: "",
                    align: "left",
                    col: {
                        xs: 12,
                        sm: this.screenWidth < 700 ? 6 : 4,
                        md: this.screenWidth < 1295 ? 3 : "auto",
                        lg: this.screenWidth < 1295 ? 3 : "auto",
                        xl: "auto"
                    }
                },
                {
                    title: this.$t("headers.order_number"),
                    tag: "orderNumber",
                    icon: null,
                    iconSize: 8,
                    colorIcon: "",
                    style: "",
                    align: "left",
                    col: {
                        xs: 12,
                        sm: this.screenWidth < 700 ? 6 : 4,
                        md: this.screenWidth < 1295 ? 3 : "auto",
                        lg: this.screenWidth < 1295 ? 3 : "auto",
                        xl: "auto"
                    }
                },
                {
                    title: this.$t("headers.tracker"),
                    tag: "tracker",
                    icon: null,
                    iconSize: 8,
                    colorIcon: "",
                    style: "",
                    align: "left",
                    col: {
                        xs: 12,
                        sm: this.screenWidth < 700 ? 6 : 4,
                        md: this.screenWidth < 1295 ? 3 : "auto",
                        lg: this.screenWidth < 1295 ? 3 : "auto",
                        xl: "auto"
                    }
                },
                {
                    title: this.$t("headers.goods"),
                    tag: "goods",
                    icon: null,
                    iconSize: 8,
                    colorIcon: "",
                    style: "",
                    align: "left",
                    col: {
                        xs: 12,
                        sm: this.screenWidth < 700 ? 6 : 4,
                        md: this.screenWidth < 1295 ? 3 : "auto",
                        lg: this.screenWidth < 1295 ? 3 : "auto",
                        xl: "auto"
                    }
                }
            );

            return _freightCard;
        },
        getColSpan(obj) {
            let _breakpoint = this.$vuetify.breakpoint.name;
            return obj[_breakpoint];
        },
        goToDocument(trip) {
            this.$router.push({
                path: "/previewtrips",
                query: {
                    id: trip,
                    tab: "freights",
                    scroll: "doc"
                }
            });
        },
        goToAlerts(trip) {
            this.$router.push({
                path: "/previewtrips",
                query: {
                    id: trip,
                    tab: "alerts"
                }
            });
        },
        actionCard(item) {
            const { numberOfDocuments, numberOfUnresolvedAlerts } = item.node;

            let screen = this.$vuetify.breakpoint.width;
            //'mobile' : 'desktop'
            let mobileScreen = screen < 1395;

            const disabledDocsColor = value => {
                return value === 0 ? "opacity: 0.5; filter: grayscale(100%);" : "";
            };

            const disabledAlertsColor = value => {
                return value === 0 ? "#616161" : "#EB4F46";
            };

            const badgeColor = (type, value) => {
                if (type === "documents") {
                    if (this.isEmpty(value) || value === 0) {
                        return {
                            color: "#E0E0E0",
                            colorText: "#616161"
                        };
                    } else {
                        return {
                            color: "#E0E0E0",
                            colorText: "#7F7EA3"
                        };
                    }
                }
                if (type === "alerts") {
                    if (this.isEmpty(value) || value === 0) {
                        return {
                            color: "#E0E0E0",
                            colorText: "#616161"
                        };
                    } else {
                        return {
                            color: "#FFCDD2",
                            colorText: "#A64640FF"
                        };
                    }
                }
            };

            // const goToDocument = (trip) => {
            //     console.log("trip-document");
            //     this.$router.push({
            //         path: "/previewtrips",
            //         query: {
            //             id: trip,
            //             tab: "freights",
            //             scroll: "doc"
            //         }
            //     });
            // }

            // const goToAlerts = (trip) => {
            //     this.$router.push({
            //         path: "/previewtrips",
            //         query: {
            //             id: trip,
            //             tab: "alerts",
            //         }
            //     });
            // }

            let _action = [
                {
                    title: this.$t("headers.documents"),
                    tag: "documents",
                    icon: this.attached,
                    //img: require("@/assets/description.png"),
                    iconSize: "18",
                    colorIcon: "",
                    style: disabledDocsColor(numberOfDocuments),
                    badgeColor: badgeColor("documents", numberOfDocuments),
                    align: "left",
                    value: numberOfDocuments,
                    actions: () => this.goToDocument(item.node.id),
                    col: {
                        xs: "auto",
                        sm: "auto",
                        md: 6,
                        lg: mobileScreen ? 6 : 4,
                        xl: 4
                    }
                },
                {
                    title: this.$t("headers.alerts"),
                    tag: "alerts",
                    icon: "warning_amber",
                    iconSize: "18",
                    colorIcon: disabledAlertsColor(numberOfUnresolvedAlerts),
                    badgeColor: badgeColor("alerts", numberOfUnresolvedAlerts),
                    style: "",
                    align: "left",
                    value: numberOfUnresolvedAlerts,
                    actions: () => this.goToAlerts(item.node.id),
                    col: {
                        xs: "auto",
                        sm: "auto",
                        md: 6,
                        lg: mobileScreen ? 6 : 4,
                        xl: 4
                    }
                }
            ];

            return _action;
        },
        openMenu() {
            this.menu = !this.menu;
        },
        processTripSeaTransport(tripSeaTransportSet, _vessels, _infoCard, getDelay, formatDateTime, translate) {
            tripSeaTransportSet.edges.forEach(({ node }) => {
                let _portName = node.portName;
                let _portCode = node.portCode;
                let _portType = node.portTransportType;
                let _estimatedArrivalDate = node.estimatedArrivalDate;
                let _originalEstimatedArrivalDate = node.originalEstimatedArrivalDate;
                let _actualArrivalDate = node.actualArrivalDate;
                let _originalEstimatedDepartureDate = node.originalEstimatedDepartureDate;
                let _estimatedDepartureDate = node.estimatedDepartureDate;
                let _actualDepartureDate = node.actualDepartureDate;

                // Add vessel to _vessels array if not already present
                if (node.vessel && !_vessels.includes(node.vessel.name)) {
                    _vessels.push(node.vessel.name);
                }

                // Populate infoCard based on the port type
                const populateInfoCard = (index, delayType) => {
                    const { offset, color, text } = getDelay(node, delayType);
                    _infoCard[index].value = _portCode;
                    _infoCard[index].delay = offset;
                    _infoCard[index].delayColor = color;
                    _infoCard[index].tooltip = {
                        title: text,
                        titleColor: color,
                        headerOET: translate(
                            `trips.original_estimated_${delayType === "etd" ? "departure" : "arrival"}`
                        ),
                        OET: formatDateTime(
                            delayType === "etd" ? _originalEstimatedDepartureDate : _originalEstimatedArrivalDate
                        ),
                        headerET: translate(`trips.estimated_${delayType === "etd" ? "departure" : "arrival"}`),
                        ET: formatDateTime(delayType === "etd" ? _estimatedDepartureDate : _estimatedArrivalDate),
                        headerACT: translate(`trips.actual_${delayType === "etd" ? "departure" : "arrival"}`),
                        ACT: formatDateTime(delayType === "etd" ? _actualDepartureDate : _actualArrivalDate)
                    };
                };

                // Process based on port type
                if (_portType === "POL") {
                    populateInfoCard(0, "etd");
                } else if (_portType === "T/S") {
                    populateInfoCard(1, "etd");
                } else if (_portType === "POD") {
                    populateInfoCard(2, "eta");
                }
            });

            // If there are vessels, update the vessel info in the last card
            if (_vessels.length > 0) {
                _infoCard[3].value = _vessels;
            }
        },
        trackerSerie(freight) {
            let trackerSerie = [];
            let _additionalInfo = this.getExternalTrackerAdditionalInfo(freight.additionalInfo);
            let freightTrackers = freight?.tripFreightTrackerSet?.edges;
            let primary = this.$vuetify.theme.themes.light.primary;

            const formatDate = date => {
                return date ? this.formatDateTime(date) : "";
            };

            if (_additionalInfo) {
                trackerSerie.push({
                    serial: _additionalInfo.serialNumber,
                    model: _additionalInfo.type || "",
                    color: primary,
                    text: "External Tracker",
                    activatedDate: "",
                    lastConnectionTime: ""
                });
            }

            if (freightTrackers) {
                freightTrackers.forEach(element => {
                    if (element.node.tracker.activatedDate) {
                        trackerSerie.push({
                            serial: element.node.tracker.serialNumber,
                            model: element.node.tracker.model || "",
                            color: primary,
                            text: this.$t("general.activated"),
                            activatedDate: formatDate(element.node.tracker.activatedDate),
                            lastConnectionTime: formatDate(element.node.tracker.lastConnectionTime)
                        });
                    } else {
                        trackerSerie.push({
                            serial: element.node.tracker.serialNumber,
                            model: element.node.tracker.model || "",
                            color: "#FFCDD2",
                            text: this.$t("general.not_activated"),
                            activatedDate: "",
                            lastConnectionTime: ""
                        });
                    }
                });
                return trackerSerie;
            }
        },
        getDelay(item, type) {
            let actual = item?.actualArrivalDate;
            let offset = null;
            if (type === "eta") {
                offset = this.calculateDelaysInDay(
                    item?.originalEstimatedArrivalDate,
                    item?.estimatedArrivalDate,
                    item?.actualArrivalDate
                );
            } else if (type == "etd") {
                actual = item?.actualDepartureDate;
                offset = this.calculateDelaysInDay(
                    item?.originalEstimatedDepartureDate,
                    item?.estimatedDepartureDate,
                    item?.actualDepartureDate
                );
            }

            let event = offset < 0 ? "-" : "+";

            if (!offset) {
                return {
                    offset: "",
                    event: "",
                    color: "success--text",
                    text: ""
                };
            }

            let color = offset > 0 ? "error--text" : "success2--text";
            let text = offset === 0 ? "" : `${event} ${Math.abs(offset)}`;

            return {
                offset: offset,
                event: event,
                color: color,
                text: text
            };
        },
        isEmpty(value) {
            return value === null || value === undefined || value === "" || value === 0;
        },
        detailsPage(item) {
            this.$emit("click:row", item);
        },
        goToFreight(freight, trip) {
            this.$router.push({
                path: "/previewtrips",
                query: {
                    id: trip,
                    tab: "freights",
                    freight: freight
                }
            });
        },
        onShareTrip(id) {
            let url = window.location.href;
            let dummy = document.createElement("input"),
                text = url;
            document.body.appendChild(dummy);
            dummy.value = text + "?id=" + id;
            dummy.select();
            document.execCommand("copy");
            document.body.removeChild(dummy);
            const payload = {
                color: "blue",
                message: this.$t("general.link")
            };
            this.$store.dispatch("snackbar/showMessage", payload);
        },
        favoriteBtn(id, value) {
            this.$apollo
                .mutate({
                    mutation: gql`
                        mutation UpdateFavoriteTrip($input: UpdateFavoriteTripInput!) {
                            updateFavoriteTrip(input: $input) {
                                trip {
                                    id
                                    isUserFavorite
                                }
                            }
                        }
                    `,
                    variables: {
                        input: {
                            favorite: value,
                            tripId: id
                        }
                    }
                })
                .then(() => {
                    let message = value ? this.$t("general.save_favorite") : this.$t("general.remove_favorite");

                    const payload = {
                        color: "blue",
                        message: message
                    };
                    this.$store.dispatch("snackbar/showMessage", payload);
                });
        },
        getIcon(name) {
            let icon = "";
            if (name !== null) {
                switch (name) {
                    case "AIR":
                        icon = "flight";
                        break;
                    case "SEA":
                        icon = "directions_boat";
                        break;
                    case "ROAD":
                        icon = "local_shipping";
                        break;
                    case "RAIL":
                        icon = "directions_railway";
                        break;
                    default:
                        icon = "directions_boat";
                        break;
                }
            }
            return icon;
        },
        getOrigin(trip) {
            let _origin = null;
            if (trip?.originLocation) {
                if (trip?.originLocation?.city && trip?.originLocation?.city !== "")
                    _origin = `${trip?.originLocation?.city}`;

                if (!_origin) _origin = `${trip?.originLocation?.name}`;
            } else if (!trip?.originLocation && trip.transportMode?.toLowerCase() === "sea") {
                _origin = trip?.tripSeaTransportSet?.edges
                    .find(edge => edge?.node?.portTransportType == "POL")
                    ?.node?.portName?.toLowerCase();
            }

            return _origin;
        },
        getDestination(trip) {
            let _destination = null;
            if (trip?.destinationLocation) {
                if (trip?.destinationLocation?.city && trip?.destinationLocation?.city !== "")
                    _destination = `${trip?.destinationLocation?.city}`;

                if (!_destination) _destination = `${trip?.destinationLocation?.name}`;
            } else if (!trip?.destinationLocation && trip.transportMode.toLowerCase() === "sea") {
                _destination = trip?.tripSeaTransportSet?.edges
                    .find(edge => edge?.node?.portTransportType == "POD")
                    ?.node?.portName?.toLowerCase();
            }

            return _destination;
        },
        onResize() {
            this.$nextTick(() => {
                this.tableHeight = window.innerHeight - 180 + "px";
            });
        },
        formatDateTime(datetime) {
            return helper.formatDateTime(datetime);
        },
        isArray(value) {
            return Array.isArray(value);
        },
        onLoadMoreTriggerIntersect(entries) {
            this.shouldLoadMore = entries[0].isIntersecting;

            if (this.shouldLoadMore && this.trips?.pageInfo?.hasNextPage) {
                this.loadMore();
            }
        },
        loadMore() {
            if (this.trips?.pageInfo?.hasNextPage) {
                this.$apollo.queries.trips.fetchMore({
                    variables: {
                        after: this.trips.pageInfo.endCursor
                    }
                });
            }
        },
        getExternalTrackerAdditionalInfo(additionalInfo) {
            let additionalInfoToReturn = null;
            if (additionalInfo) {
                let additionalInfoObj = JSON.parse(additionalInfo);

                if (additionalInfoObj?.externalTracker) {
                    try {
                        let obj = JSON.parse(additionalInfoObj.externalTracker);
                        if (obj.hasOwnProperty("externalTracker")) additionalInfoToReturn = obj?.externalTracker;
                        else additionalInfoToReturn = obj;
                    } catch (e) {
                        additionalInfoToReturn = additionalInfoObj?.externalTracker;
                    }
                }
            }
            return additionalInfoToReturn;
        },
        renderEmptySubtitle() {
            // Check which tab is currently active and return appropriate subtitle
            switch (this.tab) {
                case "favorite":
                    return this.$t("general.empty_data_subtitle_favorite");
                case "shared":
                    return this.$t("general.empty_data_subtitle_shared");
                default:
                    return this.$t("general.empty_data_subtitle_default");
            }
        },
        onIdSelected(trip, number) {

            // Clone the trip and Parse it to JSON to avoid modifying
            let _currentTrip = JSON.parse(JSON.stringify(trip));

            // Filter tripFreightSet to only include the one with matching referenceNumber...etc
            let filteredFreightSet = _currentTrip?.node?.tripFreightSet?.edges?.filter(freight => {
                return (
                    freight.node.id === number
                );
            });

            //populate the current filteredFreightSet
            if (filteredFreightSet.length > 0) {
                _currentTrip.node.tripFreightSet.edges = [...filteredFreightSet];
            }

            this.tripObject = { ..._currentTrip };
            this.freightObj = { ...filteredFreightSet[0] };
            this.freightDialog = true;
        },
        textTruncate(value) {
            return value.length > 20 ? value.substring(0, 20) : value;
        }
    }
};
</script>
<style scoped lang="css">
.row-pointer >>> tbody tr :hover {
    cursor: pointer;
}
.center-loading {
    position: sticky;
    left: 50% !important;
}
.textTruncate {
    overflow: hidden;
    text-overflow: ellipsis;
    white-space: nowrap;
}
.classMainContent {
    padding-left: 4px !important;
    padding-right: 4px !important;
    margin-top: 1px !important;
    margin-bottom: 16px !important;
}
.classSecondaryContent {
    padding-left: 40px !important;
    padding-right: 40px !important;
    margin-top: 10px !important;
    margin-bottom: 10px !important;
}

.secondaryRowSize {
    width: 100% !important;
}

.classHeader {
    color: #555464 !important;
    font-size: 12px;
    font-weight: 700;
    line-height: 13px;
    letter-spacing: 0em;
    text-align: left;
    margin-top: 3px;
}

.class-text-tooltip {
    /* color: #0e0d35 !important; */
    white-space: nowrap;
    overflow: hidden;
    text-overflow: ellipsis;
    font-size: 12px;
    font-weight: 700;
    line-height: 13px;
    letter-spacing: 0em;
    text-align: left;
    margin-top: 3px;
}

.classText {
    color: #0e0d35 !important;
    font-size: 12px !important;
    font-weight: 700;
    line-height: 13px;
    letter-spacing: 0em;
    text-align: left;
    margin-top: 3px;
}

.textTitle {
    font-size: 32px !important;
    color: "#0e1e46" !important;
    font-weight: bold;
    word-break: normal !important;
    line-height: inherit !important;
    text-align: center !important;
}
.textSubtitle {
    font-size: 13px !important;
    color: "#868599" !important;
    height: 23px !important;
    font-weight: bold;
    white-space: nowrap;
    overflow: hidden;
    text-overflow: ellipsis;
}

.outlineD {
    border: thin solid rgba(0, 0, 0, 0.12) !important;
    padding: 10px;
}
.outlinedSide {
    border-left: thin solid rgba(0, 0, 0, 0.12) !important;
    border-right: thin solid rgba(0, 0, 0, 0.12) !important;
}

.backgroundColor {
    background-color: #f9fafe !important;
}

.textLeft {
    text-align: left !important;
}

.tabItemCl {
    background-color: var(--v-background-base);
}

.disabledItem {
    pointer-events: none;
    opacity: 0.4;
}

.classHeader2 {
    color: #fff !important;
    white-space: nowrap;
    overflow: hidden;
    text-overflow: ellipsis;
    font-size: 12px;
    font-weight: 400;
    line-height: 14px;
    letter-spacing: 0em;
    text-align: left;
}

.classText2 {
    color: #fff !important;
    white-space: nowrap;
    overflow: hidden;
    text-overflow: ellipsis;
    font-size: 12px;
    font-weight: 700;
    line-height: 13px;
    letter-spacing: 0em;
    text-align: left;
    margin-top: 3px;
}

.buttomDetails {
    font-size: 17px;
    font-weight: 500;
    line-height: 20.4px;
    text-align: center;
}

.material-symbols-outlined {
    font-variation-settings: "FILL" 0, "wght" 400, "GRAD" 0, "opsz" 24;
}
.table-column {
    flex-basis: 14.2%;
    max-width: 14.2%;
    text-align: left; /* Aligns text consistently */
    padding: 8px; /* Add padding for readability */
}

.v-simple-table {
    width: 100%; /* Ensure the tables take the full width */
    table-layout: fixed; /* Prevent columns from resizing dynamically */
}

::v-deep .v-data-table {
    border-collapse: collapse; /* Ensure no gaps between cells */
    table-layout: fixed; /* Consistent column width */
}

::v-deep .v-data-table thead tr th,
::v-deep .v-data-table tbody tr td {
    border: none !important; /* Remove borders for cleaner look */
    text-align: left; /* Align text consistently */
}

.divider-row {
    position: relative;
    display: flex;
    align-items: center;
    justify-content: flex-end;
}

.divider-row .v-divider {
    position: absolute;
    width: 100%;
}

.expand-btn {
    position: relative;
    z-index: 1;
    background-color: white !important;
}
</style>
