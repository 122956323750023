import { render, staticRenderFns } from "./SettingsDetails.vue?vue&type=template&id=9325ce5a&scoped=true&"
import script from "./SettingsDetails.vue?vue&type=script&lang=js&"
export * from "./SettingsDetails.vue?vue&type=script&lang=js&"
import style0 from "./SettingsDetails.vue?vue&type=style&index=0&id=9325ce5a&scoped=true&lang=css&"


/* normalize component */
import normalizer from "!../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "9325ce5a",
  null
  
)

export default component.exports