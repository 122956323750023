<template>
    <v-card-text>
        <v-row class="d-flex justify-center py-1" flat>
            <v-col
                v-if="type.toLowerCase() === 'carrier'"
                class="d-flex justify-left align-left"
                :style="actionCard(partner).style"
                :cols="actionCard(partner).cols.xs"
                :xs="actionCard(partner).cols.xs"
                :sm="actionCard(partner).cols.sm"
                :md="actionCard(partner).cols.md"
                :lg="actionCard(partner).cols.lg"
                :xl="actionCard(partner).cols.xl"
            >
                <v-icon
                    v-if="actionCard(partner).icon"
                    class="mr-1"
                    :size="actionCard(partner).iconSize"
                    :color="actionCard(partner).colorIcon"
                    >{{ actionCard(partner).icon }}</v-icon
                >
            </v-col>
            <v-col>
                <v-row dense>
                    <v-col
                        v-for="item in mainCard(partner)"
                        :key="item.text"
                        :cols="item.cols.xs"
                        :xs="item.cols.xs"
                        :sm="item.cols.sm"
                        :md="item.cols.md"
                        :lg="item.cols.lg"
                        :xl="item.cols.xl"
                    >
                        <div class="text-uppercase user-select-text">
                            <div class="secondaryText font-weight-thin text-truncate">
                                {{ item.title }}
                            </div>
                            <div class="primaryText font-weight-bold text-truncate" :style="item.style">
                                <v-icon
                                    v-if="item.icon"
                                    class="mr-1"
                                    :size="item.iconSize"
                                    :color="item.colorIcon"
                                    >{{ item.icon }}</v-icon
                                >
                                <span v-if="item.value || item.value > -1">
                                    {{ item.value }}
                                </span>
                            </div>
                        </div>
                    </v-col>
                    <v-col
                        cols="12"
                        xs="12"
                        sm="6"
                        :md="!isCarrier ? 3 : 9"
                        :lg="!isCarrier ? 3 : 3"
                        :xl="!isCarrier ? 3 : 3"
                        
                    >
                        <v-row>
                            <v-col
                                v-for="item in secondaryCard(partner)"
                                :key="item.text"
                                :cols="item.cols.xs"
                                :xs="item.cols.xs"
                                :sm="item.cols.sm"
                                :md="item.cols.md"
                                :lg="item.cols.lg"
                                :xl="item.cols.xl"
                            >
                                <div class="text-uppercase user-select-text">
                                    <div class="secondaryText font-weight-thin text-no-wrap">
                                        {{ item.title }}
                                    </div>
                                    <div class="primaryText font-weight-bold text-truncate" :style="item.style">
                                        <v-icon
                                            v-if="item.icon"
                                            class="mr-1"
                                            :size="item.iconSize"
                                            :color="item.colorIcon"
                                            >{{ item.icon }}</v-icon
                                        >
                                        <span v-if="item.value || item.value > -1">
                                            {{ item.value }}
                                        </span>
                                    </div>
                                </div>
                            </v-col>
                        </v-row>
                    </v-col>
                </v-row>
            </v-col>
        </v-row>
    </v-card-text>
</template>
<script>
import helper from "@/utils/helper";

export default {
    name: "PartnerHeaderTemplate",
    components: {
    },
    props: {
        partner: {
            type: Object,
            required: true
        },
        type: {
            type: String,
            required: true
        }
    },
    data() {
        return {
                
        }
    },
    created() {
        this.formatDateTime = helper.formatDateTime;
        this.hasPermission = helper.hasPermission;
    },
    computed: {
        me() {
            return helper.me();
        },
        isCarrier() {
            return this.type?.toLowerCase() === "carrier";
        },
    },
    methods: {
        mainCard(item) {
            const { referenceNumber, name, carrier, notificationEnabled, description } = item.node;
            let containerStyle = `
                    background-color: ${this.statusColor(notificationEnabled).color1};
                    border-radius: 4px;
                    width: 80px;
                    display: flex;
                    justify-content: center;
                    align-items: center;
            `;

            let _mainCard = [
                {
                    title: this.$t("headers.reference_number"),
                    tag: "referenceNumber",
                    icon: null,
                    colorIcon: null,
                    style: "",
                    align: "left",
                    value: referenceNumber,
                    cols: {
                        xs: "12",
                        sm: "6",
                        md: !this.isCarrier ? "3" : "3",
                        lg: !this.isCarrier ? "2" : "2",
                        xl: !this.isCarrier ? "2" : "2"
                    }
                },
                {
                    title: this.$t("headers.name"),
                    tag: "name",
                    icon: null,
                    colorIcon: null,
                    style: "",
                    align: "left",
                    value: name,
                    cols: {
                        xs: "12",
                        sm: "6",
                        md: !this.isCarrier ? "3" : "2",
                        lg: !this.isCarrier ? "2" : "2",
                        xl: !this.isCarrier ? "2" : "2"
                    }
                },
                {
                    title: this.$t("headers.description"),
                    tag: "description",
                    icon: null,
                    colorIcon: null,
                    style: "",
                    align: "left",
                    value: description,
                    cols: {
                        xs: "12",
                        sm: "6",
                        md: !this.isCarrier ? "3" : "2",
                        lg: !this.isCarrier ? "3" : "2",
                        xl: !this.isCarrier ? "3" : "2"
                    }
                },
                {
                    title: this.$t("headers.notifications"),
                    tag: "notifications",
                    color: "primary",
                    icon: "circle",
                    iconSize: "8",
                    colorIcon: this.statusColor(notificationEnabled).color2,
                    style: containerStyle,
                    align: "center",
                    value: this.statusText(notificationEnabled),
                    cols: {
                        xs: "12",
                        sm: "6",
                        md: !this.isCarrier ? "2" : this.isSmallScreen ? "3" : "3",
                        lg: !this.isCarrier ? "2" : "1",
                        xl: !this.isCarrier ? "2" : "1"
                    }
                }
            ];

            if (this.type?.toLowerCase() === "carrier") {
                _mainCard.splice(2, 0, {
                    //add standard carrier
                    title: this.$t("partners.carrier_name"),
                    tag: "carrierName",
                    icon: null,
                    colorIcon: null,
                    style: "",
                    align: "left",
                    value: carrier?.name,
                    cols: {
                        xs: "12",
                        sm: "6",
                        md: "3",
                        lg: "2",
                        xl: "2"
                    }
                });
                let icon = null;
            }

            return _mainCard;
        },
        secondaryCard(item) {
            const { openTrips, totalTrips, notificationEnabled, totalUsers } = item.node;

            let containerStyle = `
                    background-color: ${this.statusColor(notificationEnabled).color1};
                    border-radius: 4px;
                    width: 70px;
                    display: flex;
                    justify-content: center;
                    align-items: center;
            `;

            let _secondaryCard = [
                {
                    title: this.$t("headers.open_trips1"),
                    tag: "open_trips",
                    icon: null,
                    colorIcon: null,
                    style: "",
                    align: "left",
                    value: openTrips,
                    cols: {
                        xs: "4",
                        sm: "4",
                        md: "4",
                        lg: "4",
                        xl: "4"
                    }
                },
                {
                    title: this.$t("headers.total_trips"),
                    tag: "total_trips",
                    icon: null,
                    colorIcon: null,
                    style: "",
                    align: "left",
                    value: totalTrips,
                    cols: {
                        xs: "4",
                        sm: "4",
                        md: "4",
                        lg: "4",
                        xl: "4"
                    }
                },
                {
                    title: this.$t("headers.users"),
                    tag: "users",
                    icon: null,
                    colorIcon: null,
                    style: "",
                    align: "right",
                    value: totalUsers,
                    cols: {
                        xs: "4",
                        sm: "4",
                        md: "4",
                        lg: "4",
                        xl: "4"
                    }
                }
            ];

            return _secondaryCard;
        },
        actionCard(item) {
            let icon = null;
            switch (item.node?.carrier?.carrierType.toLowerCase()) {
                case "sea":
                    icon = "directions_boat";
                    break;
                case "air":
                    icon = "flight";
                    break;
                case "road":
                    icon = "local_shipping";
                    break;
                default:
                    icon = "local_shipping";
                    break;
            }

            return {
                title: "",
                tag: null,
                icon: icon,
                iconZise: "18",
                colorIcon: null,
                style: "display: flex; align-items: center;",
                align: "left",
                value: null,
                cols: {
                    xs: "12",
                    sm: "12",
                    md: "12",
                    lg: "auto",
                    xl: "auto"
                }
            };
        },
        statusColor(status) {
            if (status) {
                return {
                    color1: "#C8E6C9",
                    color2: "#1B5E20"
                };
            } else {
                return {
                    color1: "#FFCDD2",
                    color2: "#F44336"
                };
            }
        },
        statusText(status) {
            if (status) {
                return this.$t("headers.enabled");
            } else {
                return this.$t("headers.disabled");
            }
        }
    }
}
</script>
<style scoped>
.secondaryText {
    color: #555464 !important;
    font-size: 12px;
    line-height: 14px;
    letter-spacing: 0em;
}

.primaryText {
    color: #0e0d35 !important;
    font-size: 12px;
    line-height: 13px;
    letter-spacing: 0em;
    margin-top: 3px;
}
.textLeft {
text-align: left !important;
}
</style>