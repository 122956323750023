<template>
    <v-row align="start" class="align-center" dense>
        <v-col cols="12" xs="12" sm="12" md="12" lg="12" xl="12">
            <v-select
                v-model="codeSelected"
                :items="dynamicFilters"
                :label="$t('trips.filter')"
                item-text="name"
                item-value="code"
                clearable
                :readonly="isReadOnly"
                :disabled="isReadOnly"
                dense
                outlined
                color="primary"
                hide-details
                :menu-props="{
                    offsetX: true,
                    right: true
                }"
                @change="updateCodeSelected"
            ></v-select>
        </v-col>
        <v-col cols="12" xs="12" sm="12" md="12" lg="12" xl="12">
            <!-- ?Fake field -->
            <v-text-field
                v-if="codeSelected === '' || codeSelected === null"
                v-model="menuObject['']"
                :label="getFilterChoiceNameByCode('')"
                clearable
                hide-details
                dense
                outlined
                disabled
            ></v-text-field>

            <!-- Serial Number -->
            <v-text-field
                v-if="codeSelected === 'serialNumber'"
                v-model="menuObject['serialNumber_Icontains']"
                :label="getFilterChoiceNameByCode('serialNumber')"
                clearable
                hide-details
                dense
                outlined
            ></v-text-field>

            <!-- Brand -->
            <v-select
                v-if="codeSelected === 'brand'"
                v-model="menuObject['brand_Icontains']"
                :label="getFilterChoiceNameByCode('brand')"
                :items="brandsChoices"
                clearable
                hide-details
                dense
                outlined
            ></v-select>

            <!-- Package Number -->
            <v-text-field
                v-if="codeSelected === 'packageNumber'"
                v-model="menuObject['packageNumber_Icontains']"
                :label="getFilterChoiceNameByCode('packageNumber')"
                clearable
                hide-details
                dense
                outlined
            ></v-text-field>

            <!-- Comment -->
            <v-text-field
                v-if="codeSelected === 'comment'"
                v-model="menuObject['comment_Icontains']"
                :label="getFilterChoiceNameByCode('comment')"
                clearable
                hide-details
                dense
                outlined
            ></v-text-field>

            <!-- Last Connection -->
            <v-menu
                v-if="codeSelected === 'lastConnectionTime'"
                v-model="lastConnectionMenu"
                :close-on-content-click="false"
                :return-value.sync="savedLastConnectionRange"
                transition="scale-transition"
                offset-y
                max-width="auto"
                min-width="auto"
            >
                <template v-slot:activator="{ on, attrs }">
                    <v-text-field
                        :value="lastConnectionRangeText"
                        :label="getFilterChoiceNameByCode('lastConnectionTime')"
                        readonly
                        v-bind="attrs"
                        v-on="on"
                        clearable
                        hide-details
                        dense
                        outlined
                        @click:clear="clearDateRange('lastConnectionTime')"
                    ></v-text-field>
                </template>
                <v-date-picker
                    v-model="lastConnectionRange"
                    range
                    no-title
                    scrollable
                    @input="saveDateRange('lastConnectionTime')"
                >
                </v-date-picker>
            </v-menu>

            <!-- Expiry Date -->
            <v-menu
                v-if="codeSelected === 'expiryDate'"
                v-model="expiryDateMenu"
                :close-on-content-click="false"
                :return-value.sync="savedExpiryDateRange"
                transition="scale-transition"
                offset-y
                max-width="auto"
                min-width="auto"
            >
                <template v-slot:activator="{ on, attrs }">
                    <v-text-field
                        :value="expiryDateRangeText"
                        :label="getFilterChoiceNameByCode('expiryDate')"
                        readonly
                        v-bind="attrs"
                        v-on="on"
                        clearable
                        hide-details
                        dense
                        outlined
                        @click:clear="clearDateRange('expiryDate')"
                    ></v-text-field>
                </template>
                <v-date-picker
                    v-model="expiryDateRange"
                    range
                    no-title
                    scrollable
                    @input="saveDateRange('expiryDate')"
                >
                </v-date-picker>
            </v-menu>

            <!-- Activated Date -->
            <v-menu
                v-if="codeSelected === 'activatedDate'"
                v-model="activatedDateMenu"
                :close-on-content-click="false"
                :return-value.sync="savedActivatedDateRange"
                transition="scale-transition"
                offset-y
                max-width="auto"
                min-width="auto"
            >
                <template v-slot:activator="{ on, attrs }">
                    <v-text-field
                        :value="activatedDateRangeText"
                        :label="getFilterChoiceNameByCode('activatedDate')"
                        readonly
                        v-bind="attrs"
                        v-on="on"
                        clearable
                        hide-details
                        dense
                        outlined
                        @click:clear="clearDateRange('activatedDate')"
                    ></v-text-field>
                </template>
                <v-date-picker
                    v-model="activatedDateRange"
                    range
                    no-title
                    scrollable
                    @input="saveDateRange('activatedDate')"
                >
                </v-date-picker>
            </v-menu>

            <!-- Trip Reference Number -->
            <v-text-field
                v-if="codeSelected === 'trip_referenceNumber'"
                v-model="menuObject['tripReference']"
                :label="getFilterChoiceNameByCode('trip_referenceNumber')"
                clearable
                hide-details
                dense
                outlined
            ></v-text-field>

            <!-- Status -->
            <v-select
                v-if="codeSelected === 'status'"
                v-model="menuObject['status']"
                :items="statusChoices"
                :label="getFilterChoiceNameByCode('status')"
                item-text="text"
                item-value="value"
                clearable
                hide-details
                dense
                outlined
                color="primary"
            ></v-select>

            <!-- Beacon Tracker -->
            <v-select
                v-if="codeSelected === 'beaconTracker'"
                v-model="menuObject['isBeaconTracker']"
                :items="beaconTrackerChoices"
                :label="getFilterChoiceNameByCode('beaconTracker')"
                item-text="text"
                item-value="value"
                clearable
                hide-details
                dense
                outlined
                color="primary"
            ></v-select>

            <!-- Archived -->
            <v-select
                v-if="codeSelected === 'archived'"
                v-model="menuObject['isArchived']"
                :items="archivedChoices"
                :label="getFilterChoiceNameByCode('archived')"
                item-text="text"
                item-value="value"
                clearable
                hide-details
                dense
                outlined
                color="primary"
            ></v-select>
        </v-col>
    </v-row>
</template>

<script>
import helper from "@/utils/helper.js";
import moment from "moment";
import gql from "graphql-tag";

export default {
    name: "TrackersFilterMenuTemplate",
    components: {
    },
    props: {
        value: {
            type: Object,
            required: true,
            default: () => ({})
        },
        isReadOnly: {
            type: Boolean,
            required: false,
            default: false
        },
        currentCode: {
            type: String,
            required: false,
            default: null
        },
        isSharedTrip: {
            type: Boolean,
            required: false,
            default: false
        }
    },
    data() {
        return {
            codeSelected: null,
            lastConnectionMenu: false,
            lastConnectionRange: [],
            savedLastConnectionRange: [],
            expiryDateMenu: false,
            expiryDateRange: [],
            savedExpiryDateRange: [],
            activatedDateMenu: false,
            activatedDateRange: [],
            savedActivatedDateRange: [],
            brandsChoices: [
                { text: "Sensitech", value: "Sensitech" },
                { text: "Tive", value: "Tive" },
                { text: "DeltaTrak", value: "DeltaTrak" },
                { text: "Copeland(Emerson)", value: "Copeland" },
                { text: "Frigga", value: "Frigga" }
            ],
            defaultFilterChoices: [
                {
                    code: "serialNumber",
                    name: "Serial Number",
                    tag: "headers.serial_number",
                    enabled: true
                },
                {
                    code: "brand",
                    name: "Brand",
                    tag: "headers.brand",
                    enabled: true
                },
                {
                    code: "packageNumber",
                    name: "Package Number",
                    tag: "headers.package_number",
                    enabled: true
                },
                {
                    code: "comment",
                    name: "Comment",
                    tag: "headers.comment",
                    enabled: true
                },
                {
                    code: "lastConnectionTime",
                    name: "Last Connection",
                    tag: "headers.last_connection",
                    enabled: true
                },
                {
                    code: "expiryDate",
                    name: "Expiry Date",
                    tag: "headers.expiry_date",
                    enabled: true
                },
                {
                    code: "activatedDate",
                    name: "Activated Date",
                    tag: "headers.activated_date",
                    enabled: true
                },
                {
                    code: "trip_referenceNumber",
                    name: "Trip Reference Number",
                    tag: "headers.trip_reference_number",
                    enabled: true
                },
                {
                    code: "archived",
                    name: "Archived",
                    tag: "headers.archived",
                    enabled: true
                },
                {
                    code: "beaconTracker",
                    name: "Beacon Tracker",
                    tag: "headers.beacon_tracker",
                    enabled: true
                },
                {
                    code: "status",
                    name: "Status",
                    tag: "headers.status",
                    enabled: true
                }
            ]
        };
    },
    computed: {
        menuObject: {
            get() {
                return this.value || {};
            },
            set(val) {
                this.$emit("input", val);
            }
        },
        dynamicFilters() {
            return this.defaultFilterChoices
                .filter(filter => filter.enabled)
                .sort((a, b) => a.name.localeCompare(b.name));
        },
        archivedChoices() {
            return [
                { text: this.$t("general.yes"), value: true },
                { text: this.$t("general.no"), value: false }
            ];
        },
        isStaff() {
            return helper.me()?.isStaff || false;
        },
        beaconTrackerChoices() {
            return [
                { text: this.$t("general.yes"), value: true },
                { text: this.$t("general.no"), value: false }
            ];
        },
        statusChoices() {
            return [
                { text: this.$t("general.active"), value: 'active' },
                { text: this.$t("general.inactive"), value: 'inactive' }
            ];
        },
        activatedDateRangeText() {
            if (this.activatedDateRange.length > 1) {
                let date1 = moment(this.activatedDateRange[0], "YYYY-MM-DD").format(helper.getDateFormat());
                let date2 = moment(this.activatedDateRange[1], "YYYY-MM-DD").format(helper.getDateFormat());
                return date1 + " ~ " + date2;
            } else if (this.activatedDateRange.length === 1) {
                return moment(this.activatedDateRange[0], "YYYY-MM-DD").format(helper.getDateFormat());
            } else {
                return this.activatedDateRange;
            }
        },
        lastConnectionRangeText() {
            if (this.lastConnectionRange.length > 1) {
                let date1 = moment(this.lastConnectionRange[0], "YYYY-MM-DD").format(helper.getDateFormat());
                let date2 = moment(this.lastConnectionRange[1], "YYYY-MM-DD").format(helper.getDateFormat());
                return date1 + " ~ " + date2;
            } else if (this.lastConnectionRange.length === 1) {
                return moment(this.lastConnectionRange[0], "YYYY-MM-DD").format(helper.getDateFormat());
            } else {
                return this.lastConnectionRange;
            }
        },
        expiryDateRangeText() {
            if (this.expiryDateRange.length > 1) {
                let date1 = moment(this.expiryDateRange[0], "YYYY-MM-DD").format(helper.getDateFormat());
                let date2 = moment(this.expiryDateRange[1], "YYYY-MM-DD").format(helper.getDateFormat());
                return date1 + " ~ " + date2;
            } else if (this.expiryDateRange.length === 1) {
                return moment(this.expiryDateRange[0], "YYYY-MM-DD").format(helper.getDateFormat());
            } else {
                return this.expiryDateRange;
            }
        }
    },
    watch: {
        currentCode: {
            handler(val) {
                this.codeSelected = val;
            },
            immediate: true
        },
        value: {
            handler(val) {
                if (val.lastConnectionTime_Gte && val.lastConnectionTime_Lte) {
                    this.lastConnectionRange.push(val.lastConnectionTime_Gte.split("T")[0]);
                    this.lastConnectionRange.push(val.lastConnectionTime_Lte.split("T")[0]);
                } else {
                    this.lastConnectionRange = [];
                }
                if (val.expiryDate_Gte && val.expiryDate_Lte) {
                    this.expiryDateRange.push(val.expiryDate_Gte.split("T")[0]);
                    this.expiryDateRange.push(val.expiryDate_Lte.split("T")[0]);
                } else {
                    this.expiryDateRange = [];
                }
                if (val.activatedDate_Gte && val.activatedDate_Lte) {
                    this.activatedDateRange.push(val.activatedDate_Gte.split("T")[0]);
                    this.activatedDateRange.push(val.activatedDate_Lte.split("T")[0]);
                } else {
                    this.activatedDateRange = [];
                }
            },
            deep: true,
            immediate: true
        }
    },
    methods: {
        getFilterChoiceNameByCode(code) {
            return "Value";
        },
        updateCodeSelected(val) {
            this.$emit("update:currentCode", val);
        },
        clearDateRange(type) {
            switch(type) {
                case 'lastConnectionTime':
                    this.lastConnectionRange = [];
                    this.savedLastConnectionRange = [];
                    this.menuObject['lastConnectionTime_Gte'] = null;
                    this.menuObject['lastConnectionTime_Lte'] = null;
                    break;
                case 'expiryDate':
                    this.expiryDateRange = [];
                    this.savedExpiryDateRange = [];
                    this.menuObject['expiryDate_Gte'] = null;
                    this.menuObject['expiryDate_Lte'] = null;
                    break;
                case 'activatedDate':
                    this.activatedDateRange = [];
                    this.savedActivatedDateRange = [];
                    this.menuObject['activatedDate_Gte'] = null;
                    this.menuObject['activatedDate_Lte'] = null;
                    break;
            }
        },
        saveDateRange(type) {
            switch(type) {
                case 'lastConnectionTime':
                    if (this.lastConnectionRange.length === 2) {
                        this.savedLastConnectionRange = this.lastConnectionRange;
                        this.menuObject['lastConnectionTime_Gte'] = `${this.lastConnectionRange[0]}T00:00:00`;
                        this.menuObject['lastConnectionTime_Lte'] = `${this.lastConnectionRange[1]}T23:59:59`;
                        this.lastConnectionMenu = false;
                    }
                    break;
                case 'expiryDate':
                    if (this.expiryDateRange.length === 2) {
                        this.savedExpiryDateRange = this.expiryDateRange;
                        this.menuObject['expiryDate_Gte'] = `${this.expiryDateRange[0]}T00:00:00`;
                        this.menuObject['expiryDate_Lte'] = `${this.expiryDateRange[1]}T23:59:59`;
                        this.expiryDateMenu = false;
                    }
                    break;
                case 'activatedDate':
                    if (this.activatedDateRange.length === 2) {
                        this.savedActivatedDateRange = this.activatedDateRange;
                        this.menuObject['activatedDate_Gte'] = `${this.activatedDateRange[0]}T00:00:00`;
                        this.menuObject['activatedDate_Lte'] = `${this.activatedDateRange[1]}T23:59:59`;
                        this.activatedDateMenu = false;
                    }
                    break;
            }
        }
    }
};
</script>
