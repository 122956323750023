<template>
    <v-container fluid class="px-0">
        <v-card flat color="transparent" height="350">
            <apexcharts ref="overviewChart" type="line" height="100%" :options="options" :series="series"></apexcharts>
        </v-card>
    </v-container>
</template>
<script>
import VueApexCharts from "vue-apexcharts";
import helper from "@/utils/helper.js";
import moment from "moment";

export default {
    props: {
        transportMode: {
            type: String
        },
        startTime: {
            type: String
        },
        endTime: {
            type: String
        },
        eventsLocations: {
            type: Array,
            default: () => []
        },
        currentStartTime: {
            type: String
        },
        currentEndTime: {
            type: String
        },
        temperatureSeries: {
            type: Array,
            default: () => []
        },
        lightSeries: {
            type: Array,
            default: () => []
        },
        humiditySeries: {
            type: Array,
            default: () => []
        },
        shockSeries: {
            type: Array,
            default: () => []
        },
        trackerTemperatures: {
            type: Array,
            default: () => []
        },
        selected: {
            type: Array,
            default: () => []
        },
        probeTemperaturesSeries: {
            type: Array,
            default: () => []
        },
        externalTemperaturesSeries: {
            type: Array,
            default: () => []
        },
        alertTrigger: {
            type: Array,
            default: () => []
        },
        tripAlert: {
            type: Array,
            default: () => []
        },
        minTemperature: {
            type: Number,
            default: 0
        },
        maxTemperature: {
            type: Number,
            default: 100
        },
        minHumidity: {
            type: Number,
            default: 0
        },
        maxHumidity: {
            type: Number,
            default: 100
        },
        minLight: {
            type: Number,
            default: 0
        },
        maxLight: {
            type: Number,
            default: 100
        },
        minShock: {
            type: Number,
            default: -3
        },
        maxShock: {
            type: Number,
            default: 40
        }
    },
    components: {
        apexcharts: VueApexCharts
    },
    data() {
        return {
            warning: require("@/assets/warning2.png"),
            directionsBoat: require("@/assets/directions_boat.png"),
            flightTakeoff: require("@/assets/flight_takeoff.png"),
            flightLand: require("@/assets/flight_land.png"),
            playArrow: require("@/assets/play_arrow.png"),
            login: require("@/assets/login.png"),
            logout: require("@/assets/logout.png"),
            flag: require("@/assets/flag.png"),
            sportsScore: require("@/assets/sports_score.png")
        };
    },
    computed: {
        temperatureSerie() {
            return this.temperatureSeries || [];
        },
        lightSerie() {
            return this.lightSeries || [];
        },
        humiditySerie() {
            return this.humiditySeries || [];
        },
        shockSerie() {
            return this.shockSeries || [];
        },
        probeTemperaturesSerie() {
            return this.probeTemperaturesSeries || [];
        },
        externalTemperaturesSerie() {
            return this.externalTemperaturesSeries || [];
        },
        sensorSettings() {
            let temperatureSymbol = this.getTemperatureSymbol();
            let _sensors = [
                {
                    text: this.$t("general.temperature"),
                    value: "temperature",
                    serieColor: "#1B6A99FF",
                    strokeColorOne: "#1B6A99FF",
                    series: [
                        {
                            name: this.$t("general.temperature"),
                            data: this.temperatureSerie
                        }
                    ],
                    yaxis: [
                        {
                            min: this.minTemperature,
                            max: this.maxTemperature,
                            seriesName: this.$t("general.temperature"),
                            opposite: false,
                            title: {
                                text: this.$t("general.temperature") + ` (${temperatureSymbol})`,
                                style: {
                                    color: "#1B6A99FF"
                                }
                            },
                            labels: {
                                formatter: function(value) {
                                    return value ? value?.toFixed(0) : "";
                                },
                                style: {
                                    colors: "#1B6A99FF"
                                }
                            },
                            axisBorder: {
                                show: true,
                                color: "#1B6A99FF"
                            }
                        }
                    ]
                },
                {
                    text: this.$t("general.humidity"),
                    value: "humidity",
                    serieColor: "#07A98BFF",
                    strokeColorOne: "#07A98BFF",
                    series: [
                        {
                            name: this.$t("general.humidity"),
                            data: this.humiditySerie
                        }
                    ],
                    yaxis: [
                        {
                            seriesName: this.$t("general.humidity"),
                            min: this.minHumidity,
                            max: this.maxHumidity,
                            title: {
                                text: this.$t("general.humidity") + " (%)",
                                style: {
                                    color: "#07A98BFF"
                                }
                            },
                            labels: {
                                formatter: function(value) {
                                    return value ? value?.toFixed(0) : "";
                                },
                                style: {
                                    colors: "#07A98BFF"
                                }
                            },
                            axisBorder: {
                                show: true,
                                color: "#07A98BFF"
                            }
                        }
                    ],
                    annotations: {
                        yaxis: []
                    }
                },
                {
                    text: this.$t("general.light"),
                    value: "light",
                    serieColor: "#DEBA04FF",
                    strokeColorOne: "#DEBA04FF",
                    series: [
                        {
                            name: this.$t("general.light"),
                            data: this.lightSerie
                        }
                    ],
                    yaxis: [
                        {
                            seriesName: this.$t("general.light"),
                            min: this.minLight,
                            max: this.maxLight,
                            opposite: false,
                            title: {
                                text: this.$t("general.light") + " (Lux)",
                                offsetX: this.selected[0] === "light" ? 10 : -10,
                                style: {
                                    color: "#DEBA04FF"
                                }
                            },
                            labels: {
                                formatter: function(value) {
                                    return value ? value?.toFixed(0) : "";
                                },
                                style: {
                                    colors: "#DEBA04FF"
                                }
                            },
                            axisBorder: {
                                show: true,
                                color: "#DEBA04FF"
                            }
                        }
                    ]
                },
                {
                    text: this.$t("general.shock"),
                    value: "shock",
                    serieColor: "#FF0000FF",
                    strokeColorOne: "#FF0000FF",
                    series: [
                        {
                            name: this.$t("general.shock"),
                            data: this.shockSerie
                        }
                    ],
                    yaxis: [
                        {
                            min: this.minShock,
                            max: this.maxShock,
                            seriesName: this.$t("general.shock"),
                            opposite: false,
                            title: {
                                text: this.$t("general.shock") + " (G)",
                                offsetX: this.selected[0] === "shock" ? 14 : -14,
                                style: {
                                    color: "#FF0000FF"
                                }
                            },
                            labels: {
                                formatter: function(value) {
                                    return value ? value?.toFixed(0) : "";
                                }.bind(this),
                                style: {
                                    colors: "#FF0000FF"
                                }
                            },
                            axisBorder: {
                                show: true,
                                color: "#FF0000FF"
                            }
                        }
                    ]
                },
                {
                    text: this.$t("general.probe_temperature"),
                    value: "probeTemperature",
                    serieColor: "#71C4ECFF",
                    strokeColorOne: "#71C4ECFF",
                    series: [
                        {
                            name: this.$t("general.probe_temperature"),
                            data: this.probeTemperaturesSerie
                        }
                    ],
                    yaxis: [
                        {
                            seriesName: this.$t("general.probe_temperature"),
                            min: this.minTemperature,
                            max: this.maxTemperature,
                            title: {
                                text: this.$t("general.probe_temperature") + ` (${temperatureSymbol})`,
                                style: {
                                    color: "#71C4ECFF"
                                }
                            },
                            labels: {
                                formatter: function(value) {
                                    return value ? value?.toFixed(0) : "";
                                }.bind(this),
                                style: {
                                    colors: "#71C4ECFF"
                                }
                            },
                            axisBorder: {
                                show: true,
                                color: "#71C4ECFF"
                            }
                        }
                    ]
                },
                {
                    text: this.$t("general.external_temperature"),
                    value: "externalTemperature",
                    serieColor: "#71C4ECFF",
                    strokeColorOne: "#71C4ECFF",
                    series: [
                        {
                            name: this.$t("general.external_temperature"),
                            data: this.externalTemperaturesSerie
                        }
                    ],
                    yaxis: [
                        {
                            seriesName: this.$t("general.external_temperature"),
                            min: this.minTemperature,
                            max: this.maxTemperature,
                            title: {
                                text: this.$t("general.external_temperature") + ` (${temperatureSymbol})`,
                                style: {
                                    color: "#71C4ECFF"
                                }
                            },
                            labels: {
                                formatter: function(value) {
                                    return value ? value?.toFixed(0) : "";
                                }.bind(this),
                                style: {
                                    colors: "#71C4ECFF"
                                }
                            },
                            axisBorder: {
                                show: true,
                                color: "#71C4ECFF"
                            }
                        }
                    ]
                }
            ];

            return _sensors;
        },
        yaxis() {
            const _yaxis = [];

            if (!Array.isArray(this.selected) || this.selected.length === 0) {
                return [];
            }

            this.sensorSettings
                .filter(e => this.selected.includes(e.value))
                .forEach(i => {
                    if (Array.isArray(i.yaxis) && i.yaxis.length > 0) {
                        const yaxisConfig = i.yaxis[0];
                        yaxisConfig.opposite = this.selected[0] !== i.value;

                        if (this.selected[0] === i.value) {
                            _yaxis.unshift(yaxisConfig);
                        } else {
                            _yaxis.push(yaxisConfig);
                        }
                    } else {
                        console.warn("i.yaxis is not an array or is empty", i.yaxis);
                    }
                });

            return _yaxis;
        },
        colors() {
            let _colors = [];

            if (!Array.isArray(this.selected) || this.selected.length === 0) {
                return [];
            }

            this.sensorSettings
                .filter(e => this.selected.includes(e.value))
                .forEach(i => {
                    if (this.selected[0] === i.value) {
                        _colors.unshift(i.serieColor);
                    } else {
                        _colors.push(i.serieColor);
                    }
                });

            return _colors;
        },
        strokeColors() {
            let _strokeColors = [];

            if (!Array.isArray(this.selected) || this.selected.length === 0) {
                return [];
            }

            this.sensorSettings
                .filter(e => this.selected.includes(e.value))
                .forEach(i => {
                    if (this.selected[0] === i.value) {
                        _strokeColors.unshift(i.strokeColorOne);
                    } else {
                        _strokeColors.push(i.strokeColorOne);
                    }
                });

            return _strokeColors;
        },
        annotations() {
            let _annotations = {
                yaxis: [],
                xaxis: [],
                points: []
            };
            let _yaxisIndex = 0;

            let alerts = this.alertTrigger.map(alert => {
                let _trigger = null;
                if (typeof alert == "string") {
                    _trigger = JSON.parse(alert);
                } else _trigger = JSON.parse(alert?.triggerJson);
                return _trigger;
            });

            let alertDisplay = [
                {
                    type: "temperature",
                    symbol: this.getTemperatureSymbol(),
                    position: "left",
                    textAnchor: "start",
                    color: "#1B6A99FF",
                    highestValue: this.maxTemperature,
                    lowestValue: this.minTemperature || 0
                },
                {
                    type: "humidity",
                    symbol: "%",
                    position: "right",
                    textAnchor: "end",
                    color: "#07A98BFF",
                    highestValue: this.maxHumidity,
                    lowestValue: this.minHumidity || 0
                },
                {
                    type: "light",
                    symbol: "Lux",
                    position: "right",
                    textAnchor: "end",
                    color: "#DEBA04FF",
                    highestValue: this.maxLight,
                    lowestValue: this.minLight || 0
                },
                {
                    type: "shock",
                    symbol: "G",
                    position: "right",
                    textAnchor: "end",
                    color: "#FF0000FF",
                    highestValue: this.maxShock,
                    lowestValue: this.minShock || 0
                },
                {
                    type: "probeTemperature",
                    symbol: this.getTemperatureSymbol(),
                    position: "left",
                    textAnchor: "start",
                    color: "#71C4ECFF",
                    highestValue: this.maxTemperature,
                    lowestValue: this.minTemperature || 0
                },
                {
                    type: "externalTemperature",
                    symbol: this.getTemperatureSymbol(),
                    position: "left",
                    textAnchor: "start",
                    color: "#71C4ECFF",
                    highestValue: this.maxTemperature,
                    lowestValue: this.minTemperature || 0
                },
                {
                    type: "location",
                    symbol: "",
                    position: "left",
                    textAnchor: "start",
                    color: "#71C4ECFF",
                    highestValue: this.maxTemperature || this.maxHumidity || this.maxLight || this.maxShock || 100,
                    lowestValue: this.minTemperature || this.minHumidity || this.minLight || this.minShock || 0
                }
            ];

            alerts.forEach(alert => {
                if (alert?.Sensor && alert?.Sensor?.length > 0) {
                    alert.Sensor.forEach(sensor => {
                        //find yaxis index for sensor and align with yaxis serie value
                        this.yaxis.findIndex((e, i) => {
                            if (e.seriesName.toLowerCase() == sensor.Type.toLowerCase()) {
                                _yaxisIndex = i;
                            }
                        });

                        //Alerts Rules horizontal line determinate the alert limits
                        if (this.selected.includes(sensor.Type)) {
                            alertDisplay.forEach(display => {
                                if (display.type == sensor.Type) {
                                    if (sensor.Condition == "in") {
                                        let minValue =
                                            sensor.Type == "temperature"
                                                ? this.formatTemperature(sensor.Value[0])
                                                : sensor.Value[0];
                                        let maxValue =
                                            sensor.Type == "temperature"
                                                ? this.formatTemperature(sensor.Value[1])
                                                : sensor.Value[1];

                                        //Annotation yaxis
                                        _annotations.yaxis.push(
                                            {
                                                y: minValue,
                                                borderColor: display.color,
                                                strokeDashArray: 3,
                                                yAxisIndex: _yaxisIndex,
                                                label: {
                                                    offsetY: 15,
                                                    textAnchor: display.textAnchor,
                                                    position: display.position,
                                                    borderColor: undefined,
                                                    style: {
                                                        color: "#64B5F6",
                                                        background: "transparent"
                                                    },
                                                    text: minValue + " " + display.symbol
                                                }
                                            },
                                            {
                                                position: "back",
                                                y: maxValue,
                                                borderColor: display.color,
                                                strokeDashArray: 3,
                                                yAxisIndex: _yaxisIndex,
                                                label: {
                                                    offsetY: 15,
                                                    textAnchor: display.textAnchor,
                                                    position: display.position,
                                                    borderColor: undefined,
                                                    style: {
                                                        color: "#FF4560",
                                                        background: "transparent"
                                                    },
                                                    text: maxValue + " " + display.symbol
                                                }
                                            }
                                        );
                                    } else if (sensor.Condition == "out") {
                                        let minValue = this.formatTemperature(sensor.Value[0]);
                                        let maxValue = this.formatTemperature(sensor.Value[1]);

                                        //Annotation yaxis
                                        _annotations.yaxis.push(
                                            {
                                                y: minValue,
                                                borderColor: display.color,
                                                strokeDashArray: 3,
                                                yAxisIndex: _yaxisIndex,
                                                label: {
                                                    offsetY: 15,
                                                    textAnchor: display.textAnchor,
                                                    position: display.position,
                                                    borderColor: undefined,
                                                    style: {
                                                        color: "#64B5F6",
                                                        background: "transparent"
                                                    },
                                                    text: minValue + " " + display.symbol
                                                }
                                            },
                                            {
                                                position: "back",
                                                y: maxValue,
                                                borderColor: display.color,
                                                strokeDashArray: 3,
                                                yAxisIndex: 0,
                                                label: {
                                                    textAnchor: display.textAnchor,
                                                    position: display.position,
                                                    borderColor: undefined,
                                                    style: {
                                                        color: "#FF4560",
                                                        background: "transparent"
                                                    },
                                                    text: maxValue + " " + display.symbol
                                                }
                                            }
                                        );
                                    } else if (sensor.Condition === "gt") {
                                        let value =
                                            sensor.Type == "temperature"
                                                ? this.formatTemperature(sensor.Value)
                                                : sensor.Value;
                                        //Annotation yaxis
                                        _annotations.yaxis.push({
                                            position: "back",
                                            y: value,
                                            borderColor: display.color,
                                            strokeDashArray: 3,
                                            yAxisIndex: _yaxisIndex,
                                            label: {
                                                offsetY: 0,
                                                textAnchor: display.textAnchor,
                                                position: display.position,
                                                borderColor: undefined,
                                                style: {
                                                    color: "#FF4560",
                                                    background: "transparent"
                                                },
                                                text: value + " " + display.symbol
                                            }
                                        });
                                    } else if (sensor.Condition === "lt") {
                                        let value =
                                            sensor.Type == "temperature"
                                                ? this.formatTemperature(sensor.Value)
                                                : sensor.Value;

                                        //Annotation yaxis
                                        _annotations.yaxis.push({
                                            position: "back",
                                            y: value,
                                            borderColor: display.color,
                                            strokeDashArray: 3,
                                            yAxisIndex: _yaxisIndex,
                                            label: {
                                                offsetY: 15,
                                                textAnchor: display.textAnchor,
                                                position: display.position,
                                                borderColor: undefined,
                                                style: {
                                                    color: "#64B5F6",
                                                    background: "transparent"
                                                },
                                                text: value + " " + display.symbol
                                            }
                                        });
                                    }
                                }
                            });
                        }
                    });
                }
            });

            //annotations xaixs alert warning text and red bar
            if (Array.isArray(this.tripAlert)) {
                this.tripAlert.forEach(tripAlert => {
                    alertDisplay.forEach(display => {
                        if (tripAlert.type.includes(display.type)) {
                            //Annotation xaxis
                            _annotations.xaxis.push({
                                x: tripAlert.deviceTime,
                                borderColor: "#FF4560",
                                strokeDashArray: 0,
                                label: {
                                    borderColor: undefined,
                                    text: this.annotationText,
                                    offsetX: 0,
                                    offsetY: 0,
                                    orientation: "vertical",
                                    position: "bottom",
                                    textAnchor: "middle",
                                    width: 100,
                                    height: 50,
                                    style: {
                                        color: "#BDBDBD",
                                        background: "transparent",
                                        fontSize: "11px",
                                        cssClass: "apexcharts-xaxis-label"
                                    }
                                }
                            });
                        }
                    });
                });
            }

            //annotations point alert warning icon
            if (Array.isArray(this.tripAlert)) {
                this.tripAlert.forEach(tripAlert => {
                    alertDisplay.forEach(display => {
                        if (tripAlert.type.includes(display.type)) {
                            //Annotation point
                            _annotations.points.push({
                                x: tripAlert.deviceTime,
                                y: tripAlert.alertValue,
                                marker: {
                                    size: 4,
                                    fillColor: "#FF4560",
                                    strokeColor: "#fff",
                                    radius: 1,
                                    cssClass: "apexcharts-custom-class"
                                },
                                label: {
                                    offsetY: 0,
                                    offsetX: 0,
                                    textAnchor: "middle",
                                    text: null,
                                    borderColor: "trasparent"
                                },
                                image: {
                                    path: this.warning,
                                    width: 21,
                                    height: 21,
                                    offsetX: 0,
                                    offsetY: 0,
                                    style: {
                                        cssClass: "apexcharts-custom-class"
                                    }
                                }
                            });
                        }
                    });
                });
            }

            //annotations location
            let textDetail = [
                {
                    type: "flight_depart",
                    text: this.$t("trips.flight")
                },
                {
                    type: "flight_arrive",
                    text: this.$t("trips.arrived")
                },
                {
                    type: "geofence_exit",
                    text: this.$t("general.exits")
                },
                {
                    type: "geofence_enter",
                    text: this.$t("general.enter")
                }
            ];

            const getText = (itemA, itemB) => {
                let text = "";
                let timeA = this.convertDateTimeTimezoneToMilliseconds(itemA.eventTime);
                let timeB = this.convertDateTimeTimezoneToMilliseconds(itemB.eventTime);
                let diff = timeB - timeA;
                let hours = Math.floor(diff / 1000 / 60 / 60);

                if (["flight_depart", "vessel_depart"].includes(itemA.eventType)) {
                    text = "";
                } else if (
                    (itemA.eventType === "tracker_activate" && itemB.eventSourceType === "tenant_location") ||
                    (itemA.eventType === "tracker_activate" && hours <= 1)
                ) {
                    text = this.textTruncate(itemB.eventName);
                } else if (
                    (itemA.eventType === "tracker_activate" && !itemB.eventSourceType === "tenant_location") ||
                    (itemA.eventType === "tracker_activate" && hours > 1)
                ) {
                    text = this.$t("general.tracker_activated");
                } else {
                    text = this.textTruncate(itemA.eventName);
                }

                return text;
            };

            /* event location */
            this.eventsLocations?.forEach((event, index) => {
                if (Object.keys(event).length > 1) {
                    let startEventTime = event[0]?.eventTime;
                    let endEventTime = event[1]?.eventTime;

                    _annotations.xaxis.push({
                        x: startEventTime,
                        x2: endEventTime,
                        offsetX: 0,
                        offsetY: 0,
                        fillColor: this.getColorByEventType(index, event[0].eventType),
                        opacity: 0.4,
                        strokeDashArray: 0,
                        label: {
                            text: getText(event[0], event[1]),
                            borderColor: undefined,
                            offsetX: 15,
                            offsetY: 0,
                            orientation: "vertical",
                            position: "bottom",
                            textAnchor: "middle",
                            width: 100,
                            height: 50,
                            style: {
                                color: "#686868",
                                background: "transparent",
                                fontSize: "11px",
                                cssClass: "apexcharts-xaxis-label"
                            }
                        }
                    });

                    _annotations.points.push({
                        x: this.middlePoint(startEventTime, endEventTime),
                        y: "275px",
                        marker: {
                            size: 1,
                            fillColor: "transparent",
                            strokeColor: "transparent",
                            radius: 1,
                            cssClass: "apexcharts-custom-class"
                        },
                        label: {
                            offsetY: 0,
                            offsetX: 0,
                            textAnchor: "middle",
                            position: "center",
                            text: null,
                            borderColor: "#0E1E4633"
                        },
                        image: {
                            path: this.getEventImg(event[0].eventType, event[1]?.eventType),
                            width: 25,
                            height: 25,
                            offsetX: 10,
                            offsetY: 0,
                            style: {
                                cssClass: "apexcharts-custom-class"
                            }
                        }
                    });
                }
            });

            return _annotations;
        },
        annotationText() {
            let text = "";

            let alertDisplay = [
                {
                    type: "temperature",
                    name: this.$t("general.temperature"),
                    symbol: this.getTemperatureSymbol()
                },
                {
                    type: "humidity",
                    name: this.$t("general.humidity"),
                    symbol: "%"
                },
                {
                    type: "light",
                    name: this.$t("general.light"),
                    symbol: "Lux"
                },
                {
                    type: "shock",
                    name: this.$t("general.shock"),
                    symbol: "G"
                },
                {
                    type: "probeTemperature",
                    name: this.$t("general.probe_temperature"),
                    symbol: this.getTemperatureSymbol()
                },
                {
                    type: "externalTemperature",
                    name: this.$t("general.external_temperature"),
                    symbol: this.getTemperatureSymbol()
                },
                {
                    type: "location",
                    name: this.$t("general.location"),
                    symbol: ""
                }
            ];

            if (Array.isArray(this.tripAlert)) {
                this.tripAlert.forEach(tripAlert => {
                    alertDisplay.forEach(display => {
                        if (tripAlert.type.includes(display.type)) {
                            for (let index = 0; index < tripAlert.type.length; index++) {
                                if (
                                    tripAlert.type[index] != tripAlert.type[index - 1] &&
                                    !text.toLocaleLowerCase().includes(tripAlert.type[index])
                                ) {
                                    //capilize first letter
                                    let firstLetter = tripAlert.type[index].charAt(0).toUpperCase();
                                    let newText = firstLetter + tripAlert.type[index].slice(1);
                                    text = text + newText + " ";

                                    //if text have than 3 word then break loop
                                    if (text.split(" ").length > 2) {
                                        break;
                                    }
                                }
                            }
                            //text = display.name +" "+"Alert " ;
                        }
                    });
                });
            }

            text = text + this.$t("headers.alert");
            return text;
        },
        series() {
            let _series = [];

            if (!Array.isArray(this.selected) || this.selected.length === 0) {
                return _series;
            }

            this.sensorSettings
                .filter(e => this.selected.includes(e.value))
                .forEach(i => {
                    if (!i.series || !Array.isArray(i.series) || i.series.length === 0) {
                        console.warn("i.series is not an array or is empty", i.series);
                        return;
                    }

                    const seriesData = {
                        name: i.series[0].name,
                        data: Array.isArray(i.series[0].data) ? i.series[0].data : []
                    };

                    if (this.selected[0] === i.value) {
                        _series.unshift(seriesData);
                    } else {
                        _series.push(seriesData);
                    }
                });

            return _series;
        },
        minX() {
            let minDate = []; 
            if (this.startTime) {
                minDate.push(this.convertDateTimeTimezoneToMilliseconds(this.startTime));
            }

            const seriesList = [
                this.tripAlert.map(e => e.deviceTime),
                this.temperatureSeries.map(e => e[0]),
                this.lightSeries.map(e => e[0]),
                this.humiditySeries.map(e => e[0]),
                this.shockSeries.map(e => e[0]),
                this.probeTemperaturesSeries.map(e => e[0]),
                this.externalTemperaturesSeries.map(e => e[0])
            ];

            //merge -> trip alerts and sensors data and get earliest date
            seriesList.forEach(series => {
                if (series.length > 0) {
                    minDate.push(Math.min(...series));
                }
            });

            let earliestDate = Math.min(...minDate);
            
            return earliestDate;
        },
        maxX() {
            let maxDate = [];
            //Endtime
            if (this.endTime) {
                maxDate.push(this.convertDateTimeTimezoneToMilliseconds(this.endTime));
            }

            const seriesList = [
                this.tripAlert.map(e => e.deviceTime),
                this.temperatureSeries.map(e => e[0]),
                this.lightSeries.map(e => e[0]),
                this.humiditySeries.map(e => e[0]),
                this.shockSeries.map(e => e[0]),
                this.probeTemperaturesSeries.map(e => e[0]),
                this.externalTemperaturesSeries.map(e => e[0])
            ];

            //merge -> trip alerts and sensors data and get earliest date
            seriesList.forEach(series => {
                if (series.length > 0) {
                    maxDate.push(Math.max(...series));
                }
            });

            let latestDate = Math.max(...maxDate);

            return latestDate;
        },
        options() {
            let margin = 0 ;
            let dinamicTicks = 7; // default
            const totalRange = this.maxX - this.minX;
            const minTickInterval = 24 * 60 * 60 * 1000; // Interval 1 day

            if (totalRange > 24 * 60 * 60 * 1000) {
                margin = 2 * 60 * 60 * 1000;
                // if total range is less than 7 days, then calculate the number of ticks
                if (totalRange <= minTickInterval * 7) {
                    dinamicTicks = Math.ceil(totalRange / minTickInterval); // Max 7 ticks
                } else {
                    dinamicTicks = 7; // Limit to 7 ticks
                }
            }

            let _options = {
                chart: {
                    id: "temperature-chart",
                    type: "line",
                    height: "100%",
                    width: "100%",
                    animations: {
                        enabled: false
                    },
                    /* selection: {
                        enabled: true
                    },  */
                    toolbar: {
                        show: true,
                        offsetX: -20,
                        offsetY: 0,
                        tools: {
                            download: false,
                            selection: true,
                            zoom: true,
                            zoomin: false,
                            zoomout: false,
                            pan: false,
                            customIcons: [
                                {
                                    icon: `<i class='material-icons px-1'
                                            style='
                                                font-size: 19px;
                                                color:#90A4AE !important;
                                                margin-top: 2px;
                                            '
                                        >
                                            filter_center_focus
                                        </i>`,
                                    index: 1,
                                    title: "Reset",
                                    class: "custom-icon",
                                    click: () => {
                                        this.handleResetZoom();
                                    }
                                }
                            ],
                            reset: false
                        }
                    }
                },
                legend: {
                    show: false
                },
                annotations: {
                    yaxis: []
                },
                dataLabels: {
                    enabled: false
                },
                noData: {
                    text: this.$t("loading.no_tracker_available"),
                    align: "center",
                    verticalAlign: "middle"
                },
                zoom: {
                    autoScaleYaxis: false
                },
                grid: {
                    show: true,
                    borderColor: "#90A4AE",
                    strokeDashArray: 1,
                    position: "back",
                    xaxis: {
                        lines: {
                            show: true
                        }
                    },
                    yaxis: {
                        lines: {
                            show: true
                        }
                    },
                    row: {
                        colors: "",
                        opacity: 0.5
                    },
                    column: {
                        colors: "",
                        opacity: 0.5
                    },
                    padding: {
                        top: 0,
                        right: 30,
                        bottom: 0,
                        left: 30
                    }
                },
                selection: {
                    enabled: true,
                    xaxis: {
                        min: this.minX,
                        max: this.maxX
                    }
                },
                xaxis: {
                    type: "numeric",
                    min: this.minX - margin,
                    max: this.maxX + margin,
                    tickAmount: dinamicTicks,
                    tickPlacement: "between",
                    labels: {
                        formatter: function(val, timestamp, opt) {
                            const formatAvailabe = ["DD MMM", "HH:mm"];
                            let _max = opt?.w?.config?.xaxis.max;
                            let _min = opt?.w?.config?.xaxis.min;

                            if (!_max || !_min) return "";

                            if (_max - _min < 24 * 60 * 60 * 1000) {
                                return moment(val).format(formatAvailabe[1]);
                            } else {
                                return moment(val).format(formatAvailabe[0]);
                            }
                        },
                        show: true,
                        minHeight: 70,
                        rotateAlways: false,
                        maxTicksLimit: dinamicTicks,
                        style: {
                            fontSize: "11px",
                            fontWeight: 600,
                            lineHeight: "13px",
                            letterSpacing: "0em",
                            textAlign: "left"
                        },
                        offsetX: 0,
                        offsetY: 15,
                        datetimeUTC: false,
                        datetimeFormatter: {
                            year: "yyyy",
                            month: "dd MMM",
                            day: "dd MMM",
                            hour: "HH:mm"
                        }
                    },
                    axisBorder: {
                        show: true,
                        color: "#78909C",
                        height: 1,
                        width: "100%",
                        offsetX: 0,
                        offsetY: 0
                    },
                    axisTicks: {
                        show: true,
                        borderType: "dotted",
                        color: "#78909C",
                        height: 40,
                        offsetX: 0,
                        offsetY: 0
                    }
                },
                yaxis: [],
                colors: [],
                markers: {
                    // size: [0, 0.5, 0, 0.5],
                    strokeColors: [],
                    showNullDataPoints: true,
                    hover: { sizeOffset: 3 }
                },
                tooltip: {
                    x: {
                        show: false,
                        format: "dd MMM yyyy HH:mm"
                    }
                },
                stroke: {
                    curve: "smooth",
                    width: [2, 2, 2, 2, 2, 2, 2, 2, 2, 2, 2, 2],
                    dashArray: [0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0]
                },
                fill: {
                    opacity: 1
                }
            };

            _options.yaxis = [...this.yaxis];
            _options.colors = [...this.colors];
            _options.markers.strokeColors = [...this.strokeColors];
            _options.annotations = { ...this.annotations };

            return _options;
        }
    },
    created() {
        this.formatDateTime = helper.formatDateTime;
        this.formatTemperature = helper.formatTemperature;
        this.updateDateTimeTimezone = helper.updateDateTimeTimezone;
        this.getTemperatureSymbol = helper.getTemperatureSymbol;
        this.convertDateTimeTimezoneToMilliseconds = helper.convertDateTimeTimezoneToMilliseconds;
    },
    methods: {
        middlePoint(start, end) {
            if (!start || !end) return;
            let middle = (start + end) / 2;
            return middle;
        },
        getEventImg(event_type, next_event_type) {
            switch (event_type) {
                case "vessel_depart":
                    return this.directionsBoat;
                case "flight_depart":
                    return this.flightTakeoff;
                case "Enter":
                    return this.login;
                case "Exit":
                    return this.logout;
                /*  case "planned_departure_date":
                    return this.flag;
                case "planned_arrival_date":
                    return this.sportsScore;
                case "completed_at":
                    return this.sportsScore;
                case "tracker_activate":
                    return this.playArrow;
                case "geofence_enter":
                    return this.login;
                case "geofence_exit":
                    return this.logout; */
                /*  case "vessel_arrive":
                    return this.directionsBoat; */
                /* case "flight_arrive":
                    if (next_event_type === "geofence_exit") {
                        return this.login;
                    } else {
                        return this.flightLand;
                    }
                */
                default:
                    return "";
            }
        },
        getColorByEventType(index, event) {
            let color = "#0E1E4633";
            let keysExits = ["vessel_depart", "flight_depart"];

            if (keysExits.includes(event)) {
                color = "transparent";
            } else if (index % 2 === 0) {
                color = "#ADCEC18A";
            } else {
                color = "#0E1E4633";
            }

            return color;
        },
        handleResetZoom() {
            const apexChart = this.$refs.overviewChart;
            apexChart.refresh();
        },
        textTruncate(text) {
            if (!text) return "";
            if (text.length > 30) {
                return text.substring(0, 40) + "...";
            }
            return text;
        }
    }
};
</script>
<style scoped>
#chart {
    max-height: 350px;
}
</style>
