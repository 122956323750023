<template>
    <LoadingBar v-if="$apollo.queries.tenantPartner.loading"></LoadingBar>
    <div v-else>
        <v-card fill-height flat width="100%" color="background" v-if="partnerDetails.id">
            <v-toolbar flat color="background" v-if="!isDialog">
                <!----- TOP LEFT ICONS ---->
                <v-btn icon @click="$emit('close')">
                    <v-icon>arrow_back_ios_new</v-icon>
                </v-btn>
                <v-btn v-if="shouldShowFullScreenButton" icon @click="changeFullScreen">
                    <v-icon>{{ isFullScreen ? "fullscreen_exit" : "crop_free" }}</v-icon>
                </v-btn>
                <v-spacer></v-spacer>

                <!---- TOP RIGHT ICONS ---->
                <v-btn icon>
                    <v-icon @click="editPartner"> edit </v-icon>
                </v-btn>
                <v-btn icon>
                    <v-icon @click="openDeletePartnerDialog = true">
                        delete
                    </v-icon>
                </v-btn>

                <!---- DELETE PARTNER DIALOG ---->
                <DeletePartnerDialog
                    :openDeleteDialog="openDeletePartnerDialog"
                    :partnerID="partnerDetails.id"
                    v-on:refreshDataTable="closeDrawerAndRefresh"
                    v-on:closeDeletePartnerDialog="openDeletePartnerDialog = false"
                >
                    <template v-slot:title>
                        {{ $t("partners.delete_partner") }}
                    </template>
                    <template #content>
                        {{ $t("partners.sure_partner") }}
                    </template>
                </DeletePartnerDialog>
            </v-toolbar>

            <v-card-title class="pt-0 px-5">
                <!---- PARTNER GENERAL INFO DISPLAY (NAME, TYPE, REFERENCE...) ---->
                <v-row>
                    <v-col v-for="item in detailsHeader(partnerDetails)" :key="item.text"
                       cols="12" xs="12" sm="6" md="3" lg="3" xl="3" class="mb-3"
                    >
                        <div class="">
                            <div class="textHeader text-truncate">
                                {{ item.title }}
                            </div>
                            <div class="textBody text-truncate" :style="item.style">
                                <v-icon v-if="item.icon" size="8" class="mr-1" :color="item.colorIcon">{{
                                    item.icon
                                }}</v-icon>
                                <span>
                                    {{ item.value }}
                                </span>
                            </div>
                        </div>
                    </v-col>
                </v-row>
            </v-card-title>
            <!---- tab viewType ---->
            <PartnerDetailTabView
                v-if="viewType === 'tab'"
                :partner="partnerDetails"
                :selectedFilters="defaultFilterChoices"
            />
        </v-card>
    </div>
</template>

<script>
/* import FilterMenu from "@/components/base/FilterMenu.vue"; */
import DeletePartnerDialog from "@/components/partners/delete/DeletePartnerDialog.vue";
import PartnerDetailTabView from "@/components/partners/PreviewPartnersTabsView.vue";
import helper from "../../utils/helper";
import Config from "@/utils/config.json";
import LoadingBar from "@/components/base/LoadingBar.vue";
import gql from "graphql-tag";

export default {
    name: "PartnerDetail",
    props: {
        partner: {
            type: Object,
            default: () => ({})
        },
        partnerId: {
            type: String,
            required: false
        },
        isFullScreen: {
            type: Boolean,
            required: false,
            default: false
        },
        isDialog: {
            type: Boolean,
            required: false,
            default: false
        },
        isCarrier: {
            type: Boolean,
            required: false,
            default: false
        },
        type: {
            type: String,
            required: false,
            default: "edit"
        }
    },
    apollo: {
        tenantPartner: {
            query: gql`
                query TenantPartner($tenantPartnerId: ID!) {
                    tenantPartner(id: $tenantPartnerId) {
                        id
                        name
                        carrier {
                            id
                            code
                            carrierType
                        }
                        partnerType
                        organisationPermissions
                        notificationEnabled
                        carrierType
                    }
                }
            `,
            variables() {
                return { tenantPartnerId: this.partner?.id || this.partnerId };
            },
            fetchPolicy: "cache-and-network",
            nextFetchPolicy: "cache-first",
            update: data => data.tenantPartner,
            watchLoading(isLoading) {
                this.$emit("loading", isLoading);
            }
            /* result({ data, loading, error }) {
                if (!loading) {
                    console.log("partner", data);
                }
            } */
        }
    },
    components: {
        //FilterMenu,
        PartnerDetailTabView,
        DeletePartnerDialog,
        LoadingBar
    },
    data() {
        return {
            updatedPartner: null,
            openDeletePartnerDialog: false,
            openEditPartnerDialog: false,
            viewType: "tab",
            viewChoices: [
                { code: "tab", name: this.$t("general.tab") },
                { code: "list", name: this.$t("general.list") }
            ],
            defaultFilterChoices: [
                {
                    // for filter menu
                    code: "permissions",
                    name: this.$t("headers.permissions"), //"Permissions",
                    tag: "headers.permissions",
                    enabled: true,
                    order: 3
                },
                {
                    // for filter menu
                    code: "users",
                    name: this.$t("home.users"), //"Users",
                    tag: "home.users",
                    enabled: true,
                    order: 1
                },
                {
                    // for filter menu
                    code: "locations",
                    name: this.$t("home.locations"), //"Locations",
                    tag: "home.locations",
                    enabled: true,
                    order: 2
                },

                {
                    // for filter menu
                    code: "trips",
                    name: this.$t("headers.trips"), //"Trips",
                    tag: "headers.trips",
                    enabled: true,
                    order: 3
                }
            ],
            filterChoices: []
        };
    },
    mounted() {
        // watch viewType
        this.$watch(
            "viewType",
            function(value) {
                helper.updateMyPreferences({
                    partnerDetailViewType: value
                });
            },
            { deep: true }
        );
        // watch filterChoices
        this.$watch(
            "filterChoices",
            function(value) {
                helper.updateMyPreferences({
                    partnerDetailFilterChoices: value
                });
            },
            { deep: true }
        );
        //Gtag
        this.$gtag.screenview({
            app_name: Config.VUE_APP_APPLICATION_NAME,
            screen_name: "partner_details_svw"
        });
        this.setUserProperties();
    },
    watch: {},
    created() {
        this.viewType = helper.getViewType("partnerDetailViewType");
        this.filterChoices = helper.getFilterChoices(this.defaultFilterChoices, "partnerDetailFilterChoices");
    },
    computed: {
        selectedFilters() {
            // Return array only with enabled filters
            let _filters = [];
            if (this.isDialog) {
                _filters = this.defaultFilterChoices.filter(filter => filter.code !== "trips");
            } else _filters = this.filterChoices.filter(filter => filter.enabled);
            _filters.forEach(filter => {
                filter.name = this.$t(filter.tag);
            });
            return _filters;
        },
        shouldShowFullScreenButton() {
            return this.$vuetify.breakpoint.lgAndUp;
        },
        partnerDetails() {
            //merge tenantPartner and partner
            let details = { ...this.partner, ...this.tenantPartner };
            let _partner = JSON.parse(JSON.stringify(details));
            return _partner || {};
        }
    },
    methods: {
        setUserProperties() {
            if (this.me?.isPartnerUser) {
                this.me?.allTenantPartners.forEach(c => {
                    this.$gtag.event("partner_details_svw", {
                        tenant_id: this.me?.tenant?.id,
                        tenant_name: this.me?.tenant?.name,
                        partner_id: c.id,
                        user_id: this.me?.id
                    });
                });
            } else {
                this.$gtag.event("partner_details_svw", {
                    tenant_id: this.me?.tenant?.id,
                    tenant_name: this.me?.tenant?.name,
                    partner_id: null,
                    user_id: this.me?.id
                });
            }
        },
        closeDrawerAndRefresh() {
            // Emit event that will refresh table data
            this.$emit("refreshDataTable");
            // Close detail drawer if open
            this.$emit("close");
        },
        onFilterMenuChanged(choice) {
            this.filterChoices = choice;
        },
        changeFullScreen() {
            this.$emit("changeFullScreen", !this.isFullScreen);
        },
        editPartner() {
            // Update Vuex with the current partner state for editing
            this.$store.dispatch("partner/updateCreatedPartner", {
                type: "update",
                partner: this.partnerDetails
            });

            this.$router.push({
                path: "/previewcreatepartner",
                query: {
                    type: `edit-${this.type}`, // Pass a query parameter to indicate the edit mode
                    id: this.partnerDetails.id
                }
            });
            // Navigate to the partner preview/edit page
        },
        updatePartnerValues(val) {
            // Close edit partner dialog if open
            this.$emit("refreshDataTable");
            this.openEditPartnerDialog = false;
            this.updatedPartner = val.data.updateTenantPartner.partner;
        },
        detailsHeader(item) {
            const { referenceNumber, name, carrier } = item;

            let _detailsCard = [
                {
                    title: this.$t("headers.reference_number"),
                    tag: "referenceNumber",
                    icon: null,
                    colorIcon: null,
                    style: "",
                    align: "left",
                    value: referenceNumber
                },
                {
                    title: this.$t("headers.name"),
                    tag: "name",
                    icon: null,
                    colorIcon: null,
                    style: "",
                    align: "left",
                    value: name
                }
            ];
            if (this.isCarrier && carrier) {
                _detailsCard.push(
                    {
                        title: this.$t("headers.carrier_type"),
                        tag: "carrierType",
                        icon: null,
                        colorIcon: null,
                        style: "",
                        align: "left",
                        value: carrier.carrierType || ""
                    },
                    {
                        title: this.$t("partners.carrier_name"),
                        tag: "carrierName",
                        icon: null,
                        colorIcon: null,
                        style: "",
                        align: "left",
                        value: carrier.name || ""
                    }
                );
            }
            return _detailsCard;
        }
    }
};
</script>
<style scoped>
.v-select.fit {
    max-width: min-content;
    padding: 10px;
}
.v-select.fit .v-select__selection--comma {
    text-overflow: unset;
    padding-right: 1rem;
}

.v-select.fit .v-select__selections input {
    display: none;
}
</style>
