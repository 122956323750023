<template>
    <v-autocomplete
        v-model="vesselsToFilter"
        :loading="$apollo.queries.vessels.loading"
        :items="vessels.edges"
        :label="label"
        @focus="openVesselList = true"
        :search-input.sync="search"
        dense
        hide-selected
        hide-details
        no-filter
        clearable
        item-text="node.name"
        item-value="node.id"
        :outlined="outlined"
        :menu-props="{ top: false, offsetY: true, maxHeight: 245 }"
        :no-data-text="$apollo.queries.vessels.loading ? this.$t('loading.loading1') : this.$t('loading.no_available')"
    >
    </v-autocomplete>
</template>

<script>
import gql from "graphql-tag";

export default {
    name: "VesselFilter",
    props: {
        label: {
            type: String,
            default: ""
        },
        value: {
            type: String,
            default: ""
        },
        refreshTable: {
            type: Boolean,
            default: false
        },
        outlined: {
            type: Boolean,
            default: false
        }
    },
    apollo: {
        vessels: {
            query: gql`
                query Vessels($nameIcontains: String, $first: Int) {
                    vessels(name_Icontains: $nameIcontains, first: $first) {
                        edges {
                            node {
                                id
                                name
                            }
                        }
                    }
                }
            `,
            variables() {
                return {
                    nameIcontains: this.search,
                    first: 20
                };
            },
            fetchPolicy: "cache-and-network",
            nextFetchPolicy: "cache-first",
            update: data => data.vessels,
            skip() {
                return false;
            }
        }
    },
    data() {
        return {
            loading: false,
            shouldLoadMore: false,
            isInitialLoad: true,
            vessels: { edges: [] },
            openVesselList: false,
            search: null
        };
    },
    watch: {},
    computed: {
        hasMoreData() {
            return this.tenantPartners?.pageInfo?.hasNextPage;
        },
        vesselsToFilter: {
            get() {
                return this.value;
            },
            set(value) {
                if (value) {
                    let list = this.vessels?.edges.filter(e => e.node.id === value);
                    this.$emit("update:name", list[0].node.name);
                } else {
                    this.$emit("update:name", "");
                }
                this.$emit("input", value);
            }
        },
        shouldSkip() {
            return !this.openVesselList && !this.refreshTable;
        }
    },
    methods: {}
};
</script>
