<template>
        <v-card-text class="pb-0">
            <div class="d-flex flex-row align-center justify-center" :ripple="false">
                <v-card :width="!isStaff && canEdit && openSelector ? '70' : '40'" color="transparent" flat tile class="d-flex-grow-0 mr-2">
                    <template>
                        <slot name="prepend-action"></slot>
                    </template>
                </v-card>
                <v-row dense align="center">
                    <v-col
                        cols="12"
                        xs="12"
                        sm="12"
                        md="12"
                        lg="12"
                        xl="12"
                        class="pa-0 text-uppercase user-select-text"
                    >
                        <v-row class="pa-0" dense >
                            
                            <v-col 
                                cols="12" 
                                xl="auto" 
                                lg="auto" 
                                md="3" 
                                sm="6" 
                                xs="12" 
                                class="align-center justify-start pa-1"
                                :style="{
                                    'flex-basis': $vuetify.breakpoint.lgAndUp ? '14.2%' : '',
                                    'max-width': $vuetify.breakpoint.lgAndUp ? '14.2%' : ''
                                }"
                            >
                                <div class="mt-1 textLeft">
                                    <div
                                        class="font-weight-thin secondaryText  text-truncate"
                                    >
                                        {{ $t("headers.serial_number") }}
                                    </div>
                                    <div class="font-weight-bold primaryText text-truncate">
                                        {{ item.node.serialNumber || '&nbsp;&nbsp;&nbsp;&nbsp;-' }}
                                    </div>
                                </div>
                            </v-col>

                            <v-col
                                cols="12"
                                xl="auto"
                                lg="auto"
                                md="3"
                                sm="6"
                                xs="12"
                                class="align-center justify-start pa-1"
                                :style="{
                                    'flex-basis': $vuetify.breakpoint.lgAndUp ? '14.2%' : '',
                                    'max-width': $vuetify.breakpoint.lgAndUp ? '14.2%' : ''
                                }"
                            >
                                <div class="mt-1 textLeft">
                                    <div
                                        class="font-weight-thin secondaryText  text-truncate"
                                    >
                                        {{ $t("headers.trip_number") }}
                                    </div>
                                    <div class="font-weight-bold primaryText text-truncate">
                                        {{ item.node.tripReferenceNumber || '&nbsp;&nbsp;&nbsp;&nbsp;-' }}
                                    </div>
                                </div>
                            </v-col>
                            <v-col
                                cols="12"
                                xl="auto"
                                lg="auto"
                                md="3"
                                sm="6"
                                xs="12"
                                class="align-center justify-start pa-1"
                                :style="{
                                    'flex-basis': $vuetify.breakpoint.lgAndUp ? '14.2%' : '',
                                    'max-width': $vuetify.breakpoint.lgAndUp ? '14.2%' : ''
                                }"
                            >
                                <div class="mt-1 textLeft">
                                    <div
                                        class="font-weight-thin secondaryText  text-truncate"
                                    >
                                        {{ $t("headers.brand") }}
                                    </div>
                                    <div class="font-weight-bold primaryText text-truncate">
                                        {{ item.node.brand || '&nbsp;&nbsp;&nbsp;&nbsp;-' }}
                                    </div>
                                </div>
                            </v-col>
                            <v-col
                                cols="12"
                                xl="auto"
                                lg="auto"
                                md="3"
                                sm="6"
                                xs="12"
                                class="align-center justify-start pa-1"
                                :style="{
                                    'flex-basis': $vuetify.breakpoint.lgAndUp ? '14.2%' : '',
                                    'max-width': $vuetify.breakpoint.lgAndUp ? '14.2%' : ''
                                }"
                            >
                                <div class="mt-1 textLeft">
                                    <div
                                        class="font-weight-thin secondaryText  text-truncate"
                                    >
                                        {{ $t("headers.activation") }}
                                    </div>
                                    <div class="font-weight-bold primaryText text-truncate">
                                        {{ formatDateTime(item.node.activatedDate) || '&nbsp;&nbsp;&nbsp;&nbsp;-' }}
                                    </div>
                                </div>
                            </v-col>
                            <v-col
                                cols="12"
                                xl="auto"
                                lg="auto"
                                md="3"
                                sm="6"
                                xs="12"
                                class="align-center justify-start pa-1"
                                :style="{
                                    'flex-basis': $vuetify.breakpoint.lgAndUp ? '14.2%' : '',
                                    'max-width': $vuetify.breakpoint.lgAndUp ? '14.2%' : ''
                                }"
                            >
                                <div class="mt-1 textLeft">
                                    <div
                                        class="font-weight-thin secondaryText  text-truncate"
                                    >
                                        {{ $t("headers.expiration") }}
                                    </div>
                                    <div class="font-weight-bold primaryText text-truncate">
                                        {{ formatDateTime(item.node.expiryDate) || '&nbsp;&nbsp;&nbsp;&nbsp;-' }}
                                    </div>
                                </div>
                            </v-col>
                            <v-col
                                cols="12"
                                xl="auto"
                                lg="auto"
                                md="3"
                                sm="6"
                                xs="12"
                                class="align-center justify-start pa-1"
                                :style="{
                                    'flex-basis': $vuetify.breakpoint.lgAndUp ? '14.2%' : '',
                                    'max-width': $vuetify.breakpoint.lgAndUp ? '14.2%' : ''
                                }"
                            >
                                <div class="mt-1 textLeft">
                                    <div
                                        class="font-weight-thin secondaryText  text-truncate"
                                    >
                                        {{ $t("headers.last_date") }}
                                    </div>
                                    <div class="font-weight-bold primaryText text-truncate">
                                        {{ formatDateTime(item.node.lastConnectionTime) || '&nbsp;&nbsp;&nbsp;&nbsp;-' }}
                                    </div>
                                </div>
                            </v-col>
                            <v-col
                                cols="12"
                                xl="auto"
                                lg="auto"
                                md="3"
                                sm="6"
                                xs="12"
                                class="align-center justify-start pa-1"
                                :style="{
                                    'flex-basis': $vuetify.breakpoint.lgAndUp ? '14.2%' : '',
                                    'max-width': $vuetify.breakpoint.lgAndUp ? '14.2%' : ''
                                }"
                            >
                                <div class="mt-1 textLeft">
                                    <div
                                        class="font-weight-thin secondaryText  text-truncate"
                                    >
                                        {{ $t("headers.stock_location") }}
                                    </div>
                                    <div class="font-weight-bold primaryText text-truncate">
                                        {{ item.node.stockLocation ? item.node.stockLocation.name : '&nbsp;&nbsp;&nbsp;&nbsp;-' }}
                                    </div>
                                </div>
                            </v-col>
                        </v-row>
                    </v-col>
                </v-row> 
            </div>
        </v-card-text>
</template>
<script>
import helper from "@/utils/helper";
import { TrackerPermissions } from "@/core/enum";

export default {
    name: "TrackerHeaderTemplate",
    components: {
    },
    props: {
        item: {
            type: Object,
            required: true
        },
        openSelector: {
            type: Boolean,
            required: false,
            default: false
        }
    },
    data() {
        return {
                
        }
    },
    created() {
        this.formatDateTime = helper.formatDateTime;
        this.hasPermission = helper.hasPermission;
    },
    computed: {
        me() {
            return helper.me();
        },
        isStaff() {
            return this.me.isStaff || false;
        },
        canEdit() {
            if (this.me?.isSuperuser) {
                return true;
            }
            return this.hasPermission(TrackerPermissions.EDIT_TRACKER);
        },
    }
}
</script>
<style scoped>
.secondaryText {
    color: #555464 !important;
    font-size: 12px;
    line-height: 14px;
    letter-spacing: 0em;
}

.primaryText {
    color: #0e0d35 !important;
    font-size: 12px;
    line-height: 13px;
    letter-spacing: 0em;
    margin-top: 3px;
}

.textLeft {
    text-align: left !important;
}
</style>