<template>
    <v-sheet class="mx-auto background" max-width="100%" min-height="100%">
        <v-row dense class="pl-5 pr-3" align="stretch">
            <!-- Trip timeline -->
            <!-- <v-col cols="12" class="pt-6" v-if="toogleTimeline">
                <TripTimelineNew
                    :trip="trip"
                    :isSharedTrip="isSharedTrip"
                    :organisationPermissions.sync="organisationPermissions"
                ></TripTimelineNew>
            </v-col> -->
            <v-col cols="12" class="pt-6">
                <TripTimelineNewCity
                    :trip="trip"
                    :isSharedTrip="isSharedTrip"
                    :organisationPermissions.sync="organisationPermissions"
                    :tripIsLoading.sync="tripIsLoading"
                >
                </TripTimelineNewCity>
            </v-col>
        </v-row>
    </v-sheet>
</template>

<script>
import helper from "@/utils/helper.js";
import { TripPermissions } from "@/core/enum";
import Config from "@/utils/config.json";
// import TripTimelineNew from "@/components/trips/arrangement/TripTimelineNew.vue";
import TripTimelineNewCity from "@/components/trips/arrangement/TripTimelineNewCity.vue";
//import LoadingBar from "@/components/base/LoadingBar.vue";

export default {
    props: {
        trip: {
            type: Object,
            required: true
        },
        isSharedTrip: {
            type: Boolean,
            required: false,
            default: false
        },
        organisationPermissions: {
            type: Array,
            required: true,
            default: function() {
                return [];
            }
        },
        freightProp: {
            type: Object,
            required: false,
            default: function() {
                return null;
            }
        },
        tripTimeRange: {
            type: Array,
            required: false,
            default: function() {
                return [];
            }
        },
        tripIsLoading: {
            type: Boolean,
            required: false,
            default: false
        }
    },
    components: {
        // TripTimelineNew,
        TripTimelineNewCity
        //LoadingBar,
        //FreightsForm,
        //NoPermission,
        //NoData
    },
    data() {
        return {
            panel: [0, 1],
            panelFreight: [],
            tripFreights: {},
            createDialog: false,
            deleteDialog: false,
            item: {},
            deletedItem: {},
            validateForm: false,
            toggleTitle: false,
            loadingDeleteBtn: false,
            loadingCreateEditBtn: false,
            carousel: 0,
            tripFreight: { edges: [] },
            currentTrackers: [],
            FreightsFormCardHeight: "auto"
        };
    },
    beforeCreate() {
        this.hasPermission = helper.hasPermission;
        this.hasOrganisationPermission = helper.hasOrganisationPermission;
        this.TripPermissions = TripPermissions;
    },
    created() {
        this.formatDateTime = helper.formatDateTime;
        this.relativeTime = helper.relativeTime;
        this.formatTemperature = helper.formatTemperature;
        this.getTemperatureSymbol = helper.getTemperatureSymbol;
        this.unitPerBrand = helper.unitPerBrand;
        this.formatAirwaybillNumber = helper.formatAirwaybillNumber;
        this.removeHifenFromAirwaybill = helper.removeHifenFromAirwaybill;
    },
    watch: {
        $route: {
            immediate: true,
            handler(to) {
                if (to.query.freight) {
                    if (this.tripFreight.edges.length === 0) return;
                    let index = this.tripFreight.edges.findIndex(x => x.node.id === to.query.freight);

                    let temp = this.tripFreight.edges[index];
                    let newEdges = [...this.tripFreight.edges];
                    newEdges.splice(index, 1);
                    newEdges.unshift(temp);
                    this.tripFreight = { edges: newEdges };

                    this.panelFreight = [0];
                }
            }
        }
    },
    computed: {
        me() {
            return helper.me();
        },
        toogleTimeline() {
            return false;
        },
        canAccess() {
            //Partner who is not a tenannt
            if (this.me.isPartnerUser && !this.me?.tenant) {
                if (!this.hasOrganisationPermission(TripPermissions.VIEW_TRIP_FREIGHT, this.organisationPermissions))
                    return false;
            } else {
                if (
                    this.organisationPermissions.length > 0 &&
                    !this.hasOrganisationPermission(TripPermissions.VIEW_TRIP_FREIGHT, this.organisationPermissions)
                )
                    return false;
                if (
                    !this.hasOrganisationPermission(
                        TripPermissions.MANAGE_TRIP_FREIGHT,
                        this.me?.tenant?.organisationPermissions
                    )
                )
                    return false;
                if (this.me?.tenant && this.me?.isTenantAdmin) return true;
                if (!this.hasPermission(TripPermissions.VIEW_TRIP_FREIGHT) && this.me?.tenant) return false;
            }

            return true;
        }
    },
    mounted() {
        //Gtag
        this.$gtag.screenview({
            app_name: Config.VUE_APP_APPLICATION_NAME,
            screen_name: "trip_summary_tab_svw"
        });
    },
    methods: {}
};
</script>

<style scope>
.v-expansion-panel-content__wrap {
    padding: 0% !important;
}
.v-expansion-panel:not(:first-child)::after {
    border-top: none !important;
}
.txtIndex {
    max-height: 19px;
    font-size: 12.4px;
    font-weight: 720;
    color: #a4a4a8;
}
.txtBody {
    max-height: 19px;
    font-size: 12.3px;
    font-weight: 650;
}
.maxHeight {
    max-height: 18px;
}

.transparentBg {
    background-color: transparent !important;
}
</style>
