<template>
    <div>
        <v-menu
            v-if="!useDrawerStyle"
            v-model="menu"
            bottom
            left
            :close-on-content-click="false"
            :open-on-hover="openOnHover"
            z-index="3"
            transition="slide-x-reverse-transition"
        >
            <template v-slot:activator="{ on, attrs }">
                <v-btn
                    icon
                    v-on="on"
                    :dark="dark"
                    v-bind="attrs"
                    @click="loadMenu"
                >
                    <v-icon>{{ icon }}</v-icon>
                </v-btn>
            </template>

            <v-card :width="width">
                <v-list v-if="title || description">
                    <v-list-item>
                        <v-list-item-content>
                            <v-list-item-title v-if="title">
                                {{ title }}
                            </v-list-item-title>
                            <v-list-item-subtitle
                                v-if="description"
                                class="text-wrap"
                            >
                                {{ description }}
                            </v-list-item-subtitle>
                        </v-list-item-content>

                        <v-list-item-action v-if="icon">
                            <v-icon>filter_list_alt</v-icon>
                        </v-list-item-action>
                    </v-list-item>
                </v-list>

                <v-divider></v-divider>

                <v-card flat :max-height="height" class="overflow-auto">
                    <v-list dense>
                        <draggable
                            class="list-group"
                            v-model="newChoices"
                            v-bind="dragOptions"
                        >
                            <transition-group type="transition">
                                <div
                                    v-for="(item, index) in newChoices"
                                    :key="index + 0"
                                >
                                    <v-hover>
                                        <template v-slot="{ hover }">
                                            <v-list-item
                                                class="list-group-item pl-0"
                                                :class="
                                                    hover
                                                        ? 'grey lighten-4'
                                                        : 'white'
                                                "
                                            >
                                                <v-list-item-avatar>
                                                    <v-icon
                                                        >drag_indicator</v-icon
                                                    >
                                                </v-list-item-avatar>
                                                <v-list-item-content>
                                                    <v-list-item-subtitle>
                                                        {{
                                                            translatedChoices.find(
                                                                f =>
                                                                    f.code ===
                                                                    item.code
                                                            ).name
                                                        }}
                                                    </v-list-item-subtitle>
                                                </v-list-item-content>
                                                <v-list-item-action>
                                                    <v-switch
                                                        color="primary"
                                                        v-model="item.enabled"
                                                    ></v-switch>
                                                </v-list-item-action>
                                            </v-list-item>
                                        </template>
                                    </v-hover>
                                </div>
                            </transition-group>
                        </draggable>
                    </v-list>
                </v-card>

                <v-divider></v-divider>

                <v-card-actions
                    v-if="showApplyButton"
                    class="d-flex justify-end py-2 pr-3"
                >
                    <v-btn
                        small
                        outlined
                        rounded
                        color="primary"
                        @click="onApplied"
                        >{{ $t("general.apply") }}</v-btn
                    >
                </v-card-actions>
            </v-card>
        </v-menu>
        <div v-else class="h-100">
            <v-col cols="12" style="height: calc(100vh - 100px);">
                <v-card flat :height="height" style="display: flex; flex-direction: column;">
                    <v-card-title class="pa-0">
                        <v-list v-if="title || description">
                            <v-list-item class="px-1">
                                <v-list-item-content>
                                    <v-list-item-title v-if="title">
                                        {{ title }}
                                    </v-list-item-title>
                                    <v-list-item-subtitle v-if="description" class="text-wrap">
                                        {{ description }}
                                    </v-list-item-subtitle>
                                    <v-list-item-subtitle v-if="showIconSwitch">
                                        <v-switch
                                            color="primary"
                                            hide-details
                                            class="pl-1 pb-1"
                                            :label="'Show icons'"
                                            @click="$emit('update-useIcons')"
                                        >
                                        </v-switch>
                                    </v-list-item-subtitle>
                                </v-list-item-content>
                            </v-list-item>
                        </v-list>
                    </v-card-title>

                    <v-divider></v-divider>

                    <v-card-text style="flex: 1; overflow-y: auto;" class="pa-0">
                        <v-list dense>
                            <div v-if="newFixChoices.length > 0">
                                <v-list-item
                                    v-for="(item) in newFixChoices.filter(c => c.fixedPosition)" :key="item.code"
                                    class="list-group-item pl-0"
                                    :class="'grey lighten-4'"
                                >
                                    <v-list-item-avatar>
                                        <v-icon>drag_indicator</v-icon>
                                    </v-list-item-avatar>
                                    <v-list-item-content>
                                        <v-list-item-subtitle>
                                            {{ translatedChoices.find(f => f.code === item.code).name }}
                                        </v-list-item-subtitle>
                                    </v-list-item-content>
                                    <v-list-item-action>
                                        <v-switch
                                            color="primary"
                                            v-model="item.enabled"
                                            :disabled="true"
                                        ></v-switch>
                                    </v-list-item-action>
                                </v-list-item>
                            </div>

                            <draggable class="list-group" v-model="compChoices" v-bind="dragOptions">
                                <transition-group type="transition">
                                    <div v-for="(item, i) in compChoices" :key="item.code" :class="!item.fixedPosition ? 'drag-handle' : ''">
                                        <v-hover>
                                            <template v-slot="{ hover }">
                                                <v-list-item
                                                    class="list-group-item pl-0"
                                                    :class="hover ? 'grey lighten-4' : 'white'"
                                                >
                                                    <v-list-item-avatar>
                                                        <v-icon>drag_indicator</v-icon>
                                                    </v-list-item-avatar>
                                                    <v-list-item-content>
                                                        <v-list-item-subtitle>
                                                            {{ translatedChoices.find(f => f.code === item.code).name }}
                                                        </v-list-item-subtitle>
                                                    </v-list-item-content>
                                                    <v-list-item-action>
                                                        <v-switch
                                                            color="primary"
                                                            v-model="item.enabled"
                                                            @click="onEnableChoice"
                                                        ></v-switch>
                                                    </v-list-item-action>
                                                </v-list-item>
                                            </template>
                                        </v-hover>
                                        <div v-if="dividerLimit(i+1)">
                                            <v-subheader>{{ $t("general.expanded_columns") }}</v-subheader>
                                            <v-divider></v-divider>
                                        </div>
                                    </div>
                                </transition-group>
                            </draggable>
                        </v-list>
                    </v-card-text>
                </v-card>
        </v-col>
        </div>
    </div>
</template>

<script>
import draggable from "vuedraggable";

export default {
    props: {
        choices: {
            type: Array, // [{ code: '', name: '', enabled: false }, ...]
            required: true
        },

        title: String,
        description: String,
        showApplyButton: {
            type: Boolean,
            default: false
        },
        icon: {
            type: String,
            default: "more_vert"
        },
        openOnHover: {
            type: Boolean,
            default: true
        },
        height: {
            type: String,
            default: "500px"
        },
        width: {
            type: String,
            default: "300px"
        },
        dark: {
            type: Boolean,
            default: false
        },
        isActive: {
            type: Boolean,
            default: false
        },
        useDrawerStyle: {
            type: Boolean,
            default: false
        },
        minSelection: {
            type: Number,
            default: 0
        },
        showIconSwitch: {
            type: Boolean,
            default: false
        }
    },
    components: {
        draggable
    },
    data() {
        return {
            menu: false,
            newChoices: [],
            newFixChoices: [],
            originalChoices: [],
            selectedItem: null,
            useIcons: true
        };
    },
    computed: {
        dragOptions() {
            return {
                animation: 200,
                group: "description",
                disabled: false,
                ghostClass: "ghost",
                handle: ".drag-handle"
            };
        },
        translatedChoices() {
            return this.choices.map(f => {
                return {
                    ...f,
                    name: this.$t(f.tag)
                };
            });
        },
        compChoices:{
            get(){
                return this.newChoices;
            },
            set(value){
                const sortedChoices = [...this.newFixChoices, ...value];
                this.$emit("changed", JSON.parse(JSON.stringify(sortedChoices)));
                this.newChoices = value;
            }
        }
    },
    mounted() {},
    watch: {
        // newChoices: {
        //     handler(newChoices) {
        //         const sortedChoices = [...newChoices].sort((a, b) => {
        //             if (a?.fixedPosition && !b?.fixedPosition) return -1;
        //             if (!a?.fixedPosition && b?.fixedPosition) return 1;
        //             return 0;
        //         })
        //         this.$emit("changed", JSON.parse(JSON.stringify(newChoices)));
        //         // this.$emit("changed", JSON.parse(JSON.stringify(sortedChoices)));
        //     },
        //     deep: true
        // },
        menu(value) {
            // if didn't click apply, when close menu, reset choices to originalChoices
            if (!value & this.showApplyButton) {
                this.newChoices = JSON.parse(
                    JSON.stringify(this.originalChoices)
                );
            }
        },
        isActive(value) {
            if (value) {
                this.loadMenu();
                this.menu = true;
                // if (this.useDrawerStyle) {
                //     this.sortChoices();
                // }
            }
        }
    },
    methods: {
        onApplied() {
            this.originalChoices = JSON.parse(JSON.stringify(this.newChoices));
            this.$emit("applied", JSON.parse(JSON.stringify(this.newChoices)));
            this.menu = false;
        },
        loadMenu() {
            let _choices = JSON.parse(JSON.stringify(this.choices));
            //sort by fixedPosition
            _choices.sort((a, b) => {
                if (a.enabled && !b.enabled) return -1;
                if (!a.enabled && b.enabled) return 1;
                return 0;
            });
            _choices.sort((a, b) => {
                if (a?.fixedPosition && !b?.fixedPosition) return -1;
                if (!a?.fixedPosition && b?.fixedPosition) return 1;
                return 0;
            });
            this.newChoices = _choices.filter(c => !c.fixedPosition);
            this.newFixChoices = _choices.filter(c => c.fixedPosition);
            this.originalChoices = _choices.map(item => item);
        },
        dividerLimit(i) {
            let numOfFixedPositions = this.newFixChoices.filter(c => c?.fixedPosition).length;
            return this.minSelection > 0 && (numOfFixedPositions === this.minSelection || numOfFixedPositions + i === this.minSelection);
        },
        onEnableChoice() {
            this.compChoices = this.newChoices;
        },
        sortChoices() {
            const sortedChoices = [...this.newChoices].sort((a, b) => {
                if (a.enabled && !b.enabled) return -1;
                if (!a.enabled && b.enabled) return 1;
                return 0;
            });
            this.newChoices = sortedChoices;
        }
    }
};
</script>

<style scoped>
.flip-list-move {
    transition: transform 0.5s;
}
.no-move {
    transition: transform 0s;
}
.ghost {
    opacity: 0.5;
    background: #c8ebfb;
}
.list-group {
    min-height: 10px;
}
.list-group-item {
    cursor: move;
}
.list-group-item i {
    cursor: pointer;
}
.bg-active {
    background-color: white;
    color: grey;
}
.h-100 {
    height: 100%;
}
</style>
