<template>
    <v-row align="start" class="align-center" dense>
        <v-col cols="12" xs="12" sm="12" md="12" lg="12" xl="12">
            <v-select
                v-model="codeSelected"
                :items="dynamicFilters"
                :label="$t('trips.filter')"
                item-text="name"
                item-value="code"
                clearable
                :readonly="isReadOnly"
                :disabled="isReadOnly"
                dense
                outlined
                color="primary"
                hide-details
                :menu-props="{
                    offsetX: true,
                    right: true
                }"
                @change="updateCodeSelected"
            ></v-select>
        </v-col>
        <v-col cols="12" xs="12" sm="12" md="12" lg="12" xl="12">
            <!-- ?Fake field -->
            <v-text-field
                v-if="codeSelected === '' || codeSelected === null"
                v-model="menuObject['']"
                :label="getFilterChoiceNameByCode('')"
                clearable
                hide-details
                dense
                outlined
                disabled
            ></v-text-field>

            <!-- Name -->
            <v-text-field
                v-if="codeSelected === 'name'"
                v-model="menuObject['nameIcontains']"
                :label="getFilterChoiceNameByCode('name')"
                clearable
                hide-details
                dense
                outlined
            ></v-text-field>

            <!-- City -->
            <v-text-field
                v-if="codeSelected === 'city'"
                v-model="menuObject['cityIcontains']"
                :label="getFilterChoiceNameByCode('city')"
                clearable
                hide-details
                dense
                outlined
            ></v-text-field>

            

            <!-- Country -->
            <v-text-field
                v-if="codeSelected === 'country'"
                v-model="menuObject['countryIcontains']"
                :label="getFilterChoiceNameByCode('country')"
                clearable
                hide-details
                dense
                outlined
            ></v-text-field>

            <!-- Tags -->
            <v-select
                v-if="codeSelected === 'tags'"
                v-model="menuObject['tagsIcontains']"
                :items="tagsChoices"
                :label="getFilterChoiceNameByCode('tags')"
                item-text="text"
                item-value="value"
                clearable
                hide-details
                dense
                outlined
                color="primary"
            ></v-select>

            <!-- Tenant Partner Name -->
            <v-text-field
                v-if="codeSelected === 'tenantPartnerName'"
                v-model="menuObject['tenantPartnerNameIcontains']"
                :label="getFilterChoiceNameByCode('tenantPartnerName')"
                clearable
                hide-details
                dense
                outlined
            ></v-text-field>

            <!-- Tenant Partner Partner Type -->
            <v-select
                v-if="codeSelected === 'tenantPartnerPartnerType'"
                v-model="menuObject['tenantPartnerPartnerTypeIexact']"
                :items="tenantPartnerPartnerTypeChoices"
                :label="getFilterChoiceNameByCode('tenantPartnerPartnerType')"
                clearable
                hide-details
                dense
                outlined
            ></v-select>

            <!-- Tracker Stock Location -->
            <v-select
                v-if="codeSelected === 'isTrackerStockLocation'"
                v-model="menuObject['isTrackerStockLocation']"
                :items="trackerStockLocationChoices"
                :label="getFilterChoiceNameByCode('isTrackerStockLocation')"
                clearable
                hide-details
                dense
                outlined
            ></v-select>

        </v-col>
    </v-row>
</template>

<script>
import helper from "@/utils/helper.js";
import moment from "moment";
import gql from "graphql-tag";

export default {
    name: "TrackersFilterMenuTemplate",
    components: {
    },
    props: {
        value: {
            type: Object,
            required: true,
            default: () => ({})
        },
        isReadOnly: {
            type: Boolean,
            required: false,
            default: false
        },
        currentCode: {
            type: String,
            required: false,
            default: null
        },
        tab: {
            type: String,
            required: false,
            default: null
        }
    },
    data() {
        return {
            codeSelected: null,
            defaultFilterChoices: [
                {
                    code: "name",
                    name: "Name",
                    tag: "headers.name",
                    enabled: true
                },
                {
                    code: "city",
                    name: "City",
                    tag: "headers.city",
                    enabled: true
                },
                {
                    code: "country",
                    name: "Country",
                    tag: "headers.country",
                    enabled: true
                },
                {
                    code: "tags",
                    name: "Tags",
                    tag: "headers.tags",
                    enabled: true
                },
                {
                    code: "tenantPartnerName",
                    name: "Tenant Partner Name",
                    tag: "headers.collaborator_name",
                    enabled: true
                },
                {
                    code: "tenantPartnerPartnerType",
                    name: "Tenant Partner Partner Type",
                    tag: "headers.collaborator_type1",
                    enabled: true
                },
                {
                    code: "isTrackerStockLocation",
                    name: "Tracker Stock Location",
                    tag: "headers.is_tracker_stock_location",
                    enabled: true
                }
            ],
            defaulttagsChoices: [
                {
                    text: this.$t("headers.warehouse"),
                    value: "warehouse"
                },
                {
                    text: this.$t("headers.border_post"),
                    value: "border_post"
                },
                {
                    text: this.$t("headers.cold_store"),
                    value: "cold_store"
                },
                {
                    text: this.$t("headers.empty_depot"),
                    value: "empty_depot"
                }
            ],
            partnersTypes: [
                {
                    text: this.$t("headers.consignor"),
                    value: "consignor"
                },
                {
                    text: this.$t("headers.consignee"),
                    value: "consignee"
                },
                {
                    text: this.$t("headers.forwarder"),
                    value: "forwarder"
                },
                {
                    text: this.$t("home.other"),
                    value: "other"
                }
            ],
        };
    },
    computed: {
        menuObject: {
            get() {
                return this.value || {};
            },
            set(val) {
                this.$emit("input", val);
            }
        },
        dynamicFilters() {
            return this.defaultFilterChoices
                .filter(filter => {
                    if (this.tab === 'myLocations') {
                        return filter.code !== 'tenantPartnerName' && filter.code !== 'tenantPartnerPartnerType' && filter.code !== 'isTrackerStockLocation';
                    }
                    if (this.tab === 'partnerLocations') {
                        return filter.code !== 'tags'
                    }
                    if (this.tab === 'publicLocations') {
                        return filter.code !== 'tags' && filter.code !== 'tenantPartnerName' && filter.code !== 'tenantPartnerPartnerType' && filter.code !== 'isTrackerStockLocation';
                    }
                    if (this.tab === 'weather') {
                        return filter.code !== 'tags'&& filter.code !== 'scope' && filter.code !== 'tenantPartnerName' && filter.code !== 'tenantPartnerPartnerType' && filter.name !== 'name' && filter.code !== 'isTrackerStockLocation';
                    }
                    return filter.enabled;
                })
                .sort((a, b) => a.name.localeCompare(b.name));
        },
        isStaff() {
            return helper.me()?.isStaff || false;
        },
        tagsChoices() {
            return this.defaulttagsChoices;
        },
        statusChoices() {
            return [
                { text: this.$t("general.enabled"), value: true },
                { text: this.$t("general.disabled"), value: false }
            ];
        },
        trackerStockLocationChoices() {
            return [
                { text: this.$t("general.yes"), value: true },
                { text: this.$t("general.no"), value: false }
            ];
        },
        tenantPartnerPartnerTypeChoices() {
            return this.partnersTypes;
        }
    },
    watch: {
        currentCode: {
            handler(val) {
                this.codeSelected = val;
            },
            immediate: true
        },
    },
    methods: {
        getFilterChoiceNameByCode(code) {
            return "Value";
        },
        updateCodeSelected(val) {
            this.$emit("update:currentCode", val);
        },
        
    }
};
</script>
