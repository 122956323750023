<template>
    <v-card elevation="0" style="flex: 1; overflow: hidden;" outlined v-resize="onResize" color="transparent">
        <v-card-text class="px-5 pb-1">
            <v-row v-if="!me.isPartnerUser">
                <v-col cols="12" class="d-flex justify-end" div v-if="hasData">
                    <template v-if="selectMode">
                        <!--
                        <v-btn
                            v-if="!me.isPartnerUser"
                            color="primary"
                            small
                            class="mr-2"
                            @click="
                                () => {
                                    selectedAlertsId = tripAlerts.edges.map(alert => alert.node.id);
                                    saveNoteBatch();
                                }
                            "
                        >
                            {{ $t("dashboard.resolve_all") }}
                        </v-btn>
                        -->
                        <v-btn
                            color="primary"
                            small
                            class="mr-2"
                            :disabled="loading || resolvedAlerts.length > 0"
                            @click="enableSelectAll"
                        >
                            {{ selectedAlertsId.length === 0 ? $t("dashboard.select_all") : $t("general.deselect_all") }}
                        </v-btn>
                        <v-btn
                            color="primary"
                            small
                            @click="saveNoteBatch"
                            :disabled="loading || selectedAlertsId.length === 0 || resolvedAlerts.length > 0"
                        >
                            {{ $t("general.resolve") }}
                        </v-btn>
                    </template>
                </v-col>
            </v-row>
        </v-card-text>

        <div style="height: 97%; overflow-y: auto; ">
            <v-card-text class="px-5">
                <v-row dense>
                    <v-col cols="12" class="py-0">
                        <div v-if="tripAlertsIsLoading && !hasData">
                            <LoadingBar></LoadingBar>
                        </div>
                        <div v-else>
                            <div v-if="hasData">
                                <v-card
                                    v-for="tripAlert in tripAlerts.edges"
                                    :key="tripAlert.node.id"
                                    class="mb-4 rounded-lg px-3"
                                    outlined
                                    flat
                                >
                                    <v-card-text class="py-1 pr-1">
                                        <v-row class="d-flex justify-center py-1" flat>
                                            <v-col cols="12">
                                                <v-row dense>
                                                    <v-col
                                                        v-for="item in mainCard(tripAlert)"
                                                        :key="item.text"
                                                        :cols="item.cols.xs"
                                                        :xs="item.cols.xs"
                                                        :sm="item.cols.sm"
                                                        :md="item.cols.md"
                                                        :lg="item.cols.lg"
                                                        :xl="item.cols.xl"
                                                    >
                                                        <div class="">
                                                            <div class="textHeader text-truncate">
                                                                {{ item.title }}
                                                            </div>
                                                            <div
                                                                class="textBody text-truncate"
                                                                :style="[item.style, { height: '25px' }]"
                                                            >
                                                                <template v-if="item.tag === 'alert'">
                                                                    <v-chip small color="warning">
                                                                        <div class="text-truncate">
                                                                            {{ item.value }}
                                                                        </div>
                                                                    </v-chip>
                                                                </template>
                                                                <template v-else-if="item.tag === 'tracker' || item.tag === 'freight'">
                                                                    <v-tooltip bottom>
                                                                        <template v-slot:activator="{ on, attrs }">
                                                                            <div v-bind="attrs" v-on="on">
                                                                                <div class="text-truncate">
                                                                                    {{ item.value }}
                                                                                </div>
                                                                            </div>
                                                                        </template>
                                                                        <span class="class-text-tooltip" @click.stop.prevent style="user-select: text; white-space: pre-line;">
                                                                            <div v-if="item.value">
                                                                                {{ item.value }}
                                                                            </div>
                                                                        </span>
                                                                    </v-tooltip>
                                                                </template>
                                                                <template v-else>
                                                                    <span>
                                                                        {{ item.value }}
                                                                    </span>
                                                                </template>
                                                            </div>
                                                        </div>
                                                    </v-col>
                                                    <v-col cols="12" xs="12" sm="6" md="2" lg="2" xl="2">
                                                        <!-- <div class="d-flex justify-end"> -->
                                                            <div
                                                                class="d-flex justify-end"
                                                                :style="[{ height: '35px' }]"
                                                            >
                                                                <v-tooltip bottom>
                                                                    <template v-slot:activator="{ on, attrs }">
                                                                        <v-btn
                                                                            v-bind="attrs"
                                                                            v-on="on"
                                                                            icon
                                                                            x-small
                                                                            @click="redirect(tripAlert.node.trip.id)"
                                                                        >
                                                                            <v-icon>open_in_new</v-icon>
                                                                        </v-btn>
                                                                    </template>
                                                                    <span>{{ $t("general.redirect") }}</span>
                                                                </v-tooltip>
                                                                <AlertRuleDetailDialog
                                                                    :tripAlertId="tripAlert.node.id"
                                                                    :alertTime="tripAlert.node.alertTime"
                                                                    :deviceTime="tripAlert.node.deviceTime"
                                                                    :dashboardTripId="tripAlert.node.trip.id"
                                                                    :tripId="tripAlert.node.trip.id"
                                                                    :xSmall="true"
                                                                ></AlertRuleDetailDialog>
                                                                <template v-if="!selectMode">
                                                                    <v-tooltip bottom>
                                                                        <template
                                                                            v-slot:activator="{ on, attrs }"
                                                                            v-if="!me.isPartnerUser"
                                                                        >
                                                                            <v-btn
                                                                                icon
                                                                                v-bind="attrs"
                                                                                v-on="on"
                                                                                :loading="
                                                                                    loading &&
                                                                                        selectedAlertsId.includes(
                                                                                            tripAlert.node.id
                                                                                        )
                                                                                "
                                                                                v-if="
                                                                                    !resolvedAlerts.includes(
                                                                                        tripAlert.node.id
                                                                                    )
                                                                                "
                                                                                @click="
                                                                                    () => {
                                                                                        selectedAlertsId.push(
                                                                                            tripAlert.node.id
                                                                                        );
                                                                                        saveNoteBatch();
                                                                                    }
                                                                                "
                                                                                x-small
                                                                            >
                                                                                <v-icon>done</v-icon>
                                                                            </v-btn>
                                                                            <v-btn
                                                                                icon
                                                                                v-bind="attrs"
                                                                                v-on="on"
                                                                                class="grey lighten-5"
                                                                                color="success"
                                                                                outlined
                                                                                x-small
                                                                                v-else
                                                                            >
                                                                                <v-icon>done</v-icon>
                                                                            </v-btn>
                                                                        </template>
                                                                        <span>{{ $t("general.resolve") }}</span>
                                                                    </v-tooltip>
                                                                </template>
                                                                <template v-else>
                                                                    <v-checkbox
                                                                        v-if="
                                                                            !resolvedAlerts.includes(
                                                                                tripAlert.node.id
                                                                            ) &&
                                                                                !loadingItems.includes(
                                                                                    tripAlert.node.id
                                                                                )
                                                                        "
                                                                        v-model="selectedAlertsId"
                                                                        :disabled="loading"
                                                                        :value="tripAlert.node.id"
                                                                        class="ma-0 pa-0 smaller-checkbox"
                                                                        hide-details
                                                                        :ripple="false"
                                                                    ></v-checkbox>
                                                                    <v-btn
                                                                        v-if="loadingItems.includes(tripAlert.node.id)"
                                                                        :loading="
                                                                            loadingItems.includes(tripAlert.node.id)
                                                                        "
                                                                        icon
                                                                        class="grey lighten-5"
                                                                        outlined
                                                                        x-small
                                                                    >
                                                                        <v-icon>done</v-icon>
                                                                    </v-btn>
                                                                    <v-btn
                                                                        v-if="
                                                                            resolvedAlerts.includes(tripAlert.node.id)
                                                                        "
                                                                        icon
                                                                        class="grey lighten-5"
                                                                        color="success"
                                                                        outlined
                                                                        x-small
                                                                    >
                                                                        <v-icon>done</v-icon>
                                                                    </v-btn>
                                                                </template>
                                                            </div>
                                                        <!-- </div> -->
                                                    </v-col>
                                                </v-row>
                                            </v-col>
                                            <v-col cols="12" class="px-0 pt-0" v-if="tripAlert.node.trip.transportMode === 'SEA'">
                                                <v-row dense>
                                                    <v-col cols="12" xs="12" sm="6" md="6" lg="6" xl="6">
                                                        <div class="d-flex flex-row align-center" style="overflow-x: auto; white-space: nowrap;">
                                                            <v-img 
                                                                :src="require('@/assets/container.png')" 
                                                                max-width="20" 
                                                                max-height="20" 
                                                                contain
                                                                class="mr-2"
                                                                style="flex-shrink: 0;"
                                                            ></v-img>
                                                            <div v-for="(item, idxc) in localFooterDetails(tripAlert.node.trip)" :key="idxc" class="textTruncate" style="flex-shrink: 0;">
                                                                <div
                                                                    class="classText text-uppercase"
                                                                    @click.stop.prevent
                                                                    style="user-select: text;"
                                                                >
                                                                    <v-tooltip bottom v-if="item.valueDisplay.length > 0">
                                                                        <template
                                                                            v-slot:activator="{
                                                                                on,
                                                                                attrs
                                                                            }"
                                                                        >
                                                                            <v-chip
                                                                                class="ma-1"
                                                                                label
                                                                                x-small
                                                                                v-bind="attrs"
                                                                                v-on="on"
                                                                                :color="item.color"
                                                                                style="flex-shrink: 0;"
                                                                            >
                                                                                <v-icon size="8" class="mr-1" left :color="item.colorIcon">
                                                                                    circle
                                                                                </v-icon>
                                                                                {{ item.value }}
                                                                            </v-chip>
                                                                        </template>
                                                                        <span class="class-text-tooltip" @click.stop.prevent style="user-select: text;">
                                                                            <div v-if="item.valueDisplay">
                                                                                {{ item.valueDisplay }}
                                                                            </div>
                                                                        </span>
                                                                    </v-tooltip>
                                                                    <v-chip
                                                                        class="ma-1"
                                                                        label
                                                                        x-small
                                                                        :color="item.color"
                                                                        v-else
                                                                        style="flex-shrink: 0;"
                                                                    >
                                                                        {{ item.value }}
                                                                    </v-chip>
                                                                </div>
                                                            </div>
                                                            <div v-if="(isMdAndDown || footerDetailsRow(tripAlert.node.trip).length > 3) && numberOfFreight(tripAlert.node.trip) > 0" class="textTruncate">
                                                                <div class="classText text-uppercase">
                                                                    <v-tooltip bottom>
                                                                        <template v-slot:activator="{ on, attrs }">
                                                                            <v-chip class="ma-2" label x-small v-bind="attrs" v-on="on">
                                                                                {{ numberOfFreight(tripAlert.node.trip) }} {{ $t("general.more") }}
                                                                            </v-chip>
                                                                        </template>
                                                                        <span class="class-text-tooltip" @click.stop.prevent style="user-select: text; white-space: pre-line;">
                                                                            {{ moreFreight(tripAlert.node.trip) }}
                                                                        </span>
                                                                    </v-tooltip>
                                                                </div>
                                                            </div>
                                                        </div>
                                                    </v-col>
                                                    <v-col 
                                                        v-if="tripAlert.node.trip.tripSeaTransportSet.edges.length > 0"
                                                        cols="12" 
                                                        xs="12" 
                                                        sm="6" 
                                                        md="6" 
                                                        lg="6" 
                                                        xl="6"
                                                    >
                                                        <div class="d-flex flex-row align-center" style="overflow-x: auto; white-space: nowrap;" >
                                                            <v-img 
                                                                :src="require('@/assets/directions_boat.png')" 
                                                                max-width="20" 
                                                                max-height="20" 
                                                                contain
                                                                class="mr-2"
                                                                style="flex-shrink: 0;"
                                                            ></v-img>
                                                            <div v-if="!hasVessel(tripAlert.node.trip)">
                                                                <div class="textTruncate">
                                                                    <v-chip
                                                                        class="ma-1"
                                                                        label
                                                                        x-small
                                                                        style="flex-shrink: 0;"
                                                                    >
                                                                        {{ $t("general.unknown") }}
                                                                    </v-chip>
                                                                </div>
                                                            </div>
                                                            <div v-else class="d-flex">
                                                                <div class="d-flex flex-row align-center" style="overflow-x: auto;" v-for="(item, idxc) in tripAlert.node.trip.tripSeaTransportSet.edges" :key="idxc">
                                                                    <div class="textTruncate">
                                                                        <v-chip
                                                                            v-if="item.node.vessel"
                                                                            class="ma-1"
                                                                            label
                                                                            x-small
                                                                            style="flex-shrink: 0;"
                                                                        >
                                                                            {{ item.node.vessel.name }}
                                                                        </v-chip>
                                                                    </div>
                                                                </div>
                                                            </div>
                                                        </div>
                                                    </v-col>
                                                </v-row>
                                            </v-col>
                                            <v-col cols="12" class="px-0 pt-0" v-if="tripAlert.node.trip.transportMode === 'AIR'">
                                                <v-row dense>
                                                    <v-col cols="12" xs="12" sm="6" md="6" lg="6" xl="6">
                                                        <div class="d-flex flex-row align-center" style="overflow-y: hidden; white-space: nowrap;">
                                                            <v-icon 
                                                                size="20"
                                                                class="mr-2"
                                                            >
                                                            flight
                                                            </v-icon>
                                                            <div v-for="(item, idxc) in localFooterDetails(tripAlert.node.trip)" :key="idxc" class="textTruncate" style="flex-shrink: 0;">
                                                                <div
                                                                    class="classText text-uppercase"
                                                                    @click.stop.prevent
                                                                    style="user-select: text;"
                                                                >
                                                                    <v-tooltip bottom v-if="item.valueDisplay.length > 0">
                                                                        <template
                                                                            v-slot:activator="{
                                                                                on,
                                                                                attrs
                                                                            }"
                                                                        >
                                                                            <v-chip
                                                                                class="ma-1"
                                                                                label
                                                                                x-small
                                                                                v-bind="attrs"
                                                                                v-on="on"
                                                                                :color="item.color"
                                                                                style="flex-shrink: 0;"
                                                                            >
                                                                                <v-icon size="8" class="mr-1" left :color="item.colorIcon">
                                                                                    circle
                                                                                </v-icon>
                                                                                {{ item.value }}
                                                                            </v-chip>
                                                                        </template>
                                                                        <span class="class-text-tooltip" @click.stop.prevent style="user-select: text;">
                                                                            <div v-if="item.valueDisplay">
                                                                                {{ item.valueDisplay }}
                                                                            </div>
                                                                        </span>
                                                                    </v-tooltip>
                                                                    <v-chip
                                                                        class="ma-1"
                                                                        label
                                                                        x-small
                                                                        :color="item.color"
                                                                        v-else
                                                                        style="flex-shrink: 0;"
                                                                    >
                                                                        {{ item.value }}
                                                                    </v-chip>
                                                                </div>
                                                            </div>
                                                            <div v-if="(isMdAndDown || footerDetailsRow(tripAlert.node.trip).length > 3) && numberOfFreight(tripAlert.node.trip) > 0" class="textTruncate">
                                                                <div class="classText text-uppercase">
                                                                    <v-tooltip bottom>
                                                                        <template v-slot:activator="{ on, attrs }">
                                                                            <v-chip class="ma-2" label x-small v-bind="attrs" v-on="on">
                                                                                {{ numberOfFreight(tripAlert.node.trip) }} {{ $t("general.more") }}
                                                                            </v-chip>
                                                                        </template>
                                                                        <span class="class-text-tooltip" @click.stop.prevent style="user-select: text; white-space: pre-line;">
                                                                            {{ moreFreight(tripAlert.node.trip) }}
                                                                        </span>
                                                                    </v-tooltip>
                                                                </div>
                                                            </div>
                                                        </div>
                                                    </v-col>
                                                </v-row>
                                            </v-col>
                                            <v-col cols="12" class="px-0 pt-0" v-if="tripAlert.node.trip.transportMode === 'ROAD'">
                                                <v-row dense>
                                                    <v-col cols="12" xs="12" sm="6" md="6" lg="6" xl="6">
                                                        <div class="d-flex flex-row align-center" style="overflow-y: hidden; white-space: nowrap;">
                                                            <v-icon 
                                                                size="20"
                                                                class="mr-2"
                                                            >
                                                            local_shipping
                                                            </v-icon>
                                                            <div v-for="(item, idxc) in localFooterDetails(tripAlert.node.trip)" :key="idxc" class="textTruncate" style="flex-shrink: 0;">
                                                                <div
                                                                    class="classText text-uppercase"
                                                                    @click.stop.prevent
                                                                    style="user-select: text;"
                                                                >
                                                                    <v-tooltip bottom v-if="item.valueDisplay.length > 0">
                                                                        <template
                                                                            v-slot:activator="{
                                                                                on,
                                                                                attrs
                                                                            }"
                                                                        >
                                                                            <v-chip
                                                                                class="ma-1"
                                                                                label
                                                                                x-small
                                                                                v-bind="attrs"
                                                                                v-on="on"
                                                                                :color="item.color"
                                                                                style="flex-shrink: 0;"
                                                                            >
                                                                                <v-icon size="8" class="mr-1" left :color="item.colorIcon">
                                                                                    circle
                                                                                </v-icon>
                                                                                {{ item.value }}
                                                                            </v-chip>
                                                                        </template>
                                                                        <span class="class-text-tooltip" @click.stop.prevent style="user-select: text;">
                                                                            <div v-if="item.valueDisplay">
                                                                                {{ item.valueDisplay }}
                                                                            </div>
                                                                        </span>
                                                                    </v-tooltip>
                                                                    <v-chip
                                                                        class="ma-1"
                                                                        label
                                                                        x-small
                                                                        :color="item.color"
                                                                        v-else
                                                                        style="flex-shrink: 0;"
                                                                    >
                                                                        {{ item.value }}
                                                                    </v-chip>
                                                                </div>
                                                            </div>
                                                            <div v-if="(isMdAndDown || footerDetailsRow(tripAlert.node.trip).length > 3) && numberOfFreight(tripAlert.node.trip) > 0" class="textTruncate">
                                                                <div class="classText text-uppercase">
                                                                    <v-tooltip bottom>
                                                                        <template v-slot:activator="{ on, attrs }">
                                                                            <v-chip class="ma-2" label x-small v-bind="attrs" v-on="on">
                                                                                {{ numberOfFreight(tripAlert.node.trip) }} {{ $t("general.more") }}
                                                                            </v-chip>
                                                                        </template>
                                                                        <span class="class-text-tooltip" @click.stop.prevent style="user-select: text; white-space: pre-line;">
                                                                            {{ moreFreight(tripAlert.node.trip) }}
                                                                        </span>
                                                                    </v-tooltip>
                                                                </div>
                                                            </div>
                                                        </div>
                                                    </v-col>
                                                </v-row>
                                            </v-col>
                                        </v-row>
                                    </v-card-text>
                                </v-card>
                            </div>
                            <div v-else>
                                <v-row class="d-flex align-center justify-center">
                                    <v-col cols="12" xs="12" sm="12" md="6" lg="5" xl="5" class="text-center">
                                        <v-img class="mt-3" :src="require('@/assets/empty_data_small.png')"></v-img>
                                        <v-card flat color="transparent">
                                            <v-card-title class="justify-center">
                                                <div class="textTitle">
                                                    {{ $t("dashboard.no_alerts") }}
                                                </div>
                                            </v-card-title>
                                        </v-card>
                                    </v-col>
                                </v-row>
                            </div>
                        </div>
                    </v-col>
                </v-row>
                <!-- Footer or Actions Section -->
                <v-card-actions class="justify-center">
                    <v-btn
                        v-if="hasMoreData"
                        v-intersect="onLoadMoreTriggerIntersect"
                        :disabled="!hasMoreData"
                        :loading="$apollo.queries.tripAlerts.loading"
                        plain
                        class="mb-5"
                        @click="loadMore"
                    >
                        {{ $t("loading.load_more") }}
                    </v-btn>
                </v-card-actions>
            </v-card-text>
        </div>
        <v-dialog v-model="editAlertsDialog" width="500">
            <v-card>
                <v-card-title class="primary">
                    <v-icon color="white">notifications</v-icon>
                    <div class="white--text">
                        {{ $t("trips.edit_alerts") }}
                    </div>
                </v-card-title>
                <v-card-text>
                    <v-form v-model="editAlertsValidate">
                        <h3 class="mt-2">
                            {{ $t("trips.sure_alerts") }}
                        </h3>
                        <v-textarea
                            v-model="note"
                            counter="500"
                            outlined
                            :placeholder="$t('trips.add_notes')"
                            :rules="[rules.maxLength(500)]"
                        >
                        </v-textarea>
                    </v-form>
                </v-card-text>
                <v-card-actions>
                    <v-spacer></v-spacer>
                    <v-btn text @click="editAlertsDialog = false">{{ $t("general.cancel") }}</v-btn>
                    <v-btn
                        :loading="loading"
                        :disabled="!editAlertsValidate"
                        elevation="0"
                        color="primary"
                        @click="saveBatch"
                        >{{ $t("general.save") }}</v-btn
                    >
                </v-card-actions>
            </v-card>
        </v-dialog>
    </v-card>
</template>

<script>
import gql from "graphql-tag";
import helper from "../../utils/helper";
import LoadingBar from "@/components/base/LoadingBar.vue";
import AlertRuleDetailDialog from "@/components/alertrules/other/AlertRuleDetailDialog.vue";

export default {
    name: "AlertsTable",
    props: {
        isShowPreview: {
            type: Boolean,
            required: false,
            default: false
        },
        orderBy: {
            type: String,
            required: false,
            default: null
        },
        tripFilter: {
            type: Object,
            required: false,
            default: () => {
                return {};
            }
        },
        openResolveOptions: {
            type: Boolean,
            required: false,
            default: false
        }
    },
    components: {
        LoadingBar,
        AlertRuleDetailDialog
    },
    apollo: {
        tripAlerts: {
            query: gql`
                query tripAlerts(
                    $isResolved: Boolean
                    $alertTimeIsnull: Boolean
                    $first: Int
                    $after: String
                    $before: String
                    $last: Int
                    $orderBy: String
                    $search: String
                    $trip_ReferenceNumber_Icontains: String
                    $trip_Status_Icontains: String
                    $tenantAlertRule_Name_Icontains: String
                    $tenantAlertRule_Tag_Icontains: String
                    $tripFreight_ReferenceNumber_Icontains: String
                    $tripFreight_ContainerNumber_Icontains: String
                    $tripFreight_AirWaybillNumber_Icontains: String
                    $tripFreightTracker_Tracker_SerialNumber_Icontains: String
                    $trip_TenantCarrier: ID
                    $trip_TripSeaTransportSet_Vessel: ID
                    $alertTime_Gte: DateTime
                    $alertTime_Lte: DateTime
                    $trip_PlannedDepartureDate_Gte: DateTime
                    $trip_PlannedDepartureDate_Lte: DateTime
                    $trip_PlannedArrivalDate_Gte: DateTime
                    $trip_PlannedArrivalDate_Lte: DateTime
                    $pol: String
                    $pod: String
                    $transshipment: String
                    $alertCode: [String]
                    $trip_TripFreightSet_Goods_Icontains: String
                    $trip_Trade_Icontains: String
                    $trip_TripFreightSet_Contact_Icontains: String
                    $trip_TripFreightSet_OrderNumber_Iexact: String
                ) {
                    tripAlerts(
                        isResolved: $isResolved
                        alertTime_Isnull: $alertTimeIsnull
                        first: $first
                        after: $after
                        before: $before
                        last: $last
                        orderBy: $orderBy
                        search: $search
                        trip_ReferenceNumber_Icontains: $trip_ReferenceNumber_Icontains
                        trip_Status_Icontains: $trip_Status_Icontains
                        tenantAlertRule_Name_Icontains: $tenantAlertRule_Name_Icontains
                        tenantAlertRule_Tag_Icontains: $tenantAlertRule_Tag_Icontains
                        tripFreight_ReferenceNumber_Icontains: $tripFreight_ReferenceNumber_Icontains
                        tripFreight_ContainerNumber_Icontains: $tripFreight_ContainerNumber_Icontains
                        tripFreight_AirWaybillNumber_Icontains: $tripFreight_AirWaybillNumber_Icontains
                        tripFreightTracker_Tracker_SerialNumber_Icontains: $tripFreightTracker_Tracker_SerialNumber_Icontains
                        trip_TenantCarrier: $trip_TenantCarrier
                        trip_TripSeaTransportSet_Vessel: $trip_TripSeaTransportSet_Vessel
                        alertTime_Gte: $alertTime_Gte
                        alertTime_Lte: $alertTime_Lte
                        trip_PlannedDepartureDate_Gte: $trip_PlannedDepartureDate_Gte
                        trip_PlannedDepartureDate_Lte: $trip_PlannedDepartureDate_Lte
                        trip_PlannedArrivalDate_Gte: $trip_PlannedArrivalDate_Gte
                        trip_PlannedArrivalDate_Lte: $trip_PlannedArrivalDate_Lte
                        pol: $pol
                        pod: $pod
                        transshipment: $transshipment
                        alertCode: $alertCode
                        trip_TripFreightSet_Goods_Icontains: $trip_TripFreightSet_Goods_Icontains
                        trip_Trade_Icontains: $trip_Trade_Icontains
                        trip_TripFreightSet_Contact_Icontains: $trip_TripFreightSet_Contact_Icontains
                        trip_TripFreightSet_OrderNumber_Iexact: $trip_TripFreightSet_OrderNumber_Iexact
                    ) {
                        pageInfo {
                            endCursor
                            startCursor
                            hasPreviousPage
                            hasNextPage
                        }
                        edges {
                            node {
                                id
                                alertTime
                                deviceTime
                                isResolved
                                tripFreightTracker {
                                    id
                                    tracker {
                                        id
                                        serialNumber
                                    }
                                    tripFreight {
                                        id
                                        referenceNumber
                                    }
                                }
                                trip {
                                    id
                                    referenceNumber
                                    transportMode
                                    tripFreightSet {
                                        edges {
                                            node {
                                                id
                                                referenceNumber
                                                orderNumber
                                                containerNumber
                                                airWaybillNumber
                                                billOfLadingNumber
                                                additionalInfo
                                                tripFreightTrackerSet {
                                                    edges {
                                                        node {
                                                            id
                                                            tracker {
                                                                id
                                                                serialNumber
                                                                activatedDate
                                                                lastConnectionTime
                                                            }
                                                        }
                                                    }
                                                }
                                            }
                                        }
                                    },
                                    tripSeaTransportSet(orderBy: "sequence") {
                                        edges {
                                            node {
                                                id
                                                vessel {
                                                    id
                                                    name
                                                }
                                            }
                                        }
                                     }
                                }
                                tenantAlertRule {
                                    id
                                    name
                                    isStandardAlert
                                }
                            }
                        }
                    }
                }
            `,
            variables() {
                return {
                    first: this.tripFilter?.first || this.first,
                    isResolved: false,
                    alertTimeIsnull: false,
                    orderBy: "-alertTime",
                    search: this.tripFilter?.search,
                    trip_ReferenceNumber_Icontains: this.tripFilter?.trip_ReferenceNumber_Icontains,
                    trip_Status_Icontains: this.tripFilter?.trip_Status_Icontains,
                    tenantAlertRule_Name_Icontains: this.tripFilter?.tenantAlertRule_Name_Icontains,
                    tenantAlertRule_Tag_Icontains: this.tripFilter?.tenantAlertRule_Tag_Icontains,
                    tripFreight_ReferenceNumber_Icontains: this.tripFilter?.tripFreight_ReferenceNumber_Icontains,
                    tripFreight_ContainerNumber_Icontains: this.tripFilter?.tripFreight_ContainerNumber_Icontains,
                    tripFreight_AirWaybillNumber_Icontains: this.tripFilter?.tripFreight_AirWaybillNumber_Icontains,
                    tripFreightTracker_Tracker_SerialNumber_Icontains: this.tripFilter?.tripFreightTracker_Tracker_SerialNumber_Icontains,
                    trip_TenantCarrier: this.tripFilter?.trip_TenantCarrier,
                    trip_TripSeaTransportSet_Vessel: this.tripFilter?.trip_TripSeaTransportSet_Vessel,
                    alertTime_Gte: this.tripFilter?.alertTime_Gte,
                    alertTime_Lte: this.tripFilter?.alertTime_Lte,
                    trip_PlannedDepartureDate_Gte: this.tripFilter?.plannedDepartureDate_Gte,
                    trip_PlannedDepartureDate_Lte: this.tripFilter?.plannedDepartureDate_Lte,
                    trip_PlannedArrivalDate_Gte: this.tripFilter?.plannedArrivalDate_Gte,
                    trip_PlannedArrivalDate_Lte: this.tripFilter?.plannedArrivalDate_Lte,
                    pol: this.tripFilter?.pol,
                    pod: this.tripFilter?.pod,
                    transshipment: this.tripFilter?.transshipment,
                    alertCode: this.tripFilter?.alertCode,
                    trip_TripFreightSet_Goods_Icontains: this.tripFilter?.goods,
                    trip_Trade_Icontains: this.tripFilter?.trade_Icontains,
                    trip_TripFreightSet_Contact_Icontains: this.tripFilter?.tripFreightSet_Contact_Icontains,
                    trip_TripFreightSet_OrderNumber_Iexact: this.tripFilter?.orderNumber
                };
            },
            fetchPolicy: "cache-and-network",
            nextFetchPolicy: "cache-first",
            update: data => {
                return data.tripAlerts;
            },
            watchLoading(isLoading) {
                this.tripAlertsIsLoading = isLoading;
                this.$emit("loading", isLoading);
                if (!isLoading) {
                    this.resolvedAlerts = [];
                }
            },
            skip() {
                return false;
            },
            debounce: 800,
            pollInterval: 30000
        }
    },
    data() {
        return {
            first: 10,
            shouldLoadMore: false,
            isInitialLoad: true,
            tripAlerts: { edges: [] },
            tableHeight: 800,
            headerChoices: null,
            tableFilter: {
                first: 10,
                after: null
            },
            filter: null,
            tab: null,
            tripAlertsIsLoading: true,
            ind: 0,
            selectedAlertsId: [],
            loading: false,
            selectMode: false,
            resolvedAlerts: [],
            editAlertsDialog: false,
            editAlertsValidate: false,
            note: "",
            rules: {
                required: v => !helper.isEmpty(v) || this.$t("rules.required"),
                listRequired: v => (v || "").length >= 1 || this.$t("rules.required"),
                minLength: len => v => (v || "").length >= len || this.$t("rules.require_at_least") + ` ${len}`,
                maxLength: len => v => (v || "").length <= len || this.$t("rules.too_long")
            },
            loadingItems: []
        };
    },
    created() {
        this.formatDateTime = helper.formatDateTime;
    },
    computed: {
        me() {
            return helper.me();
        },
        hasData() {
            return this.tripAlerts?.edges?.length > 0;
        },
        isSmallScreen() {
            return this.$vuetify.breakpoint.width < 1320;
        },
        isSmAndDown() {
            return this.$vuetify.breakpoint.smAndDown;
        },
        isMdAndDown() {
            return this.$vuetify.breakpoint.mdAndDown;
        },
        isCarrier() {
            return this.type?.toLowerCase() === "carrier";
        },
        hasMoreData() {
            return this.tripAlerts?.pageInfo?.hasNextPage;
        },
        screenSize() {
            let _resposiveView = this.$vuetify.breakpoint.width;
            if (_resposiveView < 650) {
                return "xs";
            } else if (_resposiveView < 1000) {
                return "sm";
            } else if (_resposiveView < 1400) {
                return "md";
            } else if (_resposiveView < 1900) {
                return "lg";
            } else {
                return "xl";
            }
        },
        localFooterDetails() {
            return (trip) => {
                let footerDetails = this.footerDetailsRow(trip);
                
                if (this.isSmAndDown) {
                    return footerDetails.slice(0, 1);
                }
                else if (footerDetails.length > 2) {
                    return footerDetails.slice(0, 2);
                }

                return footerDetails;
            };
        },
        numberOfFreight() {
            return (trip) => {
                const footerDetails = this.footerDetailsRow(trip);
                
                if (this.isSmAndDown && footerDetails.length > 1) {
                    return footerDetails.length - 1;
                }
                if (footerDetails.length > 2) {
                    return footerDetails.length - 2;
                }
                
                return 0;
            };
        },
        moreFreight() {
            return (trip) => {
                const footerDetails = this.footerDetailsRow(trip);
                
                if (this.isSmAndDown) {
                    let items = footerDetails.slice(1).map(item => item.value);
                    return this.formatItemsPerLine(items, 3);
                }
                else if (footerDetails.length > 2) {
                    let items = footerDetails.slice(2).map(item => item.value);
                    return this.formatItemsPerLine(items, 3);
                }
                
                return "";
            };
        }
    },
    watch: {
        orderBy: {
            handler(val) {
                this.tableFilter.orderBy = val;
            },
            immediate: true,
            deep: true
        },
        tripFilter: {
            handler(newValue, oldValue) {
                const keysToCheck = [
                    "alertTime_Gte",
                    "alertTime_Lte",
                    "search",
                    "tenantAlertRule_Name_Icontains",
                    "tenantAlertRule_Tag_Icontains",
                    "tripFreightTracker_Tracker_SerialNumber_Icontains",
                    "tripFreight_AirWaybillNumber_Icontains",
                    "tripFreight_ContainerNumber_Icontains",
                    "tripFreight_ReferenceNumber_Icontains",
                    "trip_ReferenceNumber_Icontains",
                    "trip_Status_Icontains",
                    "trip_TenantCarrier",
                    "trip_TripSeaTransportSet_Vessel",
                    "alertCode",
                    "goods",
                    "trade_Icontains",
                    "tripFreightSet_Contact_Icontains",
                    "orderNumber",
                    "pol",
                    "pod",
                    "transshipment",
                    "plannedDepartureDate_Gte",
                    "plannedDepartureDate_Lte",
                    "plannedArrivalDate_Gte",
                    "plannedArrivalDate_Lte"
                ];

                let hasDifferences = keysToCheck.some(key => {
                    return newValue[key] !== oldValue[key];
                });

                if (hasDifferences) {
                    this.tripAlertsIsLoading = true;
                    this.tripAlerts = { edges: [] };
                }
            },
            deep: true
        },
        openResolveOptions(val) {
            this.selectMode = val;

            if (!val) {
                this.selectedAlertsId = this.selectedAlertsId.filter(
                    id => this.resolvedAlerts.includes(id) || this.loadingItems.includes(id)
                );
            }
        }
    },
    methods: {
        formatItemsPerLine(items, itemsPerLine) {
            if (!items || !items.length) return '';
            
            return items
                .reduce((acc, item, i) => {
                    if (i % itemsPerLine === 0) {
                        acc.push([item]);
                    } else {
                        acc[acc.length - 1].push(item);
                    }
                    return acc;
                }, [])
                .map((line, lineIndex, allLines) => {
                    // Add comma at the end of the line if it's not the last line
                    return lineIndex < allLines.length - 1 ? 
                        line.join(', ') + ',' : 
                        line.join(', ');
                })
                .join('\n');
        },
        onResize() {
            this.$nextTick(() => {
                this.tableHeight = window.innerHeight - 145 + "px";
            });
        },
        formatDateTime(datetime) {
            return helper.formatDateTime(datetime);
        },
        onFilterMenuChanged(newChoices) {
            this.headerChoices = newChoices;
        },
        onLoadMoreTriggerIntersect(entries) {
            this.shouldLoadMore = entries[0].isIntersecting;

            if (this.shouldLoadMore && this.tripAlerts?.pageInfo?.hasNextPage) {
                this.loadMore();
            }
        },
        refreshQuery() {
            if (this.$apollo.queries?.tripAlerts?.refetch()) {
                this.$apollo.queries?.tripAlerts?.refetch();
            }
        },
        loadMore() {
            if (this.tripAlerts?.pageInfo?.hasNextPage) {
                this.$apollo.queries.tripAlerts.fetchMore({
                    variables: {
                        after: this.tripAlerts.pageInfo.endCursor
                    }
                });
            }
        },
        enableSelectAll() {
            if (this.selectedAlertsId.length === 0) {
                this.selectedAlertsId = this.tripAlerts.edges.map(alert => alert.node.id);
            } else {
                this.selectedAlertsId = [];
            }
        },
        getFreightReferenceNumber(tripFreightSet) {
            if (!tripFreightSet || !tripFreightSet?.edges || !tripFreightSet?.edges?.length === 0) {
                return "-";
            }
            let items = tripFreightSet?.edges.map(item => item.node.referenceNumber);
            return this.formatItemsPerLine(items, 3) || "-";
        },
        getTripFreightTrackerSerialNumbers(tripFreightSet) {
            //return join string of serial number
            //check if tripFreightSet has any edge with node.tripFreightTrackerSet.edges
            if (!tripFreightSet 
                || !tripFreightSet?.edges?.length === 0 
                || !tripFreightSet?.edges.map(item => item.node.tripFreightTrackerSet.edges).length > 0) {
                return "-";
            }
            // return "no";
            return tripFreightSet?.edges
                .map(item => 
                    item.node.tripFreightTrackerSet.edges
                        .filter(item => item.node.tracker.serialNumber)  // Filter out empty serial numbers
                        .map(item => item.node.tracker.serialNumber)
                        .join(", ")
                )
                .filter(serialNumber => serialNumber)  // Filter out empty strings after join
                .join(", ") || "-";
        },
        mainCard(item) {
            const {
                trip: { referenceNumber, tripFreightSet },
                tenantAlertRule: { name, isStandardAlert }
            } = item.node;
            
            let _mainCard = [
                {
                    title: this.$t("headers.trip"),
                    tag: "referenceNumber",
                    style: "",
                    align: "left",
                    value: referenceNumber,
                    cols: {
                        xs: "12",
                        sm: "6",
                        md: "2",
                        lg: "2",
                        xl: "2"
                    }
                },
                {
                    title: this.$t("headers.alert"),
                    tag: "alert",
                    style: "",
                    align: "left",
                    value: name,
                    cols: {
                        xs: "12",
                        sm: "6",
                        md: "2",
                        lg: "2",
                        xl: "2"
                    }
                },
                {
                    title: this.$t("headers.tracker"),
                    tag: "tracker",
                    style: "",
                    align: "left",
                    value: isStandardAlert ? this.getTripFreightTrackerSerialNumbers(tripFreightSet) 
                        : item.node.tripFreightTracker?.tracker?.serialNumber
                        ? item.node.tripFreightTracker?.tracker?.serialNumber
                        : "-",
                    cols: {
                        xs: "12",
                        sm: "6",
                        md: "2",
                        lg: "2",
                        xl: "2"
                    }
                },
                {
                    title: this.$t("headers.trigger_time"),
                    tag: "triggerTime",
                    style: "",
                    align: "left",
                    value: !item.node.alertTime ? "-" : this.formatDateTime(item.node.alertTime),
                    cols: {
                        xs: "12",
                        sm: "6",
                        md: "2",
                        lg: "2",
                        xl: "2"
                    }
                },
                {
                    title: this.$t("headers.freight"),
                    tag: "freight",
                    style: "",
                    align: "left",
                    value: isStandardAlert ? this.getFreightReferenceNumber(tripFreightSet) 
                        : item.node.tripFreightTracker?.tripFreight?.referenceNumber
                        ? item.node.tripFreightTracker?.tripFreight?.referenceNumber
                        : "-",
                    cols: {
                        xs: "12",
                        sm: "6",
                        md: "2",
                        lg: "2",
                        xl: "2"
                    }
                }
            ];

            return _mainCard;
        },
        redirect(id) {
            if (this.me.isPartnerUser && !this.me.isTenantUser && this.isShowPreview === true) {
                this.$router.push({
                    path: "/previewsharedtrips",
                    query: { id: id, tab: "alerts" }
                });
            } else if (this.me.isPartnerUser && !this.me.isTenantUser && this.isShowPreview === false) {
                this.$router.push({
                    path: "/sharedtrips",
                    query: { id: id, tab: "alerts" }
                });
            } else if (this.isShowPreview === true) {
                this.$router.push({
                    path: "/previewtrips",
                    query: { id: id, tab: "alerts" }
                });
            } else {
                this.$router.push({
                    path: "/trips",
                    query: { id: id, tab: "alerts" }
                });
            }
        },
        saveNoteBatch() {
            this.note = "";
            this.editAlertsDialog = true;
        },
        saveBatch() {
            this.loading = true;
            this.loadingItems = this.selectedAlertsId;

            let payload = {
                alertIds: this.selectedAlertsId,
                note: null
            };
            if (this.note !== "") {
                payload.note = this.note;
            }
            if (this.me.isPartnerUser && !this.me.isTenantUser) {
                payload.tenantPartnerId = this.me.tenantPartner.id;
            }
            this.editAlertsDialog = false;
            this.note = "";
            this.$apollo
                .mutate({
                    mutation: gql`
                        mutation UpdateBatchTripAlerts($input: UpdateBatchTripAlertsInput!) {
                            updateBatchTripAlerts(input: $input) {
                                trips {
                                    id
                                    status
                                    tripAlertSet {
                                        edges {
                                            node {
                                                id
                                                deviceTime
                                                alertTime
                                                resolvedTime
                                                isResolved
                                                alertJson
                                                note
                                                resolvedBy {
                                                    id
                                                    fullName
                                                }
                                                tenantAlertRule {
                                                    id
                                                    name
                                                }
                                                trackerMergedSensorData {
                                                    id
                                                }
                                            }
                                        }
                                    }
                                }
                            }
                        }
                    `,
                    variables: {
                        input: { ...payload }
                    }
                })
                .then(data => {
                    const payload = {
                        color: "success",
                        message: this.$t("general.alert_resolved")
                    };
                    this.$store.dispatch("snackbar/showMessage", payload);
                    // this.$apollo.queries.tripAlerts.refetch();
                })
                .catch(error => {
                    console.log(error);
                })
                .finally(() => {
                    this.loading = false;
                    this.resolvedAlerts = this.resolvedAlerts.concat(this.selectedAlertsId);
                    this.loadingItems = [];
                    this.selectedAlertsId = [];
                    this.$apollo.queries.tripAlerts.refetch();
                });
        },
        footerDetailsRow(trip) {
            const { tripFreightSet, transportMode } = trip;
            if (!tripFreightSet || !tripFreightSet.edges) {
                return [];
            }

            let _footerDetailsRow = [];

            _footerDetailsRow = tripFreightSet.edges.reduce((acc, item) => {
                const { node } = item;
                if (!node) {
                    return acc;
                }

                let trackers = node.tripFreightTrackerSet.edges.length > 0 ? this.trackerSerie(node) : [];
                let _tracker = {
                    serial: trackers[0]?.serial ?? "",
                    activatedDate: trackers[0]?.activatedDate ?? null,
                    lastConnectionTime: trackers[0]?.lastConnectionTime ?? null
                };

                if (transportMode === "SEA") {
                    if (node.containerNumber || node.billOfLadingNumber || node.referenceNumber) {
                        acc.push({
                            title: this.$t("headers.container_number"),
                            tag: "containerNumber",
                            icon: null,
                            iconSize: 8,
                            colorIcon: "black",
                            color: "gray lighten-2",
                            style: "",
                            align: "left",
                            value: node.containerNumber
                                ? node.containerNumber
                                : node.billOfLadingNumber
                                ? node.billOfLadingNumber
                                : node.referenceNumber,
                            valueDisplay: node.containerNumber
                                ? node.containerNumber
                                : node.billOfLadingNumber
                                ? node.billOfLadingNumber
                                : node.referenceNumber,
                            col: {
                                xs: 12,
                                sm: "auto",
                                md: "auto",
                                lg: "auto",
                                xl: "auto"
                            }
                        });
                    }
                } else if (transportMode === "AIR") {
                    if (node.airWaybillNumber || node.referenceNumber) {
                        acc.push({
                            title: this.$t("headers.airwaybill_number"),
                            tag: "airWaybillNumber",
                            icon: null,
                            iconSize: 8,
                            colorIcon: "black",
                            color: "gray lighten-2",
                            style: "",
                            align: "left",
                            value: node.airWaybillNumber
                                ? node.airWaybillNumber
                                : node.orderNumber
                                ? node.orderNumber
                                : node.referenceNumber,
                            valueDisplay: node.airWaybillNumber
                                ? node.airWaybillNumber
                                : node.orderNumber
                                ? node.orderNumber
                                : node.referenceNumber,
                            col: {
                                xs: 12,
                                sm: "auto",
                                md: "auto",
                                lg: "auto",
                                xl: "auto"
                            }
                        });
                    }
                } else {
                    if (node.orderNumber || node.referenceNumber) {
                        acc.push({
                            title: this.$t("headers.order_number"),
                            tag: "orderNumber",
                            icon: null,
                            iconSize: 8,
                            colorIcon: "black",
                            color: "gray lighten-2",
                            style: "",
                            align: "left",
                            value: node.orderNumber ? node.orderNumber : node.referenceNumber,
                            valueDisplay: node.orderNumber ? node.orderNumber : node.referenceNumber,
                            col: {
                                xs: 12,
                                sm: "auto",
                                md: "auto",
                                lg: "auto",
                                xl: "auto"
                            }
                        });
                    }
                }

                return acc;
            }, []);

            return _footerDetailsRow;
        },
        trackerSerie(freight) {
            let trackerSerie = [];
            let _additionalInfo = this.getExternalTrackerAdditionalInfo(freight.additionalInfo);
            let freightTrackers = freight?.tripFreightTrackerSet?.edges;
            let primary = this.$vuetify.theme.themes.light.primary;

            const formatDate = date => {
                return date ? this.formatDateTime(date) : "";
            };

            if (_additionalInfo) {
                trackerSerie.push({
                    serial: _additionalInfo.serialNumber,
                    model: _additionalInfo.type || "",
                    color: primary,
                    text: "External Tracker",
                    activatedDate: "",
                    lastConnectionTime: ""
                });
            }

            if (freightTrackers) {
                freightTrackers.forEach(element => {
                    if (element.node.tracker.activatedDate) {
                        trackerSerie.push({
                            serial: element.node.tracker.serialNumber,
                            model: element.node.tracker.model || "",
                            color: primary,
                            text: this.$t("general.activated"),
                            activatedDate: formatDate(element.node.tracker.activatedDate),
                            lastConnectionTime: formatDate(element.node.tracker.lastConnectionTime)
                        });
                    } else {
                        trackerSerie.push({
                            serial: element.node.tracker.serialNumber,
                            model: element.node.tracker.model || "",
                            color: "#FFCDD2",
                            text: this.$t("general.not_activated"),
                            activatedDate: "",
                            lastConnectionTime: ""
                        });
                    }
                });
                return trackerSerie;
            }
        },
        getExternalTrackerAdditionalInfo(additionalInfo) {
            let additionalInfoToReturn = null;
            if (additionalInfo) {
                let additionalInfoObj = JSON.parse(additionalInfo);

                if (additionalInfoObj?.externalTracker) {
                    try {
                        let obj = JSON.parse(additionalInfoObj.externalTracker);
                        if (obj.hasOwnProperty("externalTracker")) additionalInfoToReturn = obj?.externalTracker;
                        else additionalInfoToReturn = obj;
                    } catch (e) {
                        additionalInfoToReturn = additionalInfoObj?.externalTracker;
                    }
                }
            }
            return additionalInfoToReturn;
        },
        hasVessel(trip) {
            return trip.tripSeaTransportSet.edges.some(item => item.node.vessel);
        }
    }
};
</script>

<style scoped lang="css">
.row-pointer >>> tbody tr :hover {
    cursor: pointer;
}
.textLeft {
    text-align: left !important;
}
.textHeader {
    font-size: 11px;
    font-weight: 400;
    line-height: 12px;
    letter-spacing: 0em;
    text-align: left;
    color: #868599;
    height: 18px;
}
.textBody {
    font-size: 12px;
    font-weight: 700;
    line-height: 14px;
    letter-spacing: 0em;
    text-align: left;
    color: #0e0d35;
    height: 18px;
}
.smaller-checkbox {
  /* Reduce the overall size of the checkbox container */
  transform: scale(1.2);
}

.smaller-checkbox ::v-deep .v-input--selection-controls__input {
  /* Adjust the size of the checkbox itself */
  width: 24px;
  height: 24px;
}

.smaller-checkbox ::v-deep .v-icon {
  /* Reduce the size of the checkmark icon */
  font-size: 16px;
}

.smaller-checkbox ::v-deep .v-input--selection-controls__ripple {
  /* Adjust the size of the ripple effect */
  width: 24px !important;
  height: 24px !important;
  transform: translate(-12px, -12px);
}
</style>
