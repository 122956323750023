<template>
    <v-container fluid class="pa-0">
        <v-row justify="center" align="center" class="timeline-container flex-nowrap overflow-hidden">
            <v-col cols="12" class="pb-1" :class="{ 'pt-6': enableTooltip }">
                <div class="timeline-wrapper">
                    <template v-for="(step, index) in steps">
                        <div
                            :key="`step-${index}`"
                            class="timeline-step"
                            @click="editable ? editLocation(index) : null"
                            :style="{ cursor: editable ? 'pointer' : 'default' }"
                        >
                            <div class="step-content">
                                <v-tooltip bottom>
                                    <template v-slot:activator="{ on, attrs }">
                                        <div v-bind="attrs" v-on="on">
                                            <!-- <div class="fill-dot" :class="{ completed: activeStep > index + 1 }">
                                                <v-icon small>{{ step.icon }}</v-icon>
                                            </div> -->

                                            <div
                                                class="fill-dot"
                                                :class="{ completed: sliderConfiguration[index].activeStep }"
                                            >
                                                <v-icon x-small>{{ step.icon }}</v-icon>
                                            </div>
                                            <div
                                                class="step-title pl-1"
                                                :class="{ 'adjust-left': index === steps.length - 1 }"
                                                style="white-space: nowrap;"
                                            >
                                                {{ stepTitle(step) }}
                                            </div>
                                            <div
                                                class="step-description"
                                                :class="{ 'adjust-left': index === steps.length - 1 }"
                                            >
                                                <span
                                                    class="close-line text-left pl-1"
                                                    v-if="steps.length - 1 !== index"
                                                >
                                                    {{ getDepartureDateDescription(step) }}
                                                    <span
                                                        v-if="step.delay"
                                                        :class="`ml-1 font-weight-bold ${step.delayColor}`"
                                                    >
                                                        {{ step.delay }}
                                                    </span>
                                                </span>
                                                <span class="close-line pl-1" v-if="index === steps.length - 1">
                                                    {{ getArrivalDateDescription(step) }}
                                                </span>
                                            </div>
                                        </div>
                                    </template>
                                    <v-row>
                                        <v-col cols="12">
                                            <table style="width: 100%;">
                                                <thead style="text-align:center;">
                                                    <tr>
                                                        <th colspan="2">
                                                            {{ step.title }}
                                                        </th>
                                                    </tr>
                                                </thead>
                                                <tbody>
                                                    <tr v-if="step.code !== 'destination'" class="mb-1">
                                                        <th class="classHeader2">
                                                            {{ $t("trips.original_estimated_departure") }}
                                                        </th>
                                                        <td class="classText2">
                                                            {{ formatDateTime(step.originalEtd) }}
                                                        </td>
                                                    </tr>
                                                    <tr v-if="step.code !== 'origin'" class="mb-1">
                                                        <th class="classHeader2">
                                                            {{ $t("trips.actual_arrival") }}
                                                        </th>
                                                        <td class="classText2">
                                                            {{ formatDateTime(step.ata) }}
                                                        </td>
                                                    </tr>
                                                    <tr v-if="step.code !== 'origin'" class="mb-1">
                                                        <th class="classHeader2">
                                                            {{ $t("trips.estimated_arrival") }}
                                                        </th>
                                                        <td class="classText2">
                                                            {{ formatDateTime(step.eta) }}
                                                        </td>
                                                    </tr>
                                                    <tr v-if="step.code !== 'destination'" class="mb-1">
                                                        <th class="classHeader2">
                                                            {{ $t("trips.actual_departure") }}
                                                        </th>
                                                        <td class="classText2">
                                                            {{ formatDateTime(step.atd) }}
                                                        </td>
                                                    </tr>
                                                    <tr v-if="step.code !== 'destination'" class="mb-1">
                                                        <th class="classHeader2">
                                                            {{ $t("trips.estimated_departure") }}
                                                        </th>
                                                        <td class="classText2">
                                                            {{ formatDateTime(step.etd) }}
                                                        </td>
                                                    </tr>
                                                    <tr v-if="step.code !== 'destination' && transportMode === 'sea'">
                                                        <th class="classHeader2">
                                                            {{ $t("headers.vgm_cut_off_date") }}
                                                        </th>
                                                        <td class="classText2">
                                                            {{ formatDateTime(step.vgmCutOffDate) }}
                                                        </td>
                                                    </tr>
                                                    <tr v-if="step.code !== 'destination' && transportMode === 'sea'">
                                                        <th class="classHeader2">
                                                            {{ $t("headers.cut_off_date_time") }}
                                                        </th>
                                                        <td class="classText2">
                                                            {{ formatDateTime(step.cutOffDateTime) }}
                                                        </td>
                                                    </tr>
                                                    <tr class="mb-1">
                                                        <th class="classHeader2">
                                                            {{ $t("trips.delay") }}
                                                        </th>
                                                        <td class="classText2">
                                                            {{ step.delay !== "" ? step.delay + " day(s) delay" : "" }}
                                                        </td>
                                                    </tr>
                                                </tbody>
                                            </table>
                                        </v-col>
                                    </v-row>
                                </v-tooltip>
                            </div>
                        </div>

                        <v-slider
                            v-if="index < steps.length - 1"
                            :key="`slider-${index}`"
                            v-model="sliderConfiguration[index].sliderValue"
                            :min="0"
                            :max="100"
                            class="timeline-slider"
                            :class="[{ 'show-thumb': sliderConfiguration[index].showThumb }, `icon-${thumbLabelIcon}`]"
                            hide-details
                            tick-size="0"
                            :thumb-label="
                                enableTooltip ? (sliderConfiguration[index].showThumb ? 'always' : false) : false
                            "
                            readonly
                        >
                            <template v-slot:thumb-label>
                                <span>{{ step.transport || "" }}{{ step.voyage ? ` (${step.voyage})` : '' }}</span>
                            </template>
                        </v-slider>
                    </template>
                </div>
            </v-col>
        </v-row>
    </v-container>
</template>

<script>
import helper from "@/utils/helper";
/*
    Description: In this component the interpretation of the POL, POD and T/S is as follows:
    POL = Point of Loading
    POD = Point of Discharge
    T/S = Transshipment
*/
export default {
    name: "ProgressiveTimeline",
    props: {
        steps: {
            type: Array,
            required: true
        },
        transportMode: {
            type: String,
            required: true
        },
        editable: {
            type: Boolean,
            required: false,
            default: false
        },
        enableTooltip: {
            type: Boolean,
            required: false,
            default: false
        }
    },
    data() {
        return {
            activeStep: 0,
            ticksLabels: [""]
        };
    },
    created() {
        this.formatDateTime = helper.formatDateTime;
    },
    computed: {
        thumbLabelIcon() {
            let icon = "";
            if (this.transportMode !== null) {
                switch (this.transportMode.toLowerCase()) {
                    case "air":
                        icon = "flight";
                        break;
                    case "sea":
                        icon = "directions_boat";
                        break;
                    case "road":
                        icon = "local_shipping";
                        break;
                    case "rail":
                        icon = "directions_railway";
                        break;
                    default:
                        icon = "directions_boat";
                        break;
                }
            }
            return icon;
        },
        sliderConfiguration() {
            return this.steps.map((step, index) => {
                const nextStep = this.steps[index + 1] || {};
                let sliderValue = 0;
                let activeStep = false;
                let showThumb = false;

                if (step.code === "origin" || step.ata || step.atd) {
                    activeStep = true;
                    if (step.atd && !nextStep.ata) {
                        sliderValue = 50;
                    }
                    if (step.atd && (nextStep.ata || nextStep.atd)) {
                        sliderValue = 100;
                    }

                    if (sliderValue < 100) {
                        showThumb = true;
                    }
                    if (sliderValue === 100 && nextStep.code === "destination") {
                        showThumb = true;
                    }
                }
                return { sliderValue, showThumb, activeStep };
            });
        },
        stepTitle() {
            return step => `${step.portName} ${step.portCode}`;
        }
    },
    methods: {
        getDepartureDateDescription(step) {
            let departureDateDescription = "";
            if (step.code === "origin" || step.code === "transit") {
                if (step.atd) {
                    departureDateDescription = "ATD: " + this.formatDateTime(step.atd);
                } else if (step.etd) {
                    departureDateDescription = "ETD: " + this.formatDateTime(step.etd);
                } else if (!step.atd && !step.etd && !step.originalEtd) {
                    departureDateDescription = "ATD: Unknown";
                } else {
                    departureDateDescription =
                        "Original ETD: " + (step.originalEtd ? this.formatDateTime(step.originalEtd) : "Unknown");
                }
            }
            return departureDateDescription;
        },
        getArrivalDateDescription(step) {
            let arrivalDateDescription = "";
            if (step.code === "destination" || step.code === "transit") {
                if (step.ata) {
                    arrivalDateDescription = "ATA: " + this.formatDateTime(step.ata);
                } else if (step.eta) {
                    arrivalDateDescription = "ETA: " + this.formatDateTime(step.eta);
                } else if (!step.ata && !step.eta && !step.originalEta) {
                    arrivalDateDescription = "ATA: Unknown";
                } else {
                    arrivalDateDescription =
                        "Original ETA: " + (step.originalEta ? this.formatDateTime(step.originalEta) : "Unknown");
                }
            }
            return arrivalDateDescription;
        },
        editLocation(index) {
            this.$emit("edit-location", index);
        }
    }
};
</script>

<style scoped>
.timeline-container {
    position: relative;
    min-width: 100%;
}

.timeline-wrapper {
    display: flex;
    align-items: flex-start;
    justify-content: space-between;
    padding: 0;
    margin-top: 5px;
    margin-right: 30px;
}

.timeline-step {
    flex: 0 0 auto;
    display: flex;
    flex-direction: column;
    align-items: center;
    z-index: 2;
    position: relative;
}

.step-content {
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    text-align: left;
    padding-top: 10px;
    position: relative;
    max-width: 65px;
    min-width: 65px;
}

.fill-dot {
    width: 20px;
    height: 20px;
    border-radius: 50%;
    background-color: #e0e0e0;
    display: flex;
    justify-content: center;
    align-items: center;
    position: absolute;
    top: -8px;
    left: 50%;
    transform: translateX(-50%);
    z-index: 3;
}

.fill-dot.completed {
    background-color: var(--v-primary-base);
}

.fill-dot .v-icon {
    color: white;
}

.step-title {
    font-size: 10px;
    font-weight: bold;
    margin-bottom: 0px;
    white-space: nowrap;
    max-width: 250px;
    text-align: left;
    align-self: flex-start;
}

.step-description {
    font-size: 10px;
    color: #757575;
    max-width: 150px;
    word-wrap: break-word;
    text-align: left;
}

.timeline-slider {
    flex: 1;
    margin: -14px -20px 0 !important;
    z-index: 1;
    position: relative;
}

.timeline-slider::before,
.timeline-slider::after {
    content: "";
    position: absolute;
    top: 50%;
    width: 20px;
    height: 4px;
    background-color: inherit;
    z-index: 2;
}

.timeline-slider::before {
    left: 0;
    transform: translateX(-100%);
}

.timeline-slider::after {
    right: 0;
    transform: translateX(100%);
}

/* Adjust slider thumb label visibility */
.v-slider__thumb-container .v-slider__thumb-label {
    display: none;
}

.v-slider__thumb-container .v-slider__thumb-label--active {
    display: flex;
}

/* Ensure thumb label is above other elements */
.v-slider__thumb-container {
    z-index: 3;
}

/* Adjust slider track to be closer to step nodes */
.v-slider__track-container {
    height: 4px;
    margin: 0; /* Remove the -8px margin */
}

.close-line {
    display: block;
    margin: 0; /* Removes default margin */
    line-height: 1.2; /* Adjust if necessary */
    white-space: nowrap;
}

.classHeader2 {
    color: #fff !important;
    white-space: nowrap;
    overflow: hidden;
    text-overflow: ellipsis;
    font-size: 12px;
    font-weight: 400;
    line-height: 14px;
    letter-spacing: 0em;
    text-align: left;
}

.classText2 {
    color: #fff !important;
    white-space: nowrap;
    overflow: hidden;
    text-overflow: ellipsis;
    font-size: 12px;
    font-weight: 700;
    line-height: 13px;
    letter-spacing: 0em;
    text-align: left;
    margin-top: 3px;
}

.adjust-left {
    transform: translateX(-20px); /* Adjust this value as needed */
}

@media (max-width: 600px) {
    .timeline-wrapper {
        justify-content: flex-start;
    }

    .timeline-step {
        margin-right: 16px;
    }

    .timeline-slider {
        min-width: 40px;
    }
}

::v-deep .v-slider__thumb.primary {
    width: 15px;
    height: 15px;
    display: none;
}

::v-deep .icon-directions_boat .v-slider__thumb::after {
    content: "directions_boat";
    font-family: "Material Icons";
    font-size: 10px;
    color: #ffffff;
    display: flex;
    align-items: center;
    justify-content: center;
    margin-top: -1px;
    padding-left: 1px;
}

::v-deep .icon-flight .v-slider__thumb::after {
    content: "flight";
    font-family: "Material Icons";
    font-size: 10px;
    color: #ffffff;
    display: flex;
    align-items: center;
    justify-content: center;
    margin-top: -1px;
    padding-left: 1px;
}

::v-deep .icon-local_shipping .v-slider__thumb::after {
    content: "local_shipping";
    font-family: "Material Icons";
    font-size: 10px;
    color: #ffffff;
    display: flex;
    align-items: center;
    justify-content: center;
    margin-top: -1px;
    padding-left: 1px;
}

::v-deep .icon-directions_railway .v-slider__thumb::after {
    content: "directions_railway";
    font-family: "Material Icons";
    font-size: 10px;
    color: #ffffff;
    display: flex;
    align-items: center;
    justify-content: center;
    margin-top: -1px;
    padding-left: 1px;
}

::v-deep .show-thumb .v-slider__thumb.primary {
    display: flex !important;
}

::v-deep .v-slider__tick-label {
    font-size: 10px;
    white-space: nowrap;
    overflow: hidden;
    text-overflow: ellipsis;
    max-width: 100px;
}

::v-deep .v-slider__thumb-label.primary {
    width: 50px !important;
    height: 50px !important;
    padding-top: 0 !important;
    font-size: 10px !important;
    color: black !important;
    background-color: transparent !important;
    transform: translateY(+30%) translateY(-5px) translateX(-50%) rotate(45deg) !important;
    white-space: nowrap;
}

::v-deep .v-slider__thumb-label::before {
    display: none;
}

/* ::v-deep .v-slider__track-fill.primary {
    background-color: grey !important;
    border-color: grey !important;
}

::v-deep .v-slider__track-background.primary {
    background-color: #e0e0e0 !important;
    border-color: #e0e0e0 !important;
} */
</style>
