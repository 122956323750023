<template>
    <div>
        <v-card min-height="124px" flat>
            <v-row align="stretch" dense>
                <!-- Dynamic Columns for Items -->
                <v-col
                    v-for="(col, fIdx) in items"
                    :key="fIdx"
                    :cols="col.cols.xs"
                    :xs="col.cols.xs"
                    :sm="col.cols.sm"
                    :md="col.cols.md"
                    :lg="col.cols.lg"
                    :xl="col.cols.xl"
                >
                    <div class="textHeader">
                        {{ $t(col.text) }}
                    </div>
                    <div v-if="!col.value" class="textBody px-4">
                        <div>
                            {{ "-" }}
                        </div>
                    </div>
                    <div v-else-if="col.value && col.value.length <= 25" class="textBody">
                        {{ col.value !== "" ? col.value : "-" }}
                    </div>
                    <v-tooltip v-else-if="col.value && col.value.length > 25" bottom>
                        <template v-slot:activator="{ on }">
                            <div class="textBody selectCursor" v-on="on">
                                {{ truncate(col.value) }}
                            </div>
                        </template>
                        <span>{{ col.value }}</span>
                    </v-tooltip>
                </v-col>
            </v-row>
        </v-card>
        <v-row dense>
            <v-col cols="12">
                <!-- Expansion Panels for Trackers and Alerts -->
                <v-expansion-panels v-model="panel" multiple flat>
                    <!-- Tracker Expansion Panel -->
                    <v-expansion-panel class="mb-2 elevation-0 outlined rounded-lg">
                        <v-expansion-panel-header>
                            <v-row no-gutters align="center">
                                <v-col>
                                    <div class="d-flex align-center mt-1">
                                        <v-icon color="primary">{{ trackerHeaderInfo.icon }}</v-icon>
                                        <div class="textBody mr-1">
                                            {{ trackerHeaderInfo.text }}
                                        </div>
                                        <div>({{ trackers.length }})</div>
                                    </div>
                                </v-col>
                                <v-col cols="auto" class="pt-2">
                                    <span class="textBody mr-1" v-if="trackerHeaderInfo.timer">
                                        {{ $t("trips.last_connection") }}
                                    </span>
                                    <span class="textBody">{{ trackerHeaderInfo.timer }}</span>
                                </v-col>
                            </v-row>
                        </v-expansion-panel-header>

                        <v-expansion-panel-content class="pa-4">
                            <v-row justify="center" align="center" class="px-5 py-4" v-if="trackers.length === 0">
                                <v-col
                                    cols="4"
                                    class="textBody px-5 py-4 d-flex justify-center align-center flex-column"
                                >
                                    {{ $t("loading.no_data") }}
                                </v-col>
                            </v-row>

                            <!-- Trackers List -->
                            <v-row v-for="trackerInfo in trackers" :key="trackerInfo.id" class="mt-1 mx-0" dense>
                                <!-- Tracker Serial Number -->
                                <v-col
                                    cols="12"
                                    xs="12"
                                    sm="3"
                                    md="3"
                                    class="trackerText d-flex flex-row align-center px-0"
                                >
                                    <span>{{ getSerialNumber(trackerInfo.node.tracker) }}</span>
                                </v-col>
                                <v-spacer></v-spacer>
                                <!-- Tracker Events -->
                                <v-col cols="12" xs="12" sm="9" md="9">
                                    <v-row
                                        :class="
                                            transitionScreen.smToXs
                                                ? 'mt-1'
                                                : 'd-flex align-center flex-wrap justify-end'
                                        "
                                    >
                                        <v-col
                                            v-for="(tracker, tEIdx) in trackerEvents(trackerInfo.node.tracker)"
                                            :key="tEIdx"
                                            cols="auto"
                                            :class="tracker.class"
                                        >
                                            <v-badge bordered :color="tracker.color" offset-x="6" offset-y="8">
                                                <template v-slot:badge>
                                                    <div style="color:#fff" class="badge-content">
                                                        {{ tracker.value }}
                                                    </div>
                                                </template>
                                                <v-tooltip bottom>
                                                    <template
                                                        v-slot:activator="{
                                                            on,
                                                            attrs
                                                        }"
                                                    >
                                                        <v-img
                                                            v-bind="attrs"
                                                            v-on="on"
                                                            :style="`opacity: 0.5; filter: grayscale(100%);`"
                                                            :src="tracker.icon"
                                                            width="18"
                                                        ></v-img>
                                                    </template>
                                                    <span class="text ">
                                                        {{ tracker.text }}
                                                        {{ tracker.value }}
                                                    </span>
                                                </v-tooltip>
                                            </v-badge>
                                        </v-col>
                                        <v-col
                                            cols="auto"
                                            class="mr-8 mt-1 mb-2"
                                            :class="
                                                transitionScreen.mdToSm ? 'grey--text' : 'd-flex justify-end grey--text'
                                            "
                                            v-if="trackerInfo.node.tracker.lastConnectionTime"
                                        >
                                            <v-badge bordered color="grey" offset-x="1" offset-y="10">
                                                <template v-slot:badge>
                                                    <div class="badge-content">
                                                        {{
                                                            relativeTimeShort(
                                                                trackerInfo.node.tracker.lastConnectionTime
                                                            )
                                                        }}
                                                    </div>
                                                </template>
                                                <v-tooltip bottom>
                                                    <template
                                                        v-slot:activator="{
                                                            on,
                                                            attrs
                                                        }"
                                                    >
                                                        <v-icon size="18" v-on="on">
                                                            schedule
                                                        </v-icon>
                                                    </template>
                                                    <span
                                                        >{{ $t("trips.last_connection") }}
                                                        {{
                                                            formatDateTime(trackerInfo.node.tracker.lastConnectionTime)
                                                        }}</span
                                                    >
                                                </v-tooltip>
                                            </v-badge>
                                        </v-col>
                                    </v-row>
                                </v-col>
                            </v-row>
                        </v-expansion-panel-content>
                    </v-expansion-panel>
                    <!-- MNBU3494343 -->
                    <!-- Alert Rules Panel -->
                    <v-expansion-panel class="elevation-0 outlined rounded-lg mb-2">
                        <v-expansion-panel-header>
                            <v-row no-gutters align="center">
                                <v-col>
                                    <v-icon color="primary">notifications</v-icon>
                                    <span class="textBody">{{ $t("home.alert_rules") }}</span>
                                    ({{ alertSetHeaders.length }})
                                </v-col>
                            </v-row>
                        </v-expansion-panel-header>
                        <v-expansion-panel-content class="pb-3 px-5">
                            <!-- <LoadingBar v-if="loadingHeaders"> </LoadingBar> -->
                            <v-row>
                                <v-col cols="12">
                                    <v-card
                                        v-for="(alert, i) in alertSetHeaders"
                                        :key="i"
                                        class="contentBg rounded-lg mb-2 pb-2"
                                        outlined
                                    >
                                        <v-row class="px-3 mt-1" align="stretch">
                                            <v-col v-for="(el, j) in alertDetailsContent(alert)" :key="j">
                                                <div class="textHeader">
                                                    {{ el.tag }}
                                                </div>
                                                <div class="textBody text-capitalize" v-if="!el.icon">
                                                    {{ el.value }}
                                                </div>
                                                <div v-else>
                                                    <v-icon :color="el.iconColor">
                                                        {{ el.icon }}
                                                    </v-icon>
                                                </div>
                                            </v-col>
                                        </v-row>
                                    </v-card>
                                </v-col>
                            </v-row>
                        </v-expansion-panel-content>
                    </v-expansion-panel>

                    <!-- Additional Details Panel -->
                    <v-expansion-panel class="elevation-0 outlined rounded-lg">
                        <v-expansion-panel-header>
                            <v-row no-gutters align="center">
                                <v-col v-if="freight.node">
                                    <v-icon color="primary">plagiarism</v-icon>
                                    <span class="textBody">Additional Info</span>
                                </v-col>
                            </v-row>
                        </v-expansion-panel-header>
                        <v-expansion-panel-content class="pa-4">
                            <v-card flat>
                                <v-card-text>
                                    <v-row align="stretch" dense>
                                        <v-col
                                            v-for="(col, fBInx) in additionalInfo"
                                            :key="`freightBody-${fBInx}`"
                                            :cols="col.cols.xs"
                                            :xs="col.cols.xs"
                                            :sm="col.cols.sm"
                                            :md="col.cols.md"
                                            :lg="col.cols.lg"
                                            :xl="col.cols.xl"
                                        >
                                            <div class="textHeader">
                                                {{ $t(col.text) }}
                                            </div>
                                            <div v-if="!col.value" class="textBody px-4">
                                                <div>
                                                    {{ "-" }}
                                                </div>
                                            </div>
                                            <div v-else-if="col.value && col.value.length <= 25" class="textBody">
                                                {{ col.value !== "" ? col.value : "-" }}
                                            </div>
                                            <v-tooltip v-else-if="col.value && col.value.length > 25" bottom>
                                                <template v-slot:activator="{ on }">
                                                    <div class="textBody selectCursor" v-on="on">
                                                        {{ truncate(col.value) }}
                                                    </div>
                                                </template>
                                                <span>{{ col.value }}</span>
                                            </v-tooltip>
                                        </v-col>
                                    </v-row>
                                </v-card-text>
                            </v-card>
                        </v-expansion-panel-content>
                    </v-expansion-panel>
                </v-expansion-panels>
            </v-col>
        </v-row>
    </div>
</template>
<script>
import moment from "moment";
import helper from "@/utils/helper.js";
//import LoadingBar from "@/components/base/LoadingBar.vue";
import gql from "graphql-tag";

export default {
    props: {
        dialogMode: {
            type: Boolean,
            required: false,
            default: false
        },
        freight: {
            type: Object,
            required: true
        },
        freightHeader: {
            type: Array,
            required: true
        },
        freightBody: {
            type: Array,
            required: true
        },
        trackers: {
            type: Array,
            required: true
        },
        portBaseEvents: {
            type: Array,
            required: false,
            default: () => []
        },
        tenantAlertRules: {
            type: Object,
            required: false,
            default: function() {
                return { edges: [] };
            }
        }
    },
    components: {
        //LoadingBar
    },
    data() {
        return {
            panel: []
        };
    },
    created() {
        this.formatDateTime = helper.formatDateTime;
        this.relativeTime = helper.relativeTime;
        this.formatTemperature = helper.formatTemperature;
        this.getTemperatureSymbol = helper.getTemperatureSymbol;
        this.unitPerBrand = helper.unitPerBrand;
        this.formatAirwaybillNumber = helper.formatAirwaybillNumber;
        this.removeHifenFromAirwaybill = helper.removeHifenFromAirwaybill;
    },
    computed: {
        items() {
            let enabledFilters = ["reference", "container", "awb", "goods", "bill", "order"];
            let headers = this.freightHeader.filter(header => enabledFilters.includes(header.tag));

            return headers;
        },
        additionalInfo() {
            let _info = [];
            let _portBaseEvents = this.portBaseEvents ? this.portBaseEvents : [];
            //merge freightBody and portBaseEvents
            _info = [...this.freightBody, ..._portBaseEvents];
            return _info;
        },
        alertSetHeaders() {
            let local = [];
            // console.log("freight", this.freight);

            this.freight.node.tripFreightAlertRuleSet.edges.forEach(alert => {
                if (alert?.node?.tenantAlertRule) {
                    let _triggers = this.checkAlert(
                        alert?.node?.tenantAlertRule?.tenantAlertRuleTriggerSet?.edges[0]?.node?.triggerJson
                    );

                    if (!local.find(e => e.name === alert.node?.tenantAlertRule?.name)) {
                        local.push({
                            name: alert.node?.tenantAlertRule?.name,
                            isEnabled: alert.node?.tenantAlertRule?.isEnabled,
                            type: alert?.node?.tenantAlertRule?.isStandardAlert ? "Standard Alert" : "Custom Alert",
                            severity: alert.node?.tenantAlertRule?.alertSeverity,
                            numberOfTriggers: _triggers.length
                        });
                    }
                }
            });

            this.tenantAlertRules?.edges?.forEach(item => {
                local.push({
                    name: item.node?.name,
                    isEnabled: item.node?.isEnabled,
                    type: "Standard Alert",
                    severity: item.node?.severity ?? "Warning",
                    numberOfTriggers: 1
                });
            });
            return local;
        },
        loadingHeaders() {
            return true;
        },
        me() {
            return helper.me();
        },
        primaryColor() {
            return this.$vuetify.theme.themes.light.primary;
        },
        transitionScreen() {
            let _trasition = {
                smToXs: this.$vuetify.breakpoint.width < 600,
                mdToSm: this.$vuetify.breakpoint.width < 990,
                lgToMd: this.$vuetify.breakpoint.width < 1365
            };
            return _trasition;
        },
        trackerHeaderInfo() {
            let _obj = {
                icon: "settings_remote",
                text: this.$t("home.trackers"),
                amount: this.freight.node.tripFreightTrackerSet.edges.length ?? 0,
                timer: ""
            };
            let lastConnectionTime = null;
            this.freight.node.tripFreightTrackerSet.edges.forEach(({ node }) => {
                //last connection time descending
                if (lastConnectionTime === null || lastConnectionTime < node.tracker.lastConnectionTime) {
                    lastConnectionTime = node.tracker.lastConnectionTime;
                }
            });
            _obj.timer = lastConnectionTime ? this.formatDateTime(lastConnectionTime) : "";

            return _obj;
        }
    },
    mounted() {
        this.$emit("mounted");
    },
    methods: {
        trackerEvents(tracker) {
            let events = [];

            if (tracker.isExternal) {
                return events;
            }

            if (tracker) {
                let formatValue = Math.floor(Math.round(tracker.lastBatteryValue) ?? 0) + "%";
                let formatDate = this.formatDateTime(tracker.lastBatteryDeviceTime);

                if (tracker?.brand?.toLowerCase() !== "sensitech") {
                    events.push({
                        id: "4",
                        text: this.$t("general.battery"),
                        formatDateTime: formatDate,
                        value: formatValue,
                        icon: require("@/assets/battery_charging_full.png"),
                        color: "#1DB32544",
                        class: "mr-2 mt-1"
                    });
                }

                if (tracker.lastTemperatureValue) {
                    let symbol = this.getTemperatureSymbol();
                    let formatValue = Math.floor(tracker.lastTemperatureValue) + symbol;
                    let formatDate = this.formatDateTime(tracker.lastTemperatureDeviceTime);
                    events.push({
                        id: "1",
                        text: this.$t("general.temperature"),
                        value: formatValue,
                        formatDateTime: formatDate,
                        icon: require("@/assets/device_thermostat.png"),
                        color: "#377EF2",
                        class: "mr-3 mt-1"
                    });
                }
                if (tracker.lastExternalTemperatureValue) {
                    let symbol = this.getTemperatureSymbol();
                    let formatValue = Math.floor(tracker.lastExternalTemperatureValue) + symbol;
                    let formatDate = this.formatDateTime(tracker.lastExternalTemperatureDeviceTime);
                    events.push({
                        id: "5",
                        text: this.$t("general.external_temperature"),
                        value: formatValue,
                        formatDateTime: formatDate,
                        icon: require("@/assets/device_thermostat.png"),
                        color: "#377EF2",
                        class: "mr-3 mt-1"
                    });
                }
                if (tracker.lastProbeTemperatureValue) {
                    let symbol = this.getTemperatureSymbol();
                    let formatValue = Math.floor(tracker.lastProbeTemperatureValue) + symbol;
                    let formatDate = this.formatDateTime(tracker.lastProbeTemperatureDeviceTime);
                    events.push({
                        id: "6",
                        text: this.$t("general.probe_temperature"),
                        value: formatValue,
                        formatDateTime: formatDate,
                        icon: require("@/assets/device_thermostat.png"),
                        color: "#377EF2",
                        class: "mr-3 mt-1"
                    });
                }
                if (tracker.lastHumidityValue) {
                    let formatValue = Math.floor(tracker.lastHumidityValue) + "%";
                    let formatDate = this.formatDateTime(tracker.lastHumidityDeviceTime);
                    events.push({
                        id: "3",
                        text: this.$t("general.humidity"),
                        value: formatValue,
                        formatDateTime: formatDate,
                        icon: require("@/assets/water_drop.png"),
                        color: "#FF9D84",
                        class: "mr-3 mt-1"
                    });
                }

                if (tracker.lastLightValue) {
                    let formatValue = tracker.lastLightValue.toFixed(2) + "lx";
                    let formatDate = this.formatDateTime(tracker.lastLightDeviceTime);
                    events.push({
                        id: "2",
                        text: this.$t("general.light"),
                        value: formatValue,
                        formatDateTime: formatDate,
                        icon: require("@/assets/lightbulb.png"),
                        color: "#E3DE538F",
                        class: "mr-3 mt-1"
                    });
                }
                if (tracker.lastShockValue) {
                    let formatValue = Math.floor(tracker?.lastShockValue) + "G";
                    let formatDate = this.formatDateTime(tracker?.lastShockDeviceTime);
                    events.push({
                        id: "7",
                        text: this.$t("general.shock"),
                        value: formatValue,
                        formatDateTime: formatDate,
                        icon: require("@/assets/bolt.png"),
                        color: "#B38EFF90",
                        class: "mr-3 mt-1"
                    });
                }

                //sort by id
            }
            if (events.length > 0) {
                events.sort((a, b) => (a.id > b.id ? 1 : -1));
            }

            return events;
        },
        copyToClipboard(text) {
            try {
                // Use the modern clipboard API
                navigator.clipboard
                    .writeText(text)
                    .then(() => {
                        const payload = {
                            color: "primary",
                            message: this.$t("general.copied")
                        };
                        this.$store.dispatch("snackbar/showMessage", payload);
                    })
                    .catch(err => {
                        console.error("Failed to copy text: ", err);
                        // Fallback to older method if clipboard API fails
                        const dummy = document.createElement("textarea");
                        document.body.appendChild(dummy);
                        dummy.value = text;
                        dummy.select();
                        document.execCommand("copy");
                        document.body.removeChild(dummy);

                        const payload = {
                            color: "primary",
                            message: this.$t("general.copied")
                        };
                        this.$store.dispatch("snackbar/showMessage", payload);
                    });
            } catch (err) {
                console.error("Copy failed: ", err);
            }
        },
        redirectToAlertRule(id) {
            //future implementation
            this.$router.push({
                name: "customrules",
                query: { id: id }
            });
        },
        alertTracker(value) {
            let relativeTime = moment(value)
                .locale("en")
                .startOf("hour")
                .fromNow();
            let x = relativeTime.split(" ");

            if (x[2] === "minutes" || x[1] === "minutes" || x[1] === "minute" || x[1] === "hour" || x[1] === "hours") {
                return true;
            } else {
                return false;
            }
        },
        getSerialNumber(tracker) {
            if (tracker) {
                return tracker.serialNumber ? tracker.serialNumber : "";
            }
            return "";
        },
        truncate(value) {
            if (value) {
                return value.length > 30 ? value.substring(0, 25) + "..." : value;
            }
        },
        alertDetailsContent(item) {
            const { name, type, isEnabled, severity, numberOfTriggers } = item;

            let _content = [];

            _content.push({
                tag: this.$t("headers.alert"),
                text: "Alert",
                value: name,
                cols: {
                    xs: 12,
                    sm: 6,
                    md: 3,
                    lg: 3,
                    xl: 3
                }
            });

            _content.push({
                tag: this.$t("headers.enabled"),
                text: "Type",
                icon: "check_circle",
                iconColor: isEnabled ? "success" : "grey lighten-2",
                value: type,
                cols: {
                    xs: 12,
                    sm: 6,
                    md: 3,
                    lg: 3,
                    xl: 3
                }
            });

            /*  _content.push({
                tag: this.$t("headers.type"),
                text: "Type",
                value: type,
                cols: {
                    xs: 12,
                    sm: 6,
                    md: 3,
                    lg: 3,
                    xl: 3
                }
            }); */

            _content.push({
                tag: this.$t("headers.alert_severity"),
                text: "Severity",
                value: severity,
                cols: {
                    xs: 12,
                    sm: 6,
                    md: 3,
                    lg: 3,
                    xl: 3
                }
            });

            /*  _content.push({
                tag: this.$t("headers.number_of_triggers"),
                text: "Number of Triggers",
                value: numberOfTriggers,
                cols: {
                    xs: 12,
                    sm: 6,
                    md: 3,
                    lg: 3,
                    xl: 3
                }
            }); */

            return _content;
        },
        relativeTimeShort(value) {
            if (!value) return "";
            let relativeTime = this.relativeTime(value);
            let splitTime = relativeTime.split(" ");

            if (splitTime[0] === "a") {
                splitTime[0] = "1";
            }

            switch (splitTime[1]) {
                case this.$t("date.minutes"):
                case this.$t("date.minute"):
                    //replace the index of the array
                    splitTime[0] = splitTime[0] + "m";
                    splitTime[1] = "";
                    break;
                case this.$t("date.hours"):
                case this.$t("date.hour"):
                    splitTime[0] = splitTime[0] + "h";
                    splitTime[1] = "";
                    break;
                case this.$t("date.days"):
                case this.$t("date.day"):
                    splitTime[0] = splitTime[0] + "d";
                    splitTime[1] = "";
                    break;
                case this.$t("date.weeks"):
                case this.$t("date.week"):
                    splitTime[0] = splitTime[0] + "w";
                    splitTime[1] = "";
                    break;
                case this.$t("date.months"):
                case this.$t("date.month"):
                    splitTime[0] = splitTime[0] + "mo";
                    splitTime[1] = "";
                    break;
                case this.$t("date.years"):
                case this.$t("date.year"):
                    splitTime[0] = splitTime[0] + "y";
                    splitTime[1] = "";
                    break;
            }

            return splitTime.join(" ");
        },
        checkAlert(item) {
            if (!item) return [];
            let alert = [];
            let newObj = JSON.parse(item);

            if (newObj?.Location?.Condition) {
                alert.push({
                    type: "location",
                    tag: this.$t("general.location"),
                    img: require("@/assets/location_on.png")
                });
            }
            if (newObj?.Sensor?.length > 0) {
                newObj.Sensor.forEach(item => {
                    if (item.Type === "temperature") {
                        alert.push({
                            type: "temperature",
                            tag: this.$t("general.temperature"),
                            img: require("@/assets/thermostat.png")
                        });
                    }
                    if (item.Type === "humidity") {
                        alert.push({
                            type: "humidity",
                            tag: this.$t("general.humidity"),
                            img: require("@/assets/water_drop.png")
                        });
                    }
                    if (item.Type === "light") {
                        alert.push({
                            type: "light",
                            tag: this.$t("general.light"),
                            img: require("@/assets/lightbulb.png")
                        });
                    }
                    if (item.Type === "shock") {
                        alert.push({
                            type: "shock",
                            tag: this.$t("general.shock"),
                            img: require("@/assets/bolt.png")
                        });
                    }
                });
            }

            return alert;
        }
    }
};
</script>
<style scope>
.outlined {
    outline: 1px solid #e6e9f5; /* Blue outline, 2px thick */
    outline-offset: 0px; /* Distance between the outline and the element */
}
.txtIndex {
    max-height: 19px;
    font-size: 12.4px;
    font-weight: 720;
    color: #a4a4a8;
}
.txtBody {
    max-height: 19px;
    font-size: 12.3px;
    font-weight: 650;
}
.maxHeight {
    max-height: 18px;
}
.outlineD {
    border: 1px solid #e6e9f5;
    border-radius: 4px;
    padding: 0px 0px;
    font-size: 12px;
    font-weight: 600;
}
.contentBg {
    background-color: #f9fafe;
}
.cardContainer {
    margin-bottom: 12px;
}
.cardContainer::-webkit-scrollbar {
    width: 6px;
    border-radius: 5px;
}
.cardContainer::-webkit-scrollbar-track {
    box-shadow: inset 0 0 5px #cfd0d5ed;
    border-radius: 5px;
}
.cardContainer::-webkit-scrollbar-thumb {
    background-color: var(--scrollbar-color);
    border-radius: 5px;
}
.cardContainer::-webkit-scrollbar-thumb:hover {
    background-color: var(--scrollbar-color);
}
.textTitle {
    font-size: 20px;
    font-weight: 500;
    line-height: 22px;
    letter-spacing: 0em;
    text-align: left;
    color: #0e0d35;
}
.textTitle2 {
    font-size: 20px;
    font-weight: 500;
    line-height: 22px;
    letter-spacing: 0em;
    text-align: left;
    color: #ffffff;
}
.textHeader {
    font-size: 12px;
    font-weight: 400;
    line-height: 15px;
    letter-spacing: 0em;
    text-align: left;
    color: #555464;
    margin-bottom: 1px;
}
.textBody {
    font-size: 12px;
    font-weight: 700;
    line-height: 15px;
    letter-spacing: 0em;
    text-align: left;
    color: #0e0d35;
}

.trackerText {
    font-size: 11px;
    font-weight: 700;
    line-height: 13px;
    letter-spacing: 0em;
    text-align: left;
    color: #0e0d35;
}

.textDetails {
    font-size: 10px;
    font-weight: 400;
    line-height: 10px;
    letter-spacing: 0em;
    text-align: left;
    color: #0e0d35;
}
.dialogCardContainer {
    max-width: 100%;
    max-height: 500px;
    overflow-y: auto;
}
.selectCursor {
    cursor: pointer;
}

.badge-content {
    font-size: 10px;
    text-align: center;
    white-space: nowrap;
}

.right-aligned {
    justify-content: flex-end !important;
}
</style>
