import { VBadge } from 'vuetify/lib/components/VBadge';
import { VCardText } from 'vuetify/lib/components/VCard';
import { VCol } from 'vuetify/lib/components/VGrid';
import { VIcon } from 'vuetify/lib/components/VIcon';
import { VRow } from 'vuetify/lib/components/VGrid';
import { VSpacer } from 'vuetify/lib/components/VGrid';
import { VTooltip } from 'vuetify/lib/components/VTooltip';

var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c(VCardText,{staticClass:"pb-1 pt-2"},[_c(VRow,{attrs:{"dense":"","align":"center"}},[_c(VCol,{attrs:{"cols":"12"}},[_c(VRow,{staticClass:"d-flex align-center justify-space-around",attrs:{"dense":"","no-gutters":""}},[_c(VSpacer),(_vm.item.node.lastTemperatureValue)?_c(VCol,{staticClass:"mr-3",attrs:{"cols":"auto"}},[_c(VTooltip,{attrs:{"bottom":""},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
var on = ref.on;
var attrs = ref.attrs;
return [_c(VBadge,{staticClass:"mr-3",attrs:{"color":"#377EF2","offset-x":"6","offset-y":"8","right":""},scopedSlots:_vm._u([{key:"badge",fn:function(){return [_c('div',{staticClass:"badge-content"},[_vm._v(" "+_vm._s(Math.floor(_vm.formatTemperature(_vm.item.node.lastTemperatureValue)))+_vm._s(_vm.getTemperatureSymbol())+" ")])]},proxy:true}],null,true)},[_c(VIcon,_vm._g(_vm._b({style:("opacity: 0.5;"),attrs:{"small":""}},'v-icon',attrs,false),on),[_vm._v(" thermostat ")])],1)]}}],null,false,165353580)},[_c('span',[_vm._v(_vm._s(_vm.$t("general.probe_temperature")))])])],1):_vm._e(),(_vm.item.node.lastLightValue)?_c(VCol,{staticClass:"mr-3",attrs:{"cols":"auto"}},[_c(VTooltip,{attrs:{"bottom":""},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
var on = ref.on;
var attrs = ref.attrs;
return [_c(VBadge,{staticClass:"mr-3",attrs:{"color":"#e3de53","offset-x":"6","offset-y":"8","right":""},scopedSlots:_vm._u([{key:"badge",fn:function(){return [_c('div',{staticClass:"badge-content"},[_vm._v(" "+_vm._s(_vm.item.node.lastLightValue.toFixed(0))+"lx ")])]},proxy:true}],null,true)},[_c(VIcon,_vm._g(_vm._b({style:("opacity: 0.5;"),attrs:{"small":""}},'v-icon',attrs,false),on),[_vm._v(" lightbulb ")])],1)]}}],null,false,1327142021)},[_c('span',[_vm._v(_vm._s(_vm.$t("general.light")))])])],1):_vm._e(),(_vm.item.node.lastHumidityValue)?_c(VCol,{staticClass:"mr-3",attrs:{"cols":"auto"}},[_c(VTooltip,{attrs:{"bottom":""},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
var on = ref.on;
var attrs = ref.attrs;
return [_c(VBadge,{staticClass:"mr-3",attrs:{"color":"#ff9d84","offset-x":"6","offset-y":"8","right":""},scopedSlots:_vm._u([{key:"badge",fn:function(){return [_c('div',{staticClass:"badge-content"},[_vm._v(" "+_vm._s(Math.floor(_vm.item.node.lastHumidityValue))+"% ")])]},proxy:true}],null,true)},[_c(VIcon,_vm._g(_vm._b({style:("opacity: 0.5;"),attrs:{"small":""}},'v-icon',attrs,false),on),[_vm._v(" water_drop ")])],1)]}}],null,false,3045769186)},[_c('span',[_vm._v(_vm._s(_vm.$t("general.humidity")))])])],1):_vm._e(),(_vm.item.node.brand.toLowerCase() !== 'sensitech')?_c(VCol,{staticClass:"mr-3",attrs:{"cols":"auto"}},[_c(VTooltip,{attrs:{"bottom":""},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
var on = ref.on;
var attrs = ref.attrs;
return [_c(VBadge,{staticClass:"mr-3",attrs:{"color":_vm.getBatteryLevel(_vm.item.node).color,"offset-x":"6","offset-y":"8","right":""},scopedSlots:_vm._u([{key:"badge",fn:function(){return [_c('div',{staticClass:"badge-content"},[_vm._v(" "+_vm._s(_vm.item.node.lastBatteryValue ? ((Math.floor(_vm.item.node.lastBatteryValue)) + "%") : '0%')+" ")])]},proxy:true}],null,true)},[_c(VIcon,_vm._g(_vm._b({style:("opacity: 0.5;"),attrs:{"small":""}},'v-icon',attrs,false),on),[_vm._v(" "+_vm._s(_vm.getBatteryLevel(_vm.item.node).level)+" ")])],1)]}}],null,false,3834945066)},[_c('span',[_vm._v(_vm._s(_vm.$t("general.battery")))])])],1):_vm._e(),(_vm.item.node.lastShockValue)?_c(VCol,{staticClass:"mr-3",attrs:{"cols":"auto"}},[_c(VTooltip,{attrs:{"bottom":""},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
var on = ref.on;
var attrs = ref.attrs;
return [_c(VBadge,{staticClass:"mr-3",attrs:{"color":"#B38EFF","offset-x":"6","offset-y":"8","right":""},scopedSlots:_vm._u([{key:"badge",fn:function(){return [_c('div',{staticClass:"badge-content"},[_vm._v(" "+_vm._s(Math.floor(_vm.item.node.lastShockValue))+"G ")])]},proxy:true}],null,true)},[_c(VIcon,_vm._g(_vm._b({style:("opacity: 0.5;"),attrs:{"small":""}},'v-icon',attrs,false),on),[_vm._v(" bolt ")])],1)]}}],null,false,1167967150)},[_c('span',[_vm._v(_vm._s(_vm.$t("general.shock")))])])],1):_vm._e(),(_vm.item.node.lastConnectionTime)?_c(VCol,{staticClass:"mr-3",attrs:{"cols":"auto"}},[_c(VTooltip,{attrs:{"bottom":""},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
var on = ref.on;
var attrs = ref.attrs;
return [_c(VBadge,{staticClass:"mr-3",attrs:{"color":"grey","offset-x":"6","offset-y":"8","right":""},scopedSlots:_vm._u([{key:"badge",fn:function(){return [_c('div',{staticClass:"badge-content"},[_vm._v(" "+_vm._s(_vm.relativeTimeShort(_vm.item.node.lastConnectionTime))+" ")])]},proxy:true}],null,true)},[_c(VIcon,_vm._g(_vm._b({style:("opacity: 0.7;"),attrs:{"small":""}},'v-icon',attrs,false),on),[_vm._v(" schedule ")])],1)]}}],null,false,2965787580)},[_c('span',[_vm._v(_vm._s(_vm.$t("trips.last_connection")))])])],1):_vm._e(),(!_vm.isStaff && _vm.canEdit)?_c(VCol,{staticClass:"d-flex align-center justify-center ml-3",attrs:{"cols":"auto"}},[_c('div',[_c('TrackerChangeLocation',{attrs:{"isMenuItem":true,"onlyIcon":true,"selectedTrackers":_vm.selectedTrackersToMove},on:{"closeSelector":_vm.closeSelector,"updateTrackersTable":function($event){return _vm.$emit('refresh')},"menuItemClicked":function($event){return _vm.assignToStockLocation(_vm.item)}}})],1)]):_vm._e()],1)],1)],1)],1)}
var staticRenderFns = []

export { render, staticRenderFns }