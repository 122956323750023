<template>
    <v-dialog v-model="trackerChangeLocationDialog" max-width="500" persistent>
        <template v-slot:activator="{ on, attrs }">
            <v-btn
                v-if="!isMenuItem && !isPreview"
                :rounded="rounded"
                color="primary"
                class="mr-2 mt-1"
                v-bind="attrs"
                v-on="on"
                width="90"
            >
                {{ $t("trackers.move") }}
            </v-btn>
            <v-btn
                v-if="!isMenuItem && isPreview"
                :rounded="rounded"
                color="primary"
                class="mr-2"
                v-bind="attrs"
                v-on="on"
                icon
            >
                <v-icon>
                    check
                </v-icon>
            </v-btn>
            <v-btn 
                v-if="isMenuItem && !onlyIcon"
                plain 
                v-bind="attrs"
                v-on="on"
            >
                <v-icon left>
                    add_location
                </v-icon>
                <template >
                    {{ $t("trackers.assign_to") }} {{ $t("trackers.stock_location") }}
                </template>
            </v-btn>
            <v-tooltip bottom v-if="isMenuItem && onlyIcon"> 
                <template v-slot:activator="{ on: tooltip }">
                    <v-btn 
                        v-bind="attrs"
                        v-on="{ ...tooltip, ...on }"
                        icon
                        color="primary"
                        class="pb-2"
                        @click.stop
                    >
                        <v-icon>
                            add_location
                        </v-icon>
                    </v-btn>
                </template>
                <span>{{ $t("trackers.assign_to") }} {{ $t("trackers.stock_location") }}</span>
            </v-tooltip>
            </template>
        <v-card>
            <v-card-title class="primary white--text">
                <v-icon color="white" class="mr-2">autorenew</v-icon
                >{{ $t("general.change") }}
                {{ $t("trackers.tracker_location") }}
            </v-card-title>
            <v-alert
                style="margin-bottom: 0%; border-radius: 0% 0% 4% 4%;"
                v-if="noTrackersError"
                dense
                type="error"
                elevation="2"
            >
                {{ $t("trackers.no_tracker") }}
            </v-alert>
            <v-card-text style="padding-top: 20px">
                <v-container fluid>
                    <h3 style="margin-bottom: 1%; margin-top: 1%;">
                        {{ $t("trackers.stock_location") }}:
                    </h3>
                    <v-row align="center">
                        <v-col class="d-flex" cols="12" sm="12">
                            <v-autocomplete
                                clearable
                                hide-selected
                                hide-details
                                auto-select-first
                                dense
                                outlined
                                :items="tenantLocations.edges"
                                :no-data-text="$t('loading.no_available')"
                                v-model="pendingLocation"
                                item-text="node.name"
                                item-value="node.id"
                            ></v-autocomplete>
                        </v-col>
                    </v-row>
                </v-container>
            </v-card-text>
            <v-card-actions class="d-flex justify-end pb-5 pr-5">
                <v-btn
                    text
                    @click="
                        trackerChangeLocationDialog = false;
                        noTrackersError = false;
                    "
                    rounded
                    >{{ $t("general.close") }}</v-btn
                >
                <v-btn
                    color="primary"
                    @click="changeTrackersStockLocation"
                    rounded
                    :loading="isLoadingChangeTracker"
                >
                    {{ $t("general.apply_changes") }}
                </v-btn>
            </v-card-actions>
        </v-card>
    </v-dialog>
</template>

<script>
import gql from "graphql-tag";
import helper from "@/utils/helper.js";

export default {
    name: "TrackerChangeLocation",
    props: {
        openTrackerChangeLocationDialog:{
            type: Boolean,
            default: false
        }, 
        selectedTrackers: {
            type: Array,
            default: () => []
        }, 
        isMenuItem: {
            type: Boolean,
            default: false
        },
        onlyIcon: {
            type: Boolean,
            default: false
        },
        rounded: {
            type: Boolean,
            default: true
        },
        isPreview: {
            type: Boolean,
            default: true
        }
    },
    apollo: {
        tenantLocations: {
            query: gql`
                query tenantLocations($isTrackerStockLocation: Boolean) {
                    tenantLocations(
                        isTrackerStockLocation: $isTrackerStockLocation
                    ) {
                        edges {
                            node {
                                name
                                id
                            }
                        }
                    }
                }
            `,
            variables() {
                return {
                    isTrackerStockLocation: true
                };
            },
            // Additional options here
            fetchPolicy: "cache-and-network",
            nextFetchPolicy: "cache-first", // this setting can avoid query again after fetchMore
            update: data => data.tenantLocations,
            debounce: 0
            // skip() {
            //     return !this.me.isStaff;
            // }
            // pollInterval: 60000 // ms
        }
    },
    data() {
        return {
            trackerChangeLocationDialog: false,
            pendingLocation: null,
            tenantLocations: { edges: [] },
            noTrackersError: false,
            isLoadingChangeTracker: false
        };
    },
    computed: {
        me() {
            return helper.me();
        }
    },
    watch: {
        trackerChangeLocationDialog(val) {
            if(val && this.isMenuItem){
                this.$emit("menuItemClicked");
            }
        }
    },
    methods: {
        changeTrackersStockLocation() {
            if (this.selectedTrackers.length === 0) {
                this.noTrackersError = true;
                return;
            }

            this.isLoadingChangeTracker = true;
            
            let trackerIdsToMutation = [];
            this.selectedTrackers.forEach(tracker => {
                if(tracker?.node?.id)
                    trackerIdsToMutation.push(tracker.node.id);
                else
                    trackerIdsToMutation.push(tracker.id);
            });

            this.$apollo
                .mutate({
                    mutation: gql`
                        mutation ChangeTrackersStockLocation(
                            $input: ChangeTrackersStockLocationInput!
                        ) {
                            changeTrackersStockLocation(input: $input) {
                                success
                            }
                        }
                    `,
                    variables: {
                        input: {
                            stockLocationId: this.pendingLocation,
                            trackersIds: trackerIdsToMutation
                        }
                    }
                })
                .then(() => {
                    this.trackerChangeLocationDialog = false;
                    this.pendingLocation = null;
                    this.isLoadingChangeTracker = false;
                    this.$emit("updateTrackersTable");
                    this.$emit("closeSelector", false);
                })
                .catch(error => {
                    this.isLoadingChangeTracker = false;
                    console.log(error);
                });
        }
    }
};
</script>
