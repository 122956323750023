<template>
    <v-hover>
        <template>
            <v-card class="py-2 transparent" outlined>
                <v-row dense>
                    <v-col>
                        <v-row class="d-flex align-center py-0" dense>
                            <v-col cols="12" xs="12" sm="12" md="3" lg="2" xl="2">
                                <slot name="view-manage"></slot>
                            </v-col>
                            <v-col cols="12" xs="12" sm="12" md="3" lg="2" xl="2">
                                <div>
                                    <SearchBar v-model="search" :label="$t('filters.search')" ref="searchBar">
                                        <template v-slot:prepend-inner>
                                            <div>
                                                <v-tooltip bottom>
                                                    <template v-slot:activator="{ on }">
                                                        <v-icon v-on="on" color="">
                                                            search
                                                        </v-icon>
                                                    </template>
                                                    <span>
                                                        {{ $t("trips.searchFilter") }}
                                                    </span>
                                                </v-tooltip>
                                            </div>
                                        </template>
                                    </SearchBar>
                                </div>
                            </v-col>
                            <!-- Filter -->
                            <v-col cols="auto" v-for="i in filterValuesObj" :key="i.id">
                                <v-menu
                                    :ref="getCode(i.id)"
                                    max-width="300px"
                                    :close-on-content-click="false"
                                    :close-on-click="true"
                                    right
                                    bottom
                                    nudge-top="-6"
                                    offset-y
                                    @click.stop
                                    @input="onMenuStateChange($event, getCode(i.id))"
                                >
                                    <template v-slot:activator="{ on, attrs }">
                                        <v-chip
                                            label
                                            close
                                            class="elevation-0 text-capitalize classText"
                                            v-on="on"
                                            v-bind="attrs"
                                            @click:close="deleteFilterValue(i.id)"
                                            @click="addCode(getCode(i.id))"
                                            :disabled="disabled"
                                            v-if="
                                                i.id != 'plannedDepartureDate_Gte' &&
                                                    i.id != 'plannedDepartureDate_Lte' &&
                                                    i.id != 'plannedArrivalDate_Gte' &&
                                                    i.id != 'plannedArrivalDate_Lte' &&
                                                    i.id != 'tripSeaTransportSetCutOffDateTimeGte' &&
                                                    i.id != 'tripSeaTransportSetCutOffDateTimeLte' &&
                                                    i.id != 'tripSeaTransportSetVgmCutOffDateGte' &&
                                                    i.id != 'tripSeaTransportSetVgmCutOffDateLte'
                                            "
                                        >
                                            <span v-if="i.id == ''"></span>
                                            {{ i.name + ":" + " " + i.value }}
                                        </v-chip>
                                        <v-chip
                                            label
                                            close
                                            v-on="on"
                                            v-bind="attrs"
                                            class="elevation-0 text-capitalize classText"
                                            @click:close="deleteFilterValue(i.id)"
                                            @click="addCode(getCode(i.id))"
                                            :disabled="disabled"
                                            v-else
                                        >
                                            {{ i.name + ":" + " " + i.value }}
                                        </v-chip>
                                    </template>
                                    <v-card height="100%" class="pa-1">
                                        <v-card-text class="mt-2">
                                            <FilterMenuTemplate
                                                ref="FilterMenuTemplate"
                                                v-model="menuObject"
                                                :isReadOnly="true"
                                                :currentCode="codeSelected"
                                                :isSharedTrip="isSharedTrip"
                                            ></FilterMenuTemplate>
                                        </v-card-text>
                                        <v-card-actions class="pa-0">
                                            <v-spacer></v-spacer>
                                            <v-btn text @click="cancelFilter(getCode(i.id))">
                                                Close
                                            </v-btn>
                                            <v-btn 
                                                color="primary" 
                                                @click.stop="searchFields(menuObject, getCode(i.id))"
                                                :disabled="!hasFilterValue(menuObject, getCode(i.id))"
                                            >
                                                Apply
                                            </v-btn>
                                        </v-card-actions>
                                    </v-card>
                                </v-menu>
                            </v-col>
                            <!-- Add Filter -->
                            <v-col cols="auto">
                                <v-menu
                                    ref="addFilterMenu"
                                    max-width="300px"
                                    :close-on-content-click="false"
                                    :close-on-click="true"
                                    @click.stop
                                    right
                                    bottom
                                    nudge-top="-6"
                                    offset-y
                                    @input="onMenuStateChange($event, 'addFilterMenu')"
                                >
                                    <template v-slot:activator="{ on, attrs }">
                                        <v-btn
                                            color="primary"
                                            class="content"
                                            v-on="on"
                                            v-bind="attrs"
                                            :disabled="disabled"
                                        >
                                            <!-- <v-icon>add</v-icon> -->
                                            {{ $t("general.add_filter") }}
                                        </v-btn>
                                    </template>
                                    <v-card height="100%" class="pa-1">
                                        <v-card-text class="mt-2">
                                            <FilterMenuTemplate
                                                ref="FilterMenuTemplate"
                                                v-model="menuObject"
                                                :isReadOnly="false"
                                                :currentCode.sync="codeSelected"
                                                :isSharedTrip="isSharedTrip"
                                            ></FilterMenuTemplate>
                                        </v-card-text>
                                        <v-card-actions class="pa-0">
                                            <v-spacer></v-spacer>
                                            <v-btn text @click="cancelFilter('addFilterMenu')">
                                                Close
                                            </v-btn>
                                            <v-btn
                                                color="primary"
                                                @click.stop="searchFields(menuObject, 'addFilterMenu')"
                                                :disabled="codeSelected === null || codeSelected === '' || !hasFilterValue(menuObject, codeSelected)"
                                            >
                                                Apply
                                            </v-btn>
                                        </v-card-actions>
                                    </v-card>
                                </v-menu>
                            </v-col>
                        </v-row>
                    </v-col>
                    <!-- Sort by -->
                    <v-col cols="auto">
                        <v-row dense>
                            <v-col>
                                <v-select
                                    v-model="sortBy"
                                    :items="sortByChoices"
                                    :label="$t('general.sort_by')"
                                    :item-value="item => item.code"
                                    :item-text="item => item.name"
                                    clearable
                                    hide-details
                                    dense
                                    outlined
                                    color="primary"
                                    :menu-props="{
                                        offsetY: true,
                                        nudgeTop: 0
                                    }"
                                    class="content-select"
                                    :disabled="disabled"
                                >
                                    <template v-slot:append-outer>
                                        <v-btn class="mt-n1" small icon :disabled="!sortBy" @click="sortAsc = !sortAsc">
                                            <v-icon color="grey darken-2">{{ sortAsc ? "north" : "south" }}</v-icon>
                                        </v-btn>
                                    </template>
                                    <template #item="{item}">
                                        <span class="classText">
                                            {{ item.name }}
                                        </span>
                                    </template>
                                    <template v-slot:selection="{ item }">
                                        <div class="classText">
                                            {{ item.name }}
                                        </div>
                                    </template>
                                </v-select>
                            </v-col>
                            <v-col cols="auto" class="pa-0 mt-3">
                                <slot name="append"></slot>
                            </v-col>
                        </v-row>
                    </v-col>
                </v-row>
                <!-- Filter dialog -->
            </v-card>
        </template>
    </v-hover>
</template>

<script>
import helper from "@/utils/helper.js";
import moment from "moment";
import FilterMenuTemplate from "@/components/trips/cardtemplates/FilterMenuTemplate.vue";
import SearchBar from "@/components/base/SearchBar.vue";
import gql from "graphql-tag";

export default {
    components: {
        FilterMenuTemplate,
        SearchBar
    },
    props: {
        isSharedTrip: {
            type: Boolean,
            required: false,
            default: false
        },
        filterCode: {
            type: String,
            default: null
        },
        filterSelected: {
            type: String,
            default: null
        },
        views: {
            type: Array,
            default: () => []
        },
        disabled: {
            type: Boolean,
            default: false
        },
        filterPreferences: {
            type: Array,
            default: () => []
        }
    },
    data() {
        return {
            plannedDepartureDateMenu: false,
            plannedArrivalDateMenu: false,
            plannedDepartureDateRange: [],
            plannedArrivalDateRange: [],
            vgmCutOffDateRange: [],
            cutOffDateRange: [],
            savedPlannedDepartureDateRange: [],
            savedPlannedArrivalDateRange: [],
            savedVgmCutOffDateRange: [],
            savedCutOffDateRange: [],
            sortAsc: true,
            menuObject: {},
            copyMenuObject: {},
            vgmMenu: false,
            cutOffMenu: false,
            menuReference: null,
            filter: {
                first: 10,
                status_In: "active",
                orderBy: "-plannedDepartureDate",
                isFavorite: null,
                after: null,
                tenantPartnerId: null,
                transportMode_In: null,
                referenceNumber_Icontains: null,
                tripAlertSetIsResolved: null,
                trackerSerialNumber: null,
                tripFreightSet_ContainerNumber_Icontains: null,
                tripFreightSet_AirWaybillNumber_Icontains: null,
                tripFreightSet_ReferenceNumber_Icontains: null,
                tripFreightSet_OrderNumber_Icontains: null,
                tripFreightSet_Contact_Icontains: null,
                plannedDepartureDate_Gte: null,
                plannedDepartureDate_Lte: null,
                plannedArrivalDate_Gte: null,
                plannedArrivalDate_Lte: null,
                pol: null,
                transshipment: null,
                pod: null,
                alertCode: [],
                tenantCarrier: null,
                consignor: null,
                consignee: null,
                forwarder: null,
                polvessel: null,
                tsvessel: null,
                goods: null,
                tenant: null,
                trackerLastLocation_Icontains: null,
                search: null,
                alertTag: null,
                trade_Icontains: null,
                awbNumber: null,
                containerNumber: null,
                trackerNumber: null,
                blNumber: null,
                orderNumber: null,
                tripSeaTransportSetCutOffDateTimeLte: null,
                tripSeaTransportSetCutOffDateTimeGte: null,
                tripSeaTransportSetVgmCutOffDateGte: null,
                tripSeaTransportSetVgmCutOffDateLte: null,
                tripSeaTransportSetBookingConfirmationNumberIexact: null,
                tenantAlertRuleId: null,
                eventSource: "flight_detect",
                eventSourceType: "city_location",
                origin: null,
                destination: null
            },
            refreshTableCarrier: false,
            refreshTableConsignor: false,
            refreshTableConsignee: false,
            refreshTableForwarder: false,
            refreshTableVessel: false,
            refreshTablePorts: false,
            filterChoices: null,
            alertTags: [
                { value: "temperature", text: this.$t("general.temperature") },
                { value: "humidity", text: this.$t("general.humidity") },
                { value: "light", text: this.$t("general.light") },
                { value: "shock", text: this.$t("general.shock") },
                { value: "temperature_humidity", text: this.$t("general.temperature_humidity") },
                { value: "temperature_humidity_light", text: this.$t("general.temperature_humidity_light") },
                { value: "pressure", text: this.$t("general.pressure") }
            ],
            carrierName: null,
            consignorName: null,
            consigneeName: null,
            forwarderName: null,
            tsvesselName: null,
            polvesselName: null,
            relativePlannedDepartureDateRange: null,
            relativePlannedDepartureDateRangeChoices: [
                this.$t("date.last30"),
                this.$t("date.last90"),
                this.$t("date.last180"),
                this.$t("date.last360")
            ],
            relativePlannedArrivalDateRange: null,
            relativePlannedArrivalDateRangeChoices: [
                this.$t("date.last30"),
                this.$t("date.last90"),
                this.$t("date.last180"),
                this.$t("date.last360")
            ],
            transportModeChoices: [
                { text: this.$t("transport_mode_choice.air"), value: "air" },
                { text: this.$t("transport_mode_choice.road"), value: "road" },
                { text: this.$t("transport_mode_choice.rail"), value: "rail" },
                { text: this.$t("transport_mode_choice.sea"), value: "sea" }
            ],
            relativeCutOffDateTimeRange: null,
            relativeCutOffDateTimeRangeChoices: [
                this.$t("date.last30"),
                this.$t("date.last90"),
                this.$t("date.last180"),
                this.$t("date.last360")
            ],
            relativeVgmCutOffDateRange: null,
            relativeVgmCutOffDateRangeChoices: [
                this.$t("date.last30"),
                this.$t("date.last90"),
                this.$t("date.last180"),
                this.$t("date.last360")
            ],
            filterValuesObj: [],
            codeSelected: null,
            sortBy: null,
            enabledFilters: [
                {
                    name: "Status",
                    code: "status_In",
                    enabled: true
                }
            ],
            KEY_MAPPINGS: {
                // Basic filters
                Tenant: "tenantPartnerId",
                referenceNumber: "referenceNumber_Icontains",
                freights: "tripFreightSet_ReferenceNumber_Icontains",
                status: "status_In",

                // Document numbers
                containerNumber: "containerNumber",
                airWaybillNumber: "awbNumber",
                orderNumber: "orderNumber",
                blNumber: "blNumber",
                booking: "tripSeaTransportSetBookingConfirmationNumberIexact",

                // Dates
                plannedDepartureDate: "plannedDepartureDate_Gte",
                plannedArrivalDate: "plannedArrivalDate_Gte",
                vgm: "tripSeaTransportSetVgmCutOffDateGte",
                cutOff: "tripSeaTransportSetCutOffDateTimeGte",

                // Locations
                podsearch: "pod",
                polSearch: "pol",
                transshipmentSearch: "transshipment",
                pol: "pol",
                pod: "pod",
                transshipment: "transshipment",
                trackerLastLocation: "trackerLastLocation_Icontains",
                origin: "origin",
                destination: "destination",

                // Partners
                carrier: "tenantCarrier",
                consignor: "consignor",
                consignee: "consignee",
                forwarder: "forwarder",
                sharedBy: "tenant",

                // Vessels
                polvessel: "polvessel",
                tsvessel: "tsvessel",

                // Other attributes
                transportMode: "transportMode_In",
                alertCode: "alertCode",
                alertTag: "alertTag",
                goods: "goods",
                contactPerson: "tripFreightSet_Contact_Icontains",
                trade: "trade_Icontains",
                tenantAlertRuleId: "tenantAlertRuleId",

                // Freight set
                tripFreightSet_ContainerNumber_Icontains: "tripFreightSet_ContainerNumber_Icontains",
                tripFreightSet_AirWaybillNumber_Icontains: "tripFreightSet_AirWaybillNumber_Icontains",
                tripFreightSet_ReferenceNumber_Icontains: "tripFreightSet_ReferenceNumber_Icontains",
                tripFreightSet_OrderNumber_Icontains: "tripFreightSet_OrderNumber_Icontains",
                tripFreightSet_Contact_Icontains: "tripFreightSet_Contact_Icontains",

                // Special cases
                tracker: "trackerNumber",
                "Sort By": "orderBy",
                Favorite: "isFavorite",
                "Unresolved Alerts": "tripAlertSetIsResolved",
                Search: "search"
            },
            CODE_MAPPINGS: {
                // Basic filters
                tenantPartnerId: "Tenant",
                referenceNumber_Icontains: "referenceNumber",
                tripFreightSet_ReferenceNumber_Icontains: "freights",
                status_In: "status",

                // Document numbers
                containerNumber: "containerNumber",
                tripFreightSet_ContainerNumber_Icontains: "containerNumber",
                tripFreightSet_AirWaybillNumber_Icontains: "airWaybillNumber",
                awbNumber: "airWaybillNumber",
                orderNumber: "orderNumber",
                tripFreightSet_OrderNumber_Icontains: "orderNumber",
                blNumber: "blNumber",
                tripSeaTransportSetBookingConfirmationNumberIexact: "booking",

                // Dates
                plannedDepartureDate_Gte: "plannedDepartureDate",
                plannedDepartureDate_Lte: "plannedDepartureDate",
                plannedArrivalDate_Gte: "plannedArrivalDate",
                plannedArrivalDate_Lte: "plannedArrivalDate",
                tripSeaTransportSetVgmCutOffDateGte: "vgm",
                tripSeaTransportSetVgmCutOffDateLte: "vgm",
                tripSeaTransportSetCutOffDateTimeLte: "cutOff",
                tripSeaTransportSetCutOffDateTimeGte: "cutOff",

                // Locations
                podsearch: "podsearch",
                polSearch: "polSearch",
                transshipmentSearch: "transshipmentSearch",
                pol: "pol",
                pod: "pod",
                transshipment: "transshipment",
                trackerLastLocation_Icontains: "trackerLastLocation",
                origin: "origin",
                destination: "destination",

                // Partners and entities
                tenantCarrier: "carrier",
                consignor: "consignor",
                consignee: "consignee",
                forwarder: "forwarder",
                tenant: "sharedBy",
                polvessel: "polvessel",
                tsvessel: "tsvessel",
                goods: "goods",

                // Other attributes
                transportMode_In: "transportMode",
                alertCode: "alertCode",
                alertTag: "alertTag",
                tripFreightSet_Contact_Icontains: "contactPerson",
                trade_Icontains: "trade",
                trackerSerialNumber: "tracker",
                trackerNumber: "tracker",
                tenantAlertRuleId: "tenantAlertRuleId",

                // Special cases
                orderBy: "Sort By",
                isFavorite: "Favorite",
                tripAlertSetIsResolved: "Unresolved Alerts",
                search: "Search"
            }
        };
    },
    computed: {
        me() {
            return helper.me();
        },
        search: {
            get() {
                let _search = this.filter.search || "";
                return _search;
            },
            set(val) {
                let _filter = helper.getFilterValues("myTripTableFilters");
                if (_filter) {
                    this.filter = _filter;
                }
                this.filter.search = val;
                //this.updateFilter("search", this.filter);

                this.$emit("filter-changed", JSON.parse(JSON.stringify(this.filter)), null);
            }
        },
        screenSize() {
            let _resposiveView = this.$vuetify.breakpoint.width;
            if (_resposiveView < 650) {
                return "xs";
            } else if (_resposiveView < 1000) {
                return "sm";
            } else if (_resposiveView < 1400) {
                return "md";
            } else if (_resposiveView < 1900) {
                return "lg";
            } else {
                return "xl";
            }
        },
        screen() {
            return this.$vuetify.breakpoint.width;
        },
        isSmallScreen() {
            return this.$vuetify.breakpoint.width < 700;
        },
        _filterChoices() {
            return this.filterChoices;
        },
        tenantPartners() {
            return this.tripPartnerSet?.edges?.map(e => e.node) || [];
        },
        statusChoices() {
            let _statusChoices = [
                { text: this.$t("general.all_trips"), value: null },
                { text: this.$t("general.active"), value: "active" },
                { text: this.$t("general.completed"), value: "completed" }
            ];
            return _statusChoices;
        },
        alertsType: function() {
            let _alertsType = [
                {
                    name: this.$t("general.all"),
                    value: "All"
                },
                {
                    name: this.$t("dashboard.etd_delay"),
                    value: "SEA_ETD_DELAY"
                },
                {
                    name: this.$t("dashboard.eta_delay"),
                    value: "SEA_ETA_DELAY"
                },
                {
                    name: this.$t("dashboard.ts_waiting"),
                    value: "SEA_TRANS_WAIT"
                },
                {
                    name: this.$t("dashboard.pod_waiting"),
                    value: "SEA_DEST_WAIT"
                },
                {
                    name: this.$t("dashboard.custom_alert"),
                    value: "CUSTOM_ALERT"
                }
            ];
            return _alertsType;
        },
        sortByChoices: function() {
            let _sortByChoices = [
                {
                    code: "referenceNumber",
                    name: this.$t("headers.reference_number")
                },
                {
                    code: "status",
                    name: this.$t("headers.status")
                },
                {
                    code: "createdAt",
                    name: this.$t("headers.created_at")
                },
                {
                    code: "modifiedAt",
                    name: this.$t("headers.modified_at")
                },
                {
                    code: "plannedDepartureDate",
                    name: this.$t("headers.planned_departure")
                },
                {
                    code: "consignor",
                    name: this.$t("headers.consignor")
                },
                {
                    code: "consignee",
                    name: this.$t("headers.consignee")
                },
                {
                    code: "forwarder",
                    name: this.$t("headers.forwarder")
                },
                {
                    code: "tenant_carrier",
                    name: this.$t("headers.carrier")
                }
            ];
            return _sortByChoices;
        },
        plannedDepartureDateRangeText() {
            if (this.plannedDepartureDateRange.length > 1) {
                let date1 = moment(this.plannedDepartureDateRange[0], "YYYY-MM-DD").format(helper.getDateFormat());
                let date2 = moment(this.plannedDepartureDateRange[1], "YYYY-MM-DD").format(helper.getDateFormat());
                if (date1 == date2) {
                    return date1;
                }
                return date1 + " ~ " + date2;
            } else if (this.plannedDepartureDateRange.length === 1) {
                return moment(this.plannedDepartureDateRange[0], "YYYY-MM-DD").format(helper.getDateFormat());
            } else {
                return this.plannedDepartureDateRange;
            }
        },
        plannedArrivalDateRangeText() {
            if (this.plannedArrivalDateRange.length > 1) {
                let date1 = moment(this.plannedArrivalDateRange[0], "YYYY-MM-DD").format(helper.getDateFormat());
                let date2 = moment(this.plannedArrivalDateRange[1], "YYYY-MM-DD").format(helper.getDateFormat());
                if (date1 == date2) {
                    return date1;
                }
                return date1 + " ~ " + date2;
            } else if (this.plannedArrivalDateRange.length === 1) {
                return moment(this.plannedArrivalDateRange[0], "YYYY-MM-DD").format(helper.getDateFormat());
            } else {
                return this.plannedArrivalDateRange;
            }
        },
        vgmCutOffDateRangeText() {
            if (this.vgmCutOffDateRange.length > 1) {
                let date1 = moment(this.vgmCutOffDateRange[0], "YYYY-MM-DD").format(helper.getDateFormat());
                let date2 = moment(this.vgmCutOffDateRange[1], "YYYY-MM-DD").format(helper.getDateFormat());
                if (date1 == date2) {
                    return date1;
                }
                return date1 + " ~ " + date2;
            } else if (this.vgmCutOffDateRange.length === 1) {
                return moment(this.vgmCutOffDateRange[0], "YYYY-MM-DD").format(helper.getDateFormat());
            } else {
                return this.vgmCutOffDateRange;
            }
        },
        cutOffDateRangeText() {
            if (this.cutOffDateRange.length > 1) {
                let date1 = moment(this.cutOffDateRange[0], "YYYY-MM-DD").format(helper.getDateFormat());
                let date2 = moment(this.cutOffDateRange[1], "YYYY-MM-DD").format(helper.getDateFormat());
                if (date1 == date2) {
                    return date1;
                }
                return date1 + " ~ " + date2;
            } else if (this.cutOffDateRange.length === 1) {
                return moment(this.cutOffDateRange[0], "YYYY-MM-DD").format(helper.getDateFormat());
            } else {
                return this.cutOffDateRange;
            }
        }
    },
    watch: {
        sortBy(val) {
            if (val) {
                this.filter.orderBy = this.sortAsc ? val : `-${val}`;
            } else {
                this.filter.orderBy = null;
            }
            // if sort by is changed, update the filter
            this.$emit("filter-changed", JSON.parse(JSON.stringify(this.filter)), null);
        },
        sortAsc(val) {
            if (this.sortBy) {
                this.filter.orderBy = val ? this.sortBy : `-${this.sortBy}`;
            } else {
                this.filter.orderBy = null;
            }
            // if sort Asc/Desc is changed, update the filter
            this.$emit("filter-changed", JSON.parse(JSON.stringify(this.filter)), null);
        },
        relativePlannedDepartureDateRange(val) {
            const _index = this.relativePlannedDepartureDateRangeChoices.indexOf(val);
            let _startDate = null;
            let _endDate = null;
            switch (_index) {
                case 0:
                    // last month
                    _endDate = moment().format("YYYY-MM-DD");
                    _startDate = moment()
                        .date(0)
                        .startOf("month")
                        .format("YYYY-MM-DD");
                    this.plannedDepartureDateRange = [_startDate, _endDate];
                    this.savedPlannedDepartureDateRange = [_startDate, _endDate];
                    break;
                case 1:
                    // last quarter
                    _endDate = moment().format("YYYY-MM-DD");
                    _startDate = moment()
                        .subtract(3, "months")
                        .startOf("month")
                        .format("YYYY-MM-DD");
                    this.plannedDepartureDateRange = [_startDate, _endDate];
                    this.savedPlannedDepartureDateRange = [_startDate, _endDate];
                    break;
                case 2:
                    // last half year
                    _endDate = moment().format("YYYY-MM-DD");
                    _startDate = moment()
                        .subtract(6, "months")
                        .startOf("month")
                        .format("YYYY-MM-DD");
                    this.plannedDepartureDateRange = [_startDate, _endDate];
                    this.savedPlannedDepartureDateRange = [_startDate, _endDate];
                    break;
                case 3:
                    // last 1 year
                    _endDate = moment().format("YYYY-MM-DD");
                    _startDate = moment()
                        .subtract(12, "months")
                        .startOf("month")
                        .format("YYYY-MM-DD");
                    this.plannedDepartureDateRange = [_startDate, _endDate];
                    this.savedPlannedDepartureDateRange = [_startDate, _endDate];
                    break;
                default:
                    this.plannedDepartureDateRange = [];
                    this.savedPlannedDepartureDateRange = [];
            }
        },
        relativePlannedArrivalDateRange(val) {
            const _index = this.relativePlannedArrivalDateRangeChoices.indexOf(val);
            let _startDate = null;
            let _endDate = null;
            switch (_index) {
                case 0:
                    // last month
                    _endDate = moment().format("YYYY-MM-DD");
                    _startDate = moment()
                        .date(0)
                        .startOf("month")
                        .format("YYYY-MM-DD");
                    this.plannedArrivalDateRange = [_startDate, _endDate];
                    this.savedPlannedArrivalDateRange = [_startDate, _endDate];
                    break;
                case 1:
                    // last quarter
                    _endDate = moment().format("YYYY-MM-DD");
                    _startDate = moment()
                        .subtract(3, "months")
                        .startOf("month")
                        .format("YYYY-MM-DD");
                    this.plannedArrivalDateRange = [_startDate, _endDate];
                    this.savedPlannedArrivalDateRange = [_startDate, _endDate];
                    break;
                case 2:
                    // last half year
                    _endDate = moment().format("YYYY-MM-DD");
                    _startDate = moment()
                        .subtract(6, "months")
                        .startOf("month")
                        .format("YYYY-MM-DD");
                    this.plannedArrivalDateRange = [_startDate, _endDate];
                    this.savedPlannedArrivalDateRange = [_startDate, _endDate];
                    break;
                case 3:
                    // last 1 year
                    _endDate = moment().format("YYYY-MM-DD");
                    _startDate = moment()
                        .subtract(12, "months")
                        .startOf("month")
                        .format("YYYY-MM-DD");
                    this.plannedArrivalDateRange = [_startDate, _endDate];
                    this.savedPlannedArrivalDateRange = [_startDate, _endDate];
                    break;
                default:
                    this.plannedArrivalDateRange = [];
                    this.savedPlannedArrivalDateRange = [];
            }
        },
        savedPlannedDepartureDateRange(val) {
            if (val.length == 1) {
                this.menuObject["plannedDepartureDate_Gte"] = val[0] + "T00:00:00";
                this.menuObject["plannedDepartureDate_Lte"] = val[0] + "T23:59:59";
            } else if (val.length == 2) {
                this.menuObject["plannedDepartureDate_Gte"] = val[0] + "T00:00:00";
                this.menuObject["plannedDepartureDate_Lte"] = val[1] + "T23:59:59";
            } else {
                this.menuObject["plannedDepartureDate_Gte"] = null;
                this.menuObject["plannedDepartureDate_Lte"] = null;
            }
        },
        savedPlannedArrivalDateRange(val) {
            if (val.length == 1) {
                this.menuObject["plannedArrivalDate_Gte"] = val[0] + "T00:00:00";
                this.menuObject["plannedArrivalDate_Lte"] = val[0] + "T23:59:59";
            } else if (val.length == 2) {
                this.menuObject["plannedArrivalDate_Gte"] = val[0] + "T00:00:00";
                this.menuObject["plannedArrivalDate_Lte"] = val[1] + "T23:59:59";
            } else {
                this.menuObject["plannedArrivalDate_Gte"] = null;
                this.menuObject["plannedArrivalDate_Lte"] = null;
            }
        },
        relativeCutOffDateTimeRange(val) {
            const _index = this.relativeCutOffDateTimeRangeChoices.indexOf(val);
            let _startDate = null;
            let _endDate = null;
            switch (_index) {
                case 0:
                    // last month
                    _endDate = moment().format("YYYY-MM-DD");
                    _startDate = moment()
                        .date(0)
                        .startOf("month")
                        .format("YYYY-MM-DD");
                    this.cutOffDateRange = [_startDate, _endDate];
                    this.savedCutOffDateRange = [_startDate, _endDate];
                    break;
                case 1:
                    // last quarter
                    _endDate = moment().format("YYYY-MM-DD");
                    _startDate = moment()
                        .subtract(3, "months")
                        .startOf("month")
                        .format("YYYY-MM-DD");
                    this.cutOffDateRange = [_startDate, _endDate];
                    this.savedCutOffDateRange = [_startDate, _endDate];
                    break;
                case 2:
                    // last half year
                    _endDate = moment().format("YYYY-MM-DD");
                    _startDate = moment()
                        .subtract(6, "months")
                        .startOf("month")
                        .format("YYYY-MM-DD");
                    this.cutOffDateRange = [_startDate, _endDate];
                    this.savedCutOffDateRange = [_startDate, _endDate];
                    break;
                case 3:
                    // last 1 year
                    _endDate = moment().format("YYYY-MM-DD");
                    _startDate = moment()
                        .subtract(12, "months")
                        .startOf("month")
                        .format("YYYY-MM-DD");
                    this.cutOffDateRange = [_startDate, _endDate];
                    this.savedCutOffDateRange = [_startDate, _endDate];
                    break;
                default:
                    this.cutOffDateRange = [];
                    this.savedCutOffDateRange = [];
            }
        },
        relativeVgmCutOffDateRange(val) {
            const _index = this.relativeVgmCutOffDateRangeChoices.indexOf(val);
            let _startDate = null;
            let _endDate = null;
            switch (_index) {
                case 0:
                    // last month
                    _endDate = moment().format("YYYY-MM-DD");
                    _startDate = moment()
                        .date(0)
                        .startOf("month")
                        .format("YYYY-MM-DD");
                    this.vgmCutOffDateRange = [_startDate, _endDate];
                    this.savedVgmCutOffDateRange = [_startDate, _endDate];
                    break;
                case 1:
                    // last quarter
                    _endDate = moment().format("YYYY-MM-DD");
                    _startDate = moment()
                        .subtract(3, "months")
                        .startOf("month")
                        .format("YYYY-MM-DD");
                    this.vgmCutOffDateRange = [_startDate, _endDate];
                    this.savedVgmCutOffDateRange = [_startDate, _endDate];
                    break;
                case 2:
                    // last half year
                    _endDate = moment().format("YYYY-MM-DD");
                    _startDate = moment()
                        .subtract(6, "months")
                        .startOf("month")
                        .format("YYYY-MM-DD");
                    this.vgmCutOffDateRange = [_startDate, _endDate];
                    this.savedVgmCutOffDateRange = [_startDate, _endDate];
                    break;
                case 3:
                    // last 1 year
                    _endDate = moment().format("YYYY-MM-DD");
                    _startDate = moment()
                        .subtract(12, "months")
                        .startOf("month")
                        .format("YYYY-MM-DD");
                    this.vgmCutOffDateRange = [_startDate, _endDate];
                    this.savedVgmCutOffDateRange = [_startDate, _endDate];
                    break;
                default:
                    this.vgmCutOffDateRange = [];
                    this.savedVgmCutOffDateRange = [];
            }
        },
        savedVgmCutOffDateRange(val) {
            if (val.length == 1) {
                this.menuObject["tripSeaTransportSetVgmCutOffDateGte"] = val[0] + "T00:00:00";
                this.menuObject["tripSeaTransportSetVgmCutOffDateLte"] = val[0] + "T23:59:59";
            } else if (val.length == 2) {
                this.menuObject["tripSeaTransportSetVgmCutOffDateGte"] = val[0] + "T00:00:00";
                this.menuObject["tripSeaTransportSetVgmCutOffDateLte"] = val[1] + "T23:59:59";
            } else {
                this.menuObject["tripSeaTransportSetVgmCutOffDateGte"] = null;
                this.menuObject["tripSeaTransportSetVgmCutOffDateLte"] = null;
            }
        },
        savedCutOffDateRange(val) {
            if (val.length == 1) {
                this.menuObject["tripSeaTransportSetCutOffDateTimeGte"] = val[0] + "T00:00:00";
                this.menuObject["tripSeaTransportSetCutOffDateTimeLte"] = val[0] + "T23:59:59";
            } else if (val.length == 2) {
                this.menuObject["tripSeaTransportSetCutOffDateTimeGte"] = val[0] + "T00:00:00";
                this.menuObject["tripSeaTransportSetCutOffDateTimeLte"] = val[1] + "T23:59:59";
            } else {
                this.menuObject["tripSeaTransportSetCutOffDateTimeGte"] = null;
                this.menuObject["tripSeaTransportSetCutOffDateTimeLte"] = null;
            }
        }
        // filterSelected: {
        //     handler(value) {
        //         //reset filter
        //         this.restartAllFilters();
        //         this.$nextTick(async () => {
        //             await this.populateFilter();
        //         });
        //     },
        //     immediate: true,
        //     deep: true
        // }
    },
    created() {
        this.hasPermission = helper.hasPermission;
        this.pushTo = helper.pushTo;
    },
    async mounted() {
        this.$watch(
            "filterSelected",
            function() {
                this.restartAllFilters();
                this.$nextTick(() => {
                    this.populateFilter();
                });
            },
            { deep: true, immediate: true }
        );
    },
    methods: {
        // Method to update the menu width
        removeFilterRoute() {
            this.$router.replace({ query: {} });
            this.$emit("update:filterCode", null);
        },
        onMenuStateChange(val, menuRef) {
            if (!val) {
                this.cancelFilter(this.menuReference);
                this.menuReference = null;
            } else {
                if (this.menuReference && this.menuReference !== menuRef) {
                    //Close the previous menu
                    this.cancelFilter(this.menuReference);
                }
                //Set the current menu
                this.menuReference = menuRef;

                this.$nextTick(() => {
                    if (this.$refs[this.menuReference] && this.$refs[this.menuReference][0]) {
                        this.$refs[this.menuReference][0].isActive = true;
                    } else if (this.$refs[this.menuReference]) {
                        this.$refs[this.menuReference].isActive = true;
                    }
                });
            }
        },
        async populateFilter() {
            // Get active view filters
            const activeView =
                this.filterSelected !== "default"
                    ? this.filterPreferences.find(item => item.view.code === this.filterSelected)
                    : null;

            // Initialize filter and enabled filters from active view or defaults
            let filter = activeView ? activeView.tripTableFilters || activeView.TripTableFilters : this.filter;

            this.enabledFilters = activeView?.tripFilterEnabled || [
                {
                    name: "Status",
                    code: "status_In",
                    enabled: true
                }
            ];

            // Handle special filter codes
            if (this.filterCode) {
                filter = { ...this.applySpecialFilter(filter) };
                //remove route
                this.removeFilterRoute();
            }

            // Only proceed if we have filters to process
            if (!Object.keys(filter).length) return;

            // Update filter object and sort settings
            await this.updateFilterAndSort(filter);

            // Update date ranges
            this.updateDateRanges(filter);

            // Update refresh flags for related data
            this.updateRefreshFlags(filter);

            // Update menu object
            this.updateMenuObject(filter);

            // Trigger filter changes
            await this.filterValues(this.filter);
            this.$emit("filter-changed", JSON.parse(JSON.stringify(this.filter)), this.enabledFilters);
        },
        // Helper methods
        applySpecialFilter(filter) {
            const code = this.filterCode;
            filter.status_In = null;
            // Alert type filters
            let getAlert = this.alertsType.find(e => e.value === code);
            if (getAlert) {
                filter.alertCode = [];
                filter.alertCode.push(getAlert.value);

                //update enabled filters
                this.enabledFilters.push({
                    name: "Alert Code",
                    code: "alertCode",
                    enabled: true
                });
            }
            // Status-based filters
            else if (code === "TOTAL_OPEN_TRIPS") {
                filter.status_In = "active";
            }
            // Alert resolution filters
            else if (code === "UNRESOLVED_ALERTS") {
                filter.alertCode = [];
                filter.alertCode.push("All");

                //update enabled filters
                this.enabledFilters.push({
                    name: "Alert Code",
                    code: "alertCode",
                    enabled: true
                });

                //filter.tripAlertSetIsResolved = false;
                //this.unresolvedAlerts = true;
            }
            // Alert tag filters
            else if (code.startsWith("UNRESOLVED_TAG_")) {
                const tagMap = {
                    TEMPERATURE: "temperature",
                    HUMIDITY: "humidity",
                    LIGHT: "light",
                    SHOCK: "shock",
                    PRESSURE: "pressure",
                    TEMPERATURE_HUMIDITY: "temperature_humidity",
                    TEMPERATURE_HUMIDITY_LIGHT: "temperature_humidity_light"
                };
                const tag = code.replace("UNRESOLVED_TAG_", "");
                filter.alertTag = tagMap[tag];

                //update enabled filters
                this.enabledFilters.push({
                    name: "Alert Tag",
                    code: "alertTag",
                    enabled: true
                });
            }

            // Update filter object and return
            return filter;
        },
        async updateFilterAndSort(filter) {
            // Update main filter object
            Object.assign(this.filter, filter);

            // Handle shared trip case
            if (this.isSharedTrip) {
                this.filter.sharedBy = filter.sharedBy || null;
            }

            // Update sort settings
            if (filter.orderBy?.includes("-")) {
                this.sortBy = filter.orderBy.replace("-", "");
                this.sortAsc = false;
            } else {
                this.sortBy = filter.orderBy;
                this.sortAsc = true;
            }
        },
        updateDateRanges(filter) {
            const dateRanges = [
                {
                    condition: filter.plannedDepartureDate_Gte,
                    target: "plannedDepartureDateRange",
                    start: "plannedDepartureDate_Gte",
                    end: "plannedDepartureDate_Lte"
                },
                {
                    condition: filter.plannedArrivalDate_Gte,
                    target: "plannedArrivalDateRange",
                    start: "plannedArrivalDate_Gte",
                    end: "plannedArrivalDate_Lte"
                },
                {
                    condition: filter.tripSeaTransportSetCutOffDateTimeGte,
                    target: "cutOffDateRange",
                    start: "tripSeaTransportSetCutOffDateTimeGte",
                    end: "tripSeaTransportSetCutOffDateTimeLte"
                },
                {
                    condition: filter.tripSeaTransportSetVgmCutOffDateGte,
                    target: "vgmCutOffDateRange",
                    start: "tripSeaTransportSetVgmCutOffDateGte",
                    end: "tripSeaTransportSetVgmCutOffDateLte"
                }
            ];

            dateRanges.forEach(range => {
                if (range.condition) {
                    this[range.target] = [filter[range.start].split("T")[0], filter[range.end].split("T")[0]];
                }
            });
        },
        updateRefreshFlags(filter) {
            const refreshMap = {
                forwarder: "refreshTableForwarder",
                tenantCarrier: "refreshTableCarrier",
                consignee: "refreshTableConsignee",
                consignor: "refreshTableConsignor",
                polvessel: "refreshTableVessel",
                tsvessel: "refreshTableVessel",
                pol: "refreshTablePorts",
                pod: "refreshTablePorts",
                transshipment: "refreshTablePorts",
                origin: "refreshTableLocations",
                destination: "refreshTableLocations"
            };

            Object.entries(refreshMap).forEach(([key, flag]) => {
                if (filter[key]) {
                    this[flag] = true;
                }
            });
        },
        updateMenuObject(filter) {
            this.menuObject = Object.keys(filter).reduce((obj, key) => {
                obj[key] = key === "tripAlertSetIsResolved" ? (filter[key] === false ? true : false) : filter[key];
                return obj;
            }, {});

            this.copyMenuObject = { ...this.menuObject };
        },
        formatDate(date) {
            if (!date) return null;

            const [year, month, day] = date.split("-");
            return `${day}/${month}/${year}`;
        },
        addCode(code) {
            this.codeSelected = code;
        },
        cancelFilter(refMenu) {
            //clear menuObject
            this.codeSelected = null;

            //reset menuObject with previous value
            this.menuObject = { ...this.copyMenuObject };

            //clear menu filter
            if (this.$refs[refMenu]) {
                if (this.$refs[refMenu][0]) {
                    this.$refs[refMenu][0].isActive = false;
                } else {
                    this.$refs[refMenu].isActive = false;
                }
            }
        },
        onFilterMenuChanged(newChoices) {
            this.filterChoices = newChoices;
        },
        /* populate menu filter */
        async searchFields(menuItems, refMenu) {
            let menuKeys = Object.keys(menuItems);
            let _filter = helper.getFilterValues("myTripTableFilters");

            if (_filter) {
                Object.keys(_filter).forEach(key => {
                    if (key) {
                        this.filter[key] = _filter[key];
                    }
                });
            }

            menuKeys.forEach(key => {
                if (menuItems[key] != undefined) {
                    if (key === "tripAlertSetIsResolved") {
                        this.filter[key] = menuItems[key] === true ? false : null;
                    } else {
                        this.filter[key] = menuItems[key];
                    }
                } else {
                    this.filter[key] = null;
                }
            });

            //update copy menu object
            this.copyMenuObject = { ...menuItems };

            //custom seach reset search
            /*  if (this.filter.search) {
                this.filter.search = null;
            } */

            //populate filter date
            if (this.filter.plannedDepartureDate_Gte || this.filter.plannedDepartureDate_Lte) {
                this.plannedDepartureDateRange = [
                    this.filter.plannedDepartureDate_Gte.split("T")[0],
                    this.filter.plannedDepartureDate_Lte.split("T")[0]
                ];
            }

            if (this.filter.plannedArrivalDate_Gte || this.filter.plannedArrivalDate_Lte) {
                this.plannedArrivalDateRange = [
                    this.filter.plannedArrivalDate_Gte.split("T")[0],
                    this.filter.plannedArrivalDate_Lte.split("T")[0]
                ];
            }

            if (this.filter.tripSeaTransportSetCutOffDateTimeGte || this.filter.tripSeaTransportSetCutOffDateTimeLte) {
                this.cutOffDateRange = [
                    this.filter.tripSeaTransportSetCutOffDateTimeGte.split("T")[0],
                    this.filter.tripSeaTransportSetCutOffDateTimeLte.split("T")[0]
                ];
            }

            if (this.filter.tripSeaTransportSetVgmCutOffDateGte || this.filter.tripSeaTransportSetVgmCutOffDateLte) {
                this.vgmCutOffDateRange = [
                    this.filter.tripSeaTransportSetVgmCutOffDateGte.split("T")[0],
                    this.filter.tripSeaTransportSetVgmCutOffDateLte.split("T")[0]
                ];
            }

            //enable filter - garantee that the filter is enabled in the filter chips
            let codeIsFind = this.enabledFilters.find(e => e.code === this.getKey(this.codeSelected));
            let currentView = this.views.find(item => item.code === this.filterSelected);

            if (!codeIsFind) {
                this.enabledFilters.push({
                    name: this.replaceName(this.codeSelected),
                    code: this.getKey(this.codeSelected),
                    enabled: true
                });
            }

            //Apply filter changes to the table
            //update filter values chips
            // this.updateFilterValues(this.filter);
            await this.filterValues(this.filter);
            this.$emit("filter-changed", JSON.parse(JSON.stringify(this.filter)), this.enabledFilters);

            //reset menu content and close
            this.cancelFilter(refMenu);
        },
        async filterValues(object) {
            // Constants
            const filterKeysToIgnore = ["first", "after", "orderBy", "search"];
            const dateRangePairs = [
                {
                    start: "plannedDepartureDate_Gte",
                    end: "plannedDepartureDate_Lte",
                    getText: () => String(this.plannedDepartureDateRangeText)
                },
                {
                    start: "plannedArrivalDate_Gte",
                    end: "plannedArrivalDate_Lte",
                    getText: () => String(this.plannedArrivalDateRangeText)
                },
                {
                    start: "tripSeaTransportSetCutOffDateTimeGte",
                    end: "tripSeaTransportSetCutOffDateTimeLte",
                    getText: () => String(this.cutOffDateRangeText)
                },
                {
                    start: "tripSeaTransportSetVgmCutOffDateGte",
                    end: "tripSeaTransportSetVgmCutOffDateLte",
                    getText: () => String(this.vgmCutOffDateRangeText)
                }
            ];

            // Get enabled filter keys
            const enabledKeys = Object.keys(object).filter(key => {
                const findCode = this.enabledFilters.find(e => e.code === key);
                return findCode?.enabled && !filterKeysToIgnore.includes(key);
            });

            // Build initial filter values
            let values = await Promise.all(
                enabledKeys.map(async key => ({
                    id: key,
                    name: this.replaceName(key),
                    value: await this.valuePopulate(object[key], key)
                }))
            );

            // Process date range pairs
            dateRangePairs.forEach(({ start, end, getText }) => {
                const startFilter = values.find(e => e.id === start);
                const endFilter = values.find(e => e.id === end);
                if (startFilter || endFilter) {
                    if (startFilter?.value !== endFilter?.value) {
                        if (startFilter) startFilter.value = getText();
                    }
                    // Remove end date from values
                    values = values.filter(e => e.id !== end);
                }
            });

            this.filterValuesObj = values;
        },
        async deleteFilterValue(key) {
            if (key == "plannedDepartureDate_Gte" || key == "plannedDepartureDate_Lte") {
                this.menuObject = {};
                this.plannedDepartureDateRange = [];
                this.filter["plannedDepartureDate_Gte"] = null;
                this.filter["plannedDepartureDate_Lte"] = null;
            } else if (key == "plannedArrivalDate_Gte" || key == "plannedArrivalDate_Lte") {
                this.menuObject = {};
                this.plannedArrivalDateRange = [];
                this.filter["plannedArrivalDate_Gte"] = null;
                this.filter["plannedArrivalDate_Lte"] = null;
            } else if (key == "tripSeaTransportSetCutOffDateTimeGte" || key == "tripSeaTransportSetCutOffDateTimeLte") {
                this.menuObject = {};
                this.cutOffDateRange = [];
                this.filter["tripSeaTransportSetCutOffDateTimeGte"] = null;
                this.filter["tripSeaTransportSetCutOffDateTimeLte"] = null;
                this.relativeCutOffDateTimeRange = null;
            } else if (key == "tripSeaTransportSetVgmCutOffDateGte" || key == "tripSeaTransportSetVgmCutOffDateLte") {
                this.menuObject = {};
                this.vgmCutOffDateRange = [];
                this.filter["tripSeaTransportSetVgmCutOffDateGte"] = null;
                this.filter["tripSeaTransportSetVgmCutOffDateLte"] = null;
                this.relativeVgmCutOffDateRange = null;
            } else {
                this.menuObject[key] = null;
                this.filter[key] = null;
            }

            //delete enabled filters
            let codeIsFind = this.enabledFilters.find(e => e.code === key);
            if (codeIsFind) {
                this.enabledFilters = this.enabledFilters.filter(e => e.code !== key);
            }
            //update filter values chips
            // this.updateFilterValues(this.filter);
            await this.filterValues(this.filter);

            this.$emit("filter-changed", JSON.parse(JSON.stringify(this.filter)), this.enabledFilters);
        },
        async getLocationName(tenantLocationIds) {
            try {
                const { data } = await this.$apollo.query({
                    query: gql`
                        query TenantLocations($idsIn: [ID]) {
                            tenantLocations(idsIn: $idsIn) {
                                edges {
                                    node {
                                        id
                                        name
                                        city
                                        fullAddress
                                    }
                                }
                            }
                        }
                    `,
                    fetchPolicy: "no-cache",
                    variables: {
                        idsIn: tenantLocationIds
                    }
                });
                return data.tenantLocations.edges.map(e => e.node.city).join(", ");
            } catch (error) {
                console.error("Error fetching location name:", error);
                return null;
            }
        },
        async getVesselName(vesselId) {
            try {
                const { data } = await this.$apollo.query({
                    query: gql`
                        query Vessel($vesselId: ID!) {
                            vessel(id: $vesselId) {
                                id
                                name
                            }
                        }
                    `,
                    variables: {
                        vesselId: vesselId
                    }
                });

                return data.vessel.name;
            } catch (error) {
                console.error("Error fetching vessel name:", error);
                // Handle the error appropriately (e.g., returning a default value or rethrowing the error)
                throw error;
            }
        },
        async getPartnerName(partnerIds) {
            let _partner = [];
            try {
                const { data } = await this.$apollo.query({
                    query: gql`
                        query tenantPartners($orderBy: String, $globalIdIn: [ID]) {
                            tenantPartners(orderBy: $orderBy, globalIdIn: $globalIdIn) {
                                edges {
                                    node {
                                        id
                                        name
                                    }
                                }
                            }
                        }
                    `,
                    fetchPolicy: "no-cache",
                    variables: {
                        globalIdIn: partnerIds
                    }
                });

                //TenantPartner returns an array of partners
                if (data.tenantPartners.edges.length > 0) {
                    data.tenantPartners.edges.forEach(e => {
                        _partner.push(e.node.name);
                    });
                }

                return _partner.join(", ");
            } catch (error) {
                return null;
            }
        },
        replaceName(key) {
            let replace = "";
            switch (key) {
                case "tenantPartnerId":
                    replace = "Tenant";
                    break;
                case "referenceNumber_Icontains":
                    replace = this.$t("headers.trip_ref");
                    break;
                case "trackerSerialNumber":
                    replace = "Tracker serial no.";
                    break;
                case "tripFreightSet_ContainerNumber_Icontains":
                    replace = "Container no.";
                    break;
                case "status_In":
                    replace = "Status";
                    break;
                case "tripFreightSet_AirWaybillNumber_Icontains":
                    replace = "Airwaybill no.";
                    break;
                case "tripFreightSet_ReferenceNumber_Icontains":
                    replace = this.$t("headers.freight_ref");
                    break;
                case "plannedDepartureDate_Gte":
                    replace = this.$t("headers.planned_departure_date");
                    break;
                case "plannedDepartureDate_Lte":
                    replace = this.$t("headers.planned_departure_date");
                    break;
                case "plannedArrivalDate_Gte":
                    replace = this.$t("headers.planned_arrival_date");
                    break;
                case "plannedArrivalDate_Lte":
                    replace = this.$t("headers.planned_arrival_date");
                    break;
                case "tripSeaTransportSetCutOffDateTimeLte":
                    replace = this.$t("headers.cut_off_date");
                    break;
                case "tripSeaTransportSetCutOffDateTimeGte":
                    replace = this.$t("headers.cut_off_date");
                    break;
                case "tripSeaTransportSetVgmCutOffDateGte":
                    replace = this.$t("headers.vgm_cut_off_date");
                    break;
                case "tripSeaTransportSetVgmCutOffDateLte":
                    replace = this.$t("headers.vgm_cut_off_date");
                    break;
                case "tripSeaTransportSetBookingConfirmationNumberIexact":
                    replace = this.$t("headers.booking_confirmation_number");
                    break;
                case "orderBy":
                    replace = "Sort By";
                    break;
                case "isFavorite":
                    replace = "Favorite";
                    break;
                case "pol":
                    replace = "POL";
                    break;
                case "transshipment":
                    replace = "T/S";
                    break;
                case "pod":
                    replace = "POD";
                    break;
                case "tenantAlertRuleId":
                    replace = this.$t("headers.alert_name");
                    break;
                case "alertCode":
                    replace = this.$t("headers.alert_type");
                    break;
                case "tripAlertSetIsResolved":
                    replace = "Unresolved Alerts";
                    break;
                case "tenantCarrier":
                    replace = this.$t("headers.carrier");
                    break;
                case "consignor":
                    replace = this.$t("headers.consignor");
                    break;
                case "consignee":
                    replace = this.$t("headers.consignee");
                    break;
                case "forwarder":
                    replace = this.$t("headers.forwarder");
                    break;
                case "polvessel":
                    replace = this.$t("headers.pol_vessel");
                    break;
                case "tsvessel":
                    replace = this.$t("headers.ts_vessel");
                    break;
                case "goods":
                    replace = this.$t("headers.goods");
                    break;
                case "tenant":
                    replace = "Shared By";
                    break;
                case "trackerLastLocation_Icontains":
                    replace = this.$t("headers.last_location");
                    break;
                case "search":
                    replace = "Search";
                    break;
                case "polSearch":
                    replace = "POL Code";
                    break;
                case "transshipmentSearch":
                    replace = "T/S Code";
                    break;
                case "origin":
                    replace = "Origin";
                    break;
                case "destination":
                    replace = "Destination";
                    break;
                case "podsearch":
                    replace = "POD Code";
                    break;
                case "transportMode_In":
                    replace = this.$t("headers.transport");
                    break;
                case "tripFreightSet_OrderNumber_Icontains":
                    replace = this.$t("headers.order_number");
                    break;
                case "tripFreightSet_Contact_Icontains":
                    replace = this.$t("headers.contact_person");
                    break;
                case "alertTag":
                    replace = this.$t("headers.alert_tag");
                    break;
                case "trade_Icontains":
                    replace = this.$t("headers.trade");
                    break;
                case "awbNumber":
                    replace = this.$t("headers.airwb_number");
                    break;
                case "containerNumber":
                    replace = this.$t("headers.container_number");
                    break;
                case "trackerNumber":
                    replace = this.$t("headers.tracker_number");
                    break;
                case "blNumber":
                    replace = this.$t("headers.bl_number");
                    break;
                case "orderNumber":
                    replace = this.$t("headers.order_number");
                    break;
            }

            return replace;
        },
        getCode(key) {
            return this.CODE_MAPPINGS[key] || "";
        },
        getKey(code) {
            // Return mapped key or null if not found
            return this.KEY_MAPPINGS[code] || null;
        },
        async valuePopulate(item, key) {
            let _value = null;
            switch (key) {
                case "tenantPartnerId":
                case "referenceNumber_Icontains":
                case "trackerSerialNumber":
                case "tripFreightSet_ContainerNumber_Icontains":
                case "tripFreightSet_AirWaybillNumber_Icontains":
                case "tripFreightSet_ReferenceNumber_Icontains":
                case "tripSeaTransportSetBookingConfirmationNumberIexact":
                case "orderBy":
                case "isFavorite":
                case "pol":
                case "transshipment":
                case "goods":
                case "tenant":
                case "trackerLastLocation_Icontains":
                case "search":
                case "transportMode_In":
                case "tripFreightSet_OrderNumber_Icontains":
                case "tripFreightSet_Contact_Icontains":
                case "trade_Icontains":
                case "awbNumber":
                case "containerNumber":
                case "trackerNumber":
                case "blNumber":
                case "orderNumber":
                case "pod":
                    _value = item ? item : this.$t("general.all");
                    break;
                case "tripAlertSetIsResolved":
                    _value = !item;
                    break;
                case "plannedDepartureDate_Gte":
                case "plannedDepartureDate_Lte":
                case "plannedArrivalDate_Gte":
                case "plannedArrivalDate_Lte":
                case "tripSeaTransportSetCutOffDateTimeLte":
                case "tripSeaTransportSetCutOffDateTimeGte":
                case "tripSeaTransportSetVgmCutOffDateGte":
                case "tripSeaTransportSetVgmCutOffDateLte":
                    _value = item ? item.split("T")[0] : null;
                    break;
                case "alertCode":
                    if (item && Array.isArray(item) && item.length > 0) {
                        _value = item
                            .map(code => {
                                const alert = this.alertsType.find(el => el.value === code);
                                return alert ? alert.name : code;
                            })
                            .join(", ");
                    } else {
                        _value = this.$t("general.all");
                    }
                    break;
                case "status_In":
                    _value = this.statusChoices.find(el => el.value === (item ? item.toLowerCase() : item))?.text;
                    break;
                case "origin":
                case "destination":
                    _value = item ? await this.getLocationName(item) : this.$t("general.all");
                    break;
                case "tenantCarrier":
                case "consignor":
                case "consignee":
                case "forwarder":
                    _value = item ? await this.getPartnerName(item) : this.$t("general.all");
                    break;
                case "polvessel":
                case "tsvessel":
                    _value = item ? await this.getVesselName(item) : this.$t("general.all");
                    break;
                case "alertTag":
                    _value = this.alertTags.find(el => el.value === item)?.text || this.$t("general.all");
                    break;
                case "tenantAlertRuleId":
                    _value = item ? await this.getAlertRuleName(item) : this.$t("general.all");
                    break;
            }
            return _value;
        },
        restartAllFilters() {
            this.filterValuesObj = [];
            this.menuObject = {};
            this.filter = {
                first: 10,
                status_In: "active",
                orderBy: "-plannedDepartureDate",
                isFavorite: null,
                after: null,
                tenantPartnerId: null,
                transportMode_In: null,
                referenceNumber_Icontains: null,
                tripAlertSetIsResolved: null,
                trackerSerialNumber: null,
                tripFreightSet_ContainerNumber_Icontains: null,
                tripFreightSet_AirWaybillNumber_Icontains: null,
                tripFreightSet_ReferenceNumber_Icontains: null,
                tripFreightSet_OrderNumber_Icontains: null,
                tripFreightSet_Contact_Icontains: null,
                plannedDepartureDate_Gte: null,
                plannedDepartureDate_Lte: null,
                plannedArrivalDate_Gte: null,
                plannedArrivalDate_Lte: null,
                pol: null,
                transshipment: null,
                pod: null,
                alertCode: null,
                tenantCarrier: null,
                consignor: null,
                consignee: null,
                forwarder: null,
                polvessel: null,
                tsvessel: null,
                goods: null,
                tenant: null,
                trackerLastLocation_Icontains: null,
                search: null,
                alertTag: null,
                trade_Icontains: null,
                awbNumber: null,
                containerNumber: null,
                trackerNumber: null,
                blNumber: null,
                orderNumber: null,
                tripSeaTransportSetCutOffDateTimeLte: null,
                tripSeaTransportSetCutOffDateTimeGte: null,
                tripSeaTransportSetVgmCutOffDateGte: null,
                tripSeaTransportSetVgmCutOffDateLte: null,
                tripSeaTransportSetBookingConfirmationNumberIexact: null,
                tenantAlertRuleId: null,
                origin: null,
                destination: null
            };
            this.enabledFilters = [
                {
                    name: "Status",
                    code: "status_In",
                    enabled: true
                }
            ];
        },
        async getAlertRuleName(item) {
            let tenantAlerts = [];
            try {
                const { data } = await this.$apollo.query({
                    query: gql`
                        query tenantAlertRules($orderBy: String, $idIn: [ID]) {
                            tenantAlertRules(orderBy: $orderBy, id_In: $idIn) {
                                edges {
                                    node {
                                        id
                                        name
                                    }
                                }
                            }
                        }
                    `,
                    fetchPolicy: "no-cache",
                    variables: {
                        idIn: item
                    }
                });

                //TenantPartner returns an array of partners
                if (data.tenantAlertRules.edges.length > 0) {
                    data.tenantAlertRules.edges.forEach(e => {
                        tenantAlerts.push(e.node.name);
                    });
                }

                return tenantAlerts.join(", ");
            } catch (error) {
                return null;
            }
            // const alertRule = this.$apollo.queries.alertRules.data.edges.find(edge => edge.node.id === item);
            // return alertRule ? alertRule.node.name : this.$t("general.all");
        },
        hasFilterValue(menuItems, code) {
            if (!code) return false;

            const filterKey = this.KEY_MAPPINGS[code];
            if (!filterKey) return false;

            const value = menuItems[filterKey];

            switch (code) {
                case 'plannedDepartureDate':
                    return this.$refs.FilterMenuTemplate?.plannedDepartureDateRange?.[0] != null;
                    
                case 'plannedArrivalDate':
                    return this.$refs.FilterMenuTemplate?.plannedArrivalDateRange?.[0] != null;
                    
                case 'vgm':
                    return this.$refs.FilterMenuTemplate?.vgmCutOffDateRange?.[0] != null;
                    
                case 'cutOff':
                    return this.$refs.FilterMenuTemplate?.cutOffDateRange?.[0] != null;
                    
                default:                    
                    return value !== null && value !== undefined && value !== '';
            }
        }
    }
};
</script>
<style scoped>
.widthContent {
    width: 280px;
}
.content {
    height: 30px;
}
.content-select {
    padding: 0;
    max-width: 240px;
}
.checkBox {
    position: static !important;
}
.classText {
    color: #0e0d35 !important;
    font-size: 13px;
    letter-spacing: 0em;
    text-align: left;
    white-space: nowrap; /* Prevents text from wrapping to the next line */
    overflow: hidden; /* Hides the overflowed content */
    text-overflow: ellipsis; /* Adds the ellipsis ('...') at the end of the truncated text */
    max-width: 100%;
}

.tooltipContent {
    max-width: 200px;
}
</style>
